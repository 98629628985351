import React from "react";
import { useAuth } from "../AuthContext";

export const withLoginHOC = (Component) => {
  return (props) => {
    const { loginFB } = useAuth();

    return <Component loginHook={loginFB} {...props} />;
  };
};

export const withLogoutHOC = (Component) => {
  return (props) => {
    const { logoutFB } = useAuth();

    return <Component logoutHook={logoutFB} {...props} />;
  };
};

export const withTokenSignInHOC = (Component) => {
  return (props) => {
    const { tokenSignIn } = useAuth();

    return <Component tokenSignInHook={tokenSignIn} {...props} />;
  };
};
