import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

// Component imports

import {
  splitAddressFull,
  capitalizeFirstChar,
} from "../../../util/utilFunctions";
import ShiftAccordionDetails from "./ShiftAccordionDetails";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// Icon imports
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutline";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ShiftAccordion extends Component {
  state = {
    showMore: false,
    tasks: [],
    isLoadedTasks: false,
    loadingTasks: false,
    allowReload: false,
    disableDeleteButton: false,
    disableWhileLoad: false,
  };

  componentDidMount() {
    this.setInitialOpenState();
  }

  setInitialOpenState = () => {
    const { shiftId } = this.props;
    if (shiftId) {
      this.setState({ showMore: shiftId });
    }
  };

  // UI Methods
  handleChange = (id) => {
    if (!this.state.showMore) {
      window.history.replaceState(
        null,
        "something",
        `/myteam/${this.props.currMemberId}/shift/${id}`
      );
    } else if (this.state.showMore === id) {
      window.history.replaceState(
        null,
        "something",
        `/myteam/${this.props.currMemberId}/`
      );
    } else {
      window.history.replaceState(
        null,
        "something",
        `/myteam/${this.props.currMemberId}/shift/${id}`
      );
    }

    this.setState({ showMore: this.state.showMore === id ? false : id });
  };

  render() {
    const { classes, filteredShifts, showName, isFamily } = this.props;
    const { showMore } = this.state;

    return (
      <Box>
        {filteredShifts.map((shift) => {
          const isUnactioned =
            dayjs().isAfter(dayjs(shift.endTime).add(2, "h")) &&
            !shift.status.isCompleted;
          return (
            <Accordion
              elevation={0}
              expanded={shift.id === showMore}
              onChange={() => this.handleChange(shift.id)}
              key={shift.id}
              className={
                isUnactioned
                  ? clsx(classes.errorBackground, classes.mobileContainer)
                  : shift.id === showMore
                  ? classes.selectedGrayContainer
                  : isMobile
                  ? classes.mobileContainer
                  : classes.hoverGrayContainer
              }
              style={{ padding: 0 }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ width: "100%" }}>
                  {showName && (
                    <div>
                      <Typography>
                        {capitalizeFirstChar(
                          isFamily
                            ? shift.caregiverInfo.firstName
                            : shift.familyInfo.clientFirstName
                        )}{" "}
                        {capitalizeFirstChar(
                          isFamily
                            ? shift.caregiverInfo.lastName
                            : shift.familyInfo.clientLastName
                        )}{" "}
                        {!isFamily && (
                          <Typography
                            component="span"
                            style={{ fontWeight: 300 }}
                          >
                            {"("}
                            {capitalizeFirstChar(
                              shift.familyInfo.firstName
                            )}{" "}
                            {capitalizeFirstChar(shift.familyInfo.lastName)}
                            {")"}
                          </Typography>
                        )}
                      </Typography>
                      <div className={classes.titleLine} />
                    </div>
                  )}
                  <div
                    className={classes.flexBoxSpaced}
                    style={{ width: "100%" }}
                  >
                    <div>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 300 }}
                      >
                        {dayjs(shift.startTime).format("MMM DD, YYYY")}
                      </Typography>
                      <Typography variant="body2">
                        {dayjs(shift.startTime).format("hh:mma")}
                        {dayjs(shift.endTime).format(" - hh:mma")}
                      </Typography>
                    </div>

                    <Box
                      display={{ xs: "none", sm: "none", md: "block" }}
                      style={{ flex: 1 }}
                      className={classes.form}
                    >
                      <Typography variant="body2">
                        {splitAddressFull(shift.address.addressFull)}
                      </Typography>
                    </Box>
                    <Chip
                      size="small"
                      icon={
                        shift.status.isPaid ? (
                          <CheckCircleIcon />
                        ) : shift.status.isSubmitted ? (
                          <CheckCircleOutlinedIcon />
                        ) : null
                      }
                      label={
                        shift.status.isPaid
                          ? `Paid`
                          : !isFamily && shift.status.isSubmitted
                          ? "Submitted"
                          : isUnactioned
                          ? "Unactioned"
                          : shift.status.isSubmitted
                          ? "Submitted"
                          : shift.status.isCompleted
                          ? "Completed"
                          : shift.status.isCheckedIn
                          ? "Checked In"
                          : "Pending"
                      }
                      className={
                        isUnactioned
                          ? classes.chipRedOutlined
                          : shift.status.isPaid
                          ? classes.chipGreenOutlined
                          : shift.status.isSubmitted
                          ? classes.chipBlueOutlined
                          : shift.status.isCompleted
                          ? classes.chipGreenOutlined
                          : shift.status.isCheckedIn
                          ? classes.chipBlueOutlined
                          : classes.chipOrjOutlined
                      }
                      style={{ width: 100 }}
                    />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ marginTop: 16, width: "100%" }}>
                  <ShiftAccordionDetails
                    shift={shift}
                    isUnactioned={isUnactioned}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  }
}

ShiftAccordion.propTypes = {
  classes: PropTypes.object.isRequired,
  filteredShifts: PropTypes.array.isRequired,
  shiftId: PropTypes.string,
};

export default withStyles(styles)(ShiftAccordion);
