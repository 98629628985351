import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import { capitalizeFirstChar } from "../../../../util/utilFunctions";
import OutlinedProfilePic from "../../../../components/layout/OutlinedProfilePic";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class WelcomeHeader extends Component {
  render() {
    const { credentials, classes } = this.props;
    return (
      <Box
        className={classes.flexBoxCenteredColumn}
        sx={{ backgroundColor: "primary.light", padding: 2, paddingBottom: 1 }}
        style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8 }}
      >
        <OutlinedProfilePic
          link
          to="/profile"
          imgSrc={credentials.imgUrlThumb}
          alt="profile pic"
          color="rgb(255,255,255)"
          size={60}
        />
        <Typography style={{ marginTop: 12, fontWeight: 500 }}>
          {capitalizeFirstChar(credentials.firstName)}{" "}
          {capitalizeFirstChar(credentials.lastName)}
        </Typography>
      </Box>
    );
  }
}

WelcomeHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default withStyles(styles)(WelcomeHeader);
