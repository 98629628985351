import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import clsx from "clsx";

import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import MuiIconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Icon imports
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackwarddIosIcon from "@mui/icons-material/ArrowBackIos";

dayjs.extend(weekOfYear);

const styles = (theme) => ({
  ...theme.spreadThis,
  cssIconButton: {
    height: 20,
    width: 20,
    "& svg": {
      fontSize: 20,
    },
  },
});

export class WeekSelector extends Component {
  state = {
    currDate: dayjs().toISOString(),
    weekStart: dayjs().startOf("week").toISOString(),
    weekEnd: dayjs().endOf("week").toISOString(),
    weekOfYear: dayjs().week(),
  };
  componentDidMount() {
    const { weekStart, weekEnd } = this.state;
    if (this.props.setOnLoad) this.props.setWeekData(weekStart, weekEnd);
  }

  static getDerivedStateFromProps(props) {
    if (Boolean(props.derived)) {
      return {
        weekStart: props.weekStart,
        weekEnd: props.weekEnd,
        currDate: props.weekEnd,
      };
    }
    return null;
  }

  handleToday = () => {
    const todayObj = {
      currDate: dayjs().toISOString(),
      weekStart: dayjs(this.currDate).startOf("week").toISOString(),
      weekEnd: dayjs(this.currDate).endOf("week").toISOString(),
      weekOfYear: dayjs().week(),
    };
    if (!this.props.derived) this.setState(todayObj);
    this.props.setWeekData(todayObj.weekStart, todayObj.weekEnd);
  };

  handlePrevWeek = () => {
    const { currDate } = this.state;
    const newCurrDate = dayjs(currDate).subtract(7, "d");
    const weekStart = dayjs(newCurrDate).startOf("week").toISOString();
    const weekEnd = dayjs(newCurrDate).endOf("week").toISOString();
    const weekOfYear = dayjs(newCurrDate).week();

    if (!this.props.derived)
      this.setState({ currDate: newCurrDate, weekStart, weekEnd, weekOfYear });
    this.props.setWeekData(weekStart, weekEnd);
  };

  handleNextWeek = () => {
    const { currDate } = this.state;
    const newCurrDate = dayjs(currDate).add(7, "d");
    const weekStart = dayjs(newCurrDate).startOf("week").toISOString();
    const weekEnd = dayjs(newCurrDate).endOf("week").toISOString();
    const weekOfYear = dayjs(newCurrDate).week();

    this.setState({ currDate: newCurrDate, weekStart, weekEnd, weekOfYear });
    this.props.setWeekData(weekStart, weekEnd);
  };
  render() {
    const { weekStart, weekEnd } = this.state;
    const { showTodayButton, classes, disabled } = this.props;
    return (
      <Box display="flex" alignItems="center">
        {showTodayButton && (
          <Button
            variant={
              dayjs().isAfter(weekStart) && dayjs().isBefore(weekEnd)
                ? "contained"
                : "outlined"
            }
            size="small"
            color="secondary"
            style={{
              borderRadius: 25,
              textTransform: "none",
              marginRight: 8,
              boxShadow: "none",
            }}
            onClick={this.handleToday}
          >
            Today
          </Button>
        )}
        <Card
          style={{
            borderRadius: 25,
            padding: "4px 8px",
            width: 190,
          }}
          className={clsx(classes.darkGrayBorderOnly, classes.flexBoxSpaced)}
        >
          <MuiIconButton
            className={classes.cssIconButton}
            onClick={this.handlePrevWeek}
            disabled={disabled}
          >
            <ArrowBackwarddIosIcon color={disabled ? "disabled" : "primary"} />
          </MuiIconButton>
          <div className={classes.form} style={{ margin: "0px 8px" }}>
            <Typography
              variant="caption"
              component="div"
              color={disabled ? "textSecondary" : "textPrimary"}
            >
              {dayjs(weekEnd).format("YYYY")}
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              color={disabled ? "textSecondary" : "textPrimary"}
            >
              {dayjs(weekStart).format("MMM DD")} -{" "}
              {dayjs(weekEnd).format("MMM DD")}
            </Typography>
          </div>
          <MuiIconButton
            className={classes.cssIconButton}
            onClick={this.handleNextWeek}
            disabled={disabled}
          >
            <ArrowForwardIosIcon color={disabled ? "disabled" : "primary"} />
          </MuiIconButton>
        </Card>
      </Box>
    );
  }
}

WeekSelector.propTypes = {
  setWeekData: PropTypes.func.isRequired,
  showTodayButton: PropTypes.bool,
  setOnLoad: PropTypes.bool,
};

export default withStyles(styles)(WeekSelector);
