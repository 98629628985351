import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import PropTypes from "prop-types";
import geohash from "ngeohash";

// MUI imports
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputAdornment from "@mui/material/InputAdornment";
import PublicIcon from "@mui/icons-material/Public";

const styles = (theme) => ({
  ...theme.spreadThis,
  autocompleteDropdownContainer: {
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    position: "absolute",
    width: "100%",
    zIndex: 1000,
  },
});

class LocationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _addressFull: "",
      addressFull: "",
      addressParams: {
        unit: "",
        streetNumber: "",
        streetRoute: "",
        locality: "",
        adminAreaLevel1: "",
        country: "",
        postalCode: "",
      },
      coordinates: {},
      geohash: "",
      isSelectedAddress: false,
    };
  }
  componentDidMount() {
    if (this.props.addressSetInitial) {
      this.setState({
        isSelectedAddress: true,
        _addressFull: this.props.address.addressFull,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.addressSetInitial && this.props.addressSetInitial) {
      this.setState({
        isSelectedAddress: true,
        _addressFull: this.props.address.addressFull,
      });
    }
  }

  handleChange = (_addressFull) => {
    this.setState({ _addressFull });
    if (this.state.isSelectedAddress) {
      this.props.onSelectAddress({
        addressFull: "",
        addressParams: {
          locality: null,
          adminAreaLevel1: null,
        },
        geohash: null,
      });
    }
  };

  handleSelect = async (v) => {
    try {
      const _results = await geocodeByAddress(v);
      const results = _results[0];
      let addressData = {
        addressParams: {},
      };
      const addressFull = results.formatted_address;
      const _addressParams = results.address_components;
      _addressParams.forEach((param) => {
        let type = param.types[0];
        let shortName = param.short_name;
        switch (type) {
          case "subpremise":
            addressData.addressParams.unitInfo = shortName
              .match(/\d/g, "")
              .join("");
            break;
          case "street_number":
            addressData.addressParams.streetNumber = shortName.toLowerCase();
            break;
          case "route":
            addressData.addressParams.streetRoute = shortName.toLowerCase();
            break;
          case "locality":
            addressData.addressParams.locality = shortName.toLowerCase();
            break;
          case "administrative_area_level_1":
            addressData.addressParams.adminAreaLevel1 = shortName.toLowerCase();
            break;
          case "country":
            addressData.addressParams.country = shortName.toLowerCase();
            break;
          case "postal_code":
            addressData.addressParams.postalCode = shortName.toLowerCase();
            break;
          default:
            break;
        }
      });
      addressData.addressFull = addressFull;
      addressData._addressFull = addressFull;

      const latLng = await getLatLng(results);
      const hash = geohash.encode(latLng.lat, latLng.lng, 9);
      addressData.coordinates = {
        lat: latLng.lat,
        lng: latLng.lng,
      };
      addressData.geohash = hash;
      addressData.isSelectedAddress = true;

      this.setState({ ...addressData });
      delete addressData._addressFull;
      delete addressData.isSelectedAddress;
      this.props.onSelectAddress(addressData);
    } catch (err) {
      console.error(err);
      return;
    }
  };

  render() {
    const {
      classes,
      errorAddress,
      label,
      placeholder,
      medium,
      style,
      city,
      straightRight,
      showOneResult,
    } = this.props;

    const { _addressFull } = this.state;
    const searchOptions = {
      types: ["(regions)"],
      componentRestrictions: { country: "ca" },
    };

    let placeholderText = placeholder ? placeholder : "City or postal code ...";

    return (
      <PlacesAutocomplete
        value={_addressFull}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={
          city ? searchOptions : { componentRestrictions: { country: "ca" } }
        }
        debounce={200}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ borderRadius: 10 }}>
            <TextField
              value={_addressFull}
              label={
                label ? (label === " " ? "" : label) : "City or postal code"
              }
              style={
                style
                  ? style
                  : {
                      backgroundColor: "white",
                      borderRadius: 10,
                    }
              }
              error={errorAddress}
              helperText={
                errorAddress
                  ? "Please enter a valid address and select from one of the options below."
                  : null
              }
              variant="outlined"
              size={medium ? "medium" : "small"}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PublicIcon />
                  </InputAdornment>
                ),
              }}
              {...getInputProps({
                placeholder: placeholderText,
                className: straightRight
                  ? !errorAddress
                    ? classes.styledTextFieldRight
                    : classes.styledTextFieldErrorRight
                  : !errorAddress
                  ? classes.styledTextField
                  : classes.styledTextFieldError,
              })}
            />
            <List style={{ padding: 0 }}>
              <div className={classes.autocompleteDropdownContainer}>
                {suggestions
                  .slice(0, showOneResult ? 1 : 5)
                  .map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={suggestion.description}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <LocationOnIcon />
                          </ListItemIcon>
                          <ListItemText primary={suggestion.description} />
                        </ListItem>
                      </div>
                    );
                  })}
              </div>
            </List>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

LocationSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LocationSearch);
