import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";

// Redux imports
import { connect } from "react-redux";
import {
  setAlert,
  setLoadingUISpecial,
  stopLoadingUISpecial,
} from "../../redux/actions/uiActions";
import { updateDataArrayId } from "../../redux/actions/dataActions";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ReviewInquireDialog extends Component {
  state = { open: false };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    this._setInitialOpen();
  }

  _setInitialOpen() {
    if (this.props.initialOpen) this.setState({ open: true });
  }

  _closeDialog = (event, reason) => {
    if (reason !== "clickaway") this.setState({ open: false });
  };

  // Action methods
  _delayReviewDate = async () => {
    this.props.setLoadingUISpecial();
    try {
      const { teamMember } = this.props;
      const reviewData = await axios.get(`/review/extend/${teamMember.id}`);
      const updatedTeamMember = {
        ...teamMember,
        review: reviewData.data,
      };
      this.props.updateDataArrayId(updatedTeamMember, "members", teamMember.id);
    } catch (err) {
      if (Boolean(err.response.data.error))
        this.props.setAlert({
          message: err.response.data.error,
          type: "error",
        });
    } finally {
      this.props.stopLoadingUISpecial();
      this._closeDialog();
    }
  };

  render() {
    const { teamMember, classes, loadingSpecial } = this.props;

    const { open } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this._closeDialog}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Submit a review for{" "}
          {capitalizeFirstChar(teamMember.caregiverInfo.firstName)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reviews allows us to ensure a <b>great experience</b> for both
            families and caregivers. We take rating very serious; caregivers
            with low rating may lose access to Poyo systems.
          </DialogContentText>

          <div className={classes.form} style={{ marginTop: 12 }}>
            <Button
              onClick={this._delayReviewDate}
              className={classes.txtTrButton}
              disabled={loadingSpecial}
            >
              Remind me later
            </Button>
            <Button
              component={Link}
              to={`/reviews/${teamMember.id}`}
              variant="contained"
              className={classes.txtTrButton}
              disabled={loadingSpecial}
              style={{ boxShadow: "none" }}
            >
              Submit Review
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

ReviewInquireDialog.propTypes = {
  initialOpen: PropTypes.bool.isRequired,
  teamMember: PropTypes.object.isRequired,
  loadingSpecial: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loadingSpecial: state.UI.loadingSpecial,
});

const mapActionsToProps = {
  setAlert,
  setLoadingUISpecial,
  stopLoadingUISpecial,
  updateDataArrayId,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ReviewInquireDialog));
