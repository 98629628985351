import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Component imports
import CareProfile from "../../../../components/profile/family/caregiverSearch/CareProfile";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Skeleton from "@mui/material/Skeleton";

// Icons imports
import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";

const styles = (theme) => ({
  ...theme.spreadThis,
  linkText: {
    textDecoration: "underline",
    fontWeight: 350,
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: 550,
    },
  },
  iconTextSecondary: {
    fontSize: 56,
    color: theme.palette.text.secondary,
  },
  reactiveCenterFlexStartContainer: {
    justifyContent: "flex-start",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
});

const CSSTab = withStyles((theme) => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    border: "1px solid rgba(217,217,217)",
  },
  selected: {
    backgroundColor: "rgba(212,212,212,0.3)",
  },
}))(Tab);

export class DashCgSearch extends Component {
  state = { tabValue: 0 };

  setTabValue = (e, v) => {
    this.setState({ tabValue: v });
  };

  render() {
    const {
      classes,
      isLoadedCaregivers,
      caregivers,
      credentials,
      favouritesDoc,
    } = this.props;
    const { tabValue } = this.state;

    const viewAllCg = !isLoadedCaregivers ? (
      [...new Array(6)].map((v, i) => (
        <Grid
          item
          xs={12}
          sm="auto"
          key={i}
          className={classes.flexBoxCentered}
        >
          <Skeleton
            variant="rectangle"
            height={400}
            width={320}
            style={{ borderRadius: 20 }}
          />
        </Grid>
      ))
    ) : caregivers.length < 1 ? (
      <Grid item xs={12}>
        <SearchIcon className={classes.iconTextSecondary} />
        <Typography color="textSecondary" style={{ fontWeight: 300 }}>
          Oops... It seems we can not find any caregivers based on your job
          requirements at the moment. To view all Poyo Caregivers, click{" "}
          <Box component={Link} to="/caregivers" className={classes.linkText}>
            here
          </Box>
          .
        </Typography>
      </Grid>
    ) : (
      caregivers.map((cg) => (
        <Grid
          item
          xs={12}
          sm="auto"
          key={cg.userIdNumber}
          className={classes.flexBoxCentered}
        >
          <CareProfile cgData={cg} userData={credentials} />
        </Grid>
      ))
    );
    const viewFavourites =
      favouritesDoc.length < 1 ? (
        <Grid item xs={12}>
          <FavoriteIcon className={classes.iconTextSecondary} />
          <Typography color="textSecondary" style={{ fontWeight: 300 }}>
            You do not have any favourited caregivers at the moment.
          </Typography>
        </Grid>
      ) : (
        favouritesDoc.map((cg) => (
          <Grid
            item
            xs={12}
            sm="auto"
            key={cg.userIdNumber}
            className={classes.flexBoxCentered}
          >
            <CareProfile cgData={cg} userData={credentials} />
          </Grid>
        ))
      );

    return (
      <Paper elevation={4} className={classes.paperContainerC}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h6" style={{ fontWeight: 300, flex: 1 }}>
            Poyo Caregivers based on your profile
          </Typography>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.setTabValue}
            style={{ marginLeft: 8 }}
          >
            <CSSTab label="View All" />
            <CSSTab icon={<FavoriteIcon />} />
          </Tabs>
        </Box>
        <Divider />
        <br />
        <Grid
          container
          className={classes.reactiveCenterFlexStartContainer}
          spacing={2}
        >
          {tabValue === 0 && viewAllCg}

          {tabValue === 1 && viewFavourites}
          {isLoadedCaregivers && (
            <Grid item xs={12}>
              <Button
                component={Link}
                to="/caregivers/"
                variant="outlined"
                color="primary"
              >
                Find more caregivers
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
}

DashCgSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoadedCaregivers: PropTypes.bool.isRequired,
  caregivers: PropTypes.array.isRequired,
  credentials: PropTypes.object.isRequired,
  favouritesDoc: PropTypes.array,
};

export default withStyles(styles)(DashCgSearch);
