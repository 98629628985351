import React, { Component } from "react";
import PropTypes from "prop-types";

import { capitalizeFirstChar } from "../../../util/utilFunctions";

// MUI imports
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import NativeSelect from "@mui/material/NativeSelect";

export class MemberSelect extends Component {
  handleSelectFilter = (e) => {
    this.props.onSelectMember(e.target.value);
  };
  render() {
    const { teamMembers, currMemberId, userType, className, style } =
      this.props;

    const isFamily = userType === "family";
    return (
      <FormControl size="small">
        <FormLabel>Select {isFamily ? "Caregiver" : "Client"}: </FormLabel>
        <NativeSelect
          variant="outlined"
          input={<OutlinedInput className={className} />}
          value={currMemberId}
          onChange={this.handleSelectFilter}
          style={style ? style : { minWidth: 160 }}
        >
          <option aria-label="All" value="">
            All
          </option>
          {teamMembers.length > 0 &&
            teamMembers.map((member) => (
              <option value={member.id} key={member.id}>
                {capitalizeFirstChar(
                  isFamily
                    ? member.caregiverInfo.firstName
                    : member.familyInfo.clientFirstName
                )}{" "}
                {capitalizeFirstChar(
                  isFamily
                    ? member.caregiverInfo.lastName
                    : member.familyInfo.clientLastName
                )}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
    );
  }
}

MemberSelect.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  onSelectMember: PropTypes.func.isRequired,
  currMemberId: PropTypes.string.isRequired,
};

export default MemberSelect;
