import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Redux imports
import { connect } from "react-redux";
import { updateDataArray } from "../../../redux/actions/dataActions";
import { setAlert } from "../../../redux/actions/uiActions";

// Component imports
import {
  isEmpty,
  capitalizeFirstChar,
  isPhoneNum,
} from "../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

// Icon imports
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import EmergencyContactDisplay from "../../shiftsTasks/member/EmergencyContactDisplay";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditIcon from "@mui/icons-material/Edit";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class MemberWarningButton extends Component {
  state = {
    open: false,
    emergencyContacts: [],
    name: "",
    lastName: "",
    phoneNum: "",
    relationship: "",
    specifyRelationship: "",
    errors: {},
    disableWhileLoad: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI methods
  handleOpenDialog = async () => {
    if (this.props.defaultValue)
      await this.setStateAsync({ emergencyContacts: this.props.defaultValue });

    this.setState({ open: true });
  };

  handleCloseDialog = () => {
    this.setState({ open: false });
  };

  // Error methods
  setCustomErrorMessage = (name) => {
    let errorMsg = "";
    switch (name) {
      case "name":
        errorMsg = "Please input a valid name";
        break;
      case "specifyRelationship":
        errorMsg = "Must specify a valid relationship";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateErrors = () => {
    const { name, phoneNum, relationship, specifyRelationship } = this.state;

    const _errors = {};

    if (isEmpty(name)) _errors.name = "Please input a valid name";
    if (isEmpty(phoneNum) || !isPhoneNum(phoneNum))
      _errors.phoneNum = "Please input a valid phone number";

    if (isEmpty(relationship))
      _errors.relationship = "Please select a relationship";
    if (
      ["Other", "Relative"].includes(relationship) &&
      isEmpty(specifyRelationship)
    )
      _errors.specifyRelationship = "Must specify a valid relationship";

    this.setState({ errors: _errors });
    return [
      Object.keys(_errors).length < 1,
      { name, phoneNum, relationship, specifyRelationship, primary: false },
    ];
  };

  // Action methods

  handleChangeInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    this.setState({
      specifyRelationship:
        name === "relationship" && ["Other", "Relative"].includes(value)
          ? ""
          : this.state.specifyRelationship,
      [name]: value,

      errors: {
        ...this.state.errors,
        specifyRelationship:
          name === "relationship" &&
          ["Other", "Relative"].includes(value) &&
          null,
        [name]: isEmpty(value) && this.setCustomErrorMessage(name),
      },
    });
  };

  handlePhoneNumber = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      this.setState({
        phoneNum: onlyNums,
        errors: {
          ...this.state.errors,
          phoneNum: isEmpty(onlyNums) ? "Must not be empty" : null,
        },
      });
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      this.setState({
        phoneNum: number,
        errors: { ...this.state.errors, phoneNum: null },
      });
    }
  };

  handleSaveContact = async (e) => {
    e.preventDefault();
    const [isValid, emergencyContact] = await this.validateErrors();
    if (isValid) {
      const emergencyContacts = [...this.state.emergencyContacts];
      emergencyContacts.push(emergencyContact);
      emergencyContacts[0].primary = true;
      this.setState({
        emergencyContacts,
        name: "",
        relationship: "",
        specifyRelationship: "",
        phoneNum: "",
      });
    }
  };

  handleDeleteContact = (ind) => {
    const emergencyContacts = [...this.state.emergencyContacts];

    emergencyContacts.splice(ind, 1);
    if (emergencyContacts.length > 0) emergencyContacts[0].primary = true;

    this.setState({ emergencyContacts });
  };

  // Submit methods
  handleSubmitEmergencyContact = async () => {
    const emergencyContacts = this.state.emergencyContacts;
    await this.setStateAsync({ disableWhileLoad: true });

    try {
      const memberDoc = await axios.post(
        `/team/member/${this.props.member.id}/update/emergency-contact`,
        { emergencyContacts }
      );

      this.props.updateDataArray(memberDoc.data, "members", this.props.index);
      this.props.setAlert({
        message: "Successfully added emergency contacts",
        type: "success",
      });
      if (this.props.onUpdate) this.props.onUpdate();
      await this.setStateAsync({ open: false });
      await this.setStateAsync({ disableWhileLoad: false });
    } catch (err) {
      this.props.setAlert({
        message: "An error has occured while updating your emergency contact.",
        type: "error",
      });
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const { classes, member, fullButton, defaultValue, small } = this.props;
    const {
      open,
      name,
      phoneNum,
      errors,
      emergencyContacts,
      relationship,
      specifyRelationship,
      disableWhileLoad,
    } = this.state;
    return (
      <Fragment>
        {fullButton ? (
          <Button
            onClick={this.handleOpenDialog}
            color={Boolean(defaultValue) ? "primary" : "secondary"}
            startIcon={
              Boolean(defaultValue) ? (
                open ? (
                  <EditIcon />
                ) : (
                  <EditOutlinedIcon />
                )
              ) : (
                <WarningIcon />
              )
            }
            style={{ borderRadius: 25 }}
            size={small ? "small" : "medium"}
          >
            {Boolean(defaultValue)
              ? "Update Contacts"
              : "Missing Emergency Contacts"}
          </Button>
        ) : (
          <IconButton
            onClick={this.handleOpenDialog}
            color={Boolean(defaultValue) ? "primary" : "secondary"}
            size={small ? "small" : "medium"}
          >
            {Boolean(defaultValue) ? (
              open ? (
                <EditIcon />
              ) : (
                <EditOutlinedIcon />
              )
            ) : (
              <WarningIcon />
            )}
          </IconButton>
        )}

        <Dialog
          open={open}
          onClose={this.handleCloseDialog}
          fullWidth
          maxWidth="sm"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.headerBarRounded}
          >
            <Typography variant="h6">Missing Emergency Contact</Typography>
            <IconButton size="small" onClick={this.handleCloseDialog}>
              <CancelIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <Typography color="textSecondary">
              In case of an emergency,{" "}
              <Typography
                component="span"
                color="textPrimary"
                style={{ textDecoration: "underline" }}
              >
                at least 2 emergency contacts
              </Typography>{" "}
              must be on file for{" "}
              {capitalizeFirstChar(member.caregiverInfo.firstName)} to reach
              out. Add your contacts below.
            </Typography>

            {emergencyContacts.length > 0 &&
              emergencyContacts.map((emergencyContact, ind) => (
                <EmergencyContactDisplay
                  emergencyContact={emergencyContact}
                  ind={ind}
                  key={ind}
                  onDelete={this.handleDeleteContact}
                  showDelete
                />
              ))}

            {emergencyContacts.length < 1 && (
              <Typography
                variant="body2"
                align="center"
                color="error"
                style={{ margin: "4px 0px" }}
              >
                Missing Emergency Contact 1
              </Typography>
            )}
            {emergencyContacts.length < 2 && (
              <Typography
                variant="body2"
                align="center"
                color="error"
                style={{ margin: "4px 0px" }}
              >
                Missing Emergency Contact 2
              </Typography>
            )}

            {emergencyContacts.length < 2 && (
              <Fade
                in={emergencyContacts.length < 2}
                mountOnEnter
                unmountOnExit
              >
                <form onSubmit={this.handleSaveContact}>
                  <Grid container spacing={1} style={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Name</InputLabel>
                        <TextField
                          id="name"
                          name="name"
                          type="name"
                          variant="outlined"
                          error={Boolean(errors.name)}
                          helperText={Boolean(errors.name) ? errors.name : ""}
                          className={
                            !Boolean(errors.name)
                              ? classes.styledTextField
                              : classes.styledTextFieldError
                          }
                          style={{ margin: 0 }}
                          onChange={this.handleChangeInput}
                          fullWidth
                          value={name}
                          size="small"
                          autoFocus
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        error={Boolean(errors.relationship)}
                        fullWidth
                        size="small"
                      >
                        <InputLabel>Relationship</InputLabel>
                        <Select
                          native
                          value={relationship}
                          name="relationship"
                          variant="outlined"
                          fullWidth
                          className={
                            !Boolean(errors.relationship)
                              ? classes.styledTextField
                              : classes.styledTextFieldError
                          }
                          onChange={this.handleChangeInput}
                          style={{ margin: 0 }}
                        >
                          <option aria-label="None" value="">
                            - Relationship -
                          </option>
                          {[
                            "Parent",
                            "Sibling",
                            "Children",
                            "Grandchildren",
                            "Relative",
                            "Friend",
                            "Other",
                          ].map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </Select>
                        {Boolean(errors.relationship) && (
                          <FormHelperText
                            error
                            style={{ margin: "4px 14px 0px 14px" }}
                          >
                            {errors.relationship}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse
                        in={["Other", "Relative"].includes(relationship)}
                      >
                        <FormControl
                          error={Boolean(errors.relationship)}
                          fullWidth
                          size="small"
                        >
                          <InputLabel>Specify Relationship</InputLabel>
                          <TextField
                            id="specifyRelationship"
                            name="specifyRelationship"
                            type="specifyRelationship"
                            variant="outlined"
                            error={Boolean(errors.specifyRelationship)}
                            helperText={
                              Boolean(errors.specifyRelationship)
                                ? errors.specifyRelationship
                                : ""
                            }
                            className={
                              !Boolean(errors.specifyRelationship)
                                ? classes.styledTextField
                                : classes.styledTextFieldError
                            }
                            style={{ margin: 0 }}
                            onChange={this.handleChangeInput}
                            fullWidth
                            value={specifyRelationship}
                            size="small"
                          />
                        </FormControl>
                      </Collapse>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        error={Boolean(errors.relationship)}
                        fullWidth
                        size="small"
                      >
                        <InputLabel>Phone Number</InputLabel>
                        <TextField
                          id="phoneNum"
                          name="phoneNum"
                          type="tel"
                          variant="outlined"
                          error={Boolean(errors.phoneNum)}
                          helperText={
                            Boolean(errors.phoneNum) ? errors.phoneNum : ""
                          }
                          className={
                            !Boolean(errors.phoneNum)
                              ? classes.styledTextField
                              : classes.styledTextFieldError
                          }
                          style={{ margin: 0 }}
                          onChange={this.handlePhoneNumber}
                          fullWidth
                          value={phoneNum}
                          size="small"
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={classes.flexBox}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        disabled={disableWhileLoad}
                        startIcon={<AddIcon />}
                        type="submit"
                        color="primary"
                      >
                        Add Contact
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Fade>
            )}

            <Fade in={emergencyContacts.length > 1} mountOnEnter unmountOnExit>
              <Button
                onClick={this.handleSubmitEmergencyContact}
                variant="contained"
                style={{ borderRadius: 25, boxShadow: "none", marginTop: 8 }}
                color="primary"
                size="small"
                fullWidth
                disabled={disableWhileLoad || emergencyContacts.length < 2}
              >
                Save{" "}
                {disableWhileLoad && (
                  <CircularProgress size={25} className={classes.progress} />
                )}
              </Button>
            </Fade>

            <Typography
              variant="body2"
              style={{ marginTop: 8, fontWeight: 300 }}
            >
              Note: It is standard proceedure for your caregiver to call
              emergency services prior to reaching out to emergency contacts.
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              style={{ borderRadius: 25 }}
              color="primary"
              size="small"
              disabled={disableWhileLoad}
            >
              Back
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

MemberWarningButton.propTypes = {
  classes: PropTypes.object.isRequired,
  updateDataArray: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  fullButton: PropTypes.bool,
  onUpdate: PropTypes.func,
  defaultValue: PropTypes.array,
  small: PropTypes.bool,
};

export default connect(null, { updateDataArray, setAlert })(
  withStyles(styles)(MemberWarningButton)
);
