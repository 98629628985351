import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Redirect } from "react-router-dom";

// Component Imports
import InvoiceItem from "../../../components/pay/invoice/InvoiceItem";
import LoadingEllipsis from "../../../components/layout/LoadingEllipsis";

// Redux imports
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function CSSBox(props) {
  return <Box p={2} display="flex" justifyContent="center" {...props} />;
}
export class invoiceDisplay extends Component {
  state = {
    invoiceData: {},
    inLoadedInvoice: false,
    errorUnfound: false,
    errorUnauthorized: false,
    timer: 10,
  };

  componentDidMount() {
    this.getInvoiceData();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
    if (this.state.setRedirectInterbal) {
      clearInterval(this.state.setRedirectInterbal);
    }
  }

  async getInvoiceData() {
    const invoiceId = this.props.match.params.invoiceId;
    try {
      const _invoiceData = await axios.get(`/invoice/get/${invoiceId}`);
      const invoiceData = _invoiceData.data;
      if (!invoiceData.status.isPaid) {
        this.props.history.push(`/mypay/invoice/${invoiceData.id}`);
      } else {
        this.setState({ invoiceData, isLoadedInvoice: true });
      }
    } catch (err) {
      const errorData = err.response.data;
      if (errorData.error && errorData.error === "Unauthorized") {
        this.setState({ errorUnauthorized: true });
      } else {
        this.setState({ errorUnfound: true });
      }
      this.setState({ isLoadedInvoice: true });

      this.setRedirect();
      return;
    }
  }

  setRedirect() {
    const setRedirectInterbal = setInterval(
      () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
      1000
    );
    this.setState({ setRedirectInterbal });
  }

  render() {
    const {
      classes,
      credentials: { userType },
    } = this.props;
    const {
      invoiceData,
      isLoadedInvoice,
      errorUnauthorized,
      errorUnfound,
      timer,
    } = this.state;

    if (timer === 0) return <Redirect to="/dashboard" />;
    return (
      <div className="res-gr-con">
        {!isLoadedInvoice ? (
          <CSSBox>
            <div style={{ textAlign: "center" }}>
              <CircularProgress thickness={2} size={70} />
              <LoadingEllipsis
                prefix="Loading Invoice"
                style={{ marginTop: 10 }}
              />
            </div>
          </CSSBox>
        ) : errorUnauthorized ? (
          <CSSBox>
            <div style={{ textAlign: "center" }}>
              <WarningIcon color="secondary" className={classes.iconStyle} />
              <Typography color="textSecondary">
                Uh oh! It seems you are not authorized to view this invoice.
                Please verify the Invoice ID or try again.
              </Typography>
              <Typography color="textSecondary" style={{ marginTop: 30 }}>
                Redirecting to dashboard in.......{timer}.
              </Typography>
            </div>
          </CSSBox>
        ) : errorUnfound ? (
          <CSSBox>
            <div style={{ textAlign: "center" }}>
              <SearchIcon color="secondary" className={classes.iconStyle} />
              <Typography color="textSecondary">
                Uh oh! It seems we can not locate this invoice.
              </Typography>
              <Typography color="textSecondary" style={{ marginTop: 30 }}>
                Redirecting to dashboard in.......{timer}.
              </Typography>
            </div>
          </CSSBox>
        ) : (
          <InvoiceItem
            invoiceData={invoiceData}
            isFamily={userType === "family"}
          />
        )}
      </div>
    );
  }
}

invoiceDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps)(withStyles(styles)(invoiceDisplay));
