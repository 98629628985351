import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Redux imports
import { connect } from "react-redux";
import { updateDataArray } from "../../../redux/actions/dataActions";
import { setAlert } from "../../../redux/actions/uiActions";

// Component imports
import ChipArrayDisplay from "../../profile/ChipArrayDisplay";
import { capitalizeFirstChar, isEmpty } from "../../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Switch from "@mui/material/Switch";

// Icons import
import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class Careplan extends Component {
  state = {
    isEdit: false,
    showCareplan: false,
    disableWhileLoad: false,
    careplan: [],
    careItem: "",
    confirmDialogOpen: false,
    applyExistingShifts: true,
    isUpdated: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  // UI methods
  handleShowCareplan = () => {
    this.setState({ showCareplan: !this.state.showCareplan });
  };

  handleEditService = () => {
    if (this.state.isEdit)
      this.setState({
        isEdit: false,
        isUpdated: false,
        careItem: "",
        confirmDialogOpen: false,
      });
    else
      this.setState({
        isEdit: true,
        showCareplan: true,
        careplan: this.props.member.service.map((service) =>
          capitalizeFirstChar(service.split(/(?=[A-Z])/).join(" "))
        ),
      });
  };
  handleOpenConfirmDialog = () => {
    this.setState({ confirmDialogOpen: true });
  };

  handleCloseConfirmDialog = () => {
    this.setState({ confirmDialogOpen: false });
  };

  // Action methods
  handleCareItem = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddCareItem = (e) => {
    e.preventDefault();
    const careplan = [...this.state.careplan];
    careplan.push(this.state.careItem);
    this.setState({ careplan, isUpdated: true, careItem: "" });
  };

  handleSwitchShifts = (e) => {
    this.setState({ applyExistingShifts: e.target.checked });
  };

  handleDelete = (ind) => {
    const careplan = [...this.state.careplan];
    careplan.splice(ind, 1);
    this.setState({ careplan, isUpdated: true });
  };

  // Subit methods
  handleSave = async () => {
    const newCareplan = { service: this.state.careplan };
    const applyExistingShifts = this.state.applyExistingShifts;

    await this.setStateAsync({ disableWhileLoad: true });
    try {
      const memberDoc = await axios.post(
        `/team/member/${this.props.member.id}/update${
          applyExistingShifts ? "/include-shifts" : ""
        }`,
        newCareplan
      );
      this.props.updateDataArray(memberDoc.data, "members", this.props.index);
      this.props.setAlert({
        message: "Successfully updated care plan",
        type: "success",
      });
      if (this.props.onUpdate) this.props.onUpdate();
      await this.handleEditService();
    } catch (err) {
      this.props.setAlert({
        message: "An error has occured while updating your care plan.",
        type: "error",
      });
    } finally {
      this.setState({ disableWhileLoad: false });
    }
  };

  render() {
    const { classes, member, isFamily } = this.props;
    const {
      isEdit,
      showCareplan,
      disableWhileLoad,
      careplan,
      careItem,
      confirmDialogOpen,
      applyExistingShifts,
      isUpdated,
    } = this.state;
    return (
      <Box>
        <Box display="flex">
          {member.service.length > 0 ? (
            <Button
              startIcon={showCareplan ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              size="small"
              onClick={this.handleShowCareplan}
              style={{ borderRadius: 25, flex: 1, textTransform: "none" }}
            >
              <Typography style={{ fontWeight: 300 }}>Care Plan</Typography>
            </Button>
          ) : (
            <Typography align="center" style={{ fontWeight: 300, flex: 1 }}>
              Care Plan
            </Typography>
          )}
          {isFamily && (
            <IconButton
              size="small"
              color="primary"
              onClick={this.handleEditService}
            >
              {member.service.length > 0 ? (
                isEdit ? (
                  <EditIcon />
                ) : (
                  <EditOutlinedIcon />
                )
              ) : (
                <AddIcon />
              )}
            </IconButton>
          )}
        </Box>
        <Collapse in={showCareplan}>
          <Box
            className={classes.sectionBoxLeftOutline}
            style={{ paddingTop: 8, paddingBottom: 8 }}
          >
            {isEdit ? (
              <Box>
                {careplan.map((_service, ind) => (
                  <Chip
                    className={classes.chip}
                    style={{ marginLeft: 0 }}
                    label={_service}
                    size="small"
                    key={ind}
                    onDelete={() => this.handleDelete(ind)}
                    disabled={disableWhileLoad}
                    clickable
                    onClick={() => this.handleDelete(ind)}
                  />
                ))}
                <Box style={{ margin: "8px 0px" }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Add item to care plan:
                  </Typography>
                  <form
                    onSubmit={this.handleAddCareItem}
                    className={classes.flexBox}
                  >
                    <TextField
                      value={careItem}
                      name="careItem"
                      variant="outlined"
                      className={classes.styledTextField}
                      onChange={this.handleCareItem}
                      style={{ margin: 0 }}
                      fullWidth
                      size="small"
                    />
                    <Button
                      startIcon={<AddIcon />}
                      style={{ height: 40, marginLeft: 4 }}
                      color="primary"
                      disabled={isEmpty(careItem) || disableWhileLoad}
                      type="submit"
                    >
                      Add
                    </Button>
                  </form>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={this.handleEditService}
                    size="small"
                    color="primary"
                    style={{ borderRadius: 25 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.handleOpenConfirmDialog}
                    disabled={!isUpdated || disableWhileLoad}
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ borderRadius: 25, boxShadow: "none" }}
                  >
                    Save
                  </Button>
                </Box>
                <Dialog
                  open={confirmDialogOpen}
                  onClose={this.handleCloseConfirmDialog}
                  fullWidth
                  maxWidth="xs"
                >
                  <DialogTitle>Confirm Careplan</DialogTitle>
                  <DialogContent>
                    <Typography>
                      Do you want to apply the new care plan to currently
                      scheduled shifts or new shifts only?
                    </Typography>
                    <Box
                      className={classes.flexBoxCentered}
                      style={{ margin: "8px 0px" }}
                    >
                      <Typography
                        align="right"
                        color={
                          !applyExistingShifts ? "primary" : "textSecondary"
                        }
                        variant="subtitle2"
                        style={{ width: 150 }}
                      >
                        New shifts only
                      </Typography>
                      <Switch
                        checked={applyExistingShifts}
                        onChange={this.handleSwitchShifts}
                        color="primary"
                      />
                      <Typography
                        color={
                          applyExistingShifts ? "primary" : "textSecondary"
                        }
                        variant="subtitle2"
                        style={{ width: 150 }}
                      >
                        Include existing shifts
                      </Typography>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      style={{ borderRadius: 25 }}
                      onClick={this.handleCloseConfirmDialog}
                      size="small"
                      disabled={disableWhileLoad}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ borderRadius: 25, boxShadow: "none" }}
                      onClick={this.handleSave}
                      size="small"
                      disabled={disableWhileLoad}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            ) : (
              <ChipArrayDisplay color="primary" dataArray={member.service} />
            )}
          </Box>
        </Collapse>
      </Box>
    );
  }
}

Careplan.propTypes = {
  classes: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  index: PropTypes.number,
  isFamily: PropTypes.bool.isRequired,
  updateDataArray: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { updateDataArray, setAlert })(
  withStyles(styles)(Careplan)
);
