import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

// Component imports

import {
  capitalizeFirstChar,
  splitAddressFull,
} from "../../../util/utilFunctions";
import ChipArrayDisplay from "../../profile/ChipArrayDisplay";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

// Icon imports
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function LightTypography(props) {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      style={{ fontWeight: 300, marginLeft: 8 }}
      {...props}
    />
  );
}

function MainTypography(props) {
  return (
    <Typography
      variant="body2"
      component="span"
      color="textPrimary"
      {...props}
    />
  );
}

function ShiftInfoContainer(props) {
  const { classes, shift } = props;
  return (
    <Box style={{ marginTop: 8, width: "100%" }}>
      <Box
        className={classes.flexBoxSpaced}
        style={{ alignItems: "flex-start" }}
      >
        <Box style={{ marginRight: 8 }}>
          <Typography style={{ fontWeight: 300 }}>Shift Info:</Typography>
          <Box className={classes.flexBox} style={{ margin: "8px 0px" }}>
            <PersonIcon style={{ opacity: 0.7 }} />
            <LightTypography>
              Client Name:{" "}
              <MainTypography>
                {capitalizeFirstChar(shift.familyInfo.clientFirstName)}{" "}
                {capitalizeFirstChar(shift.familyInfo.clientLastName)}
              </MainTypography>
            </LightTypography>
          </Box>

          <Box className={classes.flexBox} style={{ margin: "8px 0px" }}>
            <LocationOnIcon style={{ opacity: 0.7 }} />
            <LightTypography>
              Address:{" "}
              <MainTypography>
                {splitAddressFull(shift.address.addressFull)}
              </MainTypography>
            </LightTypography>
          </Box>
        </Box>
        <Paper
          className={classes.darkGrayBorder}
          style={{
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        >
          <Typography variant="subtitle1" color="textSecondary">
            Shift Status
          </Typography>
          <Chip
            label={
              shift.status.isCompleted
                ? "Completed"
                : shift.status.isCheckedIn
                ? "Checked In"
                : "Pending"
            }
            className={
              shift.status.isCompleted
                ? classes.chipGreenOutlined
                : shift.status.isCheckedIn
                ? classes.chipBlueOutlined
                : classes.chipOrjOutlined
            }
            variant="outlined"
          />
          <Typography variant="caption" component="div" color="textSecondary">
            {shift.status.isCompleted
              ? `at ${dayjs(shift.completedAt).format("h:mma")}`
              : shift.status.isCheckedIn
              ? `at ${dayjs(shift.checkedInAt).format("h:mma")}`
              : "Awaiting check-in"}
          </Typography>
        </Paper>
      </Box>
      <Box className={classes.flexBox} style={{ margin: "8px 0px" }}>
        <LocalHospitalIcon style={{ opacity: 0.7 }} />
        <LightTypography>Conditions:</LightTypography>
      </Box>
      <ChipArrayDisplay dataArray={shift.conditions} color="primary" />
      <Box className={classes.flexBox} style={{ margin: "8px 0px" }}>
        <AssignmentIcon style={{ opacity: 0.7 }} />
        <LightTypography>Services:</LightTypography>
      </Box>
      <ChipArrayDisplay dataArray={shift.service} color="primary" />
      <Box className={classes.flexBox} style={{ margin: "8px 0px" }}>
        <LanguageIcon style={{ opacity: 0.7 }} />
        <LightTypography>Language:</LightTypography>
      </Box>
      <ChipArrayDisplay dataArray={shift.languagePref} color="primary" />
    </Box>
  );
}

ShiftInfoContainer.propTypes = {
  shift: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShiftInfoContainer);
