import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

// Component Imports
import { capitalizeFirstChar } from "../../../util/utilFunctions";
import OutlinedProfilePic from "../../layout/OutlinedProfilePic";

// MUI Imports
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";

import GroupIcon from "@mui/icons-material/Group";

export class MemberInfo extends Component {
  render() {
    const {
      currMemberData: { caregiverInfo, familyInfo, createdAt },
      currMemberId,
      userType,
    } = this.props;
    const isFamily = userType === "family";

    return currMemberId !== "" ? (
      <Box display="flex" alignItems="center">
        <OutlinedProfilePic
          imgSrc={isFamily ? caregiverInfo.imgUrlThumb : familyInfo.imgUrlThumb}
          size={60}
          alt="profile picture"
        />
        <Box style={{ margin: "0px 8px" }}>
          <Typography color="textSecondary" style={{ fontWeight: 300 }}>
            {isFamily ? "Poyo Caregiver" : "Client"}
          </Typography>
          <Typography style={{ fontWeight: 300 }}>
            {capitalizeFirstChar(
              isFamily ? caregiverInfo.firstName : familyInfo.clientFirstName
            )}{" "}
            {capitalizeFirstChar(
              isFamily ? caregiverInfo.lastName : familyInfo.clientLastName
            )}
          </Typography>
        </Box>
        <Hidden smDown>
          <Divider orientation="vertical" flexItem />
          <Box style={{ marginLeft: 10 }}>
            <Typography
              component="div"
              color="textSecondary"
              style={{ fontWeight: 300 }}
            >
              Team since
            </Typography>
            <Typography component="div" style={{ fontWeight: 300 }}>
              {dayjs(createdAt).format("MM/DD/YYYY")}
            </Typography>
          </Box>
        </Hidden>
      </Box>
    ) : (
      <Box display="flex" alignItems="center" style={{ height: "100%" }}>
        <GroupIcon color="primary" style={{ fontSize: 60 }} />
        <Typography style={{ marginLeft: 10 }}>
          Showing All Caregivers
        </Typography>
      </Box>
    );
  }
}

MemberInfo.propTypes = {
  currMemberData: PropTypes.object.isRequired,
  currMemberId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};
export default MemberInfo;
