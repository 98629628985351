import React, { Component } from "react";
import { Link } from "react-router-dom";

// Component imports
import SignupCaregiverForm from "../../../loginSignup/caregiver/components/SignupCaregiverForm";

// MUI imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export class JoinBanner extends Component {
  render() {
    return (
      <div className="section-wrapper" style={{ margin: 0, paddingTop: 0 }}>
        <Grid
          container
          spacing={4}
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item xs={12} md={5}>
            <div
              className="flexbox-centered-column "
              style={{ height: "100%", padding: "24px 0px" }}
            >
              <div style={{ width: "100%", position: "relative" }}>
                <div style={{ position: "relative", zIndex: 1 }}>
                  <Typography
                    variant="h3"
                    className="txt-bold-l"
                    style={{ paddingTop: 24 }}
                  >
                    Flexible home care opportunities made easy
                  </Typography>

                  <div className="title-line p-bg-l" />
                  <Typography
                    variant="h6"
                    className="description-txt contrast-txt"
                  >
                    Find local families in need of caregivers with flexible
                    schedules, better pay, and professional development on Poyo.
                  </Typography>
                </div>

                <svg
                  viewBox="0 0 250 250"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    top: 0,
                    right: 0,
                    left: 0,
                    zIndex: 0,
                    transform: "scale3d(1.5,1.5,1)",
                  }}
                >
                  <path
                    fill="rgba(103, 224, 173,0.3)"
                    d="M65.8,-32.4C78.4,-16.2,77,13.7,63.7,30C50.4,46.3,25.2,49.1,4.4,46.5C-16.4,44,-32.8,36.2,-46.7,19.6C-60.6,2.9,-71.9,-22.5,-63.7,-36.2C-55.5,-49.9,-27.7,-51.8,-0.6,-51.5C26.6,-51.1,53.3,-48.6,65.8,-32.4Z"
                    transform="translate(100 100) "
                  />
                </svg>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="flexbox-centered" style={{ paddingTop: 24 }}>
              <div className="display-card-white">
                <div className="flexbox-spaced" style={{ marginBottom: 24 }}>
                  <Typography gutterBottom variant="h6" className="txt-bold">
                    Become a Care Pro
                  </Typography>
                  <Typography
                    component={Link}
                    to="/"
                    gutterBottom
                    color="textPrimary"
                    className="lnk-txt-ul"
                  >
                    Looking for care?
                  </Typography>
                </div>
                <SignupCaregiverForm hideRequirements />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default JoinBanner;
