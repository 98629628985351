import React, { Component } from "react";
import PropTypes from "prop-types";

// Component Imports
import Stepper from "../../../../components/layout/Components/Stepper";
import { allFalse } from "../../../../util/utilFunctions";
import SingleButtonSelectContainer from "./SingleButtonSelectContainer";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class SFMyNeeds extends Component {
  state = {
    errors: {
      errorServiceStart: null,
      errorServicePerWeek: null,
      errorServiceLength: null,
    },
    serviceStart: "",
    servicePerWeek: "",
    serviceLength: "",
    serviceStartObj: {
      immediately: "Immediately",
      "1week": "Within 1 Week",
      "1month": "Within 1 Month",
      "1month+": "1 Month+",
    },

    servicePerWeekObj: {
      under10: "Under 10 hours",
      "10-20": "10 - 20 hours",
      "20+": "20+ hours",
    },
    serviceLengthObj: {
      underOneMonth: "Under 1 Month",
      underThreeMonths: "Under 3 Months",
      underSixMonths: "Under 6 Months",
      overSixMonths: "6 Months+",
    },
  };

  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    const { serviceStart, serviceLength, servicePerWeek } = this.props;
    let obj = {};
    if (serviceStart !== "") obj.serviceStart = serviceStart;
    if (serviceLength !== "") obj.serviceLength = serviceLength;
    if (servicePerWeek !== "") obj.servicePerWeek = servicePerWeek;

    this.setState({ ...obj });
  }

  // Error validation methods

  validateSubmit = () => {
    const { serviceStart, serviceLength, servicePerWeek, errors } = this.state;

    let _errors = errors;
    if (serviceStart === "")
      _errors.errorServiceStart = "Please select one of the options below.";
    if (servicePerWeek === "")
      _errors.errorServicePerWeek = "Please select one of the options below.";
    if (serviceLength === "")
      _errors.errorServiceLength = "Please select one of the options below.";

    this.setState({ errors: _errors });
  };

  // Action Methods

  handleServiceStart = (e) => {
    this.setState({
      serviceStart: e.currentTarget.value,
      errors: { ...this.state.errors, errorServiceStart: null },
    });
  };

  handleServicePerWeek = (e) => {
    this.setState({
      servicePerWeek: e.currentTarget.value,
      errors: { ...this.state.errors, errorServicePerWeek: null },
    });
  };

  handleServiceLength = (e) => {
    this.setState({
      serviceLength: e.currentTarget.value,
      errors: { ...this.state.errors, errorServiceLength: null },
    });
  };

  handleSubmit = async () => {
    await this.validateSubmit();
    const { serviceStart, serviceLength, servicePerWeek, errors } = this.state;

    if (allFalse(errors)) {
      this.props.onSelectMyNeeds({
        serviceStart,
        serviceLength,
        servicePerWeek,
      });
    }
  };

  render() {
    const { classes, total, fadeIn } = this.props;
    const {
      errors,
      serviceStart,
      servicePerWeek,
      serviceLength,
      serviceStartObj,
      servicePerWeekObj,
      serviceLengthObj,
    } = this.state;

    return (
      <Box p={2}>
        <Stepper total={total} fadeIn={fadeIn} />
        <br />
        <Typography
          variant="h6"
          color="primary"
          align="center"
          style={{ margin: "10px 0px", fontWeight: 300 }}
          className={classes.typography}
        >
          My Care Needs
        </Typography>

        <SingleButtonSelectContainer
          valueObj={serviceStartObj}
          error={errors.errorServiceStart}
          value={serviceStart}
          header="When do you require the service?"
          onSelect={this.handleServiceStart}
        />
        <br />
        <SingleButtonSelectContainer
          valueObj={servicePerWeekObj}
          error={errors.errorServicePerWeek}
          value={servicePerWeek}
          header="How many hours of service do you expect to need per week?"
          onSelect={this.handleServicePerWeek}
        />
        <br />
        <SingleButtonSelectContainer
          valueObj={serviceLengthObj}
          error={errors.errorServiceLength}
          value={serviceLength}
          header="How long do you expect to require the service?"
          onSelect={this.handleServiceLength}
        />
        <br />

        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{ width: 100, textTransform: "none" }}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  }
}

SFMyNeeds.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectMyNeeds: PropTypes.func.isRequired,
  serviceStart: PropTypes.string,
  serviceLength: PropTypes.string,
  servicePerWeek: PropTypes.string,
  total: PropTypes.number.isRequired,
  fadeIn: PropTypes.number.isRequired,
};

export default withStyles(styles)(SFMyNeeds);
