import React, { Component } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom";

// Component imports
import { capitalizeFirstChar } from "../../../util/utilFunctions";
import TeamMemberSwipe from "./TeamMemberSwipe";
import ShiftDashItem from "./ShiftDashItem";
import MemberWarningDialogButton from "../../buttons/member/MemberWarningDialogButton";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class TeamShiftContainer extends Component {
  state = {
    currentTeamIndex: 0,
    currentShiftIndex: 0,
    noShifts: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  handleChangeTeamIndex = async (index) => {
    await this.setStateAsync({ currentTeamIndex: index });
    this.setFirstShiftBasedOnTeam(index);
  };

  handleChangeShiftIndex = (index) => {
    this.setState({ currentShiftIndex: index, noShifts: false });
    this.setCurrentTeamBasedOnShift(index);
  };

  setCurrentTeamBasedOnShift = (index) => {
    const { shifts, members } = this.props;

    const memberId = shifts[index].memberId;
    const posInTeam = members.findIndex(
      (teamMember) => teamMember.id === memberId
    );
    if (posInTeam > -1) this.setState({ currentTeamIndex: posInTeam });
  };

  setFirstShiftBasedOnTeam = (index) => {
    const { shifts, members } = this.props;
    const memberId = members[index].id;
    const posInShift = shifts.findIndex((shift) => shift.memberId === memberId);
    if (posInShift > -1) {
      this.setState({ currentShiftIndex: posInShift, noShifts: false });
    } else {
      this.setState({ noShifts: true });
    }
  };

  handleResetShifts = (index) => {
    this.handleChangeShiftIndex(0);
  };

  render() {
    const { members, shifts, classes, isLoadedShifts, isFamily } = this.props;
    const { currentTeamIndex, currentShiftIndex, noShifts } = this.state;

    const currentFamilyShifts = shifts.filter(
      (shift) => shift.memberId === members[currentTeamIndex].id
    );

    return (
      <Box p={1} component={Grid} container style={{ height: "100%" }}>
        {/* // Team member display */}
        <Grid item xs={4} style={{ height: "100%" }}>
          <SwipeableViews
            index={currentTeamIndex}
            onChangeIndex={this.handleChangeTeamIndex}
            enableMouseEvents
            axis="y"
            resistance
            containerStyle={{ height: 169 }}
          >
            {members.map((teamMember, ind) => (
              <TeamMemberSwipe
                teamMember={teamMember}
                isFamily={isFamily}
                currentTeamIndex={currentTeamIndex}
                key={teamMember.id}
                ind={ind}
                numCurrentFamilyShifts={currentFamilyShifts.length}
                isLoadedShifts={isLoadedShifts}
                totalInd={members.length - 1}
                handleChangeTeamIndex={this.handleChangeTeamIndex}
              />
            ))}
          </SwipeableViews>
        </Grid>

        {/* // Shift display */}
        <Grid item xs={8} style={{ height: "100%" }}>
          {isLoadedShifts ? (
            noShifts || shifts.length < 1 ? (
              <SwipeableViews
                enableMouseEvents
                axis="y"
                resistance
                containerStyle={{
                  height: 169,
                }}
              >
                <Box
                  className={classes.flexBoxCentered}
                  style={{
                    height: 169,
                    flexDirection: "column",
                    borderRadius: 10,
                    position: "relative",
                  }}
                >
                  {isFamily &&
                    !members[currentTeamIndex].familyInfo.hasOwnProperty(
                      "emergencyContacts"
                    ) && (
                      <Box
                        className={classes.flexBox}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <MemberWarningDialogButton
                          member={members[currentTeamIndex]}
                          index={currentTeamIndex}
                        />
                      </Box>
                    )}
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    className={classes.typography}
                    style={{ fontWeight: 300, padding: "0px 12px" }}
                  >
                    You do not have any scheduled shifts for{" "}
                    {isFamily
                      ? capitalizeFirstChar(
                          members[currentTeamIndex].caregiverInfo.firstName
                        )
                      : capitalizeFirstChar(
                          members[currentTeamIndex].familyInfo.clientFirstName
                        )}{" "}
                    at the moment.
                  </Typography>

                  <Box
                    className={
                      !isFamily || shifts.length < 1
                        ? classes.flexBoxCentered
                        : classes.flexBoxSpaced
                    }
                    style={{ marginTop: 8, width: "100%" }}
                  >
                    {shifts.length > 0 && (
                      <Button
                        color="primary"
                        size="small"
                        variant={isFamily ? "text" : "outlined"}
                        onClick={this.handleResetShifts}
                        style={{ borderRadius: 25 }}
                      >
                        View Shift
                      </Button>
                    )}
                    {isFamily && (
                      <Button
                        component={Link}
                        to={`/myteam/${members[currentTeamIndex].id}/schedule-shift`}
                        color="primary"
                        size="small"
                        variant="outlined"
                        style={{ borderRadius: 25 }}
                      >
                        Add Shift
                      </Button>
                    )}
                  </Box>
                </Box>
              </SwipeableViews>
            ) : (
              shifts.length > 0 && (
                <SwipeableViews
                  index={currentShiftIndex}
                  onChangeIndex={this.handleChangeShiftIndex}
                  enableMouseEvents
                  axis="y"
                  resistance
                  containerStyle={{
                    height: 169,
                    position: "relative",
                  }}
                >
                  {shifts.map((shift, ind) => (
                    <ShiftDashItem
                      shift={shift}
                      handleChangeShiftIndex={this.handleChangeShiftIndex}
                      numShifts={shifts.length}
                      ind={ind}
                      key={shift.id}
                      isFamily={isFamily}
                    />
                  ))}
                </SwipeableViews>
              )
            )
          ) : (
            <Box className={classes.flexBoxCentered} style={{ height: "100%" }}>
              <CircularProgress thickness={2} size={50} />
            </Box>
          )}
        </Grid>
      </Box>
    );
  }
}

TeamShiftContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  shifts: PropTypes.array.isRequired,
  isLoadedShifts: PropTypes.bool.isRequired,
  isFamily: PropTypes.bool,
};

export default withStyles(styles)(TeamShiftContainer);
