import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Component imports
import DetailsTasksDisplay from "../../shiftsTasks/DetailsTasksDisplay";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/uiActions";
import { setData } from "../../../redux/actions/dataActions";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// Icon imports
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class DetailsTasksDialogButton extends Component {
  state = {
    dialogOpen: false,
    isLoadedTasks: false,
    tasks: [],
    loading: false,
    allowReload: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => this.setState(state, resolve));
  }

  // UI Methods
  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
    if (!this.state.isLoadedTasks) {
      this.getTasks();
    }
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };

  // Action Methods

  getTasks = async () => {
    const { shift } = this.props;
    await this.setStateAsync({ loading: true });
    try {
      const tasksDoc = await axios.get(`/shift/${shift.id}/task`);

      await this.setStateAsync({ tasks: tasksDoc.data, allowReload: false });
    } catch (err) {
      await this.setStateAsync({ allowReload: true });

      this.props.setAlert({
        message: "There was an error getting tasks",
        type: "error",
      });
    } finally {
      await this.setStateAsync({ loading: false, isLoadedTasks: true });
    }
  };

  handleUpdateTask = (value, ind) => {
    const { tasks } = this.state;

    const _tasks = tasks;
    _tasks[ind] = value;

    this.setState({ tasks: _tasks });
  };

  render() {
    const { classes, shift, isFamily } = this.props;
    const { dialogOpen, isLoadedTasks, tasks, loading, allowReload } =
      this.state;
    return (
      <div>
        {/* <Button
          className={classes.txtTrButton}
          color="primary"
          variant="outlined"
          size="small"
          onClick={this.handleOpenDialog}
          style={{ margin: 0, minWidth: 0 }}
          endIcon={
            shift.numTasksActioned >= shift.numTasks ? (
              <CheckCircleIcon />
            ) : (
              <Avatar
                style={{
                  height: 20,
                  width: 20,
                  backgroundColor: deepOrange[300],
                }}
              >
                {(shift.numTasks - shift.numTasksActioned).toString()}
              </Avatar>
            )
          }
        >
          Details
        </Button> */}
        <IconButton
          color="primary"
          size="small"
          onClick={this.handleOpenDialog}
        >
          {/* {shift.numTasksActioned >= shift.numTasks ? (
            <CheckCircleIcon />
          ) : ( */}
          {/* <MoreHorizIcon /> */}
          {shift.numTasksActioned < shift.numTasks ? (
            <Avatar
              style={{
                height: 30,
                width: 30,
                backgroundColor: deepOrange[300],
              }}
            >
              {(shift.numTasks - shift.numTasksActioned).toString()}
            </Avatar>
          ) : (
            <MoreHorizIcon />
          )}
          {/* )} */}
        </IconButton>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={dialogOpen}
          onClose={this.handleCloseDialog}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.headerBarRounded}
          >
            <Typography variant="h6">Shift Details</Typography>
            <IconButton
              size="small"
              onClick={this.handleCloseDialog}
              className={classes.closeButton}
            >
              <CloseIcon color="inherit" />
            </IconButton>
          </Box>
          <DialogContent>
            <DetailsTasksDisplay
              shift={shift}
              tasks={tasks}
              loading={!isLoadedTasks || loading}
              allowReload={allowReload}
              onReloadTasks={this.getTasks}
              isFamily={isFamily}
              onUpdateTaskStatus={this.handleUpdateTask}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DetailsTasksDialogButton.propTypes = {
  classes: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isFamily: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, { setAlert, setData })(
  withStyles(styles)(DetailsTasksDialogButton)
);
