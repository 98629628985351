import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { isBrowser } from "react-device-detect";

// Component imports

import ActionShiftButton from "../../buttons/shift_task/ActionShiftButton";
import DetailsTasksDialogButton from "../../buttons/shift_task/DetailsTasksDialogButton";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Icon imports

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ShiftDashItem extends Component {
  // UI Methods
  handlePrevShift = () => {
    const { ind } = this.props;
    if (ind > 0) this.props.handleChangeShiftIndex(this.props.ind - 1);
  };

  handleNextShift = () => {
    const { ind, numShifts } = this.props;
    if (ind < numShifts - 1)
      this.props.handleChangeShiftIndex(this.props.ind + 1);
  };

  render() {
    const {
      classes,
      ind,
      isFamily,
      numShifts,
      shift,
      shift: { startTime, endTime, status },
    } = this.props;

    return (
      <div
        style={{
          height: 169,
          borderRadius: 10,
          padding: 8,
          position: "relative",
          width: "100%",
        }}
      >
        {isBrowser && (
          <Fragment>
            <IconButton
              onClick={this.handlePrevShift}
              className={classes.absoluteIconButtonOnHover}
              style={{ left: 0, bottom: 0 }}
              disabled={ind < 1}
              size="large">
              <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton
              onClick={this.handleNextShift}
              className={classes.absoluteIconButtonOnHover}
              style={{ right: 0, bottom: 0 }}
              disabled={ind === numShifts - 1}
              size="large">
              <KeyboardArrowDownIcon />
            </IconButton>
          </Fragment>
        )}
        <Box
          className={classes.flexBoxSpaced}
          style={{
            width: "100%",
          }}
        >
          <Typography variant="body2" style={{ fontWeight: 300 }}>
            Status:
          </Typography>
          <Chip
            label={
              status.isCompleted
                ? "Completed"
                : status.isCheckedIn
                ? "Checked In"
                : "Scheduled"
            }
            size="small"
            className={
              status.isCompleted
                ? classes.chipGreen
                : status.isCheckedIn
                ? classes.chipBlue
                : classes.chipOrj
            }
            style={{ margin: 0 }}
          />
        </Box>
        <div
          className={classes.flexBox}
          style={{ flexDirection: "column", marginTop: 8 }}
        >
          <div>
            <CardActionArea
              component={Link}
              to={`/myteam/${shift.memberId}/shift/${shift.id}`}
              style={{
                borderRadius: 10,
                padding: 8,
                backgroundColor: "rgba(212,212,212,0.2)",
              }}
            >
              <Typography align="center" style={{ fontWeight: 300 }}>
                {dayjs(startTime).format("MMMM D, YYYY")}
              </Typography>
              <Typography align="center">
                {dayjs(startTime).format("h:mma")} -{" "}
                {dayjs(endTime).format("h:mma")}
              </Typography>
            </CardActionArea>
          </div>
          <div
            className={
              isFamily ? classes.flexBoxCentered : classes.flexBoxSpaced
            }
            style={{ marginTop: 4, width: "100%" }}
          >
            {!isFamily && <ActionShiftButton shift={shift} />}
            <DetailsTasksDialogButton isFamily={isFamily} shift={shift} />
          </div>
        </div>
      </div>
    );
  }
}

ShiftDashItem.propTypes = {
  classes: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  numShifts: PropTypes.number,
  isFamily: PropTypes.bool,
};

export default withStyles(styles)(ShiftDashItem);
