import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import CarePay from "../../../pages/caregiverPages/myPay/CarePay";
import FamilyPay from "../../../pages/familyPages/myPay/FamilyPay";

// Redux imports
import { connect } from "react-redux";
import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

export class myPay extends Component {
  componentDidMount() {
    store.dispatch({ type: SET_PAGE, payload: "MyPay" });
  }

  componentWillUnmount() {
    store.dispatch({ type: UNSET_PAGE });
  }
  render() {
    const {
      user: {
        credentials,
        credentials: { userType },
      },
    } = this.props;

    return (
      <div className="res-gr-con">
        {userType === "family" ? (
          <FamilyPay credentials={credentials} />
        ) : userType === "caregiver" ? (
          <CarePay credentials={credentials} />
        ) : null}
      </div>
    );
  }
}

myPay.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(myPay);
