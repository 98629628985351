import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter
import axios from "axios";

// Redux imports
import { connect } from "react-redux";
import { updateJobStatus } from "../../redux/actions/dataActions";
import { setAlert } from "../../redux/actions/uiActions";

// Component imports
import { capitalizeFirstChar, isEmpty } from "../../util/utilFunctions";
import AnimatedCheck from "../../assets/AnimatedCheck";
import FavouriteIconButton from "../../components/buttons/favouriteIconButton/FavouriteIconButton";
import ChipArrayDisplay from "../profile/ChipArrayDisplay";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

// Icons Imports
import ChatIcon from "@mui/icons-material/Chat";

import LanguageIcon from "@mui/icons-material/Language";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import BusinessIcon from "@mui/icons-material/Business";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  counterZero: {
    width: 25,
    height: 25,
  },
  counter: {
    width: 25,
    height: 25,
    backgroundColor: `${theme.palette.secondary.main}`,
    marginBottom: 2,
  },
});

export class JobMarkupCg extends Component {
  state = {
    isApplyDialog: false,
    disableWhileLoad: false,
    applicationSent: false,
    message: `Hi ${capitalizeFirstChar(
      this.props.jobData.familyInfo.firstName
    )}!\nSaw your posting for a caregiver with experience in ${Object.keys(
      this.props.jobData.conditions
    )
      .slice(0, 2)
      .map((condition) =>
        condition
          .split(/(?=[A-Z])/)
          .join(" ")
          .toLowerCase()
      )
      .join(" and ")} to assist with ${Object.keys(this.props.jobData.service)
      .slice(0, 3)
      .map((value) =>
        value
          .split(/(?=[A-Z])/)
          .join(" ")
          .toLowerCase()
      )
      .join(", ")} ${
      Object.keys(this.props.jobData.service).length > 3 && "and others"
    }. I believe I am a good fit for your needs and would love to discuss further. Click on my profile to view my details.  Feel free to message me to discuss further!`,
    errors: { message: null, general: null },
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI Methods
  handleApplyDialog = () => {
    const { credentials } = this.props;

    if (!credentials.status.isBgCheck)
      return this.props.setAlert({
        message:
          "Your profile is not completed. Ensure your account is completed prior to applying.",
        type: "warning",
        link: true,
        url: "/profile",
        btnTxt: "Profile",
      });

    this.setState({
      isApplyDialog: true,
      message: `Hi ${capitalizeFirstChar(
        this.props.jobData.familyInfo.firstName
      )}!\n\nSaw your posting for a caregiver with experience in ${Object.keys(
        this.props.jobData.conditions
      )
        .slice(0, 2)
        .map((condition) =>
          condition
            .split(/(?=[A-Z])/)
            .join(" ")
            .toLowerCase()
        )
        .join(" and ")} to assist with ${Object.keys(this.props.jobData.service)
        .slice(0, 3)
        .map((value) =>
          value
            .split(/(?=[A-Z])/)
            .join(" ")
            .toLowerCase()
        )
        .join(", ")} ${
        Object.keys(this.props.jobData.service).length > 3 && "and others"
      }. I believe I am a good fit for your needs and would love to discuss further. Click on my profile to view my details.  Feel free to message me to discuss further!`,
    });
  };

  handleCloseApplyDialog = () => {
    this.setState({
      isApplyDialog: false,
      errors: { ...this.state.errors, message: null },
    });
  };

  // Action Methods

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]:
          isEmpty(value) &&
          "Must not be empty. Please include a brief message with your application.",
      },
    });
  };
  handleSendMessage = async () => {
    const { credentials } = this.props;

    if (!credentials.status.isBgCheck)
      return this.props.setAlert({
        message:
          "Your profile is not completed. Ensure your account is completed prior to applying.",
        type: "warning",
        link: true,
        url: "/profile",
        btnTxt: "Profile",
      });

    const {
      jobData: {
        familyInfo: { firstName, lastName, imgUrlThumb, userIdNumber },
      },
    } = this.props;

    let chnData = { firstName, lastName, imgUrlThumb };

    await this.setStateAsync({ disableWhileLoad: true });
    try {
      const messageChannel = await axios.post(
        `/message/new/${userIdNumber}`,
        chnData
      );
      this.props.history.push(`/messages/${messageChannel.data.channelId}`);
      await this.setStateAsync({ disableWhileLoad: false });
    } catch (err) {
      if (err.response.data.error) {
        this.props.setAlert({
          message: err.response.data.error,
          type: "warning",
        });
      }
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  handleApplyJob = async () => {
    const {
      jobData: { jobId },
    } = this.props;
    const { message } = this.state;

    await this.setStateAsync({
      disableWhileLoad: true,
      errors: { ...this.state.errors, general: null },
    });
    try {
      const jobApp = await axios.post(`/job/${jobId}/apply`, { message });
      this.props.updateJobStatus(jobId, jobApp.data);
      this.props.updateStateJobStatus(jobApp.data);
      await this.setStateAsync({
        disableWhileLoad: false,
        applicationSent: true,
        isApplyDialog: false,
      });
      setTimeout(() => this.setState({ applicationSent: false }), 2000);
    } catch (err) {
      if (err.response.data.error) {
        this.props.setAlert({
          message: err.response.data.error,
          type: "warning",
        });
      }
      await this.setStateAsync({
        disableWhileLoad: false,
        // errors: { ...this.state.errors, general: err.response.data.error },
      });
    }
  };

  render() {
    const {
      classes,
      credentials,
      jobData,
      jobData: {
        serviceStart,
        address,
        servicePerWeek,
        createdAt,
        serviceLength,
        description,
        numApply,
        isAvailable,
        apps,
        jobId,
        hires,
        familyInfo: { firstName, lastName },
      },
    } = this.props;

    const {
      disableWhileLoad,
      applicationSent,
      isApplyDialog,
      message,
      errors,
    } = this.state;

    const serviceStartObj = {
      Immediately: "immediately",
      "1 week": "1week",
      "1 month": "1month",
      "1 month+": "1month+",
    };

    const servicePerWeekObj = {
      "Under 10 hrs": "under10",
      "10 - 20 hrs": "10-20",
      "20hr +": "20+",
    };

    const serviceLengthObj = {
      "Under 1 Month": "underOneMonth",
      "Under 3 Months": "underThreeMonths",
      "Under 6 Months": "underSixMonths",
      "6 Months +": "overSixMonths",
    };

    const isApplied = apps.some(
      (app) => app.userIdNumber === credentials.userIdNumber
    );

    const isHired = hires.some(
      (hire) => hire.userIdNumber === credentials.userIdNumber
    );
    return (
      <div style={{ position: "relative" }}>
        <Fade in={applicationSent} unmountOnExit>
          <Box className={classes.layoverUpdate}>
            <AnimatedCheck large />
            <Fade in>
              <div>
                <Typography variant="h6" color="primary">
                  Application Sent!
                </Typography>
              </div>
            </Fade>
          </Box>
        </Fade>
        <Paper
          elevation={4}
          className={classes.smallPaperContainer}
          style={{ width: "100%" }}
        >
          <Divider />
          <div style={{ marginTop: 10 }}>
            <Box display="flex" justifyContent="space-between">
              <BusinessIcon color="primary" style={{ fontSize: 75 }} />
              <div>
                <Typography variant="subtitle2">
                  {dayjs(createdAt).fromNow()}
                </Typography>

                {(isHired || isApplied) && (
                  <Chip
                    label={isHired ? "Hired" : "Applied"}
                    size="small"
                    className={
                      isHired
                        ? classes.chipGreenOutlined
                        : classes.chipBlueOutlined
                    }
                  />
                )}
              </div>
            </Box>
            <Box style={{ alignItems: "center", display: "flex" }}>
              <Typography variant="h6" style={{ fontWeight: 550 }}>
                {capitalizeFirstChar(firstName)} {capitalizeFirstChar(lastName)}{" "}
                •
              </Typography>
              <Chip
                label={isAvailable ? "Active Posting" : "Inactive Posting"}
                size="small"
                className={
                  isAvailable
                    ? classes.chipBlueOutlined
                    : classes.chipOrjOutlined
                }
              />
              <FavouriteIconButton id={jobId} />
            </Box>

            <Typography variant="subtitle2">
              {capitalizeFirstChar(address.addressParams.locality)},{" "}
              {address.addressParams.adminAreaLevel1.toUpperCase()}
            </Typography>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <QueryBuilderIcon
                color="primary"
                style={{ fontSize: 15, marginRight: 5 }}
              />
              <Typography variant="subtitle2" color="textSecondary">
                {parseInt(numApply) < 11
                  ? "Be in the first 10 applicants"
                  : `${parseInt(numApply)} applications received`}
              </Typography>
            </Box>
          </div>
          <div>
            <Button
              className={classes.txtTrButton}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={this.handleSendMessage}
              startIcon={<ChatIcon />}
              disabled={disableWhileLoad || !isAvailable}
            >
              Message{" "}
              {disableWhileLoad && (
                <CircularProgress
                  thickness={2}
                  size={20}
                  className={classes.progress}
                />
              )}
            </Button>
            {isApplied ? (
              <Button
                component={Link}
                to={`/jobs/applications/${
                  apps.find(
                    (app) => app.userIdNumber === credentials.userIdNumber
                  ).appId
                }`}
                className={classes.txtTrButton}
                variant="contained"
                color="secondary"
                size="small"
              >
                View Application
              </Button>
            ) : (
              <Button
                className={classes.txtTrButton}
                variant="contained"
                color="secondary"
                size="small"
                onClick={this.handleApplyDialog}
                disabled={disableWhileLoad || !isAvailable || isApplied}
              >
                Apply
              </Button>
            )}

            <Dialog open={isApplyDialog} onClose={this.handleCloseApplyDialog}>
              <DialogTitle>
                <Typography
                  variant="h6"
                  color={Boolean(errors.general) ? "error" : "primary"}
                >
                  Apply to {capitalizeFirstChar(firstName)}'s Posting?
                </Typography>
              </DialogTitle>
              {Boolean(errors.general) && (
                <Typography
                  color="error"
                  align="center"
                  style={{ marginTop: 8, marginBottom: 8 }}
                >
                  {errors.general}
                </Typography>
              )}
              <DialogContent>
                <Typography>
                  Your application will be sent to{" "}
                  {capitalizeFirstChar(firstName)} immediately!
                </Typography>
                <Typography style={{ margin: "24px 0px 16px 0px" }}>
                  You will be notified upon acceptance or decline including next
                  steps. We highly encourage you to customize a brief personal
                  message with you application.
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="message"
                  value={message}
                  onChange={this.handleChange}
                  multiline
                  maxRows={5}
                  error={Boolean(errors.message)}
                  helperText={errors.message}
                  className={
                    Boolean(errors.message)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseApplyDialog}
                  disabled={disableWhileLoad}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  onClick={this.handleApplyJob}
                  disabled={disableWhileLoad}
                >
                  Confirm
                  {disableWhileLoad && (
                    <CircularProgress
                      thickness={2}
                      size={20}
                      className={classes.progress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Paper>
        <Paper
          elevation={4}
          className={classes.smallPaperContainer}
          style={{
            marginTop: 16,
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <ScheduleIcon />
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", marginLeft: 10 }}
            >
              Schedule Highlights
            </Typography>
          </Box>

          <Grid
            container
            justifyContent="center"
            className={classes.form}
            style={{ marginBottom: 20 }}
          >
            <Grid item xs={6}>
              <Typography variant="body2">Start Time:</Typography>
              <Typography variant="body2">Hours Per Week:</Typography>
              <Typography variant="body2">Estimated Length:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                {Object.keys(serviceStartObj).filter(
                  (key) => serviceStartObj[key] === serviceStart
                )}
              </Typography>
              <Typography variant="body2">
                {Object.keys(servicePerWeekObj).filter(
                  (key) => servicePerWeekObj[key] === servicePerWeek
                )}
              </Typography>
              <Typography variant="body2">
                {Object.keys(serviceLengthObj).filter(
                  (key) => serviceLengthObj[key] === serviceLength
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Job Description
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: 300, whiteSpace: "pre-line" }}
            >
              {description}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Language Preferences
            </Typography>
            <ChipArrayDisplay
              icon={<LanguageIcon />}
              dataArray={jobData.languagePref}
              className={classes.chipBlue}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Services Needed
            </Typography>

            <ChipArrayDisplay
              icon={<AssignmentIcon />}
              dataArray={Object.keys(jobData.service).sort()}
              className={classes.chipBlue}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Special Conditions
            </Typography>

            <ChipArrayDisplay
              icon={<LocalHospitalIcon />}
              dataArray={Object.keys(jobData.conditions).sort()}
              className={classes.chipBlue}
            />
          </Grid>
        </Paper>
      </div>
    );
  }
}

JobMarkupCg.propTypes = {
  jobData: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  updateJobStatus: PropTypes.func.isRequired,
  updateStateJobStatus: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps, { updateJobStatus, setAlert })(
  withStyles(styles)(JobMarkupCg)
);
