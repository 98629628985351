import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDayjs";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import TextField from "@mui/material/TextField";

export class DateSelector extends Component {
  state = {
    date: isMobile ? "" : null,
  };

  componentDidMount() {
    if (this.props.value) {
      this.setState({ date: this.props.value.slice(0, 10) });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value && this.props.value === "") {
      this.setState({ date: isMobile ? "" : null });
    }
  }

  handleChangeDate = (value) => {
    this.setState({ date: value }, () => {
      this.props.onSelectDate(this.state.date);
    });
  };

  handleChangeDateNative = (event) => {
    this.setState({ date: event.target.value }, () => {
      this.props.onSelectDate(new Date(this.state.date).toISOString());
    });
  };

  render() {
    const {
      errorDate,
      errorDateText,
      label,
      format,
      name,
      openTo,
      id,
      placeholder,
      views,
      propMinDate,
      propMaxDate,
      disableFuture,
      style,
      required,
      disabled,
      className,
      size,
      fullWidth,
      shrink,
    } = this.props;

    return isMobile && !Boolean(views) ? (
      <TextField
        name={name}
        value={this.state.date}
        label={label}
        type="date"
        size={size}
        style={style}
        className={className}
        onChange={this.handleChangeDateNative}
        InputLabelProps={{
          shrink,
        }}
        fullWidth={fullWidth}
        error={Boolean(errorDate)}
        helperText={
          Boolean(errorDate) && Boolean(errorDateText) ? errorDateText : null
        }
      />
    ) : (
      <LocalizationProvider dateAdapter={DateAdapter}>
        {isMobile ? (
          <MobileDatePicker
            disableFuture={disableFuture}
            openTo={openTo}
            name={name}
            id={id}
            inputFormat={format}
            label={label}
            views={views}
            value={this.state.date}
            minDate={propMinDate}
            maxDate={propMaxDate}
            onChange={this.handleChangeDate}
            required={required}
            disabled={disabled}
            showTodayButton
            renderInput={(params) => (
              <TextField
                fullWidth={fullWidth}
                className={className}
                size={size}
                placeholder={placeholder}
                helperText={
                  Boolean(errorDate) && Boolean(errorDateText)
                    ? errorDateText
                    : null
                }
                style={style ? style : null}
                {...params}
                error={Boolean(errorDate) && Boolean(errorDateText)}
              />
            )}
          ></MobileDatePicker>
        ) : (
          <DesktopDatePicker
            disableFuture={disableFuture}
            openTo={openTo}
            name={name}
            id={id}
            // inputFormat={format}
            label={label}
            views={views}
            value={this.state.date}
            minDate={propMinDate}
            maxDate={propMaxDate}
            onChange={this.handleChangeDate}
            required={required}
            disabled={disabled}
            showTodayButton
            inputProps={{
              readOnly: true,
              disabled: true,
            }}
            renderInput={(params) => (
              <TextField
                fullWidth={fullWidth}
                className={className}
                size={size}
                placeholder={placeholder}
                value={this.state.date}
                helperText={
                  Boolean(errorDate) && Boolean(errorDateText)
                    ? errorDateText
                    : null
                }
                InputLabelProps={{
                  shrink,
                }}
                style={style ? style : null}
                {...params}
                error={Boolean(errorDate) && Boolean(errorDateText)}
              />
            )}
          />
        )}
      </LocalizationProvider>
    );
  }
}

export default DateSelector;
