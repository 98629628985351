import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";
import EditAboutMe from "../../pages/caregiverPages/profile/about/EditAboutMe";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// Icons imports
import HelpIcon from "@mui/icons-material/Help";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function SmallText(props) {
  return <Typography variant="body2" align="left" {...props} />;
}
function CaptionText(props) {
  return (
    <Typography
      variant="caption"
      component="div"
      align="right"
      style={{ fontWeight: 300, paddingRight: 8 }}
      {...props}
    />
  );
}

export class AboutMeBlock extends Component {
  render() {
    const { classes, credentials, languageAll } = this.props;
    return (
      <Paper elevation={4} className={classes.darkGrayBorder}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h6"
            style={{ fontWeight: 300 }}
            className={classes.typography}
          >
            About Me
          </Typography>
          <EditAboutMe aboutMe={credentials} languageAll={languageAll} />
        </Box>
        <Typography
          variant="body2"
          className={classes.typography}
          align="left"
          style={{ fontWeight: 300, whiteSpace: "pre-line", marginTop: 16 }}
        >
          {credentials.bio}
        </Typography>
        <Divider
          orientation="horizontal"
          style={{ marginTop: 10, marginBottom: 10 }}
        />
        <Typography
          align="left"
          className={classes.typography}
          style={{ fontWeight: 300 }}
        >
          Hourly Rate
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <CaptionText className={classes.typography}>Wage</CaptionText>
          </Grid>
          <Grid item xs={8}>
            <SmallText className={classes.typography}>
              ${parseFloat(credentials.wageExp).toFixed(2)}
            </SmallText>
          </Grid>
          <Grid item xs={4}>
            <CaptionText className={classes.typography}>
              Taxes {"&"} Fees
            </CaptionText>
          </Grid>
          <Grid item xs={8} style={{ display: "flex" }}>
            <SmallText className={classes.typography}>
              ${(parseFloat(credentials.wageExp) * 0.2966).toFixed(2)}
            </SmallText>
            <Tooltip
              disableFocusListener
              enterTouchDelay={0}
              placement="right"
              title={
                <Typography variant="caption">
                  Estimated based on payroll taxes of 9.66% in{" "}
                  {credentials.address.addressParams.adminAreaLevel1.toUpperCase()}{" "}
                  and 20% Poyo Fees (transaction, insurance,
                  platform/management).
                </Typography>
              }
            >
              <HelpIcon color="inherit" style={{ fontSize: 12 }} />
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <CaptionText
              className={classes.typography}
              style={{ fontWeight: 500, paddingRight: 8 }}
            >
              Total Cost
            </CaptionText>
          </Grid>
          <Grid item xs={8} style={{ display: "flex" }}>
            <SmallText
              className={classes.typography}
              style={{ fontWeight: 500 }}
            >
              ${(parseFloat(credentials.wageExp) * 1.2966).toFixed(2)}
            </SmallText>
          </Grid>
        </Grid>
        <Divider
          orientation="horizontal"
          style={{ marginTop: 10, marginBottom: 10 }}
        />

        <Typography
          variant="body2"
          align="left"
          className={classes.typography}
          component="div"
          style={{ fontWeight: 300 }}
        >
          <Box style={{ fontWeight: 500 }} display="inline">
            Work Experience:{" "}
          </Box>{" "}
          {credentials.yearsExp} years
        </Typography>
        <Typography
          variant="body2"
          align="left"
          className={classes.typography}
          component="div"
          style={{ fontWeight: 300 }}
        >
          <Box style={{ fontWeight: 500 }} display="inline">
            Languages:{" "}
          </Box>{" "}
          {credentials.languagePref
            .map((lang) => capitalizeFirstChar(lang))
            .join(", ")}
        </Typography>

        <Typography
          variant="body2"
          align="left"
          className={classes.typography}
          component="div"
          style={{ fontWeight: 300 }}
        >
          <Box style={{ fontWeight: 500 }} display="inline">
            Work Radius:{" "}
          </Box>{" "}
          Within {parseInt(credentials.workRadius)}kms
        </Typography>

        {credentials.poyoJobs.length > 0 && (
          <Typography
            variant="body2"
            align="left"
            className={classes.typography}
            component="div"
            style={{ fontWeight: 300 }}
          >
            <Box style={{ fontWeight: 500 }} display="inline">
              Poyo Jobs:{" "}
            </Box>{" "}
            {credentials.poyoJobs.length} jobs completed with Poyo
          </Typography>
        )}
      </Paper>
    );
  }
}

AboutMeBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutMeBlock);
