import React, { Component, Fragment } from "react";
import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";
import ChartDataLabels from "chartjs-plugin-datalabels";

import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const allColors = [
  "#C02323",
  "#DE542C",
  "#EF7E32",
  "#EE9A3A",
  "#EABD3B",
  "#E7E34E",
  "#F7F4BF",
];
export class InvoicePieSingle extends Component {
  state = {
    labels: [],
    dataset: [],
  };

  componentDidMount() {
    this.setLabelAndData();
  }

  componentDidUpdate(prevProps) {
    const { updateGraph } = this.props;

    if (updateGraph && !prevProps.updateGraph) {
      this.setLabelAndData();
    }
  }

  setLabelAndData() {
    const { invoices, userType } = this.props;

    let labels = [];
    let dataset = [];
    invoices.forEach((invoice) => {
      const {
        id,
        invoiceBreakdown: {
          netIncome,
          payableBreakdown: { totalPayable },
        },
      } = invoice;

      labels.push(`Invoice ${id}`);
      dataset.push(
        Math.round(
          (userType === "caregiver" ? netIncome : totalPayable) * 100
        ) / 100
      );
    });

    this.setState({ labels, dataset });
  }

  render() {
    const { dataset, labels } = this.state;
    const { userType, classes } = this.props;

    const data = {
      labels: labels,
      datasets: [
        {
          label: userType === "caregiver" ? "Net Income" : "Total Costs",
          data: dataset,
          backgroundColor: allColors,
          borderColor: allColors,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 15,
          bottom: 15,
        },
      },
      plugins: {
        legend: {
          position: "right",
        },
        datalabels: {
          backgroundColor: function (context) {
            return context.dataset.backgroundColor;
          },
          borderColor: "white",
          borderRadius: 25,
          borderWidth: 2,
          color: "white",
          display: function (context) {
            var dataset = context.dataset;
            var count = dataset.data.length;
            var value = dataset.data[context.dataIndex];
            return value > count * 1.5;
          },
          font: {
            weight: "bold",
          },
          padding: 6,
          formatter: function (value) {
            return "$" + value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              return `${context[0].label}`;
            },
            label: function (context) {
              let label = context.dataset.label || "";
              let value = context.raw;

              if (label) {
                label += ": ";
              }
              if (value !== null) {
                label += new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(value);
              }
              return label;
            },
            footer: function (context) {
              const total = context.reduce((a, b) => a + b.raw, 0);
              return `TOTAL: ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(total)}`;
            },
          },
        },
        aspectRatio: 4 / 3,
        cutoutPercentage: 32,
        layout: {
          padding: 32,
        },
        elements: {
          line: {
            fill: false,
          },
          point: {
            hoverRadius: 7,
            radius: 5,
          },
        },
      },
    };

    return (
      <Fragment>
        <Typography align="center" color="textSecondary" variant="h6">
          {userType === "caregiver" ? `Monthly Net Income` : `Monthly Cost`}
        </Typography>
        <Doughnut data={data} plugins={[ChartDataLabels]} options={options} />
        <Typography
          align="center"
          color="textSecondary"
          className={classes.selectedGrayContainer}
          style={{ fontWeight: 550, marginTop: 10 }}
        >
          Total $
          {dataset
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
        </Typography>
      </Fragment>
    );
  }
}

InvoicePieSingle.propTypes = {
  classes: PropTypes.object.isRequired,
  invoices: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
  updateGraph: PropTypes.bool,
};

export default withStyles(styles)(InvoicePieSingle);
