import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";

// Component imports
import { isEmpty } from "../../../util/utilFunctions";
import MemberSelect from "./MemberSelect";
import MemberInfo from "./MemberInfo";
import ColumnHeader from "./ColumnHeader";
import InvoiceRowDisplay from "./InvoiceRowDisplay";
import InvoiceBar from "../../charts/InvoiceBar";
import InvoicePie from "../../charts/InvoicePie";
import InvoicePieSingle from "../../charts/InvoicePieSingle";
import StyledSelect from "../../inputs/StyledSelect";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import DescriptionIcon from "@mui/icons-material/Description";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class History extends Component {
  state = {
    isLoadedInvoices: false,
    invoicesApiRes: [],
    isLoadedTeams: false,
    teamMembers: [],
    currMemberId: "",
    currMemberData: {},
    pageNum: 1,
    updateGraph: false,
    filters: {
      yearData: [],
      yearFilter: "",
      monthData: [],
      monthFilter: "",
    },
    filteredInvoices: [],
  };

  componentDidMount() {
    (async () => {
      await this.getPaidInvoices();
      await this.setFilteredInvoices();

      await this.getTeamMembers();
    })();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  // Initialize Methods

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  getPaidInvoices = async () => {
    try {
      const invoiceAPIResponse = await axios.get("/invoice/get_all/paid");
      this.setState({
        invoicesApiRes: invoiceAPIResponse.data,
        isLoadedInvoices: true,
      });
    } catch (err) {
      console.error(err.response.data);
    }
  };

  getTeamMembers = async () => {
    const teamData = await axios.get("/team");
    const teamMembers = teamData.data.members;

    await this.setStateAsync({ isLoadedTeams: true, teamMembers });
  };

  // Action Methods
  onSelectMember = async (memberId) => {
    const { teamMembers } = this.state;

    const currMemberPos = teamMembers.findIndex(
      (member) => member.id === memberId
    );

    await this.setStateAsync({
      currMemberId: memberId,
      currMemberData: currMemberPos > -1 ? teamMembers[currMemberPos] : {},
    });
    this.setFilteredInvoices();
  };

  async setFilteredInvoices() {
    const { invoicesApiRes } = this.state;

    const {
      currMemberId,
      filters: { yearFilter, monthFilter },
    } = this.state;

    let filteredInvoices = invoicesApiRes.slice();
    if (!isEmpty(currMemberId)) {
      filteredInvoices = filteredInvoices.filter(
        (invoice) => invoice.memberInfo.memberId === currMemberId
      );
    }

    if (!isEmpty(yearFilter)) {
      filteredInvoices = filteredInvoices.filter(
        (invoice) =>
          dayjs(invoice.createdAt).get("year") === parseInt(yearFilter)
      );
    }
    if (!isEmpty(monthFilter)) {
      filteredInvoices = filteredInvoices.filter(
        (invoice) =>
          dayjs(invoice.createdAt).get("month") === parseInt(monthFilter) - 1
      );
    }

    await this.setStateAsync({ filteredInvoices });
    await this.setFilteredPageNum();
    await this.setYearData();
    await this.setMonthData();
    await this.setUpdateGraph();
  }

  async setFilteredPageNum() {
    const { pageNum, filteredInvoices } = this.state;
    const _filteredInvoices = [...filteredInvoices].slice(
      (pageNum - 1) * 10,
      pageNum * 10
    );
    await this.setStateAsync({ filteredInvoices: _filteredInvoices });
  }

  async setUpdateGraph() {
    await this.setStateAsync({ updateGraph: true });
    await this.setStateAsync({ updateGraph: false });
  }

  async setYearData() {
    const { invoicesApiRes } = this.state;
    let filteredInvoices = invoicesApiRes.slice();

    const yearsObj = {};
    filteredInvoices.forEach((invoice) => {
      if (!yearsObj[invoice.createdAt.slice(0, 4)]) {
        yearsObj[invoice.createdAt.slice(0, 4)] = true;
      }
    });

    const yearData = Object.keys(yearsObj);

    await this.setStateAsync({ filters: { ...this.state.filters, yearData } });
    await this.setUpdateGraph();
  }

  async setMonthData() {
    const {
      currMemberId,
      invoicesApiRes,
      filters: { yearFilter },
    } = this.state;

    if (!isEmpty(yearFilter)) {
      let filteredInvoices = invoicesApiRes.slice();
      if (!isEmpty(currMemberId)) {
        filteredInvoices = filteredInvoices.filter(
          (invoice) => invoice.memberInfo.memberId === currMemberId
        );
      }
      const monthObj = {};
      filteredInvoices
        .filter((invoice) => invoice.createdAt.slice(0, 4) === yearFilter)
        .forEach((invoice) => {
          if (!monthObj[invoice.createdAt.slice(5, 7)]) {
            monthObj[invoice.createdAt.slice(5, 7)] = true;
          }
        });

      const monthData = Object.keys(monthObj);

      await this.setStateAsync({
        filters: { ...this.state.filters, monthData },
      });
      await this.setUpdateGraph();
    }
  }

  handlePageSel = async (e, v) => {
    this.setState({ pageNum: v });
    await this.setFilteredPageNum();
  };

  setYearFilter = async (e) => {
    if (!isEmpty(e)) {
      await this.setStateAsync({
        filters: { ...this.state.filters, yearFilter: e },
      });
    } else {
      await this.setStateAsync({
        filters: {
          ...this.state.filters,
          yearFilter: e,
          monthData: [],
          monthFilter: "",
        },
      });
    }

    await this.setFilteredInvoices();
  };

  setMonthFilter = async (e) => {
    await this.setStateAsync({
      filters: { ...this.state.filters, monthFilter: e },
    });
    await this.setFilteredInvoices();
  };

  render() {
    const {
      classes,
      credentials: { userType },
    } = this.props;

    const {
      isLoadedInvoices,
      isLoadedTeams,
      teamMembers,
      currMemberId,
      currMemberData,
      pageNum,
      updateGraph,
      filteredInvoices,
      filters: { yearData, yearFilter, monthFilter, monthData },
    } = this.state;

    return (
      <Paper
        elevation={4}
        className="hideScroll"
        style={{
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          minHeight: 450,
          width: "100%",
          borderRight: "1px solid rgb(212,212,212)",
          borderLeft: "1px solid rgb(212,212,212)",
          borderBottom: "1px solid rgb(212,212,212)",
          overflow: "auto",
        }}
      >
        <Box p={2}>
          <Grid container direction="row-reverse" alignItems="center">
            <Grid item xs={12} sm={6} style={{ padding: 8 }}>
              <Box
                className={classes.flexBox}
                style={{ justifyContent: "flex-end" }}
              >
                <MemberSelect
                  teamMembers={teamMembers}
                  onSelectMember={this.onSelectMember}
                  currMemberId={currMemberId}
                  userType={userType}
                  className={classes.styledTextField}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: 8 }}>
              <MemberInfo
                currMemberData={currMemberData}
                currMemberId={currMemberId}
                userType={userType}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "0px 8px" }}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.flexBox}
              style={{ justifyContent: "flex-end", padding: 8 }}
            >
              <FormLabel>Sort By:</FormLabel>
              <StyledSelect
                values={yearData}
                onChange={this.setYearFilter}
                value={yearFilter}
                style={{ margin: "0px 4px" }}
                defaultText="-Year-"
                className={classes.styledTextField}
              />
              <StyledSelect
                values={monthData}
                onChange={this.setMonthFilter}
                value={monthFilter}
                style={{ marginLeft: 4 }}
                defaultText="-Month-"
                className={classes.styledTextField}
              />
            </Grid>
          </Grid>
          <ColumnHeader userType={userType} />
          <Divider />

          <div
            className="hideScroll"
            style={{ minHeight: 400, maxHeight: 510, overflow: "auto" }}
          >
            {!isLoadedInvoices && !isLoadedTeams ? (
              [...new Array(6)].map((v, ind) => (
                <Skeleton
                  variant="rectangular"
                  height={50}
                  animation="wave"
                  key={ind}
                  style={{ margin: "8px 0px" }}
                />
              ))
            ) : filteredInvoices.length > 0 ? (
              filteredInvoices
                .slice((pageNum - 1) * 10, pageNum * 10)
                .map((invoice, ind) => (
                  <InvoiceRowDisplay
                    key={invoice.id}
                    invoice={invoice}
                    userType={userType}
                    isOdd={ind % 2 > 0}
                  />
                ))
            ) : (
              <div
                className={classes.flexBoxCentered}
                style={{ marginTop: 16, textAlign: "center" }}
              >
                <DescriptionIcon
                  color="primary"
                  style={{ width: 60, height: 60, opacity: 0.5 }}
                />
                <Typography color="textSecondary" variant="body2">
                  No payment history found.
                </Typography>
              </div>
            )}
          </div>
          <Divider />
          <Box
            className={classes.flexBoxCentered}
            style={{ margin: "4px 0px" }}
          >
            <Pagination
              count={
                filteredInvoices.length < 1
                  ? 1
                  : Math.ceil(filteredInvoices.length / 10)
              }
              shape="rounded"
              page={pageNum}
              onChange={this.handlePageSel}
            />
          </Box>

          <Grid
            container
            justifyContent="center"
            style={{ padding: 10, marginTop: 16 }}
          >
            <Grid item xs={12} sm={10} md={9}>
              {isEmpty(yearFilter) ? (
                <InvoiceBar
                  invoices={filteredInvoices}
                  userType={userType}
                  updateGraph={updateGraph}
                />
              ) : isEmpty(currMemberId) ? (
                <InvoicePie
                  yearly={isEmpty(monthFilter)}
                  invoices={filteredInvoices}
                  userType={userType}
                  updateGraph={updateGraph}
                />
              ) : (
                <InvoicePieSingle
                  invoices={filteredInvoices}
                  userType={userType}
                  updateGraph={updateGraph}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
}

History.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default withStyles(styles)(History);
