import React from "react";
import { Link } from "react-router-dom";

// MUI Imports
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Icons Imports
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

function UnauthDrawer(props) {
  const [openCompanyDropdown, setOpenCompanyDropdown] = React.useState(false);

  const handleCompanyDropdown = () => {
    setOpenCompanyDropdown(!openCompanyDropdown);
  };

  return (
    <>
      <ListItem
        button
        onClick={props.handleDrawerClose}
        component={Link}
        to="/join-poyo"
      >
        <ListItemText
          primary={
            <Typography variant="h6" className="txt-bold">
              Become a Care Pro
            </Typography>
          }
        />
      </ListItem>
      <ListItem button onClick={handleCompanyDropdown}>
        <ListItemText
          primary={
            <Typography variant="h6" className="txt-bold">
              Our Company
            </Typography>
          }
        />
        <ListItemIcon>
          {openCompanyDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={openCompanyDropdown}>
        <div style={{ paddingLeft: 12 }}>
          <ListItem
            onClick={props.handleDrawerClose}
            button
            component={Link}
            to="/about-us"
          >
            <ListItemText
              primary={<Typography color="textSecondary">Our Story</Typography>}
            />
          </ListItem>
          <ListItem
            onClick={props.handleDrawerClose}
            button
            component={Link}
            to="/policies"
          >
            <ListItemText
              primary={<Typography color="textSecondary">Policies</Typography>}
            />
          </ListItem>
          <ListItem
            onClick={props.handleDrawerClose}
            button
            component={Link}
            to="/response-to-covid-19"
          >
            <ListItemText
              primary={
                <Typography color="textSecondary">COVID-19 Policy</Typography>
              }
            />
          </ListItem>
          <ListItem
            onClick={props.handleDrawerClose}
            button
            component={Link}
            to="/careers"
          >
            <ListItemText
              primary={<Typography color="textSecondary">Careers</Typography>}
            />
          </ListItem>
        </div>
      </Collapse>
    </>
  );
}

export default UnauthDrawer;
