import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Component imports
import ReviewLoadingSeleton from "./ReviewLoadingSeleton";
import ReviewError from "./ReviewError";
import NewReview from "./NewReview";
import ReviewMarkup from "./ReviewMarkup";
import AllReviewsMarkup from "./AllReviewsMarkup";

export class ReviewContainer extends Component {
  state = {
    loading: true,
    isError: false,
    showAll: false,
    isNotReviewed: false,
    reviews: [],
    review: {},
    currMemberIndex: 0,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  componentDidMount() {
    this._setReviews();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currMemberId !== prevProps.currMemberId) {
      this._setReviews();
    }
  }

  async _setReviews() {
    await this._validateState();
    const { showAll, isLoadedReviews, isError } = this.state;
    const { currMemberId, members } = this.props;

    if (!isError)
      if (showAll) {
        if (!isLoadedReviews) this._getAllReviews();
        else this._unsetLoading();
      } else {
        const currMemberIndex = members.findIndex(
          (_member) => _member.id === currMemberId
        );
        const member = members[currMemberIndex];

        if (member.review.isReviewed) {
          this.setState({
            currMemberIndex,
          });
          this._getReview(member.review.id);
        } else
          this.setState({
            loading: false,
            isNotReviewed: true,
            currMemberIndex,
          });
      }
    else this._unsetLoading();
  }

  async _getAllReviews() {
    try {
      const reviews = (await axios.get("/review/get_all")).data;
      this.setState({ reviews });
    } catch (e) {
      return;
    } finally {
      this.setState({ loading: false, isLoadedReviews: true });
    }
  }

  async _getReview(reviewId) {
    try {
      const review = await axios.get(`/review/get/${reviewId}`);
      this.setState({ review: review.data });
    } catch (e) {
      return;
    } finally {
      this._unsetLoading();
    }
  }

  _setReview(review) {
    this.setState({
      review: { ...this.state.review, ...review },
      isNotReviewed: false,
      isLoadedReviews: false,
    });
  }

  _unsetReview() {
    this.setState({
      review: {},
      isNotReviewed: true,
      isLoadedReviews: false,
    });
  }

  _unsetLoading() {
    this.setState({ loading: false, isNotReviewed: false });
  }

  async _validateState() {
    await this.setStateAsync({ loading: true });
    const { currMemberId, members } = this.props;
    const showAll = !Boolean(currMemberId);
    const isError =
      Boolean(currMemberId) &&
      members.findIndex((member) => member.id === currMemberId) < 0;

    await this.setStateAsync({ isError, showAll });
  }

  render() {
    const { currMemberId, members, isFamily } = this.props;
    const {
      loading,
      isError,
      showAll,
      isNotReviewed,
      currMemberIndex,
      review,
      reviews,
    } = this.state;

    return loading ? (
      <ReviewLoadingSeleton
        individual={Boolean(currMemberId)}
        isFamily={isFamily}
      />
    ) : isError ? (
      <ReviewError />
    ) : showAll ? (
      <AllReviewsMarkup
        reviews={reviews}
        members={members}
        isFamily={isFamily}
      />
    ) : isNotReviewed ? (
      <NewReview
        member={members[currMemberIndex]}
        onSuccess={(review) => this._setReview(review)}
        isFamily={isFamily}
      />
    ) : (
      <ReviewMarkup
        review={review}
        member={members[currMemberIndex]}
        onSuccessEdit={(review) => this._setReview(review)}
        onSuccessDelete={() => this._unsetReview()}
        isFamily={isFamily}
      />
    );
  }
}

ReviewContainer.propTypes = {
  currMemberId: PropTypes.string,
  members: PropTypes.array.isRequired,
  isFamily: PropTypes.bool.isRequired,
};

export default ReviewContainer;
