import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/uiActions";

// Component imports
import InjectedCheckoutForm from "../../../../components/inputs/Stripe/InjectedCheckoutForm";
import StripeLogo from "../../../../assets/creditBrand/StripeBurple.svg";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class AddPaymentMethodDialogButton extends Component {
  state = { open: false };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleCompletePaymentMethod = () => {
    this.props.setAlert({ message: "Credit card added!", type: "success" });
    this.handleClose();
  };

  render() {
    const { myInfo, variant, color, style, fullWidth, startIcon, classes } =
      this.props;
    const { open } = this.state;
    return (
      <Fragment>
        <Button
          variant={variant ? variant : "contained"}
          color={color ? color : "primary"}
          onClick={this.handleClickOpen}
          fullWidth={fullWidth ? fullWidth : true}
          style={style ? style : { textTransform: "none" }}
          startIcon={
            startIcon ? startIcon : <AddIcon style={{ fontWeight: 300 }} />
          }
        >
          Add Payment Method
        </Button>
        <Dialog open={open} keepMounted onClose={this.handleClose}>
          <Box
            className={classes.headerBarRounded}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Add a new payment method</Typography>
            <IconButton size="small" onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText component="div">
              Review and check that the information entered is{" "}
              <Box display="inline" style={{ fontWeight: 550 }}>
                correct
              </Box>{" "}
              and{" "}
              <Box display="inline" style={{ fontWeight: 550 }}>
                accurate
              </Box>
              . This card will be charged automatically for your caregiving
              services as provided.
            </DialogContentText>
            <InjectedCheckoutForm
              myInfo={myInfo}
              completePaymentMethod={this.handleCompletePaymentMethod}
            />
            <Box style={{ marginTop: 30 }}>
              <Box className={classes.flexBox}>
                <LockIcon style={{ fontSize: 12 }} />
                <Typography color="textSecondary" style={{ fontSize: 12 }}>
                  Secure transaction SSL encryption
                </Typography>
              </Box>

              <img src={StripeLogo} alt="Stripe Logo" style={{ height: 20 }} />
            </Box>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

AddPaymentMethodDialogButton.propTypes = {
  classes: PropTypes.object.isRequired,
  myInfo: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
};

export default connect(null, { setAlert })(
  withStyles(styles)(AddPaymentMethodDialogButton)
);
