import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Component imports
import NotificationButton from "./NotificationButton";

// MUI Imports
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import withStyles from "@mui/styles/withStyles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Icons Imports
import AccountIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkIcon from "@mui/icons-material/Work";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import GroupIcon from "@mui/icons-material/Group";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import MessageIcon from "@mui/icons-material/Message";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,

  title: {
    flexGrow: 1,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});
function AuthNav(props) {
  const { classes, notifications, isSubscribed, userType, page, handleLogout } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.title}>
        {[
          "Dashboard",
          userType === "family" ? "Caregivers" : "Jobs",
          "MyTeam",
          "Messages",
          "MyPay",
        ].map((text, index) => (
          <Tooltip
            disableFocusListener
            enterTouchDelay={0}
            key={text}
            title={text}
          >
            <IconButton
              // onClick={this.handleDrawerClose}
              component={Link}
              size="large"
              color="inherit"
              to={`/${text.toLowerCase()}/`}
              style={{ borderRadius: 4 }}
            >
              {index === 0 ? (
                page === text ? (
                  <HomeIcon />
                ) : (
                  <HomeOutlinedIcon />
                )
              ) : index === 1 ? (
                page === text ? (
                  <WorkIcon />
                ) : (
                  <WorkOutlineOutlinedIcon />
                )
              ) : index === 2 ? (
                page === text ? (
                  <GroupIcon />
                ) : (
                  <GroupOutlinedIcon />
                )
              ) : index === 3 ? (
                page === text ? (
                  <MessageIcon />
                ) : (
                  <MessageOutlinedIcon />
                )
              ) : page === text ? (
                <PaymentIcon />
              ) : (
                <PaymentOutlinedIcon />
              )}
            </IconButton>
          </Tooltip>
        ))}
      </div>
      <div>
        <NotificationButton
          isSubscribed={isSubscribed}
          notifications={notifications}
        />
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          size="large"
        >
          <AccountIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem component={Link} to="/profile/" onClick={handleClose}>
            Profile
          </MenuItem>
          <MenuItem component={Link} to="/resources" onClick={handleClose}>
            Help
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </>
  );
}

AuthNav.propTypes = {
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default withStyles(styles)(AuthNav);
