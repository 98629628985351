import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Component imports
import IntroPage from "./HirePageComps/IntroPage";
import PaymentSetup from "./HirePageComps/PaymentSetup";
import TeamInviteMenu from "./HirePageComps/TeamInviteMenu";
import InvitationSent from "./HirePageComps/InvitationSent";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

export class cgHirePage extends Component {
  state = {
    isLoaded: false,
    userData: {},
    introCheck: false,
    paymentCheck: false,
    teamInviteCheck: false,
  };

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    const cgUserIdNumber = this.props.match.params.userIdNumber;
    axios
      .get(`/user/getuser/${cgUserIdNumber}`)
      .then((doc) => {
        this.setState({ isLoaded: true, userData: doc.data.credentials });
      })
      .catch((err) => console.error(err));
  }

  // UI Methods

  handleIntro = () => {
    this.setState({ introCheck: true });
  };

  handlePayment = (v) => {
    this.setState({ paymentCheck: true });
  };

  handlePaymentBack = () => {
    this.setState({ introCheck: false });
  };

  // Action Methods

  handleTeamInv = async (v) => {
    this.setState({ teamInviteCheck: true });
  };

  render() {
    const { isLoaded, userData, introCheck, paymentCheck, teamInviteCheck } =
      this.state;

    return (
      <div className="res-gr-con">
        {!isLoaded && (
          <Grid container>
            <Grid item style={{ marginTop: 50, textAlign: "center" }} xs={12}>
              <CircularProgress thickness={2} size={200} />
            </Grid>
          </Grid>
        )}
        {isLoaded && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: 500, padding: "0px 8px" }}
            spacing={2}
          >
            {!introCheck && (
              <Grid item xs={12} sm={9} md={6}>
                <IntroPage
                  userData={userData}
                  handleIntro={this.handleIntro}
                  history={this.props.history}
                />
              </Grid>
            )}
            {introCheck && !paymentCheck && (
              <Grid item xs={12} sm={9} md={6}>
                <PaymentSetup
                  userData={userData}
                  handlePayment={this.handlePayment}
                  handlePaymentBack={this.handlePaymentBack}
                />
              </Grid>
            )}
            {introCheck && paymentCheck && !teamInviteCheck && (
              <Grid item xs={12}>
                <TeamInviteMenu
                  handleTeamInv={this.handleTeamInv}
                  userData={userData}
                />
              </Grid>
            )}
            {introCheck && paymentCheck && teamInviteCheck && (
              <Grid item xs={12} sm={9} md={6}>
                <InvitationSent
                  userData={userData}
                  history={this.props.history}
                />
              </Grid>
            )}
          </Grid>
        )}
      </div>
    );
  }
}

cgHirePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default cgHirePage;
