import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Component imports
import { capitalizeFirstChar } from "../../../../util/utilFunctions";
import ProfilePicUploadButton from "../../../../components/inputs/ProfilePicUploadButton";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Hidden from "@mui/material/Hidden";
import CircularProgress from "@mui/material/CircularProgress";

// Icons imports
import LocationOnIcon from "@mui/icons-material/LocationOn";

const styles = (theme) => ({
  ...theme.spreadThis,
  media: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "50%",
    border: `3px solid ${theme.palette.primary.main}`,
  },
  mediaBox: {
    height: 350,
    width: 350,
    position: "relative",

    [theme.breakpoints.down("md")]: {
      height: 250,
      width: 250,
    },
  },
});
export class ProfilePicMarkup extends Component {
  state = { disableWhileLoad: false, errors: {} };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI Methods

  handleLoad = () => {
    this.setState({ disableWhileLoad: true, errors: {} });
  };
  handleStopLoad = () => {
    this.setState({ disableWhileLoad: false });
  };
  setError = (errors) => {
    this.setState({ errors });
  };

  handlePageChange = () => {
    this.props.handlePageChange();
  };
  render() {
    const {
      classes,
      credentials,
      credentials: {
        address: { addressParams },
      },
    } = this.props;

    const { disableWhileLoad, errors } = this.state;
    return (
      <Fade in>
        <div>
          <Typography
            variant="h5"
            align="center"
            color="primary"
            className={classes.typography}
          >
            Profile Image
          </Typography>

          <Typography
            align="center"
            className={classes.typography}
            style={{ fontWeight: 300, marginTop: 16 }}
          >
            It's time to set up your profile picture which will be viewable to
            all families. Click the camera icon at the bottom to select and
            upload a photo.
          </Typography>
          <div className={classes.flexBoxCentered} style={{ marginTop: 16 }}>
            <div className={classes.mediaBox}>
              <img
                src={credentials.imgUrlThumb}
                alt="Profile Pic"
                className={classes.media}
                style={
                  disableWhileLoad
                    ? {
                        position: "absolute",
                        filter: "grayscale(50%)",
                      }
                    : {}
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = credentials.imgUrl;
                }}
              />

              {disableWhileLoad ? (
                <Fragment>
                  <Hidden smDown>
                    <CircularProgress thickness={2} size={350} />
                  </Hidden>
                  <Hidden smUp>
                    <CircularProgress thickness={2} size={250} />
                  </Hidden>
                </Fragment>
              ) : (
                <ProfilePicUploadButton
                  onLoad={this.handleLoad}
                  onFinish={this.handleStopLoad}
                  onError={this.setError}
                  style={{ bottom: 0, right: "15%" }}
                />
              )}
            </div>
          </div>

          {Boolean(errors.file) && (
            <Typography
              variant="body2"
              align="center"
              color="error"
              style={{ margin: "16px 0px" }}
            >
              {errors.file}
            </Typography>
          )}
          <Typography
            align="center"
            style={{ marginTop: 16 }}
            className={classes.typography}
          >
            {capitalizeFirstChar(credentials.firstName)}{" "}
            {capitalizeFirstChar(credentials.lastName)}
          </Typography>
          <div className={classes.flexBoxCentered}>
            <LocationOnIcon color="primary" />
            <Typography
              variant="body2"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              {capitalizeFirstChar(addressParams.locality)}
              {", "}
              {addressParams.adminAreaLevel1.toUpperCase()}
            </Typography>
          </div>
          <div className={classes.flexBoxCentered} style={{ marginTop: 16 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handlePageChange}
              style={{ width: 100 }}
            >
              {credentials.status.isUserImgCompleted ? "Next" : "Skip"}
            </Button>
          </div>
        </div>
      </Fade>
    );
  }
}

ProfilePicMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  loadingSpecial: PropTypes.bool,
  handlePageChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProfilePicMarkup);
