import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter

// Component imports
import { capitalizeFirstChar } from "../../../util/utilFunctions";
import OutlinedProfilePic from "../../layout/OutlinedProfilePic";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  primaryText: { fontWeight: "550", color: theme.palette.secondary.light },
});

export class TeamInviteSelect extends Component {
  render() {
    const {
      classes,
      teamInvite,
      teamInvite: { familyInfo, caregiverInfo },
      isFamily,
    } = this.props;
    return (
      <CardActionArea
        key={teamInvite.invId}
        component={Link}
        to={`/invites/${teamInvite.invId}`}
        style={{
          margin: "4px 0px",
          padding: 8,
          border: "1px solid rgba(212,212,212,0.7)",
          borderRadius: 10,
        }}
      >
        <Box className={classes.flexBoxSpaced}>
          <OutlinedProfilePic
            imgSrc={
              isFamily ? caregiverInfo.imgUrlThumb : familyInfo.imgUrlThumb
            }
            size={35}
          />
          <Box
            style={{
              width: "calc(100% - 49px)",
              marginLeft: 8,
            }}
          >
            <Box className={classes.flexBoxSpaced}>
              <Typography align="left">
                {isFamily
                  ? `${capitalizeFirstChar(
                      caregiverInfo.firstName
                    )} ${caregiverInfo.lastName[0].toUpperCase()}`
                  : `${capitalizeFirstChar(
                      familyInfo.firstName
                    )} ${familyInfo.lastName[0].toUpperCase()}`}
                .
              </Typography>
              <Chip
                label={
                  teamInvite.isDeclined
                    ? "Declined"
                    : teamInvite.isAccepted
                    ? "Accepted"
                    : "Pending Action"
                }
                size="small"
                className={
                  teamInvite.isDeclined
                    ? classes.chipOrj
                    : teamInvite.isAccepted
                    ? classes.chipGreen
                    : classes.chipBlue
                }
              />
            </Box>
            <Typography variant="body2" color="textSecondary" noWrap>
              {teamInvite.message}
            </Typography>
          </Box>
        </Box>

        <Typography
          component="div"
          align="right"
          variant="caption"
          color="textSecondary"
        >
          Received: {dayjs(teamInvite.createdAt).fromNow()}
        </Typography>
      </CardActionArea>
    );
  }
}

TeamInviteSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  teamInvite: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamInviteSelect);
