import React, { Component } from "react";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";

import { capitalizeFirstChar } from "../../util/utilFunctions";

import Typography from "@mui/material/Typography";

const allColors = [
  "#7D3AC1",
  "#AF4BCE",
  "#DB4CB2",
  "#EB548C",
  "#EA7369",
  "#F0A58F",
  "#FCEAE6",
];

export class InvoiceBar extends Component {
  state = {
    currMonth: dayjs().get("M"),
    labels: [],
    datasets: [],
    labelLegend: {
      0: "Jan",
      1: "Feb",
      2: "Mar",
      3: "Apr",
      4: "May",
      5: "Jun",
      6: "Jul",
      7: "Aug",
      8: "Sep",
      9: "Oct",
      10: "Nov",
      11: "Dec",
    },
  };

  componentDidMount() {
    (async () => {
      await this.setLabel();
      await this.splitInvoicesByMemberID();
    })();
  }

  componentDidUpdate(prevProps) {
    const { updateGraph } = this.props;

    if (updateGraph && !prevProps.updateGraph) {
      (async () => {
        await this.setLabel();
        await this.splitInvoicesByMemberID();
      })();
    }
  }

  setLabel() {
    const { currMonth, labelLegend } = this.state;

    let labels = [];
    labels.unshift(labelLegend[currMonth]);
    let i = currMonth - 1;

    while (i !== currMonth) {
      labels.unshift(labelLegend[i]);
      if (i === 0) {
        i = 12;
      }
      i--;
    }
    this.setState({ labels });
  }

  setData(filteredInvoices) {
    const { labels, labelLegend } = this.state;
    const { userType } = this.props;

    let dataSet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    filteredInvoices.forEach((invoice) => {
      let month = dayjs(invoice.createdAt).get("M");
      let monthPos = labels.findIndex((mo) => mo === labelLegend[month]);
      let currSum = dataSet[monthPos];
      let newSum = 0;
      if (userType === "caregiver") {
        newSum = currSum + invoice.invoiceBreakdown.netIncome;
      } else if (userType === "family") {
        newSum =
          currSum + invoice.invoiceBreakdown.payableBreakdown.totalPayable;
      }
      dataSet[monthPos] = Math.round(newSum * 100) / 100;
    });

    return dataSet;
  }

  splitInvoicesByMemberID() {
    const { invoices, userType } = this.props;

    // Get array of memberIds
    const _memberIds = {};
    invoices.forEach((invoice) => {
      _memberIds[invoice.memberInfo.memberId] = true;
    });
    const memberIds = Object.keys(_memberIds).sort();

    // Declare container for all data sets
    const datasets = [];

    memberIds.forEach((id, index) => {
      // Get array of invoices per unique memberId
      const thisMemberIdInvoices = invoices.filter(
        (invoice) => invoice.memberInfo.memberId === id
      );

      const {
        familyInfo: { clientFirstName, clientLastName },
        caregiverInfo: { firstName, lastName },
      } = thisMemberIdInvoices[0];

      // Set dataset per chartjs
      const dataSet = {
        label:
          userType === "caregiver"
            ? `${capitalizeFirstChar(clientFirstName)} ${capitalizeFirstChar(
                clientLastName
              )}`
            : `${capitalizeFirstChar(firstName)} ${capitalizeFirstChar(
                lastName
              )}`,
        data: this.setData(thisMemberIdInvoices),
        backgroundColor: allColors[index % 7],
        borderColor: allColors[index % 7],
        borderWidth: 1,
        datalabels: {
          align: "center",
          anchor: "center",
        },
      };
      datasets.push(dataSet);
    });
    this.setState({ datasets });
  }

  render() {
    const { labels, datasets } = this.state;
    const { userType } = this.props;

    const data = {
      labels: labels,
      datasets: datasets,
    };

    const options = {
      plugins: {
        tooltip: {
          callbacks: {
            title: function (context) {
              return `${context[0].label}`;
            },
            label: function (context) {
              let label = context.dataset.label || "";
              let value = context.raw;

              if (label) {
                label += ": ";
              }
              if (value !== null) {
                label += new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(value);
              }
              return label;
            },
            footer: function (context) {
              const total = context.reduce((a, b) => a + b.raw, 0);
              return `TOTAL: ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(total)}`;
            },
          },
        },
        datalabels: {
          backgroundColor: function (context) {
            return context.dataset.backgroundColor;
          },
          borderColor: "white",
          borderRadius: 25,
          borderWidth: 2,
          color: "white",
          display: function (context) {
            var dataset = context.dataset;
            var count = dataset.data.length;
            var value = dataset.data[context.dataIndex];
            return value > count * 1.5;
          },
          font: {
            weight: "bold",
          },
          padding: 6,
          formatter: function (value) {
            return "$" + value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        aspectRatio: 4 / 3,
        cutoutPercentage: 32,
        layout: {
          padding: 32,
        },
        elements: {
          line: {
            fill: false,
          },
          point: {
            hoverRadius: 7,
            radius: 5,
          },
        },
      },

      scales: {
        y: {
          ticks: {
            beginAtZero: true,
            fontFamily: "'Open Sans Bold', sans-serif",
            fontSize: 11,
          },

          stacked: true,
        },
        x: {
          ticks: {
            fontFamily: "'Open Sans Bold', sans-serif",
            fontSize: 11,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      },
    };

    return (
      <div>
        <Typography align="center" color="textSecondary" variant="h6">
          {userType === "caregiver" ? "TTM Income" : "TTM Costs"}
        </Typography>
        <Bar data={data} plugins={[ChartDataLabels]} options={options} />
      </div>
    );
  }
}

InvoiceBar.propTypes = {
  invoices: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
  updateGraph: PropTypes.bool,
};

export default InvoiceBar;
