import React, { Component } from "react";
import PropTypes from "prop-types";

import Icons from "../../assets/icons/iconsMain";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

export class ButtonSelectUnique extends Component {
  handleSelectValue = (e) => {
    this.props.handleSelectValue(e);
  };
  render() {
    const {
      classes,
      valueObj,
      value,
      error,
      selIcon,
      unselIcon,
      showIcon,
      serviceIcons,
      noSort,
      xs,
      sm,
      md,
      lg,
    } = this.props;

    let entries = Object.entries(valueObj);
    if (!noSort) {
      entries = entries.sort();
    }
    return (
      <Grid
        container
        justifyContent="center"
        spacing={1}
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        {entries.map((keys) => (
          <Grid
            item
            xs={xs ? xs : 12}
            sm={sm ? sm : 5}
            md={md ? md : false}
            lg={lg ? lg : false}
            key={keys}
          >
            <StyledButton
              value={keys[0]}
              onClick={this.handleSelectValue}
              fullWidth
              className={
                error
                  ? value.includes(keys[0])
                    ? classes.errorSelectedToggleButton
                    : classes.errorUnselectedToggleButton
                  : value.includes(keys[0])
                  ? classes.selectedToggleButton
                  : classes.unselectedToggleButton
              }
              // style={{ paddingRight: 10, paddingLeft: 10 }}
            >
              {showIcon && (value.includes(keys[0]) ? selIcon : unselIcon)}
              {serviceIcons && (
                <SvgIcon>
                  <path d={Icons[keys[0]]} />
                </SvgIcon>
              )}
              <Typography
                component="p"
                variant="body2"
                align="center"
                color={
                  !error && !value.includes(keys[0])
                    ? "textSecondary"
                    : "inherit"
                }
                style={{
                  width: `calc(100% - 39px)`,
                }}
                noWrap
              >
                {keys[1]}
              </Typography>
            </StyledButton>
          </Grid>
        ))}
      </Grid>
    );
  }
}

ButtonSelectUnique.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  valueObj: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  handleSelectValue: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  noSort: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

export default withStyles(styles)(ButtonSelectUnique);
