import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class MsgText extends Component {
  state = { isShowTime: false };
  render() {
    const {
      myMsg,
      classes,
      userType,
      currentMessageChannelData: { familyInfo, caregiverInfo },
      msg: { firstName, lastName, body, createdAt },
    } = this.props;
    const { isShowTime } = this.state;

    return (
      // Set initial viewport for msg and justify flex based on msg
      <Box
        display="flex"
        justifyContent={myMsg ? "flex-end" : "flex-start"}
        style={{ margin: "8px 0px" }}
        onMouseDown={() => {
          this.setState({ isShowTime: !isShowTime });
        }}
      >
        {/* // Set width of flex element to prevent overflow */}
        <Box style={{ width: "75%" }} display="flex">
          {!myMsg && (
            <Avatar
              alt={`${firstName}${lastName}`}
              src={
                userType === "family"
                  ? caregiverInfo.imgUrlThumb
                  : familyInfo.imgUrlThumb
              }
              className={classes.imgThumb}
            />
          )}

          {/* // Set up element for message text accounting for avatar display. Must set width for text wrap purposes. */}
          <Box
            style={{
              width: !myMsg ? "calc(100% - 40px)" : "100%",
              whiteSpace: "pre-line",
            }}
            display="flex"
            flexDirection="column"
          >
            {/* // Setup element to align message text based on myMsg */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems={myMsg ? "flex-end" : "flex-start"}
            >
              <Typography
                variant="body2"
                className={!myMsg ? classes.msgText : classes.msgTextB}
                style={{ whiteSpace: "pre-line" }}
              >
                {body}
              </Typography>
              <Collapse in={isShowTime} unmountOnExit mountOnEnter>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ marginLeft: 10 }}
                >
                  {dayjs().diff(createdAt, "d") < 7
                    ? dayjs(createdAt).format("dddd hh:mma")
                    : dayjs(createdAt).format("MMM DD, YYYY dddd hh:mma")}
                </Typography>
              </Collapse>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

MsgText.propTypes = {
  myMsg: PropTypes.bool.isRequired,
  msg: PropTypes.object.isRequired,
};

export default withStyles(styles)(MsgText);
