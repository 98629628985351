import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import CaregiverDashboard from "../../caregiverPages/dashboard/CaregiverDashboard";
import FamilyDashboard from "../../familyPages/dashboard/FamilyDashboard";
import LoadingEllipsis from "../../../components/layout/LoadingEllipsis";

// Redux imports
import { connect } from "react-redux";
import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const styles = (theme) => ({
  ...theme.spreadThis,

  containerDivB: {
    marginTop: 10,
    width: "100%",
    position: "absolute",
    height: "100%",
  },
});

export class dashboard extends Component {
  componentDidMount() {
    store.dispatch({ type: SET_PAGE, payload: "Dashboard" });
  }

  componentWillUnmount() {
    store.dispatch({ type: UNSET_PAGE });
  }
  render() {
    const {
      user: {
        authenticated,
        loading,
        credentials: { userType },
      },
      classes,
      history,
    } = this.props;

    return (
      <Fragment>
        {loading && authenticated ? (
          <div className={classes.containerDivB}>
            <Box
              style={{
                paddingTop: 20,
                textAlign: "center",
              }}
            >
              <CircularProgress thickness={2} size={250} />
              <LoadingEllipsis
                component="div"
                variant="caption"
                color="textSecondary"
                style={{ marginTop: 10, fontWeight: 300 }}
                prefix="Loading Profile"
              />
            </Box>
          </div>
        ) : (
          <div className="res-gr-con">
            {userType === "family" ? (
              <FamilyDashboard history={history} />
            ) : userType === "caregiver" ? (
              <CaregiverDashboard />
            ) : null}
          </div>
        )}
      </Fragment>
    );
  }
}

dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  UI: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps)(withStyles(styles)(dashboard));
