import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Redux imports
import { connect } from "react-redux";
import { setData } from "../../../redux/actions/dataActions";

// Component imports
import MemberSelectContainer from "../../../components/shiftsTasks/components/MemberSelectContainer";
import ReviewLoadingSeleton from "../../../components/review/ReviewLoadingSeleton";
import ReviewContainer from "../../../components/review/ReviewContainer";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class reviews extends Component {
  componentDidMount() {
    this._getTeamMembers();
  }

  async _getTeamMembers() {
    try {
      const teams = (await axios.get("/team")).data;
      await this.props.setData({
        ...teams,
        isLoadedTeams: true,
      });
    } catch (e) {
      await this.props.setData({ isLoadedTeams: true });
      return;
    }
  }

  _selectMember = (memberId) => {
    this.props.history.push(`/reviews/${memberId === "all" ? "" : memberId}`);
  };

  render() {
    const {
      classes,
      match: { params },
      data: { members, isLoadedTeams },
      userType,
    } = this.props;

    const isFamily = userType === "family";

    return (
      <div className="res-gr-con">
        <Grid container spacing={1}>
          <Grid
            item
            component={Box}
            display={{ xs: "none", sm: "none", md: "block" }}
            md={4}
          >
            <Paper
              elevation={2}
              className={classes.paperContainerC}
              style={{ minHeight: 400 }}
            >
              <MemberSelectContainer
                onSelectMember={this._selectMember}
                currMemberId={params.memberId}
                isReview
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper elevation={2} className={classes.paperContainerC}>
              {isLoadedTeams ? (
                <ReviewContainer
                  members={members}
                  currMemberId={params.memberId}
                  isFamily={isFamily}
                />
              ) : (
                <ReviewLoadingSeleton
                  isFamily={isFamily}
                  individual={Boolean(params.memberId)}
                />
              )}
            </Paper>
          </Grid>
          {/* <Grid item xs={12}>
              <ReviewLoadingSeleton
                isFamily={isFamily}
                individual={Boolean(params.memberId)}
              />
            </Grid> */}
        </Grid>
      </div>
    );
  }
}

reviews.propTypes = {
  classes: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userType: state.user.credentials.userType,
  data: state.data,
});

export default connect(mapStateToProps, { setData })(
  withStyles(styles)(reviews)
);
