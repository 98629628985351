import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter
import { isMobile } from "react-device-detect";
import axios from "axios";

// Redux imports
import { connect } from "react-redux";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";
import withStyles from '@mui/styles/withStyles';
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0,
    },
  },
  focusHighlight: {},
  unreadMarker: {
    width: 8,
    height: 8,
    backgroundColor: `${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
});

export class JobAppMarkupCompact extends Component {
  state = { showMore: false };

  handleShowMore = () => {
    this.handleMarkRead();
    this.setState({ showMore: !this.state.showMore });
  };

  handleMarkRead = async () => {
    const {
      jobApp,
      credentials: { userType },
    } = this.props;
    if (userType === "family" && !jobApp.isRead) {
      try {
        await axios.get(`/job-applications/${jobApp.id}/mark-read`);

        if (this.props.markRead) {
          this.props.markRead(jobApp.id, { isRead: true });
        }
      } catch (err) {
        return;
      }
    }
    return;
  };
  render() {
    const {
      classes,
      credentials: { userType },
      jobApp,
      jobApp: { familyInfo, caregiverInfo },
    } = this.props;

    const { showMore } = this.state;

    const isActioned = jobApp.isAccepted || jobApp.isDeclined;

    return (
      <Box display="flex" style={{ margin: "4px 0px" }}>
        <CardActionArea
          onClick={this.handleShowMore}
          classes={
            isMobile
              ? {
                  root: classes.actionArea,
                  focusHighlight: classes.focusHighlight,
                }
              : {}
          }
        >
          <Box
            p={1}
            display="flex"
            flexDirection="column"
            style={{ width: "100%" }}
          >
            <Box className={classes.flexBoxSpaced}>
              <Box
                display="flex"
                alignItems="center"
                style={{ width: "calc(100% - 80px)" }}
              >
                <Avatar
                  src={
                    userType === "family"
                      ? caregiverInfo.imgUrlThumb
                      : familyInfo.imgUrlThumb
                  }
                />
                <Typography
                  noWrap
                  style={{ marginLeft: 8, width: "calc(100% - 48px)" }}
                >
                  {capitalizeFirstChar(
                    userType === "family"
                      ? caregiverInfo.firstName
                      : familyInfo.firstName
                  )}{" "}
                  {capitalizeFirstChar(
                    userType === "family"
                      ? caregiverInfo.lastName
                      : familyInfo.lastName
                  )}
                </Typography>
              </Box>
              {!jobApp.isRead ? (
                <Chip size="small" label="Unread" className={classes.chipOrj} />
              ) : jobApp.isAccepted ? (
                <Chip
                  size="small"
                  label="Accepted"
                  className={classes.chipGreen}
                />
              ) : jobApp.isDeclined ? (
                <Chip
                  size="small"
                  label="Declined"
                  className={classes.chipOrj}
                />
              ) : (
                jobApp.isRead && (
                  <Chip
                    size="small"
                    label="Read"
                    className={classes.chipBlue}
                  />
                )
              )}
            </Box>
            <Collapse in={showMore} collapsedSize={68}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  fontWeight: 300,
                  margin: "4px 0px",
                }}
              >
                Job ID: {jobApp.jobId}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                noWrap={!showMore}
                style={{
                  fontWeight: 300,
                  whiteSpace: showMore && "pre-line",
                }}
              >
                {jobApp.message}
              </Typography>
              {showMore && (
                <Button
                  component={Link}
                  to={`/jobs/applications/${jobApp.id}`}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  style={{ margin: "8px 0px" }}
                >
                  {userType === "family" && !isActioned
                    ? "Action"
                    : "View Details"}
                </Button>
              )}
              <Typography
                variant="caption"
                component="div"
                align="right"
                color="textSecondary"
              >
                Sent: {dayjs(jobApp.createdAt).fromNow()}
              </Typography>
            </Collapse>
          </Box>
        </CardActionArea>
      </Box>
    );
  }
}

JobAppMarkupCompact.propTypes = {
  credentials: PropTypes.object.isRequired,
  jobApp: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  markRead: PropTypes.func,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps)(
  withStyles(styles)(JobAppMarkupCompact)
);
