import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import clsx from "clsx";

// Component imports
import TooltipButton from "../../../../components/inputs/TooltipButton";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Hidden from "@mui/material/Hidden";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";

const styles = (theme) => ({
  ...theme.spreadThis,
  availChip: {
    backgroundColor: theme.palette.primary.light,
  },
  delAvailChip: {
    backgroundColor: "rgba(245, 221,219, 1)",
    color: "red",
    position: "relative",
  },
  notAvailChip: {
    backgroundColor: "rgba(245,245,245,0.5)",
    width: "100%",
  },
  dayHeader: {
    padding: "5px 0",
    backgroundColor: "rgba(18, 153, 158, 0.5)",
    fontWeight: 550,
    borderRadius: 7,
  },
  availColumn: {
    width: `${100 / 8}%`,
    marginRight: `${100 / 8 / 14}%`,
    marginLeft: `${100 / 8 / 14}%`,
  },
  mobileHeader: {
    width: 40,
    height: 50,
    backgroundColor: "rgba(18, 153, 158, 0.5)",
    fontWeight: 550,
    borderRadius: 7,
  },
  delIcon: {
    position: "absolute",
    // background: "rgba(200,200,200,0.45)",
    // "&:hover": {
    //   background: "rgba(200,200,200,0.75)",
    // },
    top: -14,
    right: -14,
  },
});

export class MyAvail extends Component {
  render() {
    const { availData, classes, showDelete } = this.props;

    const sortedAvailData = [[], [], [], [], [], [], []];

    availData.forEach((avail) => sortedAvailData[avail.dayId].push(avail));

    sortedAvailData.forEach((avails, ind) =>
      avails.sort((a, b) =>
        a.startTimeComp > b.startTimeComp
          ? 1
          : a.startTimeComp < b.startTimeComp
          ? -1
          : 0
      )
    );

    const showAvail = (obj, isOne) => (
      <Paper
        elevation={4}
        key={obj.startTimeISO}
        className={showDelete ? classes.delAvailChip : classes.availChip}
        style={{
          width: "100%",
          height: "fit-content",
          minHeight: 15 * (obj.endTimeComp - obj.startTimeComp),
          margin: "4px 0px",
        }}
      >
        <Box
          className={classes.flexBoxSpacedColumn}
          style={{ flexDirection: "column", padding: 4, minHeight: "inherit" }}
        >
          {showDelete && (
            <TooltipButton
              tip={"Delete"}
              onClick={() => this.props.onDelAvail(obj.id)}
              size={"small"}
              tipClassName={classes.delIcon}
            >
              <CancelIcon style={{ fontSize: 20 }} />
            </TooltipButton>
          )}
          <Typography variant="body2">
            <span style={{ fontWeight: "bold" }}>Start</span>{" "}
            {dayjs(obj.startTimeISO).format("h:mma")}
          </Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: "bold" }}>End</span>{" "}
            {dayjs(obj.endTimeISO).format("h:mma")}
          </Typography>
        </Box>
      </Paper>
    );

    const showAvailMobile = (obj) => (
      <Paper
        key={obj.startTimeISO}
        className={showDelete ? classes.delAvailChip : classes.availChip}
        style={{
          margin: "0 2px",
          padding: "0 3px",
        }}
      >
        <Box
          className={classes.flexBoxSpacedColumn}
          style={{ height: "100%", position: "relative" }}
        >
          {showDelete && (
            <TooltipButton
              tip={"Delete"}
              onClick={() => this.props.onDelAvail(obj.id)}
              size={"small"}
              tipClassName={classes.delIcon}
            >
              <CancelIcon style={{ fontSize: 20 }} />
            </TooltipButton>
          )}
          <Typography variant="body2">
            <span style={{ fontWeight: "bold" }}>Start: </span>
            {dayjs(obj.startTimeISO).format("h:mma")}
          </Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: "bold" }}>End: </span>
            {dayjs(obj.endTimeISO).format("h:mma")}
          </Typography>
        </Box>
      </Paper>
    );

    const showNotAvail = (
      <div
        className={clsx(classes.notAvailChip, classes.flexBoxCenteredColumn)}
      >
        <Typography variant="body2">Not Available</Typography>
      </div>
    );

    const availChart = sortedAvailData.map((avails, ind) => {
      const newInd = ind === 0 ? 6 : 7 % ind;
      return (
        <div className={classes.availColumn} key={ind}>
          <Typography
            variant="body2"
            align="center"
            className={classes.dayHeader}
            fontWeight="fontWeightBold"
          >
            {7 % ind === 0
              ? "Mon"
              : 7 % ind === 1
              ? "Tues"
              : 7 % ind === 2
              ? "Wed"
              : 7 % ind === 3
              ? "Thur"
              : 7 % ind === 4
              ? "Fri"
              : 7 % ind === 5
              ? "Sat"
              : "Sun"}
          </Typography>
          <Divider style={{ margin: "4px 0px" }} />
          <div
            className={classes.flexBoxCenteredColumn}
            style={{
              height: 360,
              backgroundColor: "rgba(245,245,245,0.5)",
            }}
          >
            {sortedAvailData[newInd].length < 1
              ? showNotAvail
              : sortedAvailData[newInd].map((avail) =>
                  showAvail(avail, sortedAvailData[newInd].length > 1)
                )}
          </div>
        </div>
      );
    });

    const mobileAvailChart = sortedAvailData.map((avails, ind) => {
      const newInd = ind === 0 ? 6 : 7 % ind;

      return (
        <div
          className={classes.flexBoxSpaced}
          xs={12}
          style={{ marginBottom: 3, width: "100%" }}
          key={ind}
        >
          <Typography
            variant="body2"
            align="center"
            className={clsx(
              classes.flexBoxCenteredColumn,
              classes.mobileHeader
            )}
            fontWeight="fontWeightBold"
          >
            {7 % ind === 0
              ? "Mon"
              : 7 % ind === 1
              ? "Tues"
              : 7 % ind === 2
              ? "Wed"
              : 7 % ind === 3
              ? "Thur"
              : 7 % ind === 4
              ? "Fri"
              : 7 % ind === 5
              ? "Sat"
              : "Sun"}
          </Typography>
          <Grid
            container
            justifyContent="center"
            style={{
              height: "100%",
              flex: 1,
              marginLeft: 4,
              backgroundColor: "rgba(245,245,245,0.8)",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {sortedAvailData[newInd].length < 1
              ? showNotAvail
              : sortedAvailData[newInd].map((avail) =>
                  showAvailMobile(avail, sortedAvailData[newInd].length > 1)
                )}
          </Grid>
        </div>
      );
    });

    return (
      <Grid container>
        <Hidden smDown>{availChart}</Hidden>
        <Hidden smUp>{mobileAvailChart}</Hidden>
      </Grid>
    );
  }
}

MyAvail.propTypes = {
  availData: PropTypes.array.isRequired,
  showDelete: PropTypes.bool.isRequired,
  onDelAvail: PropTypes.func,
};

export default withStyles(styles)(MyAvail);
