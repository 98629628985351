import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";

// Component imports
import CareProfileHeader from "./components/CareProfileHeader";
import MyAvail from "../../caregiverPages/profile/about/MyAvail";
import JobServiceConditionContainer from "../../caregiverPages/profile/about/JobServiceConditionContainer";
import EduAccredBlock from "../../caregiverPages/profile/eduWork/EduAccredBlock";
import ReviewBlock from "../../caregiverPages/profile/about/ReviewBlock";

// Redux imports
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class cgPage extends Component {
  state = {
    isLoaded: false,
    credentials: {},
    eduAccred: [],
    workExp: [],
    msgChnId: "",
    reviews: [],
    isLoadedReviews: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadCaregiver();
  }

  async loadCaregiver() {
    const userIdNumber = this.props.match.params.userIdNumber;

    const userDoc = await axios.get(`/user/getuser/${userIdNumber}`);

    this.setState({
      isLoaded: true,
      credentials: userDoc.data.credentials,
      eduAccred: userDoc.data.eduAccred,
      workExp: userDoc.data.workExp,
    });

    this.getReviews(userDoc.data.credentials);
  }

  async getReviews(credentials) {
    if (credentials.reviews.length > 0) {
      const reviewsDoc = await axios.get(
        `/review/get_all/${credentials.userIdNumber}`
      );
      this.setState({ reviews: reviewsDoc.data, isLoadedReviews: true });
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  // Action functions
  handleMsgChn = () => {
    const {
      credentials: { firstName, lastName, imgUrlThumb },
    } = this.state;
    const userIdNumber = this.props.match.params.userIdNumber;
    let chnData = { firstName, lastName, imgUrlThumb };
    this.setState({ disable: true }, () => {
      axios
        .post(`/message/new/${userIdNumber}`, chnData)
        .then((res) => {
          this.props.history.push(`/messages/${res.data.channelId}`);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  handleHireClick = () => {
    const userIdNumber = this.props.match.params.userIdNumber;
    this.props.history.push(`/caregiver/${userIdNumber}/hire`);
    this.setState({ disable: true });
  };

  render() {
    const {
      classes,
      history,
      user: { favourite },
      match: {
        params: { userIdNumber },
      },
    } = this.props;

    const {
      credentials,
      workExp,
      eduAccred,
      isLoaded,
      isLoadedReviews,
      reviews,
    } = this.state;

    const content = (
      <Grid item xs={12} sm={11} md={8}>
        <Paper
          elevation={4}
          className={classes.darkGrayBorder}
          style={{
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          <Typography
            variant="h6"
            align="left"
            style={{ fontWeight: 300, marginBottom: 8 }}
            className={classes.typography}
          >
            My Availability
          </Typography>
          <div>
            <MyAvail availData={credentials.myAvail} showDelete={false} />
          </div>
        </Paper>
        <Paper
          elevation={4}
          className={classes.darkGrayBorder}
          style={{
            marginTop: 16,
          }}
        >
          <JobServiceConditionContainer credentials={credentials} readOnly />
        </Paper>

        <Paper
          elevation={4}
          className={classes.darkGrayBorder}
          style={{
            marginTop: 16,
          }}
        >
          <EduAccredBlock readOnly workExps={workExp} eduAccreds={eduAccred} />
        </Paper>
        {isLoadedReviews && reviews.length > 0 && (
          <Paper
            elevation={4}
            className={classes.darkGrayBorder}
            style={{
              marginTop: 16,
            }}
          >
            <ReviewBlock
              isFamily
              isLoadedReviews={isLoadedReviews}
              reviews={reviews}
            />
          </Paper>
        )}
      </Grid>
    );
    return (
      <div className="res-gr-con">
        {isLoaded ? (
          <div>
            <Hidden smDown>
              <Grid
                container
                justifyContent="center"
                className={classes.form}
                spacing={2}
              >
                <Grid container item xs={12} md={4} className={classes.form}>
                  <CareProfileHeader
                    credentials={credentials}
                    favourite={favourite}
                    userIdNumber={userIdNumber}
                    history={history}
                  />
                </Grid>
                {content}
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid container justifyContent="center" className={classes.form}>
                <Grid item xs={12}>
                  <CareProfileHeader
                    credentials={credentials}
                    favourite={favourite}
                    userIdNumber={userIdNumber}
                    history={history}
                  />

                  <div style={{ marginTop: 16 }}>{content}</div>
                </Grid>
              </Grid>
            </Hidden>
          </div>
        ) : (
          <Grid container justifyContent="center">
            <CircularProgress thickness={2} size={220} />
          </Grid>
        )}
      </div>
    );
  }
}

cgPage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(cgPage));
