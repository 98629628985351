import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Auth.css";

// Component imports
import LoginForm from "./components/LoginForm";

import withStyles from "@mui/styles/withStyles";

import Paper from "@mui/material/Paper";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class login extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div
        className={classes.form}
        style={{ alignSelf: "center", padding: "36px 12px" }}
      >
        <div className="flexbox-centered-column">
          <Paper elevation={4} className="login-container">
            <LoginForm />
          </Paper>
        </div>
      </div>
    );
  }
}

login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(login);
