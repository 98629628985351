import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";
import OutlinedProfilePic from "../layout/OutlinedProfilePic";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class JobAppDashButton extends Component {
  render() {
    const {
      classes,
      jobApp,
      jobApp: { familyInfo, caregiverInfo },
      isFamily,
    } = this.props;
    return (
      <Box className={classes.flexBoxCentered} style={{ margin: "8px 0px" }}>
        <CardActionArea
          component={Link}
          to={`/jobs/applications/${jobApp.id}`}
          style={{
            borderRadius: 10,
            padding: 8,
            width: "100%",
            border: "1px solid rgba(212,212,212,0.7)",
          }}
        >
          <Box className={classes.flexBoxSpaced}>
            <Box className={classes.flexBox}>
              <OutlinedProfilePic
                imgSrc={
                  isFamily ? caregiverInfo.imgUrlThumb : familyInfo.imgUrlThumb
                }
                size={35}
              />

              <Typography style={{ marginLeft: 4 }}>
                {isFamily
                  ? `${capitalizeFirstChar(
                      caregiverInfo.firstName
                    )} ${caregiverInfo.lastName[0].toUpperCase()}`
                  : `${capitalizeFirstChar(
                      familyInfo.firstName
                    )} ${familyInfo.lastName[0].toUpperCase()}`}
                .
              </Typography>
            </Box>
            <Chip
              size="small"
              label={
                jobApp.isAccepted
                  ? "Accepted"
                  : jobApp.isDeclined
                  ? "Declined"
                  : jobApp.isRead
                  ? "Read"
                  : "Unread"
              }
              className={
                jobApp.isAccepted
                  ? classes.chipGreen
                  : jobApp.isDeclined
                  ? classes.chipOrj
                  : jobApp.isRead
                  ? classes.chipBlue
                  : classes.chipOrj
              }
            />
          </Box>
          <Typography
            variant="caption"
            color="textSecondary"
            noWrap
            component="div"
          >
            {jobApp.message}
          </Typography>
        </CardActionArea>
      </Box>
    );
  }
}

JobAppDashButton.propTypes = {
  jobApp: PropTypes.object.isRequired,
  isFamily: PropTypes.bool,
};

export default withStyles(styles)(JobAppDashButton);
