import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import firebase from "../../../Firebase";

// Component Imports
import ChnDisplay from "../../../components/message/ChnDisplay";
import ChatDisplay from "../../../components/message/ChatDisplay";
import EmptyChat from "../../../components/message/EmptyChat";
import FullVH from "../../../components/layout/FullVH";

// Redux imports
import { connect } from "react-redux";
import { setFooter, unsetFooter } from "../../../redux/actions/uiActions";
import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

import withStyles from "@mui/styles/withStyles";
import Hidden from "@mui/material/Hidden";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import GroupIcon from "@mui/icons-material/Group";

const styles = (theme) => ({
  ...theme.spreadThis,
  containerDiv: {
    // marginLeft: 175,
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: 175,
    // },
    [theme.breakpoints.up("sm")]: {
      // marginLeft: 85,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  chatGrid: {
    height: "100%",
    display: "flex",
    backgroundColor: "white",
    width: "100%",
    overflow: "hidden",
  },
  chnContainer: {
    width: 300,
    [theme.breakpoints.down("lg")]: {
      height: 60,
      width: 260,
    },
    [theme.breakpoints.down("md")]: {
      height: 60,
      width: 60,
    },
    height: "100%",
    textAlign: "center",
  },
  chatContainer: {
    height: "100%",
    flex: 1,
    marginLeft: 8,
    marginRight: 8,
    overflow: "hidden",
  },
  chatTemp: {
    height: "100%",
    width: 300,
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      width: 260,
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: 60,
    },
    backgroundColor: "rgba(50,50,50,0.08)",
    borderRight: `2px solid ${theme.palette.primary.main}`,
  },
  chatTempB: {
    height: "100%",
    width: "calc(100% - 300px)",
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      width: "calc(100% - 260px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "calc(100% - 60px)",
    },
  },
});

export class messages extends Component {
  state = {
    chnArr: [], // DELETE
    messageChannels: [],
    isLoadedMsgChannels: false,
    propChn: "",
    isInitial: true,
    initialOccured: false,
    showChat: false,
    noChannelsFound: false,
  };

  componentDidMount() {
    this._isMounted = true;
    store.dispatch({ type: SET_PAGE, payload: "Messages" });
    this.props.unsetFooter();
    this.getChannel();
  }

  getChannel = () => {
    const { isInitial } = this.state;
    const userIdNumber = this.props.user.credentials.userIdNumber;
    const chnId = this.props.match.params.chnId;

    const ref = firebase
      .firestore()
      .collection("messageChannel")
      .where("users", "array-contains", userIdNumber);

    const that = this;

    // Realtime updates listener
    this.unsubscribe = ref.onSnapshot((snapshot) => {
      var messageChannels = [];

      messageChannels = snapshot.docs.map((doc) => {
        let res = doc.data();
        res.channelId = doc.id;
        return res;
      });

      messageChannels.sort((a, b) =>
        a.latestMsg.createdAt < b.latestMsg.createdAt
          ? 1
          : b.latestMsg.createdAt < a.latestMsg.createdAt
          ? -1
          : 0
      );
      if (messageChannels.length < 1) {
        that.setState({ isLoadedMsgChannels: true });
      } else {
        let messageChannelData = {
          isLoadedMsgChannels: true,
          messageChannels,
          isInitial: false,
        };

        if (isInitial && !chnId) {
          this.props.history.replace({
            pathname: `/messages/${messageChannels[0].channelId}`,
          });
        }
        that.setState({ ...messageChannelData });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isLoadedMsgChannels, messageChannels } = this.state;
    const chnId = this.props.match.params.chnId;

    // Resets chnId when component redirected to base route /messages
    if (
      isLoadedMsgChannels &&
      messageChannels.length > 0 &&
      !chnId &&
      prevProps.match.params.chnId
    ) {
      this.props.history.replace({
        pathname: `/messages/${prevProps.match.params.chnId}`,
      });
    }
    // Checks and redirects if param chnId is not within loaded messageChannels
    if (isLoadedMsgChannels && chnId) {
      let index = messageChannels.findIndex(
        (messageChannel) => messageChannel.channelId === chnId
      );
      if (index < 0) {
        this.props.history.replace({
          pathname: `/messages/${messageChannels[0].channelId}`,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.setFooter();
    store.dispatch({ type: UNSET_PAGE });
    this._isMounted = false;
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  selChn = (chnId) => {
    const currChn = this.props.match.params.chnId;

    if (currChn !== chnId) {
      const { messageChannels } = this.state;

      let pos = messageChannels.findIndex(
        (messageChannel) => messageChannel.channelId === chnId
      );

      let currentMessageChannel = messageChannels[pos];
      this.setState({ currentMessageChannel });
    }
    this.props.history.replace({
      pathname: `/messages/${chnId}`,
    });
  };

  render() {
    const {
      user: {
        credentials: { userType, userIdNumber },
      },
      classes,
    } = this.props;
    const { isLoadedMsgChannels, messageChannels } = this.state;
    const currChn = this.props.match.params.chnId;

    const chnMarkup =
      isLoadedMsgChannels && messageChannels.length > 0 ? (
        messageChannels.map((messageChannel) => (
          <ChnDisplay
            key={messageChannel.channelId}
            myId={userIdNumber}
            userType={userType}
            messageChannel={messageChannel}
            isSelected={currChn === messageChannel.channelId}
            selChn={this.selChn}
          />
        ))
      ) : (
        <Box className={classes.chnContainer}>
          {messageChannels.length < 1 && isLoadedMsgChannels ? (
            <Fragment>
              <Hidden mdDown>
                <div
                  style={{
                    margin: "2px 0px",
                    padding: 4,
                  }}
                >
                  <Typography variant="h6">Chats</Typography>
                  <Divider />
                </div>
              </Hidden>
              <GroupIcon color="disabled" fontSize="large" />
              <Hidden mdDown>
                <Typography color="textSecondary">No channels found</Typography>
              </Hidden>
            </Fragment>
          ) : (
            <Fragment>
              <Hidden mdDown>
                <CircularProgress thickness={2} size={80} />
              </Hidden>
              <Hidden mdUp>
                <CircularProgress thickness={2} size={30} />
              </Hidden>
            </Fragment>
          )}
        </Box>
      );

    return (
      <div className={classes.containerDiv}>
        <FullVH>
          <Box className={classes.chatGrid}>
            <Box
              className="hideScroll"
              style={{
                height: "100%",
                backgroundColor: "rgba(245,245,245,0.5)",
                overflow: "auto",
                borderRight: "1px solid rgb(229,229,229)",
              }}
            >
              {chnMarkup}
            </Box>
            <Box className={classes.chatContainer}>
              {isLoadedMsgChannels &&
              messageChannels.length > 0 &&
              Boolean(currChn) ? (
                <ChatDisplay
                  currChn={currChn}
                  currentMessageChannelData={
                    messageChannels[
                      messageChannels.findIndex(
                        (messageChannel) => messageChannel.channelId === currChn
                      )
                    ]
                  }
                  myId={userIdNumber}
                  userType={userType}
                />
              ) : (
                <EmptyChat loading={!isLoadedMsgChannels} userType={userType} />
              )}
            </Box>
          </Box>
        </FullVH>
      </div>
    );
  }
}

messages.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, { setFooter, unsetFooter })(
  withStyles(styles)(messages)
);
