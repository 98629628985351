import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter
import axios from "axios";

// Redux imports
import { connect } from "react-redux";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";
import ActionJobApp from "./ActionJobApp";
import JobAppEditDialog from "./JobAppEditDialog";
import AnimatedCheck from "../../assets/AnimatedCheck";

import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import WarningIcon from "@mui/icons-material/Warning";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import WorkIcon from "@mui/icons-material/Work";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class JobAppSingle extends Component {
  componentDidMount() {
    this.handleMarkRead();
  }

  handleMarkRead = async () => {
    const {
      jobApp,
      credentials: { userType },
    } = this.props;
    if (userType === "family" && Boolean(jobApp) && !jobApp.isRead) {
      try {
        await axios.get(`/job-applications/${jobApp.id}/mark-read`);

        if (this.props.updateJobApp) {
          this.props.updateJobApp(jobApp.id, { isRead: true });
        }
      } catch (err) {
        return;
      }
    }
    return;
  };

  handleUpdateJobApp = (id, value) => {
    this.props.updateJobApp(id, value);
  };
  render() {
    const {
      classes,
      credentials: { userType },
      jobApp,
    } = this.props;

    const isFamily = userType === "family";

    return Boolean(jobApp) ? (
      <Box
        p={1}
        display="flex"
        flexDirection="column"
        style={{ width: "100%" }}
      >
        <Box className={classes.flexBoxSpaced}>
          <Box
            display="flex"
            alignItems="center"
            style={{ width: "calc(100% - 80px)" }}
          >
            <Avatar src={jobApp.caregiverInfo.imgUrlThumb} />
            <Typography
              noWrap
              style={{ marginLeft: 8, width: "calc(100% - 48px)" }}
            >
              {capitalizeFirstChar(jobApp.caregiverInfo.firstName)}{" "}
              {capitalizeFirstChar(jobApp.caregiverInfo.lastName)}
            </Typography>
          </Box>
          {!jobApp.isRead ? (
            <Chip size="small" label="Unread" className={classes.chipOrj} />
          ) : jobApp.isAccepted ? (
            <Chip size="small" label="Accepted" className={classes.chipGreen} />
          ) : jobApp.isDeclined ? (
            <Chip size="small" label="Declined" className={classes.chipOrj} />
          ) : (
            jobApp.isRead && (
              <Chip size="small" label="Read" className={classes.chipBlue} />
            )
          )}
        </Box>
        {jobApp.isAccepted ? (
          Boolean(jobApp.inviteId) ? (
            <div
              className={classes.flexBoxCentered}
              style={{ flexDirection: "column" }}
            >
              <AnimatedCheck large />
              <Typography color="primary" style={{ margin: "4px 0px" }}>
                {!isFamily
                  ? "Congrats! You have been accepted for this application."
                  : `Congrats! You have invited ${capitalizeFirstChar(
                      jobApp.caregiverInfo.firstName
                    )} to your team.`}
              </Typography>
              <Button
                color="secondary"
                variant="outlined"
                component={Link}
                to={`/invites/${jobApp.inviteId}`}
                style={{ margin: "8px 0px" }}
                fullWidth
              >
                View team invitation
              </Button>
            </div>
          ) : isFamily ? (
            <div
              className={classes.flexBoxCentered}
              style={{ flexDirection: "column" }}
            >
              <WarningIcon color="secondary" />
              <Typography
                align="center"
                color="secondary"
                style={{ margin: "8px 0px", fontWeight: 300 }}
              >
                You have accepted{" "}
                {capitalizeFirstChar(jobApp.caregiverInfo.firstName)}'s
                application but not sent an invite.
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                component={Link}
                to={`/caregiver/${jobApp.caregiverInfo.userIdNumber}/hire`}
                style={{ margin: "8px 0px", boxShadow: "none" }}
                fullWidth
              >
                Sent an invite
              </Button>
            </div>
          ) : (
            <Typography
              align="center"
              color="secondary"
              style={{ margin: "8px 0px", fontWeight: 300 }}
            >
              {capitalizeFirstChar(jobApp.familyInfo.firstName)} has accepted
              your application but not sent an invite.
            </Typography>
          )
        ) : null}
        <Box className={classes.flexBoxCentered} style={{ margin: "8px 0px" }}>
          {isFamily ? (
            <Button
              component={Link}
              to={`/caregiver/${jobApp.caregiverInfo.userIdNumber}`}
              color="primary"
              size="small"
              fullWidth
            >
              <Box style={{ textAlign: "center" }}>
                <AccountBoxIcon />
                <Typography variant="body2">View Profile</Typography>
              </Box>
            </Button>
          ) : (
            <JobAppEditDialog
              jobApp={jobApp}
              updateJobApp={this.handleUpdateJobApp}
              fullWidth
            />
          )}

          <Button
            component={Link}
            to={
              userType === "caregiver"
                ? `/job/${jobApp.jobId}`
                : `/profile/jobs/${jobApp.jobId}`
            }
            size="small"
            color="primary"
            fullWidth
          >
            <Box style={{ textAlign: "center" }}>
              <WorkIcon />
              <Typography variant="body2">View Job</Typography>
            </Box>
          </Button>
        </Box>
        <Typography variant="body2" style={{ margin: "4px 0px" }}>
          Job ID: {jobApp.jobId}
        </Typography>

        {(jobApp.isDeclined || jobApp.isAccepted) && (
          <Box className={classes.sectionBoxLeftOutlineRed}>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ fontWeight: 300, fontStyle: "italic" }}
            >
              This application was actioned at{" "}
              {dayjs(jobApp.actionAt).format("hh:mma MM/DD/YYYY")}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontWeight: 300,
                whiteSpace: "pre-line",
              }}
            >
              {jobApp.returnMessage}
            </Typography>
          </Box>
        )}
        <Box
          className={classes.sectionBoxLeftOutline}
          style={{ margin: "8px 0px" }}
        >
          {Boolean(jobApp.editedAt) && (
            <Typography
              variant="caption"
              component="div"
              align="right"
              color="textSecondary"
              style={{
                fontWeight: 300,
              }}
            >
              Edited: {dayjs(jobApp.editedAt).format("hh:mma DD/MM/YYYY")}
            </Typography>
          )}
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontWeight: 300,
              whiteSpace: "pre-line",
            }}
          >
            {jobApp.message}
          </Typography>
        </Box>

        <Typography
          variant="caption"
          component="div"
          align="right"
          color="textSecondary"
        >
          Sent: {dayjs(jobApp.createdAt).fromNow()}
        </Typography>
        <br />

        {isFamily && (
          <ActionJobApp
            updateJobApp={this.handleUpdateJobApp}
            jobApp={jobApp}
            color="secondary"
            fullWidth
            history={this.props.history}
          />
        )}

        <Button
          component={Link}
          to="/jobs/applications"
          variant="outlined"
          color="secondary"
          fullWidth
          style={{ marginTop: 16 }}
        >
          View All Applications
        </Button>
      </Box>
    ) : (
      <Box
        p={1}
        display="flex"
        flexDirection="column"
        style={{ width: "100%" }}
      >
        <div style={{ textAlign: "center" }}>
          <WarningIcon color="secondary" className={classes.iconStyle} />
          <Typography color="textSecondary">
            Uh oh! It seems that this application is missing.
          </Typography>
        </div>
        <br />
        <Button
          component={Link}
          to="/jobs/applications"
          variant="outlined"
          color="secondary"
          fullWidth
        >
          View All Applications
        </Button>
      </Box>
    );
  }
}

JobAppSingle.propTypes = {
  jobApp: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps)(withStyles(styles)(JobAppSingle));
