import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class Stepper extends Component {
  state = { total: 1, fadeIn: 1 };

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = () => {
    const { total, fadeIn } = this.props;
    if (Boolean(total) && Boolean(fadeIn)) {
      let _fadeIn = fadeIn;
      let _total = total;
      if (_total < 1) {
        _fadeIn = 1;
        _total = 1;
      } else if (_fadeIn > total) {
        _fadeIn = _total;
      } else if (_fadeIn < 1) {
        _fadeIn = 1;
      }

      this.setState({ total: _total, fadeIn: _fadeIn });
    }
  };
  render() {
    const { classes, xs, sm, md } = this.props;
    const { total, fadeIn } = this.state;

    return (
      <Grid
        container
        justifyContent="center"
        style={{ width: "100%", display: "flex" }}
      >
        <Grid
          item
          xs={xs ? xs : 12}
          sm={sm ? sm : 11}
          md={md ? md : 10}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {new Array(fadeIn - 1).fill(0).map((k, ind) => (
            <div key={ind} className={classes.unselectedProgress} />
          ))}
          <Fade in={true}>
            <div className={classes.selectedProgress} />
          </Fade>
          {new Array(total - fadeIn).fill(0).map((k, ind) => (
            <div key={ind} className={classes.unselectedProgress} />
          ))}
        </Grid>
      </Grid>
    );
  }
}

Stepper.propTyes = {
  classes: PropTypes.object.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  total: PropTypes.number.isRequired,
  fadeIn: PropTypes.number.isRequired,
};

export default withStyles(styles)(Stepper);
