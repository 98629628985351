import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import CareImg from "../../../../assets/images/care-bench.jpeg";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function HeaderText(props) {
  return (
    <Typography color="primary" variant="h4" className="txt-bold" {...props}>
      {props.children}
    </Typography>
  );
}

function DescriptionText(props) {
  return (
    <Typography
      color="textSecondary"
      className="description-txt"
      style={{ fontWeight: 500, whitespace: "pre-line" }}
      {...props}
    >
      {props.children}
    </Typography>
  );
}

function CovidAction() {
  const myRef = useRef();

  const { inViewport, enterCount } = useInViewport(myRef);

  return (
    <>
      <Grid
        container
        spacing={8}
        alignItems="stretch"
        className="section-wrapper-inner blob-2"
      >
        <Grid item xs={12} md={6}>
          {/* <div className="cv-ac-bg-img" /> */}
          <img
            src={CareImg}
            alt="img"
            className="cv-action-img"
            draggable="false"
          />
        </Grid>
        <Grid ref={myRef} container spacing={4} item xs={12} md={6}>
          <Grid item xs={12} id="covid-actions">
            <Typography variant="h3" className="txt-bold">
              Safeguarding the continuity of care.
            </Typography>
            <DescriptionText variant="subtitle1">
              Our top priority today remains the continued delivery of safe,
              quality care to families and care recipients. We have put in place
              a number of safety and screening protocols to help families safely
              continue care at home.
            </DescriptionText>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className={`${
              inViewport && enterCount === 1
                ? `slide-up-fade-in`
                : enterCount < 1 && `pre-fade`
            }`}
          >
            <HeaderText>Daily COVID-19 Screener</HeaderText>
            <DescriptionText>
              Poyo Care Professionals conduct pre- and post-visit screening via
              our online app. Families are notified in real time of any causes
              of concern.
            </DescriptionText>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className={`${
              inViewport && enterCount === 1
                ? `slide-up-fade-in delay-1`
                : enterCount < 1 && `pre-fade`
            } `}
          >
            <HeaderText>Hygiene and Practices</HeaderText>
            <DescriptionText>
              Care professionals are required to maintain good hygiene with
              practices such as wearing PPE, using hand santizers, and washing
              hands throughout the visit.
            </DescriptionText>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className={`${
              inViewport && enterCount === 1
                ? `slide-up-fade-in delay-2`
                : enterCount < 1 && `pre-fade`
            }`}
          >
            <HeaderText>Exposure</HeaderText>
            <DescriptionText>
              Mandatory health, travel, and close contact declarations on a
              daily basis via our online app.
            </DescriptionText>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className={`${
              inViewport && enterCount === 1
                ? `slide-up-fade-in delay-3`
                : enterCount < 1 && `pre-fade`
            } `}
          >
            <HeaderText>Vaccination</HeaderText>
            <DescriptionText>
              Vaccination status for Care Professional will be visible to
              families.
              {"\n\n"}Vaccination are mandated accordingly to state/provincial
              and regional health departments requirements.
            </DescriptionText>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CovidAction;
