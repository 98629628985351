import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import firebase from "../../../../Firebase";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/uiActions";
import { setNotif } from "../../../../redux/actions/userActions";

// Component imports
import NotificationItemClickable from "./NotificationItemClickable";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";

import NotificationsIcon from "@mui/icons-material/Notifications";
import { CircularProgress } from "@mui/material";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class NotificationMarkup extends Component {
  state = { disableWhileLoad: false, isLoadingMore: false, isLoadAgain: false };

  _checkLoadAgain = () => {
    const { lastKey } = this.props;
    if (Boolean(lastKey)) {
      this.setState({ isLoadAgain: true });
    }
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount() {
    this._checkLoadAgain();

    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("container");
    if (
      this.isBottom(wrappedElement) &&
      !this.state.isLoadingMore &&
      this.state.isLoadAgain
    ) {
      this.loadMoreNotifs();
    }
  };

  loadMoreNotifs = async () => {
    const {
      credentials: { userIdNumber },
      lastKey,
    } = this.props;

    await this.setStateAsync({ isLoadingMore: true });
    const notifRef = firebase
      .firestore()
      .collection("notifications")
      .where("recipient", "==", userIdNumber)
      .orderBy("createdAt", "desc")
      .startAfter(lastKey)
      .limit(10);

    try {
      const notifSnapshot = await notifRef.get();

      let lastKey = "";

      const notifications = notifSnapshot.docs.map((notif) => {
        const _notif = notif.data();
        _notif.id = notif.id;
        return _notif;
      });
      if (notifSnapshot.size < 10) {
        lastKey = null;
        await this.setStateAsync({ isLoadAgain: false });
      } else lastKey = notifSnapshot.docs[notifSnapshot.docs.length - 1];

      this.props.setNotif(
        [...this.props.notifications, ...notifications],
        lastKey
      );
    } catch (err) {
    } finally {
      await this.setStateAsync({ isLoadingMore: false });
    }
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  _markAllNotificationsRead = async () => {
    await this.setStateAsync({ disableWhileLoad: true });
    try {
      const { notifications } = this.props;

      const unreadNotificationIds = notifications
        .filter((notif) => !notif.read)
        .map((notif) => notif.id);

      await axios.post(`/notifications/mark_read`, unreadNotificationIds);
    } catch (err) {
      this.props.setAlert({ type: "error", message: err.response.data });
    } finally {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const { notifications, isLoadedNotifications, classes, hideLink } =
      this.props;
    const { disableWhileLoad, isLoadingMore, isLoadAgain } = this.state;
    return (
      <Box id="container" p={1}>
        <Box className={classes.flexBoxSpaced} style={{ padding: "0p 8px" }}>
          <Typography variant="h6" style={{ fontWeight: 550 }}>
            Notifications
          </Typography>
          <div>
            <Button
              size="small"
              onClick={this._markAllNotificationsRead}
              disabled={
                disableWhileLoad || notifications.every((notif) => notif.read)
              }
              style={{ textTransform: "none" }}
            >
              Mark as read{" "}
              {disableWhileLoad && (
                <CircularProgress
                  thickness={2}
                  size={25}
                  className={classes.progress}
                />
              )}
            </Button>
          </div>
        </Box>
        <Divider />
        {!hideLink && (
          <Button
            onClick={() => this.props.onClickLink()}
            component={Link}
            to={`/notifications`}
            color="secondary"
            fullWidth
            size="small"
            style={{
              textTransform: "None",
              borderRadius: 8,
              margin: "4px 0px",
            }}
          >
            View All
          </Button>
        )}
        {!isLoadedNotifications ? (
          [...new Array(8)].map((v, ind) => (
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={40}
              style={{ margin: "4px 0px" }}
              key={ind}
            />
          ))
        ) : notifications.length < 1 ? (
          <div className={classes.form} style={{ margin: "16px 0px 48px 0px" }}>
            <NotificationsIcon fontSize="large" color="disabled" />
            <Typography color="textSecondary">
              You do not have any notifications
            </Typography>
          </div>
        ) : (
          notifications.map((notif) => (
            <NotificationItemClickable
              key={notif.id}
              notification={notif}
              onClickLink={() => this.props.onClickLink()}
            />
          ))
        )}
        {hideLink && isLoadingMore ? (
          <Box className={classes.form}>
            <CircularProgress />
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              align="center"
              style={{ fontStyle: "italic" }}
            >
              Loading Notifications
            </Typography>
          </Box>
        ) : (
          hideLink &&
          isLoadAgain && (
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              align="center"
              style={{ fontStyle: "italic" }}
            >
              Scroll to load more
            </Typography>
          )
        )}
      </Box>
    );
  }
}

NotificationMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  hideLink: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  isLoadAgain: PropTypes.bool,
  onClickLink: PropTypes.func,
  onLoadMore: PropTypes.func,
  isLoadedNotifications: PropTypes.bool.isRequired,
  setAlert: PropTypes.func.isRequired,
  setNotif: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,

  notifications: state.user.notifications,
  isLoadedNotifications: state.user.isLoadedNotifications,
  lastKey: state.user.lastKey,
});

export default connect(mapStateToProps, { setAlert, setNotif })(
  withStyles(styles)(NotificationMarkup)
);
