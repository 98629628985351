import React from "react";
// import firebase from "../../../Firebase";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class resources extends React.Component {
  state = { resourceQueryString: "", resourceKeyterms: [] };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  _updateQuery = async (e) => {
    const value = e.target.value;
    await this.setStateAsync({ resourceQueryString: value });
    await this._updateQueryKeyTerms();
    // await this._queryHelp();
  };

  _updateQueryKeyTerms = async () => {
    const { resourceQueryString } = this.state;

    const _queryString = resourceQueryString.toLowerCase().trim();
    const resourceKeyterms = _queryString.split(" ");
    await this.setStateAsync({ resourceKeyterms });
  };

  // Firebase methods

  // async _queryHelp() {
  //   const {resourceKeyterms} = this.state;
  //   const db = firebase.firestore();

  //   const queryRef = db.collection("resources").where("keyterms", "array-contains-any", resourceKeyterms);

  //   try {

  //   } catch (err){

  //   }
  // }

  render() {
    const { classes } = this.props;
    const { resourceQueryString } = this.state;
    return (
      <section className="page-wrapper">
        <div className="full-vw-container p-bg bottom-drop section-title">
          <div className="header-title contrast-txt">
            <Typography variant="h3" className="txt-bold">
              Resource Center
            </Typography>
            <div className="flexbox-center" style={{ padding: 24 }}>
              <TextField
                value={resourceQueryString}
                name="resourceQueryString"
                onChange={this._updateQuery}
                fullWidth
                className={classes.styledTextFieldRound}
                style={{
                  backgroundColor: "white",
                  borderRadius: 25,
                  maxWidth: 500,
                }}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ margin: "36px 0px" }}>
          <Typography align="center">Coming Soon</Typography>
        </div>
      </section>
    );
  }
}

export default withStyles(styles)(resources);
