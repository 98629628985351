import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import axios from "axios";

// Component imports
import AnimatedCheck from "../../../../assets/AnimatedCheck";
import BgCheckUploadButton from "../../../../components/inputs/BgCheckUploadButton";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
  bgCheckContainer: {
    border: "1px solid rgba(41,70,100,0.5)",
    borderRadius: 25,
    marginTop: 16,
    backgroundColor: "rgba(227,241,253,0.5)",
  },
  whiteButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
});
export class BgCheckMarkup extends Component {
  state = {
    errors: {},
    disableWhileLoad: false,
    showComplete: false,
    pUserData: {},
    loadedPUserData: false,
  };

  componentDidMount() {
    this.getPrivateUserDoc();
  }

  async getPrivateUserDoc() {
    const pUserDoc = await axios.get("/user/private");
    this.setState({ pUserData: pUserDoc.data, loadedPUserData: true });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  setError = (errors) => {
    this.setState({ errors });
  };

  handleSetLoad = () => {
    this.setState({ disableWhileLoad: !this.state.disableWhileLoad });
  };

  handleUploadSuccess = () => {
    this.getPrivateUserDoc();
  };
  handleFinishUpload = async () => {
    this.handleSetLoad();
    await this.setStateAsync({ showComplete: true });
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  handlePageChange = async () => {
    await this.clearErrors();
    this.props.handlePageChange();
  };

  handleFinish = () => {
    this.props.handleFinish();
  };

  render() {
    const { classes, credentials, stopLoadingAnimation, hideBack } = this.props;

    const {
      disableWhileLoad,
      errors,
      showComplete,
      pUserData,
      loadedPUserData,
    } = this.state;

    const isSubmitted = credentials.status.isBgCheckSubmitted;
    const isApproved = loadedPUserData && pUserData.bgCheck.status.isApproved;

    return (
      <Fade in>
        <Box>
          <Typography
            variant="h5"
            color="primary"
            align="center"
            className={classes.typography}
          >
            Background Check
          </Typography>

          <div style={{ marginTop: 16 }}>
            <Typography
              align="left"
              variant="body2"
              component="span"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              Background checks are highly important when families hire a
              caregiver to ensure safety for everyone. Poyo requires all
              caregivers on our platform have a background check that is:
              <ul>
                <li>a valid RCMP, CPIC check,</li>
                <li>clean, indicating no criminal records,</li>
                <li>no older than 5 years</li>
              </ul>
              prior to your profile can be viewed. Once your background check
              has been validated, your profile will be public and you will be
              able to receive messages from families.
            </Typography>
          </div>

          <Box p={2} className={classes.bgCheckContainer}>
            <Typography
              align="left"
              variant="body2"
              className={classes.typography}
              style={{ fontWeight: 300, marginBottom: 16 }}
            >
              If you have an existing copy of your background check that meets
              the above criteria, you may upload to our private and secure
              servers by clicking "Upload" below.
            </Typography>
            <Typography
              align="left"
              variant="caption"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              <span style={{ fontWeight: 400 }}>*Note</span>: If you have a
              completed background check with your existing company, you may
              request a copy from your HR department.
            </Typography>
            <Typography
              align="left"
              variant="body2"
              className={classes.typography}
              style={{ fontWeight: 300, marginTop: 16 }}
            >
              You may complete a new background check with our partners at
              certn. by clicking "Certn." below. Cost of background checks
              completed with certn. will be reimbursed in full with your first
              payment.
            </Typography>
            {isSubmitted && loadedPUserData && (
              <Box
                m={2}
                p={2}
                className={classes.flexBoxCenteredColumn}
                style={{
                  backgroundColor: "rgba(255,255,255,0.6)",
                  borderRadius: 10,
                }}
              >
                {showComplete && (
                  <div
                    className={classes.flexBoxCentered}
                    style={{ flexDirection: "column" }}
                  >
                    <AnimatedCheck large />
                    <Typography
                      align="center"
                      style={{ marginTop: 8, fontWeight: 300 }}
                    >
                      Upload Complete
                    </Typography>
                  </div>
                )}
                <Button
                  component="a"
                  href={pUserData.bgCheck.bgCheckFile}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="secondary"
                >
                  View Background Check File
                </Button>
                <Chip
                  label={
                    <Typography
                      variant="body2"
                      component="div"
                      style={{ textTransform: "uppercase" }}
                    >
                      {isApproved
                        ? `Approved: ${dayjs(
                            pUserData.bgCheck.actionedOn
                          ).format("h:mma MMM DD, YYYY")}`
                        : `Submitted: ${dayjs(
                            pUserData.bgCheck.bgCheckFileSubmittedAt
                          ).format("h:mma MMM DD, YYYY")}`}
                    </Typography>
                  }
                  color="info"
                  style={{ margin: "4px 0px" }}
                  className={
                    isApproved
                      ? classes.chipGreenOutlined
                      : classes.chipBlueOutlined
                  }
                />
              </Box>
            )}
            {Boolean(errors.file) && (
              <Typography
                variant="body2"
                align="center"
                color="error"
                style={{ marginBottom: 16 }}
              >
                {errors.file}
              </Typography>
            )}

            <Grid
              container
              justifyContent="center"
              spacing={1}
              className={classes.form}
              style={{ marginTop: 16 }}
            >
              <Grid item xs={12} sm={4}>
                <BgCheckUploadButton
                  fullWidth
                  disable={stopLoadingAnimation}
                  onError={this.setError}
                  onLoad={this.handleSetLoad}
                  onSuccess={this.handleUploadSuccess}
                  onFinish={this.handleFinishUpload}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.whiteButton}
                  fullWidth
                  disabled={disableWhileLoad || stopLoadingAnimation}
                >
                  Certn.
                </Button>
              </Grid>
              {!hideBack && (
                <Grid item xs={12} sm={4}>
                  <Button
                    variant={isSubmitted ? "contained" : "outlined"}
                    color="primary"
                    onClick={this.handleFinish}
                    className={
                      !isSubmitted ? classes.whiteButton : classes.test
                    }
                    disabled={disableWhileLoad || stopLoadingAnimation}
                    fullWidth
                  >
                    {isSubmitted ? "FINISH" : "Skip"}
                    {isSubmitted &&
                      (disableWhileLoad || stopLoadingAnimation) && (
                        <CircularProgress
                          thickness={2}
                          size={30}
                          className={classes.progress}
                        />
                      )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>

          {!hideBack && (
            <Box display="flex" justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handlePageChange}
                style={{ width: 100, marginTop: 16 }}
                disabled={stopLoadingAnimation}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    );
  }
}

BgCheckMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  stopLoadingAnimation: PropTypes.bool,
  handlePageChange: PropTypes.func,
  handleFinish: PropTypes.func,
  hideBack: PropTypes.bool,
};

export default withStyles(styles)(BgCheckMarkup);
