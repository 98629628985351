import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

// Component imports
import CaregiverAccountUpdates from "./components/CaregiverAccountUpdates";
import DashMessagesCard from "../../../components/dashComps/DashMessagesCard";
import DashMyTeamCg from "../../../components/dashComps/DashMyTeamCg";
import DashJobSearch from "./components/DashJobSearch";
import firebase from "../../../Firebase";
import CaregiverAccountStepper from "./components/CaregiverAccountStepper";

// Redux imports
import { connect } from "react-redux";
import { setData } from "../../../redux/actions/dataActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";

dayjs.extend(weekday);

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class CaregiverDashboard extends Component {
  // Load Methods

  componentDidMount() {
    this.getJobs();
    this.getMsgChn();
    this.getTeamInv();
    this.getTeams();
    this.getJobApplications();
    this.getInvoices();
    this.getShifts();
    this.checkShowStepper();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async getMsgChn() {
    try {
      const messageChannels = (await axios.get("/message")).data;
      this.props.setData({ messageChannels, isLoadedMsgChn: true });
    } catch (e) {
      this.props.setData({ isLoadedMsgChn: true });
    }
  }

  async getJobs() {
    try {
      const jobs = (await axios.get("/jobs/dash")).data;
      this.props.setData({ jobs, isLoadedJobs: true });
    } catch (e) {
      this.props.setData({ isLoadedJobs: true });
    }
  }

  async getTeamInv() {
    try {
      const teamInvites = (await axios.get("/team/invite")).data;
      this.props.setData({ teamInvites, isLoadedTeamInv: true });
    } catch (e) {
      this.props.setData({ isLoadedTeamInv: true });
      return;
    }
  }

  async getTeams() {
    try {
      const teams = (await axios.get("/team")).data;
      this.props.setData({ ...teams, isLoadedTeams: true });
    } catch (e) {
      this.props.setData({ isLoadedTeams: true });
    }
  }

  async getJobApplications() {
    try {
      const jobApplications = (await axios.get("/job-applications/get")).data;
      this.props.setData({
        jobApplications,
        isLoadedJobApplications: true,
      });
    } catch (e) {
      this.props.setData({ isLoadedJobApplications: true });
    }
  }

  getShifts = async () => {
    const userIdNumber = this.props.user.credentials.userIdNumber;
    const colRef = firebase
      .firestore()
      .collection("shifts")
      .where("caregiverInfo.userIdNumber", "==", userIdNumber)
      .where(
        "startTime",
        ">",
        dayjs().hour(0).minute(0).second(0).toISOString()
      )
      .orderBy("startTime");
    const that = this;

    this.unsubscribe = colRef.onSnapshot(async (snapshot) => {
      const dashShifts = snapshot.docs.map((doc) => {
        let res = doc.data();
        res.id = doc.id;
        return res;
      });

      const weeklyShifts = dashShifts.filter(
        (shift) =>
          shift.startTime < dayjs().weekday(8).toISOString().slice(0, 10)
      );
      that.props.setData({
        dashShifts,
        weeklyShifts,
      });
      if (!that.props.data.isLoadedDashShifts) {
        await that.props.setData({ isLoadedDashShifts: true });
      }
    });
  };

  async getInvoices() {
    try {
      const _invoices = await axios.get("/invoice/get_all/dash");
      let invoices = _invoices.data;
      this.props.setData({ invoices, isInvoiceLoaded: true });
    } catch (err) {
      this.props.setData({ isInvoiceLoaded: true });
      return;
    }
  }

  async checkShowStepper() {
    const status = this.props.user.credentials.status;
    if (status.isCompletedFirstShift && !status.isCompletedAll) {
      await axios.get("/user/status/completed_all");
    }
  }

  render() {
    const {
      user: { credentials, workExp, eduAccred, favouritesDoc },
      data: {
        jobs,
        isLoadedJobs,
        messageChannels,
        isLoadedMsgChn,
        teamInvites,
        isLoadedTeamInv,
        members,
        isLoadedTeams,
        isLoadedDashShifts,
        dashShifts,
        weeklyShifts,
        invoices,
        isInvoiceLoaded,
        jobApplications,
        isLoadedJobApplications,
      },
    } = this.props;

    return (
      <Grid container spacing={2}>
        {!credentials.status.isCompletedAll && (
          <Grid item xs={12}>
            <CaregiverAccountStepper credentials={credentials} />
          </Grid>
        )}
        <Grid container item xs={12} md={3}>
          <Grid item xs={12}>
            <CaregiverAccountUpdates
              isLoaded={isLoadedTeamInv && isInvoiceLoaded}
              invoices={invoices}
              weeklyShifts={weeklyShifts}
              credentials={credentials}
              missingEdu={eduAccred.length < 1}
              missingWorkExp={workExp.length < 1}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} md={9}>
          <Grid item xs={12} sm={6}>
            <DashMyTeamCg
              isLoaded={
                isLoadedTeamInv &&
                isLoadedTeams &&
                isLoadedDashShifts &&
                jobApplications
              }
              isLoadedTeamInv={isLoadedTeamInv}
              teamInvites={teamInvites}
              userIdNumber={credentials.userIdNumber}
              members={members}
              isLoadedTeams={isLoadedTeams}
              isLoadedShifts={isLoadedDashShifts}
              jobApplications={jobApplications}
              isLoadedJobApplications={isLoadedJobApplications}
              shifts={dashShifts}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DashMessagesCard
              isLoaded={isLoadedMsgChn}
              messageChannels={messageChannels}
              userIdNumber={credentials.userIdNumber}
              userType={credentials.userType}
            />
          </Grid>

          <Grid item xs={12}>
            <DashJobSearch
              isLoadedJobs={isLoadedJobs}
              jobs={jobs}
              credentials={credentials}
              favouritesDoc={favouritesDoc}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CaregiverDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object,
  setData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
  data: state.data,
});

export default connect(mapStateToProps, { setData })(
  withStyles(styles)(CaregiverDashboard)
);
