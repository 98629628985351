import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";

// Component imports
import LocationSearch from "../../../components/inputs/LocationSearch";
import { allFalse, isEmpty } from "../../../util/utilFunctions";
import AnimatedCheck from "../../../assets/AnimatedCheck";

//REDUX imports
import { connect } from "react-redux";
import { setBankingDetails } from "../../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
  containerDiv: {
    marginTop: 10,
  },
});

export class caregiverBanking extends Component {
  state = {
    instNum: "",
    accNum: "",
    transitNum: "",
    bankName: "",
    bankAddress: { addressFull: "" },
    homeAddress: { addressFull: "" },

    errors: {},
    disableWhileLoad: false,
    bankingUpdated: false,
    pUserData: {},
    loadedPUserData: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  // Loading handler

  componentDidMount() {
    (async () => {
      await this.getPrivateUserDoc();
      await this.setInitialBankingInfo();
    })();
  }

  async getPrivateUserDoc() {
    const pUserDoc = await axios.get("/user/private");
    await this.setState({ pUserData: pUserDoc.data, loadedPUserData: true });
  }

  setInitialBankingInfo() {
    const { credentials } = this.props.user;

    if (credentials.status.isBanking) {
      const {
        bankingDetails: {
          instNum,
          accNum,
          transitNum,
          bankName,
          bankAddress,
          homeAddress,
        },
      } = this.state.pUserData;
      this.setState({
        instNum,
        accNum,
        transitNum,
        bankName,
        bankAddress,
        homeAddress,
      });
    } else {
      this.setState({
        homeAddress: credentials.address,
      });
    }
  }

  // Error validation

  validateSubmit() {
    const {
      instNum,
      accNum,
      transitNum,
      bankName,
      bankAddress,
      homeAddress,
      errors,
    } = this.state;
    const _errors = { ...errors };
    if (isEmpty(instNum)) {
      _errors.instNum = "Must not be empty";
    } else if (instNum.length !== 3) {
      _errors.instNum = "Please enter a valid insitution number";
    }
    if (isEmpty(accNum)) {
      _errors.accNum = "Must not be empty";
    } else if (accNum.length !== 7) {
      _errors.accNum = "Please enter a valid account number";
    }
    if (isEmpty(transitNum)) {
      _errors.transitNum = "Must not be empty";
    } else if (transitNum.length !== 5) {
      _errors.transitNum = "Please enter a valid transit/branch number";
    }
    if (isEmpty(bankName)) _errors.bankName = "Must not be empty";
    if (isEmpty(bankAddress.addressFull))
      _errors.bankAddress =
        "Please input and select a valid address from the options provided";
    if (isEmpty(homeAddress.addressFull))
      _errors.homeAddress =
        "Please input and select a valid address from the options provided";

    this.setState({ errors: _errors });
  }

  // Action handler

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: isEmpty(value) && "Must not be empty",
      },
    });
  };

  handleLimitNum = (e, maxDigit) => {
    if (e.target.value.length > 0) {
      if (isNaN(e.target.value) && !isEmpty(e.target.value)) {
        e.target.value = e.target.value.slice(0, -1);
      } else {
        e.target.value = e.target.value
          .replace(/[^0-9]/g, "")
          .toString()
          .slice(0, maxDigit);
      }
    } else {
      if (isNaN(e.target.value) && !isEmpty(e.target.value)) {
        e.target.value = "";
      } else if (isEmpty(e.target.value)) {
        e.target.value = "";
      } else {
        e.target.value = e.target.value
          .replace(/[^0-9]/g, "")
          .toString()
          .slice(0, maxDigit);
      }
    }
  };

  handleBankAddress = (value) => {
    this.setState({
      bankAddress: value,
      errors: { ...this.state.errors, bankAddress: null },
    });
  };
  handleHomeAddress = (value) => {
    this.setState({
      homeAddress: value,
      errors: { ...this.state.errors, homeAddress: null },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.setStateAsync({ disableWhileLoad: true });
    await this.validateSubmit();
    const {
      instNum,
      accNum,
      transitNum,
      bankName,
      bankAddress,
      homeAddress,
      errors,
    } = this.state;

    const valid = allFalse(errors);
    if (valid) {
      try {
        const bankingDetails = {
          instNum,
          accNum,
          transitNum,
          bankName,
          bankAddress,
          homeAddress,
        };
        const bankingDetailsDoc = await axios.post(
          "/user/banking",
          bankingDetails
        );
        await this.setStateAsync({
          pUserData: {
            ...this.state.pUserData,
            bankingDetails: bankingDetailsDoc.data,
          },
          disableWhileLoad: false,
          bankingUpdated: true,
        });
        setTimeout(() => this.setState({ bankingUpdated: false }), 5000);
      } catch (err) {
        console.error(err);
        await this.setStateAsync({ disableWhileLoad: false });
      }
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const {
      classes,
      user: {
        credentials: {
          status: { isBanking },
        },
      },
    } = this.props;
    const {
      instNum,
      accNum,
      transitNum,
      bankName,
      errors,
      bankAddress,
      homeAddress,
      disableWhileLoad,
      bankingUpdated,
    } = this.state;
    return (
      <div className={classes.containerDiv}>
        <Grid container className={classes.form} justifyContent="center">
          <Grid item xs={12} sm={10} md={7}>
            <Paper
              elevation={4}
              className={clsx(
                classes.paperContainer,
                classes.darkGrayBorderOnly
              )}
              style={{ position: "relative" }}
            >
              <AccountBalanceOutlinedIcon color="primary" fontSize="large" />
              <Typography variant="h6" className={classes.typography}>
                Manage banking info
              </Typography>
              <Typography
                align="left"
                className={classes.typography}
                style={{ marginTop: 16 }}
              >
                Poyo collects your banking information to make direct deposits
                of your pay to your bank account. This information is only
                visible to you and payroll staff. We currently only accept
                Canadian bank accounts at the moment.
              </Typography>
              <Typography
                align="left"
                className={classes.typography}
                style={{ marginTop: 16 }}
              >
                Please provide details of the account where you would like to
                receive payment.
              </Typography>

              <Divider style={{ margin: "16px 0px" }} />

              <form
                onSubmit={this.handleSubmit}
                className={classes.flexBoxCentered}
              >
                <Grid container spacing={1} style={{ maxWidth: 350 }}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      id="Institution Number"
                      onChange={this.handleChange}
                      fullWidth
                      size="small"
                      name="instNum"
                      inputProps={{ inputMode: "numeric" }}
                      label="Institution Number"
                      placeholder="e.g. 001"
                      value={instNum}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoFocus
                      className={
                        Boolean(errors.instNum)
                          ? classes.styledTextFieldError
                          : classes.styledTextField
                      }
                      error={Boolean(errors.instNum)}
                      helperText={Boolean(errors.instNum) ? errors.instNum : ""}
                      onInput={(e) => this.handleLimitNum(e, 3)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      id="Transit Number"
                      onChange={this.handleChange}
                      fullWidth
                      size="small"
                      name="transitNum"
                      inputProps={{ inputMode: "numeric" }}
                      label="Transit / Branch Number"
                      placeholder="e.g. 00001"
                      value={transitNum}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={
                        Boolean(errors.transitNum)
                          ? classes.styledTextFieldError
                          : classes.styledTextField
                      }
                      error={Boolean(errors.transitNum)}
                      helperText={
                        Boolean(errors.transitNum) ? errors.transitNum : ""
                      }
                      onInput={(e) => this.handleLimitNum(e, 5)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      id="Account Number"
                      inputProps={{ inputMode: "numeric" }}
                      onChange={this.handleChange}
                      fullWidth
                      size="small"
                      name="accNum"
                      label="Account Number"
                      placeholder="e.g. 1234567"
                      value={accNum}
                      className={
                        Boolean(errors.accNum)
                          ? classes.styledTextFieldError
                          : classes.styledTextField
                      }
                      error={Boolean(errors.accNum)}
                      helperText={Boolean(errors.accNum) ? errors.accNum : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onInput={(e) => this.handleLimitNum(e, 7)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      id="Bank Name"
                      onChange={this.handleChange}
                      fullWidth
                      size="small"
                      name="bankName"
                      label="Bank Name"
                      placeholder="e.g. RBC"
                      value={bankName}
                      className={
                        Boolean(errors.bankName)
                          ? classes.styledTextFieldError
                          : classes.styledTextField
                      }
                      error={Boolean(errors.bankName)}
                      helperText={
                        Boolean(errors.bankName) ? errors.bankName : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocationSearch
                      onSelectAddress={this.handleBankAddress}
                      errorAddress={Boolean(errors.bankAddress)}
                      label="Bank Address"
                      placeholder="Address..."
                      addressSetInitial={!isEmpty(bankAddress.addressFull)}
                      address={
                        !isEmpty(bankAddress.addressFull) ? bankAddress : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocationSearch
                      onSelectAddress={this.handleHomeAddress}
                      errorAddress={Boolean(errors.homeAddress)}
                      label="Home Address"
                      placeholder="Address..."
                      addressSetInitial={!isEmpty(homeAddress.addressFull)}
                      address={
                        !isEmpty(homeAddress.addressFull) ? homeAddress : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      disabled={disableWhileLoad}
                      className={classes.txtTrButton}
                    >
                      {!isBanking ? "Submit" : "Update"}

                      {disableWhileLoad && (
                        <CircularProgress
                          thickness={2}
                          size={30}
                          className={classes.progress}
                          style={{ right: 10 }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>

              {bankingUpdated && (
                <Box className={classes.layoverUpdate}>
                  <AnimatedCheck large />
                  <br />
                  <Typography color="primary">
                    Banking details successfully updated!
                  </Typography>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

caregiverBanking.propTypes = {
  classes: PropTypes.object.isRequired,
  setBankingDetails: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setBankingDetails })(
  withStyles(styles)(caregiverBanking)
);
