import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  ...theme.spreadThis,
  media: {
    borderRadius: "50%",
    objectFit: "cover",
    height: 100,
    width: 100,
  },
  avatarOutline: {
    height: 112,
    width: 112,
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
});

export class OutlinedProfilePic extends Component {
  render() {
    const { classes, imgSrc, size, color, alt, link, to } = this.props;

    const imgMarkup = (
      <div
        className={classes.avatarOutline}
        style={{
          width: size ? (size > 70 ? size + 12 : size + 6) : 112,
          height: size ? (size > 70 ? size + 12 : size + 6) : 112,
          border: `${size && size <= 70 ? "2" : "4"}px solid ${
            color ? color : "#17b890"
          }`,
        }}
      >
        <img
          src={imgSrc}
          alt={alt ? alt : "Profile Pic"}
          className={classes.media}
          style={{
            width: size ? size : 100,
            height: size ? size : 100,
          }}
        />
      </div>
    );

    return link ? (
      <Link
        // component={Link}
        to={to}
        className={classes.avatarOutline}
        style={{
          width: size ? (size > 70 ? size + 12 : size + 6) : 112,
          height: size ? (size > 70 ? size + 12 : size + 6) : 112,
        }}
      >
        {imgMarkup}
      </Link>
    ) : (
      imgMarkup
    );
  }
}

OutlinedProfilePic.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default withStyles(styles)(OutlinedProfilePic);
