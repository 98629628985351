import React, { forwardRef, Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Slide from "@mui/material/Slide";

// Icons imports
import WarningIcon from "@mui/icons-material/Warning";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class SkipBgCheckDialog extends Component {
  state = { disableWhileLoad: false };
  handleClose = () => {
    this.props.handleClose();
  };

  handleSkip = () => {
    this.setState({ disableWhileLoad: true }, () => this.props.handleSkip());
  };

  render() {
    const { open, classes } = this.props;
    const { disableWhileLoad } = this.state;
    return (
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={this.handleClose}
      >
        {disableWhileLoad && (
          <div className={clsx(classes.flexBoxCentered, classes.layoverUpdate)}>
            <CircularProgress thickness={2} size={50} />
          </div>
        )}
        <DialogContent>
          <div className={classes.flexBoxCentered} style={{ padding: 24 }}>
            <WarningIcon
              color="error"
              style={{ fontSize: "400%", marginRight: 10 }}
            />
            <div style={{ flex: 1 }}>
              <Typography>
                A valid and clean background check is required prior to your
                profile being visible to families.
              </Typography>

              <Typography style={{ marginTop: 16 }}>
                You may skip this process now to view job postings however your
                profile will{" "}
                <span style={{ textDecoration: "underline", fontWeight: 500 }}>
                  not be searchable
                </span>{" "}
                and you will{" "}
                <span style={{ textDecoration: "underline", fontWeight: 500 }}>
                  not be able to message families
                </span>
                .
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={disableWhileLoad}
            onClick={this.handleClose}
            color="primary"
          >
            Back
          </Button>
          <Button
            onClick={this.handleSkip}
            disabled={disableWhileLoad}
            color="primary"
          >
            Skip
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SkipBgCheckDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
};

export default withStyles(styles)(SkipBgCheckDialog);
