import React, { Component } from "react";
import { isMobile } from "react-device-detect";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDayjs";
import TimePicker from "@mui/lab/TimePicker";
import MobileTimePicker from "@mui/lab/MobileTimePicker";

import TextField from "@mui/material/TextField";

export class TimeSelector extends Component {
  state = {
    time: isMobile ? "" : null,
  };

  componentDidMount() {
    if (this.props.time) {
      this.setState({ time: this.props.time });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.time === "" && prevProps.time !== "") {
      this.setState({ time: isMobile ? "" : null });
    }
  }

  handleChangeTime = (value) => {
    this.setState({ time: value }, () => {
      this.props.onSelectTime(this.state.time);
    });
  };

  handleChangeTimeNative = (event) => {
    this.setState({ time: event.target.value }, () => {
      this.props.onSelectTime(this.state.time);
    });
  };

  render() {
    const {
      error,
      errorText,
      label,
      style,
      fullWidth,
      size,
      className,
      placeholder,
      disabled,
    } = this.props;

    return (
      <LocalizationProvider dateAdapter={DateAdapter}>
        {isMobile ? (
          <MobileTimePicker
            label={label ? label : null}
            minutesStep={5}
            value={this.state.time}
            onChange={this.handleChangeTime}
            size={size ? size : "small"}
            className={className}
            style={style}
            disabled={disabled}
            inputProps={{
              readOnly: true,
            }}
            showToolbar
            renderInput={(params) => (
              <TextField
                fullWidth={fullWidth}
                placeholder={placeholder}
                {...params}
                className={className}
                helperText={
                  Boolean(error) && Boolean(errorText) ? errorText : null
                }
                error={Boolean(error)}
              />
            )}
          />
        ) : (
          <TimePicker
            label={label ? label : null}
            minutesStep={5}
            value={this.state.time}
            onChange={this.handleChangeTime}
            size={size ? size : "small"}
            className={className}
            style={style}
            inputProps={{
              readOnly: true,
            }}
            disabled={disabled}
            showToolbar
            renderInput={(params) => (
              <TextField
                fullWidth={fullWidth}
                placeholder={placeholder}
                {...params}
                className={className}
                helperText={
                  Boolean(error) && Boolean(errorText) ? errorText : null
                }
                error={Boolean(error)}
              />
            )}
          />
        )}
      </LocalizationProvider>
    );
    // isMobile ? (
    //   <TextField
    //     value={this.state.time}
    //     label={label}
    //     type="time"
    //     style={style}
    //     onChange={this.handleChangeTimeNative}
    //     className={className}
    //     InputLabelProps={{
    //       shrink,
    //     }}
    //     fullWidth={fullWidth}
    //     helperText={Boolean(error) && Boolean(errorText) ? errorText : null}
    //     error={Boolean(error)}
    //   />
    // ) :
  }
}

export default TimeSelector;
