import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export class LoadingEllipsis extends Component {
  state = { loadingDots: 0 };
  componentDidMount() {
    const refreshIntervalId = setInterval(() => {
      let loadingDots = this.state.loadingDots;
      if (loadingDots < 3) {
        loadingDots += 1;
      } else {
        loadingDots = 0;
      }
      this.setState({ loadingDots, stopAction: false });
    }, 750);
    this.setState({ refreshIntervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshIntervalId);
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { loadingDots } = this.state;
    const { prefix, color, variant, style, component } = this.props;
    return (
      <Typography
        component={component ? component : "p"}
        color={color ? color : "textSecondary"}
        variant={variant ? variant : "caption"}
        style={style ? style : {}}
      >
        {prefix ? prefix : "Loading"}
        {".".repeat(loadingDots)}
      </Typography>
    );
  }
}

LoadingEllipsis.propTypes = {
  component: PropTypes.string,
  prefix: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object,
};

export default LoadingEllipsis;
