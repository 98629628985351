import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { capitalizeFirstChar } from "../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const StyledToggleButton = withStyles({
  root: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "8px",
    textTransform: "none",
    width: "100%",
    borderRadius: 25,
    "&$selected": {
      backgroundColor: "rgba(33, 137, 214, 0.25)",
      color: "rgb(26, 88, 159)",
      "&:hover": {
        backgroundColor: "rgba(33, 137, 214, 0.14)",
        color: "rgb(26, 88, 159)",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(33, 137, 214, 0.14)",
      color: "rgb(26, 88, 159)",
    },
  },
  selected: {},
})(ToggleButton);

const ErrorStyledToggleButton = withStyles({
  root: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    padding: "8px",
    textTransform: "none",
    width: "100%",
    borderRadius: 25,
    color: "rgb(244, 67, 54)",
    backgroundColor: "rgba(242, 165, 159, 0.4)",
    "&$selected": {
      backgroundColor: "rgba(33, 137, 214, 0.25)",
      color: "white",
      "&:hover": {
        backgroundColor: "rgba(33, 137, 214, 0.14)",
        color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(33, 137, 214, 0.14)",
      color: "rgb(26, 88, 159)",
    },
  },
  selected: {},
})(ToggleButton);

const StyledGroupButton = withStyles({
  root: {
    width: "100%",
  },
})(ToggleButtonGroup);

export class StyledToggleButtonGroup extends Component {
  handleToggleValue = (e, v) => {
    const { name } = this.props;

    if (v !== null) {
      this.props.handleToggleValue(name, v);
    }
  };

  render() {
    const { error, errorMsg, value, name, exclusive, options, disabled } =
      this.props;
    return error ? (
      <Fragment>
        <StyledGroupButton
          value={value}
          onChange={this.handleToggleValue}
          name={name}
          id={`${name}-select`}
          exclusive={exclusive}
          size="small"
        >
          {Object.keys(options).map((key) => (
            <ErrorStyledToggleButton
              key={key}
              disabled={disabled}
              value={options[key]}
            >
              <Typography variant="body2">
                {capitalizeFirstChar(key)}
              </Typography>
            </ErrorStyledToggleButton>
          ))}
        </StyledGroupButton>
        <Typography
          variant="caption"
          style={{ marginBottom: 10, color: "rgb(233,45,43)" }}
        >
          {errorMsg}
        </Typography>
      </Fragment>
    ) : (
      <StyledGroupButton
        value={value}
        onChange={this.handleToggleValue}
        name={name}
        id={`${name}-select`}
        exclusive={exclusive}
        size="small"
      >
        {Object.keys(options).map((key) => (
          <StyledToggleButton
            key={key}
            disabled={disabled}
            value={options[key]}
          >
            <Typography variant="body2">{capitalizeFirstChar(key)}</Typography>
          </StyledToggleButton>
        ))}
      </StyledGroupButton>
    );
  }
}

StyledToggleButtonGroup.propTypes = {
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  exclusive: PropTypes.bool,
  options: PropTypes.object.isRequired,
  handleToggleValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default StyledToggleButtonGroup;
