import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class AccountStatusBlock extends Component {
  render() {
    const { classes, credentials } = this.props;
    return (
      <Paper elevation={4} className={classes.darkGrayBorder}>
        <Typography
          variant="h6"
          align="left"
          className={classes.typography}
          style={{ fontWeight: 300 }}
        >
          Account Status
        </Typography>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              align="left"
              className={classes.typography}
            >
              Banking Info:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/profile/banking">
              <MuiAlert
                severity={
                  Boolean(credentials.status.isBanking) ? "success" : "error"
                }
                style={{ overflow: "hidden" }}
              >
                {Boolean(credentials.status.isBanking)
                  ? "Completed"
                  : "Incomplete"}
              </MuiAlert>
            </Link>
          </Grid>

          <Grid item xs={6}>
            <Typography
              variant="body2"
              align="left"
              className={classes.typography}
            >
              Background Check:
            </Typography>
          </Grid>

          <Grid item xs={6}>
            {Boolean(credentials.status.isBgCheck) ? (
              <MuiAlert severity="success">Completed</MuiAlert>
            ) : (
              <Link to="/profile/background-check">
                <MuiAlert
                  severity={
                    Boolean(credentials.status.isBgCheckSubmitted)
                      ? "warning"
                      : "error"
                  }
                  style={{ overflow: "hidden" }}
                >
                  {Boolean(credentials.status.isBgCheckSubmitted)
                    ? "Pending"
                    : "Incomplete"}
                </MuiAlert>
              </Link>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

AccountStatusBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountStatusBlock);
