import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale";
import clsx from "clsx";

// Component imports
import JobsList from "../../../components/jobs/JobsList";
import JobMarkupCg from "../../../components/jobs/JobMarkupCg";

// CSS Import
import "../../../App.css";

// Redux imports
import { connect } from "react-redux";
import { queryJobs } from "../../../redux/actions/dataActions";
import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// MUI Icon imports
import WorkIcon from "@mui/icons-material/Work";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  paperContainer: {
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 500,
  },

  reactiveContainerGrid: {
    paddingRight: 16,
    paddingLeft: 16,
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
});

export class jobPage extends Component {
  state = {
    jobData: {},
    msgChnId: "",
    isLoadedJobData: false,
    isErrorJobData: false,
    disabled: false,
  };

  componentDidMount() {
    store.dispatch({ type: SET_PAGE, payload: "Jobs" });

    this.getJobData();
    if (!this.props.data.queryLoaded) {
      this.props.queryJobs({ isInitial: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const jobId = this.props.match.params.jobId;
    if (jobId !== prevProps.match.params.jobId) {
      this.scrollToTop();
      this.getJobData();
    }
  }

  componentWillUnmount() {
    store.dispatch({ type: UNSET_PAGE });

    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  async getJobData() {
    const jobId = this.props.match.params.jobId;
    await this.setStateAsync({ isLoadedJobData: false, isErrorJobData: false });
    try {
      const jobData = await axios.get(`/job/${jobId}`);

      this.setState({
        jobData: jobData.data,
        isLoadedJobData: true,
        isErrorJobData: false,
      });
    } catch (err) {
      this.setState({
        isErrorJobData: true,
      });
    }
  }

  // Action functions
  updateStateJobStatus = (value) => {
    this.setState({ jobData: { ...this.state.jobData, ...value } });
  };

  handleMsgChn = () => {
    const {
      jobData: { firstName, lastName, imgUrlThumb, userIdNumber },
    } = this.state;

    let chnData = { firstName, lastName, imgUrlThumb };

    this.setState({ disabled: true });
    axios
      .post(`/message/new/${userIdNumber}`, chnData)
      .then((res) => {
        this.props.history.push(`/messages/${res.data.channelId}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleApplyJob = () => {
    const {
      jobData: { firstName, lastName, imgUrlThumb, userIdNumber },
    } = this.state;
    const jobId = this.props.match.params.jobId;

    const chnData = { firstName, lastName, imgUrlThumb };

    this.setState({ disabled: true });
    axios
      .all([
        axios.post(`/message/new/${userIdNumber}`, chnData),
        axios.post(`/job/${jobId}/apply`),
      ])
      .then(
        axios.spread((data1, data2) => {
          this.props.history.push(`/messages/${data1.data.channelId}`);
        })
      )
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const {
      classes,
      data: { filteredResults, queryResults, queryLoaded },
      user: { credentials },
      match: {
        params: { jobId },
      },
    } = this.props;

    const { jobData, isLoadedJobData, disabled, isErrorJobData } = this.state;

    const jobListMarkup = filteredResults
      .filter((job) => job.jobId !== jobId)
      .map((job) => (
        <Grid item xs={12} key={job.jobId}>
          <JobsList jobData={job} userData={credentials} />
        </Grid>
      ));

    const jobListRemainderMarkup = queryResults
      .filter(
        (job) =>
          filteredResults.findIndex(
            (filteredResult) => filteredResult.jobId === job.jobId
          ) < 0 && job.jobId !== jobId
      )
      .map((job) => {
        return (
          <Grid item xs={12} key={job.jobId}>
            <JobsList jobData={job} userData={credentials} />
          </Grid>
        );
      });

    return (
      <Grid
        container
        justifyContent="center"
        spacing={3}
        className="res-gr-con"
      >
        <Grid item xs={12} md={8}>
          {isErrorJobData ? (
            <Paper
              elevation={4}
              className={classes.paperContainer}
              style={{ flexDirection: "column" }}
            >
              <WorkIcon
                style={{ color: "rgba(98, 98, 98, 0.9)", fontSize: 32 }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ fontWeight: 300 }}
              >
                Oops! It seems this job posting is missing or has been deleted.
              </Typography>
              <br />
              <Link to="/jobs/search/">
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ textTransform: "none" }}
                >
                  <Typography variant="body2" style={{ fontWeight: 300 }}>
                    Click here to search for other jobs!
                  </Typography>
                </Button>
              </Link>
            </Paper>
          ) : isLoadedJobData ? (
            <div>
              <JobMarkupCg
                jobData={jobData}
                disabled={disabled}
                msgFamily={this.handleMsgChn}
                handleApply={this.handleApplyJob}
                updateStateJobStatus={this.updateStateJobStatus}
                history={this.props.history}
              />
            </div>
          ) : (
            <Paper
              elevation={4}
              className={clsx(
                classes.paperContainer
                // classes.darkGrayBorderOnly
              )}
            >
              <CircularProgress thickness={2} size={150} />
            </Paper>
          )}
        </Grid>
        {/* // Related jobs in xs, sm view */}
        <Box
          component={Grid}
          item
          xs={12}
          display={{ sm: "block", md: "none" }}
        >
          <Typography variant="h6" style={{ fontWeight: 300 }}>
            Related
          </Typography>
          <Divider />
          {!queryLoaded && (
            <Box className={classes.form}>
              <CircularProgress thickness={2} />
            </Box>
          )}
          <Box style={{ overflow: "auto" }}>
            {jobListMarkup}
            {jobListRemainderMarkup}
            {jobListMarkup.length < 1 && jobListRemainderMarkup.length < 1 && (
              <Typography>No related job postings found.</Typography>
            )}
          </Box>
        </Box>
        {/* // Related jobs in md view */}
        <Box
          component={Grid}
          item
          md={4}
          display={{ xs: "none", sm: "none", md: "block" }}
        >
          <Typography variant="h6" style={{ fontWeight: 300 }}>
            Related
          </Typography>
          <Divider />
          {!queryLoaded && (
            <Box className={classes.form}>
              <CircularProgress thickness={2} />
            </Box>
          )}
          <Grid container style={{ overflow: "auto", maxHeight: "100vh" }}>
            {jobListMarkup}
            {jobListRemainderMarkup}
            {jobListMarkup.length < 1 && jobListRemainderMarkup.length < 1 && (
              <Typography>No related job postings found.</Typography>
            )}
          </Grid>
        </Box>
      </Grid>
    );
  }
}

jobPage.propTypes = {
  data: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  queryJobs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  UI: state.UI,
  user: state.user,
});

export default connect(mapStateToProps, { queryJobs })(
  withStyles(styles)(jobPage)
);
