import React, { Component } from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

export class SinglePie extends Component {
  render() {
    const { title, hideLegend, dataset } = this.props;
    const labels = Object.keys(dataset);

    const _data = labels.map((label) => dataset[label]);
    const data = {
      labels: labels,
      datasets: [
        {
          label: title ? title : null,
          data: _data,
          backgroundColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
          datalabels: {
            anchor: "end",
          },
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      title: {
        text: title && title,
        display: title ? true : false,
      },

      plugins: {
        legend: {
          display: hideLegend ? false : true,
          position: "right",
          onClick: null,
        },
        datalabels: {
          backgroundColor: function (context) {
            return context.dataset.backgroundColor;
          },
          borderColor: "white",
          borderRadius: 25,
          borderWidth: 2,
          color: "white",
          display: function (context) {
            var dataset = context.dataset;
            var count = dataset.data.length;

            var value = dataset.data[context.dataIndex];
            return value > count * 1.5;
          },
          font: {
            weight: "bold",
          },
          padding: 6,
          formatter: function (value) {
            return "$" + value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              return `${context[0].label}`;
            },
            label: function (context) {
              let label = context.dataset.label || "";
              let value = context.raw;

              if (label) {
                label += ": ";
              }
              if (value !== null) {
                label += new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(value);
              }
              return label;
            },
            footer: function (context) {
              const total = context.reduce((a, b) => a + b.raw, 0);
              return `TOTAL: ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(total)}`;
            },
          },
        },
        aspectRatio: 4 / 3,
        cutoutPercentage: 32,
        layout: {
          padding: 32,
        },
        elements: {
          line: {
            fill: false,
          },
          point: {
            hoverRadius: 7,
            radius: 5,
          },
        },
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 15,
          bottom: 15,
        },
      },

      elements: {
        center: {
          text:
            _data.length > 0
              ? `$${_data
                  .reduce((a, b) => a + b)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}`
              : null,
          color: "#FF6384", // Default is #000000
          fontStyle: "Arial", // Default is Arial
          sidePadding: 20, // Default is 20 (as a percentage)
          minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
          lineHeight: 25, // Default is 25 (in px), used for when text wraps
        },
      },
    };

    return (
      <Doughnut data={data} plugins={[ChartDataLabels]} options={options} />
    );
  }
}

SinglePie.propTypes = {
  dataset: PropTypes.object.isRequired,
  title: PropTypes.string,
  hideLegend: PropTypes.bool,
};
export default SinglePie;
