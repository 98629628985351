import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { unsetAlert } from "../../redux/actions/uiActions";

import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";

import CloseIcon from "@mui/icons-material/Close";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export class AlertMarkup extends Component {
  componentDidUpdate(prevProps) {
    const { isAlert } = this.props.alert;
    if (isAlert && !prevProps.isAlert) {
      const timeout = setTimeout(() => this.handleCloseAlert(), 6000);
      this.timeout = timeout;
    }
  }

  handleCloseAlert = () => {
    clearTimeout(this.timeout);
    this.props.unsetAlert();
  };
  render() {
    const {
      alert: { isAlert, message, type, link, btnTxt, url },
      user: { authenticated, credentials },
    } = this.props;

    const online = navigator.onLine;

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isAlert || !online}
        key={message}
        style={
          authenticated && credentials.status.isProfileCompleted
            ? {}
            : { marginLeft: 0 }
        }
        onClose={this.handleCloseAlert}
      >
        <Alert
          onClose={this.handleCloseAlert}
          severity={online ? type.toLowerCase() : "error"}
          action={
            <React.Fragment>
              {link && (
                <Button color="inherit" size="small" component={Link} to={url}>
                  {btnTxt}
                </Button>
              )}
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={this.handleCloseAlert}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        >
          {online
            ? message
            : "There's a problem with your connection right now."}
        </Alert>
      </Snackbar>
    );
  }
}

AlertMarkup.propTypes = {
  unsetAlert: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.UI.alert,
  user: state.user,
});

export default connect(mapStateToProps, { unsetAlert })(AlertMarkup);
