import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Component imports
import firebase from "../../Firebase";
import TooltipButton from "./TooltipButton";

import withStyles from '@mui/styles/withStyles';
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const styles = (theme) => ({
  ...theme.spreadThis,
  responsiveIconButton: {
    position: "absolute",
    border: `2px solid  ${theme.palette.primary.main}`,
    backgroundColor: "rgb(255,255,255)",
    "&:hover": {
      backgroundColor: "rgb(233	245	232)",
    },
  },
});
export class ProfilePicUploadButton extends Component {
  handleEditPicture = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };
  handleImageChange = (event) => {
    event.preventDefault();
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image, image.name);
    this.uploadImg(formData);
  };

  uploadImg = async (formData) => {
    if (this.props.onLoad) this.props.onLoad();

    try {
      const imgUrlThumb = await axios.post("/user/image", formData);

      const user = firebase.auth().currentUser;
      user.updateProfile({
        photoURL: imgUrlThumb.data.imgUrlThumb,
      });
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (err) {
      console.error(err);
      if (this.props.onError) this.props.onError(err.response.data);
    } finally {
      if (this.props.onFinish) this.props.onFinish();
    }
  };

  render() {
    const { classes, className, style, size, tip } = this.props;
    return (
      <Fragment>
        <input
          type="file"
          id="imageInput"
          hidden="hidden"
          onChange={this.handleImageChange}
        />
        <TooltipButton
          tip={tip ? tip : "Edit profile picture"}
          onClick={this.handleEditPicture}
          btnClassName={className ? className : classes.responsiveIconButton}
          size={size ? size : "medium"}
          style={style ? style : {}}
        >
          <CameraAltIcon color="primary" />
        </TooltipButton>
      </Fragment>
    );
  }
}

ProfilePicUploadButton.propTypes = {
  style: PropTypes.object,
  size: PropTypes.string,
  tip: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  onFinish: PropTypes.func,
};

export default withStyles(styles)(ProfilePicUploadButton);
