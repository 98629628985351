import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function Story() {
  return (
    <>
      <Typography variant="h3" className="txt-bold">
        Story
      </Typography>
      <div className="title-line" />
      <Typography
        variant="h5"
        className="txt-bold"
        align="center"
        style={{ marginTop: 36 }}
      >
        Why do you know more about the person delivering your food than the
        person taking care of your loved ones?
      </Typography>

      <Grid
        container
        spacing={4}
        direction="row-reverse"
        style={{ marginTop: 0 }}
      >
        <Grid item xs={12} sm={8}>
          <Typography color="textSecondary" style={{ whiteSpace: "pre-line" }}>
            That was the topic of discussion one family dinner with parents each
            10+ years veterans in the senior care industry.
            {"\n\n"}
            We talked about their experiences visiting seniors - often with only
            just a name, address and simple directions. We talked about public
            subsidized services often insufficient, impersonal and inconsistent.
            We talked about private agencies where the same care is expensive,
            with poorly compensated caregivers and constant turnover.
            {"\n\n"}
            Finding quality home care can often feel frustrating and
            discouraging.
            {"\n\n"}
            Poyo Health was founded out of that frustration to deliver
            exceptional care experiences for families and caregivers. We combine
            smart technology with trained Care Professionals to deliver a
            personal care solution that enables your loved ones to age and
            recover gracefully and with integrity. Our platform leverages smart
            technology to uncover efficienies in the care process and passes on
            those savings to families and caregiver pay. Our real-time care
            updates deliver true peace of mind and superior quality of care.
            {"\n\n"}
            At Poyo, we take pride in our Care Professionals and believe in that
            valued people do better care; our goal is to make better care
            accessible for all.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="flexbox-centered">Img</div>
        </Grid>
      </Grid>
    </>
  );
}

export default Story;
