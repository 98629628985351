import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const StyledButton = withStyles({
  root: {
    textTransform: "none",
    height: "100%",
  },
})(Button);

export class ConditionsMarkup extends Component {
  render() {
    const { classes, error, conditionsObj, conditionsArray } = this.props;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="primary"
            className={classes.typography}
            style={{ margin: "10px 0px", fontWeight: 300 }}
          >
            Conditions
          </Typography>
          <Box className={classes.sectionBoxLeftOutline}>
            <Typography
              variant="body2"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              What type of special conditions are you trained and ready to deal
              with? Select the ones applicable below.
            </Typography>

            {error && (
              <Typography
                variant="caption"
                style={{ color: "#f44336", fontWeight: 300 }}
              >
                {error}
              </Typography>
            )}

            <Grid
              container
              justifyContent="center"
              spacing={1}
              alignItems="stretch"
              style={{ marginTop: 10 }}
            >
              {Object.entries(conditionsObj)
                .sort()
                .map((condition) => (
                  <Grid item xs={8} sm={5} md={5} key={condition}>
                    <StyledButton
                      size="large"
                      name={condition[0]}
                      onClick={(e) => this.props.handleCondition(e)}
                      fullWidth
                      className={
                        Boolean(error)
                          ? conditionsArray.includes(condition[0])
                            ? classes.errorSelectedToggleButton
                            : classes.errorUnselectedToggleButton
                          : conditionsArray.includes(condition[0])
                          ? classes.selectedToggleButton
                          : classes.unselectedToggleButton
                      }
                      startIcon={
                        conditionsArray.includes(condition[0]) ? (
                          <LocalHospitalIcon style={{}} />
                        ) : (
                          <LocalHospitalOutlinedIcon style={{}} />
                        )
                      }
                    >
                      <Typography
                        variant="body2"
                        color={
                          !Boolean(error) &&
                          !conditionsArray.includes(condition[0])
                            ? "textSecondary"
                            : "inherit"
                        }
                        style={{
                          width: "100%",
                        }}
                      >
                        {condition[1]}
                      </Typography>
                    </StyledButton>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          style={{ width: "100%", marginTop: 15 }}
        >
          <Grid item>
            <StyledButton
              color="primary"
              onClick={() => this.props.handleBack()}
              style={{ width: 100 }}
            >
              Back
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={() => this.props.handleCompleteConditions()}
              style={{ width: 100 }}
            >
              Next
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ConditionsMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCompleteConditions: PropTypes.func.isRequired,
  handleCondition: PropTypes.func.isRequired,
  conditionsObj: PropTypes.object.isRequired,
  error: PropTypes.string,
  conditionsArray: PropTypes.array.isRequired,
};

export default withStyles(styles)(ConditionsMarkup);
