import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Component imports
import NewShift from "../../shiftsTasks/NewShift";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Icon imports
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class AddShiftButton extends Component {
  state = { open: false };

  componentDidMount() {
    const { initialOpen } = this.props;
    if (initialOpen) {
      this.setState({ open: true });
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { isInitialSetShifts, currMemberData, style, classes } = this.props;
    return (
      <Fragment>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          style={style ? style : { textTransform: "none" }}
          onClick={this.handleOpen}
          disabled={!isInitialSetShifts}
        >
          Add Shift
        </Button>
        <Dialog
          fullWidth={true}
          maxWidth={false}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.headerBarRounded}
          >
            <Typography variant="h6">Add shifts</Typography>
            <IconButton
              size="small"
              onClick={this.handleClose}
              className={classes.closeButton}
            >
              <CloseIcon color="inherit" />
            </IconButton>
          </Box>
          <DialogContent style={{ marginBottom: 24 }}>
            <NewShift
              currMemberData={currMemberData}
              isNew={open}
              onSuccess={this.handleClose}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

AddShiftButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isInitialSetShifts: PropTypes.bool.isRequired,
  initialOpen: PropTypes.bool,
  style: PropTypes.object,
};

export default withStyles(styles)(AddShiftButton);
