import React, { Component } from "react";

// Redux imports
import { connect } from "react-redux";
import {
  setAvail,
  clearErrors,
  delAvail,
} from "../../../../redux/actions/userActions";

// Component imports
import AddMyAvail from "../about/AddMyAvail";
import MyAvail from "../about/MyAvail";
import TooltipButton from "../../../../components/inputs/TooltipButton";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Icons imports
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class MyAvailBlock extends Component {
  state = {
    addAvailability: false,
    showDelete: false,
    errors: {},
  };

  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    // Sets initial addAvailability based on user status
    if (!this.props.credentials.status.isAvailComplete) {
      this.setState({ addAvailability: true });
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.UI.errors) {
      return {
        errors: props.UI.errors,
      };
    }
    return null;
  }

  // UI methods
  handleShowAddAvail = () => {
    this.props.clearErrors();

    this.setState({
      addAvailability: !this.state.addAvailability,
      errors: { ...this.state.errors, avail: null },
    });
    if (!this.state.addAvailability)
      this.addAvailContainer.scrollIntoView({ behavior: "smooth" });
  };

  handleShowDeleteAvail = () =>
    this.setState({ showDelete: !this.state.showDelete });

  // Action methods

  handleSetAvail = (value) => {
    this.props.setAvail({ avail: value });
  };

  handleDelAvail = (value) => {
    this.props.delAvail(value);
  };

  render() {
    const {
      classes,
      credentials,
      UI: { loading },
    } = this.props;

    const { showDelete, addAvailability, errors } = this.state;

    return (
      <div>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h6"
            align="left"
            className={classes.typography}
            style={{ fontWeight: 300 }}
          >
            My Availability
          </Typography>

          <div>
            <TooltipButton
              tip={"Delete Time"}
              onClick={this.handleShowDeleteAvail}
              size="small"
            >
              {showDelete ? (
                <DeleteIcon color="primary" />
              ) : (
                <DeleteOutlineIcon color="primary" />
              )}
            </TooltipButton>
            <TooltipButton
              tip={!addAvailability ? "Add Availability" : "Close"}
              onClick={this.handleShowAddAvail}
              size="small"
            >
              {!addAvailability ? (
                <AddIcon color="primary" />
              ) : (
                <CloseIcon color="primary" />
              )}
            </TooltipButton>
          </div>
        </Box>
        <div style={{ marginTop: 16 }}>
          {!Boolean(credentials.status.isAvailComplete) && (
            <Typography
              variant="body2"
              align="center"
              color="error"
              className={classes.typography}
              style={{ marginBottom: 10 }}
            >
              Uh oh! It seems that you have yet to set your available hours.
              Please select from below.
            </Typography>
          )}

          <MyAvail
            availData={credentials.myAvail}
            showDelete={showDelete}
            onDelAvail={this.handleDelAvail}
          />
          <div
            id="add-avail-autoscroll"
            ref={(el) => {
              this.addAvailContainer = el;
            }}
          >
            {addAvailability && (
              <AddMyAvail
                disabledWhileLoad={loading}
                submitAvail={this.handleSetAvail}
                isError={errors.avail ? true : false}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  UI: state.UI,
  credentials: state.user.credentials,
});

export default connect(mapStateToProps, { clearErrors, delAvail, setAvail })(
  withStyles(styles)(MyAvailBlock)
);
