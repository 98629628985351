import React, { Component, Fragment } from "react";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";

// Component Imports
import ConfirmLeave from "./components/ConfirmLeave";
import SFMyNeeds from "./components/SFMyNeeds";
import SFServiceNeeds from "./components/SFServiceNeeds";
import SFConditions from "./components/SFConditions";
import SFLanguage from "./components/SFLanguage";
import SFBasicInfo from "./components/SFBasicInfo";
import SFLogin from "./components/SFLogin";
import TooltipButton from "../../../components/inputs/TooltipButton";

// Redux imports
import { connect } from "react-redux";
import { loginUser } from "../../../redux/actions/userActions";
import { withLoginHOC } from "../../../util/withFBHooks";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

// Icons imports
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
  ...theme.spreadThis,

  signupDivColor: {
    height: "100%",
    backgroundColor: `${theme.palette.primary.light}`,
  },
});

export class SignupFamilyDialog extends Component {
  state = {
    open: false,
    errors: { email: null, general: null },
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    firstName: "",
    lastName: "",
    phoneNum: "",
    address: {
      addressFull: "",
      addressParams: {
        unit: "",
        streetNumber: "",
        streetRoute: "",
        locality: "",
        adminAreaLevel1: "",
        country: "",
        postalCode: "",
      },
      coordinates: {},
    },
    conditionsArray: [],
    serviceStart: "",
    servicePerWeek: "",
    serviceLength: "",
    serviceArray: [],
    signupMaterials: {
      language: [],
      service: {},
      jobType: {},
      conditions: {},
    },
    isLoadedSignupMaterials: false,
    languagePref: ["english"],
    pages: {
      p1: false,
      p2: false,
      p3: false,
      p4: false,
      p5: false,
      confirmLeave: false,
    },
    loading: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Load Methods

  componentDidMount() {
    this.getSignupMaterials();
  }

  getSignupMaterials() {
    axios
      .get("/signup-material")
      .then((res) => {
        this.setState({
          signupMaterials: res.data,
          isLoadedSignupMaterials: true,
        });
      })
      .catch((err) => console.error(err));
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  // UI methods

  scrollToTop = () => {
    this.scrollShifts.scrollIntoView();
  };

  handleOpen = () => {
    this.setState({ ...this.state, open: true });
  };

  handleConfirmLeave = () => {
    const { confirmLeave } = this.state.pages;
    if (confirmLeave) {
      this.handleClose();
    } else {
      this.setState({
        pages: {
          ...this.state.pages,
          confirmLeave: true,
        },
      });
    }
  };

  handleConfirmBack = () => {
    this.setState((prevState) => ({
      ...prevState,
      pages: {
        ...prevState.pages,
        confirmLeave: false,
      },
    }));
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              pages: {
                p1: false,
                p2: false,
                p3: false,
                p4: false,
                p5: false,
                confirmLeave: false,
              },
            }),
          500
        )
    );
  };

  // Action methos

  handleSubmit = async () => {
    const {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      address,
      phoneNum,
      languagePref,
      serviceArray,
      serviceStart,
      servicePerWeek,
      serviceLength,
      conditionsArray,
      pages,
    } = this.state;

    if (pages.p5) {
      const newUserData = {
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        address,
        phoneNum,
        languagePref,
        service: Object.fromEntries(serviceArray.map((v) => [v, true])),
        serviceStart,
        servicePerWeek,
        serviceLength,
        conditions: Object.fromEntries(conditionsArray.map((v) => [v, true])),
      };
      await this.setStateAsync({ loading: true, errors: {} });

      try {
        await axios.post("/signup-family", newUserData);

        this.handleLoginAfterSignup();
      } catch (err) {
        await this.setStateAsync({
          loading: false,
          errors: err.response.data,
        });

        return;
      }
    }
  };

  handleLoginAfterSignup = () => {
    const { email, password } = this.state;

    this.props.loginHook(email, password);

    this.props.loginUser({ email, password });
  };

  // Toggle next page child components

  handleMyNeeds = (value) => {
    this.scrollToTop();
    this.setState({ pages: { ...this.state.pages, p1: true }, ...value });
  };

  handleServiceNeeds = (value) => {
    this.scrollToTop();

    this.setState({ pages: { ...this.state.pages, p2: true }, ...value });
  };

  handleConditions = (value) => {
    this.scrollToTop();

    this.setState({ pages: { ...this.state.pages, p3: true }, ...value });
  };

  handleLanguage = (value) => {
    this.scrollToTop();

    this.setState({ pages: { ...this.state.pages, p4: true }, ...value });
  };

  handleBasicInfo = (value) => {
    this.scrollToTop();

    this.setState({ pages: { ...this.state.pages, p5: true }, ...value });
  };

  handleSignup = (value) => {
    this.setState({ ...value }, () => this.handleSubmit());
  };

  render() {
    const { classes, btnText, btnProps } = this.props;
    const {
      errors,
      open,
      pages,
      isLoadedSignupMaterials,
      signupMaterials,
      serviceStart,
      servicePerWeek,
      serviceLength,
      serviceArray,
      conditionsArray,
      languagePref,
      firstName,
      lastName,
      phoneNum,
      address,
      loading,
    } = this.state;

    const whichPage = (
      <div>
        <div className={clsx(classes.headerBarRounded, classes.flexBoxEnd)}>
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.scrollShifts = el;
            }}
          />
          <TooltipButton
            tip="Cancel"
            placement="top"
            size="small"
            onClick={this.handleConfirmLeave}
            tipClassName={classes.closeButton}
          >
            <CloseIcon color="inherit" />
          </TooltipButton>
        </div>
        {pages.confirmLeave ? (
          <Fade in={pages.confirmLeave}>
            <div>
              <ConfirmLeave
                handleClose={this.handleClose}
                handleConfirmBack={this.handleConfirmBack}
              />
            </div>
          </Fade>
        ) : pages.p5 ? (
          <Fade in={pages.p5}>
            <div>
              <SFLogin
                loading={loading}
                onSignup={this.handleSignup}
                errorMsgEmail={Boolean(errors.email) ? errors.email : null}
                errorMsg={Boolean(errors.general) ? errors.general : null}
                fadeIn={6}
                total={6}
              />
            </div>
          </Fade>
        ) : pages.p4 ? (
          <Fade in={pages.p4}>
            <div>
              <SFBasicInfo
                onSelectBasicInfo={this.handleBasicInfo}
                firstName={firstName}
                lastName={lastName}
                phoneNum={phoneNum}
                address={address}
                fadeIn={5}
                total={6}
              />
            </div>
          </Fade>
        ) : pages.p3 ? (
          <Fade in={pages.p3}>
            <div>
              <SFLanguage
                languageArray={signupMaterials.language}
                onSelectLanguage={this.handleLanguage}
                languagePref={languagePref}
                fadeIn={4}
                total={6}
              />
            </div>
          </Fade>
        ) : pages.p2 ? (
          <Fade in={pages.p2}>
            <div>
              <SFConditions
                conditionsObj={signupMaterials.conditions}
                onSelectConditions={this.handleConditions}
                conditionsArray={conditionsArray}
                fadeIn={3}
                total={6}
              />
            </div>
          </Fade>
        ) : pages.p1 ? (
          <Fade in={pages.p1}>
            <div>
              <SFServiceNeeds
                serviceObj={signupMaterials.service}
                onSelectServiceNeeds={this.handleServiceNeeds}
                isLoaded={isLoadedSignupMaterials}
                serviceArray={serviceArray}
                fadeIn={2}
                total={6}
              />
            </div>
          </Fade>
        ) : (
          <Fade in={true}>
            <div>
              <SFMyNeeds
                onSelectMyNeeds={this.handleMyNeeds}
                serviceStart={serviceStart}
                servicePerWeek={servicePerWeek}
                serviceLength={serviceLength}
                fadeIn={1}
                total={6}
              />
            </div>
          </Fade>
        )}
      </div>
    );

    return (
      <Fragment>
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          fullWidth
          onClick={this.handleOpen}
          style={{ margin: "8px 0px", textTransform: "none" }}
          {...btnProps}
        >
          {btnText ? btnText : "FIND CARE NOW"}
        </Button>
        <Dialog
          open={open}
          onClose={this.handleConfirmLeave}
          fullWidth
          maxWidth={"md"}
          PaperProps={{
            style: { borderRadius: 25 },
          }}
        >
          <Grid container>
            <Grid
              item
              component={Box}
              display={{ xs: "none", sm: "block" }}
              sm={3}
            >
              <div className={classes.signupDivColor}></div>
            </Grid>
            <Grid item xs={12} sm={9}>
              {whichPage}
            </Grid>
          </Grid>
        </Dialog>
      </Fragment>
    );
  }
}

SignupFamilyDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { loginUser })(
  withLoginHOC(withStyles(styles)(SignupFamilyDialog))
);
