import React, { Component } from "react";
import PropTypes from "prop-types";
import CaregiverProfile from "../../caregiverPages/profile/CaregiverProfile";
import FamilyProfile from "../../familyPages/profile/FamilyProfile";

// Redux imports
import { connect } from "react-redux";

export class profile extends Component {
  render() {
    const {
      user,
      user: {
        credentials: { userType },
      },
    } = this.props;

    return (
      <div className="res-gr-con">
        {userType === "family" ? (
          <FamilyProfile />
        ) : userType === "caregiver" ? (
          <CaregiverProfile user={user} />
        ) : null}
      </div>
    );
  }
}

profile.propTypes = {
  user: PropTypes.object.isRequired,
  UI: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps)(profile);
