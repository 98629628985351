import React from "react";
import PropTypes from "prop-types";

// Component imports
import LoadingEllipsis from "./LoadingEllipsis";

import withStyles from '@mui/styles/withStyles';
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function LoadingAnimation({ size, message, thickness, marginTop, ...props }) {
  return (
    <div
      className={props.classes.flexBoxCentered}
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        marginTop: marginTop ? marginTop : 0,
      }}
    >
      <CircularProgress
        size={size ? size : 45}
        thickness={thickness ? thickness : 2}
      />
      <LoadingEllipsis
        component="div"
        variant="caption"
        color="textSecondary"
        style={{ marginTop: 8 }}
        prefix={message ? message : "Loading"}
      />
    </div>
  );
}

LoadingAnimation.propTypes = {
  classes: PropTypes.object,
  size: PropTypes.number,
  thickness: PropTypes.number,
  message: PropTypes.string,
};

export default withStyles(styles)(LoadingAnimation);
