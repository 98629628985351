import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/uiActions";
import { updateDataArrayId } from "../../redux/actions/dataActions";

// Component imports
import OutlinedProfilePic from "../layout/OutlinedProfilePic";
import { capitalizeFirstChar, isEmpty } from "../../util/utilFunctions";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";

import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ReviewMarkup extends Component {
  state = {
    empathy: null,
    punctuality: null,
    knowledge: null,
    helpfulness: null,
    message: "",
    title: "",
    errors: {
      empathy: false,
      punctuality: false,
      knowledge: false,
      helpfulness: false,
      message: false,
    },
    disableWhileLoad: false,
    isEdit: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Initial Load Methods
  componentDidMount() {
    this._setInitialReview();
  }

  _setInitialReview() {
    const {
      message,
      title,
      breakdown: { empathy, punctuality, knowledge, helpfulness },
    } = this.props.review;

    this.setState({
      empathy,
      punctuality,
      knowledge,
      helpfulness,
      message,
      title,
    });
  }

  // UI Methods
  _setEdit = () => {
    this.setState(
      { isEdit: !this.state.isEdit },
      // Reset review on edit button click
      () => {
        if (!this.state.isEdit) this._setInitialReview();
      }
    );
  };

  // Action Methods

  _setRatingValue = (event, value) => {
    if (value !== null)
      this.setState({
        [event.target.name]: value,
        errors: { ...this.state.errors, [event.target.name]: false },
      });
  };

  _setText = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: { ...this.state.errors, [event.target.name]: false },
    });
  };

  // Submit Methods

  _submitReview = async (event) => {
    event.preventDefault();
    await this.setStateAsync({ disableWhileLoad: true });
    const valid = this._validateReview();
    if (valid) {
      try {
        const { empathy, punctuality, knowledge, helpfulness, message, title } =
          this.state;

        const editedReview = {
          empathy,
          punctuality,
          knowledge,
          helpfulness,
          message,
          title,
        };

        const reviewDoc = await axios.post(
          `/review/edit/${this.props.review.id}`,
          editedReview
        );

        this.props.onSuccessEdit(reviewDoc.data);
        this.setState({ isEdit: false });
        this.props.setAlert({
          message: "Review has been updated.",
          type: "success",
        });
      } catch (err) {
        this.props.setAlert({
          message: Boolean(err.response.data.error)
            ? err.response.data.error
            : "An error has occured while editing your review. Please try again.",
          type: "error",
        });
      } finally {
        await this.setStateAsync({ disableWhileLoad: false });
      }
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  _deleteReview = async () => {
    await this.setStateAsync({ disableWhileLoad: true });
    try {
      await axios.post(`/review/delete/${this.props.review.id}`);
      this.props.setAlert({
        message: "Review has been deleted.",
        type: "success",
      });

      const updatedTeamMember = { ...this.props.member };
      updatedTeamMember.review.isReviewed = false;
      delete updatedTeamMember.review.id;

      this.props.updateDataArrayId(
        updatedTeamMember,
        "members",
        this.props.member.id
      );

      this.props.onSuccessDelete();
    } catch (err) {
      this.props.setAlert({
        message: Boolean(err.response.data.error)
          ? err.response.data.error
          : "An error has occured while editing your review. Please try again.",
        type: "error",
      });
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  // Validation Methods

  _validateReview = () => {
    const { message, title } = this.state;

    const errors = {};
    ["empathy", "punctuality", "knowledge", "helpfulness"].forEach((key) => {
      if (typeof this.state[key] !== "number")
        errors[key] = `Missing rating for ${key}.`;
    });

    if (isEmpty(message)) errors.message = "Enter a message with your review.";
    if (isEmpty(title)) errors.title = "Enter a title for your review.";

    this.setState({ errors });

    return Object.keys(errors).length < 1;
  };
  render() {
    const {
      classes,
      isFamily,
      member: { caregiverInfo, familyInfo, startDate, numShifts, id },
      review,
    } = this.props;

    const {
      empathy,
      punctuality,
      knowledge,
      helpfulness,
      message,
      errors,
      isEdit,
      title,
      disableWhileLoad,
    } = this.state;

    return (
      <div style={{ paddingBottom: 36 }}>
        <OutlinedProfilePic
          imgSrc={isFamily ? caregiverInfo.imgUrlThumb : familyInfo.imgUrlThumb}
          alt="profile pic"
        />
        <Typography variant="h6">
          {capitalizeFirstChar(
            isFamily ? caregiverInfo.firstName : familyInfo.clientFirstName
          )}{" "}
          {capitalizeFirstChar(
            isFamily ? caregiverInfo.lastName : familyInfo.clientLastName
          )}{" "}
          {!isFamily && (
            <Typography component="span" fontWeight={300}>
              {"("}
              {capitalizeFirstChar(familyInfo.firstName)}{" "}
              {capitalizeFirstChar(familyInfo.lastName)}
              {")"}
            </Typography>
          )}
        </Typography>

        <div className={classes.titleLine} />
        {isFamily && (
          <div style={{ marginTop: 12 }}>
            <Typography variant="subtitle1">
              Thank you for submitting a review!
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Your review enables{" "}
              <b>
                {capitalizeFirstChar(caregiverInfo.firstName)}{" "}
                {capitalizeFirstChar(caregiverInfo.lastName)}
              </b>{" "}
              to receive the recognition for their work and helps families find
              their future caregiver!
            </Typography>
          </div>
        )}
        <div style={{ marginTop: 12 }}>
          <Typography>
            Team Since:{" "}
            <Typography component="span" color="primary" fontWeight={600}>
              {dayjs(startDate).format("MMM DD, YYYY")}
            </Typography>
          </Typography>
          <Typography>
            Shifts Completed:{" "}
            <Typography component="span" color="primary" fontWeight={600}>
              {numShifts}
            </Typography>
          </Typography>
          <Typography>
            Member ID:{" "}
            <Typography
              component={Link}
              to={`/myteam/${id}`}
              color="primary"
              fontWeight={600}
              className={classes.typographyHover}
            >
              {id.toUpperCase().slice(0, 6)}
            </Typography>
          </Typography>
        </div>

        {isFamily && (
          <div
            className={classes.flexBox}
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              onClick={this._setEdit}
              variant={isEdit ? "outlined" : "text"}
              className={classes.txtTrButton}
              style={{ boxShadow: "none" }}
              endIcon={isEdit ? <EditIcon /> : <EditOutlinedIcon />}
              disabled={disableWhileLoad}
            >
              Edit
            </Button>
            <Button
              onClick={this._deleteReview}
              className={classes.txtTrButton}
              endIcon={<DeleteIcon />}
              disabled={disableWhileLoad}
            >
              Delete
            </Button>
          </div>
        )}
        <form onSubmit={this._submitReview}>
          <Grid
            container
            style={{ marginTop: 24 }}
            justifyContent="space-evenly"
            spacing={2}
          >
            <Grid item xs={12}>
              {!isEdit ? (
                <div>
                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="caption">
                    By: {capitalizeFirstChar(review.familyInfo.firstName)}{" "}
                    {capitalizeFirstChar(review.familyInfo.lastName)} | Posted:{" "}
                    {dayjs(review.createdAt).format("MMM DD, YYYY")}
                  </Typography>
                </div>
              ) : (
                <div>
                  <FormLabel>Title</FormLabel>
                  <TextField
                    placeholder="Title of review"
                    name="title"
                    value={title}
                    autoFocus
                    onChange={this._setText}
                    disabled={disableWhileLoad}
                    error={Boolean(errors.title)}
                    helperText={Boolean(errors.title) && errors.title}
                    className={
                      Boolean(errors.title)
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    fullWidth
                  />
                </div>
              )}
            </Grid>
            <Grid item id="rating-select">
              {["empathy", "punctuality", "knowledge", "helpfulness"].map(
                (key) => (
                  <div key={key}>
                    <Typography
                      component="legend"
                      color={Boolean(errors[key]) ? "error" : "inherit"}
                    >
                      {capitalizeFirstChar(key)}
                    </Typography>
                    <Rating
                      readOnly={!isEdit}
                      name={key}
                      value={this.state[key]}
                      onChange={this._setRatingValue}
                      size="large"
                    />
                  </div>
                )
              )}
            </Grid>
            <Grid item>
              <div
                className={classes.flexBoxCentered}
                style={{ height: "100%", flexDirection: "column" }}
              >
                <div
                  className={classes.flexBoxCentered}
                  style={{
                    flexDirection: "column",
                    backgroundColor: "honeydew",
                    borderRadius: "50%",
                    width: 160,
                    height: 160,
                    padding: 20,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    color="secondary"
                    style={{ height: 40 }}
                  >
                    {empathy !== null &&
                    punctuality !== null &&
                    knowledge !== null &&
                    helpfulness !== null ? (
                      [empathy, punctuality, knowledge, helpfulness].reduce(
                        (a, b) => a + b,
                        0
                      ) / 4
                    ) : (
                      <CircularProgress
                        variant="determinate"
                        value={[
                          empathy,
                          punctuality,
                          knowledge,
                          helpfulness,
                        ].reduce((a, b) => a + (b !== null ? 24 : 0), 4)}
                        thickness={4}
                        size={40}
                      />
                    )}
                  </Typography>
                  <Typography variant="h5">Final Rating</Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              {!isEdit ? (
                <div
                  style={{
                    padding: "16.5px 14px",
                    margin: "5px 0px",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {message}
                  </Typography>
                </div>
              ) : (
                <TextField
                  multiline
                  minRows={4}
                  maxRows={8}
                  placeholder="Include a message with your review..."
                  name="message"
                  value={message}
                  onChange={this._setText}
                  disabled={disableWhileLoad}
                  error={Boolean(errors.message)}
                  helperText={Boolean(errors.message) && errors.message}
                  className={
                    Boolean(errors.message)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  fullWidth
                />
              )}
            </Grid>
          </Grid>
          {isEdit && (
            <div
              className={classes.flexBox}
              style={{ justifyContent: "flex-end", marginTop: 12 }}
            >
              <Button
                type="submit"
                variant="contained"
                className={classes.txtTrButton}
                disabled={disableWhileLoad}
              >
                Submit Review{" "}
                {disableWhileLoad && (
                  <CircularProgress
                    thicnkess={2}
                    size={30}
                    className={classes.progress}
                  />
                )}
              </Button>
            </div>
          )}
        </form>
        <Button
          component={Link}
          to="/reviews"
          className={classes.txtTrButton}
          fullWidth
          variant="outlined"
        >
          All Reviews
        </Button>
      </div>
    );
  }
}

ReviewMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  review: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  onSuccessEdit: PropTypes.func.isRequired,
  onSuccessDelete: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  isFamily: PropTypes.bool,
};

export default connect(null, { setAlert, updateDataArrayId })(
  withStyles(styles)(ReviewMarkup)
);
