import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports

import WorkExperience from "./WorkExperience";
import CaregiverWorkDialog from "./CaregiverWorkDialog";
import EduAccred from "./EduAccred";
import CaregiverEduDialog from "./CaregiverEduDialog";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import SchoolIcon from "@mui/icons-material/SchoolOutlined";
import BusinessIcon from "@mui/icons-material/Business";

const styles = (theme) => ({
  ...theme.spreadThis,
  iconTextSecondary: {
    fontSize: 56,
    color: theme.palette.text.secondary,
  },
});

export class EduAccredBlock extends Component {
  render() {
    const { workExps, eduAccreds, classes, readOnly } = this.props;

    return (
      <div>
        <Box className={classes.flexBoxSpaced}>
          <Typography
            variant="h6"
            align="left"
            className={classes.typography}
            style={{ fontWeight: 300 }}
          >
            {" "}
            Experience
          </Typography>

          {!readOnly && <CaregiverWorkDialog />}
        </Box>

        <Box
          style={{
            marginTop: 8,
            minHeight: 150,
            overflowWrap: "break-word",
            textAlign: "left",
          }}
        >
          {workExps.length < 1 ? (
            <Box
              style={{
                height: 150,
                flexDirection: "column",
              }}
              className={classes.flexBoxCentered}
            >
              <BusinessIcon className={classes.iconTextSecondary} />
              <Typography
                variant="body2"
                className={classes.typography}
                color="textSecondary"
              >
                {readOnly
                  ? "Pending verification of work experience."
                  : "You currently do not have any work experiences listed on your profile. Please use the add icon to add a new work experience."}
              </Typography>
            </Box>
          ) : (
            workExps.map((workExp) => (
              <WorkExperience
                workExp={workExp}
                key={workExp.workExpId}
                isFamily={readOnly}
              />
            ))
          )}
        </Box>

        <Divider
          orientation="horizontal"
          style={{ marginTop: 8, marginBottom: 8 }}
        />
        <Box className={classes.flexBoxSpaced}>
          <Typography
            variant="h6"
            align="left"
            className={classes.typography}
            style={{ fontWeight: 300 }}
          >
            Education
          </Typography>

          {!readOnly && <CaregiverEduDialog />}
        </Box>
        <Box
          style={{
            overflowWrap: "break-word",
            textAlign: "left",
            marginTop: 8,
            minHeight: 150,
          }}
        >
          {eduAccreds.length < 1 ? (
            <Box
              className={classes.flexBoxCentered}
              style={{ height: 150, flexDirection: "column" }}
            >
              <SchoolIcon className={classes.iconTextSecondary} />

              <Typography
                variant="body2"
                align="center"
                className={classes.typography}
                color="textSecondary"
              >
                {readOnly
                  ? "Pending verification of education."
                  : "You currently do not have any education or accreditations listed on your profile. Please use the add icon to add a new education or accreditation."}
              </Typography>
            </Box>
          ) : (
            eduAccreds.map((eduAccred) => (
              <EduAccred
                isFamily={readOnly}
                eduAccred={eduAccred}
                key={eduAccred.eduAccredId}
              />
            ))
          )}
        </Box>
      </div>
    );
  }
}

EduAccredBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  workExps: PropTypes.array.isRequired,
  eduAccreds: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
};

export default withStyles(styles)(EduAccredBlock);
