import React, { Component } from "react";

import withStyles from '@mui/styles/withStyles';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

const styles = (theme) => ({
  ...theme.spreadThis,
  columnHeaderContainer: {
    display: "flex",
    alignItems: "center",
    height: 40,
    padding: 10,
    backgroundColor: "rgba(212,212,212,0.2)",
  },
  gridItem: {
    padding: "0px 8px",
    display: "flex",
    alignItems: "center",
  },
});

function CSSTypography(props) {
  return <Typography color="textSecondary" variant="body2" {...props} />;
}

export class ColumnHeader extends Component {
  render() {
    const { classes, userType } = this.props;

    const isFamily = userType === "family";
    return (
      <Box
        component={Paper}
        elevation={4}
        className={classes.columnHeaderContainer}
      >
        <Grid container item xs={12} alignItems="center">
          <Grid container item xs={9}>
            <Grid
              item
              xs={6}
              sm={4}
              className={classes.gridItem}
              style={{ paddingLeft: 0 }}
            >
              <CSSTypography align="left">
                {isFamily ? "Caregiver Info" : "Client Info"}
              </CSSTypography>
            </Grid>
            <Hidden smDown>
              <Grid item sm={5} className={classes.gridItem}>
                <CSSTypography>Shift Dates</CSSTypography>
              </Grid>
            </Hidden>
            <Grid item sm={3} className={classes.gridItem}>
              <CSSTypography>Date Paid</CSSTypography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            className={classes.gridItem}
            style={{ paddingRight: 24, justifyContent: "flex-end" }}
          >
            <CSSTypography align="right">
              {isFamily ? "Payment" : "Net Income"}
            </CSSTypography>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(ColumnHeader);
