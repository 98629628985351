import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Component imports
import BgCheckMarkup from "../signupProfileBuild/components/BgCheckMarkup";

// REDUX imports
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const styles = (theme) => ({
  ...theme.spreadThis,
  containerDiv: {
    marginLeft: 0,
    marginTop: 10,
    // [theme.breakpoints.down("lg")]: {
    //   marginLeft: 150,
    // },
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: 175,
    // },
    [theme.breakpoints.up("sm")]: {
      // marginLeft: 85,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
});

export class caregiverBg extends Component {
  render() {
    const {
      classes,
      user: { credentials },
    } = this.props;

    return (
      <div className={classes.containerDiv}>
        <Grid container justifyContent="center">
          <Grid item xs={11} sm={9} md={8} lg={7}>
            <Paper
              variant="elevation"
              elevation={4}
              className={clsx(
                classes.paperContainer,
                classes.darkGrayBorderOnly
              )}
            >
              <BgCheckMarkup credentials={credentials} hideBack />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

caregiverBg.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(caregiverBg));
