import React, { Component } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import axios from "axios";

// Component imports
import JobAppMarkupCompact from "../../../components/jobs/JobAppMarkupCompact";
import JobAppSingle from "../../../components/jobs/JobAppSingle";
import StyledNativeSelect from "../../../components/inputs/StyledNativeSelect";

// Redux imports
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import { lightBlue } from "@mui/material/colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,

  blue: {
    color: theme.palette.getContrastText(lightBlue[400]),
    backgroundColor: lightBlue[400],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  tabBox: {
    borderRight: "1px solid rgba(217,217,217)",
    borderBottom: "1px solid rgba(217,217,217)",
    borderLeft: "1px solid rgba(217,217,217)",
    minHeight: 350,
    width: "100%",
  },
});

const CSSTab = withStyles((theme) => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    border: "1px solid rgba(217,217,217)",
  },
  selected: {
    backgroundColor: "rgba(212,212,212,0.3)",
  },
}))(Tab);

export class applications extends Component {
  state = {
    isJobAppsLoaded: false,
    jobApps: [],
    sortedJobApps: [],
    tabValue: 0,
    show: false,
    sortClass: "Newest",
  };

  // Initial Load Methods
  componentDidMount() {
    this.getJobApps();
  }

  async getJobApps() {
    try {
      const _jobApps = await axios.get("/job-applications/get");
      this.setState({
        isJobAppsLoaded: true,
        jobApps: _jobApps.data,
        sortedJobApps: _jobApps.data,
      });
    } catch (err) {
      this.setState({ isJobAppsLoaded: true });
    }
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  // Action Methods

  updateJobApp = (id, value) => {
    const { jobApps } = this.state;
    const pos = jobApps.findIndex((jobApp) => jobApp.id === id);
    if (pos > -1) {
      const _jobApps = jobApps;
      _jobApps[pos] = { ..._jobApps[pos], ...value };
      this.setState({ jobApps: _jobApps, sortedJobApps: _jobApps });
    }
  };

  // UI Methods

  setTabValue = (e, index) => {
    this.setState({ tabValue: index });
  };

  handleChangeIndex = (index) => {
    this.setState({ tabValue: index });
  };

  handleSortClass = (value) => {
    this.setState({ sortClass: value }, this.sortAllJobs);
  };

  sortAllJobs = () => {
    const {
      user: {
        credentials: { userType },
      },
    } = this.props;
    const { sortClass, jobApps } = this.state;

    let _jobApps = jobApps;

    switch (sortClass) {
      case "Newest":
        break;
      case "Oldest":
        _jobApps.sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0
        );
        break;
      case "Accepted":
        _jobApps = _jobApps.filter((app) => app.isAccepted);
        break;
      case "Declined":
        _jobApps = _jobApps.filter((app) => app.isDeclined);

        break;
      case "Unread":
        _jobApps = _jobApps.filter((app) => !app.isRead);

        break;
      case "Read":
        _jobApps = _jobApps.filter((app) => app.isRead);
        break;
      case "First Name":
        userType === "family"
          ? _jobApps.sort((a, b) =>
              a.caregiverInfo.firstName < b.caregiverInfo.firstName
                ? -1
                : a.caregiverInfo.firstName > b.caregiverInfo.firstName
                ? 1
                : 0
            )
          : _jobApps.sort((a, b) =>
              a.familyInfo.firstName < b.familyInfo.firstName
                ? -1
                : a.familyInfo.firstName > b.familyInfo.firstName
                ? 1
                : 0
            );

        break;
      case "Last Name":
        userType === "family"
          ? _jobApps.sort((a, b) =>
              a.caregiverInfo.lastName < b.caregiverInfo.lastName
                ? -1
                : a.caregiverInfo.lastName > b.caregiverInfo.lastName
                ? 1
                : 0
            )
          : _jobApps.sort((a, b) =>
              a.familyInfo.lastName < b.familyInfo.lastName
                ? -1
                : a.familyInfo.lastName > b.familyInfo.lastName
                ? 1
                : 0
            );

        break;
      default:
        break;
    }

    this.setState({ sortedJobApps: _jobApps });
  };

  render() {
    const {
      classes,
      match: {
        params: { jobAppId },
      },
    } = this.props;

    const { isJobAppsLoaded, jobApps, tabValue, sortClass, sortedJobApps } =
      this.state;

    const unactionedJobApps = jobApps
      .filter((jobApp) => !jobApp.isAccepted && !jobApp.isDeclined)
      .sort((a, b) =>
        !a.isRead && b.isRead ? -1 : a.isRead && !b.isRead ? 1 : 0
      );

    const actionedJobApps = jobApps.filter(
      (jobApp) => jobApp.isAccepted || jobApp.isDeclined
    );

    return (
      <div className="res-gr-con">
        {!isJobAppsLoaded ? (
          <Box className={classes.flexBoxCentered}>
            <CircularProgress thickness={2} size={250} />
          </Box>
        ) : (
          <Grid container justifyContent="center" style={{ padding: 4 }}>
            <Grid
              component={Paper}
              elevation={4}
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              style={{ border: `1px solid rgba(101,101,101,0.5)` }}
              className={classes.smallPaperContainer}
            >
              <Typography variant="h6" style={{ fontWeight: 300 }}>
                My Job Applications
              </Typography>
              <Divider />
              <br />
              {!Boolean(jobAppId) ? (
                <Fade in>
                  <div>
                    <Tabs
                      value={tabValue}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={this.setTabValue}
                    >
                      <CSSTab label="All" />
                      <CSSTab label="Pending" />
                      <CSSTab label="Actioned" />
                    </Tabs>
                    <Box className={classes.tabBox}>
                      <SwipeableViews
                        index={tabValue}
                        onChangeIndex={this.handleChangeIndex}
                        enableMouseEvents
                      >
                        <Box
                          value={tabValue}
                          index={0}
                          p={2}
                          style={{ minHeight: 350 }}
                        >
                          <Box
                            className={classes.flexBoxSpaced}
                            style={{ marginBottom: 8 }}
                          >
                            <Typography style={{ fontWeight: 300 }}>
                              All Applications
                            </Typography>

                            {sortedJobApps.length > 0 && (
                              <Avatar className={classes.blue}>
                                {sortedJobApps.length}
                              </Avatar>
                            )}
                          </Box>
                          <Box
                            className={classes.flexBoxSpaced}
                            style={{ marginBottom: 8 }}
                          >
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 300 }}
                            >
                              Sort by:
                            </Typography>
                            <StyledNativeSelect
                              value={sortClass}
                              values={[
                                "Newest",
                                "Oldest",
                                "Accepted",
                                "Declined",
                                "Unread",
                                "Read",
                                "First Name",
                                "Last Name",
                              ]}
                              onSelect={this.handleSortClass}
                            />
                          </Box>

                          <Divider />
                          {sortedJobApps.length > 0 ? (
                            sortedJobApps.map((jobApp) => (
                              <JobAppMarkupCompact
                                jobApp={jobApp}
                                key={jobApp.id}
                                markRead={this.updateJobApp}
                              />
                            ))
                          ) : (
                            <Typography
                              align="center"
                              color="textSecondary"
                              style={{ margin: 8 }}
                            >
                              <Typography
                                align="center"
                                color="textSecondary"
                                style={{ margin: 8 }}
                              >
                                {jobApps.length < 1
                                  ? `You do not have any job applications`
                                  : `You do not have any ${sortClass.toLowerCase()} applications`}
                              </Typography>
                            </Typography>
                          )}
                        </Box>
                        <Box
                          value={tabValue}
                          index={1}
                          p={2}
                          style={{ minHeight: 350 }}
                        >
                          <Box
                            className={classes.flexBoxSpaced}
                            style={{ marginBottom: 8 }}
                          >
                            <Typography style={{ fontWeight: 300 }}>
                              Applications Pending Action
                            </Typography>

                            {unactionedJobApps.length > 0 && (
                              <Avatar className={classes.blue}>
                                {unactionedJobApps.length}
                              </Avatar>
                            )}
                          </Box>
                          <Divider />
                          {unactionedJobApps.length > 0 ? (
                            unactionedJobApps.map((jobApp) => (
                              <JobAppMarkupCompact
                                jobApp={jobApp}
                                key={jobApp.id}
                                markRead={this.updateJobApp}
                              />
                            ))
                          ) : (
                            <Typography
                              align="center"
                              color="textSecondary"
                              style={{ margin: 8 }}
                            >
                              No applications pending action
                            </Typography>
                          )}
                        </Box>
                        <Box
                          value={tabValue}
                          index={2}
                          p={2}
                          style={{ minHeight: 350 }}
                        >
                          <Box
                            className={classes.flexBoxSpaced}
                            style={{ marginBottom: 8 }}
                          >
                            <Typography style={{ fontWeight: 300 }}>
                              Actioned Applications
                            </Typography>

                            {actionedJobApps.length > 0 && (
                              <Avatar className={classes.blue}>
                                {actionedJobApps.length}
                              </Avatar>
                            )}
                          </Box>
                          <Divider />
                          {actionedJobApps.length > 0 ? (
                            actionedJobApps.map((jobApp) => (
                              <JobAppMarkupCompact
                                jobApp={jobApp}
                                key={jobApp.id}
                                markRead={this.updateJobApp}
                              />
                            ))
                          ) : (
                            <Typography
                              align="center"
                              color="textSecondary"
                              style={{ margin: 8 }}
                            >
                              No actioned applications
                            </Typography>
                          )}
                        </Box>
                      </SwipeableViews>
                    </Box>
                  </div>
                </Fade>
              ) : (
                <Fade in>
                  <div>
                    <JobAppSingle
                      jobApp={jobApps.find((jobApp) => jobApp.id === jobAppId)}
                      updateJobApp={this.updateJobApp}
                      history={this.props.history}
                    />
                  </div>
                </Fade>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      //
    );
  }
}

applications.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(applications));
