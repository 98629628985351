import React, { Component } from "react";
import PropTypes from "prop-types";
import { capitalizeFirstChar } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  ...theme.spreadThis,
  msgChnCard: {
    padding: 8,
    height: 64,
    width: 300,
    [theme.breakpoints.down("lg")]: {
      width: 260,
    },
    [theme.breakpoints.down("md")]: {
      width: 64,
    },
    borderRight: `2px solid white`,
    "&:hover": {
      backgroundColor: "rgba(50,50,50,0.1)",
      borderRight: `2px solid ${theme.palette.primary.light}`,
    },
    textTransform: "none",
    textAlign: "left",
  },
  msgChnCardSel: {
    padding: 8,
    height: 64,
    width: 300,
    [theme.breakpoints.down("lg")]: {
      width: 260,
    },
    [theme.breakpoints.down("md")]: {
      width: 64,
    },
    backgroundColor: "rgba(50,50,50,0.08)",
    borderRight: `2px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: "rgba(50,50,50,0.1)",
      borderRight: `2px solid ${theme.palette.primary.light}`,
    },
    textTransform: "none",
    textAlign: "left",
  },

  contentBox: {
    paddingLeft: 10,
    display: "block",
    width: "calc(300px - 26px - 48px)",
    [theme.breakpoints.down("lg")]: {
      width: "calc(260px - 26px - 48px)",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  textWrap: {
    textOverflow: "ellipsis",
    width: "226px",
    [theme.breakpoints.down("lg")]: {
      width: "186px",
    },
  },
  imgThumb: {
    height: 48,
    width: 48,
    objectFit: "cover",
    borderRadius: "50%",
  },
  // content: {
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  // },
  unreadMarker: {
    width: 8,
    height: 8,
    backgroundColor: `${theme.palette.secondary.main}`,
    position: "absolute",
    top: "45%",
    right: 0,
    marginRight: "2%",
    borderRadius: "50%",
  },
});
export class ChnDisplay extends Component {
  state = {
    noImage:
      "https://firebasestorage.googleapis.com/v0/b/poyo-health1.appspot.com/o/no-img.png?alt=media",
  };
  handleSelectChn = () => {
    this.props.selChn(this.props.messageChannel.channelId);
  };
  render() {
    const {
      userType,
      classes,
      myId,
      isSelected,
      messageChannel: {
        caregiverInfo,
        familyInfo,
        latestMsg,
        latestMsg: { body, isRead, userIdNumber },
      },
    } = this.props;

    const { noImage } = this.state;
    let avatarHeader;

    if (userType === "family") {
      if (caregiverInfo.imgUrlThumb === noImage) {
        avatarHeader = (
          <Avatar className={classes.imgThumb}>
            {caregiverInfo.firstName[0].toUpperCase()}
            {caregiverInfo.lastName[0].toUpperCase()}
          </Avatar>
        );
      } else {
        avatarHeader = (
          <Avatar
            alt={`${caregiverInfo.firstName}${caregiverInfo.lastName}`}
            src={caregiverInfo.imgUrlThumb}
            className={classes.imgThumb}
          />
        );
      }
    } else if (userType === "caregiver") {
      if (familyInfo.imgUrlThumb === noImage) {
        avatarHeader = (
          <Avatar className={classes.imgThumb}>
            {familyInfo.firstName[0].toUpperCase()}
            {familyInfo.lastName[0].toUpperCase()}
          </Avatar>
        );
      } else {
        avatarHeader = (
          <Avatar
            alt={`${familyInfo.firstName}${familyInfo.lastName}`}
            src={familyInfo.imgUrlThumb}
            className={classes.imgThumb}
          />
        );
      }
    }

    return (
      <Box
        component={Button}
        onClick={this.handleSelectChn}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={isSelected ? classes.msgChnCardSel : classes.msgChnCard}
        style={{ position: "relative" }}
      >
        {"isRead" in latestMsg && !isRead && userIdNumber !== myId && (
          <div className={classes.unreadMarker} />
        )}

        {avatarHeader}

        <Box className={classes.contentBox}>
          <Typography noWrap className={classes.textWrap}>
            {capitalizeFirstChar(
              userType === "caregiver"
                ? familyInfo.firstName
                : caregiverInfo.firstName
            )}{" "}
            {capitalizeFirstChar(
              userType === "caregiver"
                ? familyInfo.lastName
                : caregiverInfo.lastName
            )}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
            component="div"
            noWrap
            className={classes.textWrap}
          >
            {body
              ? body
              : `You are now connected with ${
                  userType === "caregiver"
                    ? familyInfo.firstName
                    : caregiverInfo.firstName
                } ${
                  userType === "caregiver"
                    ? familyInfo.lastName
                    : caregiverInfo.lastName
                }`}
          </Typography>
        </Box>
      </Box>
    );
  }
}

ChnDisplay.propTypes = {
  userType: PropTypes.string.isRequired,
  messageChannel: PropTypes.object.isRequired,
  myId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ChnDisplay);
