import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";

// Component imports
import {
  capitalizeFirstChar,
  sameKeysInObj,
} from "../../../../util/utilFunctions";
import FavouriteIconButton from "../../../../components/buttons/favouriteIconButton/FavouriteIconButton";

// Redux imports
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActionArea from "@mui/material/CardActionArea";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Check";

const styles = (theme) => ({
  ...theme.spreadThis,

  cgButton: {
    color: `${theme.palette.primary.main}`,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      color: "white",
    },
    height: "100%",
  },
  cgCard: {
    borderRadius: 20,
    padding: 16,

    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 15px 0px",

    width: 320,
  },

  media: {
    height: 120,
    width: 120,
    objectFit: "cover",
    borderRadius: "50%",
    border: `3px solid ${theme.palette.primary.main}`,
  },
  responsiveIconButtonA: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  responsiveIconButtonB: {
    position: "absolute",
    top: 0,
    right: 30,
  },
});

export class CareProfile extends Component {
  render() {
    const {
      cgData,
      cgData: {
        firstName,
        lastName,
        wageExp,
        imgUrlThumb,
        address,
        userIdNumber,
        bio,
      },
      classes,
      userData,
      user: {
        credentials: { poyoHires, poyoInvites },
      },
    } = this.props;

    const serviceSame = sameKeysInObj(userData.service, cgData.service);
    const isHired = poyoHires.some(
      (cg) => cg.userIdNumber === cgData.userIdNumber
    );
    const isInvited = poyoInvites.some((id) => id === cgData.userIdNumber);
    return (
      <Card className={classes.cgCard}>
        <Box
          display="flex"
          justifyContent="center"
          style={{ position: "relative" }}
        >
          <CardActionArea
            component={Link}
            to={`/caregiver/${userIdNumber}`}
            className={classes.media}
          >
            <img
              src={imgUrlThumb}
              alt="Profile Pic"
              style={{
                height: "100%",
                width: "100%",

                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </CardActionArea>
          <div className={classes.responsiveIconButtonA}>
            {isHired ? (
              <Chip
                className={clsx(classes.chipGreen)}
                size="small"
                label="Hired"
              />
            ) : (
              isInvited && (
                <Chip
                  className={clsx(classes.chipGreen)}
                  size="small"
                  label="Invited"
                />
              )
            )}
            <FavouriteIconButton id={cgData.uid} />
          </div>
        </Box>

        <Typography align="center">
          {capitalizeFirstChar(firstName)} {capitalizeFirstChar(lastName)}
        </Typography>
        <Typography color="textSecondary" align="center">
          ${parseFloat(wageExp).toFixed(2)}/hr
        </Typography>
        <Typography
          component="div"
          variant="body2"
          className="threeLineEllipsis"
          color="textSecondary"
          align="left"
          style={{ whiteSpace: "pre-line", fontWeight: 300, height: 60 }}
        >
          {bio}
        </Typography>
        <div
          style={{
            border: "1px solid #30E3CA",
            borderRadius: 10,
            padding: 5,
            marginTop: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 10,
            }}
          >
            <CheckIcon
              color="primary"
              style={{
                marginRight: 10,
              }}
            />
            <Typography variant="body2" align="left">
              Background Checked
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 10,
            }}
          >
            <CheckIcon
              color="primary"
              style={{
                marginRight: 10,
              }}
            />
            <Typography variant="body2" align="left">
              References Verified
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 10,
            }}
          >
            <CheckIcon
              color="primary"
              style={{
                marginRight: 10,
              }}
            />
            <Typography variant="body2" align="left">
              {`${Math.ceil(
                (serviceSame.same / serviceSame.total) * 100
              )}% Services Match`}
            </Typography>
          </div>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 4 }} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" style={{ marginRight: 10 }}>
            {capitalizeFirstChar(address.addressParams.locality)},{" "}
            {address.addressParams.adminAreaLevel1.toUpperCase()}
          </Typography>

          <Button
            component={Link}
            to={`/caregiver/${userIdNumber}`}
            variant="text"
            size="small"
            className={classes.cgButton}
          >
            View More
          </Button>
        </Box>
        {/* </CardActionArea> */}
      </Card>
    );
  }
}

CareProfile.propTypes = {
  cgData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(CareProfile));
