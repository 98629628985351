import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Component imports
import {
  capitalizeFirstChar,
  isEmpty,
  allFalse,
} from "../../util/utilFunctions";
import StyledToggleButtonGroup from "../inputs/StyledToggleButtonGroup";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// Icon imports
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ActionJobApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      action: "",
      returnMessage: "",
      errors: {},
      disableWhileLoad: false,
    };
    this.initialState = this.state;
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  // UI Methods

  handleOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = (event, reason) => {
    if (reason !== "backdropClick") this.setState(this.initialState);
  };

  // Action methods

  handleToggleAction = (e, value) => {
    if (value !== null) {
      this.setState({
        action: value,
        errors: { ...this.state.errors, action: null },
      });
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: isEmpty(value) && "Please enter a return message.",
      },
    });
  };

  checkError = async () => {
    const { returnMessage, action, errors } = this.state;
    let _errors = errors;
    if (_errors.general) delete _errors.general;

    if (isEmpty(returnMessage))
      _errors.returnMessage = "Please enter a return message";
    if (isEmpty(action)) _errors.action = "Please select an action";

    await this.setStateAsync({ errors: _errors });
    return allFalse(_errors);
  };

  handleActionJobApp = async () => {
    await this.setStateAsync({ disableWhileLoad: true });
    const valid = await this.checkError();

    if (valid) {
      const { action, returnMessage } = this.state;
      const { jobApp } = this.props;

      try {
        const updatedJobApp = await axios.post(
          `/job-applications/${jobApp.id}/${action}`,
          { returnMessage }
        );
        if (this.props.updateJobApp) {
          this.props.updateJobApp(jobApp.id, updatedJobApp.data);
        }
        if (action === "accept")
          this.props.history.push(
            `/caregiver/${jobApp.caregiverInfo.userIdNumber}/hire`
          );

        this.handleClose();
      } catch (err) {
        await this.setStateAsync({
          errors: { general: err.response.data.error },
        });
      } finally {
        await this.setStateAsync({ disableWhileLoad: false });
      }
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const { openDialog, action, returnMessage, errors, disableWhileLoad } =
      this.state;
    const { jobApp, classes, fullWidth, color, variant } = this.props;

    return (
      <div>
        <Button
          onClick={this.handleOpen}
          fullWidth={fullWidth}
          color={color ? color : "inherit"}
          variant={variant ? variant : "contained"}
          disabled={jobApp.isAccepted || jobApp.isDeclined}
        >
          {jobApp.isAccepted || jobApp.isDeclined ? "Actioned" : "Action"}
        </Button>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          fullWidth
          disableEscapeKeyDown
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.headerBarRounded}
          >
            <Typography variant="h6">
              Respond to {capitalizeFirstChar(jobApp.caregiverInfo.firstName)}
              's Application?
            </Typography>
            <IconButton size="small" onClick={this.handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box p={2} style={{ minHeight: 300 }}>
            {Boolean(errors.general) && (
              <Typography align="center" color="error">
                {errors.general}
              </Typography>
            )}
            <Typography>What would you like to do?</Typography>
            <StyledToggleButtonGroup
              name="action"
              value={action}
              handleToggleValue={this.handleToggleAction}
              options={{ "Accept & Offer": "accept", Decline: "decline" }}
              error={Boolean(errors.action)}
              errorMsg={errors.action}
              exclusive
            />
            <Typography component="div" style={{ marginTop: 16 }}>
              Include a return message to{" "}
              <Box display="inline" style={{ fontWeight: 550 }}>
                {capitalizeFirstChar(jobApp.caregiverInfo.firstName)}
              </Box>
              :
            </Typography>

            <TextField
              multiline
              minRows={5}
              variant="outlined"
              name="returnMessage"
              value={returnMessage}
              onChange={this.handleChange}
              error={Boolean(errors.returnMessage)}
              helperText={errors.returnMessage}
              className={
                Boolean(errors.returnMessage)
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
              fullWidth
            />
          </Box>
          <DialogActions style={{ padding: "8px 16px" }}>
            <Button className={classes.txtTrButton} onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              className={classes.txtTrButton}
              disabled={isEmpty(returnMessage) || disableWhileLoad}
              color="primary"
              variant="outlined"
              onClick={this.handleActionJobApp}
            >
              Action{" "}
              {disableWhileLoad && (
                <CircularProgress
                  thickness={2}
                  size={30}
                  className={classes.progress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ActionJobApp.propTypes = {
  classes: PropTypes.object.isRequired,
  jobApp: PropTypes.object.isRequired,
  updateJobApp: PropTypes.func,
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
};

export default withStyles(styles)(ActionJobApp);
