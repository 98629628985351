import React from "react";

import SignupFamilyDialog from "../../../loginSignup/family/SignupFamilyDialog";
import FamilyImg from "../../../../assets/images/family-1.jpeg";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function GetStarted() {
  return (
    <Grid
      container
      flexDirection="row-reverse"
      alignItems="center"
      justifyContent="space-evenly"
      className="section-wrapper-inner"
      style={{ padding: 0 }}
    >
      <Grid item xs={12} md={5} className="responsive-text-center grid-item">
        <Typography variant="h4" className="txt-bold">
          Take Control Of Your Loved One's Care
        </Typography>
        <Typography color="textSecondary" className="description-txt">
          Get started by completing a free care inquiry today, and learn why
          families trust Poyo to deliver the best care in their homes.
        </Typography>
        <SignupFamilyDialog
          btnText="Get Started"
          btnProps={{
            color: "primary",
            size: "large",
            fontWeight: 550,
            fullWidth: false,
            style: {
              borderRadius: 25,
              textTransform: "none",
              minWidth: 200,
              marginTop: 24,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <img
          src={FamilyImg}
          alt="family-1"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 25,
          }}
          className="blob pop-out-img"
          draggable="false"
        />
      </Grid>
    </Grid>
  );
}

export default GetStarted;
