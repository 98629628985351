import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux imports
import { connect } from "react-redux";
import {
  setPerferredPaymentMethod,
  deletePaymentMethod,
} from "../../../../../redux/actions/userActions";
// Component imports
import BrandSelect from "../../../../../components/inputs/Stripe/BrandSelect";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  ...theme.spreadThis,
  reactiveBox: {
    width: 700,
    [theme.breakpoints.down("lg")]: {
      width: 600,
    },
    [theme.breakpoints.down("md")]: {
      width: 500,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    marginBottom: 8,
  },
  reactiveFlex: {
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },

  boxText: {
    padding: "2px 8px 2px 8px",
    border: `1px solid ${theme.palette.text.primary}`,
    fontWeight: 550,
  },
});

function CSSBox(props) {
  return <Box display="flex" alignItems="center" {...props} />;
}

export class CardMarkup extends Component {
  handleDelete = async () => {
    this.props.deletePaymentMethod(this.props.card.docId);
  };

  handlePreferred = async () => {
    this.props.setPerferredPaymentMethod(this.props.card.id);
  };

  render() {
    const {
      classes,
      loading,
      card: {
        card: { brand, last4 },
      },
      isPreferred,
      deletable,
    } = this.props;

    return (
      <Box className={classes.reactiveBox}>
        <Box display="flex" alignItems="center">
          <CSSBox className={classes.reactiveFlex}>
            <BrandSelect brand={brand} />

            <Typography variant="body2" style={{ fontWeight: 550 }}>
              **** **** **** {last4}
            </Typography>
          </CSSBox>
          {isPreferred ? (
            <Box
              display={{ xs: "flex", sm: "flex" }}
              justifyContent="flex-end"
              style={{ flex: 1 }}
            >
              <Typography
                component="div"
                variant="caption"
                align="center"
                className={classes.boxText}
              >
                PERFERRED
              </Typography>
            </Box>
          ) : (
            <Box
              display={{ xs: "flex", sm: "flex" }}
              justifyContent="flex-end"
              style={{ flex: 1 }}
            >
              <Button
                color="inherit"
                disabled={loading}
                onClick={this.handlePreferred}
              >
                Make Perferred
              </Button>
            </Box>
          )}

          {deletable && (
            <Box
              display={{ xs: "none", sm: "flex" }}
              justifyContent="flex-end"
              style={{ flex: 1 }}
            >
              <Button
                color="secondary"
                disabled={loading}
                onClick={this.handleDelete}
              >
                Delete
              </Button>
            </Box>
          )}
        </Box>
        {!deletable && (
          <Typography component="div" variant="caption" color="textSecondary">
            To delete, add another payment method first.
          </Typography>
        )}
        {deletable && (
          <Box
            display={{ xs: "flex", sm: "none" }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              color="secondary"
              disabled={loading}
              onClick={this.handleDelete}
            >
              Delete
            </Button>
          </Box>
        )}
      </Box>
    );
  }
}

CardMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  isPreferred: PropTypes.bool,
  deletable: PropTypes.bool,
  setPerferredPaymentMethod: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.UI.loading,
});

export default connect(mapStateToProps, {
  setPerferredPaymentMethod,
  deletePaymentMethod,
})(withStyles(styles)(CardMarkup));
