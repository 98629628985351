import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter
import clsx from "clsx";
import { Link } from "react-router-dom";

// Component imports
import {
  sameKeysInObj,
  sameEleInArray,
  capitalizeFirstChar,
  splitByCapital,
  getDistanceGeohash,
} from "../../util/utilFunctions";
import FavouriteIconButton from "../buttons/favouriteIconButton/FavouriteIconButton";

// Redux imports
import { connect } from "react-redux";
import { setFav, setUnfav } from "../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  chip: {
    margin: 3,
  },
  jobButton: {
    color: `${theme.palette.primary.main}`,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      color: "white",
    },
    height: "100%",
  },
  jobCard: {
    padding: 16,
    minHeight: 368,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 15px 0px",

    transition: "0.3s",
    maxWidth: 325,
    height: "100%",
    width: "100%",
    textAlign: "left",
  },
});

export class JobsCard extends Component {
  render() {
    const {
      classes,
      userData,
      jobData,
      jobData: { familyInfo },
    } = this.props;

    const servicesSame = sameKeysInObj(jobData.service, userData.service);
    const languageSame = sameEleInArray(
      jobData.languagePref,
      userData.languagePref
    );

    const distanceFromJob = getDistanceGeohash(
      userData.address.geohash,
      jobData.address.geohash
    );

    const serviceChip = Object.keys(jobData.service)
      .slice(0, 2)
      .sort()
      .map((key) => (
        <Chip
          label={capitalizeFirstChar(splitByCapital(key))}
          className={classes.chip}
          size="small"
          key={key}
          variant="outlined"
          color="secondary"
        />
      ));

    return (
      <Box
        className={clsx(classes.jobCard, classes.flexBoxSpacedColumn)}
        style={{}}
      >
        <Box className={classes.flexBoxSpaced} style={{ width: "100%" }}>
          <Typography
            component={Link}
            to={`/job/${jobData.jobId}`}
            color="textPrimary"
          >
            {capitalizeFirstChar(familyInfo.firstName)}
          </Typography>
          <Box className={classes.flexBox}>
            {jobData.hires.some(
              (hire) => hire.userIdNumber === userData.userIdNumber
            ) ? (
              <Chip
                label="Hired"
                size="small"
                className={classes.chipGreenOutlined}
              />
            ) : jobData.apps.some(
                (app) => app.userIdNumber === userData.userIdNumber
              ) ? (
              <Chip
                label="Applied"
                size="small"
                className={classes.chipBlueOutlined}
              />
            ) : (
              jobData.cgViews.includes(userData.userIdNumber) && (
                <Chip
                  label="Viewed"
                  size="small"
                  className={classes.chipOrjOutlined}
                />
              )
            )}
            <FavouriteIconButton
              id={Boolean(jobData.jobId) ? jobData.jobId : jobData.id}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          style={{
            height: 128,
            width: "100%",
          }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 300 }}
          >
            is looking for help with:
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-end",
              width: "100%",
              marginBottom: 5,
            }}
          >
            {serviceChip}
            {Object.keys(jobData.service).length > 2 && (
              <Typography
                noWrap
                color="textSecondary"
                variant="body2"
                style={{ fontWeight: 300 }}
              >
                ...etc
              </Typography>
            )}
          </Box>

          <Typography
            component="div"
            variant="body2"
            className="threeLineEllipsis"
            color="textSecondary"
            style={{ whiteSpace: "pre-line", fontWeight: 300, marginTop: 8 }}
          >
            {jobData.description}
          </Typography>
        </Box>
        <div style={{ width: "100%" }}>
          <div className={classes.selectedGrayContainer}>
            <div className={classes.flexBox}>
              <CheckIcon color="primary" />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: 8 }}
              >
                {servicesSame.same === servicesSame.total
                  ? "You match 100% of services required."
                  : `You meet ${servicesSame.same} of ${servicesSame.total} services required.`}
              </Typography>
            </div>
            <div className={classes.flexBox}>
              <CheckIcon color="primary" />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: 8 }}
              >
                {languageSame.same === languageSame.total
                  ? "You meet all language requirements."
                  : `You speak ${languageSame.same} of the same languages.`}
              </Typography>
            </div>
            <div className={classes.flexBox}>
              <CheckIcon color="primary" />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: 8 }}
              >
                Approximately {(Math.round(distanceFromJob * 2) / 2).toFixed(1)}{" "}
                kms away.
              </Typography>
            </div>
          </div>
          <Divider style={{ marginTop: 8, marginBottom: 4 }} />
          <div className={classes.flexBoxSpaced}>
            <Typography component="div" variant="caption">
              {dayjs(jobData.createdAt).fromNow()}
              {<br />}
              {capitalizeFirstChar(
                jobData.address.addressParams.locality
              )}, {jobData.address.addressParams.adminAreaLevel1.toUpperCase()}
            </Typography>

            <Button
              variant="text"
              size="small"
              className={classes.jobButton}
              component={Link}
              to={`/job/${jobData.jobId}`}
            >
              View More
            </Button>
          </div>
        </div>
      </Box>
    );
  }
}

JobsCard.propTypes = {
  jobData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  setFav: PropTypes.func.isRequired,
  setUnfav: PropTypes.func.isRequired,
};

export default connect(null, { setFav, setUnfav })(
  withStyles(styles)(JobsCard)
);
