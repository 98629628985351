import React, { Component } from "react";
import { Link } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class ReviewError extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ minHeight: 400, padding: 48 }} className={classes.form}>
        <SentimentVeryDissatisfiedIcon
          style={{ fontSize: "4rem", opacity: 0.7 }}
          color="error"
        />
        <Typography
          color="error"
          variant="h6"
          style={{ marginBottom: 48, fontWeight: 300 }}
        >
          Uh oh! You seem to have wondered into an unauthorized path. Click the
          button below to find all reviews!
        </Typography>
        <Button
          component={Link}
          to="/reviews"
          color="error"
          variant="outlined"
          className={classes.txtTrButton}
          style={{ minWidth: 200 }}
        >
          BACK TO REVIEWS
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(ReviewError);
