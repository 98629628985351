import React, { Component } from "react";
import PropTypes from "prop-types";

// import axios from "axios";
import LoadingEllipsis from "../../../../../components/layout/LoadingEllipsis";
import firebase from "../../../../../Firebase";
import "../../../../../App.css";
import AddPaymentMethodDialogButton from "../../../payInvoice/components/AddPaymentMethodDialogButton";
import CardMarkup from "./CardMarkup";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Icons Imports
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
  snackbar: {
    marginLeft: 86,
    marginTop: 56,
    [theme.breakpoints.down("xl")]: {
      marginLeft: 75,
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: 86,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 44,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
});

function VerticalBox(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ minHeight: 450 }}
      {...props}
    />
  );
}
export class Payment extends Component {
  state = {
    cards: [],
    isLoadedCards: false,
    paymentMethod: "",
  };

  componentDidMount() {
    this.getPaymentMethods();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  // Initialize Methods

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  getPaymentMethods() {
    const uid = this.props.credentials.uid;
    let db = firebase.firestore();

    const that = this;

    this.unsubscribe = db
      .doc(`/users/${uid}`)
      .collection("payment_methods")
      .onSnapshot((snapshot) => {
        let cards = [];
        snapshot.forEach((doc) => {
          const paymentMethod = doc.data();
          paymentMethod.docId = doc.id;
          cards.push(paymentMethod);
        });

        let newState = {
          cards,
          isLoadedCards: true,
        };

        that.setState(newState);
      });
  }

  // Alert Methods

  handlePayment = () => {
    return null;
  };

  render() {
    const { credentials } = this.props;
    const { cards, isLoadedCards } = this.state;

    return (
      <Paper
        elevation={4}
        className="hideScroll"
        style={{
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          minHeight: 450,
          width: "100%",
          borderRight: "1px solid rgb(212,212,212)",
          borderLeft: "1px solid rgb(212,212,212)",
          borderBottom: "1px solid rgb(212,212,212)",
          overflow: "auto",
        }}
      >
        <VerticalBox p={2}>
          <LockOutlinedIcon color="primary" fontSize="large" />
          <Typography variant="h6" color="primary" style={{ marginTop: 16 }}>
            Manage payment info
          </Typography>
          <Typography style={{ fontWeight: 300 }}>
            Add, edit, or switch you preferred payment methods.
          </Typography>
          <Divider style={{ width: "100%", margin: "16px 0px" }} />
          <VerticalBox style={{ flex: 1, width: "100%" }}>
            {
              !isLoadedCards ? (
                <VerticalBox style={{ minHeight: 0 }}>
                  <CircularProgress
                    thickness={2}
                    size={75}
                    style={{ opacity: 0.5 }}
                  />
                  <LoadingEllipsis
                    component="div"
                    variant="caption"
                    color="textSecondary"
                    style={{ marginTop: 10 }}
                    prefix="Loading PaymentMethods"
                  />
                </VerticalBox>
              ) : cards < 1 ? (
                <Typography color="textSecondary" style={{ fontWeight: 300 }}>
                  No payment methods found
                </Typography>
              ) : (
                cards.map((card) => {
                  if (card.card && card.card.checks.cvc_check === "pass") {
                    return (
                      <CardMarkup
                        card={card}
                        key={card.id}
                        isPreferred={
                          credentials.primaryPaymentMethod === card.id
                        }
                        deletable={cards.length > 1}
                      />
                    );
                  }
                  return null;
                })
              )
              // <InvoiceContent invoices={invoices} />
            }
            <br />
            {isLoadedCards && (
              <AddPaymentMethodDialogButton
                handlePayment={this.handlePayment}
                myInfo={credentials}
                variant="outlined"
                color="secondary"
                style={{ borderRadius: 7, width: 290 }}
              />
            )}
          </VerticalBox>
        </VerticalBox>
      </Paper>
    );
  }
}

Payment.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default withStyles(styles)(Payment);
