import React, { Component } from "react";
import PropTypes from "prop-types";
import { capitalizeFirstChar, splitByCapital } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ObjSelectButton extends Component {
  state = { optionsAll: {}, optionsCntr: {} };

  componentDidMount() {
    const { optionsAll, optionsCntr } = this.props;

    this.setState({
      optionsAll: JSON.parse(JSON.stringify(optionsAll)),
      optionsCntr: JSON.parse(JSON.stringify(optionsCntr)),
    });
  }

  handleObjectChange = (e) => {
    const obj = this.state.optionsCntr;
    const value = e.currentTarget.value;
    if (obj.hasOwnProperty(value)) {
      delete obj[value];
      this.setState({ optionsCntr: obj });
      if (this.props.onHandleSelect) this.props.onHandleSelect(obj);
    } else {
      obj[value] = true;
      this.setState({ optionsCntr: obj });
      if (this.props.onHandleSelect) this.props.onHandleSelect(obj);
    }
  };

  onSave = () => {
    this.props.onSaveButtonClick(this.state.optionsCntr);
  };

  render() {
    const { optionsAll, optionsCntr } = this.state;
    const {
      error,
      startIcon,
      loading,
      classes,
      showSave,
      unselStartIcon,
      btnColor,
    } = this.props;

    return (
      <div>
        {Object.keys(optionsAll)
          .sort()
          .map((key) => (
            <Button
              variant={
                optionsCntr.hasOwnProperty(key) ? "contained" : "outlined"
              }
              size="small"
              className={classes.editItemButton}
              startIcon={
                (!unselStartIcon && startIcon) ||
                (startIcon && optionsCntr.hasOwnProperty(key))
                  ? startIcon
                  : unselStartIcon && unselStartIcon
              }
              value={key}
              onClick={this.handleObjectChange}
              key={key}
              style={
                error
                  ? optionsCntr.hasOwnProperty(key)
                    ? {
                        borderColor: "red",
                        backgroundColor: "rgba(245, 221,219, 1)",
                        color: "red",
                      }
                    : { borderColor: "red", color: "red" }
                  : {}
              }
            >
              {capitalizeFirstChar(splitByCapital(key))}
            </Button>
          ))}
        {showSave && (
          <div className={classes.flexBoxEnd}>
            <Button
              onClick={this.onSave}
              color={btnColor ? btnColor : "inherit"}
              disabled={loading}
            >
              Save
              {loading && (
                <CircularProgress
                  thickness={2}
                  size={30}
                  className={classes.progress}
                />
              )}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

ObjSelectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  showSave: PropTypes.bool,
  optionsAll: PropTypes.object.isRequired,
  optionsCntr: PropTypes.object.isRequired,
  onSaveButtonClick: PropTypes.func,
  error: PropTypes.string,
  color: PropTypes.string,
};

export default withStyles(styles)(ObjSelectButton);
