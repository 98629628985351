import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter
import { isMobile } from "react-device-detect";
import axios from "axios";

// Redux imports
import { connect } from "react-redux";

// Component imports
import {
  capitalizeFirstChar,
  numberWithCommas,
  getDistanceGeohash,
} from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EventIcon from "@mui/icons-material/Event";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0,
    },
  },
  focusHighlight: {},
  unreadMarker: {
    width: 8,
    height: 8,
    backgroundColor: `${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
});

export class InviteCompact extends Component {
  state = { showMore: false };

  handleShowMore = () => {
    this.handleMarkRead();
    this.setState({ showMore: !this.state.showMore });
  };

  handleMarkRead = async () => {
    const {
      teamInvite,
      credentials: { userType },
    } = this.props;
    if (userType === "caregiver" && !teamInvite.isRead) {
      await axios.get(`/team/invite/${teamInvite.id}/mark-read`);
    }
    return;
  };

  render() {
    const {
      classes,
      credentials,
      teamInvite,
      teamInvite: { familyInfo, caregiverInfo },
    } = this.props;

    const { showMore } = this.state;

    const isFamily = credentials.userType === "family";

    return (
      <CardActionArea
        onClick={this.handleShowMore}
        classes={
          isMobile
            ? {
                root: classes.actionArea,
                focusHighlight: classes.focusHighlight,
              }
            : {}
        }
        style={{
          margin: "8px 0px",
          border: "1px solid rgba(212,212,212,0.7)",
          borderRadius: 10,
        }}
      >
        <Box
          p={1}
          display="flex"
          flexDirection="column"
          style={{ width: "100%" }}
        >
          <Box className={classes.flexBoxSpaced}>
            <Box
              display="flex"
              alignItems="center"
              style={{ width: "calc(100% - 80px)" }}
            >
              <Avatar
                src={
                  isFamily ? caregiverInfo.imgUrlThumb : familyInfo.imgUrlThumb
                }
              />
              <Typography
                noWrap
                style={{ marginLeft: 8, width: "calc(100% - 48px)" }}
              >
                {capitalizeFirstChar(
                  isFamily ? caregiverInfo.firstName : familyInfo.firstName
                )}{" "}
                {capitalizeFirstChar(
                  isFamily ? caregiverInfo.lastName : familyInfo.lastName
                )}
              </Typography>
            </Box>
            {!teamInvite.isRead ? (
              <Chip size="small" label="Unread" className={classes.chipOrj} />
            ) : teamInvite.isAccepted ? (
              <Chip
                size="small"
                label="Accepted"
                className={classes.chipGreen}
              />
            ) : teamInvite.isDeclined ? (
              <Chip size="small" label="Declined" className={classes.chipOrj} />
            ) : (
              teamInvite.isRead && (
                <Chip size="small" label="Read" className={classes.chipBlue} />
              )
            )}
          </Box>
          <Collapse in={showMore} collapsedSize={68}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontWeight: 300,
                margin: "4px 0px",
              }}
            >
              Job ID: {teamInvite.jobId}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap={!showMore}
              style={{
                fontWeight: 300,
                whiteSpace: showMore && "pre-line",
              }}
            >
              {teamInvite.message}
            </Typography>
            <Fade in={showMore} unmountOnExit mountOnEnter>
              <div>
                <Grid
                  container
                  justifyContent="space-around"
                  alignItems="center"
                  className={classes.form}
                  style={{
                    margin: "8px 0px",
                    padding: "4px 0px",

                    backgroundColor: "rgba(212,212,212,0.3)",
                  }}
                >
                  <Grid item>
                    <Box display="flex">
                      <MonetizationOnIcon color="primary" />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ marginLeft: 4 }}
                      >
                        Wage
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="secondary">
                      ${numberWithCommas(teamInvite.propWage)}/hr
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box display="flex">
                      <EventIcon color="primary" />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ marginLeft: 4 }}
                      >
                        Start Date
                      </Typography>
                    </Box>

                    <Typography variant="body2" color="secondary">
                      {dayjs(teamInvite.startDate).format("MMM DD, YYYY")}
                    </Typography>
                  </Grid>
                  {!isFamily && (
                    <Grid item>
                      <Box display="flex">
                        <DriveEtaIcon color="primary" />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ marginLeft: 4 }}
                        >
                          Distance
                        </Typography>
                      </Box>

                      <Typography variant="body2" color="secondary">
                        ~
                        {numberWithCommas(
                          getDistanceGeohash(
                            credentials.address.geohash,
                            teamInvite.address.geohash
                          )
                        )}
                        km
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Button
                  component={Link}
                  to={`/invites/${teamInvite.id}`}
                  color="primary"
                  variant="outlined"
                  fullWidth
                  style={{ margin: "8px 0px", borderRadius: 10 }}
                >
                  View Details {"&"} Action
                </Button>
              </div>
            </Fade>
            <Typography
              variant="caption"
              component="div"
              align="right"
              color="textSecondary"
            >
              Sent: {dayjs(teamInvite.createdAt).fromNow()}
            </Typography>
          </Collapse>
        </Box>
      </CardActionArea>
    );
  }
}

InviteCompact.propTypes = {
  credentials: PropTypes.object.isRequired,
  teamInvite: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  markRead: PropTypes.func,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps)(withStyles(styles)(InviteCompact));
