import React from "react";
import dayjs from "dayjs";
import "./footer.css";

// Redux Imports
import { connect } from "react-redux";

// Component imports
import ContactGrid from "./components/ContactGrid";
import PolicyLinks from "./components/PolicyLinks";
import MainLinks from "./components/MainLinks";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function FooterMain() {
  // TODO: Add authorized footer methods

  return (
    <footer>
      <Grid
        container
        spacing={4}
        alignItems="center"
        className="footer-container"
      >
        <Grid item xs={12} sm={4}>
          <ContactGrid />
        </Grid>
        <Grid container item xs={12} sm={8}>
          <MainLinks />
        </Grid>
        <Grid item xs={12}>
          <PolicyLinks />
        </Grid>
        <Grid item xs={12}>
          <Typography
            color="textSecondary"
            align="right"
            style={{ fontSize: "0.75rem", fontWeight: 300 }}
          >
            © {dayjs(new Date()).format("YYYY")} Poyo Health. All rights
            reserved
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(FooterMain);
