import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import SendIcon from "@mui/icons-material/Send";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class EmptyChat extends Component {
  render() {
    const { classes, loading, userType } = this.props;
    return (
      <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: 50, borderBottom: "1px solid rgb(245,245,245,1)" }}
        >
          {loading && <CircularProgress size={30} thickness={2} />}
        </Box>
        <Box className={classes.flexBoxCenteredColumn} style={{ flex: 1 }}>
          {loading ? (
            <CircularProgress size={30} thickness={2} />
          ) : (
            <Fragment>
              <ChatBubbleOutlineOutlinedIcon
                style={{ opacity: 0.7, fontSize: 72 }}
                color="disabled"
              />
              <Typography color="textSecondary">
                You are not connected with any{" "}
                {userType === "family" ? "caregivers" : "families"} right now.
              </Typography>

              <Button
                variant="outlined"
                component={Link}
                color="secondary"
                to={`/${userType === "family" ? "caregivers" : "jobs"}`}
                className={classes.txtTrButton}
              >
                Start Your Search
              </Button>
            </Fragment>
          )}
        </Box>
        <Box
          style={{
            width: "100%",
            height: 50,
            display: "flex",
            justifyContent: "center",
            padding: 5,
          }}
        >
          <TextField
            variant="outlined"
            name="msg"
            size="small"
            className={classes.styledTextField}
            style={{ flex: 1, margin: 0 }}
            disabled
            placeholder="Aa"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled
            endIcon={<SendIcon />}
            style={{
              width: 100,
              height: 40,
              marginLeft: 5,
            }}
          >
            Send
          </Button>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(EmptyChat);
