import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";

// Component imports
import firebase from "../../../../Firebase";
import SingleReport from "./components/SingleReport";
import AllReports from "./components/AllReports";

// Redux imports
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  ...theme.spreadThis,
  containerDiv: {
    marginLeft: 0,
    marginTop: 10,
    // [theme.breakpoints.down("lg")]: {
    //   marginLeft: 150,
    // },
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: 175,
    // },
    [theme.breakpoints.up("sm")]: {
      // marginLeft: 85,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
});

export class reports extends Component {
  state = {
    report: {},
    loadingReport: true,
    isLoadedReport: false,
    errors: {},
    reports: [],
    isLoadedReports: false,
    isLoadingMore: false,
    isLoadAgain: true,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    const reportId = this.props.match.params.reportId;
    this.getAllReports();
    if (reportId) this.getReport(reportId);
  }

  componentDidUpdate(prevProps) {
    const reportId = this.props.match.params.reportId;
    if (Boolean(reportId) && !prevProps.match.params.reportId) {
      this.setState({ errors: {} });

      this.getReport(reportId);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
    this.setState = (state, callback) => {
      return;
    };
  }

  getReport = async (reportId) => {
    await this.setStateAsync({ loadingReport: true, isLoadedReport: false });
    try {
      const reportDoc = await axios.get(`/report/${reportId}/get`);

      this.setState({ report: reportDoc.data });
    } catch (err) {
      this.setState({ errors: err.response.data });
    } finally {
      this.setState({ loadingReport: false, isLoadedReport: true });
    }
  };

  getAllReports = async () => {
    const { userIdNumber, userType } = this.props.credentials;
    const db = firebase.firestore();

    let reportsQueryRef = db.collection("reports");
    switch (userType) {
      case "family":
        reportsQueryRef = reportsQueryRef
          .where("familyInfo.userIdNumber", "==", userIdNumber)
          .limit(15);
        break;
      case "caregiver":
        reportsQueryRef = reportsQueryRef
          .where("caregiverInfo.userIdNumber", "==", userIdNumber)
          .limit(15);
        break;
      case "admin":
        reportsQueryRef = reportsQueryRef
          .where("submitted", ">", dayjs().subtract(7, "d").toISOString())
          .limit(30);
        break;
      default:
        break;
    }

    const that = this;

    this.unsubscribe = reportsQueryRef
      .orderBy("submitted", "desc")
      .onSnapshot(async (snapshot) => {
        const reports = snapshot.docs.map((doc) => {
          let res = doc.data();
          res.id = doc.id;
          return res;
        });

        // Sets lastDoc for pagniation
        let lastDoc;
        if (!this.state.isLoadedReports) {
          lastDoc = snapshot.docs[snapshot.docs.length - 1];
        } else {
          lastDoc = this.state.lastDoc;
        }

        /*  Finds first new message via snapshot. 
            Modifies state messages array to remove all existing 
            copies of snapshot messages. Returns new array with 
            unique messages only. */

        let lastReportIndex = -1;
        let _reports = this.state.reports;
        if (reports.length > 0)
          lastReportIndex = this.state.reports.findIndex(
            (report) => report.id === reports[reports.length - 1].id
          );
        if (lastReportIndex > -1)
          _reports = _reports.splice(lastReportIndex + 1, _reports.length);

        _reports = [...reports, ..._reports];

        that.setState({
          reports: _reports,
          isLoadedReports: true,
          lastDoc,
          isLoadAgain: reports.length >= 15 || userType === "admin",
        });
      });
  };

  // Action Methods
  handleActionSuccess = (actionUpdate) => {
    this.setState({ report: { ...this.state.report, ...actionUpdate } });
  };

  handleLoadMoreReports = async () => {
    await this.setStateAsync({ isLoadingMore: true });

    const { userIdNumber, userType } = this.props.credentials;
    const db = firebase.firestore();

    let reportsQueryRef = db.collection("reports");
    switch (userType) {
      case "family":
        reportsQueryRef = reportsQueryRef.where(
          "familyInfo.userIdNumber",
          "==",
          userIdNumber
        );
        break;
      case "caregiver":
        reportsQueryRef = reportsQueryRef.where(
          "caregiverInfo.userIdNumber",
          "==",
          userIdNumber
        );
        break;
      case "admin":
        reportsQueryRef = reportsQueryRef.where(
          "submitted",
          ">",
          dayjs().subtract(7, "d").toISOString()
        );
        break;
      default:
        break;
    }

    try {
      const reportsQuery = await reportsQueryRef
        .orderBy("submitted", "desc")
        .startAfter(this.state.lastDoc)
        .limit(3)
        .get();

      const _reports = [];
      reportsQuery.forEach((doc) => {
        let res = doc.data();
        res.id = doc.id;
        _reports.push(res);
      });

      const lastDoc = reportsQuery.docs[reportsQuery.docs.length - 1];
      const isLoadAgain = Boolean(lastDoc);
      const reports = [...this.state.reports, ..._reports];

      this.setState({ reports, isLoadedReports: true, lastDoc, isLoadAgain });
    } catch (err) {
      console.error(err);
    } finally {
      await this.setStateAsync({ isLoadingMore: false });
    }
  };

  render() {
    const {
      classes,
      credentials: { userType },
    } = this.props;
    const reportId = this.props.match.params.reportId;

    return (
      <div className={classes.containerDiv}>
        <Grid container justifyContent="center" style={{ padding: 4 }}>
          <Grid
            item
            component={Paper}
            elevation={4}
            xs={12}
            sm={10}
            md={8}
            lg={6}
            className={classes.darkGrayBorder}
          >
            {Boolean(reportId) ? (
              <Fade in>
                <div>
                  <SingleReport
                    userType={userType}
                    onActionSuccess={this.handleActionSuccess}
                    {...this.state}
                  />
                </div>
              </Fade>
            ) : (
              <Fade in>
                <div>
                  <AllReports
                    userType={userType}
                    {...this.state}
                    onLoadMore={this.handleLoadMoreReports}
                  />
                </div>
              </Fade>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

reports.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps)(withStyles(styles)(reports));
