import {
  LOADING_UI,
  STOP_LOADING_UI,
  LOADING_UI_SPECIAL,
  CLEAR_ERRORS,
  UNSET_QUERY_LOADED,
  SET_QUERY_RESULTS,
  SET_FILTERED_RESULTS,
  SET_JOB,
  UPDATE_SINGLE_QUERY,
  SET_SHIFTS,
  SET_ERRORS,
  SET_TEAMS,
  SET_DASH_DATA,
  UPDATE_DASH_DATA,
  UPDATE_DASH_ARRAY,
  SPLICE_DASH_ARRAY,
  UPDATE_DASH_ARRAY_BY_ID,
} from "../types";
import axios from "axios";

// Clear errors
export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// Query Jobs
export const queryJobs = (queryData) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .post("/jobs/search", queryData)
    .then((res) => {
      dispatch({ type: UNSET_QUERY_LOADED });
      const sortedRes = res.data.sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
      dispatch({
        type: SET_QUERY_RESULTS,
        payload: { queryResults: sortedRes, isInitial: queryData.isInitial },
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get one job
export const getOneJob = (jobId) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .get(`/job/${jobId}`)
    .then((res) => {
      dispatch({
        type: SET_JOB,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => console.error(err));
};

// Update Job Status
export const updateJobStatus = (jobId, update) => (dispatch) => {
  dispatch({
    type: UPDATE_SINGLE_QUERY,
    payload: { id: jobId, update },
  });
};

// Get all shifts
export const getAllShifts = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .get("/shift")
    .then((res) => {
      dispatch({
        type: SET_SHIFTS,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Query Caregivers
export const queryCg = (queryData) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .post("/user/filter", queryData)
    .then((res) => {
      dispatch({ type: UNSET_QUERY_LOADED });
      dispatch({
        type: SET_QUERY_RESULTS,
        payload: { queryResults: res.data, isInitial: queryData.isInitial },
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getTeams = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .get("/team")
    .then((res) => {
      dispatch({
        type: SET_TEAMS,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setFilteredResults = (filteredResults) => (dispatch) => {
  dispatch({ type: LOADING_UI_SPECIAL });
  dispatch({ type: SET_FILTERED_RESULTS, payload: filteredResults });
  dispatch({ type: STOP_LOADING_UI });
};

export const setData = (dashData) => (dispatch) => {
  dispatch({ type: SET_DASH_DATA, payload: dashData });
};

export const updateData = (data, key) => (dispatch) => {
  dispatch({ type: UPDATE_DASH_DATA, payload: { ...data, key } });
};

export const updateDataArray = (data, key, index) => (dispatch) => {
  dispatch({ type: UPDATE_DASH_ARRAY, payload: { ...data, key, index } });
};

export const updateDataArrayId = (data, key, id) => (dispatch) => {
  dispatch({ type: UPDATE_DASH_ARRAY_BY_ID, payload: { ...data, key, id } });
};

export const spliceDataArray = (id, key) => (dispatch) => {
  dispatch({ type: SPLICE_DASH_ARRAY, payload: { id, key } });
};
