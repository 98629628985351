import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

// Component Imports
import firebase from "../../../../Firebase";
import LoadingEllipsis from "../../../../components/layout/LoadingEllipsis";
import AddPaymentMethodDialogButton from "./AddPaymentMethodDialogButton";
import OutlinedProfilePic from "../../../../components/layout/OutlinedProfilePic";
import {
  capitalizeFirstChar,
  numberWithCommas,
  isEmail,
  allFalse,
  formatAmount,
  isEmpty,
} from "../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Hidden from "@mui/material/Hidden";
import FormControl from "@mui/material/FormControl";

// Icons Import
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

const styles = (theme) => ({
  ...theme.spreadThis,
  containerBorder: {
    padding: "24px 16px",
  },
  jobAppMediaA: {
    height: 78,
    width: 78,
    objectFit: "cover",
    borderRadius: "50%",
    margin: "auto",
    border: "2px solid white",
  },
  mediaBorder: {
    height: 82,
    width: 82,
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
  },
});

export class PaymentPortal extends Component {
  state = {
    cards: [],
    isLoadedCards: false,
    paymentMethod: "",
    email: "",
    paymentId: "",
    payment: {},
    isLoadedPayment: false,
    errors: {
      errorEmailEmpty: false,
      errorNotEmail: false,
      errorPaymentMethodEmpty: false,
      errorInvoiceNotApproved: false,
    },
    disablePaymentButton: false,
  };

  componentDidMount() {
    this.getPaymentMethods();
    this.setInitialEmail();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    if (this.unsubscribePayment) {
      this.unsubscribePayment();
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  setInitialEmail() {
    const email = this.props.credentials.email;
    const newEmail = (" " + email).slice(1);
    this.setState({ email: newEmail });
  }

  getPaymentMethods() {
    const uid = this.props.credentials.uid;
    let db = firebase.firestore();

    const that = this;

    this.unsubscribe = db
      .doc(`/users/${uid}`)
      .collection("payment_methods")
      .onSnapshot((snapshot) => {
        let cards = [];
        snapshot.forEach((doc) => {
          const paymentMethod = doc.data();
          cards.push(paymentMethod);
        });
        let firstCard = { id: "" };
        if (cards.length > 0) {
          firstCard = cards[0];
        }
        let newState = {
          cards,
          isLoadedCards: true,
          paymentMethod: firstCard.id,
        };

        if (cards.length > 0 && firstCard.billing_details.email !== null)
          newState.email = firstCard.billing_details.email;
        that.setState(newState);
      });
  }

  getPayment(paymentId) {
    const uid = this.props.credentials.uid;
    const db = firebase.firestore();

    const that = this;

    this.unsubscribePayment = db
      .doc(`/users/${uid}/payments/${paymentId}`)
      .onSnapshot((paymentDoc) => {
        const payment = { ...paymentDoc.data(), id: paymentDoc.id };

        let content = "";
        let disablePaymentButton = that.state.disablePaymentButton;
        if (
          payment.status === "new" ||
          payment.status === "requires_confirmation"
        ) {
          if (Boolean(payment.error) && !isEmpty(payment.error)) {
            content = payment.error;
            disablePaymentButton = false;
          } else {
            content = `Creating Payment for ${formatAmount(
              payment.amount,
              payment.currency
            )}.`;
          }
        } else if (payment.status === "succeeded") {
          content = `Payment of ${formatAmount(
            payment.amount,
            payment.currency
          )} successful.`;
        } else if (payment.status === "requires_action") {
          content = `Payment for ${formatAmount(
            payment.amount,
            payment.currency
          )} ${payment.status}`;
          // handleCardAction(payment, doc.id);
        } else {
          content = `Payment for ${formatAmount(
            payment.amount,
            payment.currency
          )} ${payment.status}`;
        }
        payment.content = content;

        that.setState({
          payment,
          disablePaymentButton,
          isLoadedPayment: true,
        });
      });
  }

  handleSelectPaymentMethod = (e) => {
    const { cards } = this.state;
    let paymentMethodId = e.target.value;
    let pos = cards.findIndex((card) => card.id === paymentMethodId);
    let newEmail = cards[pos].billing_details.email;
    if (newEmail !== null) {
      this.setState({
        paymentMethod: paymentMethodId,
        email: newEmail,
        errors: { ...this.state.errors, errorPaymentMethodEmpty: false },
      });
    } else {
      this.setState({
        paymentMethod: paymentMethodId,
        email: "",
        errors: { ...this.state.errors, errorPaymentMethodEmpty: false },
      });
    }
  };

  handleSetEmail = (e) => {
    if (isEmpty(e.target.value)) {
      this.setState({
        errors: { ...this.state.errors, errorEmailEmpty: true },
        email: e.target.value,
      });
    } else {
      this.setState({
        email: e.target.value,
        errors: {
          ...this.state.errors,
          errorEmailEmpty: false,
          errorNotEmail: false,
        },
      });
    }
  };

  handleSubmitPaymentIntent = async (e) => {
    const { email, paymentMethod } = this.state;
    const {
      status: { isApproved },
      id,
      invoiceBreakdown: {
        payableBreakdown: { totalPayable },
      },
    } = this.props.invoiceData;

    e.preventDefault();

    await this.setStateAsync({
      errors: {
        errorEmailEmpty: false,
        errorNotEmail: false,
        errorPaymentMethodEmpty: false,
        errorInvoiceNotApproved: false,
      },
      disablePaymentButton: true,
    });

    const errors = {};
    if (isEmpty(email)) errors.errorEmailEmpty = true;
    if (!isEmail(email)) errors.errorNotEmail = true;
    if (isEmpty(paymentMethod)) errors.errorPaymentMethodEmpty = true;
    if (!isApproved) errors.errorInvoiceNotApproved = true;
    if (Object.keys(errors).length > 0) {
      this.setState({ errors, disablePaymentButton: false });
    } else {
      const paymentData = {
        amount: Math.round(totalPayable * 100),
        currency: "cad",
        payment_method: paymentMethod,
        description: id,
        receipt_email: email,
        status: "new",
      };
      const paymentRes = await axios.post("/payment/add/payment", paymentData);

      const paymentId = paymentRes.data.payment;

      this.getPayment(paymentId);
    }
  };

  render() {
    const {
      classes,
      isLoadedInvoice,
      credentials,
      errorLoadInvoice,
      invoiceData: { invoiceBreakdown, id, caregiverInfo, paidOn, status },
    } = this.props;
    const {
      paymentMethod,
      cards,
      isLoadedCards,
      payment,
      isLoadedPayment,
      email,
      errors,
      errors: {
        errorEmailEmpty,
        errorNotEmail,
        errorPaymentMethodEmpty,
        errorInvoiceNotApproved,
      },
      disablePaymentButton,
    } = this.state;

    let whichBgColor = "rgba(227,241,253,0.9)";
    if (isLoadedPayment) {
      if (payment.status === "succeeded") {
        whichBgColor = "rgba(233,245,232,0.9)";
      } else if (payment.status === "requires_action") {
        whichBgColor = "rgba(254,241,222,0.9)";
      }
      if (Boolean(payment.error) && !isEmpty(Boolean(payment.error))) {
        whichBgColor = "rgba(245,221,219,0.9)";
      }
    }

    return (
      <Paper elevation={6} className={classes.containerBorder}>
        <div className={classes.form}>
          <Typography
            variant="h6"
            color={allFalse(errors) ? "textSecondary" : "error"}
            className={classes.selectedGrayContainer}
          >
            Payment Details
          </Typography>
          {isLoadedInvoice && !errorLoadInvoice && (
            <div>
              {errorInvoiceNotApproved && (
                <Typography
                  component="div"
                  variant="caption"
                  className={classes.chipRed}
                  style={{ padding: 4, borderRadius: 10 }}
                >
                  This invoice has yet to be approved.
                </Typography>
              )}
              <Typography
                color={errorInvoiceNotApproved ? "error" : "textSecondary"}
                style={{ fontWeight: 300, marginTop: 5 }}
              >
                Invoice #: {id}
              </Typography>
              <Typography
                variant="h5"
                color="secondary"
                style={{ fontWeight: 550 }}
              >
                $
                {numberWithCommas(
                  invoiceBreakdown.payableBreakdown.totalPayable
                )}
              </Typography>
            </div>
          )}
        </div>
        {isLoadedInvoice && !errorLoadInvoice && (
          <Box className={classes.flexBoxCentered} style={{ marginTop: 10 }}>
            <OutlinedProfilePic imgSrc={caregiverInfo.imgUrlThumb} size={76} />
            <Box style={{ marginLeft: 10 }}>
              <Typography color="textSecondary" style={{ fontWeight: 300 }}>
                Poyo Caregiver
              </Typography>
              <Typography style={{ fontWeight: 300 }}>
                {capitalizeFirstChar(caregiverInfo.firstName)}{" "}
                {capitalizeFirstChar(caregiverInfo.lastName)}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          style={{
            padding: "0px 40px",
            marginTop: 60,
            minHeight: 330,
          }}
        >
          {!isLoadedCards || !isLoadedInvoice ? (
            <Box style={{ textAlign: "center", minHeight: "inherit" }}>
              <CircularProgress thickness={2} size={50} />
              <LoadingEllipsis
                component="div"
                variant="caption"
                color="textSecondary"
                style={{ marginTop: 10 }}
                prefix={`Loading ${
                  !isLoadedCards ? "Payment" : "Invoice"
                } Details`}
              />
            </Box>
          ) : (
            !errorLoadInvoice && (
              <form onSubmit={this.handleSubmitPaymentIntent}>
                <Typography variant="body2" color="textSecondary">
                  Card:
                </Typography>
                <FormControl fullWidth size="small">
                  <Select
                    native
                    value={paymentMethod}
                    onChange={this.handleSelectPaymentMethod}
                    // input={<BootstrapInput />}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: 10 }}
                    className={
                      !Boolean(errorPaymentMethodEmpty)
                        ? classes.styledTextField
                        : classes.styledTextFieldError
                    }
                  >
                    {cards.map((card) => {
                      if (card.card && card.card.checks.cvc_check === "pass") {
                        return (
                          <option value={card.id} key={card.id}>
                            {card.card.brand} •••• {card.card.last4} | Expires{" "}
                            {card.card.exp_month}/{card.card.exp_year}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
                <AddPaymentMethodDialogButton
                  myInfo={credentials}
                  variant="outlined"
                  color="secondary"
                />
                <Typography
                  variant="body2"
                  color={
                    errorNotEmail || errorEmailEmpty ? "error" : "textSecondary"
                  }
                  style={{ marginTop: 15 }}
                >
                  Email Receipt:
                </Typography>
                <TextField
                  name="email"
                  value={email}
                  type="email"
                  variant="outlined"
                  size="small"
                  onChange={this.handleSetEmail}
                  fullWidth
                  className={
                    errorNotEmail || errorEmailEmpty
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                />

                <div style={{ height: 21 }}>
                  {(errorNotEmail || errorEmailEmpty) && (
                    <Typography variant="caption" color="error">
                      {errorEmailEmpty
                        ? "Must not be empty."
                        : "Not a valid email address."}
                    </Typography>
                  )}
                </div>
                <div style={{ margin: "16px 0px 8px 0px" }}>
                  {status.isPaid && (
                    <Button
                      component={Link}
                      to={`/mypay/history`}
                      color="secondary"
                      fullWidth
                    >
                      View Historical Payments
                    </Button>
                  )}
                </div>
                <div
                  style={{
                    minHeight: 21,
                    marginBottom: 8,

                    position: "relative",
                  }}
                  className={classes.flexBoxCentered}
                >
                  {isLoadedPayment ? (
                    <Fragment>
                      <Typography
                        variant="body2"
                        align="center"
                        color={
                          Boolean(payment.error) && !isEmpty(payment.error)
                            ? "error"
                            : "textPrimary"
                        }
                        style={{
                          width: "100%",
                          padding: 10,
                          paddingRight: 40,
                          borderRadius: 7,
                          backgroundColor:
                            Boolean(payment.error) && !isEmpty(payment.error)
                              ? "rgba(245,221,219,0.9)"
                              : whichBgColor,
                        }}
                      >
                        {payment.content}
                      </Typography>
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          bottom: 0,
                          marginRight: 10,
                        }}
                        className={classes.flexBoxCenteredColumn}
                      >
                        {payment.status === "succeeded" ? (
                          <CheckCircleOutlineIcon
                            style={{
                              color: "rgb(71,162,58)",
                            }}
                          />
                        ) : payment.status === "requires_action" ? (
                          <WarningIcon
                            style={{
                              color: "rgb(249,131,0)",
                            }}
                          />
                        ) : Boolean(payment.error) &&
                          !isEmpty(payment.error) ? (
                          <ErrorIcon color="error" />
                        ) : (
                          <CircularProgress
                            thickness={2}
                            size={20}
                            style={{
                              color: "rgb(35,131,243)",
                            }}
                          />
                        )}
                      </div>
                    </Fragment>
                  ) : (
                    status.isPaid && (
                      <Fragment>
                        <Typography
                          variant="body2"
                          align="center"
                          color="textSecondary"
                          style={{
                            width: "100%",
                            padding: 10,
                            borderRadius: 7,
                            backgroundColor: "rgba(233,245,232,0.9)",
                          }}
                        >
                          Paid on {dayjs(paidOn).format("h:mma MMM DD, YYYY")}
                        </Typography>
                        <CheckCircleOutlineIcon
                          style={{
                            position: "absolute",
                            right: 0,
                            marginRight: 10,
                            color: "rgb(71,162,58)",
                          }}
                        />
                      </Fragment>
                    )
                  )}
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disablePaymentButton || status.isPaid}
                  fullWidth
                  style={{
                    textTransform: "none",
                    width: "100%",
                    borderRadius: 7,
                    boxShadow:
                      (disablePaymentButton || status.isPaid) &&
                      "0 0 0 0.2rem rgba(34,137,141,0.50)",
                  }}
                >
                  {status.isPaid
                    ? "Invoiced Paid"
                    : `Pay ${numberWithCommas(
                        invoiceBreakdown.payableBreakdown.totalPayable
                      )}`}
                  {disablePaymentButton && !status.isPaid && (
                    <CircularProgress
                      thickness={2}
                      size={20}
                      style={{
                        position: "absolute",
                        right: 0,
                        marginRight: 10,
                        color: "rgb(35,131,243)",
                      }}
                    />
                  )}
                </Button>
              </form>
            )
          )}
          <Hidden mdUp>
            <Button
              color="secondary"
              onClick={() => this.props.showInvoiceDetails()}
              style={{
                textTransform: "none",
                width: "100%",
                marginTop: 10,
              }}
            >
              View Invoice Details
            </Button>
          </Hidden>
        </Box>
      </Paper>
    );
  }
}

PaymentPortal.propTypes = {
  credentials: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  invoiceData: PropTypes.object.isRequired,
  isLoadedInvoice: PropTypes.bool.isRequired,
  showInvoiceDetails: PropTypes.func,
};

export default withStyles(styles)(PaymentPortal);
