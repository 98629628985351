import React, { Component } from "react";
import PropTypes from "prop-types";
import firebase from "../../../Firebase";
import { Link } from "react-router-dom";
import clsx from "clsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter

// Component imports
import OutlinedProfilePic from "../../layout/OutlinedProfilePic";
import { capitalizeFirstChar } from "../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import CardActionArea from "@mui/material/CardActionArea";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class MessageChnContainer extends Component {
  state = { latestMsg: {}, isloadedLatest: false, isNotEmpty: true };

  componentDidMount() {
    this.getLatestMessage();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  getLatestMessage = () => {
    const db = firebase.firestore();
    const messageChannelId = this.props.messageChannel.channelId;

    const docRef = db
      .collection("messageChannel")
      .doc(messageChannelId)
      .collection("messages")
      .orderBy("createdAt")
      .limitToLast(1)
      .limit(1);

    const that = this;

    this.unsubscribe = docRef.onSnapshot(async (snapshot) => {
      const latestMsg = snapshot.docs.map((message) => {
        const _message = message.data();
        _message.id = message.id;
        return _message;
      });

      that.setState({
        latestMsg,
        isLoadedLatest: true,
        isNotEmpty: latestMsg.length > 0,
      });
    });
  };
  render() {
    const { classes, isFamily, messageChannel, userIdNumber } = this.props;
    const { latestMsg, isLoadedLatest, isNotEmpty } = this.state;

    const isUnread =
      isNotEmpty &&
      isLoadedLatest &&
      !latestMsg[0].isRead &&
      latestMsg[0].userIdNumber === userIdNumber;

    return (
      <CardActionArea
        key={messageChannel.channelId}
        component={Link}
        to={`/messages/${messageChannel.channelId}`}
        style={{
          width: "100%",
          padding: 8,
          margin: "4px 0px",
          alignItems: "flex-start",
          backgroundColor: isUnread ? "rgba(227,241,253,0.4)" : "transparent",
          borderRadius: 8,
        }}
        className={clsx(classes.flexBoxSpaced)}
      >
        <OutlinedProfilePic
          imgSrc={
            isFamily
              ? messageChannel.caregiverInfo.imgUrlThumb
              : messageChannel.familyInfo.imgUrlThumb
          }
          size={40}
        />
        <Box
          display="flex"
          flexDirection="column"
          style={{ width: "calc(100% - 52px)" }}
        >
          <Box className={classes.flexBoxSpaced}>
            <Typography noWrap>
              {isFamily
                ? `${capitalizeFirstChar(
                    messageChannel.caregiverInfo.firstName
                  )} ${messageChannel.caregiverInfo.lastName[0].toUpperCase()}.`
                : `${capitalizeFirstChar(
                    messageChannel.familyInfo.firstName
                  )} ${messageChannel.familyInfo.lastName[0].toUpperCase()}.`}
            </Typography>
            {isUnread && <div className={classes.unreadMarker} />}
          </Box>

          {!isLoadedLatest ? (
            <Box className={classes.form}>
              <CircularProgress thickness={2} size={30} />
            </Box>
          ) : (
            isNotEmpty && (
              <Box>
                <Typography
                  noWrap
                  color="textSecondary"
                  variant="body2"
                  style={{
                    width: "100%",
                    fontStyle: !isUnread ? "normal" : "italic",
                  }}
                >
                  {`${latestMsg[0].firstName}: ${latestMsg[0].body}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="caption"
                  align="right"
                  component="div"
                >
                  Received: {dayjs(latestMsg[0].createdAt).fromNow()}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </CardActionArea>
    );
  }
}

MessageChnContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  messageChannel: PropTypes.object.isRequired,
  isFamily: PropTypes.bool.isRequired,
  ind: PropTypes.number,
  userIdNumber: PropTypes.string.isRequired,
};

export default withStyles(styles)(MessageChnContainer);
