import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";

// Component imports
import InitialLoadScreen from "./components/InitialLoadScreen";
import AccountUpdates from "./components/AccountUpdates";
import DashCgSearch from "./components/DashCgSearch";
import DashMessagesCard from "../../../components/dashComps/DashMessagesCard";
import DashMyTeamFamily from "../../../components/dashComps/DashMyTeamFamily";
import firebase from "../../../Firebase";

// Redux imports
import { connect } from "react-redux";
import { setData } from "../../../redux/actions/dataActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class FamilyDashboard extends Component {
  state = {
    showInitialScreen: false,
  };

  // Load Methods
  componentDidMount() {
    const { userIdNumber } = this.props.user.credentials;
    this.setInitialLoad();
    this.getJobs();
    this.getHomeCg();
    this.getMsgChn();
    this.getTeamInv();
    this.getTeam();
    this.getInvoices();
    this.getJobApps();
    if (userIdNumber) {
      this.getShifts();
    }
  }

  componentDidUpdate() {
    const { userIdNumber } = this.props.user.credentials;

    if (userIdNumber && !this.unsubscribe) {
      this.getShifts();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  setInitialLoad() {
    const { isInitial } = this.props.user.credentials.status;
    if (isInitial) {
      this.setState({ showInitialScreen: true });
    }
  }

  async getJobs() {
    try {
      const jobs = (await axios.post("/my-jobs", { isUpdate: true })).data;
      await this.props.setData({ jobs, isLoadedJobs: true });
    } catch (e) {
      await this.props.setData({ isLoadedJobs: true });
      return;
    }
  }

  async getHomeCg() {
    try {
      const caregivers = (await axios.get("/user/home")).data;
      await this.props.setData({ caregivers, isLoadedCaregivers: true });
    } catch (e) {
      await this.props.setData({ isLoadedCaregivers: true });
      return;
    }
  }

  async getMsgChn() {
    try {
      const messageChannels = (await axios.get("/message")).data;
      await this.props.setData({ messageChannels, isLoadedMsgChn: true });
    } catch (err) {
      await this.props.setData({ isLoadedMsgChn: true });
      return;
    }
  }

  async getTeamInv() {
    try {
      const teamInvites = (await axios.get("/team/invite")).data;
      await this.props.setData({ teamInvites, isLoadedTeamInv: true });
    } catch (e) {
      await this.props.setData({ isLoadedTeamInv: true });
      return;
    }
  }

  async getTeam() {
    try {
      const teams = (await axios.get("/team")).data;
      await this.props.setData({
        ...teams,
        isLoadedTeams: true,
      });
    } catch (e) {
      await this.props.setData({ isLoadedTeams: true });
      return;
    }
  }

  async getInvoices() {
    try {
      const _invoices = await axios.get("/invoice/get_all/dash");
      let invoices = _invoices.data;
      this.props.setData({ invoices, isInvoiceLoaded: true });
    } catch (err) {
      console.error(err);
      await this.props.setData({ isInvoiceLoaded: true });
      return;
    }
  }

  getShifts = async () => {
    const userIdNumber = this.props.user.credentials.userIdNumber;
    const colRef = firebase
      .firestore()
      .collection("shifts")
      .where("familyInfo.userIdNumber", "==", userIdNumber)
      .where(
        "startTime",
        ">=",
        dayjs().hour(0).minute(0).second(0).toISOString()
      )
      .limit(20)
      .orderBy("startTime");

    const that = this;
    this.unsubscribe = colRef.onSnapshot(async (snapshot) => {
      const dashShifts = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      await that.props.setData({ dashShifts });
      if (!this.props.data.isLoadedDashShifts) {
        await that.props.setData({ isLoadedDashShifts: true });
      }
    });
  };

  getJobApps = async () => {
    try {
      const _jobApps = await axios.get("/job-applications/get");
      let jobApplications = _jobApps.data;
      this.props.setData({
        jobApplications,
        isLoadedJobApplications: true,
      });
    } catch (err) {
      console.error(err);
      await this.props.setData({ isLoadedJobApplications: true });
      return;
    }
  };

  // UI Methods
  onClickDashboard = () => {
    this.setState({ showInitialScreen: false });
  };

  render() {
    const {
      history,
      user: { credentials, favouritesDoc },
      data: {
        jobs,
        isLoadedJobs,
        messageChannels,
        isLoadedMsgChn,
        teamInvites,
        isLoadedTeamInv,
        members,
        isLoadedTeams,
        isLoadedDashShifts,
        dashShifts,
        invoices,
        isInvoiceLoaded,
        jobApplications,
        isLoadedJobApplications,
        caregivers,
        isLoadedCaregivers,
      },
    } = this.props;
    const { showInitialScreen } = this.state;
    if (showInitialScreen) {
      return (
        <InitialLoadScreen
          onClickDashboard={this.onClickDashboard}
          history={history}
        />
      );
    }

    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid container item xs={12} md={3}>
          <Grid item xs={12}>
            <AccountUpdates
              history={history}
              jobs={jobs}
              invoices={invoices}
              credentials={credentials}
              jobApplications={jobApplications}
              isLoaded={
                isLoadedJobs && isInvoiceLoaded && isLoadedJobApplications
              }
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={9} spacing={2}>
          <Grid item xs={12} sm={6}>
            <DashMyTeamFamily
              teamInvites={teamInvites}
              isLoadedTeamInv={isLoadedTeamInv}
              members={members}
              isLoadedTeams={isLoadedTeams}
              userType={credentials.userType}
              shifts={dashShifts}
              isLoadedShifts={isLoadedDashShifts}
              isLoadedJobApplications={isLoadedJobApplications}
              jobApplications={jobApplications}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DashMessagesCard
              isLoaded={isLoadedMsgChn}
              messageChannels={messageChannels}
              userIdNumber={credentials.userIdNumber}
              userType={credentials.userType}
            />
          </Grid>

          <Grid item xs={12}>
            <DashCgSearch
              isLoadedCaregivers={isLoadedCaregivers}
              caregivers={caregivers}
              credentials={credentials}
              favouritesDoc={favouritesDoc}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

FamilyDashboard.propTypes = {
  user: PropTypes.object.isRequired,
  UI: PropTypes.object,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
  data: state.data,
});

export default connect(mapStateToProps, { setData })(
  withStyles(styles)(FamilyDashboard)
);
