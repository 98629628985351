import React from "react";
import { Link } from "react-router-dom";

// MUI Imports
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Icons Imports

import AccountIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkIcon from "@mui/icons-material/Work";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import GroupIcon from "@mui/icons-material/Group";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import MessageIcon from "@mui/icons-material/Message";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";

function AuthDrawer(props) {
  const { userType, page } = props;

  return (
    <>
      {[
        "Dashboard",
        userType === "family" ? "Caregivers" : "Jobs",
        "MyTeam",
        "Messages",
        "MyPay",
      ].map((text, index) => (
        <ListItem
          button
          onClick={props.handleDrawerClose}
          key={text}
          component={Link}
          to={`/${text.toLowerCase()}/`}
        >
          <ListItemIcon>
            {index === 0 ? (
              page === text ? (
                <HomeIcon color="primary" />
              ) : (
                <HomeOutlinedIcon color="primary" />
              )
            ) : index === 1 ? (
              page === text ? (
                <WorkIcon color="primary" />
              ) : (
                <WorkOutlineOutlinedIcon color="primary" />
              )
            ) : index === 2 ? (
              page === text ? (
                <GroupIcon color="primary" />
              ) : (
                <GroupOutlinedIcon color="primary" />
              )
            ) : index === 3 ? (
              page === text ? (
                <MessageIcon color="primary" />
              ) : (
                <MessageOutlinedIcon color="primary" />
              )
            ) : page === text ? (
              <PaymentIcon color="primary" />
            ) : (
              <PaymentOutlinedIcon color="primary" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography fontWeight={page === text ? "bold" : "medium"}>
                {text.split(/(?=[A-Z])/).join(" ")}
              </Typography>
            }
          />
        </ListItem>
      ))}
      <ListItem
        button
        onClick={props.handleDrawerClose}
        component={Link}
        to="/profile"
      >
        <ListItemIcon>
          <AccountIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItem>
    </>
  );
}

export default AuthDrawer;
