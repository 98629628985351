import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";

// Redux imports
import { connect } from "react-redux";
import { getUserData } from "../../../redux/actions/userActions";

// Components imports
import { allFalse } from "../../../util/utilFunctions";
import { minWage } from "../../../util/utilConst";
import ServiceMarkup from "./components/ServiceMarkup";
import ConditionsMarkup from "./components/ConditionsMarkup";
import JobTypeLanguageMarkup from "./components/JobTypeLanguageMarkup";
import WageBioMarkup from "./components/WageBioMarkup";
import Stepper from "../../../components/layout/Components/Stepper";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class caregiverService extends Component {
  state = {
    serviceArray: [],
    languagePrefArray: ["english"],
    jobTypeArray: [],
    conditionsArray: ["homeHealthCare"],
    bio: "",
    wageExp: "",
    yearsExp: 0,
    isShowMoreService: false,
    isShowMoreConditions: false,
    errors: {},
    signupMaterials: {
      language: [],
      service: {},
      jobType: {},
      conditions: {},
    },
    isLoadedSignupMateraials: false,
    isShowMain: false,
    showService: true,
    showConditions: false,
    showJobTypeLanguage: false,
    showBio: false,
  };

  // Initial Load Methods

  componentDidMount() {
    this.checkAccountStatus();
    this.showMain();
    this.getSignupMaterials();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  checkAccountStatus() {
    const { isBasicCompleted, isServiceCompleted, isEduAccredCompleted } =
      this.props.credentials.status;
    if (!isBasicCompleted) {
      this.props.history.push("/caregiver/signup/basic-info");
    }
    if (isServiceCompleted) {
      this.props.history.push("/caregiver/signup/education-experience");
    }
    if (isEduAccredCompleted) {
      this.props.history.push("/caregiver/signup/finish");
    }
  }

  getSignupMaterials() {
    axios
      .get("/signup-material")
      .then((res) => {
        this.setState({
          signupMaterials: res.data,
          isLoadedSignupMateraials: true,
        });
      })
      .catch((err) => console.error(err));
  }

  showMain() {
    setTimeout(() => this.setState({ isShowMain: true }), 2000);
  }

  setStateAsync(state) {
    return new Promise((resolve) => this.setState(state, resolve));
  }

  // Errors method

  validateService = () => {
    const { serviceArray, errors } = this.state;
    this.setState({
      errors: {
        ...errors,
        service:
          serviceArray.length < 1
            ? "Please select at least 1 service you can provide"
            : null,
      },
    });
  };

  validateConditions = () => {
    const { conditionsArray, errors } = this.state;
    this.setState({
      errors: {
        ...errors,
        conditions:
          conditionsArray.length < 1
            ? "Please select at least 1 option from below"
            : null,
      },
    });
  };

  validateJobType = () => {
    const { jobTypeArray, errors } = this.state;
    this.setState({
      errors: {
        ...errors,
        jobType:
          jobTypeArray.length < 1
            ? "Please select at least 1 type of job"
            : null,
      },
    });
  };

  validateLanguage = () => {
    const { languagePrefArray, errors } = this.state;
    this.setState({
      errors: {
        ...errors,
        languagePref:
          languagePrefArray.length < 1
            ? "Please select at least 1 language"
            : null,
      },
    });
  };

  validateWage = () => {
    const { wageExp, errors } = this.state;
    const { credentials } = this.props;

    const prov = credentials.address.addressParams.adminAreaLevel1;

    const _minWage = Boolean(minWage[prov]) ? minWage[prov] : 0;

    this.setState({
      errors: {
        ...errors,
        wageExp:
          parseFloat(wageExp) < 0
            ? "Wage can not be negative"
            : parseFloat(wageExp) === 0 || wageExp.trim() === ""
            ? "Wage can not be empty or 0"
            : parseFloat(wageExp) < _minWage
            ? `Must be higher than ${credentials.address.addressParams.adminAreaLevel1.toUpperCase()}'s minimum wage of $${_minWage}`
            : null,
      },
    });
  };

  validateBio = () => {
    const { bio, errors } = this.state;
    this.setState({
      errors: {
        ...errors,
        bio: bio.trim() === "" ? "About me section must not be empty" : null,
      },
    });
  };

  validateSubmit = async () => {
    await this.validateService();
    await this.validateConditions();
    await this.validateJobType();
    await this.validateLanguage();
    await this.validateWage();
    await this.validateBio();
  };

  // Action Methods

  handleService = (e) => {
    const value = e.currentTarget.name;
    const { serviceArray } = this.state;
    if (!serviceArray.includes(value)) {
      const _serviceArray = serviceArray;
      _serviceArray.push(value);
      this.setState({
        serviceArray: _serviceArray,
        errors: {
          ...this.state.errors,
          service: null,
        },
      });
    } else {
      this.setState({
        serviceArray: serviceArray.filter((service) => service !== value),
        errors: {
          ...this.state.errors,
          service: null,
        },
      });
    }
  };

  handleCondition = (e) => {
    const value = e.currentTarget.name;
    const { conditionsArray } = this.state;
    if (!conditionsArray.includes(value)) {
      const _conditionsArray = conditionsArray;
      _conditionsArray.push(value);
      this.setState({
        conditionsArray: _conditionsArray,
        errors: {
          ...this.state.errors,
          conditions: null,
        },
      });
    } else {
      this.setState({
        conditionsArray: conditionsArray.filter(
          (conditions) => conditions !== value
        ),
        errors: {
          ...this.state.errors,
          conditions: null,
        },
      });
    }
  };

  handleJobType = (e) => {
    let value = e.currentTarget.name;
    const { jobTypeArray } = this.state;
    if (!jobTypeArray.includes(value)) {
      let _jobTypeArray = jobTypeArray;
      _jobTypeArray.push(value);
      this.setState({
        jobTypeArray: _jobTypeArray,
        errors: {
          ...this.state.errors,
          jobType: null,
        },
      });
    } else {
      this.setState({
        jobTypeArray: jobTypeArray.filter((jobType) => jobType !== value),
        errors: {
          ...this.state.errors,
          jobType: null,
        },
      });
    }
  };

  handleLanguage = (e) => {
    let value = e.currentTarget.name;
    const { languagePrefArray } = this.state;
    if (!languagePrefArray.includes(value)) {
      let _languagePrefArray = languagePrefArray;
      _languagePrefArray.push(value);
      this.setState({
        languagePrefArray: _languagePrefArray,
        errors: {
          ...this.state.errors,
          languagePref: null,
        },
      });
    } else {
      this.setState({
        languagePrefArray: languagePrefArray.filter((lang) => lang !== value),
        errors: {
          ...this.state.errors,
          languagePref: null,
        },
      });
    }
  };

  handleWage = (e) => {
    const value = e.target.value;

    const { credentials } = this.props;

    const prov = credentials.address.addressParams.adminAreaLevel1;

    const _minWage = Boolean(minWage[prov]) ? minWage[prov] : 0;

    this.setState({
      wageExp: value,
      errors: {
        ...this.state.errors,
        wageExp:
          parseFloat(value) < 0
            ? "Wage can not be negative"
            : parseFloat(value) === 0 || value.trim() === ""
            ? "Wage can not be empty or 0"
            : parseFloat(value) < _minWage
            ? `Must be higher than ${credentials.address.addressParams.adminAreaLevel1.toUpperCase()}'s minimum wage of $${_minWage.toFixed(
                2
              )}`
            : null,
      },
    });
  };

  handleYearsExp = (v) => {
    this.setState({ yearsExp: v });
  };

  handleBio = (e) => {
    let value = e.target.value;

    this.setState({
      bio: value,
      errors: {
        ...this.state.errors,
        bio: value.trim() === "" ? "About me section must not be empty" : null,
      },
    });
  };

  handleSubmit = async (e) => {
    await this.validateSubmit();
    await this.setStateAsync({ disableWhileLoad: true });
    const {
      errors,
      serviceArray,
      conditionsArray,
      jobTypeArray,
      languagePrefArray,
      wageExp,
      yearsExp,
      bio,
    } = this.state;

    const noErrors = allFalse(errors);

    if (noErrors) {
      const userDetails = {
        service: Object.fromEntries(serviceArray.map((key) => [key, true])),
        conditions: Object.fromEntries(
          conditionsArray.map((key) => [key, true])
        ),
        jobType: Object.fromEntries(jobTypeArray.map((key) => [key, true])),
        languagePref: languagePrefArray,
        yearsExp,
        wageExp,
        bio,
      };

      try {
        await axios.post("/user/caregiver/service-info", userDetails);
        setTimeout(
          () =>
            this.props.history.push("/caregiver/signup/education-experience"),
          500
        );
      } catch (err) {
        this.setState({ errors: err.response.data, disableWhileLoad: false });
      }
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  // UI page change

  scrollToTop = () => {
    window.scrollTo({ top: 24, behavior: "smooth" });
  };

  handleCompleteService = async () => {
    await this.validateService();
    const { errors } = this.state;

    if (!Boolean(errors.service)) {
      this.setState(
        { showService: false, showConditions: true },
        this.scrollToTop
      );
    }
  };

  handleBackToService = () => {
    this.setState(
      { showService: true, showConditions: false },
      this.scrollToTop
    );
  };

  handleCompleteConditions = async () => {
    await this.validateConditions();
    const { errors } = this.state;

    if (!Boolean(errors.conditions)) {
      this.setState(
        { showConditions: false, showJobTypeLanguage: true },
        this.scrollToTop
      );
    }
  };

  handleBackToConditions = () => {
    this.setState(
      { showConditions: true, showJobTypeLanguage: false },
      this.scrollToTop
    );
  };

  handleCompleteJobTypeLanguage = async () => {
    await this.validateJobType();
    await this.validateLanguage();
    const { errors } = this.state;

    if (!Boolean(errors.jobType) && !Boolean(errors.languagePref)) {
      this.setState(
        { showJobTypeLanguage: false, showBio: true },
        this.scrollToTop
      );
    }
  };

  handleBackToJobTypeLanguage = () => {
    this.setState(
      { showJobTypeLanguage: true, showBio: false },
      this.scrollToTop
    );
  };

  render() {
    const { classes } = this.props;
    const {
      errors,
      signupMaterials,
      isLoadedSignupMateraials,
      serviceArray,
      conditionsArray,
      jobTypeArray,
      languagePrefArray,
      isShowMain,
      showService,
      showConditions,
      showJobTypeLanguage,
      wageExp,
      bio,
      yearsExp,
      disableWhileLoad,
    } = this.state;

    return (
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: 24, minHeight: 400 }}
      >
        {isShowMain ? (
          <Fade in timeout={1000} unmountOnExit>
            <Grid item xs={12} sm={9} md={8} lg={7}>
              <Paper
                elevation={4}
                className={clsx(
                  classes.paperContainerSignup,
                  classes.darkGrayBorderOnly
                )}
              >
                <Grid
                  container
                  justifyContent="center"
                  style={{ width: "100%", display: "flex", marginBottom: 20 }}
                >
                  <Stepper total={5} fadeIn={2} />
                </Grid>
                {showService ? (
                  <Fade in>
                    <div>
                      <ServiceMarkup
                        isLoadedSignupMateraials={isLoadedSignupMateraials}
                        serviceObj={signupMaterials.service}
                        error={errors.service}
                        serviceArray={serviceArray}
                        handleService={this.handleService}
                        handleCompleteService={this.handleCompleteService}
                      />
                    </div>
                  </Fade>
                ) : showConditions ? (
                  <Fade in>
                    <div>
                      <ConditionsMarkup
                        conditionsObj={signupMaterials.conditions}
                        error={errors.conditions}
                        conditionsArray={conditionsArray}
                        handleCondition={this.handleCondition}
                        handleCompleteConditions={this.handleCompleteConditions}
                        handleBack={this.handleBackToService}
                      />
                    </div>
                  </Fade>
                ) : showJobTypeLanguage ? (
                  <Fade in>
                    <div>
                      <JobTypeLanguageMarkup
                        jobTypeObj={signupMaterials.jobType}
                        errorJobType={errors.jobType}
                        jobTypeArray={jobTypeArray}
                        handleJobType={this.handleJobType}
                        languagePref={signupMaterials.language}
                        errorLanguagePref={errors.languagePref}
                        languagePrefArray={languagePrefArray}
                        handleLanguage={this.handleLanguage}
                        handeComplete={this.handleCompleteJobTypeLanguage}
                        handleBack={this.handleBackToConditions}
                      />
                    </div>
                  </Fade>
                ) : (
                  <Fade in>
                    <div>
                      <WageBioMarkup
                        handleWage={this.handleWage}
                        wageExp={wageExp}
                        errorWageExp={errors.wageExp}
                        yearsExp={yearsExp}
                        setYearsExp={this.handleYearsExp}
                        bio={bio}
                        handleBio={this.handleBio}
                        errorBio={errors.bio}
                        handleComplete={this.handleSubmit}
                        handleBack={this.handleBackToJobTypeLanguage}
                        disable={disableWhileLoad}
                      />
                    </div>
                  </Fade>
                )}
              </Paper>
            </Grid>
          </Fade>
        ) : (
          <Fade in timeout={1000} unmountOnExit>
            <Typography
              variant="h4"
              align="center"
              style={{ fontWeight: 300, marginTop: 50 }}
            >
              Tell us about you professionally
            </Typography>
          </Fade>
        )}
      </Grid>
    );
  }
}

caregiverService.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});
export default connect(mapStateToProps, { getUserData })(
  withStyles(styles)(caregiverService)
);
