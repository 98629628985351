import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// Component imports
import MemberSelectContainer from "../../../components/shiftsTasks/components/MemberSelectContainer";
import MemberShiftDataContainer from "../../../components/shiftsTasks/components/MemberShiftDataContainer";
import firebase from "../../../Firebase";

// Redux imports
import { connect } from "react-redux";
import { setData } from "../../../redux/actions/dataActions";
import { setAlert, setUpdateCheck } from "../../../redux/actions/uiActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Hidden from "@mui/material/Hidden";
import Slide from "@mui/material/Slide";

dayjs.extend(utc);

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class CgMyTeam extends Component {
  state = {
    currMemberId: "",
    currMemberData: {},
    isInitialSet: false,
    showMemberData: false,
    showMemberList: true,
  };

  // Load Methods
  componentDidMount() {
    (async () => {
      await this.getTeams();
      await this.getShifts();
      await this.setMemberId();
      await this.setInitialShiftNonSnapshot();

      await this.setInitialShowState();
    })();
  }

  componentWillUnmount() {
    if (this.state.unsubscribeShifts) {
      this.unsubscribeShifts();
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async setInitialShiftNonSnapshot() {
    const shiftId = this.props.match.params.shiftId;

    if (shiftId) {
      try {
        const shiftDoc = await axios.get(`/shift/${shiftId}`);
        await this.setFirstDateLoaded(dayjs().startOf("week").toISOString());
        await this.handleLoadShifts(
          dayjs(shiftDoc.data.startTime).startOf("week").toISOString()
        );
      } catch (err) {
        return;
      }
    } else {
      await this.setFirstDateLoaded(dayjs());
    }
  }

  async getTeams() {
    const teamsObj = (await axios.get("/team")).data;
    this.props.setData({ ...teamsObj, isLoadedTeams: true });
  }

  async getShifts() {
    const userId = this.props.credentials.userIdNumber;
    const db = firebase.firestore();
    const colRef = db
      .collection("shifts")
      .where("caregiverInfo.userIdNumber", "==", userId)
      .where("startTime", ">=", dayjs().startOf("week").toISOString())
      .orderBy("startTime");

    // Realtime updates listener
    this.unsubscribeShifts = colRef.onSnapshot(async (snapshot) => {
      let shifts = snapshot.docs.map((doc) => {
        let res = doc.data();
        res.id = doc.id;
        return res;
      });

      this.setShiftsToState(shifts, dayjs().startOf("week").toISOString());
    });
  }

  handleLoadShifts = async (weekStart, lazyLoad) => {
    const userId = this.props.credentials.userIdNumber;

    const db = firebase.firestore();
    const shiftsRef = db
      .collection("shifts")
      .where("caregiverInfo.userIdNumber", "==", userId)
      .where("startTime", ">=", weekStart)
      .where("startTime", "<", this.props.data.firstDateLoaded)
      .orderBy("startTime");

    if (!lazyLoad) this.props.setData({ isLoadedShifts: false });

    try {
      const shiftsSnapshot = await shiftsRef.get();
      if (!shiftsSnapshot.empty) {
        const shifts = [];
        shiftsSnapshot.forEach((shift) => {
          shifts.push({ ...shift.data(), id: shift.id });
        });
        this.props.setData({
          shifts: [...shifts, ...this.props.data.shifts],
        });

        this.props.setUpdateCheck();
      }
      this.setFirstDateLoaded(weekStart);
    } catch (err) {
      this.props.setAlert({
        message: "There was an error loading your shifts. Please try again",
        type: "error",
      });
    } finally {
      this.props.setData({ isLoadedShifts: true });
    }
  };

  async setMemberId() {
    const memberId = this.props.match.params.memberId;
    const { members } = this.props.data;

    // Sets member info if memberId in URL
    if (memberId) {
      const currMemberData = members.find((k) => k.id === memberId);
      // Sets member if team member found
      if (Boolean(currMemberData)) {
        const currMemberId = currMemberData.id;
        this.setState({
          isInitialSet: true,
          currMemberData: currMemberData,
          currMemberId,
        });

        // Sets member if memberId not part of members
      } else if (members.length > 0) {
        this.setState({
          isInitialSet: true,
          currMemberData: members[0],
          currMemberId: members[0].id,
        });
        this.props.history.push(`/myteam/${members[0].id}`);
        // Sets default
      } else {
        this.props.history.push(`/myteam/`);
      }
    }
    // Sets member info as first loaded member if no memberId passed to URL
    else {
      this.setState({
        isInitialSet: true,
        currMemberData: {},
        currMemberId: "all",
      });
      this.props.history.push(`/myteam/all`);
    }
  }

  // Sets initial load case for xs view
  setInitialShowState() {
    const memberId = this.props.match.params.memberId;
    const initial = {};
    if (memberId) {
      initial.showMemberData = true;
      initial.showMemberList = false;
    }
    this.setState({ ...initial });
  }

  // Helper methods

  setShiftsToState = (snapshotShifts, startTime) => {
    const preSnapshotShifts = this.props.data.shifts.filter(
      (shift) => shift.startTime < startTime
    );

    // Sets new shifts array with preSnapshotShifts and snapshotShifts
    this.props.setData({
      shifts: [...preSnapshotShifts, ...snapshotShifts],
      isLoadedShifts: true,
    });

    // Sets update check
    setTimeout(() => this.props.setUpdateCheck(), 250);
  };

  setFirstDateLoaded(weekStart) {
    this.props.setData({
      firstDateLoaded: dayjs(weekStart).startOf("week").toISOString(),
    });
  }

  // Action Method
  handleNext = async () => {
    await this.setStateAsync({ showMemberList: false });
    await setTimeout(() => this.setState({ showMemberData: true }), 200);
  };

  handleBack = async () => {
    await this.setStateAsync({ showMemberData: false });
    await setTimeout(() => this.setState({ showMemberList: true }), 200);
  };

  handleSelectMember = (v) => {
    const { members } = this.props.data;
    if (v !== "all") {
      const currMemberData = members.find((cg) => cg.id === v);
      this.setState({ currMemberId: v, currMemberData });
      this.props.history.push(`/myteam/${v}`);
    } else {
      this.setState({ currMemberId: v, currMemberData: {} });
      this.props.history.push(`/myteam/all`);
    }
  };

  onUpdateMember = () => {
    const { members } = this.props.data;

    const memberId = this.props.match.params.memberId;

    const currMemberData = members.find((cg) => cg.id === memberId);
    this.setState({ currMemberId: memberId, currMemberData });
  };

  render() {
    const { classes } = this.props;
    const {
      currMemberData,
      showMemberData,
      showMemberList,
      currMemberId,
      isInitialSet,
    } = this.state;

    const scheduleNewShift =
      this.props.history.location.pathname.includes("schedule-shift");

    const shiftId = this.props.match.params.shiftId;

    // .utcOffset(new Date().getTimezoneOffset())
    //     .format()
    return (
      <Grid container justifyContent="center" spacing={2}>
        <Hidden mdDown>
          <Grid item sm={4} md={3}>
            <Paper
              style={{ height: 430 }}
              className={classes.smallPaperContainer}
              elevation={4}
            >
              <MemberSelectContainer
                currMemberId={currMemberId}
                onSelectMember={this.handleSelectMember}
                onSelectNext={this.handleNext}
              />
            </Paper>
          </Grid>
          <Grid item sm={8} md={9} style={{ minHeight: 430 }}>
            <Paper
              elevation={4}
              style={{ minHeight: 430 }}
              className={classes.smallPaperContainer}
            >
              <MemberShiftDataContainer
                onUpdateMember={this.onUpdateMember}
                shiftId={shiftId}
                isInitialSet={isInitialSet}
                loadShifts={this.handleLoadShifts}
                currMemberId={currMemberId}
                scheduleNewShift={scheduleNewShift}
                currMemberData={currMemberData}
                onSelectBack={this.handleBack}
              />
            </Paper>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Slide
            in={showMemberList}
            direction="right"
            mountOnEnter
            unmountOnExit
          >
            <Grid item xs={12}>
              <Paper
                style={{ height: 430 }}
                className={classes.smallPaperContainer}
                elevation={4}
              >
                <MemberSelectContainer
                  currMemberId={currMemberId}
                  onSelectMember={this.handleSelectMember}
                  onSelectNext={this.handleNext}
                />
              </Paper>
            </Grid>
          </Slide>
          <Slide
            in={showMemberData}
            direction="left"
            mountOnEnter
            unmountOnExit
          >
            <Grid item xs={12}>
              <Paper
                elevation={4}
                style={{ minHeight: 430 }}
                className={classes.smallPaperContainer}
              >
                <MemberShiftDataContainer
                  onUpdateMember={this.onUpdateMember}
                  shiftId={shiftId}
                  isInitialSet={isInitialSet}
                  loadShifts={this.handleLoadShifts}
                  currMemberId={currMemberId}
                  scheduleNewShift={scheduleNewShift}
                  currMemberData={currMemberData}
                  onSelectBack={this.handleBack}
                />
              </Paper>
            </Grid>
          </Slide>
        </Hidden>
      </Grid>
    );
  }
}

CgMyTeam.propTypes = {
  credentials: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  setUpdateCheck: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, { setData, setAlert, setUpdateCheck })
);
export default enhance(CgMyTeam);
