import React, { Component } from "react";
import "./policies.css";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export class policies extends Component {
  state = { panel: false };
  componentDidMount() {
    this._setInitialPanel();
  }

  componentDidUpdate(prevState) {
    if (prevState.panel !== this.state.panel) {
      this._scrollIntoView();
    }
  }

  _setInitialPanel = () => {
    const { pathname } = this.props.location;

    this.setState({ panel: pathname.split("/policies/")[1] });
    this._scrollIntoView();
  };

  _togglePolicyPanel = (panel) => (e, newExpanded) => {
    this.setState({ panel: newExpanded ? panel : false });
    // this.props.history.replace();
    window.history.replaceState(null, "something", "/policies/" + panel);
  };

  _scrollIntoView = () => {
    const accord = document.getElementById(this.state.panel);
    Boolean(accord) && accord.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    const { panel } = this.state;
    return (
      <div className="page-wrapper">
        <section className="section-wrapper">
          <Typography variant="h2" color="primary" className="txt-bold">
            Policies
          </Typography>
        </section>
        <section style={{ margin: "12px 0px" }}>
          <Accordion
            className="accordion"
            expanded={panel === "terms-of-use"}
            onChange={this._togglePolicyPanel("terms-of-use")}
            TransitionProps={{ unmountOnExit: true, timeout: 250 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="terms-of-use-content"
              id="terms-of-use"
            >
              <Typography variant="h6">Terms of Use</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <Terms />
            </AccordionDetails>
          </Accordion>
        </section>
        <section style={{ margin: "12px 0px" }}>
          <Accordion
            className="accordion"
            expanded={panel === "privacy"}
            onChange={this._togglePolicyPanel("privacy")}
            TransitionProps={{ unmountOnExit: true, timeout: 250 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="privacy-content"
              id="privacy"
            >
              <Typography variant="h6">Privacy</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <Privacy history={this.props.history} />
            </AccordionDetails>
          </Accordion>
        </section>
      </div>
    );
  }
}

export default policies;
