import React from "react";

// Component imports
import LoadingAnimation from "../../layout/LoadingAnimation";
import TaskDisplay from "./TaskDisplay";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Icon imports
import AssignmentIcon from "@mui/icons-material/Assignment";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function TaskDisplayContainer(props) {
  const {
    loadingTasks,
    tasks,
    classes,
    isFamily,
    shift,
    disableDeleteButton,
    allowReload,
  } = props;

  return loadingTasks ? (
    <LoadingAnimation message="Loading tasks" />
  ) : (
    <Box>
      {tasks.length < 1 ? (
        <Box
          className={classes.flexBoxCentered}
          style={{ flexDirection: "column", padding: 10 }}
        >
          <AssignmentIcon color="disabled" fontSize="large" />
          <Typography variant="body2" align="center" color="textSecondary">
            No tasks asigned.
          </Typography>
        </Box>
      ) : (
        tasks.map((task, ind) => (
          <TaskDisplay
            key={task.id}
            task={task}
            ind={ind}
            small
            showAction
            isFamily={isFamily}
            onUpdateTaskStatus={props.handleUpdateTask}
            canUpdate={shift.status.isCheckedIn}
            handleDeleteTask={props.handleDeleteTask}
            disableDeleteButton={disableDeleteButton}
          />
        ))
      )}
      {(allowReload || shift.numTasks !== tasks.length) && (
        <div className={classes.flexBoxCentered}>
          <Button
            size="small"
            onClick={props.getTasks}
            color="primary"
            style={{ marginTop: 16 }}
          >
            Reload
          </Button>
        </div>
      )}
    </Box>
  );
}

export default withStyles(styles)(TaskDisplayContainer);
