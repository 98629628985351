import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

// Component Imports
import { allFalse, isEmpty } from "../../../../util/utilFunctions";
import TooltipButton from "../../../../components/inputs/TooltipButton";
import DateSelector from "../../../../components/inputs/DateSelector";

// Redux imports
import { connect } from "react-redux";
import store from "../../../../redux/store";
import { CLEAR_ERRORS } from "../../../../redux/types";
import {
  submitWorkExp,
  editWorkExp,
} from "../../../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

// Icons imports
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/EditOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class CaregiverWorkDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        title: null,
        company: null,
        startDate: null,
        endDate: null,
        description: null,
      },
      title: "",
      company: "",
      employType: "",
      startDate: "",
      endDate: "",
      description: "",
      isPresent: false,
      open: false,
    };

    // preserve the initial state in a new object
    this.initialState = this.state;
  }

  componentDidMount() {
    const { isEdit } = this.props;
    if (isEdit) {
      this.setInitialState();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.UI.loadingSpecial &&
      !this.props.UI.loadingSpecial &&
      !this.props.UI.errors
    ) {
      this.handleClose();
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.UI.errors) {
      return {
        errors: props.UI.errors,
      };
    }
    return null;
  }

  setInitialState() {
    const { title, company, employType, startDate, endDate, description } =
      this.props.workExp;

    this.setState({
      errors: {
        title: false,
        company: false,
        startDate: false,
        endDate: false,
        description: false,
      },
      title: title,
      company: company,
      employType: employType,
      startDate: startDate,
      endDate: endDate,
      description: description,
      isPresent: endDate === "Present",
    });
  }

  // UI Methods

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = async (event, reason) => {
    const { isEdit } = this.props;
    if (reason !== "backdropClick") {
      if (isEdit) {
        this.setState({ open: false }, () => this.setInitialState());
      } else {
        this.setState(this.initialState);
      }
    }
  };

  // Action functions

  handleTitle = (event) => {
    let value = event.target.value;
    this.setState({
      title: value,
      errors: {
        ...this.state.errors,
        title: value.trim() === "" && "Must not be empty",
      },
    });
  };

  handleCompany = (event) => {
    let value = event.target.value;
    this.setState({
      company: value,
      errors: {
        ...this.state.errors,
        company: value.trim() === "" && "Must not be empty",
      },
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStartDate = async (value) => {
    await this.validateStartDate(value);
    this.setState({
      startDate: dayjs(value).toISOString(),
    });
  };

  handleEndDate = async (value) => {
    await this.validateEndDate(value);

    this.setState({
      endDate: dayjs(value).toISOString(),
    });
  };

  handlePresent = (event) => {
    this.setState({
      isPresent: event.target.checked,
      endDate: !this.state.isPresent ? "Present" : "",
      errors: {
        ...this.state.errors,
        endDate: null,
      },
    });
  };

  // Error validation methods

  validateStartDate = (value) => {
    const { endDate, errors } = this.state;

    this.setState({
      errors: {
        ...errors,
        startDate:
          !isEmpty(endDate) &&
          endDate !== "Present" &&
          endDate < dayjs(value).toISOSTring()
            ? "Must be less than end date"
            : null,
      },
    });
  };

  validateEndDate = (value) => {
    const { startDate, errors } = this.state;
    this.setState({
      errors: {
        ...errors,
        endDate:
          !isEmpty(startDate) &&
          value !== "Present" &&
          startDate > dayjs(value).toISOString()
            ? "Must be greater than start date"
            : null,
      },
    });
  };

  validateSubmit = () => {
    const { title, company, startDate, endDate } = this.state;
    const _errors = {
      title: title.trim() === "" && "Please enter your title",
      company: company.trim() === "" && "Please enter your company",
      startDate:
        startDate.trim() === ""
          ? "Please enter your start date"
          : endDate.trim() !== "" &&
            endDate.trim() !== "Present" &&
            startDate > endDate &&
            "Must be less than end date",
      endDate:
        endDate.trim() === ""
          ? "Please enter your end date"
          : startDate.trim() !== "" &&
            endDate.trim() !== "Present" &&
            endDate < startDate &&
            "Must be greater than start date",
    };

    this.setState({ errors: _errors });
  };

  // Submit methods

  handleSubmit = async (event) => {
    event.preventDefault();
    store.dispatch({ type: CLEAR_ERRORS });
    const { isEdit, workExpId } = this.props;

    await this.validateSubmit();
    const {
      errors,
      title,
      company,
      employType,
      startDate,
      endDate,
      description,
    } = this.state;

    let noErrors = allFalse(errors);
    if (noErrors) {
      const newWorkExp = {
        title,
        company,
        startDate,
        endDate,
        description,
        employType,
      };
      isEdit
        ? this.props.editWorkExp(newWorkExp, workExpId)
        : this.props.submitWorkExp(newWorkExp);
    }
  };

  render() {
    const {
      classes,
      UI: { loadingSpecial },
      isEdit,
      color,
    } = this.props;
    const {
      errors,
      title,
      company,
      employType,
      endDate,
      description,
      isPresent,
      open,
      startDate,
    } = this.state;

    return (
      <div>
        <TooltipButton
          onClick={this.handleOpen}
          tip={isEdit ? "Edit" : "Add"}
          size="small"
          tipClassName={classes.expandButton}
        >
          {isEdit ? (
            <EditIcon color={color ? color : "primary"} />
          ) : (
            <AddIcon color={color ? color : "primary"} />
          )}
        </TooltipButton>
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
          disableEscapeKeyDown
        >
          <DialogContent className={classes.dialogContent}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" className={classes.typography}>
                {isEdit
                  ? "Edit my work experience"
                  : "Add a new work experience"}
              </Typography>
              <TooltipButton
                tip="Cancel"
                placement="top"
                size="small"
                onClick={this.handleClose}
                tipClassName={classes.closeButton}
              >
                <CloseIcon color="primary" />
              </TooltipButton>
            </Box>
            <Divider />
            <form
              noValidate
              onSubmit={this.handleSubmit}
              style={{ marginTop: 16 }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color={Boolean(errors.title) ? "error" : "inherit"}
                    className={classes.typography}
                  >
                    Title*
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="title"
                    onChange={this.handleTitle}
                    placeholder="Ex: Home Support Worker..."
                    value={title}
                    error={Boolean(errors.title)}
                    className={
                      !Boolean(errors.title)
                        ? classes.styledTextField
                        : classes.styledTextFieldError
                    }
                    helperText={errors.title}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color={Boolean(errors.company) ? "error" : "inherit"}
                    className={classes.typography}
                  >
                    Company*
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="company"
                    onChange={this.handleCompany}
                    value={company}
                    placeholder="Ex: Vancouver Coastal Health.."
                    error={Boolean(errors.company)}
                    className={
                      !Boolean(errors.company)
                        ? classes.styledTextField
                        : classes.styledTextFieldError
                    }
                    helperText={errors.company}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.typography}>
                    Employment type
                  </Typography>

                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      native
                      value={employType}
                      name="employType"
                      onChange={this.handleChange}
                      className={classes.styledTextField}
                      fullWidth
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                    >
                      <option value="" disabled>
                        -
                      </option>
                      <option value={"Full-time"}>Full-time</option>
                      <option value={"Part-time"}>Part-time</option>
                      <option value={"Casual"}>Casual / On Call</option>
                      <option value={"Self-employed"}>Self-employed</option>
                      <option value={"Internship"}>Internship</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.flexBox}>
                  <Checkbox
                    checked={isPresent}
                    onChange={this.handlePresent}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <Typography
                    variant="body2"
                    color={isPresent ? "primary" : "textSecondary"}
                  >
                    I am currently working here
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    color={Boolean(errors.startDate) ? "error" : "inherit"}
                    className={classes.typography}
                  >
                    Start Date*
                  </Typography>
                  <DateSelector
                    variant="outlined"
                    size="small"
                    placeholder="Start Date..."
                    format="MMMM YYYY"
                    fullWidth
                    value={!isEmpty(startDate) && startDate}
                    views={["year", "month"]}
                    errorDate={Boolean(errors.startDate)}
                    errorDateText={errors.startDate}
                    onSelectDate={this.handleStartDate}
                    propMinDate={dayjs("1960-01-05")}
                    propMaxDate={dayjs()}
                    className={
                      !Boolean(errors.startDate)
                        ? classes.styledTextField
                        : classes.styledTextFieldError
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    color={
                      Boolean(errors.endDate)
                        ? "error"
                        : isPresent
                        ? "textSecondary"
                        : "inherit"
                    }
                    className={classes.typography}
                  >
                    End Date*
                  </Typography>
                  {!isPresent ? (
                    <DateSelector
                      variant="outlined"
                      size="small"
                      placeholder="End Date..."
                      format="MMMM YYYY"
                      fullWidth
                      views={["year", "month"]}
                      value={!isEmpty(endDate) && endDate}
                      errorDate={Boolean(errors.endDate)}
                      errorDateText={errors.endDate}
                      onSelectDate={this.handleEndDate}
                      propMinDate={dayjs("1960-01-05")}
                      propMaxDate={dayjs()}
                      className={
                        !Boolean(errors.endDate)
                          ? classes.styledTextField
                          : classes.styledTextFieldError
                      }
                    />
                  ) : (
                    <Typography style={{ marginTop: 10 }}>{endDate}</Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.typography}>
                    Description
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="description"
                    onChange={this.handleChange}
                    value={description}
                    placeholder="What did you do..."
                    fullWidth
                    multiline
                    minRows="5"
                    size="small"
                    className={classes.styledTextField}
                  />
                </Grid>
              </Grid>
              <Divider style={{ margin: "8px 0px" }} />

              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  style={{ width: 100 }}
                  disabled={loadingSpecial}
                >
                  Save
                  {loadingSpecial && (
                    <CircularProgress
                      thickness={2}
                      size={30}
                      className={classes.progress}
                    />
                  )}
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

CaregiverWorkDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  submitWorkExp: PropTypes.func,
  UI: PropTypes.object.isRequired,
  User: PropTypes.object,
  color: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, { submitWorkExp, editWorkExp })(
  withStyles(styles)(CaregiverWorkDialog)
);
