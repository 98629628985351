import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";

// Component imports
import { capitalizeFirstChar } from "../../../../util/utilFunctions";
import TooltipButton from "../../../../components/inputs/TooltipButton";

// Redux imports
import { connect } from "react-redux";
import { setFav, setUnfav } from "../../../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// Icons imports
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import HelpIcon from "@mui/icons-material/Help";
import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarIcon from "@mui/icons-material/Star";

const styles = (theme) => ({
  ...theme.spreadThis,

  mediaBox: {
    height: 120,
    width: 120,
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
    borderRadius: "50%",
    border: "4px solid white",
    position: "relative",
  },
  media: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  },
  responsiveIconButton: {
    position: "absolute",
    top: 0,
    right: "0%",
    [theme.breakpoints.down("md")]: {
      right: "5%",
    },
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(231, 240, 248, 0.95)",
    },
  },
});

function HeaderText(props) {
  return (
    <Typography
      variant="h6"
      align="left"
      style={{ fontWeight: 300 }}
      {...props}
    />
  );
}

function SmallText(props) {
  return <Typography variant="body2" align="left" {...props} />;
}
function CaptionText(props) {
  return (
    <Typography
      variant="caption"
      component="div"
      align="right"
      style={{ fontWeight: 300, paddingRight: 8 }}
      {...props}
    />
  );
}
export class CareProfileHeader extends Component {
  state = { disabled: false };
  handleFav = () => {
    const { favourite, userIdNumber } = this.props;
    favourite.indexOf(userIdNumber) >= 0
      ? this.props.setUnfav(userIdNumber)
      : this.props.setFav(userIdNumber);
  };

  // Action functions
  handleMsgChn = () => {
    const {
      userIdNumber,
      credentials: { firstName, lastName, imgUrlThumb },
    } = this.props;
    let chnData = { firstName, lastName, imgUrlThumb };
    this.setState({ disabled: true }, () => {
      axios
        .post(`/message/new/${userIdNumber}`, chnData)
        .then((res) => {
          this.props.history.push(`/messages/${res.data.channelId}`);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  handleHireClick = () => {
    const { userIdNumber } = this.props;
    this.props.history.push(`/caregiver/${userIdNumber}/hire`);
    this.setState({ disabled: true });
  };

  render() {
    const { classes, credentials, favourite, userIdNumber } = this.props;
    const { disabled } = this.state;
    return (
      <Grid item xs={12}>
        <Paper elevation={4} className={classes.paperContainerD}>
          <Box display="flex" justifyContent="center">
            <Box className={classes.mediaBox}>
              <img
                src={credentials.imgUrlThumb}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = credentials.imgUrl;
                }}
                alt="Profile Pic"
                className={classes.media}
              />
              <TooltipButton
                tip={`${
                  favourite.indexOf(userIdNumber) >= 0 ? "Unf" : "F"
                }avourite Profile`}
                onClick={this.handleFav}
                btnClassName={classes.responsiveIconButton}
                size="small"
              >
                {favourite.indexOf(userIdNumber) >= 0 ? (
                  <FavoriteIcon color="secondary" />
                ) : (
                  <FavoriteBorderIcon color="secondary" />
                )}
              </TooltipButton>
            </Box>
          </Box>
          <Box
            className={classes.flexBoxCentered}
            style={{ marginTop: 4, flexDirection: "column" }}
          >
            <div
              className={classes.flexBoxCentered}
              style={{
                backgroundColor: "rgba(255,255,255,0.9)",
                padding: "0px 8px",
                marginBottom: 4,
                borderRadius: 15,
              }}
            >
              <Typography variant="h5">
                {capitalizeFirstChar(credentials.firstName)}{" "}
                {capitalizeFirstChar(credentials.lastName)}
              </Typography>
            </div>
            <div
              className={classes.flexBoxCentered}
              style={{
                backgroundColor: "rgba(255,255,255,0.9)",
                padding: "0px 8px",
                marginBottom: 4,
                borderRadius: 15,
                color: "#ffb300",
              }}
            >
              <Typography fontWeight="bold">
                {credentials.reviewScore > 0
                  ? credentials.reviewScore.toFixed(2)
                  : "N/A"}
              </Typography>
              <StarIcon />
            </div>

            <div
              className={classes.flexBoxCentered}
              style={{
                backgroundColor: "rgba(255,255,255,0.9)",
                padding: "0px 8px",
                marginBottom: 4,
                borderRadius: 15,
              }}
            >
              <Typography variant="h5">
                {capitalizeFirstChar(credentials.firstName)}{" "}
                {capitalizeFirstChar(credentials.lastName)}
              </Typography>
            </div>

            <div
              className={classes.flexBoxCentered}
              style={{
                backgroundColor: "rgba(255,255,255,0.9)",
                padding: "0px 8px",
                marginBottom: 4,
                borderRadius: 15,
              }}
            >
              <LocationOnIcon color="primary" />
              <Typography className={classes.typography}>
                {capitalizeFirstChar(
                  credentials.address.addressParams.locality
                )}
                {", "}
                {credentials.address.addressParams.adminAreaLevel1.toUpperCase()}
              </Typography>
            </div>
          </Box>

          <Grid item style={{ width: "100%" }}>
            <Button
              className={classes.txtTrButton}
              variant="contained"
              color="secondary"
              onClick={this.handleMsgChn}
              startIcon={<ChatIcon />}
              disabled={disabled}
            >
              Message
            </Button>
            <Button
              className={classes.txtTrButton}
              variant="contained"
              color="secondary"
              startIcon={<WorkIcon />}
              disabled={disabled}
              onClick={this.handleHireClick}
            >
              Hire
            </Button>
          </Grid>
        </Paper>

        <Paper
          elevation={4}
          className={classes.darkGrayBorder}
          style={{
            marginTop: 16,
            textAlign: "left",
          }}
        >
          <HeaderText className={classes.typography}>About Me</HeaderText>

          <Typography
            variant="body2"
            className={classes.typography}
            style={{ fontWeight: 300, whiteSpace: "pre-line" }}
          >
            {credentials.bio}
          </Typography>
          <Divider
            orientation="horizontal"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <Typography
            align="left"
            className={classes.typography}
            style={{ fontWeight: 300 }}
          >
            Hourly Rate
          </Typography>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <CaptionText className={classes.typography}>Wage</CaptionText>
            </Grid>
            <Grid item xs={8}>
              <SmallText className={classes.typography}>
                ${parseFloat(credentials.wageExp).toFixed(2)}
              </SmallText>
            </Grid>
            <Grid item xs={4}>
              <CaptionText className={classes.typography}>
                Taxes {"&"} Fees
              </CaptionText>
            </Grid>
            <Grid item xs={8} style={{ display: "flex" }}>
              <SmallText className={classes.typography}>
                ${(parseFloat(credentials.wageExp) * 0.2966).toFixed(2)}
              </SmallText>
              <Tooltip
                disableFocusListener
                enterTouchDelay={0}
                placement="right"
                title={
                  <Typography variant="caption">
                    Estimated based on payroll taxes of 9.66% in{" "}
                    {credentials.address.addressParams.adminAreaLevel1.toUpperCase()}{" "}
                    and 20% Poyo Fees (transaction, insurance,
                    platform/management).
                  </Typography>
                }
              >
                <HelpIcon color="inherit" style={{ fontSize: 12 }} />
              </Tooltip>
            </Grid>

            <Grid item xs={4}>
              <CaptionText
                className={classes.typography}
                style={{ fontWeight: 550, paddingRight: 8 }}
              >
                You Pay
              </CaptionText>
            </Grid>
            <Grid item xs={8} style={{ display: "flex" }}>
              <SmallText
                className={classes.typography}
                style={{ fontWeight: 550 }}
              >
                ${(parseFloat(credentials.wageExp) * 1.2966).toFixed(2)}
              </SmallText>
            </Grid>
          </Grid>
          <Divider
            orientation="horizontal"
            style={{ marginTop: 10, marginBottom: 10 }}
          />

          <Typography
            variant="body2"
            align="left"
            className={classes.typography}
            component="div"
            style={{ fontWeight: 300 }}
          >
            <Box style={{ fontWeight: 550 }} display="inline">
              Work Experience:{" "}
            </Box>{" "}
            {credentials.yearsExp} years
          </Typography>
          <Typography
            variant="body2"
            align="left"
            className={classes.typography}
            component="div"
            style={{ fontWeight: 300 }}
          >
            <Box style={{ fontWeight: 550 }} display="inline">
              Languages:{" "}
            </Box>{" "}
            {credentials.languagePref
              .map((lang) => capitalizeFirstChar(lang))
              .join(", ")}
          </Typography>
          {credentials.poyoJobs.length > 0 && (
            <Typography
              variant="body2"
              align="left"
              className={classes.typography}
              component="div"
              style={{ fontWeight: 300 }}
            >
              <Box style={{ fontWeight: 550 }} display="inline">
                Poyo Jobs:{" "}
              </Box>{" "}
              {credentials.poyoJobs.length} jobs completed with Poyo
            </Typography>
          )}
        </Paper>
      </Grid>
    );
  }
}

CareProfileHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  favourite: PropTypes.array.isRequired,
  userIdNumber: PropTypes.string.isRequired,
};

export default connect(null, { setFav, setUnfav })(
  withStyles(styles)(CareProfileHeader)
);
