import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";
import firebase from "../../Firebase";
import { Link } from "react-router-dom";
import MsgText from "./MsgText";

import "../../App.css";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SendIcon from "@mui/icons-material/Send";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BlockIcon from "@mui/icons-material/Block";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class ChatDisplay extends Component {
  state = {
    isLoading: true,
    msg: "",
    linkId: "",
    messages: [],
    chnData: {},
    isSubscribed: false,
    anchorEl: null,
    disableWhileLoad: false,
    lastDoc: null,
    isLoadingMore: false,
  };

  // Initial Load Methods

  componentDidMount() {
    this._isMounted = true;
    this.getMsgs();
    this.getUserInfo();
  }

  componentDidUpdate(prevProps) {
    const currChn = this.props.currChn;
    if (currChn !== prevProps.currChn) {
      this.resetMessageChannel();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  resetMessageChannel = () => {
    this.setState({ isLoading: true, messages: [] }, () => {
      if (this.unsubscribe) {
        this.unsubscribe();
      }

      this.getUserInfo();
      this.getMsgs();
    });
  };

  // Loading Methods

  getUserInfo = async () => {
    const {
      userType,
      currentMessageChannelData: { familyInfo, caregiverInfo },
    } = this.props;

    const db = firebase.firestore();

    let ref;
    if (userType === "family") {
      ref = db
        .collection("users")
        .where("userIdNumber", "==", caregiverInfo.userIdNumber)
        .limit(1);
    } else if (userType === "caregiver") {
      ref = db
        .collection("jobs")
        .where("familyInfo.userIdNumber", "==", familyInfo.userIdNumber)
        .limit(1);
    }
    try {
      const snapshot = await ref.get();
      snapshot.forEach((doc) => {
        this.setState({
          linkId: userType === "family" ? doc.data().userIdNumber : doc.id,
        });
      });
    } catch (err) {
      return;
    }
  };

  getMsgs = () => {
    const currChn = this.props.currChn;

    const db = firebase.firestore();
    const colRef = db
      .collection("messageChannel")
      .doc(`${currChn}`)
      .collection("messages");

    const that = this;

    // Realtime updates listener
    this.unsubscribe = colRef
      .orderBy("createdAt")
      .limitToLast(50)
      .onSnapshot((snapshot) => {
        const newMessages = snapshot.docs.map((doc) => {
          let res = doc.data();
          res.id = doc.id;
          return res;
        });

        // Set lastDoc for pagniation
        let lastDoc;
        if (
          !this.state.isSubscribed &&
          newMessages.length > 0 &&
          snapshot.size === 50
        )
          lastDoc = snapshot.docs[0];
        else lastDoc = this.state.lastDoc;

        /*  Finds first new message via snapshot. 
            Modifies state messages array to remove all existing 
            copies of snapshot messages. Returns new array with 
            unique messages only. */

        let firstMsgIndex = -1;
        let _messages = this.state.messages;
        if (newMessages.length > 0)
          firstMsgIndex = this.state.messages.findIndex(
            (msg) => msg.id === newMessages[0].id
          );
        if (firstMsgIndex > -1) _messages = _messages.splice(0, firstMsgIndex);

        _messages = [..._messages, ...newMessages];

        // Mark unread messages read as loaded
        let unreadMessages = [];
        snapshot.docs.forEach((doc) => {
          let res = doc.data();
          res.id = doc.id;
          if (!res.isRead) {
            return unreadMessages.push(res);
          }
          return false;
        });
        if (unreadMessages.length > 0) {
          axios
            .post(`/message/${currChn}/mark-read`, unreadMessages)
            .catch((err) => console.error(err));
        }

        that.setState(
          {
            messages: _messages,
            isLoading: false,
            isSubscribed: true,
            lastDoc,
          },
          () => {
            that.scrollToBottom();
          }
        );
      });
  };

  handleLoadOlderMessages = async () => {
    await this.setStateAsync({ isLoadingMore: true });

    const currChn = this.props.currChn;

    const db = firebase.firestore();
    const colRef = db
      .collection("messageChannel")
      .doc(`${currChn}`)
      .collection("messages")
      .orderBy("createdAt");

    try {
      const messagesQuery = await colRef
        .endBefore(this.state.lastDoc)
        .limitToLast(25)
        .get();

      const _messages = [];
      messagesQuery.forEach((doc) => {
        let res = doc.data();
        res.id = doc.id;
        _messages.push(res);
      });

      const lastDoc = messagesQuery.docs[0];

      const messages = [..._messages, ...this.state.messages];

      this.setState({ messages, lastDoc });
    } catch (err) {
      console.error(err);
    } finally {
      await this.setStateAsync({ isLoadingMore: false });
    }
  };

  // UI Methods
  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  // Action methods

  onKeyPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      e.preventDefault();
      let start = e.target.selectionStart,
        end = e.target.selectionEnd;
      this.setState((prevState) => ({
        msg:
          prevState.msg.substring(0, start) +
          "\n" +
          prevState.msg.substring(end),
      }));
    } else if (e.keyCode === 13) {
      this.handleSubmit(e);
      e.preventDefault();
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { msg } = this.state;
    const currChn = this.props.currChn;
    this.setState({ msg: "" });

    if (msg.trim() !== "") {
      axios
        .post(`/message/${currChn}`, { body: msg.trim() })
        .catch((err) => console.error(err));
    }
  };

  applyBlock = async () => {
    await this.setStateAsync({ disableWhileLoad: true });
    const {
      currentMessageChannelData: { blockedBy },
      myId,
      currChn,
    } = this.props;

    try {
      await axios.get(
        `/message/${currChn}/${blockedBy.includes(myId) ? "unblock" : "block"}`
      );
    } catch (err) {
      return;
    } finally {
      await this.handleClose();
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const { classes, myId, userType, currentMessageChannelData } = this.props;
    const {
      isLoading,
      msg,
      messages,
      linkId,
      anchorEl,
      isLoadingMore,
      lastDoc,
      isSubscribed,
    } = this.state;

    const msgMarkup =
      messages.length > 0 ? (
        messages.map((msg, index) => {
          if (index === 0) {
            return (
              <div key={msg.id} style={{ width: "100%", marginTop: 10 }}>
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                  style={{ paddingTop: 10 }}
                >
                  {dayjs(msg.createdAt).format("ddd MMM D, YYYY")}
                </Typography>
                <MsgText
                  currentMessageChannelData={currentMessageChannelData}
                  myMsg={msg.userIdNumber === myId}
                  msg={msg}
                />
              </div>
            );
          } else {
            return (
              <Fragment key={msg.id}>
                {dayjs(messages[index].createdAt).diff(
                  messages[index - 1].createdAt,
                  "day"
                ) >= 1 && (
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                  >
                    {dayjs(msg.createdAt).format("ddd MMM D, YYYY")}
                  </Typography>
                )}
                <MsgText
                  currentMessageChannelData={currentMessageChannelData}
                  userType={userType}
                  myMsg={msg.userIdNumber === myId}
                  msg={msg}
                />
              </Fragment>
            );
          }
        })
      ) : (
        <Typography variant="body2" align="center" style={{ marginTop: 20 }}>
          You are now connected with{" "}
          {userType === "family"
            ? currentMessageChannelData.caregiverInfo.firstName
            : userType === "caregiver"
            ? currentMessageChannelData.familyInfo.firstName
            : null}{" "}
          {userType === "family"
            ? currentMessageChannelData.caregiverInfo.lastName
            : userType === "caregiver"
            ? currentMessageChannelData.familyInfo.lastName
            : null}
          .
        </Typography>
      );

    return (
      <Box
        display="flex"
        flexDirection="column"
        style={{ width: "100%", height: "100%", position: "relative" }}
      >
        <Box
          style={{
            width: "100%",
            height: 50,
            borderBottom: "1px solid rgb(245,245,245,1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            alt={`${
              userType === "family"
                ? currentMessageChannelData.caregiverInfo.firstName
                : userType === "caregiver"
                ? currentMessageChannelData.familyInfo.firstName
                : null
            } ${
              userType === "family"
                ? currentMessageChannelData.caregiverInfo.lastName
                : userType === "caregiver"
                ? currentMessageChannelData.familyInfo.lastName
                : null
            }`}
            src={
              userType === "family"
                ? currentMessageChannelData.caregiverInfo.imgUrlThumb
                : userType === "caregiver"
                ? currentMessageChannelData.familyInfo.imgUrlThumb
                : null
            }
            className={classes.imgThumb}
            style={{ margin: "5px 0 5px 0" }}
          />
          <Typography variant="h6" style={{ marginLeft: 10 }}>
            {userType === "family"
              ? currentMessageChannelData.caregiverInfo.firstName
              : userType === "caregiver"
              ? currentMessageChannelData.familyInfo.firstName
              : null}{" "}
            {userType === "family"
              ? currentMessageChannelData.caregiverInfo.lastName
              : userType === "caregiver"
              ? currentMessageChannelData.familyInfo.lastName
              : null}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            style={{ position: "absolute", top: 0, right: 0, height: 50 }}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                component={Link}
                to={
                  userType === "caregiver"
                    ? `/job/${linkId}`
                    : `/caregiver/${linkId}`
                }
                onClick={this.handleClose}
                style={{ display: "flex", alignItems: "center" }}
              >
                {userType === "family" ? (
                  <PersonIcon color="primary" />
                ) : (
                  <WorkIcon color="primary" />
                )}
                <Typography style={{ marginLeft: 5 }}>
                  {userType === "family" ? "View Profile" : "View Job"}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={this.applyBlock}
                style={{ display: "flex", alignItems: "center" }}
              >
                {currentMessageChannelData.blockedBy.includes(myId) ? (
                  <BlockIcon color="disabled" />
                ) : (
                  <BlockIcon color="secondary" />
                )}
                <Typography style={{ marginLeft: 5 }}>
                  {currentMessageChannelData.blockedBy.includes(myId)
                    ? "Unblock"
                    : "Block"}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {!isLoading && currentMessageChannelData.blockedBy.length > 0 && (
          <Typography
            component="div"
            align="center"
            variant="caption"
            color="error"
          >
            {currentMessageChannelData.blockedBy.includes(myId)
              ? "This person has been blocked. Messages can no longer be sent or recived until unblocked by you."
              : "You have been blocked and are unable to send messages."}
          </Typography>
        )}
        <Box
          className="hideScroll"
          style={{
            overflow: "scroll",
            flex: 1,
            width: "100%",
          }}
        >
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress thickness={2} size={200} />
            </div>
          ) : (
            <React.Fragment>
              <Box className={classes.flexBoxCentered}>
                {Boolean(lastDoc) && isSubscribed && (
                  <Button
                    onClick={this.handleLoadOlderMessages}
                    color="primary"
                    style={{ padding: "4px 8px" }}
                    className={classes.txtTrButton}
                    disabled={isLoadingMore}
                    fullWidth
                  >
                    Load Older Messages
                  </Button>
                )}
              </Box>
              {msgMarkup}
            </React.Fragment>
          )}
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </Box>
        <Divider style={{ margin: "2px 0px 2px 0px" }} />
        <form
          id="sendBar"
          onSubmit={this.handleSubmit}
          style={{
            width: "100%",
            padding: "4px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TextField
            variant="outlined"
            id="msg"
            onChange={this.handleChange}
            onKeyDown={this.onKeyPress}
            name="msg"
            size="small"
            value={msg}
            className={classes.styledTextField}
            disabled={currentMessageChannelData.blockedBy.length > 0}
            style={{ flex: 1, margin: 0 }}
            multiline
            minRows={1}
            maxRows={5}
            placeholder="Aa"
          />
          <Hidden smDown>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              disabled={currentMessageChannelData.blockedBy.length > 0}
              style={{
                width: 100,
                height: 40,
                marginLeft: 5,
                borderRadius: 10,
              }}
            >
              Send
            </Button>
          </Hidden>
          <Hidden smUp>
            <IconButton
              type="submit"
              color="primary"
              disabled={currentMessageChannelData.blockedBy.length > 0}
              size="small"
              style={{
                marginLeft: 5,
              }}
            >
              <SendIcon />
            </IconButton>
          </Hidden>
        </form>
      </Box>
    );
  }
}

ChatDisplay.propTypes = {
  currChn: PropTypes.string.isRequired,
  myId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  currentMessageChannelData: PropTypes.object,
};

export default withStyles(styles)(ChatDisplay);
