import React, { Component } from "react";

import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

import curve1 from "../../../assets/images/curve.svg";
import curve2 from "../../../assets/images/curve-2.svg";
import Values from "./component/Values";
import Story from "./component/Story";
import GetStarted from "../home/components/GetStarted";

import Typography from "@mui/material/Typography";

export class about extends Component {
  componentDidMount() {
    store.dispatch({ type: SET_PAGE, payload: "Home" });
  }

  componentWillUnmount() {
    store.dispatch({ type: UNSET_PAGE });
  }

  render() {
    return (
      <div className="page-wrapper" style={{ marginTop: -58 }}>
        <section className="full-vw-container hero-about-us">
          <div
            className="flexbox-centered-column contrast-txt text-center"
            style={{ maxWidth: 1100, height: "100%" }}
          >
            <div className="hero-padding">
              <Typography
                variant="h1"
                className="txt-bold txt-shadow"
                style={{ paddingBottom: 16 }}
              >
                We are Poyo Health
              </Typography>
              <Typography variant="h4" className="description-txt">
                Helping you stay independent at home
              </Typography>
            </div>
          </div>
          <div className="curves">
            <img
              src={curve1}
              alt="curve1"
              draggable="false"
              className="curve"
            />
            <img
              src={curve2}
              alt="curve2"
              draggable="false"
              className="curve"
            />
          </div>
        </section>

        <section id="story" className="stn-margin">
          <Story />
        </section>

        <section id="mission" className="stn-margin">
          <Typography variant="h3" className="txt-bold">
            Mission
          </Typography>
          <div className="title-line" />
          <Typography variant="h4" className="description-txt">
            Helping you stay independent at home with personalized, transparent,
            and affordable care.
          </Typography>
        </section>

        <section id="values" className=" comp-advan stn-margin">
          <Values />
        </section>

        <section className="get-started full-vw-container stn-margin">
          <GetStarted />
        </section>
      </div>
    );
  }
}

export default about;
