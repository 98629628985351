import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AuthFamRoute = ({
  component: Component,
  authenticated,
  userType,
  loading,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !authenticated ? (
        <Redirect to="/login" />
      ) : userType === "family" ? (
        <Component {...props} />
      ) : (
        <Redirect to="/dashboard" />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  userType: state.user.credentials.userType,
  loading: state.user.loading,
});

AuthFamRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  userType: PropTypes.string,
};

export default connect(mapStateToProps)(AuthFamRoute);
