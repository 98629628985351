import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Component imports
import NotificationMarkup from "../../Components/Notification/NotificationMarkup";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Popover from "@mui/material/Popover";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class NotificationButton extends Component {
  state = { anchorEl: null };

  handleOpen = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = (e) => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { anchorEl } = this.state;
    const { isSubscribed, notifications } = this.props;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const unreadNotifications = notifications.filter(
      (notification) => !notification.read
    );

    return (
      <Fragment>
        <IconButton
          aria-label="notifications"
          color="inherit"
          onClick={this.handleOpen}
          size="large"
        >
          <Badge
            badgeContent={isSubscribed ? unreadNotifications.length : null}
            color="error"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          onClose={this.handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              maxHeight: "calc(100vh - 20%)",
              width: 360,
              maxWidth: "85vw",
            },
          }}
        >
          <NotificationMarkup onClickLink={this.handleClose} />
        </Popover>
      </Fragment>
    );
  }
}

NotificationButton.propTypes = {
  isSubscribed: PropTypes.bool,
  notifications: PropTypes.array.isRequired,
  sortedNotifications: PropTypes.object,
};

export default withStyles(styles)(NotificationButton);
