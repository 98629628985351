import {
  SET_USER,
  SET_USER_QUICK,
  SET_CREDENTIALS,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  ADD_WORKEXP,
  EDIT_WORKEXP,
  DELETE_WORKEXP,
  ADD_EDUACCRED,
  EDIT_EDUACCRED,
  DELETE_EDUACCRED,
  FINISH_EDUACCRED,
  UPDATE_PROFILE_IMG,
  UPLOAD_BACKGROUND_CHECK,
  FINISH_USER,
  SET_BANKING,
  SET_FAV,
  SET_AVAIL,
  SET_NOTIF,
  CLEAR_NOTIF,
  SET_SIGNUPSUCCESS,
  SET_FAVOURITE_DOC,
  SET_PREFERRED_PAYMENT_METHOD,
} from "../types";

const initialState = {
  authenticated: false,
  loading: false,
  loadedUser: false,
  isSuccessSignup: false,
  credentials: {},
  workExp: [],
  eduAccred: [],
  notifications: [],
  bankingDetails: {},
  favourite: [],
  favouritesDoc: [],
  lastKey: null,
  isLoadedNotifications: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        ...action.payload,
      };
    case SET_USER_QUICK:
      return {
        ...state,
        authenticated: true,
        loading: false,
        credentials: action.payload,
      };
    case SET_SIGNUPSUCCESS:
      return {
        ...state,
        isSuccessSignup: true,
      };
    case ADD_WORKEXP: {
      let mutatedWorkExp = state.workExp;
      mutatedWorkExp.push(action.payload);
      mutatedWorkExp.sort((a, b) =>
        a.startDate < b.startDate ? 1 : b.startDate < a.startDate ? -1 : 0
      );
      return {
        ...state,
        workExp: [...mutatedWorkExp],
      };
    }
    case EDIT_WORKEXP: {
      let mutatedWorkExp = state.workExp;
      let index = mutatedWorkExp.findIndex(
        (exp) => exp.workExpId === action.payload.workExpId
      );
      mutatedWorkExp[index] = action.payload;
      return {
        ...state,
        workExp: [...mutatedWorkExp],
      };
    }
    case DELETE_WORKEXP: {
      let index = state.workExp.findIndex(
        (exp) => exp.workExpId === action.payload
      );
      let mutatedWorkExp = state.workExp;
      mutatedWorkExp.splice(index, 1);
      return {
        ...state,
        workExp: [...mutatedWorkExp],
      };
    }
    case ADD_EDUACCRED: {
      let mutatedEduAccred = state.eduAccred;
      mutatedEduAccred.push(action.payload);
      mutatedEduAccred.sort((a, b) =>
        a.endDate && b.endDate && a.endDate < b.endDate
          ? 1
          : a.endDate && b.endDate && b.endDate < a.endDate
          ? -1
          : !a.endDate && b.endDate
          ? 1
          : a.endDate && !b.endDate
          ? -1
          : a.accredDate && b.accredDate && a.accredDate < b.accredDate
          ? 1
          : a.accredDate && b.accredDate && b.accredDate < a.accredDate
          ? -1
          : !a.accredDate && b.accredDate
          ? 1
          : a.accredDate && !b.accredDate
          ? -1
          : 0
      );
      return {
        ...state,
        eduAccred: [...mutatedEduAccred],
      };
    }
    case EDIT_EDUACCRED: {
      let mutatedEduAccred = state.eduAccred;
      let index = mutatedEduAccred.findIndex(
        (exp) => exp.eduAccredId === action.payload.eduAccredId
      );
      mutatedEduAccred[index] = action.payload;
      return {
        ...state,
        eduAccred: [...mutatedEduAccred],
      };
    }
    case DELETE_EDUACCRED: {
      let index = state.eduAccred.findIndex(
        (exp) => exp.eduAccredId === action.payload
      );
      let mutatedEduAccred = state.eduAccred;
      mutatedEduAccred.splice(index, 1);
      return {
        ...state,
        eduAccred: [...mutatedEduAccred],
      };
    }
    case FINISH_EDUACCRED:
      return {
        ...state,
        // credentials: {
        //   ...state.credentials,
        //   ...action.payload,
        // },
      };
    case UPDATE_PROFILE_IMG:
      return {
        ...state,
        // credentials: {
        //   ...state.credentials,
        //   imgUrl: action.payload.imgUrl,
        //   imgUrlThumb: action.payload.imgUrlThumb,
        // },
      };
    case UPLOAD_BACKGROUND_CHECK:
      return {
        ...state,
        // credentials: {
        //   ...state.credentials,
        //   ...action.payload,
        // },
      };
    case FINISH_USER:
      return {
        ...state,
        // credentials: {
        //   ...state.credentials,
        //   ...action.payload,
        // },
      };
    case SET_BANKING:
      return {
        ...state,
        bankingDetails: action.payload,
      };
    case SET_FAV:
      return {
        ...state,
        favourite: action.payload.favArr,
      };
    case SET_FAVOURITE_DOC: {
      let isAdd = action.payload.add;
      let doc = action.payload.doc;
      let _favouritesDoc = state.favouritesDoc;

      if (isAdd) {
        _favouritesDoc.push(doc);
      } else {
        let pos = _favouritesDoc.findIndex(
          (k) => k.id === action.payload.docId
        );
        _favouritesDoc.splice(pos, 1);
      }

      return {
        ...state,
        favouritesDoc: _favouritesDoc,
      };
    }
    case SET_AVAIL:
      return {
        ...state,
        // credentials: {
        //   ...state.credentials,
        //   myAvail: action.payload.myAvail,
        //   isAvailComplete: action.payload.isAvailComplete,
        //   daysAvail: action.payload.daysAvail,
        // },
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case SET_NOTIF:
      return {
        ...state,
        isLoadedNotifications: true,
        notifications: action.payload.notifArr,
        lastKey: action.payload.lastKey,
      };
    case CLEAR_NOTIF:
      return {
        ...state,
        notifications: [],
        lastKey: null,
      };
    case SET_PREFERRED_PAYMENT_METHOD:
      return {
        ...state,
        // credentials: {
        //   ...state.credentials,
        //   primaryPaymentMethod: action.payload,
        // },
      };
    default:
      return state;
  }
}
