import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import LoginForm from "../../../../pages/loginSignup/components/LoginForm";

function SignInButtonDialog() {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Button onClick={handleOpen} color="inherit" className="link-btn">
        <Typography variant="subtitle2" color="inherit">
          Sign In
        </Typography>
      </Button>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="xs">
        <div
          className="login-container text-center"
          style={{ borderRadius: 0 }}
        >
          <LoginForm />
        </div>
      </Dialog>
    </>
  );
}

export default SignInButtonDialog;
