import {
  SET_JOB,
  SET_SHIFTS,
  SET_QUERY_RESULTS,
  SET_FILTERED_RESULTS,
  UPDATE_SINGLE_QUERY,
  SET_TEAMS,
  CLEAR_DATA,
  UNSET_QUERY_LOADED,
  SET_DASH_DATA,
  UPDATE_DASH_DATA,
  UPDATE_DASH_ARRAY,
  SPLICE_DASH_ARRAY,
  UPDATE_DASH_ARRAY_BY_ID,
} from "../types";

const initialState = {
  job: {},
  queryLoaded: false,
  queryResults: [],
  filteredResults: [],

  teamsObj: {},
  caregivers: [],
  isLoadedCaregivers: false,
  teamInfo: {},
  members: [],
  isLoadedTeams: false,
  jobs: [],
  isLoadedJobs: false,
  jobApplications: [],
  isLoadedJobApplications: false,
  messageChannels: [],
  isLoadedMsgChn: false,

  teamInvites: [],
  isLoadedTeamInv: false,
  weeklyShifts: [],
  firstDateLoaded: "",
  numFirstDateLoaded: 0,
  shifts: [],
  isLoadedShifts: false,
  dashShifts: [],
  isLoadedDashShifts: false,
  tasks: [],
  isLoadedTasks: false,
  invoices: [],
  isInvoiceLoaded: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case UNSET_QUERY_LOADED:
      return {
        ...state,
        queryLoaded: false,
      };

    case SET_JOB:
      return {
        ...state,
        job: action.payload,
      };
    case SET_SHIFTS:
      return {
        ...state,
        shifts: action.payload,
        isLoadedShifts: true,
      };

    case SET_TEAMS:
      return {
        ...state,
        teamsObj: action.payload,
        isLoadedTeams: true,
      };
    case SET_QUERY_RESULTS:
      return {
        ...state,
        queryLoaded: true,
        queryResults: action.payload.queryResults,
        isInitial: action.payload.isInitial,
      };
    case SET_FILTERED_RESULTS:
      return {
        ...state,
        filteredResults: action.payload,
      };
    case UPDATE_SINGLE_QUERY:
      let _filteredResults = state.filteredResults;
      let _queryResults = state.queryResults;

      let posFilter = _filteredResults.findIndex(
        (filteredResult) => filteredResult.jobId === action.payload.id
      );
      let posQuery = _queryResults.findIndex(
        (queryResult) => queryResult.jobId === action.payload.id
      );

      if (posFilter > -1) {
        _filteredResults[posFilter] = {
          ..._filteredResults[posFilter],
          ...action.payload.update,
        };
      }

      if (posQuery > -1) {
        _queryResults[posQuery] = {
          ..._queryResults[posQuery],
          ...action.payload.update,
        };
      }
      return {
        ...state,
        filteredResults: _filteredResults,
        queryResults: _queryResults,
      };

    case SET_DASH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_DASH_DATA: {
      const key = action.payload.key;
      const data = { ...action.payload };
      delete data.key;

      return {
        ...state,
        [key]: data,
      };
    }
    case UPDATE_DASH_ARRAY: {
      const key = action.payload.key;
      const index = action.payload.index;
      const data = { ...action.payload };
      delete data.key;
      delete data.index;

      let dataArray = state[key];
      if (index >= 0) dataArray[index] = data;

      return {
        ...state,
        [key]: dataArray,
      };
    }
    case UPDATE_DASH_ARRAY_BY_ID: {
      const key = action.payload.key;
      const id = action.payload.id;
      const data = { ...action.payload };
      delete data.key;

      const dataArray = state[key];

      const pos = dataArray.findIndex((data) => data.id === id);

      if (pos > -1) dataArray[pos] = data;

      return {
        ...state,
        [key]: dataArray,
      };
    }
    case SPLICE_DASH_ARRAY: {
      const key = action.payload.key;
      const id = action.payload.id;

      const dataArray = state[key];
      const pos = dataArray.findIndex((data) => data.id === id);
      if (pos > -1) {
        dataArray.splice(pos, 1);
      }

      return {
        ...state,
        [key]: dataArray,
      };
    }
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}
