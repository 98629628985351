import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AuthRoute = ({
  component: Component,
  authenticated,
  userType,
  credentials,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !authenticated ? (
        <Redirect to="/login" />
      ) : userType === "family" ? (
        <Component {...props} />
      ) : userType === "caregiver" && !credentials.status.isBasicCompleted ? (
        <Redirect to="/caregiver/signup/basic-info" />
      ) : userType === "caregiver" && !credentials.status.isServiceCompleted ? (
        <Redirect to="/caregiver/signup/services" />
      ) : userType === "caregiver" &&
        !credentials.status.isEduAccredCompleted ? (
        <Redirect to="/caregiver/signup/education-experience" />
      ) : userType === "caregiver" && !credentials.status.isProfileCompleted ? (
        <Redirect to="/caregiver/signup/finish" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  userType: state.user.credentials.userType,
  credentials: state.user.credentials,
});

AuthRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  userType: PropTypes.string,
  credentials: PropTypes.object,
};

export default connect(mapStateToProps)(AuthRoute);
