import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";

// Components imports
import { arrayHasSameElements } from "../../../util/utilFunctions";
import SearchHeader from "./components/SearchHeader";
import SearchFilters from "./components/SearchFilters";
import CareProfile from "../../../components/profile/family/caregiverSearch/CareProfile";
import Geocode from "react-geocode";
import geohash from "ngeohash";
import { languages } from "../../../util/utilConst";

// Redux imports
import { connect } from "react-redux";
import {
  queryCg,
  setFilteredResults,
} from "../../../redux/actions/dataActions";
import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Fade from "@mui/material/Fade";

// Icons Imports
import SearchIcon from "@mui/icons-material/Search";

Geocode.setApiKey("AIzaSyBS_m4APPDCbm_1xqD3lPllrFbO-_8m2es");
Geocode.setLanguage("en");
Geocode.setRegion("ca");

const styles = (theme) => ({
  ...theme.spreadThis,

  reactiveJustifyGrid: {
    justifyContent: "flex-start",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  linkText: {
    textDecoration: "underline",
    fontWeight: 350,
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: 550,
    },
  },
  iconTextSecondary: {
    fontSize: 56,
    color: theme.palette.text.secondary,
  },
});

export class cgSearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      locality: this.props.credentials.address.addressParams.locality,
      adminAreaLevel1:
        this.props.credentials.address.addressParams.adminAreaLevel1,
      geohash: "",
      service: [],
      conditions: [],
      jobType: "",
      gender: "",
      wageExp: "",
      wageRange: { lower: 0, upper: 100 },
      languagePref: "",
      yearsExp: "",
      rating: 0,
      pageNum: 1,
      isInitial: true,
      currFilteredRes: [],
      totalFilteredResults: 0,
      avgWage: "0",
      isSetFilteredResults: false,
      currentQueryParams: {
        geohash: "",
        locality: this.props.credentials.address.addressParams.locality,
        adminAreaLevel1:
          this.props.credentials.address.addressParams.adminAreaLevel1,
      },
      filterMaterials: {},
      isLoadedFilters: false,
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    store.dispatch({ type: SET_PAGE, payload: "Caregivers" });

    this.splitQuery();
    this.getFilterMaterials();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.queryLoaded && !prevProps.data.queryLoaded) {
      this.filterSearchResults();
    }
    if (this.props.match.params.cgQuery !== prevProps.match.params.cgQuery) {
      this.splitQuery();
    }
  }

  componentWillUnmount() {
    store.dispatch({ type: UNSET_PAGE });
  }

  async splitQuery() {
    const cgQuery = this.props.match.params.cgQuery;

    let queryData = {
      geohash: "",
      locality: this.props.credentials.address.addressParams.locality,
      adminAreaLevel1:
        this.props.credentials.address.addressParams.adminAreaLevel1,
      wageRange: { lower: 0, upper: 100 },
    };
    if (cgQuery) {
      if (cgQuery.includes("gender=")) {
        queryData.gender = cgQuery.split("gender=")[1].split("&")[0];
      }
      if (cgQuery.includes("language=")) {
        queryData.languagePref = cgQuery.split("language=")[1].split("&")[0];
      }
      if (cgQuery.includes("lower=")) {
        queryData.wageRange.lower = cgQuery.split("lower=")[1].split("&")[0];
      }
      if (cgQuery.includes("upper=")) {
        queryData.wageRange.upper = cgQuery.split("upper=")[1].split("&")[0];
      }
      if (cgQuery.includes("job-type=")) {
        queryData.jobType = cgQuery.split("job-type=")[1].split("&")[0];
      }
      if (cgQuery.includes("rating=")) {
        queryData.rating = parseInt(cgQuery.split("rating=")[1].split("&")[0]);
      }
      if (cgQuery.includes("page=")) {
        queryData.pageNum = cgQuery.split("page=")[1].split("&")[0];
      }
      if (cgQuery.includes("locality=") && cgQuery.includes("region=")) {
        let locality = cgQuery.split("locality=")[1].split("&")[0];
        let adminAreaLevel1 = cgQuery.split("region=")[1].split("&")[0];
        queryData.locality = locality;
        queryData.adminAreaLevel1 = adminAreaLevel1;
        try {
          const response = await Geocode.fromAddress(
            `${locality}, ${adminAreaLevel1}`
          );

          const { lat, lng } = response.results[0].geometry.location;
          queryData.geohash = geohash.encode(lat, lng);
        } catch (err) {
          console.error(err);
        }
      }
      queryData.isInitial = false;
    } else {
      queryData = {
        ...queryData,
        locality: this.props.credentials.address.addressParams.locality,
        adminAreaLevel1:
          this.props.credentials.address.addressParams.adminAreaLevel1,
        geohash: "",
        service: [],
        conditions: [],
        jobType: "",
        gender: "",
        wageExp: "",
        wageRange: { lower: 0, upper: 100 },
        languagePref: "",
        yearsExp: "",
        pageNum: 1,
      };
    }

    // Checks if query has already been loaded and queryParams matches currentQueryParams

    if (
      !this.props.data.queryLoaded ||
      queryData.geohash !== this.state.currentQueryParams.geohash
    ) {
      await this.setStateAsync({
        ...queryData,
        currentQueryParams: {
          geohash: queryData.geohash,
          locality: queryData.locality,
          adminAreaLevel1: queryData.adminAreaLevel1,
        },
      });
      this.props.queryCg({ geohash: queryData.geohash });
    } else if (this.props.data.queryLoaded) {
      // Reloads filteredResults if query already loaded and no new query completed
      await this.setStateAsync({
        ...queryData,
        currentQueryParams: {
          geohash: queryData.geohash,
          locality: queryData.locality,
          adminAreaLevel1: queryData.adminAreaLevel1,
        },
      });
      await this.filterSearchResults();
    }
  }

  async getFilterMaterials() {
    const filterMaterials = await axios.get("/signup-material");
    const _filterMaterials = filterMaterials.data;
    _filterMaterials.language = languages;

    this.setState({
      filterMaterials: _filterMaterials,
      isLoadedFilters: true,
    });
  }

  // Filter query results based on state

  async filterSearchResults() {
    const { queryResults } = this.props.data;
    const {
      service,
      conditions,
      gender,
      languagePref,
      wageRange,
      rating,
      pageNum,
      jobType,
      yearsExp,
    } = this.state;

    let _queryResults = queryResults;

    if (yearsExp !== "") {
      _queryResults = _queryResults.filter(
        (cg) => cg.yearsExp >= parseInt(yearsExp)
      );
    }
    if (rating > 0) {
      _queryResults = _queryResults.filter(
        (cg) => cg.reviews.length > 0 || cg.reviewsScore >= parseInt(rating)
      );
    }
    if (jobType !== "") {
      _queryResults = _queryResults.filter((cg) => cg.jobType[jobType]);
    }
    if (languagePref !== "") {
      let value = Object.keys(languages).find(
        (lang) => languages[lang] === languagePref
      );

      _queryResults = _queryResults.filter(
        (cg) => cg.languagePref.indexOf(value) > -1
      );
    }

    _queryResults = _queryResults.filter(
      (cg) =>
        parseFloat(cg.wageExp) >= parseFloat(wageRange.lower) &&
        parseFloat(cg.wageExp) <= parseFloat(wageRange.upper)
    );

    if (gender !== "") {
      _queryResults = _queryResults.filter((cg) => cg.gender === gender);
    }

    if (conditions.length > 0) {
      _queryResults = _queryResults.filter((cg) =>
        arrayHasSameElements(conditions, Object.keys(cg.conditions))
      );
    }
    if (service.length > 0) {
      _queryResults = _queryResults.filter((cg) =>
        arrayHasSameElements(service, Object.keys(cg.service))
      );
    }

    const totalFilteredResults = _queryResults.length;
    let avgWage = 0;
    _queryResults.forEach((cg) => (avgWage += parseFloat(cg.wageExp)));
    avgWage = avgWage / totalFilteredResults;
    if (isNaN(avgWage)) {
      avgWage = 0;
    }

    this.props.setFilteredResults(_queryResults);

    const currFilteredRes = _queryResults.slice(
      parseInt(pageNum) * 20 - 20,
      parseInt(pageNum) * 20
    );

    this.setState({
      totalFilteredResults,
      avgWage,
      currFilteredRes,
      isSetFilteredResults: true,
    });
  }

  handleReplaceQuery = () => {
    const {
      locality,
      adminAreaLevel1,
      gender,
      jobType,
      wageExp,
      rating,
      wageRange,
      languagePref,
      pageNum,
    } = this.state;

    let searchRedirectLink = "/caregivers/search/";
    if (locality !== "" && locality !== "any")
      searchRedirectLink = searchRedirectLink + `&locality=${locality}`;
    if (adminAreaLevel1 !== "" && adminAreaLevel1 !== "any")
      searchRedirectLink = searchRedirectLink + `&region=${adminAreaLevel1}`;

    if (gender !== "" && gender !== "any")
      searchRedirectLink = searchRedirectLink + `&gender=${gender}`;
    if (wageExp !== "" && wageExp !== "any")
      searchRedirectLink =
        searchRedirectLink +
        `&lower=${wageRange.lower}` +
        `&upper=${wageRange.upper}`;
    if (languagePref !== "" && languagePref !== "any")
      searchRedirectLink =
        searchRedirectLink + `&language=${languages[languagePref]}`;
    if (jobType !== "" && jobType !== "any")
      searchRedirectLink = searchRedirectLink + `&job-type=${jobType}`;
    if (rating > 0)
      searchRedirectLink = searchRedirectLink + `&rating=${rating}`;
    if (pageNum !== "1")
      searchRedirectLink = searchRedirectLink + `&page=${pageNum}`;

    if (this.props.match.params.cgQuery !== searchRedirectLink) {
      this.props.history.replace({
        pathname: searchRedirectLink,
      });
      this.setState({ isSetFilteredResults: false });
    }
  };

  // Filter Select Methods

  handleSelect = async (name, value) => {
    await this.setStateAsync({ [name]: value });
    if (name === "wageExp") {
      let lower = 0,
        upper = 100;
      if (value !== "") {
        lower = value.split("u")[0];
        upper = value.split("u")[1].split("d")[0];
      }
      await this.setStateAsync({
        wageRange: {
          lower,
          upper,
        },
      });
    }
    await this.setStateAsync({ isSetFilteredResults: false });

    await this.handleReplaceQuery();
  };

  handleSelectService = async (value) => {
    const { service } = this.state;
    let _service = service;
    if (value === "any") {
      _service = [];
    } else {
      if (_service.includes(value)) {
        let pos = _service.indexOf(value);
        _service.splice(pos, 1);
      } else {
        _service.push(value);
        _service = _service.sort();
      }
    }
    await this.setStateAsync({ isSetFilteredResults: false });

    await this.setStateAsync({ service: _service });
    await this.filterSearchResults();
  };
  handleSelectConditions = async (value) => {
    const { conditions } = this.state;
    let _conditions = conditions;

    if (value === "any") {
      _conditions = [];
    } else {
      if (_conditions.includes(value)) {
        let pos = _conditions.indexOf(value);
        _conditions.splice(pos, 1);
      } else {
        _conditions.push(value);
        _conditions = _conditions.sort();
      }
    }
    await this.setStateAsync({ isSetFilteredResults: false });

    await this.setStateAsync({ conditions: _conditions });
    await this.filterSearchResults();
  };

  clearFilters = async () => {
    await this.setStateAsync({
      service: [],
      conditions: [],
      jobType: "",
      gender: "",
      wageExp: "",
      languagePref: "",
      yearsExp: "",
      rating: 0,
    });
    await this.handleReplaceQuery();
  };

  handlePageChange = async (e, v) => {
    const { pageNum } = this.state;
    if (parseInt(pageNum) !== v) {
      await this.setStateAsync({ pageNum: v });
      this.handleReplaceQuery();
    }
  };

  handleAddress = (value) => {
    this.setState({
      locality: value.addressParams.locality,
      adminAreaLevel1: value.addressParams.adminAreaLevel1,
      geohash: value.geohash,
      errors: { ...this.state.errors, addressFull: false },
    });
  };

  // UI Methods

  handleSearch = () => {
    this.handleReplaceQuery();
  };

  render() {
    const { classes, credentials } = this.props;

    const {
      errors,
      service,
      rating,
      conditions,
      gender,
      wageExp,
      jobType,
      yearsExp,
      pageNum,
      currFilteredRes,
      languagePref,
      isLoadedFilters,
      totalFilteredResults,
      isSetFilteredResults,
      avgWage,
      filterMaterials,
      currentQueryParams,
    } = this.state;

    return (
      <Grid
        container
        justifyContent="center"
        spacing={2}
        className="res-gr-con"
      >
        <Grid item xs={12} className={classes.form}>
          <SearchHeader
            currentQueryParams={currentQueryParams}
            onSearch={this.handleSearch}
            errors={errors}
            total={totalFilteredResults}
            avgWage={Math.round(parseFloat(avgWage) * 100) / 100}
            isSetFilteredResults={isSetFilteredResults}
            handleAddress={this.handleAddress}
            credentials={credentials}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4} className={classes.paperContainerC}>
            <SearchFilters
              gender={gender}
              wageExp={wageExp}
              service={service}
              rating={rating}
              conditions={conditions}
              languagePref={languagePref}
              jobType={jobType}
              yearsExp={yearsExp}
              handleSelect={this.handleSelect}
              handleSelectService={this.handleSelectService}
              handleSelectConditions={this.handleSelectConditions}
              filterMaterials={filterMaterials}
              clearFilters={this.clearFilters}
              isLoadedFilters={isLoadedFilters}
            />
            <Divider style={{ margin: "8px 0px" }} />
            <Typography variant="h6" style={{ fontWeight: 300 }}>
              Based on your search results:
            </Typography>
            {isSetFilteredResults && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginBottom: 5 }}
              >
                Showing {errors.cg ? 0 : currFilteredRes.length} of{" "}
                {errors.cg ? 0 : totalFilteredResults}{" "}
                {errors.cg || totalFilteredResults > 1
                  ? "caregivers"
                  : "caregiver"}{" "}
                found.
              </Typography>
            )}

            <Grid
              container
              justifyContent="center"
              style={{ marginTop: 16 }}
              className={classes.reactiveJustifyGrid}
              spacing={2}
            >
              {!isSetFilteredResults ? (
                [...new Array(12)].map((v, i) => (
                  <Grid
                    item
                    xs={12}
                    sm="auto"
                    key={i}
                    className={classes.flexBoxCentered}
                  >
                    <Skeleton
                      variant="rectangle"
                      height={400}
                      width={320}
                      style={{ borderRadius: 20 }}
                    />
                  </Grid>
                ))
              ) : totalFilteredResults === 0 || errors.cg ? (
                <Grid item xs={12}>
                  <SearchIcon className={classes.iconTextSecondary} />
                  <Typography color="textSecondary" style={{ fontWeight: 300 }}>
                    Oops... It seems we can not find any caregivers based on
                    your search criteria at the moment. To view all Poyo
                    Caregivers, click{" "}
                    <Box
                      component={Link}
                      to="/caregivers"
                      className={classes.linkText}
                    >
                      here
                    </Box>
                    .
                  </Typography>
                </Grid>
              ) : (
                currFilteredRes.map((cg) => (
                  <Fade in key={cg.userIdNumber}>
                    <Grid
                      item
                      xs={12}
                      sm={"auto"}
                      className={classes.flexBoxCentered}
                    >
                      <CareProfile cgData={cg} userData={credentials} />
                    </Grid>
                  </Fade>
                ))
              )}
            </Grid>

            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
              <Pagination
                count={
                  Math.ceil(totalFilteredResults / 20) < 1
                    ? 1
                    : Math.ceil(totalFilteredResults / 20)
                }
                page={parseInt(pageNum)}
                onChange={this.handlePageChange}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

cgSearchPage.propTypes = {
  credentials: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  queryCg: PropTypes.func.isRequired,
  setFilteredResults: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
  data: state.data,
});

export default connect(mapStateToProps, { queryCg, setFilteredResults })(
  withStyles(styles)(cgSearchPage)
);
