import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { capitalizeFirstChar } from "../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Masonry from "@mui/lab/Masonry";

import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

const styles = (theme) => ({
  linkText: {
    fontWeight: 550,
    color: theme.palette.primary.main,
    transition: "0.3s",
    width: "fit-content",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  responsiveCard: {
    width: "50%",
    maxWidth: "none",
    transition: "0.3s",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});

function CareersGrid(props) {
  const { careers, careersFiltered, loading } = props;
  const noAvailCareers = !loading && careers.length < 1;
  return (
    <>
      <div></div>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6" className="txt-bold">
            Current Available Positions
          </Typography>
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <div className="display-card" style={{ maxWidth: "none" }}>
              {[...new Array(6)].map((v, ind) => (
                <div key={ind} style={{ margin: "8px 0px" }}>
                  <Skeleton variant="rectangle" />
                  <Skeleton width={100} />
                </div>
              ))}
            </div>
          </Grid>
        ) : noAvailCareers ? (
          <Grid item xs={12} sm="auto">
            <div
              className="display-card flexbox-centered-column"
              style={{ minHeight: 300 }}
            >
              <SentimentDissatisfiedIcon
                sx={{ color: "text.secondary", fontSize: "3rem" }}
              />
              <Typography
                color="textSecondary"
                align="center"
                className="description-txt"
              >
                Seems like we don't have any positions available at the moment.
              </Typography>
            </div>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Masonry columns={2} spacing={{ xs: 4, sm: 2, md: 3 }}>
              {Object.keys(careersFiltered).map((department, ind) => (
                <div
                  className={`display-card ${props.classes.responsiveCard}`}
                  key={ind}
                >
                  <Typography variant="h5" className="txt-bold">
                    {capitalizeFirstChar(department)}
                  </Typography>
                  {careersFiltered[department].map((job, ind) => (
                    <div key={job.id} style={{ margin: "8px 0px" }}>
                      <Typography
                        component={Link}
                        to={`/careers/${job.id}`}
                        variant="h6"
                        className={props.classes.linkText}
                      >
                        {job.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {capitalizeFirstChar(job.locations)}
                      </Typography>
                    </div>
                  ))}
                </div>
              ))}
            </Masonry>
          </Grid>
        )}
      </Grid>
    </>
  );
}

CareersGrid.propTypes = {
  careers: PropTypes.array.isRequired,
  careersFiltered: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CareersGrid);
