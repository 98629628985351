import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  capitalizeFirstChar,
  numberWithCommas,
} from "../../../util/utilFunctions";
import dayjs from "dayjs";
import InvoiceShiftDisplay from "./InvoiceShiftDisplay";
import InvoiceCostBreakdown from "./InvoiceCostBreakdown";
import { ReactComponent as PoyoIconLogo } from "../../../assets/logo/icon-logo-inherit.svg";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import SvgIcon from "@mui/material/SvgIcon";

const styles = (theme) => ({
  ...theme.spreadThis,
  header: {
    height: 50,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
  },
  subheader: {
    backgroundColor: theme.palette.secondary.light,
    padding: "4px 10px",
    marginTop: 10,
    borderRadius: 8,
  },
  invoiceContainer: {
    width: "100%",
  },
  counterAvatar: {
    marginLeft: 3,
    height: 30,
    width: 30,
  },
  highlightBox: {
    display: "flex",
    justifyContent: "center",
    borderRadius: 20,
    border: `2px solid ${theme.palette.secondary.main}`,
    width: 300,
    height: 180,
    margin: "10px auto 0px auto",
    padding: 10,
  },
  grayHeader: {
    backgroundColor: "rgba(212,212,212,0.4)",
    marginTop: 10,
    fontWeight: 550,
    padding: "4px 10px",
    borderRadius: 8,
  },
});

export class InvoiceItem extends Component {
  render() {
    const {
      classes,
      isFamily,
      elevation,
      invoiceData,
      invoiceData: {
        id,
        createdAt,
        sortedShifts,
        caregiverInfo,
        familyInfo,
        memberInfo,
        status: { isApproved, isPaid },
        invoiceBreakdown,
        invoiceBreakdown: {
          grossIncome,
          netIncome,
          totalVacation,
          totalHours,
          totalOtHours,
          totalOperatingIncome,
          taxes: { taxProv, taxCountry },
        },
      },
    } = this.props;

    let orderedShiftsArr = Object.keys(sortedShifts).sort();
    return (
      <Paper
        elevation={elevation ? elevation : 0}
        className={classes.invoiceContainer}
      >
        <Box className={classes.header} p={1}>
          <SvgIcon>
            <PoyoIconLogo />
          </SvgIcon>
        </Box>
        <Box p={1}>
          <Typography style={{ fontWeight: 300 }} align="center" variant="h6">
            Invoice #{id}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <div>
              <Typography variant="subtitle2" color="textSecondary">
                For the week of:
              </Typography>
              <Typography>
                {dayjs(orderedShiftsArr[0])
                  .startOf("week")
                  .format("MMM DD, YYYY")}{" "}
                -{" "}
                {dayjs(orderedShiftsArr[0])
                  .endOf("week")
                  .format("MMM DD, YYYY")}
              </Typography>
            </div>
            <div>
              <Typography variant="body2" align="right" color="textSecondary">
                Created on:{" "}
                <Typography component="span" color="textPrimary">
                  {dayjs(createdAt).format("MM/DD/YYYY")}
                </Typography>
              </Typography>

              <Typography
                component="div"
                variant="body2"
                align="right"
                color="textSecondary"
              >
                Status:{" "}
                <Chip
                  label={isPaid ? "Paid" : isApproved ? "Approved" : "Pending"}
                  size="small"
                  className={
                    isPaid
                      ? classes.chipGreenOutlined
                      : isApproved
                      ? classes.chipBlueOutlined
                      : classes.chipBlueOutlined
                  }
                />
              </Typography>
            </div>
          </Box>
          <div
            className={isPaid ? classes.chipGreen : classes.chipBlue}
            style={{
              margin: "12px 0px",
              padding: 8,
              borderRadius: 8,
            }}
          >
            <Typography align="center">
              {isPaid
                ? `Invoice was Paid on: 
              ${dayjs(invoiceData.paymentInfo.paidOn).format("MM/DD/YYYY")}`
                : isApproved
                ? `This invoice has been approved pending payment on ${dayjs(
                    createdAt
                  )
                    .add(2, "d")
                    .format("MM/DD/YYYY")}`
                : `This invoice will be automatically approved and paid on ${dayjs(
                    createdAt
                  )
                    .add(2, "d")
                    .format("MM/DD/YYYY")}`}
            </Typography>
          </div>

          <Typography className={classes.grayHeader}>
            Family Information
          </Typography>
          <Grid container style={{ padding: "0px 8px" }}>
            <Grid item xs={5} style={{ padding: "2px 4px" }}>
              <Typography variant="body2" color="textSecondary">
                Client Name:
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ padding: "2px 4px" }}>
              <Typography variant="body2">
                {capitalizeFirstChar(familyInfo.clientFirstName)}{" "}
                {capitalizeFirstChar(familyInfo.clientLastName)}
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ padding: "2px 4px" }}>
              <Typography variant="body2" color="textSecondary">
                Family Name:
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ padding: "2px 4px" }}>
              <Typography variant="body2">
                {capitalizeFirstChar(familyInfo.firstName)}{" "}
                {capitalizeFirstChar(familyInfo.lastName)}
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ padding: "2px 4px" }}>
              <Typography variant="body2" color="textSecondary">
                Job ID:
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ padding: "2px 4px" }}>
              <Typography
                component={Link}
                to={
                  isFamily
                    ? `/profile/jobs/${memberInfo.jobId}`
                    : `/job/${memberInfo.jobId}`
                }
                variant="body2"
                color="primary"
                className={classes.typographyHover}
              >
                {memberInfo.jobId.toUpperCase().slice(0, 8)}...
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ padding: "2px 4px" }}>
              <Typography variant="body2" color="textSecondary">
                Address:
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ padding: "2px 4px" }}>
              <Typography variant="body2">
                {memberInfo.address.addressFull}
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.grayHeader}>
            Poyo Caregiver Information
          </Typography>

          <Grid container style={{ padding: "0px 8px" }}>
            <Grid item xs={5} style={{ padding: "2px 4px" }}>
              <Typography variant="body2" color="textSecondary">
                Name:
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ padding: "2px 4px" }}>
              <Typography variant="body2">
                {capitalizeFirstChar(caregiverInfo.firstName)}{" "}
                {capitalizeFirstChar(caregiverInfo.lastName)}
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ padding: "2px 4px" }}>
              <Typography variant="body2" color="textSecondary">
                Member ID:
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ padding: "2px 4px" }}>
              <Typography
                component={Link}
                to={`/myteam/${memberInfo.memberId}`}
                variant="body2"
                color="primary"
              >
                {memberInfo.memberId.toUpperCase().slice(0, 8)}..
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ padding: "2px 4px" }}>
              <Typography variant="body2" color="textSecondary">
                Poyo ID:
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ padding: "2px 4px" }}>
              <Typography variant="body2">
                {caregiverInfo.userIdNumber}
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ padding: "2px 4px" }}>
              <Typography variant="body2" color="textSecondary">
                Tax Residency:
              </Typography>
            </Grid>
            <Grid item xs={7} style={{ padding: "2px 4px" }}>
              <Typography variant="body2">
                {taxProv.toUpperCase()} - {taxCountry.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>

          <Typography className={classes.grayHeader}>
            Invoice Highlights
          </Typography>
          <Grid container justifyContent="space-evenly">
            <Grid item xs={11} sm="auto" className={classes.highlightBox}>
              <Box style={{ margin: "auto 0px auto 0px", textAlign: "center" }}>
                {isFamily ? (
                  <React.Fragment>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 550 }}
                      color="secondary"
                    >
                      $
                      {numberWithCommas(
                        invoiceBreakdown.payableBreakdown.totalPayable
                      )}
                    </Typography>
                    <Typography style={{ marginBottom: 5 }}>
                      Total Cost
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontWeight: 300 }}
                    >
                      See breakdown below
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 550 }}
                      color="secondary"
                    >
                      {numberWithCommas(totalHours)}
                    </Typography>
                    <Typography style={{ marginBottom: 5 }}>Hours</Typography>
                    <Typography
                      component="div"
                      variant="h6"
                      style={{ fontWeight: 550 }}
                      color="secondary"
                    >
                      {numberWithCommas(totalHours - totalOtHours)}{" "}
                      <Typography
                        component="span"
                        variant="body1"
                        color="textPrimary"
                        style={{ fontWeight: 300 }}
                      >
                        regular hours +{" "}
                      </Typography>
                      {numberWithCommas(totalOtHours)}{" "}
                      <Typography
                        component="span"
                        variant="body1"
                        color="textPrimary"
                        style={{ fontWeight: 300 }}
                      >
                        OT hours
                      </Typography>
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            </Grid>
            <Grid item xs={11} sm="auto" className={classes.highlightBox}>
              <Box style={{ margin: "auto 0px auto 0px", textAlign: "center" }}>
                {isFamily ? (
                  <React.Fragment>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 550 }}
                      color="secondary"
                    >
                      $
                      {numberWithCommas(
                        invoiceBreakdown.payableBreakdown.base / 0.65 -
                          invoiceBreakdown.payableBreakdown.totalPayable
                      )}
                    </Typography>

                    <Typography style={{ marginBottom: 5 }}>
                      Estimated Savings
                      <Tooltip
                        disableFocusListener
                        arrow
                        enterTouchDelay={0}
                        placement="top"
                        title={
                          <Typography variant="caption">
                            Estimated based on caregiver pay consisting of 65%
                            in total cost for traditional agencies.
                          </Typography>
                        }
                      >
                        <HelpIcon color="inherit" style={{ fontSize: 16 }} />
                      </Tooltip>
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 550 }}
                      color="secondary"
                    >
                      ${numberWithCommas(netIncome)}
                    </Typography>
                    <Typography style={{ marginBottom: 5 }}>
                      Est. Net Income
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontWeight: 300 }}
                    >
                      See breakdown below
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box p={2} className={classes.subheader}>
            <Typography style={{ fontWeight: 550, color: "#e4f9f5" }}>
              Shifts {"&"} Activities
            </Typography>
          </Box>
          <Box p={1}>
            {orderedShiftsArr.map((date) => (
              <InvoiceShiftDisplay
                dateData={sortedShifts[date]}
                date={date}
                key={date}
              />
            ))}
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ padding: "0px 5px 0px 5px" }}
            >
              <Typography variant="body2" style={{ fontWeight: 300 }}>
                Gross Income:
              </Typography>
              <Typography
                variant="body2"
                align="right"
                style={{ fontWeight: 550, width: 75 }}
              >
                ${numberWithCommas(totalOperatingIncome)}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ padding: "0px 5px 0px 5px" }}
            >
              <Typography variant="body2" style={{ fontWeight: 300 }}>
                Vacation Pay {"(4%)"}:
              </Typography>
              <Typography
                variant="body2"
                align="right"
                style={{ fontWeight: 550, width: 75 }}
              >
                ${numberWithCommas(totalVacation)}
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="flex-end"
              style={{
                backgroundColor: "rgba(212,212,212,0.4)",
                borderRadius: 8,
                padding: "0px 5px 0px 5px",
              }}
            >
              <Typography variant="body2" style={{ fontWeight: 550 }}>
                Taxable Income:
              </Typography>
              <Typography
                variant="body2"
                align="right"
                style={{ fontWeight: 550, width: 75 }}
              >
                ${numberWithCommas(grossIncome)}
              </Typography>
            </Box>
          </Box>
          <Box p={2} className={classes.subheader}>
            <Typography style={{ fontWeight: 550, color: "#e4f9f5" }}>
              Pay Breakdown
            </Typography>
          </Box>
          <Box p={1}>
            <InvoiceCostBreakdown
              invoiceData={invoiceData}
              isFamily={isFamily}
            />
          </Box>
        </Box>
      </Paper>
    );
  }
}

InvoiceItem.propTypes = {
  classes: PropTypes.object.isRequired,
  invoiceData: PropTypes.object.isRequired,
  isFamily: PropTypes.bool,
  elevation: PropTypes.number,
};

export default withStyles(styles)(InvoiceItem);
