import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AuthCareRoute = ({
  component: Component,
  authenticated,
  credentials,
  loading,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !authenticated ? (
        <Redirect to="/login" />
      ) : loading ? null : Boolean(credentials.status.isProfileCompleted) ? (
        <Redirect to="/dashboard" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  credentials: state.user.credentials,
  loading: state.user.loading,
});

AuthCareRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  credentials: PropTypes.object,
};

export default connect(mapStateToProps)(AuthCareRoute);
