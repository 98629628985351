import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

export class ButtonSelectUnique extends Component {
  handleSelectValue = (e) => {
    this.props.handleSelectValue(e);
  };
  render() {
    const { classes, valueObj, value, error } = this.props;

    return (
      <Grid
        container
        justifyContent="center"
        spacing={1}
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        {Object.entries(valueObj).map((v) => (
          <Grid item xs={12} sm={6} md={3} key={v}>
            <StyledButton
              value={v[0]}
              onClick={this.handleSelectValue}
              fullWidth
              className={
                error
                  ? value === v[0]
                    ? classes.errorSelectedToggleButton
                    : classes.errorUnselectedToggleButton
                  : value === v[0]
                  ? classes.selectedToggleButton
                  : classes.unselectedToggleButton
              }
              style={{ paddingRight: 10, paddingLeft: 10 }}
            >
              <Typography
                component="p"
                variant="body2"
                align="center"
                color={!error && value !== v[0] ? "textSecondary" : "inherit"}
                noWrap
              >
                {v[1]}
              </Typography>
            </StyledButton>
          </Grid>
        ))}
      </Grid>
    );
  }
}

ButtonSelectUnique.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  value: PropTypes.string.isRequired,
  valueObj: PropTypes.object.isRequired,
  handleSelectValue: PropTypes.func.isRequired,
};

export default withStyles(styles)(ButtonSelectUnique);
