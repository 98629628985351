import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import clsx from "clsx";

// Component imports
import OutlinedProfilePic from "../../../../../components/layout/OutlinedProfilePic";
import { capitalizeFirstChar } from "../../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// Icon imports
import LoadingAnimation from "../../../../../components/layout/LoadingAnimation";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class AllReports extends Component {
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("container");
    if (
      this.isBottom(wrappedElement) &&
      !this.props.isLoadingMore &&
      this.props.isLoadAgain
    ) {
      this.props.onLoadMore();
    }
  };

  render() {
    const {
      classes,
      reports,
      isLoadedReports,
      isLoadingMore,
      isLoadAgain,
      userType,
    } = this.props;

    const isCaregiver = userType === "caregiver";
    return (
      <Box id="container" style={{ minHeight: "50vh" }}>
        <Typography variant="h6" align="center" style={{ fontWeight: 300 }}>
          All Report
        </Typography>
        <Divider />
        {!isLoadedReports && (
          <Box style={{ paddingTop: 16 }}>
            <LoadingAnimation message="Loading reports" />
          </Box>
        )}
        {isLoadedReports &&
          reports.map((report, index) => (
            <React.Fragment key={report.id}>
              {(index === 0 ||
                !dayjs(report.submitted).isSame(
                  reports[index - 1].submitted,
                  "d"
                )) && (
                <Box
                  className={classes.selectedGrayContainer}
                  style={{ paddingBottom: 4, paddingTop: 4, margin: "4px 0px" }}
                >
                  <Typography variant="subtitle2" style={{ fontWeight: 300 }}>
                    Submitted: {dayjs(report.submitted).format("MMM DD, YYYY")}
                  </Typography>
                </Box>
              )}
              <CardActionArea
                component={Link}
                to={`/reports/${report.id}`}
                className={clsx(
                  classes.hoverGrayContainer,
                  !report.status.isRead && classes.errorBackground
                )}
                style={{
                  margin: "4px 0px",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  Shift:
                </Typography>
                <Typography variant="caption" component="div">
                  {dayjs(report.shiftInfo.startTime).format("MMM DD, YYYY")}{" "}
                  {dayjs(report.shiftInfo.startTime).format("h:mma")} -{" "}
                  {dayjs(report.shiftInfo.endTime).format("h:mma")}
                </Typography>
                <Box className={classes.flexBoxSpaced}>
                  <Box className={classes.flexBox}>
                    <OutlinedProfilePic
                      imgSrc={
                        isCaregiver
                          ? report.familyInfo.imgUrlThumb
                          : report.caregiverInfo.imgUrlThumb
                      }
                      size={35}
                    />
                    <Box style={{ marginLeft: 4, whiteSpace: "pre-line" }}>
                      <Typography variant="body2" color="textPrimary">
                        {isCaregiver
                          ? `${capitalizeFirstChar(
                              report.familyInfo.clientFirstName
                            )} ${capitalizeFirstChar(
                              report.familyInfo.clientLastName
                            )}`
                          : `${capitalizeFirstChar(
                              report.caregiverInfo.firstName
                            )} ${capitalizeFirstChar(
                              report.caregiverInfo.lastName
                            )}`}
                      </Typography>
                      {isCaregiver && (
                        <Typography variant="body2" color="textSecondary">
                          {"("}
                          {capitalizeFirstChar(
                            report.familyInfo.firstName
                          )}{" "}
                          {capitalizeFirstChar(report.familyInfo.lastName)}
                          {")"}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Chip
                      label={
                        report.status.isActioned
                          ? "Actioned"
                          : report.status.isRead
                          ? "Read"
                          : "Pending"
                      }
                      size="small"
                      className={
                        report.status.isActioned
                          ? classes.chipGreenOutlined
                          : report.status.isRead
                          ? classes.chipBlueOutlined
                          : classes.chipOrjOutlined
                      }
                      style={{ margin: 0 }}
                    />
                    <Typography
                      component="div"
                      variant="caption"
                      align="right"
                      color="textSecondary"
                    >
                      {dayjs(report.submitted).format("h:mma")}
                    </Typography>
                  </Box>
                </Box>
              </CardActionArea>
            </React.Fragment>
          ))}

        {isLoadingMore ? (
          <Box className={classes.form}>
            <CircularProgress />
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              align="center"
              style={{ fontStyle: "italic" }}
            >
              Loading reports
            </Typography>
          </Box>
        ) : isLoadAgain ? (
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            align="center"
            style={{ fontStyle: "italic" }}
          >
            Scroll to load more
          </Typography>
        ) : (
          <Box className={classes.selectedGrayContainer}>
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              align="center"
              style={{ fontStyle: "italic" }}
            >
              All reports loaded
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
}

AllReports.propTypes = {
  classes: PropTypes.object.isRequired,
  reports: PropTypes.array.isRequired,
  isLoadedReports: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isLoadAgain: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
};

export default withStyles(styles)(AllReports);
