import {
  SET_FOOTER,
  UNSET_FOOTER,
  LOADING_UI_SPECIAL,
  STOP_LOADING_UI,
  SET_ALERT,
  UNSET_ALERT,
  SET_UPDATED,
  CLEAR_UPDATED,
} from "../types";

// Clear errors
export const setFooter = () => (dispatch) => {
  dispatch({ type: SET_FOOTER });
};

export const unsetFooter = () => (dispatch) => {
  dispatch({ type: UNSET_FOOTER });
};

export const setLoadingUISpecial = () => (dispatch) => {
  dispatch({ type: LOADING_UI_SPECIAL });
};

export const stopLoadingUISpecial = () => (dispatch) => {
  dispatch({ type: STOP_LOADING_UI });
};

export const setAlert = (alert) => (dispatch) => {
  dispatch({ type: SET_ALERT, payload: alert });
};

export const unsetAlert = () => (dispatch) => {
  dispatch({ type: UNSET_ALERT });
};

export const setUpdateCheck = () => (dispatch) => {
  dispatch({ type: SET_UPDATED });
  dispatch({ type: CLEAR_UPDATED });
};
