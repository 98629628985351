import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import firebase from "../../../Firebase";
import "./Nav.css";

// Component imports
import { withLogoutHOC } from "../../../util/withFBHooks";
import ScrollToColor from "./components/ScrollToColor";
import AuthNav from "./components/AuthNav";
import UnauthNav from "./components/UnauthNav";
import UnauthDrawer from "./components/UnauthDrawer";
import AuthDrawer from "./components/AuthDrawer";
import { ReactComponent as PoyoIconLogo } from "../../../assets/logo/icon-logo-inherit.svg";
import PoyoLogo from "../../../assets/logo/logo.svg";

// Redux Imports
import { connect } from "react-redux";
import {
  logoutUser,
  setNotif,
  clearNotif,
} from "../../../redux/actions/userActions";

// MUI Imports
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import SvgIcon from "@mui/material/SvgIcon";

// Icons Imports
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 300;
const smDrawerWidth = 300;

const styles = (theme) => ({
  ...theme.spreadThis,
  root: {
    display: "flex",
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: smDrawerWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    width: `100%`,

    // zIndex: theme.zIndex.drawer + 1,
    // boxShadow: "none",
  },
  toolBar: {
    minHeight: 50,
    maxHeight: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  drawerHeader: {
    minHeight: 50,
    display: "flex",
    alignItems: "center",

    padding: "16px 8px",
  },
  drawerPaper: {
    width: drawerWidth,

    [theme.breakpoints.up("sm")]: {
      width: smDrawerWidth,
    },
    // [theme.breakpoints.up("md")]: {
    //   width: drawerWidth,
    // },
    backgroundColor: "rgba(254,254,254,1)",
    color: theme.palette.text.primary,
  },

  listButton: {
    "&:hover": {
      backgroundColor: "rgba(27,	27,	27, 0.64)",
    },
  },
});

export class Navbar extends Component {
  state = {
    mobileOpen: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  getNotif = async () => {
    const { userIdNumber } = this.props.credentials;
    const colRef = firebase
      .firestore()
      .collection("notifications")
      .where("recipient", "==", userIdNumber)
      .orderBy("createdAt", "desc")
      .limit(25);

    const that = this;

    this.unsubscribe = colRef.onSnapshot((snapshot) => {
      let lastKey = "";

      const notifications = snapshot.docs.map((notif) => {
        const _notif = notif.data();
        _notif.id = notif.id;
        return _notif;
      });
      if (snapshot.size < 25) lastKey = null;
      else lastKey = snapshot.docs[snapshot.docs.length - 1];

      that.props.setNotif(notifications, lastKey);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      authenticated,
      credentials: { userIdNumber },
    } = this.props;
    if (Boolean(userIdNumber)) {
      if (authenticated && !prevProps.authenticated) {
        this.getNotif();
      }
    }
  }

  handleLogout = () => {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.props.logoutHook();
    this.props.logoutUser();
    this.props.clearNotif();
  };

  handleDrawerToggle = () => {
    this.setState({ ...this.state, mobileOpen: !this.state.mobileOpen });
  };

  handleDrawerClose = () => {
    this.setState({ ...this.state, mobileOpen: false });
  };

  render() {
    const {
      classes,
      authenticated,
      window,
      notifications,
      credentials,
      credentials: { userType },
      page,
    } = this.props;

    const container =
      window !== undefined ? () => window().document.body : undefined;

    return (
      <nav className={classes.root}>
        <CssBaseline />
        <ScrollToColor notHome={page !== "Home"}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              {authenticated ? (
                <>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerToggle}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                  <AuthNav
                    notifications={notifications}
                    isSubscribed={Boolean(this.unsubscribe)}
                    userType={userType}
                    page={page}
                    handleLogout={this.handleLogout}
                  />
                </>
              ) : (
                <>
                  <IconButton
                    edge="start"
                    component={Link}
                    size="large"
                    color="inherit"
                    to="/"
                  >
                    <SvgIcon className="nav-icon">
                      <PoyoIconLogo />
                    </SvgIcon>
                  </IconButton>

                  <UnauthNav handleDrawerToggle={this.handleDrawerToggle} />
                </>
              )}
            </Toolbar>
          </AppBar>
        </ScrollToColor>
        <nav className={classes.drawer} aria-label="drawer nav">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {/* <Hidden smUp implementation="css"> */}
          <Drawer
            container={container}
            variant="temporary"
            anchor="right"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton
                color="inherit"
                aria-label="close drawer"
                onClick={this.handleDrawerClose}
              >
                <CloseIcon />
              </IconButton>

              <Link
                to="/"
                onClick={this.handleDrawerClose}
                style={{ marginLeft: 16 }}
              >
                <img src={PoyoLogo} alt="logo-full" style={{ width: 100 }} />
              </Link>
            </div>
            {authenticated && Boolean(credentials.status.isProfileCompleted) ? (
              <AuthDrawer
                page={page}
                userType={userType}
                handleDrawerClose={this.handleDrawerClose}
              />
            ) : (
              <UnauthDrawer handleDrawerClose={this.handleDrawerClose} />
            )}
          </Drawer>
          {/* </Hidden> */}
        </nav>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  credentials: state.user.credentials,
  notifications: state.user.notifications,
  page: state.UI.page,
});

const mapActionToProps = {
  logoutUser,
  setNotif,
  clearNotif,
};

Navbar.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  credentials: PropTypes.object,
  logoutUser: PropTypes.func.isRequired,
  setNotif: PropTypes.func.isRequired,
  clearNotif: PropTypes.func.isRequired,
  notifications: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withLogoutHOC(withStyles(styles)(Navbar)));
