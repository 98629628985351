export const prov = {
  CA: [
    "",
    "AB",
    "BC",
    "SK",
    "MB",
    "ON",
    "QC",
    "NB",
    "NS",
    "PE",
    "NL",
    "YT",
    "NT",
    "NU",
  ],
  US: [
    "",
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ],
};

export const languages = {
  english: "en",
  mandarin: "zh-mn",
  cantonese: "zh-cn",
  french: "fr",
  hindi: "hi",
  panjabi: "pa",
  farsi: "fa",
  spanish: "es",
  german: "de",
  tagalog: "tl",
  korean: "ko",
};

export const minWage = {
  ab: 15.0,
  bc: 15.2,
  mb: 11.95,
  nb: 11.75,
  nl: 12.75,
  nt: 12.75,
  ns: 12.95,
  nu: 16.0,
  on: 14.36,
  pe: 13.0,
  qc: 13.5,
  sk: 11.81,
  yt: 15.2,
};
