import React, { Component } from "react";
import dayjs from "dayjs";
import axios from "axios";

// Component imports
import TimeSelector from "../../../../components/inputs/TimeSelector";
import {
  getLocalTime,
  isEmpty,
  allFalse,
} from "../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
  editBorder: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 15,
    padding: 8,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    width: 30,
    height: 30,
    margin: "0px 2px 0px 2px",
  },
  secondary: {
    width: 30,
    height: 30,
    margin: "0px 2px 0px 2px",
  },
  error: {
    color: "red",
    backgroundColor: "rgba(245, 221,219, 1)",
    width: 30,
    height: 30,
    margin: "0px 2px 0px 2px",
  },
  dayIconButtom: {
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
});

export class AddMyAvail extends Component {
  state = {
    errors: {},
    availDay: {
      isMon: false,
      isTues: false,
      isWed: false,
      isThur: false,
      isFri: false,
      isSat: false,
      isSun: false,
    },
    availability: {
      startTime: null,
      endTime: null,
      startTimeISO: "",
      endTimeISO: "",
      startTimeComp: null,
      endTimeComp: null,
    },
    disabledWhileLoad: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Validate Methods

  validateStartTime = () => {
    const {
      availability: { startTimeComp, endTimeComp },
      errors,
    } = this.state;

    this.setState({
      errors: {
        ...errors,
        startTime:
          Boolean(endTimeComp) && startTimeComp >= endTimeComp
            ? "Start time must be earlier than end time."
            : null,
        endTime:
          !Boolean(endTimeComp) || startTimeComp < endTimeComp
            ? null
            : errors.endTime,
        general: false,
      },
    });
  };

  validateEndTime = () => {
    const {
      availability: { startTimeComp, endTimeComp },
      errors,
    } = this.state;

    this.setState({
      errors: {
        ...errors,
        endTime:
          Boolean(startTimeComp) && startTimeComp >= endTimeComp
            ? "End time must be later than start time."
            : null,
        startTime:
          !Boolean(startTimeComp) || startTimeComp < endTimeComp
            ? null
            : errors.startTime,
        general: false,
      },
    });
  };

  validateSubmit = async () => {
    const { availability, availDay, errors } = this.state;
    const checkErrors = {};
    if (allFalse(availDay)) {
      checkErrors.availDay = "Must select at least 1 day.";
    }
    if (isEmpty(availability.startTime) || availability.startTime === null) {
      checkErrors.startTime = "Must not be empty.";
    }
    if (isEmpty(availability.endTime) || availability.endTime === null) {
      checkErrors.endTime = "Must not be empty.";
    }
    await this.setStateAsync({ errors: { ...errors, ...checkErrors } });
    return Object.keys(checkErrors).length < 1;
  };

  handleDay = (e) => {
    const day = e.currentTarget.value;

    this.setState({
      availDay: {
        ...this.state.availDay,
        [day]: !this.state.availDay[day],
      },
      errors: {
        ...this.state.errors,
        availDay: false,
        general: false,
      },
    });
  };

  handleStartTime = async (value) => {
    const v = getLocalTime(value);
    const hour = parseInt(v.slice(0, 2));
    const min = parseInt(v.slice(3)) / 60;
    const timeComp = hour + min;
    const vISO = dayjs(new Date(value).setMilliseconds(0)).toISOString();

    await this.setStateAsync({
      availability: {
        ...this.state.availability,
        startTime: v,
        startTimeISO: vISO,
        startTimeComp: timeComp,
      },
    });

    this.validateStartTime();
  };
  handleEndTime = async (value) => {
    const v = getLocalTime(value);
    const hour = parseInt(v.slice(0, 2));
    const min = parseInt(v.slice(3)) / 60;
    const timeComp = hour + min;
    const vISO = dayjs(new Date(value).setMilliseconds(0)).toISOString();

    await this.setStateAsync({
      availability: {
        ...this.state.availability,
        endTime: v,
        endTimeISO: vISO,
        endTimeComp: timeComp,
      },
    });
    this.validateEndTime();
  };

  handleReset = () => {
    this.setState({
      errors: {},
      availDay: {
        isMon: false,
        isTues: false,
        isWed: false,
        isThur: false,
        isFri: false,
        isSat: false,
        isSun: false,
      },
      availability: {
        startTime: "",
        endTime: "",
        startTimeISO: "",
        endTimeISO: "",
      },
    });
  };

  handleSubmit = async () => {
    const { availability, availDay } = this.state;
    const valid = await this.validateSubmit();

    if (valid) {
      let availArr = [];
      Object.keys(availDay).forEach((day) => {
        if (availDay[day]) {
          let avail = JSON.parse(JSON.stringify(availability));

          let dayId =
            day === "isMon"
              ? 0
              : day === "isTues"
              ? 1
              : day === "isWed"
              ? 2
              : day === "isThur"
              ? 3
              : day === "isFri"
              ? 4
              : day === "isSat"
              ? 5
              : day === "isSun"
              ? 6
              : null;

          avail.dayId = dayId;
          availArr.push(avail);
        }
      });
      this.submitAvail(availArr);
      // this.props.submitAvail(availArr);
    }
  };

  submitAvail = async (availArr) => {
    await this.setStateAsync({ disabledWhileLoad: true });

    try {
      await axios.post("/user/avail/set", availArr);
    } catch (err) {
      this.setState({ errors: { general: err.response.data.error } });
    } finally {
      await this.setStateAsync({ disabledWhileLoad: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { availability, errors, availDay, disabledWhileLoad } = this.state;

    return (
      <Grid
        container
        justifyContent="space-evenly"
        className={classes.editBorder}
        style={{ marginTop: 50 }}
      >
        <Grid item xs={12}>
          <Typography
            color={
              errors.availDay ||
              errors.general ||
              errors.startTime ||
              errors.endTime
                ? "error"
                : "inherit"
            }
            variant="h6"
            fontWeight={400}
          >
            Add Availabilty
          </Typography>
        </Grid>
        {errors.availDay || errors.general ? (
          <Grid
            item
            xs={12}
            style={{
              marginTop: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              align="center"
              color="error"
              style={{ width: "100%", marginRight: 10 }}
            >
              {errors.general ? errors.general : errors.availDay}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
          {[
            "isSun",
            "isMon",
            "isTues",
            "isWed",
            "isThur",
            "isFri",
            "isSat",
          ].map((date) => (
            <IconButton
              size="small"
              value={date}
              key={date}
              className={classes.dayIconButton}
              onClick={this.handleDay}
              disabled={disabledWhileLoad}
            >
              <Avatar
                className={
                  errors.availDay
                    ? classes.error
                    : availDay[date]
                    ? classes.primary
                    : classes.secondary
                }
              >
                {date === "isSun" ? "Su" : date.slice(2, 3)}
              </Avatar>
            </IconButton>
          ))}
        </Grid>

        <Grid item xs={10} sm={5}>
          <Typography
            variant="body2"
            align="left"
            component="legend"
            color={errors.startTime || errors.general ? "error" : "inherit"}
          >
            Start Time:
          </Typography>

          <TimeSelector
            onSelectTime={this.handleStartTime}
            error={errors.startTime || errors.general}
            errorText={errors.startTime}
            fullWidth
            disabled={disabledWhileLoad}
            className={
              Boolean(errors.startTime || errors.general)
                ? classes.styledTextFieldError
                : classes.styledTextField
            }
            time={availability.startTime}
          />
        </Grid>
        <Grid item xs={10} sm={5}>
          <Typography
            variant="body2"
            align="left"
            component="legend"
            color={errors.endTime || errors.general ? "error" : "inherit"}
          >
            End Time:
          </Typography>

          <TimeSelector
            onSelectTime={this.handleEndTime}
            error={errors.endTime || errors.general}
            errorText={errors.endTime}
            fullWidth
            disabled={disabledWhileLoad}
            className={
              Boolean(errors.endTime || errors.general)
                ? classes.styledTextFieldError
                : classes.styledTextField
            }
            time={availability.endTime}
          />
        </Grid>

        <Grid
          item
          xs={10}
          sm={6}
          style={{
            marginTop: 10,
          }}
        >
          <div className={classes.flexBox}>
            <Typography
              component="div"
              variant="body2"
              align="right"
              fontWeight="fontWeightBold"
              style={{ width: "40%", marginRight: 10 }}
            >
              Total Daily Hours:
            </Typography>
            <Typography
              component="div"
              variant="body2"
              align="center"
              fontWeight="fontWeightBold"
              style={{ width: "50%" }}
            >
              {availability.endTimeComp - availability.startTimeComp < 0
                ? 0
                : (
                    availability.endTimeComp - availability.startTimeComp
                  ).toFixed(2)}
            </Typography>
          </div>
        </Grid>
        <Grid container item justifyContent="flex-end" style={{ margin: 12 }}>
          <Button
            onClick={this.handleReset}
            disabled={disabledWhileLoad}
            className={classes.txtTrButton}
          >
            Reset
          </Button>
          <Button
            onClick={this.handleSubmit}
            variant="contained"
            disabled={disabledWhileLoad}
            className={classes.txtTrButton}
          >
            Add
            {disabledWhileLoad && (
              <CircularProgress
                thickness={2}
                size={25}
                className={classes.progress}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AddMyAvail);
