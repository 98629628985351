import React, { Component } from "react";
import PropTypes from "prop-types";
import { capitalizeFirstChar, splitByCapital } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ArraySelectButton extends Component {
  state = { optionsAll: [], optionsCntr: [] };

  componentDidMount() {
    const { optionsAll, optionsCntr } = this.props;

    this.setState({
      optionsAll: JSON.parse(JSON.stringify(optionsAll)),
      optionsCntr: JSON.parse(JSON.stringify(optionsCntr)),
    });
  }

  componentDidUpdate(prevProps) {
    const { optionsAll, optionsCntr } = this.props;
    if (prevProps.optionsAll.length === 0 && optionsAll.length > 0) {
      this.setState({
        optionsAll: JSON.parse(JSON.stringify(optionsAll)),
        optionsCntr: JSON.parse(JSON.stringify(optionsCntr)),
      });
    }
  }

  handleArrayChange = (e) => {
    let arr = this.state.optionsCntr;
    if (arr.includes(e.currentTarget.value)) {
      if (arr.length > 1) {
        let index = arr.indexOf(e.currentTarget.value);
        arr.splice(index, 1);
        this.setState({ optionsCntr: arr }, () => {
          this.props.onHandleSelect(arr);
        });
      }
    } else {
      arr.push(e.currentTarget.value);
      this.setState({ optionsCntr: arr }, () => {
        this.props.onHandleSelect(arr);
      });
    }
  };

  render() {
    const { optionsAll, optionsCntr } = this.state;
    const { error, startIcon, loading, classes, showSave, unselStartIcon } =
      this.props;

    return (
      <div>
        {optionsAll.map((key) => {
          return (
            <Button
              variant={optionsCntr.includes(key) ? "contained" : "outlined"}
              size="small"
              className={classes.editItemButton}
              startIcon={
                (!unselStartIcon && startIcon) ||
                (startIcon && optionsCntr.includes(key))
                  ? startIcon
                  : unselStartIcon && unselStartIcon
              }
              value={key}
              onClick={this.handleArrayChange}
              key={key}
              style={
                error
                  ? optionsCntr.includes(key)
                    ? {
                        borderColor: "red",
                        backgroundColor: "rgba(245, 221,219, 1)",
                        color: "red",
                      }
                    : { borderColor: "red", color: "red" }
                  : {}
              }
            >
              {capitalizeFirstChar(splitByCapital(key))}
            </Button>
          );
        })}
        {showSave ? (
          <Grid container item justifyContent="flex-end">
            <Button
              onClick={() => this.props.onSaveButtonClick()}
              disabled={loading}
            >
              Save
              {loading && (
                <CircularProgress
                  thickness={2}
                  size={30}
                  className={classes.progress}
                />
              )}
            </Button>
          </Grid>
        ) : null}
      </div>
    );
  }
}

ArraySelectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  showSave: PropTypes.bool,
  optionsAll: PropTypes.array.isRequired,
  optionsCntr: PropTypes.array.isRequired,
  onSaveButtonClick: PropTypes.func,
  onHandleSelect: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default withStyles(styles)(ArraySelectButton);
