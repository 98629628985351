import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import axios from "axios";

// Redux imports
import { connect } from "react-redux";
import { setAlert, setUpdateCheck } from "../../../redux/actions/uiActions";
import { updateDataArrayId } from "../../../redux/actions/dataActions";
import { capitalizeFirstChar } from "../../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

// Icon imports
import ScheduleIcon from "@mui/icons-material/Schedule";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ActionShiftButton extends Component {
  state = { disableWhileLoad: false, openCompleteDialog: false, shiftNote: "" };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI Methods
  handleOpenCompleteDialog = () => {
    this.setState({ openCompleteDialog: true });
  };
  handleCloseCompleteDialog = () => {
    this.setState({ openCompleteDialog: false, shiftNote: "" });
  };

  // Error Validation

  validateError = async () => {
    const { startTime, numTasks, numTasksActioned, status, id } =
      this.props.shift;

    let errorMsg;

    if (!status.isCheckedIn && dayjs().isBefore(startTime, "d"))
      errorMsg = "You may only check in on the scheduled date.";

    if (status.isCheckedIn) {
      const shiftDoc = await axios.get(`/shift/${id}`);
      if (shiftDoc.data.numTasksActioned < numTasks)
        errorMsg = `There ${
          numTasks - numTasksActioned === 1 ? "is" : "are"
        } still ${
          numTasks - numTasksActioned
        } task(s) that need to be actioned`;
    }
    if (Boolean(errorMsg))
      this.props.setAlert({ message: errorMsg, type: "warning" });

    return Boolean(errorMsg);
  };

  // Action methods

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getCoordinates = () => {
    const that = this;
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        resolve,
        function () {
          that.props.setAlert({
            message: "Please enable your location",
            type: "warning",
          });
          that.setState({ disableWhileLoad: false });
        },
        {
          maximumAge: 10000,
          timeout: 5000,
          enableHighAccuracy: true,
        }
      );
    });
  };

  handleCheckInShift = async () => {
    const { id } = this.props.shift;
    await this.setStateAsync({ disableWhileLoad: true });

    const isError = await this.validateError();

    if (!isError) {
      try {
        const _coordinates = await this.getCoordinates();
        const coordinates = {
          lat: _coordinates.coords.latitude,
          lng: _coordinates.coords.longitude,
        };
        await axios.post(`/shift/${id}/check-in`, { coordinates });

        this.handleUpdateOnAction("isCheckedIn");
      } catch (err) {
        Boolean(err.response.data.shift) &&
          this.props.setAlert({
            message: err.response.data.shift,
            type: "error",
          });
      }
    }
    this.setStateAsync({ disableWhileLoad: false });
  };

  handleCompleteShift = async () => {
    const { id } = this.props.shift;
    const { shiftNote } = this.state;
    await this.setStateAsync({ disableWhileLoad: true });

    const isError = await this.validateError();

    if (!isError) {
      try {
        const _coordinates = await this.getCoordinates();
        const coordinates = {
          lat: _coordinates.coords.latitude,
          lng: _coordinates.coords.longitude,
        };
        await axios.post(`/shift/${id}/complete`, { coordinates, shiftNote });
        this.handleUpdateOnAction("isCompleted");

        this.handleCloseCompleteDialog();
      } catch (err) {
        this.props.setAlert({
          message: "Please enable your location",
          type: "warning",
        });
      }
    }
    this.setState({ disableWhileLoad: false });
  };

  handleUpdateOnAction = (key) => {
    const { id } = this.props.shift;

    const shiftData = { ...this.props.shift };
    shiftData.status[key] = true;
    this.props.updateDataArrayId(shiftData, "shifts", id);
    setTimeout(() => this.props.setUpdateCheck(), 250);
  };

  render() {
    const {
      classes,
      fullWidth,
      variant,
      shift: { status, familyInfo },
    } = this.props;

    const { disableWhileLoad, openCompleteDialog, shiftNote } = this.state;

    return (
      <React.Fragment>
        <Button
          className={classes.txtTrButton}
          onClick={
            !status.isCheckedIn
              ? this.handleCheckInShift
              : this.handleOpenCompleteDialog
          }
          variant={variant ? variant : "outlined"}
          startIcon={
            !status.isCheckedIn ? <ScheduleIcon /> : <WatchLaterIcon />
          }
          color="primary"
          size="small"
          fullWidth={fullWidth}
          style={{ margin: "4px 0px 4px 4px", minWidth: 0 }}
          disabled={disableWhileLoad || status.isCompleted}
        >
          {status.isCompleted
            ? "Completed"
            : status.isCheckedIn
            ? "Complete"
            : "Check in"}
          {disableWhileLoad && (
            <CircularProgress
              thickness={2}
              size={25}
              className={classes.progress}
            />
          )}
        </Button>
        <Dialog
          open={openCompleteDialog}
          onClose={this.handleCloseCompleteDialog}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Complete Shift</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add a note for {capitalizeFirstChar(familyInfo.firstName)}
            </DialogContentText>
            <TextField
              variant="outlined"
              name="shiftNote"
              value={shiftNote}
              onChange={this.handleChange}
              className={classes.styledTextField}
              fullWidth
              multiline
              minRows={5}
              maxRows={7}
              disabled={disableWhileLoad}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={disableWhileLoad}
              onClick={this.handleCloseCompleteDialog}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={disableWhileLoad}
              onClick={this.handleCompleteShift}
            >
              Confirm
              {disableWhileLoad && (
                <CircularProgress
                  thickness={2}
                  size={25}
                  className={classes.progress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ActionShiftButton.propTypes = {
  classes: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  updateOnAction: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  setUpdateCheck: PropTypes.func,
  updateDataArrayId: PropTypes.func,
};

export default connect(null, { setAlert, setUpdateCheck, updateDataArrayId })(
  withStyles(styles)(ActionShiftButton)
);
