import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

// Component imports
import DateSelector from "../../../../../components/inputs/DateSelector";
import { isEmpty } from "../../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class AccreditationMarkup extends Component {
  state = {
    accredName: "",
    accredDescription: "",
    accredDate: "",
    errors: {},
  };

  componentDidMount() {
    this.setEditMode();
  }

  setEditMode() {
    const { eduAccred, isEdit } = this.props;
    isEdit && this.setState({ ...eduAccred });
  }

  // Error validate methods

  validateSubmit = () => {
    const { accredName } = this.state;
    if (isEmpty(accredName))
      this.setState({ errors: { accredName: "Must not be empty" } });
  };

  // Action methods

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAccredDate = (value) => {
    this.setState({ accredDate: dayjs(value).toISOString() });
  };

  handleAccredName = (e) => {
    const value = e.target.value;
    this.setState({
      accredName: value,
      errors: {
        accredName: isEmpty(value) && "Must not be empty",
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.validateSubmit();
    const { errors, accredName, accredDescription, accredDate } = this.state;
    if (!Boolean(errors.accredName)) {
      this.props.handleSubmit({
        accredName,
        accredDescription,
        accredDate,
        type: "accreditation",
      });
    }
  };

  render() {
    const { classes, disableWhileLoad, isEdit } = this.props;

    const { errors, accredName, accredDescription } = this.state;

    return (
      <Fade in>
        <form onSubmit={this.handleSubmit}>
          <Typography
            variant="body2"
            color={Boolean(errors.accredName) ? "error" : "inherit"}
            className={classes.typography}
          >
            Name*
          </Typography>
          <TextField
            variant="outlined"
            name="accredName"
            onChange={this.handleAccredName}
            placeholder="Ex: BC HCA..."
            value={accredName}
            error={Boolean(errors.accredName)}
            helperText={errors.accredName}
            className={
              Boolean(errors.accredName)
                ? classes.styledTextFieldError
                : classes.styledTextField
            }
            fullWidth
            size="small"
          />
          <br />
          <Typography variant="body2" className={classes.typography}>
            Year obtained
          </Typography>
          <DateSelector
            variant="outlined"
            size="small"
            fullWidth
            placeholder="End Year..."
            format="yyyy"
            views={["year"]}
            onSelectDate={this.handleAccredDate}
            propMinDate={dayjs("1960-01-05")}
            propMaxDate={dayjs()}
            className={classes.styledTextField}
          />
          <br />
          <Typography variant="body2" className={classes.typography}>
            Description
          </Typography>
          <TextField
            variant="outlined"
            name="accredDescription"
            onChange={this.handleChange}
            value={accredDescription}
            fullWidth
            multiline
            minRows="2"
            size="small"
            className={classes.styledTextField}
          />

          <Divider style={{ margin: "8px 0px" }} />
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ textTransform: "none", width: 100 }}
              disabled={disableWhileLoad}
            >
              {isEdit ? "Save" : "Add"}{" "}
              {disableWhileLoad && (
                <CircularProgress
                  thickness={2}
                  size={30}
                  className={classes.progress}
                />
              )}
            </Button>
          </Box>
        </form>
      </Fade>
    );
  }
}

AccreditationMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  disableWhileLoad: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccreditationMarkup);
