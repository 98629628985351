import React, { useContext, useState, useEffect } from "react";
// import jwtDecode from "jwt-decode";
// import axios from "axios";
// import { SET_UNAUTHENTICATED, SET_CREDENTIALS } from "./redux/types";

// import store from "./redux/store";

import firebase from "./Firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signupFB(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  function loginFB(email, password) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err) => {
        console.error(err);
      });
  }

  function tokenSignIn(token) {
    return firebase
      .auth()
      .signInWithCustomToken(token)
      .catch((err) => {
        console.error(err);
      });
  }

  function logoutFB() {
    return firebase
      .auth()
      .signOut()
      .catch((err) => {
        console.error(err);
      });
  }

  function resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  // const getCurrentUserAuthorization = async () => {
  //   const currentUser = firebase.auth().currentUser;
  //   if (currentUser) {
  //     const token = await currentUser.getIdToken();
  //     dispatch(setFBAuthHeader(token));

  //     return token;
  //   } else {
  //     return "";
  //   }
  // };

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged((user) => {
      setCurrentUser(user);
      setLoading(false);

      // getCurrentUserAuthorization();
      // if (user) {
      //   user.getIdToken().then(function (idToken) {
      //     store.dispatch({ type: SET_AUTHENTICATED });
      //     dispatch(setFBAuthHeader(idToken));
      //   });
      // }
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    currentUser,
    loginFB,
    signupFB,
    logoutFB,
    resetPassword,
    updateEmail,
    updatePassword,
    tokenSignIn,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
