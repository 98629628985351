import React, { Component } from "react";
import { Helmet } from "react-helmet";

import JoinBanner from "./components/JoinBanner";

export class joinPoyo extends Component {
  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>Care with Poyo: Where Your Care Is Valued | Poyo Health</title>
          <meta name="description" content="Helmet application" />
        </Helmet>
        <section id="values" className="full-vw-container p-bg">
          <JoinBanner />
        </section>
        <section className="section-wrapper"></section>
      </div>
    );
  }
}

export default joinPoyo;
