import React, { Component } from "react";
import PropTypes from "prop-types";

import StyledNumberInput from "../../../../components/inputs/StyledNumberInput";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const PrettySlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    // marginTop: -8,
    // marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    // left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

export class WageBioMarkup extends Component {
  render() {
    const { classes, wageExp, errorWageExp, bio, errorBio, yearsExp, disable } =
      this.props;

    return (
      <Box style={{ textAlign: "left" }}>
        <Typography
          variant="h5"
          color="primary"
          className={classes.typography}
          style={{ marginBottom: 10 }}
        >
          Home Stretch
        </Typography>
        <Typography
          variant="h6"
          color="primary"
          className={classes.typography}
          style={{ margin: "10px 0px", fontWeight: 300 }}
        >
          Desired Wage
        </Typography>
        <Box className={classes.sectionBoxLeftOutline}>
          <StyledNumberInput
            variant="outlined"
            color="primary"
            placeholder="e.g. 20.00"
            type="number"
            step="0.01"
            value={parseFloat(wageExp).toFixed(2)}
            onChange={(e) => this.props.handleWage(e)}
            name="wageExp"
            size="small"
            id="formatted-numberformat-input"
            error={Boolean(errorWageExp)}
            helperText={Boolean(errorWageExp) ? errorWageExp : ""}
            className={
              !Boolean(errorWageExp)
                ? classes.styledTextField
                : classes.styledTextFieldError
            }
            fullWidth
          />

          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.typography}
            style={{ fontStyle: "italic" }}
          >
            *Please ensure that your desired wage is above your provincial
            minimum wage requirement
          </Typography>
        </Box>
        <Typography
          variant="h6"
          color="primary"
          className={classes.typography}
          style={{ margin: "10px 0px", fontWeight: 300 }}
        >
          About Me
        </Typography>

        <Box className={classes.sectionBoxLeftOutline}>
          <Typography
            variant="body2"
            className={classes.typography}
            style={{ margin: "20px 0px", fontWeight: 300 }}
          >
            How many years of experience do you have?
          </Typography>
          <PrettySlider
            key={`slider-yearsExp`}
            defaultValue={yearsExp}
            value={yearsExp}
            getAriaValueText={(v) => v}
            onChange={(e, v) => this.props.setYearsExp(v)}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={30}
          />
          <Typography
            color="secondary"
            align="center"
            style={{ marginBottom: 20 }}
          >
            {yearsExp} years
          </Typography>
          <Typography
            variant="body2"
            className={classes.typography}
            style={{ fontWeight: 300, marginBottom: 5 }}
          >
            Families love to get to know their caregivers before working
            together. Tell your families a little about yourself.
          </Typography>

          <TextField
            variant="outlined"
            name="bio"
            placeholder="I am a..."
            error={Boolean(errorBio)}
            helperText={Boolean(errorBio) ? errorBio : " "}
            onChange={(e) => this.props.handleBio(e)}
            className={
              !Boolean(errorBio)
                ? classes.styledTextField
                : classes.styledTextFieldError
            }
            value={bio}
            size="small"
            minRows="5"
            multiline
            fullWidth
          />
        </Box>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          style={{ width: "100%", marginTop: 15 }}
        >
          <Grid item>
            <StyledButton
              color="primary"
              onClick={() => this.props.handleBack()}
              disabled={disable}
              style={{ width: 100 }}
            >
              Back
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={() => this.props.handleComplete()}
              disabled={disable}
              style={{ width: 100 }}
            >
              Complete{" "}
              {disable && (
                <CircularProgress
                  thickness={2}
                  size={30}
                  className={classes.progress}
                />
              )}
            </StyledButton>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
WageBioMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWage: PropTypes.func.isRequired,
  wageExp: PropTypes.string,
  errorWageExp: PropTypes.string,
  yearsExp: PropTypes.number,
  setYearsExp: PropTypes.func.isRequired,
  bio: PropTypes.string.isRequired,
  handleBio: PropTypes.func.isRequired,
  errorBio: PropTypes.string,
  handleBack: PropTypes.func.isRequired,
  handleComplete: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};
export default withStyles(styles)(WageBioMarkup);
