import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import dayjs from "dayjs";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";
import OutlinedProfilePic from "../layout/OutlinedProfilePic";
import ReviewCard from "./ReviewCard";

// Redux imports
import { connect } from "react-redux";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import CardActionArea from "@mui/material/CardActionArea";
import Divider from "@mui/material/Divider";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ReviewsIcon from "@mui/icons-material/Reviews";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const swipeStyles = {
  root: {
    padding: "0 40px",
  },
  slideContainer: {
    padding: "0 10px",
  },
  slide: {
    padding: 12,
    minHeight: 100,
    borderRadius: 10,
  },
  slide1: {
    backgroundColor: "#FEA900",
  },
  slide2: {
    backgroundColor: "#B3DC4A",
  },
  slide3: {
    backgroundColor: "#6AC0FF",
  },
};

export class AllReviewsMarkup extends Component {
  state = {
    showMembers: false,
    showEligible: false,
    showReviewed: false,
    currMemberIndex: 0,
  };

  _toggleShowMembers = () => {
    const members = this.props.members;
    if (members.length > 0)
      this.setState((prevState) => ({
        currMemberIndex: 0,
        showMembers: !prevState.showMembers,
        showReviewed: false,
        showEligible: false,
      }));
  };

  _toggleEligible = () => {
    const members = this.props.members;
    if (members.length > 0)
      this.setState((prevState) => ({
        currMemberIndex: 0,
        showMembers: false,
        showReviewed: false,
        showEligible: !prevState.showEligible,
      }));
  };

  _toggleReviewed = () => {
    const members = this.props.members;
    if (members.length > 0)
      this.setState((prevState) => ({
        currMemberIndex: 0,
        showMembers: false,
        showReviewed: !prevState.showReviewed,
        showEligible: false,
      }));
  };

  handleChangeTeamIndex = (index) => {
    this.setState({ currMemberIndex: index });
  };

  render() {
    const { classes, reviews, members, credentials, isFamily } = this.props;
    const { showMembers, showEligible, showReviewed, currMemberIndex } =
      this.state;

    const isClickableReviewed =
      members.reduce((a, b) => (a + b.review.isReviewed ? 1 : 0), 0) > 0;

    const isClickableEligible =
      members.reduce((a, b) => (a + b.review.shouldInquire ? 1 : 0), 0) > 0;

    const isClickableMembers = members.length > 0;

    return (
      <div>
        <Grid container spacing={1} className={classes.form}>
          <Grid item xs={12}>
            <Typography variant="h4">My Reviews</Typography>
          </Grid>
          <Grid item xs={12}>
            <div
              className={classes.flexBoxCentered}
              style={{ flexDirection: "column" }}
            >
              <OutlinedProfilePic
                imgSrc={credentials.imgUrlThumb}
                alt="Profile pic"
              />
              <Typography variant="h6">
                {capitalizeFirstChar(credentials.firstName)}{" "}
                {capitalizeFirstChar(credentials.lastName)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            <CardActionArea
              className={clsx(
                (showReviewed || !isClickableReviewed) &&
                  classes.selectedGrayContainer,
                classes.flexBoxCenteredColumn,
                isClickableReviewed && classes.grayBorder
              )}
              onClick={this._toggleReviewed}
              style={{ minHeight: "100%", borderRadius: 10 }}
              disabled={
                members.reduce((a, b) => (a + b.review.isReviewed ? 1 : 0), 0) <
                1
              }
            >
              <Typography variant="h6" color="secondary" fontWeight="bold">
                {members.reduce((a, b) => (a + b.review.isReviewed ? 1 : 0), 0)}
              </Typography>
              <div className={classes.flexBox}>
                <Typography color="textSecondary" variant="subtitle2">
                  {isFamily ? "Completed" : "Reviews"}
                </Typography>
                {!isClickableReviewed ? null : !showReviewed ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </div>
            </CardActionArea>
          </Grid>
          <Grid item xs={3}>
            <CardActionArea
              className={clsx(
                (showEligible || !isClickableEligible) &&
                  classes.selectedGrayContainer,
                classes.flexBoxCenteredColumn,
                isClickableEligible && classes.grayBorder
              )}
              onClick={this._toggleEligible}
              style={{ minHeight: "100%", borderRadius: 10 }}
              disabled={
                members.reduce(
                  (a, b) => (a + b.review.shouldInquire ? 1 : 0),
                  0
                ) < 1
              }
            >
              <Typography variant="h6" color="secondary" fontWeight="bold">
                {members.reduce(
                  (a, b) => (a + b.review.shouldInquire ? 1 : 0),
                  0
                )}
              </Typography>
              <div className={classes.flexBox}>
                <Typography color="textSecondary" variant="subtitle2">
                  Eligible
                </Typography>
                {!isClickableEligible ? null : !showEligible ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </div>
            </CardActionArea>
          </Grid>
          <Grid item xs={3}>
            <div
              className={clsx(
                classes.selectedGrayContainer,
                classes.flexBoxCenteredColumn
              )}
              style={{ minHeight: "100%" }}
            >
              <Typography variant="h6" color="secondary" fontWeight="bold">
                {reviews.length > 0
                  ? isFamily
                    ? dayjs(reviews[0].createdAt).format("MMM DD, YYYY")
                    : credentials.reviewScore.toFixed(2)
                  : "N/A"}
              </Typography>
              <Typography color="textSecondary" variant="subtitle2">
                {isFamily ? "Latest" : "Avg Rating"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            <CardActionArea
              className={clsx(
                (showMembers || !isClickableMembers) &&
                  classes.selectedGrayContainer,
                classes.flexBoxCenteredColumn,
                isClickableMembers && classes.grayBorder
              )}
              onClick={this._toggleShowMembers}
              style={{ minHeight: "100%", borderRadius: 10 }}
              disabled={members.length < 1}
            >
              <Typography variant="h6" color="secondary" fontWeight="bold">
                {members.length}
              </Typography>
              <div className={classes.flexBox}>
                <Typography color="textSecondary" variant="subtitle2">
                  {isFamily ? "Members" : "Families"}
                </Typography>
                {!isClickableMembers ? null : !showMembers ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </div>
            </CardActionArea>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={showMembers || showEligible || showReviewed}>
              <div>
                <SwipeableViews
                  index={currMemberIndex}
                  onChangeIndex={this.handleChangeTeamIndex}
                  enableMouseEvents
                  resistance
                  style={swipeStyles.root}
                  slideStyle={swipeStyles.slideContainer}
                >
                  {members
                    .filter((member) =>
                      showEligible
                        ? member.review.shouldInquire
                        : showReviewed
                        ? member.review.isReviewed
                        : true
                    )
                    .map((member, ind) => (
                      <div
                        value={currMemberIndex}
                        className={clsx(
                          classes.flexBox,
                          classes.selectedGrayContainer,
                          classes.darkGrayBorderOnly
                        )}
                        style={{
                          ...swipeStyles.slide,
                        }}
                        index={ind}
                        key={member.id}
                      >
                        <OutlinedProfilePic
                          imgSrc={
                            isFamily
                              ? member.caregiverInfo.imgUrlThumb
                              : member.familyInfo.imgUrlThumb
                          }
                          alt="Profile Pic"
                          size={50}
                        />
                        <div style={{ flex: 1 }}>
                          <div className={classes.flexBox}>
                            <Typography style={{ marginLeft: 8 }}>
                              {capitalizeFirstChar(
                                isFamily
                                  ? member.caregiverInfo.firstName
                                  : member.familyInfo.clientFirstName
                              )}{" "}
                              {capitalizeFirstChar(
                                isFamily
                                  ? member.caregiverInfo.lastName
                                  : member.familyInfo.clientLastName
                              )}
                            </Typography>
                            <div
                              className={classes.flexBox}
                              style={{
                                flex: 1,
                                justifyContent: "flex-end",
                                marginLeft: 8,
                              }}
                            >
                              {member.review.isReviewed ? (
                                <CheckCircleIcon color="primary" />
                              ) : member.review.shouldInquire ? (
                                <PendingActionsIcon color="primary" />
                              ) : null}
                            </div>
                          </div>
                          <div
                            className={classes.flexBox}
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Button
                              variant="outlined"
                              component={Link}
                              to={`/reviews/${member.id}`}
                              className={classes.txtTrButton}
                              size="small"
                            >
                              View
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </SwipeableViews>
              </div>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={12} sm={11} lg={10} style={{ textAlign: "left" }}>
            <div style={{ padding: 10 }}>
              <Typography variant="h6" fontWeight={300}>
                Your Reviews
              </Typography>
              <div className={classes.titleLine} />
            </div>
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <ReviewCard
                  review={review}
                  clickable={isFamily}
                  key={review.id}
                />
              ))
            ) : isFamily ? (
              <div style={{ padding: 48, height: 200 }}>
                <Typography color="textSecondary">
                  Seems like you haven't reviewed any of your caregivers at the
                  moment.
                </Typography>
                <Typography color="textSecondary">
                  Click on members with the <PendingActionsIcon /> icon to write
                  a review.
                </Typography>
              </div>
            ) : (
              <Typography
                color="textSecondary"
                align="center"
                style={{ whiteSpace: "pre-line" }}
              >
                <ReviewsIcon fontSize="large" />
                {"\n\n"}
                Seems like you don't have any reviews from your families.
                {"\n\n"}
                Reviews help you be recognized for your work and is an integral
                part of new families looking to hire. Encorage your families to
                complete reviews when eligible.
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

AllReviewsMarkup.propTypes = {
  reviews: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  isFamily: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});
export default connect(mapStateToProps)(withStyles(styles)(AllReviewsMarkup));
