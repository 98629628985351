import React from "react";
import PropTypes from "prop-types";

// Component imports
import {
  isEmpty,
  toPhoneNum,
  phoneNumDisplay,
} from "../../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";

// Icon imports
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import GroupIcon from "@mui/icons-material/Group";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function EmergencyContactDisplay(props) {
  const { classes, ind, emergencyContact, showDelete, small } = props;
  return (
    <Fade in>
      <Box className={classes.hoverGrayContainer}>
        <Box display="flex" justifyContent="flex-end">
          <Chip
            className={
              Boolean(emergencyContact.primary)
                ? classes.chipBlue
                : classes.chipOrj
            }
            label={Boolean(emergencyContact.primary) ? "Primary" : "Secondary"}
            size="small"
          />
        </Box>
        <Box
          className={classes.flexBoxSpaced}
          style={{
            alignItems: "flex-start",
          }}
        >
          <Box style={{ width: showDelete ? "calc(100% - 35px)" : "100%" }}>
            <Box display="flex">
              <PermContactCalendarIcon color="primary" />
              <Typography
                variant={small ? "body2" : "inherit"}
                style={{ marginLeft: 4 }}
              >
                {emergencyContact.name}
              </Typography>
            </Box>
            <Box display="flex">
              <GroupIcon color="primary" />
              <Typography
                variant={small ? "body2" : "inherit"}
                style={{ marginLeft: 4, fontWeight: 300 }}
              >
                Relationship: {emergencyContact.relationship}{" "}
                {!isEmpty(emergencyContact.specifyRelationship) &&
                  `(${emergencyContact.specifyRelationship})`}
              </Typography>
            </Box>
            <Box display="flex">
              <PhoneIcon color="primary" />
              <Typography
                variant={small ? "body2" : "inherit"}
                style={{ marginLeft: 4, fontWeight: 300 }}
              >
                Contact Number:{" "}
                {phoneNumDisplay(toPhoneNum(emergencyContact.phoneNum))}
              </Typography>
            </Box>
          </Box>
          {showDelete && (
            <IconButton
              style={{ width: 30, height: 30 }}
              color="secondary"
              onClick={() => props.onDelete(ind)}
              size="large">
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Fade>
  );
}

EmergencyContactDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  emergencyContact: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  ind: PropTypes.number,
  showDelete: PropTypes.bool,
  small: PropTypes.bool,
};

export default withStyles(styles)(EmergencyContactDisplay);
