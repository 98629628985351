import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";

// Component imports
import AnimatedCheck from "../../assets/AnimatedCheck";
import { isEmail } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const styles = (theme) => ({
  ...theme.spreadThis,
  paperContainer: {
    borderRadius: 10,
    padding: "20px 0px 30px 0px",
    border: `1px solid rgba(101,101,101,0.5)`,
  },
  linkText: {
    fontWeight: 550,
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
});
export class forgotPassword extends Component {
  state = {
    email: "",
    disableWhileLoad: false,
    resetCodeSent: false,
    errors: { unknownEmail: false, empty: false, invalidEmail: false },
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  setEmail = (e) => {
    let value = e.target.value.trim();
    this.setState({
      [e.target.name]: value,
      errors: { empty: value === "" },
    });
  };

  checkEmailValid = async () => {
    const { email } = this.state;

    let isValidEmail = isEmail(email);
    await this.setStateAsync({
      errors: {
        ...this.state.errors,
        empty: email.trim() === "",
        invalidEmail: !isValidEmail,
      },
    });

    return isValidEmail;
  };

  submitEmailReset = async (e) => {
    e.preventDefault();
    await this.setStateAsync({ disableWhileLoad: true });

    const { errors, email } = this.state;
    await this.checkEmailValid();
    if (!errors.invalidEmail) {
      try {
        await axios.post("/forgot_password/reset", { email });
        this.setState({
          resetCodeSent: true,
          disableWhileLoad: false,
        });
      } catch (err) {
        console.error(err.response);
        this.setState({
          errors: { ...errors, unknownEmail: true },
          disableWhileLoad: false,
        });
        return;
      }
    }

    await this.setStateAsync({ disableWhileLoad: false });
  };

  render() {
    const { classes } = this.props;
    const { email, errors, disableWhileLoad, resetCodeSent } = this.state;

    let isError = errors.unknownEmail || errors.empty || errors.invalidEmail;
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ alignSelf: "center", padding: "36px 12px" }}
      >
        <Grid item xs={11} sm={7} md={6}>
          <Paper elevation={4} className={classes.paperContainer}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ position: "relative", margin: "0px 30px 20px 30px" }}
            >
              <IconButton
                component={Link}
                to="/login"
                size="small"
                style={{ position: "absolute", left: 0 }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="body2" style={{ fontWeight: 550 }}>
                Forgot Password?
              </Typography>
            </Box>
            <Divider />
            <Box style={{ margin: "30px 30px 0px 30px" }}>
              {!resetCodeSent && (
                <form onSubmit={this.submitEmailReset}>
                  <Typography color="textSecondary">
                    Enter the email address associated with your account, and
                    we’ll email you a link to reset your password.
                  </Typography>
                  <TextField
                    value={email}
                    name="email"
                    label="Email"
                    type="email"
                    variant="outlined"
                    onChange={this.setEmail}
                    fullWidth
                    className={
                      !isError
                        ? classes.styledTextField
                        : classes.styledTextFieldError
                    }
                    error={isError}
                    helperText={
                      errors.empty
                        ? "Must not be empty"
                        : errors.unknownEmail || errors.invalidEmail
                        ? "Invalid email. Please try again."
                        : null
                    }
                    style={{ marginTop: 15 }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={disableWhileLoad}
                    style={{
                      textTransform: "none",
                      marginTop: 175,
                      borderRadius: 10,
                      fontWeight: 550,
                      padding: 12,
                      color: "white",
                    }}
                  >
                    Send Reset Link{" "}
                    {disableWhileLoad && (
                      <CircularProgress
                        thickness={2}
                        size={25}
                        className={classes.progress}
                        style={{ right: 0, marginRight: 5 }}
                      />
                    )}
                  </Button>
                </form>
              )}
              <Fade in={resetCodeSent} mountOnEnter>
                <div style={{ height: 342 }}>
                  <Box display="flex" justifyContent="center">
                    <AnimatedCheck large />
                  </Box>
                  <Typography color="textSecondary" style={{ marginTop: 10 }}>
                    You're good to go! Please click the link sent to {email} to
                    complete your password reset.
                  </Typography>

                  <div style={{ marginTop: 50 }}>
                    <Typography
                      component={Link}
                      to="/login"
                      className={classes.linkText}
                    >
                      Back to login
                    </Typography>
                  </div>
                </div>
              </Fade>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

forgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(forgotPassword);
