import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter
import axios from "axios";
import clsx from "clsx";

// Redux imports
import { connect } from "react-redux";

// Component imports
import {
  isEmpty,
  capitalizeFirstChar,
  numberWithCommas,
  getDistanceGeohash,
} from "../../util/utilFunctions";
import ActionInvite from "./ActionInvite";
import AnimatedCheck from "../../assets/AnimatedCheck";

import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import HelpIcon from "@mui/icons-material/Help";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EventIcon from "@mui/icons-material/Event";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import WorkIcon from "@mui/icons-material/Work";
import CancelIcon from "@mui/icons-material/Cancel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0,
    },
  },
  focusHighlight: {},
  unreadMarker: {
    width: 8,
    height: 8,
    backgroundColor: `${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
});

export class InviteSingle extends Component {
  state = { alertSuccess: false, alertDecline: false };
  componentDidMount() {
    this.handleMarkRead();
  }

  handleMarkRead = async () => {
    const {
      teamInvite,
      credentials: { userType },
    } = this.props;
    if (userType === "caregiver" && !teamInvite.isRead) {
      await axios.get(`/team/invite/${teamInvite.id}/mark-read`);
    }
    return;
  };

  handleOnSuccess = async (action) => {
    this.setState(
      { alertSuccess: action === "accept", alertDecline: action === "decline" },
      () => {
        setTimeout(
          () => this.setState({ alertSuccess: false, alertDecline: false }),
          5000
        );
      }
    );
  };

  render() {
    const {
      classes,
      credentials,
      teamInvite,
      teamInvite: { familyInfo, caregiverInfo },
    } = this.props;

    const { alertSuccess, alertDecline } = this.state;

    const isFamily = credentials.userType === "family";

    return (
      <Box
        p={1}
        display="flex"
        flexDirection="column"
        style={{ width: "100%", position: "relative" }}
      >
        {alertDecline && (
          <Box
            className={classes.layoverUpdate}
            style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
          >
            <CancelIcon color="secondary" style={{ height: 60, width: 60 }} />

            <Typography align="center" style={{ margin: "16px 8px" }}>
              You have declined {capitalizeFirstChar(familyInfo.firstName)}'s
              invite.
            </Typography>
          </Box>
        )}

        {alertSuccess && (
          <Box
            className={classes.layoverUpdate}
            style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
          >
            <AnimatedCheck large />
            <Typography
              variant="h6"
              color="primary"
              style={{
                margin: 8,
              }}
            >
              Congrats on your new role!
            </Typography>
            <Typography
              align="center"
              style={{
                margin: "16px 8px",
              }}
            >
              You have accepted {capitalizeFirstChar(familyInfo.firstName)}'s
              invite. Please arrange with{" "}
              {capitalizeFirstChar(familyInfo.firstName)} for your first shift.
            </Typography>
          </Box>
        )}
        <Box className={classes.flexBoxSpaced}>
          <Box
            display="flex"
            alignItems="center"
            style={{ width: "calc(100% - 80px)" }}
          >
            <Avatar
              src={
                isFamily ? caregiverInfo.imgUrlThumb : familyInfo.imgUrlThumb
              }
            />
            <Box style={{ marginLeft: 8, width: "calc(100% - 48px)" }}>
              <Typography color="primary" noWrap>
                {capitalizeFirstChar(
                  isFamily
                    ? caregiverInfo.firstName
                    : familyInfo.clientFirstName
                )}{" "}
                {capitalizeFirstChar(
                  isFamily ? caregiverInfo.lastName : familyInfo.clientLastName
                )}{" "}
                {!isFamily && "(Client)"}
              </Typography>
              {!isFamily && (
                <Typography variant="subtitle2" color="textSecondary" noWrap>
                  {capitalizeFirstChar(familyInfo.firstName)}{" "}
                  {capitalizeFirstChar(familyInfo.lastName)}
                </Typography>
              )}
            </Box>
          </Box>
          {!teamInvite.isRead ? (
            <Chip size="small" label="Unread" className={classes.chipOrj} />
          ) : teamInvite.isAccepted ? (
            <Chip size="small" label="Accepted" className={classes.chipGreen} />
          ) : teamInvite.isDeclined ? (
            <Chip size="small" label="Declined" className={classes.chipOrj} />
          ) : (
            teamInvite.isRead && (
              <Chip size="small" label="Read" className={classes.chipBlue} />
            )
          )}
        </Box>

        <Grid
          container
          alignItems="center"
          className={clsx(classes.form, classes.grayContainerLight)}
          style={{
            margin: "8px 0px",
            padding: 8,
            borderRadius: 10,
            width: "100%",
          }}
          spacing={2}
        >
          <Grid item xs={6}>
            <Box className={classes.flexBoxCentered} style={{ width: "100%" }}>
              <MonetizationOnIcon color="primary" />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: 4 }}
              >
                Wage
              </Typography>
            </Box>
            <Typography variant="body2" color="secondary">
              ${numberWithCommas(teamInvite.propWage)}
              /hr{" "}
              <Tooltip
                disableFocusListener
                enterTouchDelay={0}
                placement="top"
                title={
                  <Typography variant="caption">
                    Proposed wage includes 4% vacation pay of $
                    {numberWithCommas(teamInvite.vacation)}
                    /hr in lieu of annual vacation time.
                  </Typography>
                }
              >
                <HelpIcon
                  color="action"
                  style={{ fontSize: 14, opacity: 0.7 }}
                />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.flexBoxCentered} style={{ width: "100%" }}>
              <EventIcon color="primary" />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: 4 }}
              >
                Start Date
              </Typography>
            </Box>

            <Typography variant="body2" color="secondary">
              {dayjs(teamInvite.startDate).format("MMM DD, YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.flexBoxCentered} style={{ width: "100%" }}>
              <AccessTimeIcon color="primary" />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: 4 }}
              >
                Weekly Hours
              </Typography>
            </Box>

            <Typography variant="body2" color="secondary">
              {teamInvite.estimatedHours}
            </Typography>
          </Grid>
          {!isFamily && (
            <Grid item xs={6}>
              <Box
                className={classes.flexBoxCentered}
                style={{ width: "100%" }}
              >
                <DriveEtaIcon color="primary" />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginLeft: 4 }}
                >
                  Distance
                </Typography>
              </Box>

              <Typography variant="body2" color="secondary">
                ~
                {numberWithCommas(
                  getDistanceGeohash(
                    credentials.address.geohash,
                    teamInvite.address.geohash
                  )
                )}
                km
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1} style={{ margin: "8px 0px" }}>
          <Grid item xs={6}>
            <Button
              component={Link}
              to={
                isFamily
                  ? `/profile/jobs/${teamInvite.jobId}`
                  : `/job/${teamInvite.jobId}`
              }
              color="primary"
              fullWidth
              style={{ borderRadius: 10 }}
            >
              <Box
                className={classes.flexBoxCentered}
                style={{ flexDirection: "column", color: "inherit" }}
              >
                <WorkIcon />
                <Typography variant="body2">VIEW JOB</Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item xs={6}>
            {isFamily ? (
              <Button
                component={Link}
                to={`/caregiver/${teamInvite.caregiverInfo.userIdNumber}`}
                color="primary"
                fullWidth
                style={{ borderRadius: 10 }}
              >
                <Box
                  className={classes.flexBoxCentered}
                  style={{ flexDirection: "column", color: "inherit" }}
                >
                  <AccountCircleIcon />
                  <Typography variant="body2">VIEW PROFILE</Typography>
                </Box>
              </Button>
            ) : (
              <ActionInvite
                fullWidth
                color="primary"
                variant="outlined"
                teamInvite={teamInvite}
                className={classes.txtTrButton}
                style={{ margin: 0 }}
                onSuccess={this.handleOnSuccess}
              />
            )}
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{
            fontWeight: 300,
            margin: "4px 0px",
          }}
        >
          Job ID: {teamInvite.jobId}
        </Typography>
        {!isEmpty(teamInvite.returnMessage) && (
          <Box
            className={classes.sectionBoxLeftOutlineRed}
            style={{ margin: "8px 0px" }}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ fontWeight: 300, fontStyle: "italic" }}
            >
              This application was actioned at{" "}
              {dayjs(teamInvite.actionAt).format("hh:mma MM/DD/YYYY")}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontWeight: 300,
                whiteSpace: "pre-line",
              }}
            >
              {teamInvite.returnMessage}
            </Typography>
          </Box>
        )}
        <Box
          className={classes.sectionBoxLeftOutline}
          style={{ margin: "8px 0px" }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontWeight: 300,
              whiteSpace: "pre-line",
            }}
          >
            {teamInvite.message}
          </Typography>
        </Box>
        <Button
          component={Link}
          to={`/invites/`}
          color="secondary"
          variant="outlined"
          fullWidth
          className={classes.txtTrButton}
          style={{ margin: "8px 0px" }}
        >
          View All Invites
        </Button>
        <Typography
          variant="caption"
          component="div"
          align="right"
          color="textSecondary"
        >
          Sent: {dayjs(teamInvite.createdAt).fromNow()}
        </Typography>
      </Box>
    );
  }
}

InviteSingle.propTypes = {
  credentials: PropTypes.object.isRequired,
  teamInvite: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  markRead: PropTypes.func,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps)(withStyles(styles)(InviteSingle));
