const themeFile = {
  palette: {
    // primary: {
    //   light: "#41D3BD",
    //   main: "#119 99e",
    //   dark: "#0b6b6e",
    //   contrastText: "#e4f9f5",
    // },
    // secondary: {
    //   light: "#e08b87",
    //   main: "#d96f6a",
    //   dark: "#974d4a",
    //   contrastText: "#e4f9f5",
    // },
    primary: {
      light: "#67e0ad",
      main: "#2dad7e",
      dark: "#007d52",
      contrastText: "#e4f9f5",
    },
    secondary: {
      light: "#f05369",
      main: "#b8173f",
      dark: "#810019",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#22234e",
    },
  },
  typography: {
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
  },
  overrides: {
    MuiStepper: {
      root: {
        background: "none",
        border: "none",
      },
    },
    MuiButton: {
      root: {
        transition: "color .01s",
      },
    },
  },

  spreadThis: {
    basicMargin: {
      margin: "4px 0px",
    },
    sectionBoxLeftOutline: {
      textAlign: "left",
      padding: "8px 8px 8px 24px",
      borderLeft: `3px solid #41D3BD`,
    },
    sectionBoxLeftOutlineRed: {
      textAlign: "left",
      padding: "8px 8px 8px 24px",
      borderLeft: `3px solid rgba(243,	10,	10, 1)`,
      backgroundColor: `rgba(245,221,219,0.5)`,
    },
    sectionBoxLeftOutlineBlue: {
      textAlign: "left",
      padding: "8px 8px 8px 24px",
      borderLeft: `3px solid rgb(227	241	253)`,
    },
    // typography: {
    //   useNextVariants: true,
    // },
    styledTextField: {
      margin: "8px auto",
      [`& fieldset`]: {
        borderRadius: 10,
      },
    },
    styledTextFieldError: {
      margin: "8px auto",
      [`& input`]: { color: "rgb(233,45,43)" },
      [`& fieldset`]: {
        borderRadius: 10,
        backgroundColor: "rgba(245,221,219,0.5)",
      },
    },
    styledTextFieldRound: {
      margin: "8px auto",
      [`& fieldset`]: {
        borderRadius: 25,
      },
    },
    styledTextFieldRoundError: {
      margin: "8px auto",
      [`& input`]: { color: "rgb(233,45,43)" },
      [`& fieldset`]: {
        borderRadius: 25,
        backgroundColor: "rgba(245,221,219,0.5)",
      },
    },

    styledTextFieldRight: {
      margin: "8px auto",
      [`& fieldset`]: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    styledTextFieldErrorRight: {
      margin: "8px auto",
      [`& input`]: { color: "rgb(233,45,43)" },
      [`& fieldset`]: {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: "rgba(245,221,219,0.5)",
      },
    },
    typographyHover: {
      userNextVariants: true,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    form: {
      textAlign: "center",
    },
    image: {
      margin: "20px auto",
      width: "50%",
      height: "auto",
    },

    textField: {
      margin: "8px auto",
    },
    standardWidthButton: {
      minWidth: 100,
      textTransform: "none",
      margin: "auto 8px",
    },
    button: {
      marginTop: 5,
      marginBottom: 5,
      position: "relative",
    },

    customError: {
      color: "red",
      fontSize: "0.8 rem",
      marginTop: "10px",
    },
    progress: {
      position: "absolute",
    },
    progressRight: {
      position: "absolute",
      right: 0,
      marginRight: 5,
    },

    buttons: {
      textAlign: "center",
      "& a": {
        margin: "20px 10px",
      },
    },

    signupForm: {
      marginTop: 20,
    },
    subText: {
      textDecoration: "underline",
      fontStyle: "italic",
    },
    paperContainerSignup: {
      padding: 40,
      borderRadius: 25,
    },
    paperContainer: {
      padding: "25px 50px 25px 50px",
    },

    paperContainerC: {
      borderRadius: 8,
      padding: 16,
      // border: "2px solid #17b890",
    },
    paperContainerD: {
      padding: 20,
      background: "linear-gradient(to bottom, #41D3BD 50%, #e4f9f5 50%)",
    },
    paperContainerE: {
      padding: 20,
      borderTop: "2px solid #17b890",
      backgroundColor: "#e4f9f5",
    },
    smallPaperContainer: {
      padding: 16,

      // border: "1px solid rgba(101,101,101,0.5)",
    },
    dropdownStyle: {
      border: "1px solid",
      borderRadius: 5,
    },
    overFlow: { overflowWrap: "break-word" },

    filterButton: {
      borderRadius: 25,
      textTransform: "none",
      height: 36,
      margin: "0px 4px",
    },
    filterButtonB: {
      height: "100%",
      borderRadius: 20,
      textTransform: "none",
      backgroundColor: "rgba(200, 200, 200, 0.9)",
      "&:hover": {
        backgroundColor: "rgba(180, 180, 180, 0.7)",
      },
    },
    dropdownButtonMenu: {
      marginRight: 20,
      marginLeft: 20,
    },
    chip: {
      margin: 4,
      padding: 5,
    },
    chipGreen: {
      margin: 4,
      backgroundColor: "rgb(233	245	232)",
      color: "rgb(63	89	60)",
    },
    chipOrj: {
      margin: 4,
      backgroundColor: "rgb(254	241	222)",
      color: "rgb(112	80	39)",
    },
    chipRed: {
      margin: 4,
      color: "rgb(233,45,43)",
      backgroundColor: "rgba(219,17,32,0.1)",
    },
    chipBlue: {
      margin: 4,
      backgroundColor: "rgb(227	241	253)",
      color: "rgb(41	70	100)",
    },
    chipGreenOutlined: {
      margin: 4,
      backgroundColor: "rgb(233	245	232)",
      color: "rgb(63	89	60)",
      border: "1px solid rgb(63	89	60)",
    },
    chipOrjOutlined: {
      margin: 4,
      backgroundColor: "rgb(254	241	222)",
      color: "rgb(112	80	39)",
      border: "1px solid rgb(112	80	39)",
    },
    chipRedOutlined: {
      margin: 4,
      color: "rgb(233,45,43)",
      backgroundColor: "rgba(219,17,32,0.1)",
      border: "1px solid rgb(233,45,43)",
    },
    chipBlueOutlined: {
      margin: 4,
      backgroundColor: "rgb(227	241	253)",
      color: "rgb(41	70	100)",
      border: "1px solid rgb(41	70	100)",
    },

    editItemButton: {
      textTransform: "none",
      borderRadius: 25,
      margin: 3,
      paddingTop: 1,
      paddingBottom: 1,
      paddingLeft: 10,
      paddingRight: 10,
    },
    errorBox: {
      border: "1px solid red",
      padding: 10,
      borderRadius: 10,
    },
    txtTrButton: {
      textTransform: "none",
      borderRadius: 25,
      margin: "10px 5px 0 5px",
      minWidth: 100,
    },
    responsiveIconButton: {
      position: "relative",
      left: "45%",
      top: "-5%",
    },
    msgContainer: {
      height: "40%",
      top: 0,
      left: 0,
      bottom: -20,
      right: -20,
      overflow: "scroll",
    },
    imgThumb: {
      height: 40,
      width: 40,
      objectFit: "cover",
      borderRadius: "50%",
    },
    msgDiv: {
      display: "flex",
      alignItems: "flex-start",
      margin: "10px 0 0 5px",
      width: "75%",
    },
    msgB: {
      display: "flex",
      margin: "0 0 0 0",
      justifyContent: "flex-end",
      maxWidth: "100%",
    },
    msgText: {
      marginLeft: 10,
      borderRadius: 15,
      backgroundColor: "#E4E6EB",
      padding: "5px 10px 5px 10px",
      width: "max-content",
      maxWidth: "75%",
      wordWrap: "break-word",
    },
    msgTextB: {
      borderRadius: 15,
      backgroundColor: "#0199FC",
      padding: "5px 10px 5px 10px",
      color: "white",
      width: "max-content",
      maxWidth: "100%",
      wordWrap: "break-word",
    },
    jobAppMedia: {
      height: 76,
      width: 76,
      objectFit: "cover",
      borderRadius: "50%",
    },

    avatarMedia: {
      height: 35,
      width: 35,
      objectFit: "cover",
      borderRadius: "50%",
    },
    flexBox: {
      display: "flex",
      alignItems: "center",
    },
    flexBoxColumn: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    flexBoxCentered: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flexBoxCenteredColumn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    flexBoxSpaced: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    flexBoxSpacedColumn: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
    },
    flexBoxEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    flexBoxEndColumn: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: "column",
    },
    dashCounterOrj: {
      backgroundColor: "rgb(254	241	222)",
      color: "rgb(112	80	39)",
      marginRight: 10,
      height: 35,
      width: 35,
    },
    dashCounterGreen: {
      backgroundColor: "rgb(233,245,232)",
      color: "rgb(63,89,60)",
      marginRight: 10,
      height: 35,
      width: 35,
    },
    dashCounterBlue: {
      backgroundColor: "rgb(227,241,253)",
      color: "rgb(41,70,100)",
      marginRight: 10,
      height: 35,
      width: 35,
    },

    avatarSelectButton: {
      margin: 3,
      width: 50,
      height: 50,
      padding: 0,
    },
    selAvatar: {
      width: 50,
      height: 50,
    },
    unSelAvatar: {
      width: 50,
      height: 50,
      filter: "grayscale(100%) ",
    },
    errorAvatar: {
      width: 50,
      height: 50,
      filter:
        "grayscale(100%) brightness(60%) sepia(100%) hue-rotate(-50deg) saturate(200%) contrast(0.8)",
    },
    accordionSummary: {
      maxHeight: 48,
    },
    selectedProgress: {
      flex: 1,
      height: 10,
      backgroundColor: "rgb(233	245	232)",
      border: "1px solid rgba(63,89,60,0.15)",
      borderRadius: 25,
      marginRight: 5,
      marginLeft: 5,
    },
    unselectedProgress: {
      flex: 1,
      height: 10,
      backgroundColor: "rgba(212,212,212,0.2)",
      border: "1px solid rgba(212,212,212,0.4)",
      borderRadius: 25,
      marginRight: 5,
      marginLeft: 5,
    },
    headerBarRounded: {
      padding: "8px 16px 8px 16px",
      backgroundColor: `rgba(212,212,212,0.2)`,
    },
    unselectedToggleButton: {
      // padding: "10px 5px 10px 5px",
      // borderRadius: 7,
      border: `1px solid rgba(98,98,98, 0.2)`,
      minWidth: 100,
      // "&:hover": {
      //   border: `1px solid rgba(98,98,98, 0.2)`,
      // },
    },
    selectedToggleButton: {
      // padding: "10px 5px 10px 5px",

      // borderRadius: 7,
      border: `1px solid rgba(212, 227, 243, 1)`,
      backgroundColor: "rgba(212, 227, 243, 0.7)",
      color: "rgba(8, 85, 155, 0.8)",
      "&:hover": {
        backgroundColor: "rgba(212, 227, 243, 1)",
        color: "rgba(8, 85, 155, 0.8)",
      },
    },
    errorSelectedToggleButton: {
      // padding: "10px 5px 10px 5px",
      // borderRadius: 7,
      borderColor: "rgba(243,	10,	10, 0.75)",
      backgroundColor: "rgba(243,	10,	10, 0.75)",
      color: "rgba(250,200,210, 1)",
      "&:hover": {
        borderColor: "rgba(243,	10,	10, 1)",
        backgroundColor: "rgba(243,	10,	10, 1)",
        color: "rgba(250,200,210, 1)",
      },
    },
    errorUnselectedToggleButton: {
      backgroundColor: "rgba(253,216,217,0.6)",
      color: "rgb(240,26,43)",
      "&:hover": {
        backgroundColor: "rgba(253,216,217,1)",
      },
    },
    layoverUpdate: {
      position: "absolute",
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(255,255,255,0.925)",
      zIndex: 1000,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    iconStyle: {
      height: 80,
      width: 80,
      opacity: 0.5,
    },
    grayBorder: {
      border: "1px solid rgba(212,212,212,0.7)",
    },
    darkGrayBorder: {
      border: `1px solid rgba(101,101,101,0.5)`,
      padding: 16,
    },
    darkGrayBorderOnly: {
      border: `1px solid rgba(101,101,101,0.5)`,
    },

    unreadMarker: {
      width: 8,
      height: 8,
      backgroundColor: `#d96f6a`, // secondary.main
      borderRadius: "50%",
    },
    absoluteIconButtonOnHover: {
      position: "absolute",
      opacity: 0.25,
      "&:hover": {
        opacity: 1,
      },
      height: 30,
      width: 30,
    },
    hoverGrayContainer: {
      borderRadius: 10,
      padding: 10,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(212,212,212,0.2)",
      },
    },
    selectedGrayContainer: {
      borderRadius: 10,
      padding: 10,

      backgroundColor: "rgba(212,212,212,0.15)",
    },
    grayContainerLight: {
      backgroundColor: "rgba(212,212,212,0.2)",
    },
    grayContainerDark: {
      backgroundColor: "rgba(212,212,212,0.7)",
    },
    mobileContainer: {
      borderRadius: 10,
      padding: 10,
    },
    errorBackground: {
      backgroundColor: "rgba(245,221,219,0.2)",
    },
    successBackground: {
      backgroundColor: "rgb(233	245	232)",
    },
    reviewCard: {
      margin: "12px 0px",
      padding: 16,
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 15px 0px",
    },
    titleLine: {
      width: 50,
      margin: "4px 0px",
      height: 4,
      backgroundColor: "#17b890",
    },
  },
};

export default themeFile;
