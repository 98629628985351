import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import ReviewCard from "../../../../components/review/ReviewCard";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import ReviewsIcon from "@mui/icons-material/Reviews";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ReviewBlock extends Component {
  render() {
    const { classes, isLoadedReviews, reviews, isFamily } = this.props;
    return (
      <div style={{ textAlign: "left" }}>
        <div className={classes.flexBoxSpaced}>
          <Typography
            component={!isFamily ? Link : Typography}
            to="/reviews"
            variant="h6"
            color="textPrimary"
            className={!isFamily ? classes.typographyHover : classes.typography}
            style={{ fontWeight: 300, marginBottom: 8 }}
          >
            Reviews
          </Typography>
          <div
            className={classes.selectedGrayContainer}
            style={{ marginBottom: 8, width: "fit-content" }}
          >
            <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
              {reviews.length > 0
                ? (
                    reviews.reduce((a, b) => a + b.score, 0) / reviews.length
                  ).toFixed(2)
                : 0}{" "}
              stars | {reviews.length} reviews
            </Typography>
          </div>
        </div>

        {!isLoadedReviews ? (
          [...new Array(5)].map((v, ind) => (
            <Skeleton
              key={ind}
              variant="rectangle"
              height={100}
              className={classes.basicMargin}
            />
          ))
        ) : reviews.length > 0 ? (
          <div>
            {reviews.map((review) => (
              <ReviewCard review={review} clickable={false} key={review.id} />
            ))}
            {!isFamily && (
              <Button
                component={Link}
                to="/reviews"
                fullWidth
                className={classes.txtTrButton}
              >
                Click here to review all reviews
              </Button>
            )}
          </div>
        ) : (
          <div style={{ padding: 16 }}>
            <Typography
              color="textSecondary"
              align="center"
              style={{ whiteSpace: "pre-line" }}
            >
              <ReviewsIcon fontSize="large" />
              {"\n\n"}
              Seems like you don't have any reviews from your families.{"\n\n"}
              Reviews help you be recognized for your work and is an integral
              part of new families looking to hire. Encorage your families to
              complete reviews when eligible.
            </Typography>
            <Button
              component={Link}
              to="/reviews"
              fullWidth
              className={classes.txtTrButton}
            >
              Click here to review all reviews
            </Button>
          </div>
        )}
      </div>
    );
  }
}

ReviewBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoadedReviews: PropTypes.bool.isRequired,
  reviews: PropTypes.array.isRequired,
  isFamily: PropTypes.bool,
};
export default withStyles(styles)(ReviewBlock);
