import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";

// Component imports
import ProfilePicMarkup from "./components/ProfilePicMarkup";
import BgCheckMarkup from "./components/BgCheckMarkup";
import SkipBgCheckDialog from "./components/SkipBgCheckDialog";
import Stepper from "../../../components/layout/Components/Stepper";

// REDUX imports
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const styles = (theme) => ({
  ...theme.spreadThis,

  media: {
    height: 350,
    width: 350,
    [theme.breakpoints.down("md")]: {
      height: 250,
      width: 250,
    },
    objectFit: "cover",
    borderRadius: "50%",
    border: "4px solid white",
  },
  responsiveIconButton: {
    position: "relative",
    left: "70%",
    top: "80%",
    border: `2px solid  ${theme.palette.primary.main}`,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgb(233	245	232)",
    },
  },
  bgCheckContainer: {
    border: "1px solid rgb(41	70	100)",
    borderRadius: 25,
    padding: 10,
    marginTop: 16,
    backgroundColor: "rgb(227	241	253)",
  },
  button: {
    textTransform: "none",
    minWidth: 79,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
    },
  },
  buttonContained: {
    minWidth: 79,
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
    },
  },
  snackbar: {
    marginTop: 56,
  },
});

export class caregiverFinish extends Component {
  state = {
    isProfilePic: false,
    isOpenSkipDialog: false,
    errors: {},
    stopLoadingAnimation: false,
  };

  componentDidMount() {
    this.checkAccountStatus();

    this.checkProfilePicUpdated();
  }

  checkAccountStatus() {
    const {
      isBasicCompleted,
      isServiceCompleted,
      isEduAccredCompleted,
      isProfileCompleted,
    } = this.props.user.credentials.status;
    if (!isBasicCompleted)
      this.props.history.push("/caregiver/signup/basic-info");

    if (!isServiceCompleted)
      this.props.history.push("/caregiver/signup/services");

    if (!isEduAccredCompleted)
      this.props.history.push("/caregiver/signup/education-experience");

    if (isProfileCompleted) this.props.history.push("/dashboard");
  }

  checkProfilePicUpdated() {
    const { isUserImgCompleted } = this.props.user.credentials.status;

    if (isUserImgCompleted) this.setState({ isProfilePic: true });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.credentials.status.isProfileCompleted) {
      this.props.history.push("/dashboard");
    }
  }

  handlePageChange = () => {
    this.setState({ isProfilePic: !this.state.isProfilePic });
  };

  handleFinish = async () => {
    if (!this.props.user.credentials.status.isBgCheckSubmitted) {
      this.setState({ isOpenSkipDialog: true });
    } else {
      await this._stopLoadingAnimation();
      this.handleFinishUser();
    }
  };

  handleFinishUser = async () => {
    try {
      await axios.post("/user/caregiver/finish");
    } catch (err) {
      return;
    }
  };

  // UI methods

  _stopLoadingAnimation() {
    this.setState({ stopLoadingAnimation: true });
  }

  handleSkipDialog = () => {
    this.setState({ isOpenSkipDialog: !this.state.isOpenSkipDialog });
  };

  render() {
    const {
      classes,
      user: { credentials },
    } = this.props;

    const { isProfilePic, stopLoadingAnimation, isOpenSkipDialog } = this.state;
    return (
      <Grid container justifyContent="center" style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={9} md={8} lg={7}>
          <Paper
            variant="elevation"
            elevation={4}
            className={clsx(
              classes.paperContainerSignup,
              classes.darkGrayBorderOnly
            )}
          >
            <Grid
              container
              justifyContent="center"
              style={{ marginBottom: 16 }}
            >
              <Stepper total={5} fadeIn={isProfilePic ? 5 : 4} />
            </Grid>
            {!isProfilePic ? (
              <ProfilePicMarkup
                credentials={credentials}
                handlePageChange={this.handlePageChange}
              />
            ) : (
              <BgCheckMarkup
                credentials={credentials}
                stopLoadingAnimation={stopLoadingAnimation}
                handlePageChange={this.handlePageChange}
                handleFinish={this.handleFinish}
              />
            )}
            <SkipBgCheckDialog
              open={isOpenSkipDialog}
              handleClose={this.handleSkipDialog}
              handleSkip={this.handleFinishUser}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

caregiverFinish.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(caregiverFinish));
