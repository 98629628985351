import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

// Component imports
import DropdownButton from "../../../../components/layout/DropdownButton";

import withStyles from "@mui/styles/withStyles";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";

import ClearAllIcon from "@mui/icons-material/ClearAll";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TooltipButton from "../../../../components/inputs/TooltipButton";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function sideScroll(element, direction, speed, distance, step) {
  let scrollAmount = 0;
  var slideTimer = setInterval(function () {
    if (direction === "left") {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
}

export class SearchFilters extends Component {
  componentDidMount() {
    this.setHorizontalScrollFilterBox();
  }

  setHorizontalScrollFilterBox() {
    let scrollSpeed = 20;

    let scroller = document.getElementById("filter_box");
    scroller.addEventListener(
      "mousewheel",
      (e) => {
        // block if e.deltaY==0
        if (!e.deltaY) return;
        // Set scrollDirection (-1 = up // 1 = down)
        let scrollDirection = e.deltaY > 0 ? 1 : -1;
        // convert vertical scroll into horizontal
        scroller.scrollLeft += scrollSpeed * scrollDirection;
        let scrollLeft = Math.round(scroller.scrollLeft);
        // calculate box total vertical scroll
        let maxScrollLeft = Math.round(
          scroller.scrollWidth - scroller.clientWidth
        );
        // if element scroll has not finished scrolling
        // prevent window to scroll
        if (
          (scrollDirection === -1 && scrollLeft > 0) ||
          (scrollDirection === 1 && scrollLeft < maxScrollLeft)
        )
          e.preventDefault();
        // done!
        return true;
      },
      false
    );
  }
  handleSelect = (name, value) => {
    this.props.handleSelect(name, value);
  };

  handleSelectService = (name, value) => {
    this.props.handleSelectService(value);
  };
  handleSelectConditions = (name, value) => {
    this.props.handleSelectConditions(value);
  };

  clearFilters = () => {
    this.props.clearFilters();
  };

  handleLeftScroll = () => {
    let scrollerLeft = document.getElementById("filter_box");
    sideScroll(scrollerLeft, "left", 25, 100, 10);
  };
  handleRightScroll = () => {
    let scrollerRight = document.getElementById("filter_box");
    sideScroll(scrollerRight, "right", 25, 100, 10);
  };

  handleLeftScrollSlow = () => {
    if (isMobile) {
      return false;
    } else {
      let scroller = document.getElementById("filter_box");

      const refreshIntervalId = setInterval(() => {
        scroller.scrollLeft -= 10;
      }, 100);
      this.setState({ refreshIntervalId });
    }
  };
  handleRightScrollSlow = () => {
    if (isMobile) {
      return false;
    } else {
      let scroller = document.getElementById("filter_box");
      const refreshIntervalId = setInterval(() => {
        scroller.scrollLeft += 10;
      }, 100);
      this.setState({ refreshIntervalId });
    }
  };

  handleRemoveAutoScroll = () => {
    if (isMobile) {
      return false;
    } else {
      clearInterval(this.state.refreshIntervalId);
    }
  };

  render() {
    const {
      classes,
      datePosted,
      serviceStart,
      servicePerWeek,
      serviceLength,
      languagePref,
      service,
      conditions,
      isLoadedFilters,
      filterMaterials,
      numFilters,
    } = this.props;

    const serviceStartData = {
      "Any Date": "",
      Immediately: "immediately",
      "In 1 Week": "1week",
      "In 1 Month": "1month",
      "Over 1 Month": "1month+",
    };
    const servicePerWeekData = {
      "Any Length": "",
      "Under 10 Hours": "under10",
      "Between 10 - 20 Hours": "10-20",
      "Over 20 Hours": "20+",
    };
    const serviceLengthData = {
      Any: "",
      "Under 1 Month": "underOneMonth",
      "Under 3 Months": "underThreeMonths",
      "Under 6 Months": "underSixMonths",
      "Over 6 Months": "overSixMonths",
    };
    const datePostedData = {
      Any: "",
      Today: "1",
      "This Week": "7",
      "This Month": "30",
    };
    const datePostedValue = Object.keys(datePostedData).find(
      (data) => datePostedData[data] === datePosted
    );

    const serviceStartValue = Object.keys(serviceStartData).find(
      (data) => serviceStartData[data] === serviceStart
    );
    const servicePerWeekValue = Object.keys(servicePerWeekData).find(
      (data) => servicePerWeekData[data] === servicePerWeek
    );
    const serviceLengthValue = Object.keys(serviceLengthData).find(
      (data) => serviceLengthData[data] === serviceLength
    );

    return (
      <Box
        className={classes.flexBoxSpaced}
        style={{ position: "relative", width: "100%" }}
      >
        <Box
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1000,
            height: "100%",
            background:
              "linear-gradient(90deg, rgba(255,255,255,0.99) 0%, rgba(255,255,255,0) 65%)",
          }}
        >
          <IconButton
            size="small"
            onClick={this.handleLeftScroll}
            // onMouseEnter={this.handleLeftScrollSlow}
            // onMouseLeave={this.handleRemoveAutoScroll}
            style={
              {
                // backgroundColor: "red",
              }
            }
          >
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Box
          id="filter_box"
          style={{
            overflowX: "scroll",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            // maxWidth: "inherit",
            flex: 1,
          }}
          className="hideScroll"
          // onWheel={this.onWheel}
        >
          {!isLoadedFilters ? (
            <LinearProgress style={{ margin: "0px 43px" }} />
          ) : (
            <Fragment>
              <DropdownButton
                color="inherit"
                radio
                closeOnSelect
                btnVariant="outlined"
                header="Date Posted"
                btnText={
                  <Typography component="div" variant="body2">
                    Posting Date{" "}
                    {datePosted !== "" && (
                      <span style={{ fontWeight: 550 }}>
                        {"("}
                        {datePostedValue ? datePostedValue : "Other"}
                        {")"}
                      </span>
                    )}
                  </Typography>
                }
                selectionObj={datePostedData}
                onSelect={this.handleSelect}
                name="datePosted"
                value={datePosted}
                className={classes.filterButton}
                style={{ marginLeft: 43 }}
              />

              <DropdownButton
                color="inherit"
                radio
                closeOnSelect
                btnVariant="outlined"
                header="Start Date"
                btnText={
                  <Typography component="div" variant="body2">
                    Start Date{" "}
                    {serviceStart !== "" && (
                      <span style={{ fontWeight: 550 }}>
                        {"("}
                        {serviceStartValue ? serviceStartValue : "Other"}
                        {")"}
                      </span>
                    )}
                  </Typography>
                }
                selectionObj={serviceStartData}
                onSelect={this.handleSelect}
                name="serviceStart"
                value={serviceStart}
                className={classes.filterButton}
              />
              <DropdownButton
                color="inherit"
                radio
                closeOnSelect
                xs={12}
                btnVariant="outlined"
                header="Weekly Hours"
                width={200}
                btnText={
                  <Typography component="div" variant="body2">
                    Hours Per Week{" "}
                    {servicePerWeek !== "" && (
                      <span style={{ fontWeight: 550 }}>
                        {"("}
                        {servicePerWeekValue ? servicePerWeekValue : "Other"}
                        {")"}
                      </span>
                    )}
                  </Typography>
                }
                selectionObj={servicePerWeekData}
                onSelect={this.handleSelect}
                name="servicePerWeek"
                value={servicePerWeek}
                className={classes.filterButton}
              />
              <DropdownButton
                color="inherit"
                radio
                closeOnSelect
                xs={12}
                btnVariant="outlined"
                header="Service Length"
                width={200}
                btnText={
                  <Typography component="div" variant="body2">
                    Length{" "}
                    {serviceLength !== "" && (
                      <span style={{ fontWeight: 550 }}>
                        {"("}
                        {serviceLengthValue ? serviceLengthValue : "Other"}
                        {")"}
                      </span>
                    )}
                  </Typography>
                }
                selectionObj={serviceLengthData}
                onSelect={this.handleSelect}
                name="serviceLength"
                value={serviceLength}
                className={classes.filterButton}
              />
              <DropdownButton
                color="inherit"
                radio
                closeOnSelect
                btnVariant="outlined"
                header="Language"
                btnText={
                  <Typography component="div" variant="body2">
                    Language{" "}
                    {languagePref !== "" && (
                      <span style={{ fontWeight: 550 }}>
                        {"("}
                        {languagePref.toUpperCase()}
                        {")"}
                      </span>
                    )}
                  </Typography>
                }
                selectionObj={{ ...filterMaterials.language, Any: "" }}
                onSelect={this.handleSelect}
                name="languagePref"
                value={languagePref}
                className={classes.filterButton}
              />
              <DropdownButton
                color="inherit"
                check
                btnVariant="outlined"
                header="Services Rendered"
                btnText={
                  <Typography component="div" variant="body2">
                    Services{" "}
                    {service.length > 0 && (
                      <span style={{ fontWeight: 550 }}>
                        {"("}
                        {service.length}
                        {")"}
                      </span>
                    )}
                  </Typography>
                }
                selectionObj={filterMaterials.service}
                onSelect={this.handleSelectService}
                name="service"
                value={service}
                className={classes.filterButton}
              />
              <DropdownButton
                color="inherit"
                check
                btnVariant="outlined"
                header="Specialized Conditions"
                btnText={
                  <Typography component="div" variant="body2">
                    Conditions{" "}
                    {conditions.length > 0 && (
                      <span style={{ fontWeight: 550 }}>
                        {"("}
                        {conditions.length}
                        {")"}
                      </span>
                    )}
                  </Typography>
                }
                selectionObj={filterMaterials.conditions}
                onSelect={this.handleSelectConditions}
                name="conditions"
                value={conditions}
                className={classes.filterButton}
                style={{ marginRight: 43 }}
              />
            </Fragment>
          )}
        </Box>
        <Box
          style={{
            position: "absolute",
            right: 43,
            top: 0,
            zIndex: 1000,
            height: "100%",

            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 35%, rgba(255,255,255,0.99) 100%)",
          }}
        >
          <IconButton
            size="small"
            onClick={this.handleRightScroll}
            // onMouseEnter={this.handleRightScrollSlow}
            // onMouseLeave={this.handleRemoveAutoScroll}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
        <Box className={classes.flexBox}>
          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "0px 4px" }}
          />

          <TooltipButton
            enterTouchDelay={0}
            tip="Clear"
            size="small"
            onClick={this.clearFilters}
            color={numFilters > 0 ? "secondary" : "inherit"}
            disabled={numFilters < 1}
          >
            <ClearAllIcon />
          </TooltipButton>
        </Box>
      </Box>
    );
  }
}

SearchFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  service: PropTypes.array.isRequired,
  conditions: PropTypes.array.isRequired,
  languagePref: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSelectService: PropTypes.func.isRequired,
  handleSelectConditions: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filterMaterials: PropTypes.object.isRequired,
  numFilters: PropTypes.number.isRequired,
};

export default withStyles(styles)(SearchFilters);
