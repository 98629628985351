import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ReviewCard extends Component {
  render() {
    const { classes, review, clickable } = this.props;
    return (
      <Card elevation={0} className={classes.reviewCard}>
        <div className={classes.flexBoxSpaced}>
          <div>
            {clickable && (
              <Typography variant="h5" fontWeight={300}>
                {capitalizeFirstChar(review.caregiverInfo.firstName)}{" "}
                {capitalizeFirstChar(review.caregiverInfo.lastName)}
              </Typography>
            )}

            <Typography variant="h6">{review.title}</Typography>
            <Typography variant="caption">
              By: {capitalizeFirstChar(review.familyInfo.firstName)}{" "}
              {capitalizeFirstChar(review.familyInfo.lastName)} | Posted:{" "}
              {dayjs(review.createdAt).format("MMM DD, YYYY")}
            </Typography>
          </div>
          {clickable && (
            <IconButton component={Link} to={`/reviews/${review.teamMemberId}`}>
              <EditOutlinedIcon color="primary" />
            </IconButton>
          )}
        </div>
        <div className={classes.flexBoxSpaced} style={{ margin: "8px 0px" }}>
          <div className={classes.selectedGrayContainer}>
            {["empathy", "punctuality", "knowledge", "helpfulness"].map(
              (key) => (
                <div key={key} className={classes.flexBox}>
                  <Typography variant="body2" style={{ width: 100 }}>
                    {capitalizeFirstChar(key)}
                  </Typography>
                  <Rating
                    readOnly
                    name={key}
                    value={review.breakdown[key]}
                    size="small"
                  />
                </div>
              )
            )}
          </div>
          <div
            className={classes.flexBoxCentered}
            style={{
              flexDirection: "column",
              backgroundColor: "honeydew",
              borderRadius: "50%",
              width: 80,
              height: 80,
              padding: 12,
              textAlign: "center",
            }}
          >
            <Typography color="secondary" fontWeight="bold" variant="h5">
              {review.score}
            </Typography>
            <Typography variant="body2">Total</Typography>
          </div>
        </div>
        <Divider />
        <Typography
          variant="body2"
          style={{
            whiteSpace: "pre-line",
            paddingTop: 8,
          }}
        >
          {review.message}
        </Typography>
      </Card>
    );
  }
}

ReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
  review: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
};

export default withStyles(styles)(ReviewCard);
