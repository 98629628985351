import React from "react";
import dayjs from "dayjs";

import { capitalizeFirstChar } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const GreenRadio = withStyles({
  root: {
    color: "#41D3BD",
    "&$checked": {
      color: "#17b890",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: "#e08b87",
    "&$checked": {
      color: "#d96f6a",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function RadioJobSelect({ jobData, classes, ...props }) {
  return (
    <Box
      component="label"
      style={{
        display: "flex",
        alignItems: "flex-start",
        marginBottom: 8,
      }}
    >
      {!props.errors.currJob ? (
        <GreenRadio
          checked={props.currJob === jobData.id}
          onChange={(e) => props.handleJobSelect(e)}
          value={jobData.id}
          disabled={props.isInvited}
        />
      ) : (
        <RedRadio
          checked={props.currJob === jobData.id}
          onChange={(e) => props.handleJobSelect(e)}
          value={jobData.id}
          disabled={props.isInvited}
        />
      )}
      <Box style={{ width: "calc(100% - 42px)" }}>
        <Box className={classes.flexBoxSpaced} style={{ marginBottom: 4 }}>
          <Typography
            component="div"
            align="left"
            variant="body2"
            color={props.errors.currJob ? "error" : "initial"}
            style={{ width: "100%", fontWeight: 550 }}
          >
            Status:
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            {(props.isAccepted || props.isInvited) && (
              <Chip
                label={props.isAccepted ? "Accepted" : "Invited"}
                size="small"
                style={{
                  backgroundColor: "rgb(233,245,232)",
                  color: "rgb(26,55,23)",
                  marginRight: 3,
                }}
              />
            )}
            {props.isApplied && (
              <Chip
                label="Applied"
                size="small"
                style={{
                  backgroundColor: "rgb(233,245,232)",
                  color: "rgb(26,55,23)",
                  marginRight: 3,
                }}
              />
            )}
          </Box>
        </Box>
        <Typography
          component="div"
          noWrap
          align="left"
          variant="body2"
          color={props.errors.currJob ? "error" : "initial"}
          style={{ width: "100%", fontWeight: 300 }}
        >
          <Box display="inline" style={{ fontWeight: 500 }}>
            Date Posted:{" "}
          </Box>
          {dayjs(jobData.createdAt).format("MMM DD, YYYY")}
        </Typography>
        <Typography
          component="div"
          noWrap
          align="left"
          variant="body2"
          color={props.errors.currJob ? "error" : "initial"}
          style={{ width: "100%", fontWeight: 300 }}
        >
          <Box display="inline" style={{ fontWeight: 500 }}>
            Description:{" "}
          </Box>
          {jobData.description}
        </Typography>
        <Typography
          component="div"
          noWrap
          align="left"
          variant="body2"
          color={props.errors.currJob ? "error" : "initial"}
          style={{ width: "100%", fontWeight: 300 }}
        >
          <Box display="inline" style={{ fontWeight: 500 }}>
            Services:{" "}
          </Box>
          {Object.keys(jobData.service)
            .map((value) =>
              capitalizeFirstChar(value.split(/(?=[A-Z])/).join(" "))
            )
            .join(", ")}
        </Typography>
        <Typography
          component="div"
          noWrap
          align="left"
          variant="body2"
          color={props.errors.currJob ? "error" : "initial"}
          style={{ width: "100%", fontWeight: 300 }}
        >
          <Box display="inline" style={{ fontWeight: 500 }}>
            Conditions:{" "}
          </Box>
          {Object.keys(jobData.conditions)
            .map((condition) =>
              capitalizeFirstChar(condition.split(/(?=[A-Z])/).join(" "))
            )
            .join(", ")}
        </Typography>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(RadioJobSelect);
