import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import withStyles from '@mui/styles/withStyles';
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

import { lightBlue } from "@mui/material/colors";

const styles = (theme) => ({
  ...theme.spreadThis,

  jobButton: {
    color: `${theme.palette.primary.main}`,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      color: "white",
    },
    height: "100%",
  },
  counter: {
    width: 30,
    height: 30,
    backgroundColor: lightBlue[500],
  },
});

export class JobProfile extends Component {
  render() {
    const { classes, jobData } = this.props;

    return (
      <CardActionArea
        component={Link}
        to={`/profile/jobs/${jobData.id}`}
        style={{ padding: 4 }}
      >
        <Box className={classes.flexBoxSpaced}>
          <div
            className={classes.flexBoxCentered}
            style={{ flexDirection: "column" }}
          >
            <Avatar className={classes.counter}>{jobData.numApply}</Avatar>
            <Typography variant="body2">Apps</Typography>
          </div>
          <div style={{ width: "calc(100% - 45px)" }}>
            <Typography
              align="left"
              variant="subtitle2"
              noWrap
              style={{ fontWeight: 300 }}
            >
              {jobData.description}
            </Typography>
            <Box className={classes.flexBoxSpaced}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="left"
              >
                {dayjs(jobData.createdAt).format("MMM DD, YYYY")}
              </Typography>
              <Button variant="text" size="small" className={classes.jobButton}>
                View More
              </Button>
            </Box>
          </div>
        </Box>
      </CardActionArea>
    );
  }
}

JobProfile.propTypes = {
  jobData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobProfile);
