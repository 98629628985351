import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";

import AnimatedCheck from "../../../../assets/AnimatedCheck";
import {
  allFalse,
  checkAll,
  isEmail,
  isEmpty,
} from "../../../../util/utilFunctions";

// Redux imports
import { connect } from "react-redux";
import { loginUser } from "../../../../redux/actions/userActions";
import { withLoginHOC } from "../../../../util/withFBHooks";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// Icons imports
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CancelIcon from "@mui/icons-material/Cancel";

const styles = (theme) => ({
  ...theme.spreadThis,

  linkText: {
    fontWeight: 550,
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
});

export class SignupCaregiverForm extends Component {
  state = {
    email: "",
    password: "",
    confirmPassword: "",
    errors: { errorAllCheck: true },
    passwordChecker: {
      isCapital: false,
      isLower: false,
      isNumber: false,
      isLength: false,
    },
    showPassword: false,
    disableWhileLoad: false,
    passwordCheckers: [
      "Includes at least 1 upper case letter",
      "Includes at least 1 lower case letter",
      "Includes at least 1 digit",
      "Minimum 8 characters in total",
    ],
  };

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  };
  // Error validation

  handleValidateConfirmNewPassword = (inputPassword) => {
    const { password } = this.state;

    const passwordSameOrGreaterLength = inputPassword.length >= password.length;

    return (
      passwordSameOrGreaterLength && password.trim() !== inputPassword.trim()
    );
  };

  validateSignupCredentials = () => {
    const { email, password, confirmPassword, errors } = this.state;
    const _errors = { ...errors };
    if (isEmpty(email)) {
      _errors.email = "Must not be empty";
    } else if (!isEmail(email)) {
      _errors.email = "Invalid email address";
    }

    if (isEmpty(password)) {
      _errors.passwordRequirements = false;

      _errors.password = "Must not be empty";
    } else if (errors.errorAllCheck) {
      _errors.passwordRequirements = true;

      _errors.password = "Password does not meet safety requirements";
    } else if (password !== confirmPassword) {
      _errors.passwordRequirements = false;

      _errors.confirmPassword = "Passwords must match";
    } else {
      _errors.passwordRequirements = false;
    }
    this.setState({ errors: { ..._errors } });
  };

  // Action Methods
  handleEmail = (e) => {
    const value = e.target.value;
    this.setState({
      email: value,
      errors: {
        ...this.state.errors,
        email: isEmpty(value) ? "Must not be empty" : null,
      },
    });
  };

  handlePassword = (e) => {
    const value = e.target.value.trim();

    const { isCapital, isLower, isNumber, isLength, allCheck } =
      checkAll(value);
    this.setState({
      password: value,
      passwordChecker: {
        isCapital,
        isLower,
        isNumber,
        isLength,
      },
      errors: {
        ...this.state.errors,
        errorAllCheck: !allCheck,
        password: isEmpty(value) ? "Must not be empty" : null,
        confirmPassword:
          value === this.state.confirmPassword
            ? null
            : this.state.errors.confirmPassword,
      },
    });
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  handleConfirmPassword = (e) => {
    const value = e.target.value.trim();

    let notMatch = this.handleValidateConfirmNewPassword(value);
    if (isEmpty(this.state.password) && notMatch) notMatch = false;

    this.setState({
      confirmPassword: value,
      errors: {
        ...this.state.errors,
        confirmPassword: isEmpty(value)
          ? "Must not be empty"
          : notMatch
          ? "Passwords must match"
          : null,
      },
    });
  };

  handleSubmit = async (event) => {
    const { errors, email, password, confirmPassword } = this.state;
    event.preventDefault();
    await this.setStateAsync({ disableWhileLoad: true });

    await this.validateSignupCredentials();
    const noErrors = allFalse(errors);
    if (noErrors) {
      try {
        await axios.post("/signup", {
          email,
          password,
          confirmPassword,
        });

        this.handleLoginAfterSignup();
      } catch (err) {
        console.error(err.response.data);

        await this.setStateAsync({
          disableWhileLoad: false,
          errors: err.response.data,
        });

        return;
      }
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  handleLoginAfterSignup = () => {
    const { email, password } = this.state;

    this.props.loginHook(email, password);
    this.props.loginUser({ email, password });
  };

  render() {
    const { classes, hideRequirements } = this.props;

    const {
      errors,
      showPassword,
      email,
      password,
      confirmPassword,
      disableWhileLoad,
      passwordCheckers,
      passwordChecker: { isCapital, isLower, isNumber, isLength },
    } = this.state;

    const passwordRequirements = passwordCheckers.map((k, i) => {
      const notError =
        i === 0 ? isCapital : i === 1 ? isLower : i === 2 ? isNumber : isLength;
      return (
        <Box
          display="flex"
          justifyContent="space-between"
          style={{
            height: 20,
            paddingTop: 2,
            paddingBottom: 2,
            width: "100%",
          }}
          key={i}
        >
          <Typography
            component="div"
            variant="caption"
            color={notError ? "textSecondary" : "error"}
          >
            {k}
          </Typography>
          {notError ? (
            <AnimatedCheck />
          ) : (
            <CancelIcon color="error" style={{ fontSize: 16 }} />
          )}
        </Box>
      );
    });

    return (
      <form noValidate onSubmit={this.handleSubmit}>
        <TextField
          id="email"
          name="email"
          type="email"
          label="Email"
          variant="outlined"
          className={
            !Boolean(errors.email)
              ? classes.styledTextField
              : classes.styledTextFieldError
          }
          helperText={errors.email}
          error={Boolean(errors.email)}
          value={email}
          onChange={this.handleEmail}
          inputProps={{ inputMode: "email" }}
          fullWidth
        />

        <TextField
          id="password"
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={this.handlePassword}
          error={Boolean(errors.password)}
          className={
            !Boolean(errors.password)
              ? classes.styledTextField
              : classes.styledTextFieldError
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  edge="end"
                  size="small"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          helperText={errors.password}
        />

        <TextField
          id="confirmPassword"
          name="confirmPassword"
          type={showPassword ? "text" : "password"}
          label="Confirm Password"
          variant="outlined"
          className={
            !Boolean(errors.confirmPassword)
              ? classes.styledTextField
              : classes.styledTextFieldError
          }
          helperText={errors.confirmPassword}
          error={Boolean(errors.confirmPassword)}
          value={confirmPassword}
          onChange={this.handleConfirmPassword}
          fullWidth
        />
        {!hideRequirements && (
          <>
            <Typography
              variant="body2"
              color="textSecondary"
              align="left"
              style={{ marginTop: 10, fontWeight: 550 }}
            >
              Password Requirements
            </Typography>
            <Divider style={{ marginBottom: 5 }} />
            {passwordRequirements}
          </>
        )}
        {errors.general && (
          <Typography variant="body2" className={classes.customError}>
            {errors.general}
          </Typography>
        )}
        <Typography
          align="left"
          color="textSecondary"
          style={{ whiteSpace: "pre-line", fontSize: 12, marginTop: 24 }}
        >
          By proceeding, I agree to Poyo Health's{" "}
          <Box
            component={Link}
            to="/policies/terms-of-use"
            style={{ textDecoration: "underline" }}
          >
            Terms of Use
          </Box>{" "}
          and acknowledge that I have read the{" "}
          <Box
            component={Link}
            to="/policies/privacy"
            style={{ textDecoration: "underline" }}
          >
            Privacy Policy
          </Box>
          .{"\n\n"}I also agree that Poyo Health or its representatives may
          contact me by email, phone, or SMS (including by automated means) at
          the email address or number I provide, including for marketing
          purposes.
        </Typography>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className={classes.button}
          disabled={disableWhileLoad}
          style={{ marginTop: 12 }}
          fullWidth
        >
          Sign up
          {disableWhileLoad && (
            <CircularProgress
              thickness={2}
              size={30}
              className={classes.progress}
              style={{ position: "absolute", right: 0, marginRight: 5 }}
            />
          )}
        </Button>
        <Typography
          component="div"
          align="center"
          variant="body2"
          style={{ marginTop: 8 }}
        >
          Already have an account?{" "}
          <Box component={Link} to="/login" className={classes.linkText}>
            Log in
          </Box>
        </Typography>
        {hideRequirements && errors.passwordRequirements && (
          <div className={classes.sectionBoxLeftOutlineRed}>
            {passwordRequirements}
          </div>
        )}
      </form>
    );
  }
}

SignupCaregiverForm.propTypes = {
  classes: PropTypes.object.isRequired,
  hideRequirements: PropTypes.bool,
};

const mapActionToProps = {
  loginUser,
};

export default connect(
  null,
  mapActionToProps
)(withLoginHOC(withStyles(styles)(SignupCaregiverForm)));
