import React, { useLayoutEffect, useState } from "react";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const FullVH = ({ children }) => {
  // eslint-disable-next-line
  const [width, height] = useWindowSize();

  return (
    <div
      style={{
        height: `calc(${height}px - 56px)`,
        minHeight: `calc(${height}px - 56px)`,
        maxHeight: `calc(${height}px - 56px)`,
      }}
    >
      {children}
    </div>
  );
};

export default FullVH;
