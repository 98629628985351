import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import { browserName, deviceType, mobileVendor } from "react-device-detect";

// Component imports
import { isEmpty, isEmail } from "../../../util/utilFunctions";
import AnimatedCheck from "../../../assets/AnimatedCheck";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class Contact extends React.Component {
  state = {
    loading: false,
    name: "",
    email: "",
    subject: "",
    message: "",
    errors: {},
    successSubmit: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Action Methods
  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    this.setState({ [name]: value }, () => {
      if (name === "email") this.validateEmail(name, value);
      else this.validateEmpty(name, value);
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const [valid, _inquiryDoc] = await this.validateSubmit();

    if (valid) {
      await this.setStateAsync({ loading: true });

      const inquiryDoc = {
        ..._inquiryDoc,
        browserName,
        deviceType,
        mobileVendor,
      };

      await axios.post("/contact-us/submit", inquiryDoc);

      await this.setStateAsync({
        loading: false,
        name: "",
        email: "",
        subject: "",
        message: "",
        errors: {},
        successSubmit: true,
      });
      setTimeout(() => {
        this.setState({ successSubmit: false });
      }, 5000);
    }
  };

  // Error handle Methods
  validateEmpty = (name, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: isEmpty(value) ? "Must not be empty" : false,
      },
    });
  };

  validateEmail = (name, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: isEmpty(value)
          ? "Must not be empty"
          : !isEmail(value)
          ? "Must be a valid email"
          : false,
      },
    });
  };

  validateSubmit = () => {
    const { name, email, subject, message } = this.state;
    const errors = {};
    if (isEmpty(name)) errors.name = "Must not be empty";
    if (!isEmail(email)) errors.email = "Must be a valid email";
    if (isEmpty(subject)) errors.subject = "Must not be empty";
    if (isEmpty(message)) errors.message = "Must not be empty";

    this.setState({ errors: errors });
    return [Object.keys(errors).length < 1, { name, email, subject, message }];
  };

  render() {
    const { loading, name, email, subject, message, errors, successSubmit } =
      this.state;
    const { classes } = this.props;
    return (
      <div className="page-wrapper">
        <section className="full-vw-container p-bg bottom-drop section-title">
          <div className="header-title contrast-txt">
            <Typography variant="h3" className="txt-bold">
              Contact Us
            </Typography>
            <Typography className="description-txt">
              If you need immediate assistance, please visit our{" "}
              <Typography
                component={Link}
                color="inherit"
                to="resources"
                className="txt-bold"
              >
                resource center
              </Typography>
              .
            </Typography>
            <Typography className="description-txt">
              For additional assistance, please fill in the form below and we'll
              get back to you within 1-3 business days.
            </Typography>
          </div>
        </section>
        <Grid container justifyContent="center" style={{ padding: "36px 0px" }}>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={5}
            className="flexbox-centered-column"
          >
            <form onSubmit={this.handleSubmit} className="display-card">
              {successSubmit && (
                <div
                  className={classes.layoverUpdate}
                  style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
                >
                  <AnimatedCheck large />
                  <Typography color="textSecondary" className="description-txt">
                    Inqury Submitted!
                  </Typography>
                  <Typography color="textSecondary" className="description-txt">
                    We'll get back to you shortly.
                  </Typography>
                </div>
              )}
              <Typography variant="h6">Contact Info</Typography>
              <div className="title-line" style={{ marginBottom: 16 }} />
              <div style={{ margin: "8px 0px" }}>
                <InputLabel>Your Name</InputLabel>
                <TextField
                  value={name}
                  name="name"
                  onChange={this.handleChange}
                  fullWidth
                  disabled={loading}
                  className={
                    errors.name
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.name)}
                  helperText={errors.name && errors.name}
                />
              </div>
              <div style={{ margin: "8px 0px" }}>
                <InputLabel>Your Email</InputLabel>
                <TextField
                  value={email}
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  fullWidth
                  disabled={loading}
                  className={
                    errors.email
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email}
                />
              </div>
              <div style={{ margin: "8px 0px" }}>
                <InputLabel>Subject</InputLabel>
                <TextField
                  value={subject}
                  name="subject"
                  onChange={this.handleChange}
                  fullWidth
                  disabled={loading}
                  className={
                    errors.subject
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.subject)}
                  helperText={errors.subject && errors.subject}
                />
              </div>
              <div style={{ margin: "8px 0px" }}>
                <InputLabel>Message</InputLabel>
                <TextField
                  value={message}
                  name="message"
                  onChange={this.handleChange}
                  fullWidth
                  disabled={loading}
                  className={
                    errors.message
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.message)}
                  helperText={errors.message && errors.message}
                  multiline
                  minRows={5}
                  maxRows={8}
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                style={{ borderRadius: 25, marginTop: 16 }}
                disabled={loading}
              >
                Submit{" "}
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
