import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";

// Component imports
import { splitVarInArrayToLowerCase } from "../../../util/utilFunctions";
import SFMyNeeds from "../../loginSignup/family/components/SFMyNeeds";
import SFServiceNeeds from "../../loginSignup/family/components/SFServiceNeeds";
import SFConditions from "../../loginSignup/family/components/SFConditions";
import SFLanguage from "../../loginSignup/family/components/SFLanguage";
import NewJobFinal from "./components/NewJobFinal";
import ReviewJobPosting from "./components/ReviewJobPosting";

// Redux imports
import { connect } from "react-redux";

// CSS Imports
import "../../../App.css";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  ...theme.spreadThis,
  containerDiv: {
    marginLeft: 0,
    paddingTop: 20,
    // [theme.breakpoints.down("lg")]: {
    //   marginLeft: 150,
    // },
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: 175,
    // },
    [theme.breakpoints.up("sm")]: {
      // marginLeft: 85,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  jobPaper: {
    borderRadius: 10,
    padding: 16,
    border: `1px solid ${theme.palette.primary.main}`,
  },
});

export class familyNewJob extends Component {
  state = {
    isLoadedSignupMaterials: false,
    signupMaterials: {},
    address: { addressFull: "" },
    serviceStart: "",
    servicePerWeek: "",
    serviceLength: "",
    serviceArray: [],
    conditionsArray: [],
    description: "",
    errors: {},
    disableWhileLoad: false,
    postingComplete: false,
    languagePref: ["english"],
    generalError: null,
    pages: {
      p1: false,
      p2: false,
      p3: false,
      p4: false,
      p5: false,
    },
    newJobId: null,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Load Methods

  componentDidMount() {
    this.getSignupMaterials();
  }

  getSignupMaterials() {
    axios
      .get("/signup-material")
      .then((res) => {
        this.setState({
          signupMaterials: res.data,
          isLoadedSignupMaterials: true,
        });
      })
      .catch((err) => console.error(err));
  }

  // Action methods

  setDescription = () => {
    const { serviceArray, conditionsArray } = this.state;
    let serviceLength = serviceArray.length,
      conditionsLength = conditionsArray.length,
      someService = splitVarInArrayToLowerCase(serviceArray),
      someCondi = splitVarInArrayToLowerCase(conditionsArray);
    if (serviceLength === 1) {
      someService = someService[0];
    } else if (serviceLength === 2) {
      someService = someService.join(" and ");
    } else {
      someService = someService.slice(0, 3);
      someService = `${someService[0]}, ${someService[1]}, and ${someService[2]}`;
    }

    if (conditionsLength === 1) {
      someCondi = someCondi[0];
    } else if (conditionsLength === 2) {
      someCondi = someCondi.join(" and ");
    } else {
      someCondi = someCondi.slice(0, 3);
      someCondi = `${someCondi[0]}, ${someCondi[1]}, and ${someCondi[2]}`;
    }

    let description = `Looking for a compassionate, caring, and professional caregiver to help in the family. You must be knowledgeable in dealing with ${someCondi} and will be assisting with ${someService}. If you are qualified, send me a message and we can chat!`;

    this.setState({ description });
  };

  // UI methods

  scrollToTop = () => {
    this.scrollShifts.scrollIntoView();
  };

  handleMyNeeds = (value) => {
    this.scrollToTop();
    this.setState({ pages: { ...this.state.pages, p1: true }, ...value });
  };

  handleServiceNeeds = (value) => {
    this.scrollToTop();

    this.setState({ pages: { ...this.state.pages, p2: true }, ...value });
  };

  handleConditions = (value) => {
    this.scrollToTop();

    this.setState({ pages: { ...this.state.pages, p3: true }, ...value });
  };

  handleLanguage = (value) => {
    this.scrollToTop();
    this.setDescription();
    this.setState({ pages: { ...this.state.pages, p4: true }, ...value });
  };

  handleFinal = (value) => {
    this.scrollToTop();
    this.setState({ pages: { ...this.state.pages, p5: true }, ...value });
  };

  handleReview = (value) => {
    this.scrollToTop();
    this.setState({ pages: value });
  };

  // Submit method

  handlePostJob = async () => {
    const {
      serviceStart,
      servicePerWeek,
      serviceLength,
      languagePref,
      conditionsArray,
      serviceArray,
      address,
      description,
    } = this.state;

    const newJob = {
      serviceStart,
      servicePerWeek,
      serviceLength,
      languagePref,
      conditions: Object.fromEntries(conditionsArray.map((k) => [k, true])),
      service: Object.fromEntries(serviceArray.map((k) => [k, true])),
      address,
      description,
    };
    await this.setStateAsync({ disableWhileLoad: true, generalError: null });
    try {
      const job = await axios.post("/job", newJob);
      this.setState({
        disableWhileLoad: false,
        postingComplete: true,
        newJobId: job.data.jobId,
      });
    } catch (err) {
      this.setState({
        disableWhileLoad: false,
        generalError: "An error has occured, please try again.",
      });
    }
  };

  render() {
    const {
      classes,
      user: { credentials },
    } = this.props;
    const {
      isLoadedSignupMaterials,
      signupMaterials,
      serviceStart,
      servicePerWeek,
      serviceLength,
      languagePref,
      conditionsArray,
      serviceArray,
      pages,
      address,
      description,
      disableWhileLoad,
      postingComplete,
      generalError,
      newJobId,
    } = this.state;

    return (
      <div className={classes.containerDiv}>
        <Grid container justifyContent="center" style={{ padding: "0px 8px" }}>
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.scrollShifts = el;
            }}
          />
          <Grid item xs={12} sm={11} md={9}>
            <Paper elevation={4} className={classes.jobPaper}>
              <Typography variant="h6" style={{ fontWeight: 300 }}>
                Post a new job
              </Typography>
              {pages.p5 ? (
                <ReviewJobPosting
                  address={address}
                  languagePref={languagePref}
                  serviceArray={serviceArray}
                  conditionsArray={conditionsArray}
                  firstName={credentials.firstName}
                  lastName={credentials.lastName}
                  description={description}
                  serviceStart={serviceStart}
                  servicePerWeek={servicePerWeek}
                  serviceLength={serviceLength}
                  onEdit={this.handleReview}
                  onSubmitJob={this.handlePostJob}
                  loading={disableWhileLoad}
                  generalError={generalError}
                  postingComplete={postingComplete}
                  newJobId={newJobId}
                />
              ) : pages.p4 ? (
                <Fade in={pages.p4}>
                  <div>
                    <NewJobFinal
                      credentials={credentials}
                      address={address}
                      total={5}
                      fadeIn={5}
                      description={description}
                      onFinish={this.handleFinal}
                    />
                  </div>
                </Fade>
              ) : pages.p3 ? (
                <Fade in={pages.p3}>
                  <div>
                    <SFLanguage
                      languageArray={signupMaterials.language}
                      onSelectLanguage={this.handleLanguage}
                      languagePref={languagePref}
                      total={5}
                      fadeIn={4}
                    />
                  </div>
                </Fade>
              ) : pages.p2 ? (
                <Fade in={pages.p2}>
                  <div>
                    <SFConditions
                      conditionsObj={signupMaterials.conditions}
                      onSelectConditions={this.handleConditions}
                      conditionsArray={conditionsArray}
                      total={5}
                      fadeIn={3}
                    />
                  </div>
                </Fade>
              ) : pages.p1 ? (
                <Fade in={pages.p1}>
                  <div>
                    <SFServiceNeeds
                      serviceObj={signupMaterials.service}
                      onSelectServiceNeeds={this.handleServiceNeeds}
                      isLoaded={isLoadedSignupMaterials}
                      serviceArray={serviceArray}
                      total={5}
                      fadeIn={2}
                    />
                  </div>
                </Fade>
              ) : (
                <Fade in>
                  <div>
                    <SFMyNeeds
                      onSelectMyNeeds={this.handleMyNeeds}
                      serviceStart={serviceStart}
                      servicePerWeek={servicePerWeek}
                      serviceLength={serviceLength}
                      total={5}
                      fadeIn={1}
                    />
                  </div>
                </Fade>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

familyNewJob.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(withStyles(styles)(familyNewJob));
