import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Component import
import SinglePie from "../../charts/SinglePie";
import { numberWithCommas } from "../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
  subTotalHighlight: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 10px",
    borderRadius: 25,
    backgroundColor: "rgba(212,212,212,0.2)",
  },
  lineItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 10px",
  },
});

export class InvoiceCostBreakdown extends Component {
  render() {
    const {
      classes,
      isFamily,
      invoiceData: { invoiceBreakdown },
    } = this.props;
    return (
      <div>
        <Typography variant="body2" style={{ fontWeight: 550 }}>
          {isFamily ? "Employer" : "Employee"} Breakdown
        </Typography>
        <Grid container>
          <Grid item xs={12} className={classes.lineItem}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: 300, maxWidth: "65%" }}
            >
              Regular Pay {"("}$
              {numberWithCommas(
                invoiceBreakdown.totalRegularIncome /
                  (invoiceBreakdown.totalHours - invoiceBreakdown.totalOtHours)
              )}
              /hr x{" "}
              {numberWithCommas(
                invoiceBreakdown.totalHours - invoiceBreakdown.totalOtHours
              )}{" "}
              hrs{")"}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="right">
              ${numberWithCommas(invoiceBreakdown.totalRegularIncome)}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.lineItem}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: 300, maxWidth: "65%" }}
            >
              1.5x OT Pay{" "}
              {invoiceBreakdown.totalOtHours > 0
                ? `($${invoiceBreakdown.currWage * 1.5}/hr x ${
                    invoiceBreakdown.totalOt1Hours
                  } hrs)`
                : "(---}"}
            </Typography>

            <Typography variant="body2" color="textSecondary" align="right">
              ${numberWithCommas(invoiceBreakdown.totalOt1Income)}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.lineItem}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: 300, maxWidth: "65%" }}
            >
              2.0x OT Pay{" "}
              {invoiceBreakdown.totalOtHours > 0
                ? `($${invoiceBreakdown.currWage * 1.2}/hr x ${
                    invoiceBreakdown.totalOt2Hours
                  } hrs)`
                : "(---}"}
            </Typography>

            <Typography variant="body2" color="textSecondary" align="right">
              ${numberWithCommas(invoiceBreakdown.totalOt2Income)}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.lineItem}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: 300, maxWidth: "65%" }}
            >
              Vacation Pay {"("}4%{")"}
            </Typography>

            <Typography variant="body2" color="textSecondary" align="right">
              ${numberWithCommas(invoiceBreakdown.totalVacation)}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.subTotalHighlight}>
            <Typography
              color="primary"
              variant="body2"
              style={{ fontWeight: 550 }}
            >
              Taxable Income
            </Typography>

            <Typography
              variant="body2"
              align="right"
              color="primary"
              style={{ fontWeight: 550 }}
            >
              ${numberWithCommas(invoiceBreakdown.grossIncome)}
            </Typography>
          </Grid>
        </Grid>

        {!isFamily && (
          <Grid container style={{ marginTop: 10, width: "100%" }}>
            <Grid item xs={12} className={classes.lineItem}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 300, maxWidth: "65%" }}
              >
                Federal Taxes
              </Typography>

              <Typography variant="body2" color="textSecondary" align="right">
                ${numberWithCommas(invoiceBreakdown.taxes.fedTax.total)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.lineItem}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 300, maxWidth: "65%" }}
              >
                Prov Taxes
              </Typography>

              <Typography variant="body2" color="textSecondary" align="right">
                ${numberWithCommas(invoiceBreakdown.taxes.provTax.total)}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.subTotalHighlight}>
              <Typography
                color="primary"
                variant="body2"
                style={{ fontWeight: 550 }}
              >
                Est. Income Tax
              </Typography>

              <Typography
                variant="body2"
                align="right"
                color="primary"
                style={{ fontWeight: 550 }}
              >
                $
                {numberWithCommas(
                  (
                    Math.round(
                      (invoiceBreakdown.taxes.fedTax.total +
                        invoiceBreakdown.taxes.provTax.total) *
                        100
                    ) / 100
                  ).toFixed(2)
                )}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid container style={{ marginTop: 10, width: "100%" }}>
          <Grid item xs={12} className={classes.lineItem}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: 300, maxWidth: "65%" }}
            >
              CPP
            </Typography>

            <Typography variant="body2" color="textSecondary" align="right">
              ${numberWithCommas(invoiceBreakdown.payrollTaxes.cppEmployee)}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.lineItem}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: 300, maxWidth: "65%" }}
            >
              EI
            </Typography>

            <Typography variant="body2" color="textSecondary" align="right">
              ${numberWithCommas(invoiceBreakdown.payrollTaxes.eiEmployee)}
            </Typography>
          </Grid>
          {isFamily && (
            <Grid item xs={12} className={classes.lineItem}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 300 }}
              >
                WCB
              </Typography>

              <Typography variant="body2" color="textSecondary" align="right">
                ${numberWithCommas(invoiceBreakdown.payrollTaxes.wcb)}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} className={classes.subTotalHighlight}>
            <Typography
              color="primary"
              variant="body2"
              style={{ fontWeight: 550 }}
            >
              Est. Payroll {isFamily ? "Contributions" : "Deductions"}
            </Typography>

            <Typography
              variant="body2"
              align="right"
              color="primary"
              style={{ fontWeight: 550 }}
            >
              $
              {numberWithCommas(
                isFamily
                  ? invoiceBreakdown.employerContributions
                  : invoiceBreakdown.employeeDeductions
              )}
            </Typography>
          </Grid>
        </Grid>
        <Box
          className={clsx(classes.subTotalHighlight, classes.flexBoxSpaced)}
          style={{
            marginTop: 10,
            backgroundColor: `rgba(212,212,212,${isFamily ? "0.4" : "0.7"})`,
          }}
        >
          <Typography variant="body2" style={{ fontWeight: 550 }}>
            {isFamily ? "Subtotal" : "Net Income"}
          </Typography>

          <Typography variant="body2" align="right" style={{ fontWeight: 550 }}>
            $
            {numberWithCommas(
              isFamily
                ? invoiceBreakdown.payableBreakdown.base
                : invoiceBreakdown.netIncome
            )}
          </Typography>
        </Box>

        {isFamily && (
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12} className={classes.lineItem}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 300 }}
              >
                Poyo Fee {"("}transaction fees, platform/management, insurance
                {")"}
              </Typography>

              <Typography variant="body2" color="textSecondary" align="right">
                ${numberWithCommas(invoiceBreakdown.payableBreakdown.poyoFee)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.lineItem}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 300 }}
              >
                Tax {"("}GST/HST{")"}
              </Typography>

              <Typography variant="body2" color="textSecondary" align="right">
                ${numberWithCommas(invoiceBreakdown.payableBreakdown.salesTax)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.subTotalHighlight}
              style={{
                backgroundColor: "rgba(212,212,212,0.7)",
              }}
            >
              <Typography variant="body2" style={{ fontWeight: 550 }}>
                Total Payable
              </Typography>

              <Typography
                variant="body2"
                align="right"
                style={{ fontWeight: 550 }}
              >
                $
                {numberWithCommas(
                  invoiceBreakdown.payableBreakdown.totalPayable
                )}
              </Typography>
            </Grid>
          </Grid>
        )}

        <div style={{ marginTop: 12 }}>
          <SinglePie
            dataset={
              isFamily
                ? {
                    "Caregiver Cost": invoiceBreakdown.grossIncome,
                    "Payroll Taxes": invoiceBreakdown.employerContributions,
                    "Poyo Fees": invoiceBreakdown.payableBreakdown.poyoFee,
                    Taxes: invoiceBreakdown.payableBreakdown.salesTax,
                  }
                : {
                    "Net Income": invoiceBreakdown.netIncome,
                    "Payroll Deduction": invoiceBreakdown.employeeDeductions,
                    "Income Tax":
                      invoiceBreakdown.taxes.fedTax.total +
                      invoiceBreakdown.taxes.provTax.total,
                  }
            }
          />
        </div>
      </div>
    );
  }
}

InvoiceCostBreakdown.propTypes = {
  classes: PropTypes.object.isRequired,
  invoiceData: PropTypes.object.isRequired,
  isFamily: PropTypes.bool,
};
export default withStyles(styles)(InvoiceCostBreakdown);
