import React, { Component } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export class CenteredCircularLoading extends Component {
  render() {
    const { size, color } = this.props;

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%", width: "100%" }}
      >
        <CircularProgress
          size={size ? size : 30}
          color={color ? color : "primary"}
          thickness={2}
        />
      </Box>
    );
  }
}

CenteredCircularLoading.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default CenteredCircularLoading;
