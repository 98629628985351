import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Component imports
import {
  capitalizeFirstChar,
  isEmpty,
  allFalse,
} from "../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class JobAppEditDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      message: this.props.jobApp.message,
      errors: {},
      disableWhileLoad: false,
    };
    this.initialState = this.state;
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  // UI Methods

  handleOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = (event, reason) => {
    if (reason !== "backdropClick") this.setState(this.initialState);
  };

  // Action methods

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: isEmpty(value) && "Application message can not be empty",
      },
    });
  };

  checkError = async () => {
    const { message, errors } = this.state;

    let _errors = errors;
    if (_errors.general) delete _errors.general;
    if (isEmpty(message))
      _errors.message = "Please enter a message with your application";

    await this.setStateAsync({ errors: _errors });
    return allFalse(_errors);
  };

  handleActionJobApp = async () => {
    await this.setStateAsync({ disableWhileLoad: true });
    const valid = await this.checkError();

    if (valid) {
      const { message } = this.state;
      const { jobApp } = this.props;

      try {
        const updatedJobApp = await axios.post(
          `/job-applications/${jobApp.id}/edit`,
          { message }
        );
        if (this.props.updateJobApp) {
          this.props.updateJobApp(jobApp.id, updatedJobApp.data);
        }
        this.handleClose();
      } catch (err) {
        await this.setStateAsync({
          errors: { general: err.response.data.error },
        });
      } finally {
        await this.setStateAsync({ disableWhileLoad: false });
      }
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const { openDialog, message, errors, disableWhileLoad } = this.state;
    const { jobApp, classes, fullWidth, color, variant } = this.props;

    return (
      <Fragment>
        <Button
          onClick={this.handleOpen}
          fullWidth={fullWidth}
          color={color ? color : "primary"}
          variant={variant ? variant : "text"}
          disabled={jobApp.isAccepted || jobApp.isDeclined}
        >
          <Box style={{ textAlign: "center" }}>
            <EditIcon />
            <Typography variant="body2">Edit Application</Typography>
          </Box>
        </Button>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          fullWidth
          disableEscapeKeyDown
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.headerBarRounded}
          >
            <Typography variant="h6">Edit your application?</Typography>
            <IconButton size="small" onClick={this.handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box p={2} style={{ minHeight: 300 }}>
            {Boolean(errors.general) && (
              <Typography align="center" color="error">
                {errors.general}
              </Typography>
            )}
            <Typography>
              Edit your application's message sent to{" "}
              {capitalizeFirstChar(jobApp.familyInfo.firstName)}:
            </Typography>

            <TextField
              multiline
              minRows={10}
              variant="outlined"
              name="message"
              value={message}
              onChange={this.handleChange}
              error={Boolean(errors.message)}
              helperText={errors.message}
              className={
                Boolean(errors.message)
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
              fullWidth
            />
          </Box>
          <DialogActions style={{ padding: "8px 16px 8px 16px" }}>
            <Button className={classes.txtTrButton} onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              className={classes.txtTrButton}
              disabled={
                isEmpty(message) ||
                message === jobApp.message ||
                disableWhileLoad
              }
              color="primary"
              onClick={this.handleActionJobApp}
            >
              Update{" "}
              {disableWhileLoad && (
                <CircularProgress
                  thickness={2}
                  size={30}
                  className={classes.progress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

JobAppEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  jobApp: PropTypes.object.isRequired,
  updateJobApp: PropTypes.func,
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
};

export default withStyles(styles)(JobAppEditDialog);
