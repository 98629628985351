import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";

// Component imports
import TimeSelector from "../../inputs/TimeSelector";
import { isEmpty } from "../../../util/utilFunctions";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/uiActions";

import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";

import AddIcon from "@mui/icons-material/Add";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class AddTaskDialog extends Component {
  state = {
    open: false,
    title: "",
    body: "",
    time: "",
    showNewTask: false,
    showSavedTasks: false,
    initialScreen: true,
    saveTask: false,
    savedTasksIndex: null,
    disableWhileLoad: false,
    errors: {},
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI methods
  handleOpenDialog = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      open: false,
      title: "",
      body: "",
      time: "",
      showNewTask: false,
      showSavedTasks: false,
      initialScreen: true,
      savedTasksIndex: null,
    });
  };

  handleSetNew = () => {
    this.setState({ initialScreen: false, showNewTask: true });
  };

  handleSetTemp = () => {
    this.setState({ initialScreen: false, showSavedTasks: true });
  };

  handleSetNewFromTemp = () => {
    this.setState({ showSavedTasks: false, showNewTask: true });
  };

  // Action methods
  handleTitle = async (e) => {
    const value = e.target.value;
    await this.setStateAsync({ title: value });
    this.validateTitle();
  };

  validateTitle = () => {
    const { title } = this.state;
    this.setState({
      errors: { title: isEmpty(title) && "Title must not be empty" },
    });
  };
  handleNote = (e) => {
    const value = e.target.value;
    this.setState({ body: value });
  };

  onSelectTime = (value) => {
    this.setState({ time: value.toISOString() });
  };

  handleSubmitTask = async (e) => {
    e.preventDefault();
    const { title, body, time, saveTask } = this.state;
    const taskObj = {
      title,
      body,
      time,
    };
    this.props.onSubmitTask(taskObj);
    this.handleCloseDialog();
    if (saveTask) {
      await this.saveTaskOnSubmit(taskObj);
    }
  };

  saveTaskOnSubmit = async (taskObj) => {
    await this.setStateAsync({ disableWhileLoad: true });
    try {
      await axios.post("/user/tasks", taskObj);
      this.props.setAlert({
        message: "Task has been saved to your profile",
        type: "success",
      });
    } catch (e) {
      this.props.setAlert({
        message: "There was an error saving this task. Please try again",
        type: "error",
      });

      return;
    } finally {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  handleDeleteSaved = async () => {
    const { savedTasksIndex } = this.state;

    await this.setStateAsync({ disableWhileLoad: true });

    try {
      await axios.delete("/user/tasks", { taskIndex: savedTasksIndex });
      await this.setStateAsync({ savedTasksIndex: null });

      this.props.setAlert({
        message: "Task has been deleted your profile",
        type: "success",
      });
    } catch (e) {
      this.props.setAlert({
        message: "There was an error deleting this task. Please try again",
        type: "error",
      });

      return;
    } finally {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  handleSubmitSavedTask = () => {
    const {
      credentials: { savedTasks },
    } = this.props;
    const { savedTasksIndex } = this.state;

    const taskObj = savedTasks[savedTasksIndex];
    this.props.onSubmitTask(taskObj);
    this.handleCloseDialog();
  };

  handleChangeTasks = (e) => {
    this.setState({ savedTasksIndex: parseInt(e.target.value) });
  };

  saveTask = () => {
    this.setState({ saveTask: !this.state.saveTask });
  };

  render() {
    const {
      style,
      isDisableTaskAdd,
      classes,
      credentials: { savedTasks },
    } = this.props;
    const {
      open,
      title,
      body,
      time,
      showNewTask,
      showSavedTasks,
      initialScreen,
      saveTask,
      savedTasksIndex,
      disableWhileLoad,
      errors,
    } = this.state;

    return (
      <Fragment>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={this.handleOpenDialog}
          style={style ? style : { borderRadius: 25 }}
          disabled={isDisableTaskAdd}
          startIcon={<AddIcon />}
        >
          Add Task{" "}
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          onClose={this.handleCloseDialog}
        >
          <DialogTitle>Add a new task</DialogTitle>

          {/* // Show initial container */}

          {initialScreen && (
            <Fragment>
              <DialogContent>
                <Typography color="textSecondary">
                  Create a new task or use a saved task.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={this.handleSetTemp}>
                  Use Saved
                </Button>
                <Button color="primary" onClick={this.handleSetNew}>
                  Set New
                </Button>
              </DialogActions>
            </Fragment>
          )}

          {/* // Show use saved task container */}

          {showSavedTasks && (
            <Fragment>
              <DialogContent>
                {savedTasks.length < 1 ? (
                  <Typography color="textSecondary">
                    You don't have any tasks saved. You can create a new task
                    and save for future use.
                  </Typography>
                ) : (
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="saved_tasks"
                      name="tasks_array"
                      value={savedTasksIndex}
                      onChange={this.handleChangeTasks}
                    >
                      {savedTasks.map((k, i) => (
                        <FormControlLabel
                          value={i}
                          key={i}
                          control={<Radio />}
                          label={
                            <Typography
                              component="div"
                              variant="body2"
                              align="left"
                              color="textSecondary"
                              style={{ fontWeight: 550 }}
                            >
                              {k.title}
                              {!isEmpty(k.time) && (
                                <Box
                                  display="inline"
                                  style={{ fontWeight: 300 }}
                                >
                                  {" ("}
                                  {dayjs(k.time).format("h:mma")}
                                  {")"}
                                </Box>
                              )}
                              {!isEmpty(k.body) && (
                                <Box
                                  display="inline"
                                  style={{ fontWeight: 400 }}
                                >
                                  {" "}
                                  - {k.body}
                                </Box>
                              )}
                            </Typography>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialog}
                  color="primary"
                  disabled={disableWhileLoad}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleDeleteSaved}
                  disabled={savedTasksIndex === null || disableWhileLoad}
                  color="primary"
                >
                  Delete
                </Button>
                {savedTasks.length < 1 ? (
                  <Button onClick={this.handleSetNewFromTemp} color="primary">
                    Set New
                  </Button>
                ) : (
                  <Button
                    onClick={this.handleSubmitSavedTask}
                    disabled={savedTasksIndex === null || disableWhileLoad}
                    color="primary"
                  >
                    Add
                  </Button>
                )}
              </DialogActions>
            </Fragment>
          )}

          {/* // Show new task container */}

          {showNewTask && (
            <form onSubmit={this.handleSubmitTask}>
              <DialogContent>
                <Typography variant="subtitle2">Title*:</Typography>
                <TextField
                  name="title"
                  value={title}
                  onChange={this.handleTitle}
                  size="small"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  error={Boolean(errors.title)}
                  helperText={Boolean(errors.title) && errors.title}
                  onBlur={this.validateTitle}
                  required
                  autoFocus
                  style={{ marginBottom: 8 }}
                  className={classes.styledTextField}
                />
                <Typography variant="subtitle2">Details:</Typography>
                <TextField
                  name="body"
                  value={body}
                  onChange={this.handleNote}
                  size="small"
                  variant="outlined"
                  margin="dense"
                  multiline
                  minRows={3}
                  fullWidth
                  className={classes.styledTextField}
                  style={{ marginBottom: 8 }}
                />
                <Typography variant="subtitle2">Time:</Typography>

                <TimeSelector
                  onSelectTime={this.onSelectTime}
                  fullWidth
                  variant="outlined"
                  className={classes.styledTextField}
                  time={time}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saveTask}
                      onChange={this.saveTask}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      color={saveTask ? "primary" : "inherit"}
                    >
                      {saveTask
                        ? "Task will be saved to profile"
                        : "Save task for future?"}
                    </Typography>
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialog}
                  color="primary"
                  disabled={disableWhileLoad}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  disabled={disableWhileLoad}
                >
                  Add
                  {disableWhileLoad && (
                    <CircularProgress
                      thickness={2}
                      size={30}
                      className={classes.progress}
                    />
                  )}
                </Button>
              </DialogActions>
            </form>
          )}
        </Dialog>
      </Fragment>
    );
  }
}

AddTaskDialog.propTypes = {
  onSubmitTask: PropTypes.func,
  isDisableTaskAdd: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  credentials: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps, { setAlert })(
  withStyles(styles)(AddTaskDialog)
);
