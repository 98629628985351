import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Component imports
import StyledNativeSelect from "../../../components/inputs/StyledNativeSelect";
import InviteCompact from "../../../components/invites/InviteCompact";
import InviteSingle from "../../../components/invites/InviteSingle";
import firebase from "../../../Firebase";
import MinFullVH from "../../../components/layout/MinFullVH";

// Redux imports
import { connect } from "react-redux";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import { lightBlue } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";

const styles = (theme) => ({
  ...theme.spreadThis,

  blue: {
    color: theme.palette.getContrastText(lightBlue[400]),
    backgroundColor: lightBlue[400],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
});

export class invites extends Component {
  state = {
    isLoadedTeamInvites: false,
    teamInvites: [],
    sortedTeamInvites: [],
    tabValue: 0,
    show: false,
    sortClass: "Newest",
  };

  // Initial Load Methods
  componentDidMount() {
    this.getTeamInv();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
    this.setState = (state, callback) => {
      return;
    };
  }

  getTeamInv = () => {
    const { userIdNumber, userType } = this.props.user.credentials;

    const db = firebase.firestore();
    let colRef = db.collection("teamInvite");

    if (userType === "caregiver")
      colRef = colRef
        .where("caregiverInfo.userIdNumber", "==", userIdNumber)
        .orderBy("createdAt", "desc");
    else
      colRef = colRef
        .where("familyInfo.userIdNumber", "==", userIdNumber)
        .orderBy("createdAt", "desc");

    const that = this;

    // Realtime updates listener
    this.unsubscribe = colRef.onSnapshot((snapshot) => {
      let teamInvites = snapshot.docs.map((doc) => {
        let res = doc.data();
        res.id = doc.id;
        return res;
      });
      that.setState(
        {
          teamInvites,
          isLoadedTeamInvites: true,
        },
        () => that.sortAll()
      );
    });
  };

  // UI Methods

  handleSetSortClass = (value) => {
    this.setState({ sortClass: value }, () => {
      this.sortAll();
    });
  };

  sortAll = () => {
    const {
      user: {
        credentials: { userType },
      },
    } = this.props;
    const { sortClass, teamInvites } = this.state;

    let _teamInvites = teamInvites;

    switch (sortClass) {
      case "Newest":
        break;
      case "Oldest":
        _teamInvites.sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0
        );
        break;
      case "Start Date":
        _teamInvites.sort((a, b) =>
          a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0
        );
        break;
      case "Accepted":
        _teamInvites = _teamInvites.filter((inv) => inv.isAccepted);

        break;
      case "Declined":
        _teamInvites = _teamInvites.filter((inv) => inv.isDeclined);

        break;
      case "Unread":
        _teamInvites = _teamInvites.filter((inv) => !inv.isRead);

        break;
      case "Read":
        _teamInvites = _teamInvites.filter((inv) => inv.isRead);
        break;
      case "First Name":
        userType === "family"
          ? _teamInvites.sort((a, b) =>
              a.caregiverInfo.firstName < b.caregiverInfo.firstName
                ? -1
                : a.caregiverInfo.firstName > b.caregiverInfo.firstName
                ? 1
                : 0
            )
          : _teamInvites.sort((a, b) =>
              a.familyInfo.firstName < b.familyInfo.firstName
                ? -1
                : a.familyInfo.firstName > b.familyInfo.firstName
                ? 1
                : 0
            );

        break;
      case "Last Name":
        userType === "family"
          ? _teamInvites.sort((a, b) =>
              a.caregiverInfo.lastName < b.caregiverInfo.lastName
                ? -1
                : a.caregiverInfo.lastName > b.caregiverInfo.lastName
                ? 1
                : 0
            )
          : _teamInvites.sort((a, b) =>
              a.familyInfo.lastName < b.familyInfo.lastName
                ? -1
                : a.familyInfo.lastName > b.familyInfo.lastName
                ? 1
                : 0
            );

        break;
      default:
        break;
    }
    this.setState({ sortedTeamInvites: _teamInvites });
  };

  render() {
    const {
      classes,
      match: {
        params: { inviteId },
      },
    } = this.props;

    const { isLoadedTeamInvites, teamInvites, sortClass, sortedTeamInvites } =
      this.state;

    const currInvite = teamInvites.find(
      (teamInvite) => teamInvite.id === inviteId
    );
    return (
      <div className="res-gr-con">
        {!isLoadedTeamInvites ? (
          <Box className={classes.flexBoxCentered}>
            <CircularProgress thickness={2} size={250} />
          </Box>
        ) : (
          <Grid container justifyContent="center" style={{ padding: 4 }}>
            <Grid
              component={Paper}
              elevation={4}
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              style={{ border: `1px solid rgba(101,101,101,0.5)` }}
              className={classes.smallPaperContainer}
            >
              <Box
                className={classes.flexBoxSpaced}
                style={{ marginBottom: 8 }}
              >
                <Typography variant="h6" style={{ fontWeight: 300 }}>
                  My Team Invites
                </Typography>
                {!Boolean(inviteId) && sortedTeamInvites.length > 0 && (
                  <Avatar className={classes.blue}>
                    {sortedTeamInvites.length}
                  </Avatar>
                )}
              </Box>

              {!Boolean(inviteId) ? (
                <Fade in>
                  <Box style={{ minHeight: 350 }}>
                    <Box
                      className={classes.flexBoxSpaced}
                      style={{ marginBottom: 8 }}
                    >
                      <Typography variant="body2" style={{ fontWeight: 300 }}>
                        Sort by:
                      </Typography>
                      <StyledNativeSelect
                        value={sortClass}
                        values={[
                          "Newest",
                          "Oldest",
                          "Start Date",
                          "Accepted",
                          "Declined",
                          "Unread",
                          "Read",
                          "First Name",
                          "Last Name",
                        ]}
                        onSelect={this.handleSetSortClass}
                      />
                    </Box>

                    <Divider />
                    <MinFullVH>
                      {sortedTeamInvites.length > 0 ? (
                        sortedTeamInvites.map((teamInvite) => (
                          <InviteCompact
                            teamInvite={teamInvite}
                            key={teamInvite.id}
                          />
                        ))
                      ) : (
                        <Typography
                          align="center"
                          color="textSecondary"
                          style={{ margin: 8 }}
                        >
                          {teamInvites.length < 1
                            ? `You do not have any team
                          invites`
                            : `You do not have any ${sortClass.toLowerCase()} invites`}
                        </Typography>
                      )}
                    </MinFullVH>
                  </Box>
                </Fade>
              ) : (
                <Fade in>
                  <div>
                    {Boolean(currInvite) ? (
                      <InviteSingle
                        teamInvite={currInvite}
                        history={this.props.history}
                      />
                    ) : (
                      <div
                        className={classes.form}
                        style={{ height: 200, padding: 24 }}
                      >
                        <SearchIcon fontSize="large" color="primary" />
                        <Typography
                          align="center"
                          color="textSecondary"
                          style={{ marginTop: 8 }}
                        >
                          Hmm... It seems we are unable to locate this invite.
                          Please try again.
                        </Typography>
                        <Button
                          component={Link}
                          to={`/invites/`}
                          color="secondary"
                          variant="outlined"
                          fullWidth
                          className={classes.txtTrButton}
                          style={{ margin: "8px 0px" }}
                        >
                          View All Invites
                        </Button>
                      </div>
                    )}
                  </div>
                </Fade>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

invites.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(invites));
