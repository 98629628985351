import React from "react";
import { Link } from "react-router-dom";

import AboutMenu from "./AboutMenu";
import SignInButtonDialog from "./SignInButtonDialog";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

function UnauthNav(props) {
  return (
    <>
      <div className="nav-center-div">
        <div className="flexbox" style={{ width: "100%" }}>
          <AboutMenu />
          <Button
            component={Link}
            to="/join-poyo"
            color="inherit"
            className="link-btn dropbtn"
          >
            <Typography
              variant="subtitle2"
              color="inherit"
              className="styled-txt-link"
            >
              Become a Care Pro
            </Typography>
          </Button>
        </div>
      </div>
      <div>
        <SignInButtonDialog />
        <Button
          variant="outlined"
          component={Link}
          to="/signup"
          color="inherit"
          className="link-btn"
        >
          <Typography variant="subtitle2" color="inherit">
            Join
          </Typography>
        </Button>

        <IconButton
          edge="start"
          className="nav-menu-icon-btn link-btn"
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerToggle}
          size="small"
        >
          <MenuIcon />
        </IconButton>
      </div>
    </>
  );
}

export default UnauthNav;
