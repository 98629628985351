import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import ArrayButtonSelect from "../../../../components/inputs/ArrayButtonSelect";
import Stepper from "../../../../components/layout/Components/Stepper";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Icons imports
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentOutlineIcon from "@mui/icons-material/AssignmentOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
  serviceButton: {
    width: "200px",
    margin: 10,
    backgroundColor: "white",
  },
  serviceButtonContained: {
    width: "200px",
    margin: 10,
  },
});

export class SFServiceNeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: { errorService: null },
      serviceArray: [],
    };
  }

  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    const { serviceArray } = this.props;
    if (serviceArray.length > 0) this.setState({ serviceArray });
  }

  // Error Validation Methods

  validateSubmit = () => {
    const { serviceArray } = this.state;
    if (serviceArray.length < 1) {
      this.setState({
        errors: {
          errorService: "Please select at least one service required.",
        },
      });
    }
  };

  // Action Methods

  handleServiceArray = (e) => {
    let value = e.currentTarget.value;
    const { serviceArray } = this.state;
    let _serviceArray = serviceArray;

    if (!_serviceArray.includes(value)) {
      _serviceArray.push(value);
    } else {
      let pos = _serviceArray.findIndex((k) => k === value);
      _serviceArray.splice(pos, 1);
    }
    this.setState({
      serviceArray: _serviceArray,
      errors: { errorService: null },
    });
  };

  handleSubmit = async () => {
    await this.validateSubmit();
    const { errors, serviceArray } = this.state;

    if (!Boolean(errors.errorService)) {
      this.props.onSelectServiceNeeds({
        serviceArray,
      });
    }
  };

  handleBack = () => {
    this.props.onSelectServiceNeeds({ pages: { p1: false, p2: false } });
  };

  render() {
    const { classes, serviceObj, isLoaded, total, fadeIn } = this.props;
    const { errors, serviceArray } = this.state;

    return (
      <Box p={2} className={classes.form}>
        <Stepper total={total} fadeIn={fadeIn} />

        <br />

        <Typography
          variant="h6"
          color="primary"
          align="center"
          style={{ margin: "10px 0px", fontWeight: 300 }}
          className={classes.typography}
        >
          Services Required
        </Typography>
        <Box className={classes.sectionBoxLeftOutline}>
          <Typography variant="body2" style={{ fontWeight: 300 }}>
            Our caregivers are able to provide various different types of
            services. Select the ones you are seeking below.
          </Typography>
          {Boolean(errors.errorService) && (
            <Typography
              variant="caption"
              style={{ fontWeight: 300, color: "#f44336" }}
            >
              {errors.errorService}
            </Typography>
          )}

          {isLoaded ? (
            <ArrayButtonSelect
              valueObj={serviceObj}
              value={serviceArray}
              handleSelectValue={this.handleServiceArray}
              error={Boolean(errors.errorService)}
              selIcon={<AssignmentIcon />}
              unselIcon={<AssignmentOutlineIcon />}
              serviceIcons
            />
          ) : (
            <Box style={{ textAlign: "center", width: "100%" }}>
              <CircularProgress thickness={2} size={70} />
              <Typography variant="body2" color="primary">
                Loading
              </Typography>
            </Box>
          )}
        </Box>

        <br />

        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={this.handleBack}
            style={{ marginRight: 10, width: 100, backgroundColor: "white" }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: 100 }}
            onClick={this.handleSubmit}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  }
}

SFServiceNeeds.propTypes = {
  onSelectServiceNeeds: PropTypes.func.isRequired,
  serviceObj: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  serviceArray: PropTypes.array,
  total: PropTypes.number.isRequired,
  fadeIn: PropTypes.number.isRequired,
};

export default withStyles(styles)(SFServiceNeeds);
