import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class ConfirmLeave extends Component {
  handleClose = () => {
    this.props.handleClose();
  };

  handleConfirmBack = () => {
    this.props.handleConfirmBack();
  };

  render() {
    const { classes } = this.props;
    return (
      <Box p={2} className={classes.form}>
        <Typography variant="h6" style={{ marginTop: 150 }}>
          Are you sure you want to leave?
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          style={{ fontWeight: 300, marginBottom: 150 }}
        >
          You will lose all your saved progress.
        </Typography>

        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClose}
            style={{ width: 100, marginRight: 10 }}
          >
            Leave
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleConfirmBack}
            style={{ width: 100 }}
          >
            Return
          </Button>
        </Box>
      </Box>
    );
  }
}

ConfirmLeave.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmBack: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmLeave);
