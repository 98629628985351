import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";

function Values() {
  const myRef = useRef();

  const { inViewport, enterCount } = useInViewport(myRef);

  return (
    <div className="section-wrapper" style={{ width: "100%" }}>
      <Typography variant="h3" className="txt-bold">
        Values
      </Typography>
      <div className="title-line" />
      <Grid
        ref={myRef}
        container
        spacing={3}
        justifyContent="center"
        style={{ marginTop: 16 }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          className={`${
            inViewport && enterCount === 1
              ? `slide-up-fade-in`
              : enterCount < 1 && `pre-fade`
          }`}
        >
          <div className="icon-container icon-blue">
            <PeopleOutlinedIcon />
          </div>
          <Typography variant="h5" className="description-txt">
            Focus on people
          </Typography>
          <Typography variant="subtitle1">
            Better care starts with happier people.
          </Typography>
          <Typography color="textSecondary" className="description-txt">
            Poyo Care Professionals set their own wages, have flexible
            schedules, and find families that match their specialties.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className={`delay-1 ${
            inViewport && enterCount === 1
              ? `slide-up-fade-in `
              : enterCount < 1 && `pre-fade`
          }`}
        >
          <div className="icon-container icon-orange">
            <VisibilityOutlinedIcon />
          </div>
          <Typography variant="h5" className="description-txt">
            All things transparent
          </Typography>
          <Typography variant="subtitle1">
            Heathcare can be complicated and opaque.
          </Typography>
          <Typography color="textSecondary" className="description-txt">
            We bring a little transparency with real-time updates on care
            services for families as well as a transparent cost structure for
            your true peace of mind.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className={`delay-2 ${
            inViewport && enterCount === 1
              ? `slide-up-fade-in`
              : enterCount < 1 && `pre-fade`
          }`}
        >
          <div className="icon-container icon-green">
            <FaceOutlinedIcon />
          </div>
          <Typography variant="h5" className="description-txt">
            Personalize
          </Typography>
          <Typography variant="subtitle1">
            Every situation is a little unique.
          </Typography>
          <Typography color="textSecondary" className="description-txt">
            We embrace creativity and innovation to personalize the care
            experience for every family and consistenly search for better
            solutions that fit you.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Values;
