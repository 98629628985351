import React, { Component, Fragment } from "react";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";
import StarDisplay from "../misc/StarDisplay";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

export class DropdownButton extends Component {
  state = { anchorEl: null };

  // componentDidMount() {
  //   this.setState({ [this.props.name]: "" });
  // }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSelect = (e) => {
    this.props.onSelect(e.target.name, e.target.value);
    if (this.props.closeOnSelect) {
      this.handleClose();
    }
  };

  render() {
    const {
      btnVariant,
      btnText,
      className,
      selectionObj,
      color,
      classes,
      name,
      radio,
      value,
      check,
      style,
      width,
      header,
      xs,
    } = this.props;
    const open = Boolean(this.state.anchorEl);

    let selectionObjKeys = Object.keys(selectionObj);
    if (name === "rating")
      selectionObjKeys = selectionObjKeys.sort((a, b) =>
        a < 1 ? -1 : a < b ? 1 : a > b ? -1 : 0
      );

    return (
      <Fragment>
        <Button
          variant={btnVariant}
          endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          className={className}
          style={style ? style : {}}
          onClick={this.handleMenu}
          color={color}
        >
          {btnText}
        </Button>
        <StyledMenu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={open}
          onClose={this.handleClose}
        >
          <FormControl component="fieldset" style={{ padding: "0px 8px" }}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              fontWeight="bold"
            >
              {header}
            </Typography>
            <div className={classes.titleLine} />
            {radio && (
              <RadioGroup
                aria-label="position"
                name={name}
                defaultValue="top"
                value={value}
                onChange={this.handleSelect}
              >
                <Grid
                  container
                  alignItems="center"
                  style={{ width: width ? width : 275 }}
                >
                  {selectionObjKeys.map((key) => (
                    <Grid item xs={xs ? xs : 6} key={key}>
                      <FormControlLabel
                        value={selectionObj[key]}
                        control={<Radio color="primary" size="small" />}
                        label={
                          <Typography variant="body2">
                            {name === "rating" ? (
                              selectionObj[key] > 0 ? (
                                <span className={classes.flexBox}>
                                  <StarDisplay
                                    max={5}
                                    numStar={selectionObj[key]}
                                    size="small"
                                  />{" "}
                                  {"&"} up
                                </span>
                              ) : (
                                "All"
                              )
                            ) : (
                              capitalizeFirstChar(key)
                            )}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            )}
            {check && (
              <Grid container alignItems="center" style={{ width: 275 }}>
                <Grid item xs={12}>
                  <FormControlLabel
                    value="any"
                    control={
                      <Checkbox
                        checked={value.length === 0}
                        onChange={this.handleSelect}
                        name="any"
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">Matches Me</Typography>}
                  />
                </Grid>
                {selectionObjKeys.sort().map((key) => (
                  <Grid item xs={6} key={key}>
                    <FormControlLabel
                      value={key}
                      control={
                        <Checkbox
                          checked={value.includes(key)}
                          onChange={this.handleSelect}
                          name={selectionObj[key]}
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {selectionObj[key]}
                        </Typography>
                      }
                    />
                  </Grid>
                ))}

                <Grid container item xs={12} justifyContent="flex-end">
                  <Button onClick={this.handleClose} color="secondary">
                    Close
                  </Button>
                </Grid>
              </Grid>
            )}
          </FormControl>
        </StyledMenu>
      </Fragment>
    );
  }
}

export default withStyles(styles)(DropdownButton);
