import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { lightBlue } from "@mui/material/colors";

const styles = (theme) => ({
  ...theme.spreadThis,
  jobAppMarkup: {
    height: 115,
    width: 115,
    textAlign: "center",
    backgroundColor: "rgba(200,200,200,0.1)",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    outline: `1px solid ${theme.palette.primary.light}`,

    "&:hover": {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },
  media: {
    borderRadius: "50%",
    objectFit: "cover",
    height: 50,
    width: 50,
  },
  unreadMarker: {
    width: 8,
    height: 8,
    backgroundColor: `${theme.palette.primary.main}`,
    position: "absolute",
    top: 4,
    right: 4,
    borderRadius: "50%",
  },
  unreadMarkerRed: {
    width: 8,
    height: 8,
    backgroundColor: `${theme.palette.secondary.main}`,
    position: "absolute",
    top: 4,
    right: 4,
    borderRadius: "50%",
  },
  unreadMarkerBlue: {
    width: 8,
    height: 8,
    backgroundColor: lightBlue[500],
    position: "absolute",
    top: 4,
    right: 4,
    borderRadius: "50%",
  },
  empty: {
    display: "none",
  },
});

export class JobAppMediaCard extends Component {
  render() {
    const { classes, jobApp } = this.props;
    return (
      <Grid item>
        <CardActionArea
          component={Link}
          className={classes.jobAppMarkup}
          to={`/jobs/applications/${jobApp.id}`}
        >
          <div
            className={!jobApp.isRead ? classes.unreadMarker : classes.empty}
          />

          <img
            src={jobApp.caregiverInfo.imgUrlThumb}
            className={classes.media}
            alt="Profile Pic"
          />
          <Typography variant="body2">
            {capitalizeFirstChar(jobApp.caregiverInfo.firstName)}{" "}
            {capitalizeFirstChar(jobApp.caregiverInfo.lastName)}
          </Typography>
          {jobApp.isAccepted ? (
            <Chip size="small" label="Accepted" className={classes.chipGreen} />
          ) : jobApp.isDeclined ? (
            <Chip size="small" label="Declined" className={classes.chipOrj} />
          ) : (
            <Typography variant="caption" color="textSecondary">
              Applied: {dayjs(jobApp.createdAt).format("MMM DD, YYYY")}
            </Typography>
          )}
        </CardActionArea>
      </Grid>
    );
  }
}

JobAppMediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
  jobApp: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobAppMediaCard);
