import React, { Component } from "react";
import PropTypes from "prop-types";

import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

export class StarDisplay extends Component {
  render() {
    const { numStar, max, size, color } = this.props;

    return [...new Array(max)].map((v, ind) => {
      if (ind < numStar) {
        return (
          <StarIcon
            key={ind}
            fontSize={size}
            color={color ? color : "warning"}
          />
        );
      } else {
        return (
          <StarOutlineIcon
            key={ind}
            fontSize={size}
            color={color ? color : "warning"}
          />
        );
      }
    });
  }
}

StarDisplay.propTypes = {
  numStar: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default StarDisplay;
