import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

// Component Imports
import LoadingEllipsis from "../../../../components/layout/LoadingEllipsis";
import {
  capitalizeFirstChar,
  numberWithCommas,
} from "../../../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Hidden from "@mui/material/Hidden";

import ErrorIcon from "@mui/icons-material/Error";

const styles = (theme) => ({
  ...theme.spreadThis,
  containerBorder: {
    padding: "24px 16px",
  },
  textSecondaryInlineBold: {
    color: theme.palette.secondary.main,
    fontWeight: 550,
  },
  textPrimaryInlineBoldHover: {
    color: theme.palette.primary.main,
    fontWeight: 550,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  importantInfoOutline: {
    display: "flex",
    padding: 10,
    borderRadius: 5,
    border: `1px solid ${theme.palette.secondary.light}`,
  },
  paddedSideOutline: {
    marginLeft: 10,
    paddingLeft: 10,
    borderLeft: `3px solid ${theme.palette.secondary.main}`,
  },
});
export class InvoiceDetails extends Component {
  render() {
    const {
      classes,
      invoiceId,
      isLoadedInvoice,
      errorLoadInvoice,
      loadingApprove,
      invoiceData,
      invoiceData: { createdAt, approvedAt, caregiverInfo, sortedShifts },
    } = this.props;

    const loadingInvoice = (
      <Box style={{ margin: "20px auto", textAlign: "center" }}>
        <CircularProgress thickness={2} size={50} />
        <LoadingEllipsis
          component="div"
          variant="caption"
          color="textSecondary"
          style={{ marginTop: 10 }}
          prefix="Loading Invoice Details"
        />
      </Box>
    );

    const errorInvoice = (
      <Box style={{ margin: "20px auto", textAlign: "center" }}>
        <ErrorIcon color="primary" style={{ fontSize: 50, opacity: 0.5 }} />
        <Typography component="div" color="textSecondary" variant="body2">
          Oops... There seems to be an error loading invoice{" "}
          <Typography component="span" color="primary" fontWeight="bold">
            #{invoiceId}
          </Typography>
          . Please try again later.
        </Typography>
      </Box>
    );
    return (
      <Paper elevation={6} className={classes.containerBorder}>
        <div className={classes.form}>
          <Typography variant="h6" className={classes.selectedGrayContainer}>
            Invoice Details
          </Typography>
          {isLoadedInvoice && !errorLoadInvoice && (
            <div>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                For the week of
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ fontWeight: 300 }}
              >
                {dayjs(Object.keys(sortedShifts)[0])
                  .startOf("week")
                  .format("MMM DD, YYYY")}{" "}
                -{" "}
                {dayjs(Object.keys(sortedShifts)[0])
                  .endOf("week")
                  .format("MMM DD, YYYY")}
              </Typography>
            </div>
          )}
        </div>
        <Box
          style={{
            minHeight: 300,
            marginTop: 60,
          }}
        >
          {!isLoadedInvoice ? (
            loadingInvoice
          ) : errorLoadInvoice ? (
            errorInvoice
          ) : (
            <Box>
              <Box
                className={classes.flexBoxSpaced}
                style={{ marginBottom: 10 }}
              >
                <Typography variant="body2" color="textSecondary">
                  Status:
                </Typography>
                <Chip
                  label={
                    !invoiceData.status.isApproved
                      ? "Pending Approval"
                      : !invoiceData.status.isPaid
                      ? "Ready for Payment"
                      : "Paid"
                  }
                  size="small"
                  color={
                    invoiceData.status.isApproved ? "primary" : "secondary"
                  }
                  className={
                    !invoiceData.status.isApproved
                      ? classes.chipBlue
                      : classes.chipGreen
                  }
                />
              </Box>
              <Box
                p={1}
                style={{
                  borderRadius: 5,
                  backgroundColor: "rgba(212,212,212,0.2)",
                }}
              >
                <Typography component="div" color="textSecondary">
                  Invoice #:{" "}
                  <Box
                    component={Link}
                    to={`/mypay/invoice/${invoiceId}`}
                    display="inline"
                    className={classes.textPrimaryInlineBoldHover}
                  >
                    {invoiceId}
                  </Box>
                </Typography>
                <Typography component="div" color="textSecondary">
                  Poyo Caregiver:{" "}
                  <Typography component="span" style={{ fontWeight: 300 }}>
                    {capitalizeFirstChar(caregiverInfo.firstName)}{" "}
                    {capitalizeFirstChar(caregiverInfo.lastName)}
                  </Typography>
                </Typography>
              </Box>
              <Grid
                container
                style={{ width: "100%", marginTop: 10 }}
                justifyContent="space-evenly"
                className={classes.form}
              >
                <Grid item xs={5} className={classes.importantInfoOutline}>
                  <Box style={{ margin: "auto" }}>
                    <Typography
                      variant="h5"
                      color="secondary"
                      style={{ fontWeight: 550 }}
                    >
                      $
                      {numberWithCommas(
                        invoiceData.invoiceBreakdown.payableBreakdown
                          .totalPayable
                      )}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ fontWeight: 300 }}
                    >
                      Payable
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5} className={classes.importantInfoOutline}>
                  <Box style={{ margin: "auto" }}>
                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ fontWeight: 550 }}
                    >
                      {invoiceData.status.isApproved
                        ? dayjs(approvedAt).format("MMM DD, YYYY")
                        : dayjs(createdAt).format("MMM DD, YYYY")}
                    </Typography>
                    <Typography color="secondary" style={{ fontWeight: 300 }}>
                      Due Date
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box style={{ marginTop: 10 }}>
                <Typography
                  variant="body2"
                  className={classes.paddedSideOutline}
                >
                  Invoice will be automatically approved and paid at 11:59pm on
                  the Due Date with your default payment method if not manually
                  completed.
                </Typography>
              </Box>
              <Box style={{ margin: "20px 0px 10px 0px" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => this.props.approveInvoice(invoiceData.id)}
                  disabled={
                    invoiceData.status.isApproved ||
                    invoiceData.status.isPaid ||
                    loadingApprove
                  }
                  fullWidth
                  className={classes.txtTrButton}
                >
                  Approve{" "}
                  {loadingApprove && (
                    <CircularProgress
                      thickness={2}
                      size={25}
                      className={classes.progress}
                    />
                  )}
                </Button>

                <Hidden mdUp>
                  <Button
                    color="secondary"
                    onClick={() => this.props.showPayDetails()}
                    className={classes.txtTrButton}
                    fullWidth
                  >
                    View Payment Details
                  </Button>
                </Hidden>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    );
  }
}

InvoiceDetails.propTypes = {
  invoiceData: PropTypes.object.isRequired,
  invoiceId: PropTypes.string,
  isLoadedInvoice: PropTypes.bool.isRequired,
  errorLoadInvoice: PropTypes.bool.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  loadingApprove: PropTypes.bool.isRequired,
  showPayDetails: PropTypes.func,
};

export default withStyles(styles)(InvoiceDetails);
