import React from "react";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const TooltipButton = ({
  children,
  onClick,
  tip,
  btnClassName,
  tipClassName,
  placement,
  size,
  style,
  name,
  disabled,
  enterTouchDelay,
  color,
}) => (
  <Tooltip
    title={tip}
    enterTouchDelay={enterTouchDelay}
    className={tipClassName}
    placement={placement}
  >
    <span>
      <IconButton
        name={name}
        onClick={onClick}
        className={btnClassName}
        size={size}
        style={style}
        color={color}
        disabled={disabled}
      >
        {children}
      </IconButton>
    </span>
  </Tooltip>
);

export default TooltipButton;
