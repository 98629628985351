import React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";

const ScrollHandler = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 8,
    target: props.window ? window() : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger
        ? "#2dad7e"
        : props.notHome
        ? "#2dad7e"
        : "rgba(47,47,47,0.4)",
      // opacity: trigger ? 0 : 1,
      boxShadow: trigger
        ? "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
        : "none",
      transition: "0.3s",
      padding: trigger ? 0 : "4px 0px",
      color: trigger ? "#fff" : "#fff",
      // borderBottom: trigger ? "2px solid #17b890" : "none",
    },
  });
};

const ScrollToColor01 = (props) => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

export default ScrollToColor01;
