import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Component imports
import EducationMarkup from "./components/EducationMarkup";
import AccreditationMarkup from "./components/AccreditationMarkup";
import TooltipButton from "../../../../components/inputs/TooltipButton";

// Redux imports
import { connect } from "react-redux";
import {
  submitEduAccred,
  editEduAccred,
} from "../../../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// Icons imports
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/EditOutlined";
import SchoolIcon from "@mui/icons-material/SchoolOutlined";
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const StyledToggleButton = withStyles({
  root: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    padding: "15px 15px",
    textTransform: "none",
    width: "100%",
    borderRadius: 25,
    "&$selected": {
      backgroundColor: "rgba(33, 137, 214, 0.14)",
      color: "rgb(26, 88, 159)",
      "&:hover": {
        backgroundColor: "rgba(33, 137, 214, 0.14)",
        color: "rgb(26, 88, 159)",
      },
    },
  },
  selected: {},
})(ToggleButton);

export class CaregiverEduDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        type: false,
        school: false,
        endDate: false,
        accredName: false,
      },
      school: "",
      degree: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
      description: "",
      accredName: "",
      accredDate: "",
      accredDescription: "",
      type: null,
      open: false,
    };
    this.baseState = this.state;
  }

  // Load methods
  componentDidMount() {
    this.setInitialEdit();
  }

  setInitialEdit() {
    const { isEdit, eduAccred } = this.props;
    if (isEdit) {
      this.setState({ type: eduAccred.type });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.UI.loadingSpecial &&
      !this.props.UI.loadingSpecial &&
      !this.props.UI.errors
    ) {
      this.handleClose();
    }
  }

  // UI Methods
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    const { isEdit } = this.props;
    if (reason !== "backdropClick")
      isEdit ? this.setState({ open: false }) : this.setState(this.baseState);
  };

  // Action methods

  handleToggleType = (e, value) => {
    if (value !== null) {
      this.setState({ type: value });
    }
  };

  handleSubmit = (v) => {
    const { isEdit, eduAccredId } = this.props;
    isEdit
      ? this.props.editEduAccred(v, eduAccredId)
      : this.props.submitEduAccred(v);
  };

  render() {
    const {
      isEdit,
      eduAccred,
      eduAccredId,
      classes,
      UI: { loadingSpecial },
    } = this.props;
    const { type, open } = this.state;

    return (
      <Fragment>
        <TooltipButton
          onClick={this.handleOpen}
          tip={isEdit ? "Edit" : "Add"}
          size="small"
          tipClassName={classes.expandButton}
        >
          {isEdit ? <EditIcon /> : <AddIcon color="primary" />}
        </TooltipButton>
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
          disableEscapeKeyDown
        >
          <DialogContent className={classes.dialogContent}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.typography}>
                {isEdit ? "Edit" : "Add"} Education
              </Typography>

              <TooltipButton
                tip="Cancel"
                placement="top"
                size="small"
                onClick={this.handleClose}
                tipClassName={classes.closeButton}
              >
                <CloseIcon color="primary" />
              </TooltipButton>
            </Box>
            <Divider style={{ margin: "8px 0px" }} />
            <Typography className={classes.typography}>Select one*</Typography>
            <Box
              className={classes.flexBoxCentered}
              style={{ marginBottom: 16 }}
            >
              <ToggleButtonGroup
                value={type}
                onChange={this.handleToggleType}
                name="type"
                id="type-select"
                exclusive={true}
                size="small"
              >
                <StyledToggleButton disabled={isEdit} value="education">
                  <SchoolIcon size="small" style={{ marginRight: 5 }} />
                  <Typography variant="body2">Education</Typography>
                </StyledToggleButton>
                <StyledToggleButton disabled={isEdit} value="accreditation">
                  <AssignmentIcon size="small" style={{ marginRight: 5 }} />
                  <Typography variant="body2">Accreditation</Typography>
                </StyledToggleButton>
              </ToggleButtonGroup>
            </Box>
            {type === "education" ? (
              <EducationMarkup
                handleSubmit={this.handleSubmit}
                disableWhileLoad={loadingSpecial}
                isEdit={isEdit}
                eduAccred={eduAccred}
                eduAccredId={eduAccredId}
              />
            ) : type === "accreditation" ? (
              <AccreditationMarkup
                handleSubmit={this.handleSubmit}
                disableWhileLoad={loadingSpecial}
              />
            ) : null}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

CaregiverEduDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  submitEduAccred: PropTypes.func,
  UI: PropTypes.object.isRequired,
  User: PropTypes.object,
  eduAccredId: PropTypes.string,
  eduAccred: PropTypes.object,
  isEdit: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, { submitEduAccred, editEduAccred })(
  withStyles(styles)(CaregiverEduDialog)
);
