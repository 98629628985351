import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import TooltipButton from "../inputs/TooltipButton";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class DeleteIconButton extends Component {
  state = {
    open: false,
  };

  handleDialogOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete = () => {
    this.props.onDeleteClick();
    this.setState({ open: false });
  };

  render() {
    const { classes, color } = this.props;
    return (
      <Fragment>
        <TooltipButton
          size="small"
          tip="Delete"
          onClick={this.handleDialogOpen}
          tipClassName={classes.expandButton}
        >
          <DeleteOutlineIcon color={color ? color : "primary"} />
        </TooltipButton>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 150,
                width: 200,
              }}
            >
              <Typography>Confirm delete?</Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              color="primary"
              onClick={this.handleClose}
              size="small"
            >
              No
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DeleteIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};

export default withStyles(styles)(DeleteIconButton);
