import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";
import { Redirect } from "react-router-dom";
import clsx from "clsx";

// Component Imports
import LocationSearch from "../../../components/inputs/LocationSearch";
import DateSelector from "../../../components/inputs/DateSelector";
import {
  isEmpty,
  isPhoneNum,
  allFalse,
  capitalizeFirstChar,
} from "../../../util/utilFunctions";
import firebase from "../../../Firebase";
import StyledToggleButtonGroup from "../../../components/inputs/StyledToggleButtonGroup";
import Stepper from "../../../components/layout/Components/Stepper";

// Redux stuff
import { connect } from "react-redux";
import { getUserData } from "../../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import Hidden from "@mui/material/Hidden";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class caregiverBasicInfo extends Component {
  state = {
    firstName: "",
    lastName: "",
    phoneNum: "",
    gender: "",
    address: {
      addressFull: "",
    },
    workRadius: "",
    dob: "",
    errors: {},
    disableWhileLoad: false,
  };

  componentDidMount() {
    this.checkAccountStatus();
  }

  checkAccountStatus() {
    const { isBasicCompleted, isServiceCompleted, isEduAccredCompleted } =
      this.props.user.credentials.status;
    if (isBasicCompleted) {
      this.props.history.push("/caregiver/signup/services");
    }
    if (isServiceCompleted) {
      this.props.history.push("/caregiver/signup/education-experience");
    }
    if (isEduAccredCompleted) {
      this.props.history.push("/caregiver/signup/finish");
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Action Methods

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: { ...this.state.errors, [event.target.name]: null },
    });
  };

  handleSelectDob = (value) => {
    this.setState({
      dob: value,
      errors: { ...this.state.errors, dob: null },
    });
  };

  handleToggleValue = (name, value) => {
    this.setState({
      [name]: value,
      errors: { ...this.state.errors, [name]: null },
    });
  };

  handlePhoneNum = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      this.setState({
        phoneNum: onlyNums,
        errors: {
          ...this.state.errors,
          phoneNum: onlyNums === "" ? "Must not be empty" : null,
        },
      });
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      this.setState({
        phoneNum: number,
        errors: { ...this.state.errors, phoneNum: null },
      });
    }
  };

  handleToggleWorkRadius = (e, value) => {
    if (value !== null) {
      this.setState({
        workRadius: value,
        errors: { ...this.state.errors, workRadius: null },
      });
    }
  };

  handleAddress = (value) => {
    this.setState({
      address: value,
      errors: { ...this.state.errors, address: null },
    });
  };

  // Error Methods

  validateBasicInfo = () => {
    const {
      firstName,
      lastName,
      dob,
      phoneNum,
      gender,
      address,
      workRadius,
      errors,
    } = this.state;

    let _errors = errors;

    if (isEmpty(firstName)) _errors.firstName = "Must not be empty";
    if (isEmpty(lastName)) _errors.lastName = "Must not be empty";
    if (!Date.parse(dob)) _errors.dob = "Must not be empty";
    if (isEmpty(phoneNum)) {
      _errors.phoneNum = "Must not be empty";
    } else if (!isPhoneNum(phoneNum))
      _errors.phoneNum = "Must be a valid phone number";
    if (isEmpty(gender)) _errors.gender = "Please make a selection";
    if (isEmpty(workRadius)) _errors.workRadius = "Please make a selection";
    if (isEmpty(address.addressFull)) _errors.address = true;

    this.setState({ errors: _errors, disableWhileLoad: true });
  };

  handleSetError = (errors) => {
    this.setState({ errors, disableWhileLoad: false });
  };

  // Submit Methods

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      firstName,
      lastName,
      dob,
      phoneNum,
      gender,
      address,
      workRadius,
      errors,
    } = this.state;

    await this.validateBasicInfo();

    if (allFalse(errors)) {
      const userDetails = {
        firstName,
        lastName,
        dob,
        phoneNum,
        gender,
        address,
        workRadius,
      };

      try {
        // Calls API to submit user details form to firestore
        await axios.post("/user/caregiver/basic-info", userDetails);

        // Declare and updates user details on firebase Auth
        let user = firebase.auth().currentUser;

        await user.updateProfile({
          displayName: `${capitalizeFirstChar(firstName)} ${capitalizeFirstChar(
            lastName
          )}`,
          phoneNumber: phoneNum,
        });

        // Push to next signup page
        setTimeout(
          () => this.props.history.push("/caregiver/signup/services"),
          500
        );
      } catch (err) {
        console.error(err);
        // this.handleSetError(err.response.data);
        return;
      }
    } else {
      this.handleSetError(errors);
    }
  };

  render() {
    const {
      classes,
      user: { credentials },
    } = this.props;
    const {
      firstName,
      lastName,
      phoneNum,
      gender,
      workRadius,
      dob,
      errors,
      disableWhileLoad,
      address,
    } = this.state;

    if (credentials.isBasicCompleted) {
      return <Redirect to="/caregiver/signup/services" />;
    }

    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.form}
        style={{ marginTop: 24 }}
      >
        <Hidden smDown>
          <Fade in={true} timeout={1000}>
            <Grid item sm={4} md={6}>
              <Typography
                align="left"
                variant="h2"
                style={{ wordBreak: "break-word" }}
              >
                Welcome
              </Typography>
              <Typography align="left" variant="h4">
                to Poyo Health.
              </Typography>
            </Grid>
          </Fade>
        </Hidden>
        <Grid item xs={12} sm={7} md={5} lg={5}>
          <Paper
            variant="elevation"
            elevation={4}
            className={clsx(
              classes.paperContainerSignup,
              classes.darkGrayBorderOnly
            )}
          >
            <div>
              <Stepper total={5} fadeIn={1} />

              <Typography
                variant="h6"
                className={classes.typography}
                style={{ marginTop: 16 }}
              >
                Welcome to Poyo!
              </Typography>

              <Typography variant="body2" className={classes.typography}>
                Let's start with some basic info to setup your profile.
              </Typography>
              <form
                noValidate
                onSubmit={this.handleSubmit}
                style={{ marginTop: 16 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      type="firstName"
                      label="First Name"
                      variant="outlined"
                      className={
                        !errors.firstName
                          ? classes.styledTextField
                          : classes.styledTextFieldError
                      }
                      error={errors.firstName ? true : false}
                      helperText={errors.firstName}
                      onChange={this.handleChange}
                      value={firstName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      type="lastName"
                      label="Last Name"
                      variant="outlined"
                      className={
                        !errors.lastName
                          ? classes.styledTextField
                          : classes.styledTextFieldError
                      }
                      error={errors.lastName ? true : false}
                      helperText={errors.lastName}
                      onChange={this.handleChange}
                      value={lastName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color={errors.gender ? "error" : "textPrimary"}
                      variant="body2"
                      align="left"
                      style={{ marginBottom: 4 }}
                    >
                      My Gender
                    </Typography>
                    <StyledToggleButtonGroup
                      name="gender"
                      value={gender}
                      handleToggleValue={this.handleToggleValue}
                      options={{ male: "male", female: "female" }}
                      error={Boolean(errors.gender)}
                      errorMsg={errors.gender}
                      exclusive
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DateSelector
                      value={dob}
                      propMaxDate={dayjs()}
                      onSelectDate={this.handleSelectDob}
                      errorDate={errors.dob ? true : false}
                      errorDateText={errors.dob}
                      className={
                        !errors.dob
                          ? classes.styledTextField
                          : classes.styledTextFieldError
                      }
                      label="Date of Birth"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="phoneNum"
                      name="phoneNum"
                      type="tel"
                      label="Phone Number"
                      variant="outlined"
                      style={{ backgroundColor: "white" }}
                      className={
                        !errors.phoneNum
                          ? classes.styledTextField
                          : classes.styledTextFieldError
                      }
                      value={phoneNum}
                      onChange={this.handlePhoneNum}
                      error={errors.phoneNum ? true : false}
                      helperText={errors.phoneNum}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color={errors.workRadius ? "error" : "textPrimary"}
                      variant="body2"
                      style={{ marginBottom: 3 }}
                    >
                      I am comfortable working within
                    </Typography>
                    <StyledToggleButtonGroup
                      name="workRadius"
                      value={workRadius}
                      handleToggleValue={this.handleToggleValue}
                      options={{
                        "10 km": "10",
                        "15 km": "15",
                        "25 km": "25",
                        "50 km": "50",
                      }}
                      error={Boolean(errors.workRadius)}
                      errorMsg={errors.workRadius}
                      exclusive
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocationSearch
                      onSelectAddress={this.handleAddress}
                      errorAddress={Boolean(errors.address) ? true : false}
                      address={address}
                      medium
                      label="Address"
                      placeholder="Address..."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      className={classes.button}
                      disabled={disableWhileLoad}
                    >
                      Next
                      {disableWhileLoad && (
                        <CircularProgress
                          thickness={2}
                          size={30}
                          className={classes.progressRight}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

caregiverBasicInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { getUserData })(
  withStyles(styles)(caregiverBasicInfo)
);
