import React, { Component } from "react";

import NativeSelect from "@mui/material/NativeSelect";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";

export class StyledSelect extends Component {
  handleSelectFilter = (e) => {
    this.props.onChange(e.target.value);
  };
  render() {
    const { values, style, value, defaultText, className, size } = this.props;
    return (
      <FormControl size={size ? size : "small"}>
        <NativeSelect
          variant="outlined"
          value={value}
          input={<OutlinedInput className={className} />}
          onChange={this.handleSelectFilter}
          style={{ width: 100, ...style }}
        >
          <option value="">{defaultText ? defaultText : "None"}</option>
          {values.map((v) => (
            <option value={v} key={v}>
              {v}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    );
  }
}

export default StyledSelect;
