import {
  SET_USER,
  SET_INITIAL_LOAD,
  SET_ERRORS,
  SET_UPDATED,
  CLEAR_UPDATED,
  ADD_WORKEXP,
  EDIT_WORKEXP,
  DELETE_WORKEXP,
  ADD_EDUACCRED,
  EDIT_EDUACCRED,
  DELETE_EDUACCRED,
  FINISH_EDUACCRED,
  FINISH_USER,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  CLEAR_DATA,
  LOADING_USER,
  LOADING_UI_SPECIAL,
  UPDATE_PROFILE_IMG,
  // UPLOAD_BACKGROUND_CHECK,
  SET_BANKING,
  SET_FAV,
  SET_FAVOURITE_DOC,
  SET_AVAIL,
  SET_NOTIF,
  CLEAR_NOTIF,
  SET_SIGNUPSUCCESS,
  SET_PREFERRED_PAYMENT_METHOD,
} from "../types";
import axios from "axios";
import firebase from "../../Firebase";

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .post("/login", userData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      // dispatch(updateOnline());
      // dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      // console.error(err.response.data);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const signupUserCaregiver = (newUserData) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .post("/signup", newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(setSignupSuccess());
      dispatch(getUserData());
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const signupUserFamily = (newUserData) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .post("/signup-family", newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(setSignupSuccess());
      dispatch(getUserData());
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch(updateOffline());

  localStorage.removeItem("FBIdToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
  dispatch({ type: CLEAR_DATA });
};

const updateOnline = () => async (dispatch) => {
  await axios.get("/login/online");
};

export const updateOffline = () => async (dispatch) => {
  await axios.get("/login/offline");
};

export const setSignupSuccess = () => (dispatch) => {
  dispatch({ type: SET_SIGNUPSUCCESS });
};

//Get own data
export const getUserData = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const fullUser = await axios.get("/user");
    dispatch({
      type: SET_USER,
      payload: fullUser.data,
    });
    dispatch({ type: SET_INITIAL_LOAD });
  } catch (err) {
    console.error(err.response);
    return;
  }
};

// FB set auth header
export const setFBAuthHeader = (token, authenticated) => async (dispatch) => {
  setAuthorizationHeader(token);
  if (!authenticated) {
    dispatch(updateOnline());
    dispatch(getUserData());
  }
};

//Set auth header
const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};

// Upload profile pic
export const uploadImg = (formData) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .post("/user/image", formData)
    .then((res) => {
      dispatch({ type: UPDATE_PROFILE_IMG, payload: res.data });
      dispatch(clearErrors());
      let user = firebase.auth().currentUser;
      user.updateProfile({
        photoURL: res.data.imgUrlThumb,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: "Please select a valid image format (jpeg or png)",
      });
      console.error(err);
    });
};

// Not used
export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .post("/user", userDetails)
    .then(() => {
      dispatch(getUserData());
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Submit signup basic info
export const submitBasicInfo = (userDetails) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .post("/user/caregiver/basic-info", userDetails)
    .then(() => {
      dispatch(getUserData());
      dispatch(clearErrors());
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Submit signup service info
export const submitServiceInfo = (userDetails) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .post("/user/caregiver/service-info", userDetails)
    .then(() => {
      dispatch(getUserData());
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Submit new work exp
export const submitWorkExp = (userDetails) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .post("/user/caregiver/work-exp", userDetails)
    .then((res) => {
      dispatch({
        type: ADD_WORKEXP,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Edit a work exp
export const editWorkExp = (userDetails, workExpId) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .put(`/user/caregiver/work-exp/${workExpId}`, userDetails)
    .then((res) => {
      dispatch({
        type: EDIT_WORKEXP,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => console.error(err));
};

// Delete a work exp
export const deleteWorkExp = (workExpId) => (dispatch) => {
  axios
    .delete(`/user/caregiver/work-exp/${workExpId}`)
    .then(() => {
      dispatch({ type: DELETE_WORKEXP, payload: workExpId });
    })
    .catch((err) => console.error(err));
};

// Clear errors
export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

//Submit new EduAccred
export const submitEduAccred = (newEduAccred) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .post("/user/caregiver/edu-accred", newEduAccred)
    .then((res) => {
      dispatch({
        type: ADD_EDUACCRED,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => console.error(err));
};

//Edit a edu accred
export const editEduAccred = (newEduAccred, eduAccredId) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .put(`/user/caregiver/edu-accred/${eduAccredId}`, newEduAccred)
    .then((res) => {
      dispatch({
        type: EDIT_EDUACCRED,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => console.error(err));
};

// Delete a edu accred
export const deleteEduAccred = (eduAccredId) => (dispatch) => {
  axios
    .delete(`/user/caregiver/edu-accred/${eduAccredId}`)
    .then(() => {
      dispatch({ type: DELETE_EDUACCRED, payload: eduAccredId });
    })
    .catch((err) => console.error(err));
};

// Finish edu accred
export const finishEduAccred = () => (dispatch) => {
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .post(`/user/caregiver/edu-accred/finish`)
    .then((res) => {
      dispatch({
        type: FINISH_EDUACCRED,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => console.error(err));
};

// // Upload background check
// export const uploadBgCheck = (formData) => (dispatch) => {
//   dispatch({ type: CLEAR_ERRORS });
//   dispatch({ type: LOADING_UI_SPECIAL });

//   axios
//     .post("/user/caregiver/background-check", formData)
//     .then((res) => {
//       dispatch({ type: SET_UPDATED });

//       dispatch({ type: UPLOAD_BACKGROUND_CHECK, payload: res.data });
//       dispatch({ type: CLEAR_UPDATED });

//       dispatch(clearErrors());
//     })
//     .catch((err) => {
//       dispatch({
//         type: SET_ERRORS,
//         payload: err.response.data,
//       });
//       console.error(err);
//     });
// };

export const finishUser = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI_SPECIAL });

  axios
    .post("/user/caregiver/finish")
    .then((res) => {
      dispatch({ type: FINISH_USER, payload: res.data });
      dispatch(clearErrors());
    })
    .catch((err) => {
      console.error(err);
    });
};

// Set Banking Details
export const setBankingDetails = (bankingDetails) => (dispatch) => {
  dispatch({
    type: SET_BANKING,
    payload: bankingDetails,
  });
};

// Set Favourite
export const setFav = (docId) => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });

  try {
    const favourites = await axios.post(`/favourite/${docId}`);
    dispatch({
      type: SET_FAV,
      payload: favourites.data,
    });

    const favDoc = await axios.get(`/favourite/${docId}`);
    dispatch({
      type: SET_FAVOURITE_DOC,
      payload: { doc: { ...favDoc.data }, add: true, docId },
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data,
    });
  }
};

// Set Unfavourite
export const setUnfav = (docId) => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });

  try {
    const favourites = await axios.post(`/unfavourite/${docId}`);
    dispatch({
      type: SET_FAV,
      payload: favourites.data,
    });
    dispatch({
      type: SET_FAVOURITE_DOC,
      payload: { doc: {}, add: false, docId },
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data,
    });
  }
};

// Set Avail
export const setAvail = (availObj) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .post("/user/avail/set", availObj)
    .then((res) => {
      dispatch({ type: SET_UPDATED });
      dispatch({
        type: SET_AVAIL,
        payload: res.data,
      });
      dispatch({ type: CLEAR_UPDATED });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const delAvail = (availId) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });

  axios
    .get(`/user/avail/del/${availId}`)
    .then((res) => {
      dispatch({
        type: SET_AVAIL,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Set notification
export const setNotif = (notifArr, lastKey) => (dispatch) => {
  dispatch({
    type: SET_NOTIF,
    payload: { notifArr, lastKey },
  });
};

// Clear notification
export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  });
};

// Updates preferred payment method
export const setPerferredPaymentMethod =
  (paymentMethodId) => async (dispatch) => {
    dispatch({ type: LOADING_UI });

    try {
      await axios.post("/payment/preferred/payment-method", {
        id: paymentMethodId,
      });
      dispatch({
        type: SET_PREFERRED_PAYMENT_METHOD,
        payload: paymentMethodId,
      });
      dispatch(clearErrors());
    } catch (err) {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    }
  };

// Delete payment method
export const deletePaymentMethod = (pmtMethodDocId) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const resPaymentMethodId = await axios.post(
      "/payment/delete/payment-method",
      {
        id: pmtMethodDocId,
      }
    );
    dispatch({
      type: SET_PREFERRED_PAYMENT_METHOD,
      payload: resPaymentMethodId.data.paymentMethodId,
    });
    dispatch(clearErrors());
  } catch (err) {
    // console.error(err);
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data,
    });
  }
};
