import React from "react";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

function PolicyLinks() {
  return (
    <Grid container justifyContent="center">
      <Typography
        component={Link}
        to="/policies/terms-of-use"
        color="textSecondary"
        variant="body2"
        className="footer-margin"
      >
        Terms of Use
      </Typography>
      <Divider orientation="vertical" flexItem className="footer-margin" />
      <Typography
        component={Link}
        to="/policies/privacy"
        color="textSecondary"
        variant="body2"
        className="footer-margin"
      >
        Privacy
      </Typography>
    </Grid>
  );
}

export default PolicyLinks;
