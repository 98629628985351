import React from "react";
import { Link } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
function AboutMenu() {
  return (
    <div className="dropdown">
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        color="inherit"
        className="link-btn dropbtn"
        endIcon={<ArrowDropDownIcon style={{ transition: "0s" }} />}
      >
        <Typography variant="subtitle2" color="inherit">
          Company
        </Typography>
      </Button>

      <div className="dropdown-content">
        <Typography
          align="left"
          color="textPrimary"
          style={{ fontWeight: 300 }}
        >
          About
        </Typography>
        <div>
          <div className="title-line" />
        </div>
        <MenuItem
          component={Link}
          to="/about-us"
          className="dropdown-content-link"
        >
          <Typography color="textSecondary" variant="body2">
            Our Story
          </Typography>
        </MenuItem>
        <MenuItem
          component={Link}
          to="/policies"
          className="dropdown-content-link"
        >
          <Typography color="textSecondary" variant="body2">
            Policies
          </Typography>
        </MenuItem>
        <MenuItem
          component={Link}
          to="/response-to-covid-19"
          className="dropdown-content-link"
        >
          <Typography color="textSecondary" variant="body2">
            COVID-19 Policy
          </Typography>
        </MenuItem>
        <MenuItem
          component={Link}
          to="/careers"
          className="dropdown-content-link"
        >
          <Typography color="textSecondary" variant="body2">
            Careers
          </Typography>
        </MenuItem>
      </div>
    </div>
  );
}

export default AboutMenu;
