import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../../../assets/logo/logo.svg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

function ContactGrid() {
  return (
    <>
      <Grid
        component="address"
        container
        direction="column"
        spacing={2}
        style={{ fontStyle: "normal" }}
      >
        <Grid item>
          <Link to="/">
            <img src={Logo} alt="logo-full" style={{ width: 100 }} />
          </Link>
        </Grid>
        <Grid item className="flexbox">
          <PhoneOutlinedIcon color="disabled" fontSize="small" />
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 8, textDecoration: "none" }}
          >
            778 558 2646
          </Typography>
        </Grid>
        <Grid item className="flexbox">
          <EmailOutlinedIcon color="disabled" fontSize="small" />
          <Typography
            component="a"
            href="mailto:support@poyohealth.com"
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 8, textDecoration: "none" }}
          >
            support@poyohealth.com
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default ContactGrid;
