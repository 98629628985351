import React from "react";
import { Link } from "react-router-dom";
import { isDesktop } from "react-device-detect";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function MainLinks() {
  return (
    <Grid container spacing={1} justifyContent="space-evenly">
      <Grid item xs={12} sm={"auto"}>
        <Typography
          component={Link}
          to="/about-us"
          variant="body2"
          color="textSecondary"
          className={`${isDesktop && "hover-txt-primary"}`}
        >
          About Us
        </Typography>
      </Grid>

      <Grid item xs={12} sm={"auto"}>
        <Typography
          component={Link}
          to="/response-to-covid-19"
          variant="body2"
          color="textSecondary"
          className={`${isDesktop && "hover-txt-primary"}`}
        >
          Covid-19 Advisory
        </Typography>
      </Grid>

      <Grid item xs={12} sm={"auto"}>
        <Typography
          component={Link}
          to="/resources"
          variant="body2"
          color="textSecondary"
          className={`${isDesktop && "hover-txt-primary"}`}
        >
          Resources
        </Typography>
      </Grid>

      <Grid item xs={12} sm={"auto"}>
        <Typography
          component={Link}
          to="/contact"
          variant="body2"
          color="textSecondary"
          className={`${isDesktop && "hover-txt-primary"}`}
        >
          Contact Us
        </Typography>
      </Grid>

      <Grid item xs={12} sm={"auto"}>
        <Typography
          component={Link}
          to="/careers"
          variant="body2"
          color="textSecondary"
          className={`${isDesktop && "hover-txt-primary"}`}
        >
          Careers
        </Typography>
      </Grid>
    </Grid>
  );
}

export default MainLinks;
