import React, { useEffect, useLayoutEffect } from "react";

import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

import CovidHero from "./components/CovidHero";
import CovidAction from "./components/CovidAction";
import CovidMessage from "./components/CovidMessage";
import "./covid.css";

// import Fade from "@mui/material/Fade";

function Covid19() {
  useEffect(() => {
    store.dispatch({ type: SET_PAGE, payload: "Cov19" });
  }, []);

  useLayoutEffect(() => {
    store.dispatch({ type: UNSET_PAGE });
  }, []);

  return (
    <div className="page-wrapper">
      <section className="full-vw-container cv-main s-bg">
        <CovidHero />
      </section>

      <section className="full-vw-container white-bg stn-margin">
        <CovidAction />
      </section>

      <section className="stn-margin">
        <CovidMessage />
      </section>
    </div>
  );
}

export default Covid19;
