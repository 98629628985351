import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Icon imports
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import MessageIcon from "@mui/icons-material/Message";
import WorkIcon from "@mui/icons-material/Work";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "rgb(230,230,236)",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <VerifiedUserIcon />,
    2: <MessageIcon />,
    3: <WorkIcon />,
    4: <AssignmentTurnedInIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
    position: "relative",
    marginTop: 16,
  },
  container: {
    border: `2px solid ${theme.palette.secondary.light}`,
    borderRadius: 10,
    paddingTop: 16,
  },
  headerText: {
    position: "absolute",
    backgroundColor: "rgb(246,247,249)",
    top: -16,
    left: 16,
    padding: "0px 4px",
  },
}));

function getSteps() {
  return [
    "Complete your profile",
    "Send your first message",
    "Apply for a job",
    "Complete your first shift",
  ];
}

function CaregiverAccountStepper({ classes, credentials: { status } }) {
  const _classes = useStyles();
  const steps = getSteps();
  const _activeStep = Boolean(status.isApply)
    ? 3
    : Boolean(status.isMessage)
    ? 2
    : Boolean(status.isBgCheck)
    ? 1
    : 0;

  return (
    <div className={_classes.root}>
      <Box className={_classes.headerText}>
        <Typography variant="h6" color="primary">
          What Next?
        </Typography>
      </Box>

      <Box p={1} className={_classes.container}>
        <Typography>Let's help you get started with Poyo!</Typography>
        <Stepper
          alternativeLabel
          activeStep={_activeStep}
          connector={<ColorlibConnector />}
          style={{ padding: 8 }}
        >
          {steps.map((label, ind) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Link
                  to={ind === 0 ? "/profile" : ind < 3 ? "/jobs" : "/myteam"}
                >
                  <Typography
                    component={"h6"}
                    color="textPrimary"
                    variant="subtitle2"
                    className={
                      ind < _activeStep
                        ? classes.chipGreen
                        : ind === _activeStep
                        ? classes.chipBlue
                        : classes.classesOrj
                    }
                    style={{ padding: 4, borderRadius: 10 }}
                  >
                    {label}
                  </Typography>
                </Link>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}
export default withStyles(styles)(CaregiverAccountStepper);
