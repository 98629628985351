import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";
import ProfilePicUploadButton from "../inputs/ProfilePicUploadButton";

// Redux imports
import { connect } from "react-redux";
import { uploadImg } from "../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Icons imports
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";

const styles = (theme) => ({
  ...theme.spreadThis,
  mediaBox: {
    height: 150,
    width: 150,
    [theme.breakpoints.down("md")]: {
      height: 250,
      width: 250,
    },
    [theme.breakpoints.down("sm")]: {
      height: 150,
      width: 150,
    },
    borderRadius: "50%",
    border: "4px solid white",
    position: "relative",
  },
  media: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  },
  responsiveIconButton: {
    position: "absolute",
    bottom: 0,
    right: "0%",
    [theme.breakpoints.down("md")]: {
      right: "5%",
    },
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(231, 240, 248, 0.95)",
    },
  },
});

export class ProfilePicBlock extends Component {
  state = { disableWhileLoad: false };

  setDisable = () => {
    this.setState({ disableWhileLoad: !this.state.disableWhileLoad });
  };

  render() {
    const {
      classes,
      credentials,
      credentials: {
        address: { addressParams },
      },
    } = this.props;

    const { disableWhileLoad } = this.state;
    return (
      <Paper
        elevation={4}
        className={classes.paperContainerD}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box className={classes.mediaBox}>
          <img
            src={credentials.imgUrlThumb}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = credentials.imgUrl;
            }}
            alt="Profile Pic"
            className={classes.media}
            style={
              disableWhileLoad
                ? {
                    filter: "grayscale(50%)",
                  }
                : {}
            }
          />

          {disableWhileLoad ? (
            <Fragment>
              {/*  Hidden only on sm*/}

              <Hidden only="sm">
                <CircularProgress
                  thickness={2}
                  size={142}
                  style={{ position: "absolute", left: 0 }}
                />
              </Hidden>
              {/*  Show only on sm*/}

              <Hidden smDown mdUp>
                <CircularProgress
                  thickness={2}
                  size={242}
                  style={{ position: "absolute", left: 0 }}
                />
              </Hidden>
            </Fragment>
          ) : (
            <ProfilePicUploadButton
              size="small"
              className={classes.responsiveIconButton}
              onLoad={this.setDisable}
              onFinish={this.setDisable}
            />
          )}
        </Box>

        <Typography variant="h5" className={classes.typography}>
          {capitalizeFirstChar(credentials.firstName)}{" "}
          {capitalizeFirstChar(credentials.lastName)}
        </Typography>
        {credentials.userType === "caregiver" && credentials.reviewScore > 0 && (
          <div className={classes.flexBox} style={{ color: "#ffb300" }}>
            <Typography fontWeight="bold">
              {credentials.reviewScore.toFixed(2)}
            </Typography>
            <StarIcon />
          </div>
        )}
        <div className={classes.flexBoxCentered}>
          <LocationOnIcon color="primary" />
          <Typography className={classes.typography}>
            {capitalizeFirstChar(addressParams.locality)}
            {", "}
            {addressParams.adminAreaLevel1.toUpperCase()}
          </Typography>
        </div>
      </Paper>
    );
  }
}

ProfilePicBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default connect(null, { uploadImg })(
  withStyles(styles)(ProfilePicBlock)
);
