import React, { Component, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Component imports

import JobProfile from "../../../components/jobs/JobProfile";
import ProfilePicBlock from "../../../components/profile/ProfilePicBlock";
import MyInfoBlock from "../../../components/profile/MyInfoBlock";

// Redux imports
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAlert from '@mui/material/Alert';

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class FamilyProfile extends Component {
  state = {
    jobs: [],
    isLoadedJobs: false,
    isMyInfoUpdated: false,
  };

  componentDidMount() {
    this.getJobs();
  }

  getJobs() {
    axios
      .post("/my-jobs", { isUpdate: false })
      .then((res) => {
        let resData = res.data;
        resData.sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
        );
        this.setState({ jobs: resData, isLoadedJobs: true });
      })
      .catch((err) => console.error(err));
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const {
      classes,
      user: { credentials },
    } = this.props;

    const { isLoadedJobs, jobs } = this.state;

    const jobsMarkup =
      jobs.length > 0 &&
      jobs.slice(0, 4).map((job, index) => (
        <Fragment key={job.id}>
          <JobProfile jobData={job} />
          {index < jobs.slice(0, 4).length - 1 && (
            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          )}
        </Fragment>
      ));

    const headerInfo = (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProfilePicBlock credentials={credentials} />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={4} className={classes.darkGrayBorder}>
            <Typography
              variant="h6"
              align="left"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              Account Status
            </Typography>

            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Typography
                variant="body2"
                className={classes.typography}
                style={{ flex: 1 }}
              >
                Payment Info:
              </Typography>

              {Boolean(credentials.status.isPaymentMethod) ? (
                <Link to="/mypay/detail" style={{ flex: 1 }}>
                  <MuiAlert severity="success" style={{ width: 140 }}>
                    Completed
                  </MuiAlert>
                </Link>
              ) : (
                <Link to="/mypay/detail" style={{ flex: 1 }}>
                  <MuiAlert severity="error" style={{ width: 140 }}>
                    Missing
                  </MuiAlert>
                </Link>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "left" }}>
          <MyInfoBlock credentials={credentials} />
        </Grid>
      </Grid>
    );

    const content = (
      <Grid item xs={12} sm={10} md={8}>
        <Paper elevation={4} className={classes.darkGrayBorder}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h6"
              align="left"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              My Job Postings
            </Typography>

            <Typography
              component={Link}
              to="/profile/jobs/new"
              variant="subtitle2"
              color="primary"
              className={classes.typographyHover}
            >
              Add Posting
            </Typography>
          </Box>
          <Divider />
          <br />
          {!isLoadedJobs ? <CircularProgress thickness={2} /> : jobsMarkup}
          {jobs.length > 4 && (
            <Typography
              component={Link}
              to="/profile/jobs"
              variant="subtitle2"
              align="right"
              color="primary"
              className={classes.typographyHover}
            >
              View All
            </Typography>
          )}
        </Paper>
      </Grid>
    );

    return (
      <Fragment>
        <Hidden smDown>
          <Grid
            container
            justifyContent="center"
            className={classes.form}
            spacing={3}
          >
            <Grid
              container
              item
              xs={12}
              sm={10}
              md={4}
              className={classes.form}
            >
              {headerInfo}
            </Grid>
            {content}
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container justifyContent="center" className={classes.form}>
            <Grid item xs={11}>
              {headerInfo}

              <div style={{ marginTop: 25 }}>{content}</div>
            </Grid>
          </Grid>
        </Hidden>
      </Fragment>
    );
  }
}

FamilyProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(FamilyProfile));
