import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import clsx from "clsx";

// Component imports
import { numberWithCommas } from "../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const styles = (theme) => ({
  ...theme.spreadThis,
  taskBox: {
    borderRadius: 5,
    borderLeft: `2px solid ${theme.palette.primary.light}`,
    borderRight: `2px solid ${theme.palette.primary.light}`,
    width: "100%",
    margin: "4px 0px",
  },
  taskBoxDecline: {
    borderRadius: 5,
    borderLeft: `2px solid ${theme.palette.secondary.light}`,
    borderRight: `2px solid ${theme.palette.secondary.light}`,
    width: "100%",
    margin: "4px 0px",
  },
});
export class InvoiceShiftDisplay extends Component {
  render() {
    const {
      classes,
      date,
      dateData: { shifts, breakdown },
    } = this.props;

    return (
      <Box p={1}>
        <Typography variant="body2" style={{ fontWeight: 550 }}>
          {dayjs(date).format("ddd - MMM DD, YYYY")}
        </Typography>
        <div className={classes.titleLine} />
        <Typography component="div" variant="body2" style={{ fontWeight: 300 }}>
          Overtime Status:{" "}
          <Chip
            label={breakdown.isOt ? "Yes" : "No"}
            size="small"
            className={
              breakdown.dailyOtHours > 0
                ? classes.chipOrjOutlined
                : classes.chipGreenOutlined
            }
            style={{
              width: 50,
            }}
          />
        </Typography>

        {shifts.map((shift, index) => (
          <div key={index}>
            <Typography variant="body2">
              {dayjs(shift.startTime).format("h:mma")} -{" "}
              {dayjs(shift.endTime).format("h:mma")} •{" "}
              {numberWithCommas(
                dayjs(shift.endTime).diff(shift.startTime, "h", true)
              )}{" "}
              hrs
            </Typography>
            {shift.tasks.map((task, ind) => (
              <Box
                key={ind}
                p={1}
                className={
                  task.isDeclined ? classes.taskBoxDecline : classes.taskBox
                }
              >
                <Box display="flex" style={{ justifyContent: "space-between" }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ wordWrap: "break-word" }}
                  >
                    {task.title}
                  </Typography>
                  <Chip
                    label={task.isDeclined ? "Declined" : "Completed"}
                    size="small"
                    className={
                      task.isDeclined
                        ? classes.chipOrjOutlined
                        : classes.chipGreenOutlined
                    }
                    variant="outlined"
                    style={{ opacity: 0.5 }}
                  />
                </Box>
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                  style={{ fontWeight: 300, wordWrap: "break-word" }}
                >
                  {task.body}
                </Typography>
                {task.note && (
                  <Typography
                    component="div"
                    variant="caption"
                    color="textSecondary"
                    style={{
                      fontWeight: 300,
                      marginTop: 10,
                      wordWrap: "break-word",
                    }}
                  >
                    Note: {task.note}
                  </Typography>
                )}
              </Box>
            ))}
          </div>
        ))}
        <Divider style={{ marginTop: 8 }} />
        <Box
          style={{
            margin: "4px 0px",
            padding: "0px 10px",
          }}
        >
          <div className={classes.flexBoxSpaced}>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ fontWeight: 300 }}
            >
              ${numberWithCommas(breakdown.wage)}
              /hr x {numberWithCommas(breakdown.regularHours)} hrs
            </Typography>
            <Typography variant="caption" color="textSecondary">
              ${numberWithCommas(breakdown.dailyRegularIncome)}
            </Typography>
          </div>
          {breakdown.ot1Hours > 0 && (
            <div className={classes.flexBoxSpaced}>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ fontWeight: 300 }}
              >
                ${numberWithCommas(breakdown.wage * 1.5)}
                /hr x {numberWithCommas(breakdown.ot1Hours)} hrs
                {" (OT - 1.5x)"}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                ${numberWithCommas(breakdown.wage * 1.5 * breakdown.ot1Hours)}
              </Typography>
            </div>
          )}
          {breakdown.ot2Hours > 0 && (
            <div className={classes.flexBoxSpaced}>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ fontWeight: 300 }}
              >
                ${numberWithCommas(breakdown.wage * 2)}
                /hr x {numberWithCommas(breakdown.ot2Hours)} hrs
                {" (OT - 2x)"}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                ${numberWithCommas(breakdown.wage * 2 * breakdown.ot2Hours)}
              </Typography>
            </div>
          )}
          <div
            className={clsx(
              classes.flexBoxSpaced,
              classes.selectedGrayContainer
            )}
            style={{ padding: 0, marginTop: 4 }}
          >
            <Typography variant="body2" style={{ fontWeight: 550 }}>
              Daily Income
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 550 }}>
              ${numberWithCommas(breakdown.dailyOperatingIncome)}
            </Typography>
          </div>
        </Box>
        <Divider />
      </Box>
    );
  }
}

InvoiceShiftDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  dateData: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
};

export default withStyles(styles)(InvoiceShiftDisplay);
