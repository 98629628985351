import {
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  LOADING_UI_SPECIAL,
  SET_UPDATED,
  CLEAR_UPDATED,
  SET_FOOTER,
  UNSET_FOOTER,
  SET_INITIAL_LOAD,
  SET_ALERT,
  UNSET_ALERT,
  SET_PAGE,
  UNSET_PAGE,
} from "../types";

const initialState = {
  loading: false,
  loadingSpecial: false,
  errors: null,
  updateCheck: false,
  isFooter: true,
  initialLoad: false,
  alert: {
    isAlert: false,
    message: "",
    type: "",
    link: false,
    url: "",
    btnTxt: "",
  },
  page: "",
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case UNSET_FOOTER:
      return {
        ...state,
        isFooter: false,
      };
    case SET_FOOTER:
      return {
        ...state,
        isFooter: true,
      };
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        loadingSpecial: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
        loadingSpecial: false,
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    case LOADING_UI_SPECIAL:
      return {
        ...state,
        loadingSpecial: true,
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false,
        loadingSpecial: false,
      };
    case SET_UPDATED:
      return {
        ...state,
        updateCheck: true,
      };
    case CLEAR_UPDATED:
      return {
        ...state,
        updateCheck: false,
      };
    case SET_INITIAL_LOAD:
      return {
        ...state,
        initialLoad: true,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: {
          isAlert: true,
          ...action.payload,
          link: Boolean(action.payload.link) ? action.payload.link : false,
          url: Boolean(action.payload.url) ? action.payload.url : "",
          btnTxt: Boolean(action.payload.btnTxt) ? action.payload.btnTxt : "",
        },
      };
    case UNSET_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          isAlert: false,
        },
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case UNSET_PAGE:
      return {
        ...state,
        page: false,
      };
    default:
      return state;
  }
}
