import React, { Component } from "react";
import VisaLogo from "../../../assets/creditBrand/VisaLogo.svg";
import McVrt from "../../../assets/creditBrand/McVrt.svg";
import Amex from "../../../assets/creditBrand/Amex.svg";

export class BrandSelect extends Component {
  render() {
    const { brand } = this.props;

    let match = {
      mastercard: McVrt,
      visa: VisaLogo,
      amex: Amex,
    };

    return (
      <img
        src={match[brand.toLowerCase()]}
        alt={`${brand}`}
        style={{ width: 32, height: 20, marginRight: 5 }}
      />
    );
  }
}

export default BrandSelect;
