import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router";
import Invoice from "../../../components/pay/invoice/Invoice";
import History from "../../../components/pay/history/History";
import Payment from "./components/Payment/Payment";
import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class FamilyPay extends Component {
  state = { tabValue: 0 };

  componentDidMount() {
    this.setInitialTab();
  }

  setInitialTab() {
    const { pathname } = this.props.history.location;

    if (pathname.includes("history")) {
      this.setState({ tabValue: 1 });
    } else if (pathname.includes("detail")) {
      this.setState({ tabValue: 2 });
    } else {
      this.props.history.replace({
        pathname: "/mypay/invoice",
      });
    }
  }

  handleSetTabValue = (e, v) => {
    this.setState({ tabValue: v });
    this.props.history.replace({
      pathname:
        v === 0
          ? "/mypay/invoice"
          : v === 1
          ? "/mypay/history"
          : "/mypay/detail",
    });
  };

  sendToInvoice = () => {
    this.setState({ tabValue: 0 });
  };
  sendToHistory = () => {
    this.setState({ tabValue: 1 });
  };
  sendToDetails = () => {
    this.setState({ tabValue: 2 });
  };
  render() {
    const { credentials } = this.props;
    const { tabValue } = this.state;

    return (
      <Grid container style={{ padding: "0px 8px" }}>
        <Typography
          color="textSecondary"
          variant="h4"
          style={{ fontWeight: 300 }}
        >
          My Pay
        </Typography>
        <Grid item xs={12} style={{ marginTop: 60 }}>
          <Paper
            elevation={4}
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: "rgba(212,212,212,0.2)",
              borderTop: "1px solid rgb(212,212,212)",
              borderRight: "1px solid rgb(212,212,212)",
              borderLeft: "1px solid rgb(212,212,212)",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={this.handleSetTabValue}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Invoice" />
              <Tab label="Pay History" />
              <Tab label="Payment Methods" />
            </Tabs>
          </Paper>
          {tabValue === 0 ? (
            <Invoice credentials={credentials} />
          ) : tabValue === 1 ? (
            <History credentials={credentials} />
          ) : (
            <Payment credentials={credentials} />
          )}
        </Grid>
      </Grid>
    );
  }
}
FamilyPay.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};
export default compose(withRouter, withStyles(styles))(FamilyPay);
