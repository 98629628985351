import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

// Component imports
import {
  isEmpty,
  makeCapitalArrayToString,
  capitalizeFirstChar,
  splitAddressFull,
  formatAmount,
  allFalse,
} from "../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ReviewInviteDialog extends Component {
  state = { showReview: false };
  handleOpenShowReview = () => {
    this.setState({ showReview: true });
  };

  handleCloseShowReview = (event, reason) => {
    if (reason !== "backdropClick") this.setState({ showReview: false });
  };

  validateErrors = () => {
    this.props.validateErrors();
  };

  handleHire = () => {
    this.props.onHire();
  };
  render() {
    const {
      classes,
      jobs,
      errors,
      disableWhileLoad,
      clientFirstName,
      clientLastName,
      startDate,
      currJob,
      address,
      message,
      isVacationIncluded,
      propWage,
      estimatedHours,
      deactivateJobOnHire,
    } = this.props;
    const { showReview } = this.state;

    const jobData = jobs.find((job) => job.id === currJob);

    const isDisabled =
      !allFalse(errors) ||
      isEmpty(clientFirstName) ||
      isEmpty(clientLastName) ||
      isEmpty(startDate) ||
      estimatedHours < 1 ||
      isEmpty(currJob) ||
      isEmpty(message) ||
      !Boolean(address.addressFull) ||
      !Boolean(jobData);

    return (
      <React.Fragment>
        <Button
          color="primary"
          className={classes.txtTrButton}
          variant="contained"
          size="small"
          onClick={isDisabled ? this.validateErrors : this.handleOpenShowReview}
          disabled={disableWhileLoad}
        >
          REVIEW
        </Button>
        <Dialog
          open={showReview}
          onClose={this.handleCloseShowReview}
          maxWidth="sm"
          fullWidth
          disableEscapeKeyDown={disableWhileLoad}
        >
          <DialogTitle>Review Invite</DialogTitle>
          {!isDisabled && (
            <DialogContent>
              <div
                className={classes.grayContainerLight}
                style={{
                  marginBottom: 16,
                  padding: "0px 8px",
                  borderRadius: 25,
                }}
              >
                {disableWhileLoad && (
                  <div className={classes.layoverUpdate}>
                    <CircularProgress thickness={2} size={70} />
                  </div>
                )}

                <Typography style={{ fontWeight: 300 }}>Job Details</Typography>
              </div>
              <Card
                elevation={4}
                style={{ margin: "0px 8px", padding: 8, borderRadius: 0 }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Job ID
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography
                      variant="body2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {currJob.slice(0, 8).toUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Posted
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">
                      {dayjs(jobData.createdAt).format("MMM DD, YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Services
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">
                      {makeCapitalArrayToString(Object.keys(jobData.service))}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Conditions
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">
                      {makeCapitalArrayToString(
                        Object.keys(jobData.conditions)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
              <div
                className={classes.grayContainerLight}
                style={{
                  margin: "24px 0px 16px 0px",
                  padding: "0px 8px",
                  borderRadius: 25,
                }}
              >
                <Typography style={{ fontWeight: 300 }}>
                  Offer Details
                </Typography>
              </div>
              <Card
                elevation={4}
                style={{ margin: "0px 8px", padding: 8, borderRadius: 0 }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Client Name
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">
                      {capitalizeFirstChar(clientFirstName)}{" "}
                      {capitalizeFirstChar(clientLastName)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Start Date
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">
                      {dayjs(startDate).format("MMM DD, YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Address of Care
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">
                      {splitAddressFull(address.addressFull)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Total Wage
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">
                      {formatAmount(
                        isVacationIncluded
                          ? propWage * 100
                          : propWage * 1.04 * 100,
                        "cad"
                      )}
                      /hr
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Wage Breakdown
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">
                      {`${formatAmount(
                        isVacationIncluded
                          ? (propWage / 1.04) * 100
                          : propWage * 100,
                        "cad"
                      )}/hr base + ${formatAmount(
                        isVacationIncluded
                          ? (propWage - propWage / 1.04) * 100
                          : propWage * 0.04 * 100,
                        "cad"
                      )}/hr vacation (4%)`}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Est. Weekly Hours
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">{estimatedHours}</Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Deactivate Posting on Hire
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <div
                      className={classes.flexBoxCentered}
                      style={{ height: "100%", width: "100%" }}
                    >
                      <Chip
                        className={
                          deactivateJobOnHire
                            ? classes.chipGreenOutlined
                            : classes.chipBlueOutlined
                        }
                        size="small"
                        label={deactivateJobOnHire ? "Yes" : "No"}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "4px 0px" }} />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={4} sm={3}>
                    <Typography fontWeight="bold" variant="body2">
                      Message
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={8}>
                    <Typography variant="body2">{message}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </DialogContent>
          )}
          <DialogActions>
            <Button
              onClick={this.handleCloseShowReview}
              style={{ borderRadius: 25 }}
              size="small"
              color="primary"
              disabled={disableWhileLoad}
            >
              Back
            </Button>
            <Button
              onClick={this.handleHire}
              style={{ borderRadius: 25 }}
              size="small"
              color="primary"
              variant="contained"
              disabled={disableWhileLoad}
            >
              Hire
              {disableWhileLoad && (
                <CircularProgress
                  thickness={2}
                  size={25}
                  className={classes.progress}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ReviewInviteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReviewInviteDialog);
