import React, { Component } from "react";

import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import PropTypes from "prop-types";
import { capitalizeFirstChar } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const allColors = [
  "#142459",
  "#176BA0",
  "#19AADE",
  "#1AC9E6",
  "#1DE4BD",
  "#6DFDD2",
  "#C7F9EE",
];

export class InvoicePie extends Component {
  state = {
    labels: [],
    dataset: [],
    renamedLabels: [],
  };

  componentDidMount() {
    (async () => {
      await this.setLabel();
      await this.splitInvoicesByMemberID();
      await this.setNamedLabels();
    })();
  }

  componentDidUpdate(prevProps) {
    const { updateGraph } = this.props;

    if (updateGraph && !prevProps.updateGraph) {
      (async () => {
        await this.setLabel();
        await this.splitInvoicesByMemberID();
        await this.setNamedLabels();
      })();
    }
  }

  setLabel() {
    const { invoices } = this.props;

    let memberIds = {};
    invoices.forEach((invoice) => {
      const {
        memberInfo: { memberId },
      } = invoice;

      memberIds[memberId] = true;
    });

    this.setState({ labels: Object.keys(memberIds) });
  }

  setNamedLabels() {
    const { invoices, userType } = this.props;
    const { labels } = this.state;

    let renamedLabels = [];
    labels.forEach((id) => {
      let pos = invoices.findIndex(
        (invoice) => invoice.memberInfo.memberId === id
      );

      const {
        caregiverInfo: { firstName, lastName },
        familyInfo: { clientFirstName, clientLastName },
      } = invoices[pos];

      let label = "";
      if (userType === "caregiver") {
        label = `${capitalizeFirstChar(clientFirstName)} ${capitalizeFirstChar(
          clientLastName
        )}`;
      } else if (userType === "family") {
        label = `${capitalizeFirstChar(firstName)} ${capitalizeFirstChar(
          lastName
        )}`;
      }

      renamedLabels.push(label);
    });

    this.setState({ renamedLabels });
  }

  splitInvoicesByMemberID() {
    const { invoices, userType } = this.props;
    const { labels } = this.state;

    // Declare container for main data set
    let dataset = [];

    labels.forEach((id, index) => {
      // Get array of invoices per unique memberId
      let thisMemberIdInvoices = invoices.filter(
        (invoice) => invoice.memberInfo.memberId === id
      );

      const {
        netIncome,
        payableBreakdown: { totalPayable },
      } = thisMemberIdInvoices[0].invoiceBreakdown;

      // Get sum of income/cost per unique memberId
      let sumInvoices = 0;
      thisMemberIdInvoices.forEach((invoice) => {
        if (userType === "caregiver") {
          sumInvoices += netIncome;
        } else if (userType === "family") {
          sumInvoices += totalPayable;
        }
      });

      dataset.push(Math.round(sumInvoices * 100) / 100);
    });
    this.setState({ dataset });
  }

  render() {
    const { dataset, renamedLabels } = this.state;
    const { userType, classes, yearly } = this.props;

    const data = {
      labels: renamedLabels,
      datasets: [
        {
          label: userType === "caregiver" ? "Net Income" : "Total Costs",
          data: dataset,
          backgroundColor: allColors,
          borderColor: allColors,
          borderWidth: 1,
          datalabels: {
            anchor: "end",
          },
        },
      ],
    };

    const options = {
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 15,
          bottom: 15,
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "right",
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              return `${context[0].label}`;
            },
            label: function (context) {
              let label = context.dataset.label || "";
              let value = context.raw;

              if (label) {
                label += ": ";
              }
              if (value !== null) {
                label += new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(value);
              }
              return label;
            },
            footer: function (context) {
              const total = context.reduce((a, b) => a + b.raw, 0);
              return `TOTAL: ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(total)}`;
            },
          },
        },
        datalabels: {
          backgroundColor: function (context) {
            return context.dataset.backgroundColor;
          },
          borderColor: "white",
          borderRadius: 25,
          borderWidth: 2,
          color: "white",
          display: function (context) {
            var dataset = context.dataset;
            var count = dataset.data.length;
            var value = dataset.data[context.dataIndex];
            return value > count * 1.5;
          },
          font: {
            weight: "bold",
          },
          padding: 6,
          formatter: function (value) {
            return "$" + value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
        aspectRatio: 4 / 3,
        cutoutPercentage: 32,
        layout: {
          padding: 32,
        },
        elements: {
          line: {
            fill: false,
          },
          point: {
            hoverRadius: 7,
            radius: 5,
          },
        },
      },
    };

    return (
      <div>
        <Typography align="center" color="textSecondary" variant="h6">
          {userType === "caregiver"
            ? `${yearly ? "Annual" : "Monthly"} Net Income`
            : `${yearly ? "Annual" : "Monthly"} Cost`}
        </Typography>
        <Doughnut data={data} plugins={[ChartDataLabels]} options={options} />
        <Typography
          align="center"
          color="textSecondary"
          className={classes.selectedGrayContainer}
          style={{ fontWeight: 550, marginTop: 10 }}
        >
          Total $
          {dataset
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
        </Typography>
      </div>
    );
  }
}

InvoicePie.propTypes = {
  classes: PropTypes.object.isRequired,
  invoices: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
  updateGraph: PropTypes.bool,
  yearly: PropTypes.bool.isRequired,
};

export default withStyles(styles)(InvoicePie);
