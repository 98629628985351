import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Component imports
import OutlinedProfilePic from "../../../../components/layout/OutlinedProfilePic";
import { capitalizeFirstChar } from "../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class IntroPage extends Component {
  handleContinue = () => {
    this.props.handleIntro();
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { userData, classes } = this.props;

    return (
      <Paper
        elevation={4}
        className={clsx(
          classes.smallPaperContainer,
          classes.darkGrayBorderOnly
        )}
        style={{ padding: 16 }}
      >
        <Fade in={true}>
          <div>
            <Typography align="center" variant="h5">
              Congratulations on finding your Poyo caregiver!
            </Typography>
            <Typography
              align="center"
              variant="h6"
              color="primary"
              style={{ marginTop: 16 }}
            >
              Ready to hire {capitalizeFirstChar(userData.firstName)}?
            </Typography>
            <div className={classes.flexBoxCentered}>
              <OutlinedProfilePic imgSrc={userData.imgUrlThumb} />
            </div>
            <Typography
              align="center"
              color="textSecondary"
              style={{ marginTop: 24, fontWeight: 300 }}
            >
              Let us help you through the process in a few simple steps.
            </Typography>
            <Grid container justifyContent="center" style={{ marginTop: 16 }}>
              <Button
                onClick={this.goBack}
                color="primary"
                className={classes.txtTrButton}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className={classes.txtTrButton}
                variant="contained"
                onClick={this.handleContinue}
              >
                Continue
              </Button>
            </Grid>
          </div>
        </Fade>
      </Paper>
    );
  }
}

IntroPage.propTypes = {
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  handleIntro: PropTypes.func.isRequired,
};

export default withStyles(styles)(IntroPage);
