import React, { Component } from "react";
import PropTypes from "prop-types";

// Component Imports
import LoadingEllipsis from "../../../../components/layout/LoadingEllipsis";
import Icons from "../../../../assets/icons/iconsMain";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import SvgIcon from "@mui/material/SvgIcon";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const StyledButton = withStyles({
  root: {
    textTransform: "none",
    height: "100%",
  },
})(Button);

export class ServiceMarkup extends Component {
  render() {
    const {
      classes,
      isLoadedSignupMateraials,
      serviceObj,
      error,
      serviceArray,
    } = this.props;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="primary"
            style={{ margin: "10px 0px", fontWeight: 300 }}
            className={classes.typography}
          >
            Service
          </Typography>
          <Box className={classes.sectionBoxLeftOutline}>
            <Typography
              variant="body2"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              Which services can you provide to your clients. Select from the
              options below.
            </Typography>

            {error && (
              <Typography
                variant="body2"
                style={{ color: "#f44336", fontWeight: 300 }}
              >
                {error}
              </Typography>
            )}

            <Grid
              container
              justifyContent="center"
              spacing={1}
              alignItems="stretch"
              style={{ marginTop: 10 }}
            >
              {isLoadedSignupMateraials ? (
                Object.entries(serviceObj)
                  .sort()
                  .map((service) => (
                    <Grid item xs={8} sm={5} md={5} key={service}>
                      <StyledButton
                        size="large"
                        name={service[0]}
                        onClick={(e) => this.props.handleService(e)}
                        fullWidth
                        className={
                          Boolean(error)
                            ? serviceArray.includes(service[0])
                              ? classes.errorSelectedToggleButton
                              : classes.errorUnselectedToggleButton
                            : serviceArray.includes(service[0])
                            ? classes.selectedToggleButton
                            : classes.unselectedToggleButton
                        }
                        startIcon={
                          <SvgIcon>
                            <path d={Icons[service[0]]} />
                          </SvgIcon>
                        }
                      >
                        <Typography
                          variant="body2"
                          color={
                            !Boolean(error) &&
                            !serviceArray.includes(service[0])
                              ? "textSecondary"
                              : "inherit"
                          }
                          style={{
                            width: "100%",
                          }}
                          noWrap
                        >
                          {service[1]}
                        </Typography>
                      </StyledButton>
                    </Grid>
                  ))
              ) : (
                <div style={{ marginTop: 10 }}>
                  <CircularProgress thickness={2} size={70} />
                  <LoadingEllipsis
                    style={{ marginTop: 10 }}
                    prefix="Loading Signup Materials"
                  />
                </div>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          style={{ width: "100%", marginTop: 15 }}
        >
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={() => this.props.handleCompleteService()}
              style={{ width: 100 }}
            >
              Next
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ServiceMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoadedSignupMateraials: PropTypes.bool.isRequired,
  serviceObj: PropTypes.object.isRequired,
  error: PropTypes.string,
};

export default withStyles(styles)(ServiceMarkup);
