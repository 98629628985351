import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import clsx from "clsx";
import { Link } from "react-router-dom";

// Redux imports
import { connect } from "react-redux";

// Component imports
import {
  capitalizeFirstChar,
  numberWithCommas,
} from "../../../util/utilFunctions";
import OutlinedProfilePic from "../../layout/OutlinedProfilePic";
import EmergencyContactDisplay from "./EmergencyContactDisplay";
import MemberWarningDialog from "../../buttons/member/MemberWarningDialogButton";
import Careplan from "./Careplan";
import PinnedNote from "./PinnedNote";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// Icon imports
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HelpIcon from "@mui/icons-material/Help";
import ReviewsIcon from "@mui/icons-material/Reviews";
import StarIcon from "@mui/icons-material/Star";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class MemberDisplay extends React.Component {
  state = { showEmergencyContact: true };
  onUpdateMember = () => {
    this.props.onUpdateMember();
  };

  handleEmergencyContact = () => {
    this.setState({ showEmergencyContact: !this.state.showEmergencyContact });
  };
  render() {
    const {
      classes,
      member,
      member: { familyInfo, caregiverInfo, numShifts, review },
      credentials,
      members,
    } = this.props;

    const { showEmergencyContact } = this.state;

    const isFamily = credentials.userType === "family";

    return (
      <Box style={{ width: "100%", margin: "8px 0px" }}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <OutlinedProfilePic
              imgSrc={
                isFamily ? caregiverInfo.imgUrlThumb : familyInfo.imgUrlThumb
              }
              size={55}
            />
          </Grid>
          <Grid item style={{ marginRight: 16 }}>
            <Typography>
              {isFamily
                ? `${capitalizeFirstChar(
                    caregiverInfo.firstName
                  )} ${capitalizeFirstChar(caregiverInfo.lastName)}`
                : `${capitalizeFirstChar(
                    familyInfo.clientFirstName
                  )} ${capitalizeFirstChar(familyInfo.clientLastName)}`}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {isFamily
                ? `Client: ${capitalizeFirstChar(
                    familyInfo.clientFirstName
                  )} ${capitalizeFirstChar(familyInfo.clientLastName)}`
                : `Family: ${capitalizeFirstChar(
                    familyInfo.firstName
                  )} ${capitalizeFirstChar(familyInfo.lastName)}`}
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex">
              <CalendarTodayIcon color="primary" />
              <Typography
                variant="body2"
                style={{ fontWeight: 300, marginLeft: 4 }}
              >
                Joined:{" "}
                <Typography variant="body2" component="span">
                  {dayjs(member.createdAt).format("MMM DD, YYYY")}
                </Typography>
              </Typography>
            </Box>

            <Box display="flex">
              <MonetizationOnIcon color="primary" />
              <Typography
                variant="body2"
                style={{ fontWeight: 300, marginLeft: 4 }}
              >
                Current Wage:{" "}
                <Typography variant="body2" component="span">
                  ${numberWithCommas(member.wage)}/hr
                  <Tooltip
                    disableFocusListener
                    enterTouchDelay={0}
                    placement="top"
                    title={
                      <Typography variant="caption">
                        Includes wage of ${numberWithCommas(member.wage / 1.04)}
                        /hr and 4% vacation pay of $
                        {numberWithCommas(member.wage - member.wage / 1.04)}/hr
                        in lieu of annual vacation time.
                      </Typography>
                    }
                  >
                    <HelpIcon
                      color="action"
                      style={{ fontSize: 14, opacity: 0.7 }}
                    />
                  </Tooltip>
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          p={1}
          m={1}
          className={clsx(classes.flexBoxSpaced, classes.selectedGrayContainer)}
        >
          <div className={classes.flexBox}>
            <Typography style={{ marginRight: 4 }}>Review</Typography>
            <Typography
              sx={{
                color: review.isReviewed
                  ? "#ffb300"
                  : numShifts >= 3
                  ? "primary.main"
                  : "info.dark",
              }}
              fontWeight={300}
            >
              {"("}
            </Typography>
            {review.isReviewed ? (
              <div className={classes.flexBox} style={{ color: "#ffb300" }}>
                <Typography style={{ fontWeight: 400 }}>
                  {review.score}
                </Typography>
                <StarIcon />
              </div>
            ) : numShifts >= 3 ? (
              <Typography color="primary">Eligible</Typography>
            ) : (
              <Typography sx={{ color: "info.dark" }}>
                Not Eligible
                <Tooltip
                  disableFocusListener
                  enterTouchDelay={0}
                  placement="top"
                  arrow
                  title={
                    <Typography variant="caption">
                      At least 3 shifts must be completed prior to a review
                      being submitted.
                    </Typography>
                  }
                >
                  <HelpIcon
                    // color="inher"
                    style={{ fontSize: 14, opacity: 0.7 }}
                  />
                </Tooltip>
              </Typography>
            )}
            <Typography
              sx={{
                color: review.isReviewed
                  ? "#ffb300"
                  : numShifts >= 3
                  ? "primary.main"
                  : "info.dark",
              }}
              fontWeight={300}
            >
              {")"}
            </Typography>
          </div>

          <Button
            component={Link}
            to={`/reviews/${member.id}`}
            className={classes.txtTrButton}
            startIcon={<ReviewsIcon />}
            disabled={numShifts < 3}
            style={{ margin: 0 }}
          >
            {isFamily ? (review.isReviewed ? "Edit" : "Submit") : "View"} Review
          </Button>
        </Box>

        <Box style={{ padding: "8px 0px" }}>
          <PinnedNote
            member={member}
            onUpdate={this.onUpdateMember}
            index={members.findIndex((_member) => _member.id === member.id)}
            isFamily={isFamily}
          />
        </Box>

        <Box className={classes.flexBoxSpaced} style={{ padding: "8px 0px" }}>
          {familyInfo.hasOwnProperty("emergencyContacts") ? (
            <React.Fragment>
              <Button
                startIcon={
                  showEmergencyContact ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                size="small"
                onClick={this.handleEmergencyContact}
                style={{ borderRadius: 25, flex: 1, textTransform: "none" }}
              >
                <Typography style={{ fontWeight: 300 }}>
                  Emergency Contacts
                </Typography>
              </Button>
              {isFamily && (
                <MemberWarningDialog
                  member={member}
                  index={members.findIndex(
                    (_member) => _member.id === member.id
                  )}
                  defaultValue={familyInfo.emergencyContacts}
                  onUpdate={this.onUpdateMember}
                  small
                />
              )}
            </React.Fragment>
          ) : (
            <Typography align="center" style={{ fontWeight: 300, flex: 1 }}>
              Emergency Contacts
            </Typography>
          )}
        </Box>

        {familyInfo.hasOwnProperty("emergencyContacts") ? (
          <Collapse in={showEmergencyContact}>
            <Grid container className={classes.sectionBoxLeftOutline}>
              {familyInfo.emergencyContacts.map((emergencyContact, ind) => (
                <Grid item xs={12} md={6} key={ind}>
                  <EmergencyContactDisplay
                    emergencyContact={emergencyContact}
                    ind={ind}
                    small
                  />
                </Grid>
              ))}
            </Grid>
          </Collapse>
        ) : (
          <Box className={classes.sectionBoxLeftOutlineRed}>
            {isFamily ? (
              <MemberWarningDialog
                member={member}
                index={members.findIndex((_member) => _member.id === member.id)}
                fullButton
                onUpdate={this.onUpdateMember}
                isFamily={isFamily}
              />
            ) : (
              <Typography color="error" variant="body2" align="center">
                Emergency Contacts not on file
              </Typography>
            )}
          </Box>
        )}
        <Box style={{ padding: "8px 0px" }}>
          <Careplan
            member={member}
            onUpdate={this.onUpdateMember}
            index={members.findIndex((_member) => _member.id === member.id)}
            isFamily={isFamily}
          />
        </Box>
      </Box>
    );
  }
}

MemberDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  onUpdateMember: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
  members: state.data.members,
});

export default connect(mapStateToProps)(withStyles(styles)(MemberDisplay));
