import React, { Component } from "react";
import PropTypes from "prop-types";

// Component Imports
import TooltipButton from "../../../components/inputs/TooltipButton";
import SignupCaregiverForm from "./components/SignupCaregiverForm";

// Redux imports
import { connect } from "react-redux";
import { loginUser } from "../../../redux/actions/userActions";
import { withLoginHOC } from "../../../util/withFBHooks";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Icons imports
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
  ...theme.spreadThis,

  signupDivColor: {
    height: "100%",
    backgroundColor: theme.palette.primary.light,
  },
});

export class SignupCaregiverDialog extends Component {
  state = {
    open: false,
  };

  // Dialog Methods

  handleOpen = () => {
    this.setState({ ...this.state, open: true });
  };

  handleClose = (event, reason) => {
    if (reason !== "backdropClick")
      this.setState({
        open: false,
      });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <>
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          fullWidth
          onClick={this.handleOpen}
          style={{ margin: "8px 0px" }}
        >
          START APPLYING NOW
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={"md"}
          disableEscapeKeyDown
          PaperProps={{ style: { borderRadius: 25 } }}
        >
          <Grid container alignItems="stretch">
            <Grid
              item
              component={Box}
              display={{ sm: "none", md: "block" }}
              md={3}
              className="p-bg-l"
            />
            <Grid item xs={12} md={9}>
              <Box
                display="flex"
                justifyContent="flex-end"
                className={classes.headerBarRounded}
              >
                <TooltipButton
                  tip="Cancel"
                  placement="top"
                  size="small"
                  onClick={this.handleClose}
                  tipClassName={classes.closeButton}
                >
                  <CloseIcon color="inherit" />
                </TooltipButton>
              </Box>
              <Box p={2} className={classes.form}>
                <Typography variant="h6" color="primary">
                  Join Poyo
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: 300, marginTop: 16 }}
                >
                  Become a caregiver with Poyo Health today to enjoy better pay,
                  more flexible hours, and personalized matching of your skills
                  to your clients.
                </Typography>
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 16 }}
                >
                  <Grid item xs={11} sm={7} md={6}>
                    <SignupCaregiverForm />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      </>
    );
  }
}

SignupCaregiverDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapActionToProps = {
  loginUser,
};

export default connect(
  null,
  mapActionToProps
)(withLoginHOC(withStyles(styles)(SignupCaregiverDialog)));
