import React, { Component, Fragment } from "react";
import axios from "axios";
import PropTypes from "prop-types";

// Component imports
import { isSameArray, lowerFirstChar } from "../../../../util/utilFunctions";
import TooltipButton from "../../../../components/inputs/TooltipButton";
import ObjSelectButton from "../../../../components/inputs/ObjSelectButton";
import ChipArrayDisplay from "../../../../components/profile/ChipArrayDisplay";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/uiActions";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// Icons imports
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class JobServiceConditionContainer extends Component {
  state = {
    disableWhileLoad: false,
    errors: {},
    editJobType: false,
    editService: false,
    editConditions: false,
    jobType: {},
    service: {},
  };

  // UI methods

  setLoading = () => {
    this.setState({ disableWhileLoad: !this.state.disableWhileLoad });
  };

  handleSetEdit = (e) => {
    const editParam = e.currentTarget.name;
    const errorName = lowerFirstChar(editParam.split("edit")[1]);

    this.setState({
      [editParam]: !this.state[editParam],
      errors: { ...this.state.errors, [errorName]: null },
    });
  };

  // Action Methods
  handleUpdateJobType = (value) => {
    const { jobType } = this.props.credentials;
    if (Object.keys(value).length < 1) {
      this.setState({
        errors: {
          ...this.state.errors,
          jobType: "Please select at least 1 job type you are interested in.",
        },
      });
    } else if (isSameArray(Object.keys(jobType), Object.keys(value))) {
      this.setState({ editJobType: false });
    } else {
      this.editUserProfile({ jobType: value }, "editJobType");
    }
  };

  handleUpdateService = (value) => {
    const { service } = this.props.credentials;
    if (Object.keys(value).length < 1) {
      this.setState({
        errors: {
          ...this.state.errors,
          service: "Please select at least 1 service you can provide.",
        },
      });
    } else if (isSameArray(Object.keys(service), Object.keys(value))) {
      this.setState({ editService: false });
    } else {
      this.editUserProfile({ service: value }, "editService");
    }
  };

  handleUpdateConditions = (value) => {
    const { conditions } = this.props.credentials;
    if (Object.keys(value).length < 1) {
      this.setState({
        errors: {
          ...this.state.errors,
          conditions:
            "Please select at least 1 condition you are knowledgeable in.",
        },
      });
    } else if (isSameArray(Object.keys(conditions), Object.keys(value))) {
      this.setState({ editConditions: false });
    } else {
      this.editUserProfile({ conditions: value }, "editConditions");
    }
  };

  editUserProfile = async (userDetails, endEdit) => {
    await this.setLoading();

    try {
      await axios.post("/user", userDetails);
      this.props.setAlert({
        message: "Profile successfully updated",
        type: "success",
      });
      this.setState({ [endEdit]: false });
    } catch (err) {
      this.setState({ errors: err.response.data });
    } finally {
      this.setLoading();
    }
  };

  render() {
    const { classes, credentials, signupMaterials, readOnly } = this.props;
    const {
      disableWhileLoad,
      errors,
      editJobType,
      editService,
      editConditions,
    } = this.state;
    return (
      <div style={{ textAlign: "left" }}>
        <Box className={classes.flexBoxSpaced}>
          <Typography
            variant="h6"
            align="left"
            className={classes.typography}
            style={{ fontWeight: 300 }}
          >
            Jobs I'm Interested in
          </Typography>

          {!readOnly && (
            <TooltipButton
              tip={!editJobType ? "Edit Job Type" : "Close"}
              name="editJobType"
              onClick={this.handleSetEdit}
              size="small"
            >
              {!editJobType ? (
                <EditOutlinedIcon color="primary" />
              ) : (
                <CloseIcon color="primary" />
              )}
            </TooltipButton>
          )}
        </Box>
        <Box style={{ marginTop: 16 }}>
          {!editJobType ? (
            <ChipArrayDisplay
              icon={<AccessTimeIcon />}
              dataArray={Object.keys(credentials.jobType)}
              className={classes.chipBlue}
            />
          ) : (
            <Fragment>
              {Boolean(errors.jobType) && (
                <Typography
                  variant="body2"
                  align="center"
                  color="error"
                  style={{ marginBottom: 8 }}
                >
                  {errors.jobType}
                </Typography>
              )}
              <ObjSelectButton
                btnColor="primary"
                optionsAll={signupMaterials.jobType}
                optionsCntr={credentials.jobType}
                error={errors.jobType}
                loading={disableWhileLoad}
                startIcon={<AccessTimeIcon />}
                onSaveButtonClick={this.handleUpdateJobType}
                showSave
              />
            </Fragment>
          )}
          <Divider orientation="horizontal" style={{ margin: "8px 0px" }} />
        </Box>

        <Box className={classes.flexBoxSpaced}>
          <Typography
            variant="h6"
            align="left"
            className={classes.typography}
            style={{ fontWeight: 300 }}
          >
            My Services
          </Typography>
          {!readOnly && (
            <TooltipButton
              tip={!editService ? "Edit Services" : "Close"}
              name="editService"
              onClick={this.handleSetEdit}
              size="small"
            >
              {!editService ? (
                <EditOutlinedIcon color="primary" />
              ) : (
                <CloseIcon color="primary" />
              )}
            </TooltipButton>
          )}
        </Box>
        <Box style={{ marginTop: 16 }}>
          {!editService ? (
            <ChipArrayDisplay
              dataArray={Object.keys(credentials.service).sort()}
              className={classes.chipBlue}
              serviceIcons
            />
          ) : (
            <Fragment>
              {Boolean(errors.service) && (
                <Typography
                  variant="body2"
                  align="center"
                  color="error"
                  style={{ marginBottom: 8 }}
                >
                  {errors.service}
                </Typography>
              )}
              <ObjSelectButton
                btnColor="primary"
                optionsAll={signupMaterials.service}
                optionsCntr={credentials.service}
                error={errors.service}
                loading={disableWhileLoad}
                startIcon={<AssignmentIcon />}
                onSaveButtonClick={this.handleUpdateService}
                showSave
              />
            </Fragment>
          )}
        </Box>
        <Divider style={{ width: "100%", margin: "8px 0px" }} />
        <Box className={classes.flexBoxSpaced}>
          <Typography
            variant="h6"
            align="left"
            className={classes.typography}
            style={{ fontWeight: 300 }}
          >
            My Trained Conditions
          </Typography>
          {!readOnly && (
            <TooltipButton
              tip={!editConditions ? "Edit Conditions" : "Close"}
              name="editConditions"
              onClick={this.handleSetEdit}
              size="small"
            >
              {!editConditions ? (
                <EditOutlinedIcon color="primary" />
              ) : (
                <CloseIcon color="primary" />
              )}
            </TooltipButton>
          )}
        </Box>
        <Box style={{ marginTop: 16 }}>
          {!editConditions ? (
            <ChipArrayDisplay
              icon={<LocalHospitalIcon />}
              dataArray={Object.keys(credentials.conditions).sort()}
              className={classes.chipBlue}
            />
          ) : (
            <Fragment>
              {Boolean(errors.conditions) && (
                <Typography
                  variant="body2"
                  align="center"
                  color="error"
                  style={{ marginBottom: 8 }}
                >
                  {errors.conditions}
                </Typography>
              )}
              <ObjSelectButton
                btnColor="primary"
                optionsAll={signupMaterials.conditions}
                optionsCntr={credentials.conditions}
                error={errors.conditions}
                loading={disableWhileLoad}
                startIcon={<LocalHospitalIcon />}
                onSaveButtonClick={this.handleUpdateConditions}
                showSave
              />
            </Fragment>
          )}
        </Box>
      </div>
    );
  }
}

JobServiceConditionContainer.propTypes = {
  setAlert: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  signupMaterials: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default connect(null, { setAlert })(
  withStyles(styles)(JobServiceConditionContainer)
);
