import React from "react";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import HeartBrokenOutlinedIcon from "@mui/icons-material/HeartBrokenOutlined";

function GenericNotFound() {
  return (
    <div className="page-wrapper" style={{ alignSelf: "stretch" }}>
      <div className="full-page-container top-drop" style={{ flex: 1 }}>
        <div className="flexbox-centered-column" style={{ minHeight: 500 }}>
          <HeartBrokenOutlinedIcon style={{ fontSize: "4rem" }} />
          <Typography variant="h4" className="txt-bold">
            Oops! We've lost this page.
          </Typography>
          <Typography variant="h6" className="description-txt">
            Seems like we're a little lost. Click{" "}
            <Typography component={Link} to="/" variant="h6" color="primary">
              here
            </Typography>{" "}
            to go home.
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default GenericNotFound;
