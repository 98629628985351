import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

import Typography from "@mui/material/Typography";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",

      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
function StripeCardSection({ setError, error }) {
  return (
    <label>
      <Typography
        variant="body2"
        align="center"
        style={{
          backgroundColor: "rgba(212,212,212,0.4)",
          borderRadius: 25,
          marginBottom: 14,
          padding: "4px 0px",
        }}
      >
        Card Details
      </Typography>
      <div
        style={{
          boxShadow: !error
            ? "0 0 0 0.2rem rgba(34,137,141,.25)"
            : "0 0 0 0.2rem rgba(234,51,48,0.50)",
          backgroundColor: error ? "rgba(245,221,219,0.5)" : "transparent",
          padding: 8,
          border: "1px solid #80bdff",
          borderRadius: 4,
          // boxShadow: "0 0 0 0.2rem rgba(34,137,141,.25)",
          zIndex: 1000,
        }}
      >
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onChange={({ error }) => {
            if (error) {
              setError(error.message);
            } else {
              setError(null);
            }
          }}
        />
      </div>
    </label>
  );
}
export default StripeCardSection;
