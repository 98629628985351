import React, { Fragment } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import StripeCardSelection from "./StripeCardSelection";

import {
  allFalse,
  allFalseExcept,
  capitalizeFirstChar,
  isEmail,
  isEmpty,
} from "../../../util/utilFunctions";
import { prov } from "../../../util/utilConst";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";

function CSSBox(props) {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "8px 16px ",
      }}
      {...props}
    />
  );
}

class CheckoutForm extends React.Component {
  state = {
    error: null,
    disableButton: false,
    firstName: "",
    lastName: "",
    email: "",
    line1: "",
    city: "",
    state: "",
    country: "",
    errors: {
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorEmailInvalid: false,
      errorLine1: false,
      errorLine2: false,
      errorCity: false,
      errorState: false,
      errorCountry: false,
    },
  };

  componentDidMount() {
    const {
      myInfo: { email },
    } = this.props;

    let _email = email;
    this.setState({ email: _email });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  handleSetError = async (v) => {
    await this.setStateAsync({ error: v });
  };

  handleInputBillingDetails = (e) => {
    let name = e.target.name;

    let errorName = `error${capitalizeFirstChar(name)}`;
    let value = e.target.value;

    if (!isEmpty(this.state[name]) && isEmpty(value)) {
      this.setState({
        [name]: value,
        errors: { ...this.state.errors, [errorName]: true },
      });
    } else {
      this.setState({
        [name]: value,
        errors: { ...this.state.errors, [errorName]: false },
      });
    }
  };

  handleInputEmail = async (e) => {
    const { errorEmailInvalid } = this.state.errors;
    let value = e.target.value;

    if (!isEmpty(this.state.email) && isEmpty(value)) {
      await this.setStateAsync({
        email: value,
        errors: {
          ...this.state.errors,
          errorEmail: true,
        },
      });
    } else {
      await this.setStateAsync({
        email: value,
        errors: {
          ...this.state.errors,
          errorEmail: false,
        },
      });
    }

    if (errorEmailInvalid && isEmail(value)) {
      await this.setStateAsync({
        errors: {
          ...this.state.errors,
          errorEmailInvalid: false,
        },
      });
    }
  };

  handleBillingState = (e) => {
    this.setState({
      state: e.target.value,
      errors: { ...this.state.errors, errorState: false },
    });
  };

  handleBillingCountry = (e) => {
    this.setState({
      country: e.target.value,
      errors: { ...this.state.errors, errorCountry: false },
    });
  };

  handleBillingError = async () => {
    const { firstName, lastName, line1, email, city, state, country } =
      this.state;

    let errors = {
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorEmailInvalid: false,
      errorLine1: false,
      errorCity: false,
      errorState: false,
      errorCountry: false,
    };

    await this.setStateAsync({ errors: errors });

    if (isEmpty(firstName)) errors.errorFirstName = true;
    if (isEmpty(lastName)) errors.errorLastName = true;
    if (isEmpty(email)) errors.errorEmail = true;
    if (!isEmail(email)) errors.errorEmailInvalid = true;
    if (isEmpty(line1)) errors.errorLine1 = true;
    if (isEmpty(city)) errors.errorCity = true;
    if (isEmpty(state)) errors.errorState = true;
    if (isEmpty(country)) errors.errorCountry = true;

    return {
      isPassed: allFalse(errors),
      errors,
    };
  };

  handleStripe = async () => {
    const { firstName, lastName, line1, city, state, country, email } =
      this.state;
    const { stripe, elements, myInfo } = this.props;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { setupIntent, error } = await stripe.confirmCardSetup(
      myInfo.setup_secret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${firstName.trim().toUpperCase()} ${lastName
              .trim()
              .toUpperCase()}`,
            email: email.trim(),
            address: {
              city: city.trim(),
              country: country.trim(),
              line1: line1.trim(),
              state: state.trim(),
            },
          },
        },
      }
    );

    if (error) {
      // Show error to your customer.
      this.setState({ error: error.message, disableButton: false });
    } else {
      await this.setStateAsync({ error: null });
      await axios.post("/payment/add/payment-method", {
        id: setupIntent.payment_method,
      });
      await this.setStateAsync({ disableButton: false });
      this.props.completePaymentMethod();
    }
  };

  handleSubmit = async () => {
    await this.setStateAsync({ disableButton: true });
    const { isPassed, errors } = await this.handleBillingError();

    if (!isPassed) {
      this.setState({ errors: errors, disableButton: false });
    } else {
      this.handleStripe();
    }
  };

  render() {
    const {
      error,
      disableButton,
      firstName,
      lastName,
      email,

      line1,
      city,
      state,
      country,
      errors,
      errors: {
        errorFirstName,
        errorLastName,
        errorEmail,
        errorEmailInvalid,
        errorLine1,
        errorCity,
        errorState,
        errorCountry,
      },
    } = this.state;
    return (
      <Fragment>
        <StripeCardSelection
          error={error ? true : false}
          setError={this.handleSetError}
        />
        <Typography
          component="div"
          variant="body2"
          align="center"
          color="error"
          style={{ width: "100%", marginTop: 5 }}
        >
          {error && error}
        </Typography>
        <div style={{ marginTop: 10 }}>
          <Typography
            variant="body2"
            align="center"
            style={{
              backgroundColor: "rgba(212,212,212,0.4)",
              borderRadius: 25,
              margin: "14px 0px",
              padding: "4px 0px",
            }}
          >
            Billing Info
          </Typography>
          {!allFalse(errors) && (
            <div
              style={{
                margin: 5,
              }}
            >
              {!allFalseExcept(errors, ["errorEmailInvalid"]) && (
                <Typography
                  component="div"
                  variant="caption"
                  align="center"
                  color="error"
                  style={{ width: "100%" }}
                >
                  Missing Billing Info
                </Typography>
              )}
              {errorEmailInvalid && (
                <Typography
                  component="div"
                  variant="caption"
                  align="center"
                  color="error"
                  style={{ width: "100%" }}
                >
                  Invalid Email
                </Typography>
              )}
            </div>
          )}
          <Paper elevation={4} component="div" style={{ width: "100%" }}>
            <CSSBox>
              <Typography
                color={!errorFirstName ? "textSecondary" : "error"}
                style={{ width: 100 }}
              >
                First Name
              </Typography>
              <InputBase
                name="firstName"
                value={firstName}
                onChange={this.handleInputBillingDetails}
                style={{
                  flex: 1,
                  backgroundColor: errorFirstName
                    ? "rgba(245,221,219,0.5)"
                    : "transparent",
                }}
              />
            </CSSBox>
            <Divider />
            <CSSBox>
              <Typography
                color={!errorLastName ? "textSecondary" : "error"}
                style={{ width: 100 }}
              >
                Last Name
              </Typography>
              <InputBase
                name="lastName"
                value={lastName}
                onChange={this.handleInputBillingDetails}
                style={{
                  flex: 1,
                  backgroundColor: errorLastName
                    ? "rgba(245,221,219,0.5)"
                    : "transparent",
                }}
              />
            </CSSBox>
            <Divider />

            <CSSBox>
              <Typography
                color={
                  errorEmail || errorEmailInvalid ? "error" : "textSecondary"
                }
                style={{ width: 100 }}
              >
                Email
              </Typography>
              <InputBase
                name="email"
                value={email}
                onChange={this.handleInputEmail}
                style={{
                  flex: 1,
                  backgroundColor:
                    errorEmail || errorEmailInvalid
                      ? "rgba(245,221,219,0.5)"
                      : "transparent",
                }}
              />
            </CSSBox>
            <Divider />

            <CSSBox>
              <Typography
                color={!errorLine1 ? "textSecondary" : "error"}
                style={{ width: 100 }}
              >
                Address
              </Typography>
              <InputBase
                name="line1"
                value={line1}
                onChange={this.handleInputBillingDetails}
                style={{
                  flex: 1,
                  backgroundColor: errorLine1
                    ? "rgba(245,221,219,0.5)"
                    : "transparent",
                }}
              />
            </CSSBox>
            <Divider />

            <CSSBox>
              <Typography
                color={!errorCity ? "textSecondary" : "error"}
                style={{ width: 100 }}
              >
                City
              </Typography>
              <InputBase
                name="city"
                value={city}
                onChange={this.handleInputBillingDetails}
                style={{
                  flex: 1,
                  backgroundColor: errorCity
                    ? "rgba(245,221,219,0.5)"
                    : "transparent",
                }}
              />
            </CSSBox>

            <Divider />
            <CSSBox>
              <Typography
                color={!errorState ? "textSecondary" : "error"}
                style={{ width: 100 }}
              >
                Province
              </Typography>
              <NativeSelect
                value={state}
                onChange={this.handleBillingState}
                input={<InputBase />}
                style={{
                  flex: 1,
                  backgroundColor: errorState
                    ? "rgba(245,221,219,0.5)"
                    : "transparent",
                }}
              >
                {country === "" ? (
                  <option value="">--Prov/State--</option>
                ) : (
                  prov[country].map((prov, ind) => {
                    if (prov !== "") {
                      return (
                        <option value={prov} key={ind}>
                          {prov}
                        </option>
                      );
                    } else {
                      return (
                        <option value={prov} key={ind}>
                          --Prov/State--
                        </option>
                      );
                    }
                  })
                )}
              </NativeSelect>
            </CSSBox>
            <Divider />
            <CSSBox>
              <Typography
                color={!errorCountry ? "textSecondary" : "error"}
                style={{ width: 100 }}
              >
                Country
              </Typography>
              <NativeSelect
                value={country}
                onChange={this.handleBillingCountry}
                input={<InputBase />}
                style={{
                  flex: 1,
                  backgroundColor: errorCountry
                    ? "rgba(245,221,219,0.5)"
                    : "transparent",
                }}
              >
                <option value="">--Country--</option>
                <option value="CA">Canada</option>
                <option value="US">US</option>
              </NativeSelect>
            </CSSBox>
          </Paper>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            disabled={!this.props.stripe || disableButton}
            fullWidth
            style={{
              borderRadius: 7,
              marginTop: 10,
              boxShadow:
                (!this.props.stripe || disableButton) &&
                "0 0 0 0.2rem rgba(34,137,141,0.50)",
            }}
          >
            Add Payment Method{" "}
            {(!this.props.stripe || disableButton) && (
              <CircularProgress
                thickness={2}
                size={30}
                style={{ position: "absolute", right: 0, marginRight: 10 }}
              />
            )}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default function InjectedCheckoutForm({
  myInfo,
  completePaymentMethod,
}) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          myInfo={myInfo}
          completePaymentMethod={completePaymentMethod}
        />
      )}
    </ElementsConsumer>
  );
}
