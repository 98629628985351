import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class InitialLoadScreen extends Component {
  componentDidMount() {
    this.unsetInitialLoad();
  }
  unsetInitialLoad = () => {
    try {
      axios.get("/user/initial_load/off");
    } catch (err) {
      console.error(err.response);
      return;
    }
  };
  onClickDashboard = () => {
    this.props.onClickDashboard();
  };
  handlePosting = () => {
    this.props.history.push("/profile/jobs");
  };
  render() {
    const { classes } = this.props;

    return (
      <Box
        p={3}
        className={classes.flexBoxCentered}
        style={{ paddingTop: 100 }}
      >
        <Box>
          <Grow in timeout={500}>
            <Typography align="center" variant="h4" color="primary">
              Welcome to Poyo!
            </Typography>
          </Grow>

          <Grow in timeout={500} style={{ transitionDelay: "250ms" }}>
            <Paper
              elevation={4}
              className={clsx(
                classes.paperContainerC,
                classes.darkGrayBorderOnly
              )}
              style={{ marginTop: 16 }}
            >
              <Typography
                style={{
                  fontWeight: 300,
                  marginTop: 32,
                  whiteSpace: "pre-line",
                }}
              >
                We've made a job posting based on your signup information!
                {"\n\n"}
                Click to view your posting or return to your dashboard.
              </Typography>
              <Box className={classes.flexBoxSpaced} style={{ marginTop: 32 }}>
                <Button
                  color="primary"
                  className={classes.txtTrButton}
                  onClick={this.onClickDashboard}
                >
                  Dashboard
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.txtTrButton}
                  onClick={this.handlePosting}
                >
                  View Posting
                </Button>
              </Box>
            </Paper>
          </Grow>
        </Box>
      </Box>
    );
  }
}

InitialLoadScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickDashboard: PropTypes.func.isRequired,
};

export default withStyles(styles)(InitialLoadScreen);
