import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";

// Component imports
import LoadingAnimation from "../layout/LoadingAnimation";
import MessageChnContainer from "./components/MessageChnContainer";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Icon imports
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,

  noMessageBox: {
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    height: "100%",
    borderRadius: 10,
  },
});

export class DashMessagesCard extends Component {
  render() {
    const { classes, isLoaded, messageChannels, userType, userIdNumber } =
      this.props;

    const isFamily = userType === "family";

    return (
      <Paper
        elevation={4}
        className={clsx(classes.paperContainerC, classes.flexBoxCenteredColumn)}
        style={{ height: 250 }}
      >
        <Typography variant="h6" style={{ fontWeight: 300 }}>
          Messages
        </Typography>

        <Divider style={{ width: "100%" }} />
        <Box
          className={clsx(classes.flexBoxColumn, `hideScroll`)}
          style={{
            width: "100%",
            flex: 1,
            overflow: "auto",
            justifyContent: "flex-start",
          }}
        >
          {!isLoaded ? (
            <LoadingAnimation size={75} message="Loading messages" />
          ) : messageChannels.length > 0 ? (
            messageChannels.map((messageChannel, ind) => (
              <MessageChnContainer
                key={messageChannel.channelId}
                messageChannel={messageChannel}
                isFamily={isFamily}
                ind={ind}
                userIdNumber={userIdNumber}
              />
            ))
          ) : (
            <Box p={1} display="flex" className={classes.noMessageBox}>
              <Box
                style={{
                  margin: "auto 0px auto 0px",
                }}
              >
                <ChatBubbleOutlineOutlinedIcon
                  style={{ opacity: 0.7, fontSize: 40 }}
                  color="primary"
                />
                <Typography color="textSecondary">
                  You have no new messages.
                </Typography>
                <Button
                  variant="outlined"
                  component={Link}
                  color="secondary"
                  to={`/${userType === "family" ? "caregivers" : "jobs"}`}
                  className={classes.txtTrButton}
                >
                  Start Your Search
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    );
  }
}

DashMessagesCard.propTypes = {
  classes: PropTypes.object.isRequired,
  messageChannels: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  userIdNumber: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

export default withStyles(styles)(DashMessagesCard);
