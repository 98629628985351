import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import axios from "axios";
import clsx from "clsx";

// Component imports
import CaregiverWorkDialog from "../profile/eduWork/CaregiverWorkDialog";
import CaregiverEduDialog from "../profile/eduWork/CaregiverEduDialog";
import WorkExperience from "../profile/eduWork/WorkExperience";
import EduAccred from "../profile/eduWork/EduAccred";
import SkipNextDialog from "./components/SkipNextDialog";
import Stepper from "../../../components/layout/Components/Stepper";

// Redux imports
import { connect } from "react-redux";
import {
  setLoadingUISpecial,
  stopLoadingUISpecial,
} from "../../../redux/actions/uiActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

// Icons imports
import BusinessIcon from "@mui/icons-material/Business";
import SchoolIcon from "@mui/icons-material/School";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class caregiverEducationExperience extends Component {
  componentDidMount() {
    this.checkAccountStatus();
  }

  checkAccountStatus() {
    const { isBasicCompleted, isServiceCompleted, isEduAccredCompleted } =
      this.props.user.credentials.status;
    if (!isBasicCompleted) {
      this.props.history.push("/caregiver/signup/basic-info");
    }
    if (!isServiceCompleted) {
      this.props.history.push("/caregiver/signup/services");
    }
    if (isEduAccredCompleted) {
      this.props.history.push("/caregiver/signup/finish");
    }
  }

  finishEduAccred = async () => {
    try {
      this.props.setLoadingUISpecial();
      await axios.post(`/user/caregiver/edu-accred/finish`);
      setTimeout(() => {
        this.props.stopLoadingUISpecial();

        this.props.history.push("/caregiver/signup/finish");
      }, 500);
    } catch (err) {
      console.error(err.response);
      this.props.stopLoadingUISpecial();
    }
  };

  render() {
    const {
      classes,
      user: { workExp, eduAccred },
      UI: { loadingSpecial },
    } = this.props;

    const workExpListMarkup =
      workExp.length < 1 ? (
        <Box
          className={classes.form}
          style={{ opacity: 0.5, margin: "20px 0px ", minHeight: 100 }}
        >
          <BusinessIcon fontSize="large" />
          <Typography variant="body2" style={{ fontWeight: "550" }}>
            Add your work experiences
          </Typography>
        </Box>
      ) : (
        workExp.map((workExperience) => (
          <WorkExperience
            workExp={workExperience}
            key={workExperience.workExpId}
          />
        ))
      );

    const eduListMarkup =
      eduAccred.length < 1 ? (
        <Box
          className={classes.form}
          style={{ opacity: 0.5, margin: " 20px 0px", minHeight: 100 }}
        >
          <SchoolIcon fontSize="large" />
          <Typography variant="body2" style={{ fontWeight: "550" }}>
            Add your education and other accreditations
          </Typography>
        </Box>
      ) : (
        eduAccred.map((eduAccredData) => (
          <EduAccred
            eduAccred={eduAccredData}
            key={eduAccredData.eduAccredId}
          />
        ))
      );

    if (this.props.user.credentials.isEduAccredCompleted) {
      return <Redirect to="/caregiver/signup/finish" />;
    }

    return (
      <Grid container justifyContent="center" style={{ marginTop: 24 }}>
        <Grid item xs={12} sm={9}>
          <Paper
            variant="elevation"
            elevation={4}
            className={clsx(
              classes.paperContainerSignup,
              classes.darkGrayBorderOnly
            )}
          >
            <Grid
              container
              justifyContent="center"
              style={{
                width: "100%",
                marginBottom: 20,
              }}
            >
              <Stepper total={5} fadeIn={3} />
            </Grid>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              className={classes.typography}
              style={{ margin: 10 }}
            >
              Experience and Education
            </Typography>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={classes.typography}
                  style={{ fontWeight: 300 }}
                >
                  Add your work experience and education / accreditation below.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.typography}
                    style={{ fontWeight: 300 }}
                  >
                    Work Experience
                  </Typography>
                  <CaregiverWorkDialog />
                </Box>
                <Divider />

                {workExpListMarkup}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.typography}
                    style={{ fontWeight: 300 }}
                  >
                    Education
                  </Typography>
                  <CaregiverEduDialog />
                </Box>
                <Divider />

                {eduListMarkup}
              </Grid>
              <Box style={{ width: "100%", padding: "0px 8px" }}>
                <Divider />
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: "100%", padding: 8 }}
              >
                <SkipNextDialog
                  loading={loadingSpecial}
                  showSkip={workExp.length < 1 || eduAccred.length < 1}
                  finishEduAccred={this.finishEduAccred}
                />
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

caregiverEducationExperience.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, {
  setLoadingUISpecial,
  stopLoadingUISpecial,
})(withStyles(styles)(caregiverEducationExperience));
