import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Icons imports
import WarningIcon from "@mui/icons-material/Warning";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class SkipNextDialog extends Component {
  state = { open: false };

  handleClose = (event, reason) => {
    if (reason !== "backdropClick") this.setState({ open: false });
  };

  handleNext = () => {
    const { showSkip } = this.props;
    if (showSkip) {
      this.setState({ open: true });
    } else {
      this.props.finishEduAccred();
    }
  };

  handleSkip = () => {
    this.props.finishEduAccred();
  };

  render() {
    const { classes, loading, showSkip } = this.props;
    const { open } = this.state;
    return (
      <Fragment>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleNext}
          className={classes.button}
          disabled={loading}
          style={{ width: 100 }}
        >
          {showSkip ? "Skip" : "Next"}
          {loading && (
            <CircularProgress
              thickness={2}
              size={30}
              className={classes.progress}
            />
          )}
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
          disableEscapeKeyDown
        >
          <DialogContent
            className={classes.dialogContent}
            style={{ padding: 16, textAlign: "center" }}
          >
            <Box style={{ margin: "16px 0px" }}>
              <WarningIcon color="error" fontSize="large" />
              <Typography variant="h6" color="error">
                One or more parts of your profile is incomplete
              </Typography>
            </Box>
            <div className={classes.selectedGrayContainer}>
              <Typography variant="h6" style={{ fontWeight: 300 }}>
                Your <span style={{ fontWeight: "bold" }}>work experience</span>{" "}
                and <span style={{ fontWeight: "bold" }}>education</span> is at
                the top of what families carefully consider when looking for a
                qualified caregiver.
              </Typography>

              <Typography
                variant="body2"
                style={{ fontWeight: 300, marginTop: 16 }}
              >
                You may{" "}
                <span style={{ textDecoration: "underline" }}>skip</span> this
                section for now and come back later.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.handleClose}
              className={classes.button}
            >
              Complete
            </Button>
            <Button
              color="primary"
              onClick={this.handleSkip}
              className={classes.button}
            >
              Skip
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

SkipNextDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  showSkip: PropTypes.bool.isRequired,
  finishEduAccred: PropTypes.func.isRequired,
};

export default withStyles(styles)(SkipNextDialog);
