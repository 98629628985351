import React, { Component } from "react";
import axios from "axios";

import CareersGrid from "./components/CareersGrid";

import Typography from "@mui/material/Typography";

export class careers extends Component {
  state = {
    careersApiData: [],
    careersFilteredApiData: {},
    loading: true,
  };
  componentDidMount() {
    this._getCareers();
  }

  async _getCareers() {
    const careersApi = await axios.get("/careers/get-all");
    this.setState({
      careersApiData: careersApi.data.careers,
      careersFilteredApiData: careersApi.data.careersFiltered,
      loading: false,
    });
  }

  render() {
    const { careersApiData, careersFilteredApiData, loading } = this.state;
    return (
      <section className="page-wrapper">
        <div className="full-vw-container p-bg bottom-drop section-title">
          <div className="header-title contrast-txt">
            <Typography variant="h3" className="txt-bold">
              Careers
            </Typography>
            <Typography className="description-txt">
              We believe that people make the difference and strive to build a
              team where ideas are heard, hard work is supported, and success is
              celebrated.
            </Typography>
          </div>
        </div>

        <div style={{ padding: "36px 0px" }}>
          <CareersGrid
            careers={careersApiData}
            careersFiltered={careersFilteredApiData}
            loading={loading}
          />
        </div>
      </section>
    );
  }
}

export default careers;
