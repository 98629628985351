import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import axios from "axios";
import { withRouter } from "react-router";

// Redux imports
import { compose } from "redux";
import { connect } from "react-redux";
import { spliceDataArray } from "../../../redux/actions/dataActions";
import { setAlert, setUpdateCheck } from "../../../redux/actions/uiActions";

// Component imports
import AddTaskDialog from "../../buttons/shift_task/AddTaskDialog";
import TaskDisplayContainer from "../task/TaskDisplayContainer";
import { isEmpty, capitalizeFirstChar } from "../../../util/utilFunctions";
import ShiftInfoContainer from "./ShiftInfoContainer";
import ActionShiftButton from "../../buttons/shift_task/ActionShiftButton";
import ShiftReportButton from "../../buttons/shift_task/ShiftReportButton";
import ShiftReportsMenuButton from "../../buttons/shift_task/ShiftReportsMenuButton";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// Icon imports
import DeleteIcon from "@mui/icons-material/DeleteOutline";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function InnerTypography(props) {
  return (
    <Typography
      component="span"
      variant="body2"
      color="textSecondary"
      style={{ fontWeight: 300 }}
      {...props}
    />
  );
}

export class ShiftAccordionDetails extends Component {
  state = {
    tasks: [],
    isLoadedTasks: false,
    loadingTasks: false,
    allowReload: false,
    disableDeleteButton: false,
    disableWhileLoad: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    this.getTasks();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  // Action Methods
  getTasks = async () => {
    const { shift } = this.props;
    await this.setStateAsync({ loadingTasks: true, stopLoadTask: true });
    try {
      const tasksDoc = await axios.get(`/shift/${shift.id}/task`);

      await this.setStateAsync({ tasks: tasksDoc.data, allowReload: false });
    } catch (err) {
      await this.setStateAsync({ allowReload: true });

      this.props.setAlert({
        message: "There was an error getting tasks",
        type: "error",
      });
    } finally {
      await this.setStateAsync({ loadingTasks: false, isLoadedTasks: true });
    }
  };

  // Action Family Methods

  setNewTask = async (taskObj) => {
    const { shift } = this.props;
    const { tasks } = this.state;
    await this.setStateAsync({ disableWhileLoad: true });

    try {
      const taskDoc = await axios.post(`/shift/${shift.id}/task`, taskObj);
      const _tasks = tasks;
      _tasks.push(taskDoc.data);

      this.setState({ tasks: _tasks });
    } catch (err) {
      this.props.setAlert({
        message: Boolean(err.response.data.task)
          ? err.response.data.task
          : "An error has occurred while adding this task.",
        type: "error",
      });
    } finally {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  handleUpdateTask = (value, ind) => {
    const { tasks } = this.state;

    const _tasks = tasks;
    _tasks[ind] = value;

    this.setState({ tasks: _tasks });
  };

  handleDeleteTask = async (ind) => {
    const { tasks } = this.state;
    const taskId = tasks[0].id;
    await this.setStateAsync({ disableDeleteButton: true });

    try {
      await axios.get(`/task/${taskId}/delete`);
      const _tasks = tasks;
      _tasks.splice(ind, 1);

      this.setState({ tasks: _tasks });
      this.props.setUpdateCheck();
    } catch (err) {
      this.props.setAlert({
        message: err.response.data.hasOwnProperty("task")
          ? err.response.data.task
          : "There was an error deleting this task",
        type: "error",
      });
    } finally {
      await this.setStateAsync({ disableDeleteButton: false });
    }
  };

  handleDeleteShift = async () => {
    const { shift } = this.props;
    await this.setStateAsync({ disableWhileLoad: true });

    try {
      await axios.delete(`/shift/${shift.id}`);
      this.props.history.replace({
        pathname: `/myteam/${this.props.currMemberId}`,
      });
      this.props.spliceDataArray(shift.id, "shifts");
      setTimeout(() => this.props.setUpdateCheck(), 250);
    } catch (err) {
      await this.setStateAsync({ disableWhileLoad: false });
      this.props.setAlert({
        message: Boolean(err.response.data.shift)
          ? err.response.data.shift
          : "An error has occurred while deleting this shift.",
        type: "error",
      });
    }
  };

  render() {
    const { classes, shift, isUnactioned, credentials } = this.props;

    const {
      tasks,
      allowReload,
      loadingTasks,
      disableDeleteButton,
      disableWhileLoad,
    } = this.state;

    const isFamily = credentials.userType === "family";

    return (
      <div>
        {isUnactioned && (
          <Box display="flex" justifyContent="center">
            <Typography
              align="center"
              variant="body2"
              color="error"
              style={{
                padding: "8px 16px",
                borderRadius: 25,
                backgroundColor: "rgba(255,255,255,0.5)",
              }}
            >
              {isFamily
                ? `This shift was not actioned. Please contact ${capitalizeFirstChar(
                    shift.caregiverInfo.firstName
                  )}.`
                : "This shift was not completed in the allotted time. Please action immediately."}
            </Typography>
          </Box>
        )}

        <Box display="flex" alignItems="flex-end" flexDirection="column">
          <Box style={{ width: "100%" }}>
            {shift.hasOwnProperty("reports") &&
              shift.reports.length > 0 &&
              shift.reports.map((report) => (
                <ShiftReportsMenuButton key={report.id} report={report} />
              ))}
          </Box>
          {isFamily ? (
            !shift.status.isCheckedIn && (
              <Button
                onClick={this.handleDeleteShift}
                style={{ borderRadius: 25 }}
                startIcon={<DeleteIcon />}
                disabled={disableWhileLoad}
              >
                Delete Shift
              </Button>
            )
          ) : (
            <div>
              <ShiftReportButton shift={shift} />
              <ActionShiftButton
                shift={shift}
                variant="contained"
                updateOnAction
              />
            </div>
          )}

          <ShiftInfoContainer shift={shift} />
        </Box>

        <Box>
          {!isEmpty(shift.note) && (
            <Box style={{ margin: "8px 0px" }}>
              <Typography style={{ fontWeight: 300 }}>Note:</Typography>
              <Divider style={{ margin: "4px 0px" }} />
              <InnerTypography
                component="div"
                className={classes.sectionBoxLeftOutline}
                style={{ whiteSpace: "pre-line", margin: "4px 0px" }}
              >
                {shift.note}
              </InnerTypography>
            </Box>
          )}
        </Box>
        <Box style={{ marginTop: 16 }}>
          <Box className={classes.flexBoxSpaced}>
            <Typography style={{ fontWeight: 300 }}>Tasks:</Typography>
            {isFamily && (
              <AddTaskDialog
                onSubmitTask={this.setNewTask}
                isDisableTaskAdd={
                  shift.status.isCheckedIn ||
                  disableWhileLoad ||
                  dayjs().toISOString() > shift.startTime
                }
              />
            )}
          </Box>
          <Divider style={{ margin: "4px 0px" }} />
          <TaskDisplayContainer
            loadingTasks={loadingTasks}
            tasks={tasks}
            isFamily={isFamily}
            shift={shift}
            disableDeleteButton={disableDeleteButton}
            allowReload={allowReload}
            handleUpdateTask={this.handleUpdateTask}
            handleDeleteTask={this.handleDeleteTask}
            getTasks={this.getTasks}
          />
        </Box>
      </div>
    );
  }
}

ShiftAccordionDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  isUnactioned: PropTypes.bool,
  credentials: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, { setAlert, setUpdateCheck, spliceDataArray })
);

export default enhance(ShiftAccordionDetails);
