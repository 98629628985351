import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

let config = {
  apiKey: "AIzaSyBS_m4APPDCbm_1xqD3lPllrFbO-_8m2es",
  authDomain: "poyo-health1.firebaseapp.com",
  databaseURL: "https://poyo-health1.firebaseio.com",
  projectId: "poyo-health1",
  storageBucket: "poyo-health1.appspot.com",
  messagingSenderId: "133985777062",
  appId: "1:133985777062:web:45beaf8580ec859181cb67",
  measurementId: "G-ZEN35GRFL9",
};

export default !firebase.apps.length
  ? firebase.initializeApp(config)
  : firebase.app();
