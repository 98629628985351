import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { withLoginHOC } from "../../../util/withFBHooks";
// Redux imports
import { connect } from "react-redux";
import { loginUser } from "../../../redux/actions/userActions";

// Component imports
import LogoSvg from "../../../assets/logo/logo.svg";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const styles = (theme) => ({
  ...theme.spreadThis,

  linkText: {
    fontWeight: 550,
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    transition: "0.25s",
  },
});

export class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    errors: {},
    disableWhileLoad: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    await this.setStateAsync({ disableWhileLoad: true });
    const loginSuccess = await this.handleLoginUser(userData);
    if (loginSuccess) this.props.loginHook(userData.email, userData.password);
  };

  handleLoginUser = async (userData) => {
    await this.setStateAsync({ errors: {} });
    try {
      await axios.post("/login", userData);
      return true;
      // this.props.loginUser(userData);
    } catch (err) {
      this.setState({
        disableWhileLoad: false,
        errors: Boolean(err.response) ? err.response.data : {},
      });
      return false;
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  render() {
    const { classes } = this.props;

    const { errors, disableWhileLoad } = this.state;

    return (
      <>
        <form noValidate onSubmit={this.handleSubmit}>
          <img
            src={LogoSvg}
            alt="icon"
            className={classes.image}
            draggable="false"
          />
          <Typography align="left" variant="h6">
            Login to Poyo
          </Typography>
          <div className="title-line" />
          <TextField
            name="email"
            type="email"
            label="Email"
            variant="outlined"
            className={
              !errors.email
                ? classes.styledTextField
                : classes.styledTextFieldError
            }
            helperText={errors.email}
            error={errors.email || errors.general ? true : false}
            value={this.state.email}
            onChange={this.handleChange}
            style={{ backgroundColor: "white", marginTop: 24 }}
            fullWidth
          />
          <TextField
            name="password"
            type="password"
            label="Password"
            variant="outlined"
            className={
              !errors.password
                ? classes.styledTextField
                : classes.styledTextFieldError
            }
            helperText={errors.password}
            error={errors.password || errors.general ? true : false}
            value={this.state.password}
            onChange={this.handleChange}
            style={{ backgroundColor: "white" }}
            fullWidth
          />
          {errors.general && (
            <Typography variant="body2" className={classes.customError}>
              {errors.general}
            </Typography>
          )}
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            className={classes.button}
            disabled={disableWhileLoad}
            style={{ marginTop: 15 }}
          >
            Login
            {disableWhileLoad && (
              <CircularProgress
                thickness={2}
                size={30}
                className={classes.progress}
              />
            )}
          </Button>
          <div style={{ textAlign: "left", marginTop: 8 }}>
            <Typography
              component={Link}
              to="/forgot_password"
              variant="body2"
              className={classes.linkText}
            >
              Forgot Password?
            </Typography>
            <Typography variant="body2" style={{ marginTop: 8 }}>
              Dont have an account?{" "}
              <Box component={Link} to="/signup" className={classes.linkText}>
                Sign Up
              </Box>
            </Typography>
          </div>
        </form>
      </>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withLoginHOC(withStyles(styles)(LoginForm)));
