import React, { Component } from "react";
import PropTypes from "prop-types";
import SignupFamilyDialog from "./family/SignupFamilyDialog";
import SignupCaregiverDialog from "./caregiver/SignupCaregiverDialog";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class signup extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        justifyContent="center"
        className={classes.form}
        style={{ alignSelf: "center", padding: "36px 12px" }}
      >
        <Grid item xs={12} sm={7} md={5}>
          <Paper
            elevation={4}
            className="login-container" // style={{ padding: 32 }}
          >
            <Typography variant="h5" style={{ marginBottom: 16 }}>
              Find quality care with Poyo
            </Typography>
            <Typography color="textSecondary">
              Get started today by telling us your needs
            </Typography>
            <SignupFamilyDialog />

            <Divider style={{ margin: "16px 0px" }} />
            <Typography variant="h5" style={{ marginBottom: 16 }}>
              Apply with Poyo
            </Typography>
            <Typography color="textSecondary">
              Make more on your schedule
            </Typography>

            <SignupCaregiverDialog />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

signup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(signup);
