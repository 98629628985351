import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import PropTypes from "prop-types";

// Component imports
import {
  capitalizeFirstChar,
  splitAddressFull,
} from "../../../util/utilFunctions";
import OutlinedProfilePic from "../../layout/OutlinedProfilePic";
import ReviewInquireDialog from "../../review/ReviewInquireDialog";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Icon imports

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class TeamMemberSwipe extends Component {
  handlePrevMember = () => {
    this.props.handleChangeTeamIndex(this.props.ind - 1);
  };

  handleNextMember = () => {
    this.props.handleChangeTeamIndex(this.props.ind + 1);
  };

  render() {
    const {
      currentTeamIndex,
      ind,
      teamMember,
      classes,
      isFamily,
      isLoadedShifts,
      totalInd,
      numCurrentFamilyShifts,
    } = this.props;
    return (
      <Box
        value={currentTeamIndex}
        index={ind}
        key={teamMember.id}
        style={{
          height: 169,
          flexDirection: "column",
          borderRadius: 10,
          position: "relative",
        }}
        className={classes.flexBoxCentered}
      >
        <ReviewInquireDialog
          initialOpen={Boolean(isFamily) && teamMember.review.shouldInquire}
          teamMember={teamMember}
        />
        {isBrowser && (
          <Fragment>
            <IconButton
              onClick={this.handlePrevMember}
              className={classes.absoluteIconButtonOnHover}
              style={{ left: 0, bottom: 0 }}
              disabled={ind < 1}
              size="large"
            >
              <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton
              onClick={this.handleNextMember}
              className={classes.absoluteIconButtonOnHover}
              style={{ right: 0, bottom: 0 }}
              disabled={ind === totalInd}
              size="large"
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Fragment>
        )}
        <CardActionArea
          component={Link}
          to={`/myteam/${teamMember.id}`}
          className={classes.flexBoxCentered}
          style={{
            flexDirection: "column",
            borderRadius: 10,
            padding: 4,
            backgroundColor: "rgba(212,212,212,0.1)",
          }}
        >
          <OutlinedProfilePic
            imgSrc={
              isFamily
                ? teamMember.caregiverInfo.imgUrlThumb
                : teamMember.familyInfo.imgUrlThumb
            }
            alt="Profile Pic"
            size={40}
          />
          {isFamily ? (
            <Typography variant="body2" align="center">
              {capitalizeFirstChar(teamMember.caregiverInfo.firstName)}{" "}
              {teamMember.caregiverInfo.lastName[0].toUpperCase()}.
            </Typography>
          ) : (
            <Typography variant="body2" align="center">
              {capitalizeFirstChar(teamMember.familyInfo.clientFirstName)}{" "}
              {teamMember.familyInfo.clientLastName[0].toUpperCase()}.
            </Typography>
          )}
          <Typography variant="body2" align="center" color="textSecondary">
            {splitAddressFull(teamMember.address.addressFull)}
          </Typography>
        </CardActionArea>

        <Box
          style={{
            flex: 1,
            width: "100%",
          }}
          className={classes.flexBoxCentered}
        >
          {isLoadedShifts ? (
            <Typography color="secondary" style={{ fontWeight: 550 }}>
              {numCurrentFamilyShifts} shifts
            </Typography>
          ) : (
            <CircularProgress thickness={2} size={25} />
          )}
        </Box>
      </Box>
    );
  }
}

TeamMemberSwipe.propTypes = {
  teamMember: PropTypes.object.isRequired,
  isFamily: PropTypes.bool,
  currentTeamIndex: PropTypes.number.isRequired,
  ind: PropTypes.number.isRequired,
  numCurrentFamilyShifts: PropTypes.number.isRequired,
  isLoadedShifts: PropTypes.bool.isRequired,
  totalInd: PropTypes.number.isRequired,
  handleChangeTeamIndex: PropTypes.func.isRequired,
};

export default withStyles(styles)(TeamMemberSwipe);
