import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import ButtonSelectUnique from "../../../../components/inputs/ButtonSelectUnique";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class SingleButtonSelectContainer extends Component {
  onSelect = (e) => {
    this.props.onSelect(e);
  };

  render() {
    const { classes, valueObj, error, value, header } = this.props;
    return (
      <Box className={classes.sectionBoxLeftOutline}>
        <Typography
          variant="body2"
          className={classes.typography}
          style={{ fontWeight: 300 }}
        >
          {header}
        </Typography>

        {error && (
          <Typography
            variant="caption"
            style={{ color: "#f44336", fontWeight: 300 }}
          >
            {error}
          </Typography>
        )}
        <ButtonSelectUnique
          valueObj={valueObj}
          value={value}
          error={Boolean(error)}
          handleSelectValue={this.onSelect}
        />
      </Box>
    );
  }
}

SingleButtonSelectContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  valueObj: PropTypes.object.isRequired,
  error: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default withStyles(styles)(SingleButtonSelectContainer);
