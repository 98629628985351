import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Component imports
import Stepper from "../../../../components/layout/Components/Stepper";
import { allFalse, checkAll, isEmail } from "../../../../util/utilFunctions";
import AnimatedCheck from "../../../../assets/AnimatedCheck";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// Icons imports
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CancelIcon from "@mui/icons-material/Cancel";

const styles = (theme) => ({
  ...theme.spreadThis,
  linkText: {
    fontWeight: 550,
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
});

function CSSBox(props) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{
        height: 20,
        paddingTop: 2,
        paddingBottom: 2,
        width: "100%",
      }}
      {...props}
    />
  );
}

export class SFLogin extends Component {
  state = {
    errors: {},
    email: "",
    password: "",
    showPassword: false,
    confirmPassword: "",
    passwordChecker: {
      isCapital: false,
      isLower: false,
      isNumber: false,
      isLength: false,
    },
    passwordCheckers: [
      "Includes at least 1 upper case letter",
      "Includes at least 1 lower case letter",
      "Includes at least 1 digit",
      "Minimum 8 characters in total",
    ],
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Error validation

  handleValidateConfirmNewPassword = (inputPassword) => {
    const { password } = this.state;

    let passwordSameOrGreaterLength = false;
    passwordSameOrGreaterLength = inputPassword.length >= password.length;

    return (
      passwordSameOrGreaterLength && password.trim() !== inputPassword.trim()
    );
  };

  validateSignupCredentials = () => {
    const { email, password, confirmPassword, errors } = this.state;
    let _errors = errors;
    if (email.trim() === "") {
      _errors.email = "Must not be empty";
    } else if (!isEmail(email)) {
      _errors.email = "Invalid email address";
    }

    if (password === "") {
      _errors.password = "Must not be empty";
    } else if (Boolean(errors.errorAllCheck)) {
      _errors.password = "Password does not meet safety requirements";
    } else if (password !== confirmPassword) {
      _errors.confirmPassword = "Passwords must match";
    }
    this.setState({ errors: _errors });
  };

  // Action Methods
  handleEmail = (e) => {
    let value = e.target.value;
    this.setState({
      email: value,
      errors: {
        ...this.state.errors,
        email: value === "" ? "Must not be empty" : null,
      },
    });
  };

  handlePassword = (e) => {
    let value = e.target.value.trim();

    const { isCapital, isLower, isNumber, isLength, allCheck } =
      checkAll(value);
    this.setState({
      password: value,
      passwordChecker: {
        isCapital,
        isLower,
        isNumber,
        isLength,
      },
      errors: {
        ...this.state.errors,
        errorAllCheck: !allCheck,
        password: value === "" ? "Must not be empty" : null,
        confirmPassword:
          value === this.state.confirmPassword
            ? null
            : this.state.errors.confirmPassword,
      },
    });
  };

  handleConfirmPassword = (e) => {
    let value = e.target.value.trim();

    let notMatch = this.handleValidateConfirmNewPassword(value);
    if (this.state.password === "" && notMatch) notMatch = false;

    this.setState({
      confirmPassword: value,
      errors: {
        ...this.state.errors,
        confirmPassword:
          value === ""
            ? "Must not be empty"
            : notMatch
            ? "Passwords must match"
            : null,
      },
    });
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  handleSubmit = async (event) => {
    const { errors, email, password, confirmPassword } = this.state;
    event.preventDefault();
    await this.setStateAsync({ disableWhileLoad: true });

    await this.validateSignupCredentials();
    const noErrors = allFalse(errors);
    if (noErrors) {
      this.props.onSignup({
        email,
        password,
        confirmPassword,
      });
      await this.setStateAsync({ disableWhileLoad: false });
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  handleBack = () => {
    this.props.onSignup({
      pages: { p1: true, p2: true, p3: false, p4: true, p5: false },
    });
  };

  render() {
    const { classes, loading, errorMsg, errorMsgEmail, total, fadeIn } =
      this.props;

    const {
      errors,
      email,
      password,
      confirmPassword,
      showPassword,
      passwordCheckers,
      disableWhileLoad,
      passwordChecker: { isCapital, isLower, isNumber, isLength },
    } = this.state;

    return (
      <Box p={2} className={classes.form}>
        <Stepper total={total} fadeIn={fadeIn} />
        <br />
        <Typography
          variant="h6"
          color="primary"
          align="center"
          style={{ margin: "10px 0px", fontWeight: 300 }}
          className={classes.typography}
        >
          Login Info
        </Typography>
        <Typography variant="body2" align="left" style={{ fontWeight: 300 }}>
          Your email and password will be used as your login information to
          connect with caregivers. Communicating with caregivers is absolutely
          free on Poyo, and there is no long-term commitment. You'll only be
          billed once you successfully hire a caregiver.
        </Typography>
        <br />
        <form noValidate onSubmit={this.handleSubmit}>
          <Grid container justifyContent="center" style={{ width: "100%" }}>
            <Grid item xs={11} sm={6}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                className={
                  !Boolean(errors.email) && !Boolean(errorMsgEmail)
                    ? classes.styledTextField
                    : classes.styledTextFieldError
                }
                helperText={
                  Boolean(errorMsgEmail) ? errorMsgEmail : errors.email
                }
                error={Boolean(errors.email) || Boolean(errorMsgEmail)}
                value={email}
                onChange={this.handleEmail}
                inputProps={{ inputMode: "email" }}
                fullWidth
              />

              <TextField
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={this.handlePassword}
                error={Boolean(errors.password)}
                className={
                  !Boolean(errors.password)
                    ? classes.styledTextField
                    : classes.styledTextFieldError
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                helperText={errors.password}
              />
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                label="Confirm Password"
                variant="outlined"
                className={
                  !Boolean(errors.confirmPassword)
                    ? classes.styledTextField
                    : classes.styledTextFieldError
                }
                helperText={errors.confirmPassword}
                error={Boolean(errors.confirmPassword)}
                value={confirmPassword}
                onChange={this.handleConfirmPassword}
                fullWidth
              />
              <Typography
                variant="body2"
                color="textSecondary"
                align="left"
                style={{ marginTop: 10, fontWeight: 550 }}
              >
                Password Requirements
              </Typography>
              <Divider style={{ marginBottom: 5 }} />
              {passwordCheckers.map((k, i) => {
                let notError =
                  i === 0
                    ? isCapital
                    : i === 1
                    ? isLower
                    : i === 2
                    ? isNumber
                    : isLength;
                return (
                  <CSSBox key={i}>
                    <Typography
                      component="div"
                      variant="caption"
                      color={notError ? "textSecondary" : "error"}
                    >
                      {k}
                    </Typography>
                    {notError ? (
                      <AnimatedCheck />
                    ) : (
                      <CancelIcon color="error" style={{ fontSize: 16 }} />
                    )}
                  </CSSBox>
                );
              })}
              {Boolean(errorMsg) && (
                <Typography variant="body2" className={classes.customError}>
                  {errorMsg}
                </Typography>
              )}

              <Button
                variant="contained"
                type="submit"
                color="primary"
                className={classes.button}
                disabled={loading || disableWhileLoad}
                style={{ marginTop: 5 }}
                fullWidth
              >
                Sign up
                {(loading || disableWhileLoad) && (
                  <CircularProgress
                    thickness={2}
                    size={30}
                    className={classes.progress}
                    style={{ position: "absolute", right: 0, marginRight: 5 }}
                  />
                )}
              </Button>
              <br />
              <Typography
                component="div"
                align="center"
                variant="body2"
                style={{ marginTop: 10 }}
              >
                Already have an account?{" "}
                <Box component={Link} to="/login" className={classes.linkText}>
                  Log in
                </Box>
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleBack}
                style={{
                  textTransform: "none",
                  marginTop: 8,
                }}
                fullWidth
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }
}

SFLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  onSignup: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errorMsg: PropTypes.string,
  errorMsgEmail: PropTypes.string,
  total: PropTypes.number.isRequired,
  fadeIn: PropTypes.number.isRequired,
};

export default withStyles(styles)(SFLogin);
