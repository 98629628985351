// User reducer types
export const SET_UNAUTHENTICATED = "SET_UNAUTHENICATED";
export const SET_SIGNUPSUCCESS = "SET_SIGNUPSUCCESS";
export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const SET_USER = "SET_USER";
export const SET_USER_QUICK = "SET_USER_QUICK";
export const LOADING_USER = "LOADING_USER";
export const UPDATE_PROFILE_IMG = "UPDATE_PROFILE_IMG";
export const ADD_WORKEXP = "ADD_WORKEXP";
export const EDIT_WORKEXP = "EDIT_WORKEXP";
export const DELETE_WORKEXP = "DELETE_WORKEXP";
export const ADD_EDUACCRED = "ADD_EDUACCRED";
export const EDIT_EDUACCRED = "EDIT_EDUACCRED";
export const DELETE_EDUACCRED = "DELETE_EDUACCRED";
export const FINISH_EDUACCRED = "FINISH_EDUACCRED";
export const UPLOAD_BACKGROUND_CHECK = "UPLOAD_BACKGROUND_CHECK";
export const FINISH_USER = "FINISH_USER";
export const SET_BANKING = "SET_BANKING";
export const SET_FAV = "SET_FAV";
export const SET_AVAIL = "SET_AVAIL";
export const SET_NOTIF = "SET_NOTIF";
export const CLEAR_NOTIF = "CLEAR_NOTIF";
export const SET_FAVOURITE_DOC = "SET_FAVOURITE_DOC";
export const SET_PREFERRED_PAYMENT_METHOD = "SET_PREFERRED_PAYMENT_METHOD";

// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const LOADING_UI_SPECIAL = "LOADING_UI_SPECIAL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const SET_UPDATED = "SET_UPDATED";
export const CLEAR_UPDATED = "CLEAR_UPDATED";
export const UNSET_FOOTER = "UNSET_FOOTER";
export const SET_FOOTER = "SET_FOOTER";
export const SET_INITIAL_LOAD = "SET_INITIAL_LOAD";
export const SET_ALERT = "SET_ALERT";
export const UNSET_ALERT = "UNSET_ALERT";
export const SET_PAGE = "SET_PAGE";
export const UNSET_PAGE = "UNSET_PAGE";

// Data reducer types

export const SET_QUERY_RESULTS = "SET_QUERY_RESULTS";
export const SET_FILTERED_RESULTS = "SET_FILTERED_RESULTS";
export const SET_JOB = "SET_JOB";
export const SET_SHIFTS = "SET_SHIFTS";
export const SET_SHIFT = "SET_SHIFT";
export const SET_TEAMS = "SET_TEAMS";
export const SET_CG = "SET_CG";
export const SET_DASH_DATA = "SET_DASH_DATA";
export const UPDATE_DASH_DATA = "UPDATE_DASH_DATA";
export const UPDATE_DASH_ARRAY = "UPDATE_DASH_ARRAY";
export const UNSET_QUERY_LOADED = "UNSET_QUERY_LOADED";
export const SET_MSGCHN = "SET_MSGCHN";
export const CLEAR_DATA = "CLEAR_DATA";
export const UPDATE_SINGLE_QUERY = "UPDATE_SINGLE_QUERY";
export const SPLICE_DASH_ARRAY = "SPLICE_DASH_ARRAY";
export const UPDATE_DASH_ARRAY_BY_ID = "UPDATE_DASH_ARRAY_BY_ID";
