import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Component imports
import TaskDisplay from "./task/TaskDisplay";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import CircularProgress from "@mui/material/CircularProgress";

// Icon imports
import AssignmentIcon from "@mui/icons-material/Assignment";

const styles = (theme) => ({
  ...theme.spreadThis,
});
function DetailsTasksDisplay(props) {
  const { classes, shift, tasks, loading, allowReload, isFamily } = props;

  return (
    <Grid container spacing={1} style={{ minHeight: 200 }}>
      <Grid item xs={12} sm={4}>
        <Typography>Notes:</Typography>
        <Typography style={{ whiteSpace: "pre-line", fontWeight: 300 }}>
          {shift.note}
        </Typography>

        <Hidden only="xs">
          <Box className={classes.flexBoxCentered}>
            <Button
              component={Link}
              to={`/myteam/${shift.memberId}/shift/${shift.id}`}
              className={classes.txtTrButton}
              size="small"
              variant="outlined"
              color="primary"
              fullWidth
            >
              View More
            </Button>
          </Box>
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography>Tasks:</Typography>
        {loading ? (
          <Box className={classes.form}>
            <CircularProgress thickness={2} size={75} />
          </Box>
        ) : (
          <Box>
            {tasks.length < 1 ? (
              <Box
                className={classes.flexBoxCentered}
                style={{ flexDirection: "column", padding: 10 }}
              >
                <AssignmentIcon color="disabled" fontSize="large" />
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                >
                  No tasks asigned.
                </Typography>
              </Box>
            ) : (
              tasks.map((task, ind) => (
                <TaskDisplay
                  key={ind}
                  task={task}
                  ind={ind}
                  small
                  hideDeleteButton
                  showAction
                  isFamily={isFamily}
                  onUpdateTaskStatus={props.onUpdateTaskStatus}
                  canUpdate={shift.status.isCheckedIn}
                />
              ))
            )}
            {allowReload && (
              <div className={classes.flexBoxCentered}>
                <Button
                  size="small"
                  onClick={() => props.onReloadTasks()}
                  color="primary"
                  style={{ marginTop: 16 }}
                >
                  Reload
                </Button>
              </div>
            )}
          </Box>
        )}
        <Hidden smUp>
          <Box className={classes.flexBoxCentered}>
            <Button
              component={Link}
              to={`/myteam/${shift.memberId}/shift/${shift.id}`}
              className={classes.txtTrButton}
              size="small"
              variant="outlined"
              color="primary"
              fullWidth
            >
              View More
            </Button>
          </Box>
        </Hidden>
      </Grid>
    </Grid>
  );
}

DetailsTasksDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  shift: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  allowReload: PropTypes.bool.isRequired,
  onReloadTasks: PropTypes.func.isRequired,
  isFamily: PropTypes.bool,
  onUpdateTaskStatus: PropTypes.func,
};

export default withStyles(styles)(DetailsTasksDisplay);
