import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import { capitalizeFirstChar, splitByCapital } from "../../util/utilFunctions";
import Icons from "../../assets/icons/iconsMain";

import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";
import SvgIcon from "@mui/material/SvgIcon";

const styles = (theme) => ({
  ...theme.spreadThis,
});
export class ChipArrayDisplay extends Component {
  render() {
    const {
      classes,
      dataArray,
      icon,
      className,
      color,
      variant,
      serviceIcons,
    } = this.props;
    return dataArray.map((key) => {
      return (
        <Chip
          label={capitalizeFirstChar(splitByCapital(key))}
          className={className ? className : classes.chip}
          icon={
            serviceIcons ? (
              <SvgIcon>
                <path d={Icons[key]} />
              </SvgIcon>
            ) : icon ? (
              icon
            ) : null
          }
          size="small"
          variant={variant ? variant : "filled"}
          color={color ? color : "default"}
          key={key}
          style={{ marginLeft: 0 }}
        />
      );
    });
  }
}

ChipArrayDisplay.propTypes = {
  dataArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChipArrayDisplay);
