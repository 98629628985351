import geohash from "ngeohash";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
dayjs.extend(dayOfYear);

const splitUniqueDates = (array) => {
  const uniqueDate = {};

  array.forEach((date) => {
    const _dayOfYear = dayjs(date.startTime).dayOfYear();
    if (!uniqueDate.hasOwnProperty(_dayOfYear)) {
      return (uniqueDate[_dayOfYear] = [date]);
    }
    return uniqueDate[_dayOfYear].push(date);
  });
  return uniqueDate;
};

const getDailyWage = (shifts) => {
  let isOt = false,
    isOt2 = false,
    dailyHours = 0,
    dailyOperatingIncome = 0,
    dailyGrossIncome = 0,
    dailyRegularIncome = 0,
    dailyOtIncome = 0,
    dailyVacation = 0;
  let regularHours = 0;
  let ot1Hours = 0;
  let ot2Hours = 0;
  let isHoliday = false;
  let wage = 0;

  shifts.forEach((shift) => {
    const shiftLength = dayjs(shift.endTime).diff(shift.startTime, "h", true);

    wage = parseFloat(shift.wage) / 1.04;

    let shiftOtIncome = 0,
      shiftRegularIncome = 0;

    // isHoliday = isHoliday(shift.endTime)
    // isHoliday = true;
    if (isHoliday) isOt = true;

    if (isOt2) {
      shiftOtIncome = wage * 2 * shiftLength;
      ot2Hours += shiftLength;
    } else if (isOt) {
      const shiftOtHours = shiftLength;
      if (shiftOtHours + dailyHours > 12) {
        shiftOtIncome =
          wage * 1.5 * (12 - dailyHours) +
          wage * 2 * (shiftOtHours - (12 - dailyHours));
        ot2Hours += shiftOtHours - (12 - dailyHours);
        ot1Hours += 12 - dailyHours;
      } else {
        shiftOtIncome = wage * 1.5 * shiftOtHours;
        ot1Hours += shiftOtHours;
      }
    } else if (shiftLength + dailyHours > 12) {
      const shiftOtHours = shiftLength + dailyHours - 8;
      const shiftRegularHours = shiftLength - shiftOtHours;

      shiftOtIncome = wage * 1.5 * 4 + wage * 2 * (shiftOtHours - 4);
      ot2Hours += shiftOtHours - 4;

      ot1Hours += 4;

      shiftRegularIncome = wage * shiftRegularHours;
      regularHours += shiftRegularHours;
    } else if (shiftLength + dailyHours > 8) {
      const shiftOtHours = shiftLength + dailyHours - 8;
      const shiftRegularHours = shiftLength - shiftOtHours;

      shiftOtIncome = wage * 1.5 * shiftOtHours;
      ot1Hours += shiftOtHours;

      shiftRegularIncome = wage * shiftRegularHours;
      regularHours += shiftRegularHours;
    } else {
      shiftRegularIncome = wage * shiftLength;
      regularHours += shiftLength;
    }

    const grossIncome = shiftOtIncome + shiftRegularIncome;

    dailyVacation += grossIncome * 0.04;
    dailyOperatingIncome += grossIncome;
    dailyRegularIncome += shiftRegularIncome;
    dailyOtIncome += shiftOtIncome;
    dailyHours += shiftLength;
    if (dailyHours > 12) isOt2 = true;
    if (dailyHours > 8) isOt = true;
  });

  const dailyOtHours = ot1Hours + ot2Hours;
  dailyGrossIncome = dailyOperatingIncome + dailyVacation;

  return {
    dailyHours,
    dailyOtHours,
    dailyOperatingIncome,
    dailyGrossIncome,
    dailyRegularIncome,
    dailyOtIncome,
    dailyVacation,
    isOt,
    wage,
    regularHours,
    ot1Hours,
    ot2Hours,
    isHoliday,
  };
};

const calcSortedShiftsIncome = (dateObj) => {
  let totalHours = 0,
    totalOtHours = 0,
    grossIncome = 0,
    totalRegularIncome = 0,
    totalOtIncome = 0,
    totalVacation = 0,
    totalOperatingIncome = 0;

  Object.keys(dateObj).forEach((date) => {
    const {
      dailyHours,
      dailyOtHours,
      dailyGrossIncome,
      dailyOperatingIncome,
      dailyRegularIncome,
      dailyOtIncome,
      dailyVacation,
    } = getDailyWage(dateObj[date]);
    totalHours += dailyHours;
    totalOtHours += dailyOtHours;
    grossIncome += dailyGrossIncome;
    totalOperatingIncome += dailyOperatingIncome;
    totalRegularIncome += dailyRegularIncome;
    totalOtIncome += dailyOtIncome;
    totalVacation += dailyVacation;
  });
  return {
    totalHours,
    totalOtHours,
    totalRegularIncome,
    totalOtIncome,
    totalOperatingIncome,
    totalVacation,
    grossIncome,
  };
};

const calcUnsortedShiftsIncome = (unsortedShifts) => {
  const sortedShifts = splitUniqueDates(unsortedShifts);
  return calcSortedShiftsIncome(sortedShifts);
};

const isEmpty = (string) => {
  if (typeof string !== "string") return true;
  return string.trim() === "";
};

const toPhoneNum = (phoneNum) => {
  return parseInt(phoneNum.replace(/[^0-9]/g, ""), 10).toString();
};

const isPhoneNum = (phoneNum) => {
  let _phoneNum = phoneNum;
  if (typeof phoneNum === "number") _phoneNum = _phoneNum.toString();
  if (typeof _phoneNum !== "string") return false;
  const phoneNumRegEx =
    // eslint-disable-next-line
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (_phoneNum.match(phoneNumRegEx)) return true;
  else return false;
};

const phoneNumDisplay = (phoneNum) => {
  return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(
    6
  )}`;
};

const splitVarInArrayToLowerCase = (array) => {
  return array.map((key) =>
    key
      .split(/(?=[A-Z])/)
      .join(" ")
      .toLowerCase()
  );
};

const makeCapitalArrayToString = (array) => {
  const _array = splitVarInArrayToLowerCase(array);
  return _array.map((key) => capitalizeFirstChar(key)).join(", ");
};

const splitVarInArrayToUpperCase = (array) => {
  return array.map((key) =>
    key
      .split(/(?=[A-Z])/)
      .join(" ")
      .toUpperCase()
  );
};

const objectFlip = (obj) => {
  return Object.keys(obj).reduce((ret, key) => {
    ret[obj[key]] = key;
    return ret;
  }, {});
};

const arrayHasSameElements = (array1, array2) => {
  return array1.every((ele) => array2.includes(ele));
};

const arraySameLength = (array1, array2) => {
  return array1.length === array2.length;
};
const isSameArray = (array1, array2) => {
  return (
    arrayHasSameElements(array1, array2) && arraySameLength(array1, array2)
  );
};

const capitalizeFirstChar = (string) => {
  if (typeof string === "string" && string.trim() !== "") {
    return string[0].toUpperCase() + string.slice(1);
  }
  return null;
};

const splitByCapital = (string) => {
  if (typeof string === "string") return string.split(/(?=[A-Z])/).join(" ");
  return string;
};

const lowerFirstChar = (string) => {
  if (typeof string === "string" && string.trim() !== "") {
    return string[0].toLowerCase() + string.slice(1);
  }
  return null;
};

const sameKeysInObj = (obj1, obj2) => {
  let res = {};
  let total = Object.keys(obj1).length;
  let same = 0;
  Object.keys(obj1).forEach((key) => {
    if (Object.keys(obj2).includes(key)) {
      same += 1;
    }
  });
  res.same = same;
  res.total = total;
  return res;
};

const sameEleInArray = (array1, array2) => {
  let res = {};
  let total = array1.length;
  let same = 0;
  array1.forEach((ele) => {
    if (array2.includes(ele)) same += 1;
  });
  res.same = same;
  res.total = total;
  return res;
};

const getDistanceLatLng = (lat1, lon1, lat2, lon2) => {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

const getDistanceGeohash = (geo1, geo2) => {
  const anchor = geohash.decode(geo1);
  const newLocation = geohash.decode(geo2);

  return getDistanceLatLng(
    anchor.latitude,
    anchor.longitude,
    newLocation.latitude,
    newLocation.longitude
  );
};

const getLocalTime = (date) => {
  if (isNaN(Date.parse(date))) {
    return date;
  } else {
    let time = new Date(date);
    let zone = time.getTimezoneOffset() * 60 * 1000;
    let tLocal = time - zone;
    tLocal = new Date(tLocal);
    let iso = tLocal.toISOString();
    iso = iso.slice(11, 16);
    return iso;
  }
};

const splitAddressFull = (string) => {
  let resString = string;
  resString = resString.split("AB")[0];
  resString = resString.split("BC")[0];
  resString = resString.split("SK")[0];
  resString = resString.split("MB")[0];
  resString = resString.split("ON")[0];
  resString = resString.split("QC")[0];
  resString = resString.split("NB")[0];
  resString = resString.split("NS")[0];
  resString = resString.split("NL")[0];
  resString = resString.split("PE")[0];
  resString = resString.slice(0, resString.length - 2);
  return resString;
};

const numberWithCommas = (numStr) => {
  let num = (Math.round(numStr * 100) / 100).toFixed(2);
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const isEmail = (email) => {
  const emailRegEx =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(emailRegEx)) return true;
  else return false;
};

const allFalse = (obj) => {
  const keys = Object.keys(obj);
  return keys.every((key) => !Boolean(obj[key]));
};

const allFalseExcept = (obj, arr) => {
  let exceptions = arr;
  let keys = Object.keys(obj);

  let checker = true;
  for (let key = 0; key < keys.length; key++) {
    if (exceptions.length > 0) {
      if (exceptions.indexOf(keys[key]) > -1) {
        return;
      } else {
        if (obj[keys[key]]) return (checker = false);
      }
    } else {
      if (obj[keys[key]]) return (checker = false);
    }
  }

  return checker;
};

// Password validator

const checkPasswordCapital = (password) => {
  let pwdRegEx = /(?=.*[A-Z])/;
  if (password.match(pwdRegEx)) return true;
  else return false;
};

const checkPasswordLower = (password) => {
  let pwdRegEx = /(?=.*[a-z])/;
  if (password.match(pwdRegEx)) return true;
  else return false;
};

const checkPasswordNumber = (password) => {
  let pwdRegEx = /(?=.*\d)/;
  if (password.match(pwdRegEx)) return true;
  else return false;
};

const checkPasswordLength = (password) => {
  let pwdRegEx = /[0-9a-zA-Z]{8,}/;
  if (password.match(pwdRegEx)) return true;
  else return false;
};

const checkAll = (password) => {
  const isCapital = checkPasswordCapital(password);
  const isLower = checkPasswordLower(password);
  const isNumber = checkPasswordNumber(password);
  const isLength = checkPasswordLength(password);
  let allCheck = isCapital && isLower && isNumber && isLength;
  return { isCapital, isLower, isNumber, isLength, allCheck };
};

const setNotifURL = (notifClass, refId) => {
  let notifUrl = "/";
  switch (notifClass) {
    case "Job Application":
      notifUrl = `/jobs/applications/${refId}`;
      break;
    case "Invite":
      notifUrl = `/invites/${refId}`;
      break;
    case "Shift":
      notifUrl = `/myteam/${refId}`;
      break;
    case "Invoice":
      notifUrl = `/mypay/invoice/${refId}`;
      break;
    case "Report":
      notifUrl = `/reports/${refId}`;
      break;
    case "Review":
      notifUrl = `/reviews/${refId}`;
      break;
    default:
      break;
  }
  return notifUrl;
};

// Stripe format

// Format amount for diplay in the UI
function formatAmount(amount, currency) {
  amount = zeroDecimalCurrency(amount, currency)
    ? amount
    : (amount / 100).toFixed(2);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(amount);
}

// // Format amount for Stripe
// function formatAmountForStripe(amount, currency) {
//   return zeroDecimalCurrency(amount, currency)
//     ? amount
//     : Math.round(amount * 100);
// }

// Check if we have a zero decimal currency
// https://stripe.com/docs/currencies#zero-decimal
function zeroDecimalCurrency(amount, currency) {
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false;
    }
  }
  return zeroDecimalCurrency;
}

// export function setData(invoicesArr, labels, labelLegend, userType) {
//   let dataSet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
//   invoicesArr.forEach((invoice) => {
//     let month = dayjs(invoice.createdAt).get("M");
//     let monthPos = labels.findIndex((mo) => mo === labelLegend[month]);
//     let currSum = dataSet[monthPos];
//     let newSum = 0;
//     if (userType === "caregiver") {
//       newSum = currSum + invoice.netIncome;
//     } else if (userType === "family") {
//       newSum = currSum + invoice.payableBreakdown.totalPayable;
//     }
//     dataSet[monthPos] = Math.round(newSum * 100) / 100;
//   });

//   return dataSet;
// }

export {
  splitUniqueDates,
  calcUnsortedShiftsIncome,
  arraySameLength,
  arrayHasSameElements,
  isEmpty,
  toPhoneNum,
  isPhoneNum,
  phoneNumDisplay,
  splitVarInArrayToLowerCase,
  splitVarInArrayToUpperCase,
  makeCapitalArrayToString,
  objectFlip,
  isSameArray,
  capitalizeFirstChar,
  splitByCapital,
  lowerFirstChar,
  sameKeysInObj,
  sameEleInArray,
  getDistanceGeohash,
  getLocalTime,
  splitAddressFull,
  numberWithCommas,
  isEmail,
  allFalse,
  allFalseExcept,
  checkAll,
  setNotifURL,
  formatAmount,
};
