import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import LocationSearch from "../../../../components/inputs/LocationSearch";
import { isPhoneNum, allFalse } from "../../../../util/utilFunctions";
import Stepper from "../../../../components/layout/Components/Stepper";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class SFBasicInfo extends Component {
  constructor() {
    super();
    this.state = {
      errors: {
        firstName: null,
        lastName: null,
        addressFull: null,
        phoneNum: null,
      },
      firstName: "",
      lastName: "",
      phoneNum: "",
      address: {
        addressFull: "",
        addressParams: {
          unit: "",
          streetNumber: "",
          streetRoute: "",
          locality: "",
          adminAreaLevel1: "",
          country: "",
          postalCode: "",
        },
        coordinates: {},
        geohash: "",
      },

      addressSetInitial: false,
    };
  }

  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    const { firstName, lastName, phoneNum, address } = this.props;
    let obj = {};
    if (firstName !== "") obj.firstName = firstName;
    if (lastName !== "") obj.lastName = lastName;
    if (phoneNum !== "") obj.phoneNum = phoneNum;
    if (address.addressFull !== "") {
      obj.address = address;
      obj.addressSetInitial = true;
    }

    this.setState({ ...obj });
  }

  // Error validation method

  validateSubmit = () => {
    const {
      errors,
      firstName,
      lastName,
      phoneNum,
      address: { addressFull },
    } = this.state;
    let _errors = errors;
    if (firstName.trim() === "") _errors.firstName = "Must not be empty";
    if (lastName.trim() === "") _errors.lastName = "Must not be empty";
    if (phoneNum.trim() === "") {
      _errors.phoneNum = "Must not be empty";
    } else if (!isPhoneNum(phoneNum)) {
      _errors.phoneNum = "Please enter a valid phone number";
    }
    if (addressFull === "") _errors.addressFull = true;

    this.setState({ errors: _errors });
  };

  // Action Methods

  handleName = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: value.trim() === "" ? "Must not be empty" : null,
      },
    });
  };

  handlePhoneNumber = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      this.setState({
        phoneNum: onlyNums,
        errors: {
          ...this.state.errors,
          phoneNum: onlyNums === "" ? "Must not be empty" : null,
        },
      });
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      this.setState({
        phoneNum: number,
        errors: { ...this.state.errors, phoneNum: null },
      });
    }
  };

  handleAddress = (value) => {
    this.setState({
      address: value,
      errors: { ...this.state.errors, addressFull: false },
    });
  };

  handleSubmit = async () => {
    await this.validateSubmit();
    const { errors, firstName, lastName, phoneNum, address } = this.state;
    if (allFalse(errors)) {
      this.props.onSelectBasicInfo({
        firstName,
        lastName,
        phoneNum,
        address,
      });
    }
  };

  handleBack = () => {
    this.props.onSelectBasicInfo({
      pages: { p3: true, p4: false },
    });
  };

  render() {
    const { classes, total, fadeIn } = this.props;
    const {
      errors,
      firstName,
      lastName,
      phoneNum,
      address,
      addressSetInitial,
    } = this.state;

    return (
      <Box p={2} className={classes.form}>
        <Stepper total={total} fadeIn={fadeIn} />

        <Typography
          variant="h6"
          color="primary"
          align="center"
          style={{ margin: "10px 0px", fontWeight: 300, marginTop: 26 }}
          className={classes.typography}
        >
          Basic Info
        </Typography>

        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} sm={10}>
            <Typography
              variant="body2"
              align="left"
              style={{ fontWeight: 300 }}
            >
              Enter your details below as the main point of contact. You'll
              receive important Poyo Health announcements as well as
              notifications whenever a caregiver messages you.
            </Typography>
          </Grid>

          <Grid item xs={6} sm={5}>
            <TextField
              id="firstName"
              name="firstName"
              type="firstName"
              label="First Name"
              variant="outlined"
              value={firstName}
              error={Boolean(errors.firstName)}
              helperText={Boolean(errors.firstName) ? errors.firstName : ""}
              onChange={this.handleName}
              className={
                !Boolean(errors.firstName)
                  ? classes.styledTextField
                  : classes.styledTextFieldError
              }
              fullWidth
              size="medium"
            />
          </Grid>
          <Grid item xs={6} sm={5}>
            <TextField
              id="lastName"
              name="lastName"
              type="lastName"
              label="Last Name"
              variant="outlined"
              value={lastName}
              error={Boolean(errors.lastName)}
              helperText={Boolean(errors.lastName) ? errors.lastName : ""}
              className={
                !Boolean(errors.lastName)
                  ? classes.styledTextField
                  : classes.styledTextFieldError
              }
              onChange={this.handleName}
              fullWidth
              size="medium"
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextField
              id="phoneNum"
              name="phoneNum"
              type="tel"
              label="Phone Number"
              variant="outlined"
              error={Boolean(errors.phoneNum)}
              helperText={Boolean(errors.phoneNum) ? errors.phoneNum : ""}
              className={
                !Boolean(errors.phoneNum)
                  ? classes.styledTextField
                  : classes.styledTextFieldError
              }
              onChange={this.handlePhoneNumber}
              fullWidth
              value={phoneNum}
              size="medium"
              style={{ marginTop: 0 }}
            />

            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              align="left"
              style={{ fontWeight: 300, fontStyle: "italic", marginBottom: 16 }}
            >
              Provide a phone number for emergency contact purposes. Your
              contact information will remain private and will only be shared
              with hired caregivers as needed.
            </Typography>

            <LocationSearch
              onSelectAddress={this.handleAddress}
              errorAddress={Boolean(errors.addressFull)}
              medium
              city
              showOneResult
              label="City"
              address={address.addressFull !== "" ? address : null}
              addressSetInitial={addressSetInitial}
            />
          </Grid>
        </Grid>

        <Box className={classes.flexBoxEnd} style={{ marginTop: 16 }}>
          <Button
            color="primary"
            onClick={this.handleBack}
            style={{
              marginRight: 10,
              width: 100,
              backgroundColor: "white",
              textTransform: "none",
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{ width: 100, textTransform: "none" }}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  }
}

SFBasicInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  fadeIn: PropTypes.number.isRequired,
};
export default withStyles(styles)(SFBasicInfo);
