import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

// Component imports
import DropdownButton from "../../../../components/layout/DropdownButton";
import {
  capitalizeFirstChar,
  objectFlip,
  isEmpty,
} from "../../../../util/utilFunctions";
import StarDisplay from "../../../../components/misc/StarDisplay";

import withStyles from "@mui/styles/withStyles";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";

import ClearAllIcon from "@mui/icons-material/ClearAll";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function sideScroll(element, direction, speed, distance, step) {
  let scrollAmount = 0;
  var slideTimer = setInterval(function () {
    if (direction === "left") {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
}

export class SearchFilters extends Component {
  componentDidMount() {
    this.setHorizontalScrollFilterBox();
  }

  setHorizontalScrollFilterBox() {
    let scrollSpeed = 20;

    let scroller = document.getElementById("filter_box");
    scroller.addEventListener(
      "mousewheel",
      (e) => {
        // block if e.deltaY==0
        if (!e.deltaY) return;
        // Set scrollDirection (-1 = up // 1 = down)
        let scrollDirection = e.deltaY > 0 ? 1 : -1;
        // convert vertical scroll into horizontal
        scroller.scrollLeft += scrollSpeed * scrollDirection;
        let scrollLeft = Math.round(scroller.scrollLeft);
        // calculate box total vertical scroll
        let maxScrollLeft = Math.round(
          scroller.scrollWidth - scroller.clientWidth
        );
        // if element scroll has not finished scrolling
        // prevent window to scroll
        if (
          (scrollDirection === -1 && scrollLeft > 0) ||
          (scrollDirection === 1 && scrollLeft < maxScrollLeft)
        )
          e.preventDefault();
        // done!
        return true;
      },
      false
    );
  }
  handleSelect = (name, value) => {
    this.props.handleSelect(name, value);
  };

  handleSelectService = (name, value) => {
    this.props.handleSelectService(value);
  };
  handleSelectConditions = (name, value) => {
    this.props.handleSelectConditions(value);
  };

  clearFilters = () => {
    this.props.clearFilters();
  };

  handleLeftScroll = () => {
    let scrollerLeft = document.getElementById("filter_box");
    sideScroll(scrollerLeft, "left", 25, 100, 10);
  };
  handleRightScroll = () => {
    let scrollerRight = document.getElementById("filter_box");
    sideScroll(scrollerRight, "right", 25, 100, 10);
  };

  handleLeftScrollSlow = () => {
    if (isMobile) {
      return false;
    } else {
      let scroller = document.getElementById("filter_box");

      const refreshIntervalId = setInterval(() => {
        scroller.scrollLeft -= 10;
      }, 100);
      this.setState({ refreshIntervalId });
    }
  };
  handleRightScrollSlow = () => {
    if (isMobile) {
      return false;
    } else {
      let scroller = document.getElementById("filter_box");
      const refreshIntervalId = setInterval(() => {
        scroller.scrollLeft += 10;
      }, 100);
      this.setState({ refreshIntervalId });
    }
  };

  handleRemoveAutoScroll = () => {
    if (isMobile) {
      return false;
    } else {
      clearInterval(this.state.refreshIntervalId);
    }
  };

  render() {
    const {
      classes,
      gender,
      jobType,
      rating,
      languagePref,
      wageExp,
      service,
      yearsExp,
      conditions,
      isLoadedFilters,
      filterMaterials,
    } = this.props;

    const wageObj = {
      "$15 - $20": "15u20d",
      "$20 - $30": "20u30d",
      "$30 - $40": "30u40d",
      "$40+": "40u100d",
      Any: "",
    };
    return (
      <Box
        className={classes.flexBoxSpaced}
        style={{ position: "relative", width: "100%" }}
      >
        <Box
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1000,

            background:
              "linear-gradient(90deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0) 65%)",
          }}
        >
          <IconButton
            size="small"
            onClick={this.handleLeftScroll}
            // onMouseEnter={this.handleLeftScrollSlow}
            // onMouseLeave={this.handleRemoveAutoScroll}
            style={
              {
                // backgroundColor: "red",
              }
            }
          >
            <ArrowLeftIcon />
          </IconButton>
        </Box>
        <Box
          id="filter_box"
          style={{
            overflowX: "scroll",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            flex: 1,
            position: "relative",
          }}
          className="hideScroll"
          // onWheel={this.onWheel}
        >
          {!isLoadedFilters ? (
            <LinearProgress />
          ) : (
            <Fragment>
              <DropdownButton
                radio
                xs={12}
                width={150}
                btnVariant="outlined"
                header="Expected Wage"
                color="inherit"
                btnText={
                  <Typography
                    component="div"
                    fontWeight={!isEmpty(wageExp) ? "bold" : "normal"}
                    variant="body2"
                  >
                    Wage{" "}
                    {!isEmpty(wageExp) && (
                      <Box display="inline" style={{ fontWeight: 550 }}>
                        {"("}
                        {Object.keys(wageObj).find(
                          (key) => wageObj[key] === wageExp
                        )}
                        {")"}
                      </Box>
                    )}
                  </Typography>
                }
                closeOnSelect
                value={wageExp}
                selectionObj={wageObj}
                onSelect={this.handleSelect}
                name="wageExp"
                className={classes.filterButton}
                style={{ marginLeft: 35 }}
              />
              <DropdownButton
                radio
                xs={12}
                width={150}
                closeOnSelect
                header="Gender"
                btnVariant="outlined"
                color="inherit"
                btnText={
                  <Typography
                    component="div"
                    fontWeight={!isEmpty(gender) ? "bold" : "normal"}
                    variant="body2"
                  >
                    Gender{" "}
                    {!isEmpty(gender) && (
                      <Box display="inline" style={{ fontWeight: 550 }}>
                        {"("}
                        {capitalizeFirstChar(gender)}
                        {")"}
                      </Box>
                    )}
                  </Typography>
                }
                selectionObj={{ Male: "male", Female: "female", Any: "" }}
                onSelect={this.handleSelect}
                name="gender"
                value={gender}
                className={classes.filterButton}
              />
              <DropdownButton
                radio
                closeOnSelect
                btnVariant="outlined"
                header="Language"
                color="inherit"
                btnText={
                  <Typography
                    component="div"
                    fontWeight={!isEmpty(languagePref) ? "bold" : "normal"}
                    variant="body2"
                  >
                    Language{" "}
                    {!isEmpty(languagePref) && (
                      <Box display="inline" style={{ fontWeight: 550 }}>
                        {"("}
                        {languagePref.toUpperCase()}
                        {")"}
                      </Box>
                    )}
                  </Typography>
                }
                selectionObj={{ ...filterMaterials.language, Any: "" }}
                onSelect={this.handleSelect}
                name="languagePref"
                value={languagePref}
                className={classes.filterButton}
              />
              <DropdownButton
                check
                btnVariant="outlined"
                header="Services Rendered"
                color="inherit"
                btnText={
                  <Typography
                    component="div"
                    fontWeight={service.length > 0 ? "bold" : "normal"}
                    variant="body2"
                  >
                    Services{" "}
                    {service.length > 0 && (
                      <Box display="inline" style={{ fontWeight: 550 }}>
                        {"("}
                        {service.length}
                        {")"}
                      </Box>
                    )}
                  </Typography>
                }
                selectionObj={filterMaterials.service}
                onSelect={this.handleSelectService}
                name="service"
                value={service}
                className={classes.filterButton}
              />
              <DropdownButton
                check
                btnVariant="outlined"
                header="Secialized Conditions"
                color="inherit"
                btnText={
                  <Typography
                    component="div"
                    fontWeight={conditions.length > 0 ? "bold" : "normal"}
                    variant="body2"
                  >
                    Conditions{" "}
                    {conditions.length > 0 && (
                      <Box display="inline" style={{ fontWeight: 550 }}>
                        {"("}
                        {conditions.length}
                        {")"}
                      </Box>
                    )}
                  </Typography>
                }
                selectionObj={filterMaterials.conditions}
                onSelect={this.handleSelectConditions}
                name="conditions"
                value={conditions}
                className={classes.filterButton}
              />
              <DropdownButton
                radio
                closeOnSelect
                header="Job Types"
                btnVariant="outlined"
                color="inherit"
                btnText={
                  <Typography
                    component="div"
                    fontWeight={!isEmpty(jobType) ? "bold" : "normal"}
                    variant="body2"
                  >
                    Job Type{" "}
                    {!isEmpty(jobType) && (
                      <Box display="inline" style={{ fontWeight: 550 }}>
                        {"("}
                        {capitalizeFirstChar(filterMaterials.jobType[jobType])}
                        {")"}
                      </Box>
                    )}
                  </Typography>
                }
                selectionObj={{
                  ...objectFlip(filterMaterials.jobType),
                  Any: "",
                }}
                onSelect={this.handleSelect}
                name="jobType"
                value={jobType}
                className={classes.filterButton}
              />
              <DropdownButton
                radio
                closeOnSelect
                header="Years of Experience"
                btnVariant="outlined"
                color="inherit"
                xs={12}
                width={200}
                btnText={
                  <Typography
                    component="div"
                    fontWeight={!isEmpty(yearsExp) ? "bold" : "normal"}
                    variant="body2"
                  >
                    Experience{" "}
                    {!isEmpty(yearsExp) && (
                      <Box display="inline" style={{ fontWeight: 550 }}>
                        {"(>"} {yearsExp} yrs
                        {")"}
                      </Box>
                    )}
                  </Typography>
                }
                selectionObj={{
                  "Over 3 years": 3,
                  "Over 5 years": 5,
                  "Over 7 years": 7,
                  "Over 10 years": 10,
                  Any: "",
                }}
                onSelect={this.handleSelect}
                name="yearsExp"
                value={yearsExp}
                className={classes.filterButton}
              />
              <DropdownButton
                radio
                closeOnSelect
                btnVariant="outlined"
                color="inherit"
                width={200}
                header="Family Reviews"
                xs={12}
                btnText={
                  <div className={classes.flexBox}>
                    <Typography
                      variant="body2"
                      fontWeight={rating > 0 ? "bold" : "normal"}
                      style={{ marginRight: 4 }}
                    >
                      Rating
                    </Typography>
                    {rating > 0 && (
                      <Box className={classes.flexBox}>
                        <Typography variant="body2">{"("}</Typography>
                        {<StarDisplay max={5} numStar={parseInt(rating)} />}
                        <Typography variant="body2">{")"}</Typography>
                      </Box>
                    )}
                  </div>
                }
                selectionObj={{
                  1: 1,
                  2: 2,
                  3: 3,
                  4: 4,
                  5: 5,
                  6: 0,
                }}
                onSelect={this.handleSelect}
                name="rating"
                value={rating}
                className={classes.filterButton}
                style={{ marginRight: 43 }}
              />
            </Fragment>
          )}
        </Box>
        <Box
          style={{
            position: "absolute",
            right: 43,
            top: 0,
            zIndex: 1000,

            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 35%, rgba(255,255,255,0.85) 100%)",
          }}
        >
          <IconButton
            size="small"
            onClick={this.handleRightScroll}
            // onMouseEnter={this.handleRightScrollSlow}
            // onMouseLeave={this.handleRemoveAutoScroll}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
        <Box className={classes.flexBox}>
          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "0px 4px" }}
          />
          <IconButton
            size="small"
            // color="secondary"
            onClick={this.clearFilters}
            // onMouseEnter={this.handleRightScrollSlow}
            // onMouseLeave={this.handleRemoveAutoScroll}
          >
            <ClearAllIcon />
          </IconButton>
        </Box>
      </Box>
    );
  }
}

SearchFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  gender: PropTypes.string.isRequired,
  wageExp: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired,
  service: PropTypes.array.isRequired,
  conditions: PropTypes.array.isRequired,
  languagePref: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSelectService: PropTypes.func.isRequired,
  handleSelectConditions: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filterMaterials: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchFilters);
