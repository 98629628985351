import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

// Component imports
import { phoneNumDisplay } from "../../util/utilFunctions";
import EditMyInfo from "../../pages/caregiverPages/profile/about/EditMyInfo";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class MyInfoBlock extends Component {
  render() {
    const { classes, credentials } = this.props;
    return (
      <Paper elevation={4} className={classes.darkGrayBorder}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h6"
            style={{ fontWeight: 300 }}
            className={classes.typography}
          >
            My Info
          </Typography>
          <EditMyInfo />
        </Box>

        <Typography
          variant="body2"
          className={classes.typography}
          component="div"
          align="left"
          style={{ fontWeight: 300, marginTop: 16 }}
        >
          <Box style={{ fontWeight: 500 }} display="inline">
            Poyo ID Number:{" "}
          </Box>
          {credentials.userIdNumber}
        </Typography>
        <Typography
          variant="body2"
          align="left"
          className={classes.typography}
          component="div"
          style={{ fontWeight: 300 }}
        >
          <Box style={{ fontWeight: 500 }} display="inline">
            Email:{" "}
          </Box>
          {credentials.email}
        </Typography>

        <Typography
          variant="body2"
          align="left"
          className={classes.typography}
          component="div"
          style={{ fontWeight: 300 }}
        >
          <Box style={{ fontWeight: 500 }} display="inline">
            Phone:{" "}
          </Box>
          {phoneNumDisplay(credentials.phoneNum)}
        </Typography>
        {Boolean(credentials.dob) && credentials.dob !== "" && (
          <Typography
            variant="body2"
            align="left"
            className={classes.typography}
            component="div"
            style={{ fontWeight: 300 }}
          >
            <Box style={{ fontWeight: 500 }} display="inline">
              Date of Birth:{" "}
            </Box>
            {dayjs(credentials.dob).format("MMM DD, YYYY")}
          </Typography>
        )}
      </Paper>
    );
  }
}

MyInfoBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyInfoBlock);
