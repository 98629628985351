import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router";

// Component Imports
import Invoice from "../../../components/pay/invoice/Invoice";
import History from "../../../components/pay/history/History";
import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class CarePay extends Component {
  state = { tabValue: 0 };

  componentDidMount() {
    const { pathname } = this.props.history.location;
    // if (pathname.includes("invoice")) {
    //   this.setState({ tabValue: 1 });
    // }
    if (pathname.includes("history")) {
      this.setState({ tabValue: 1 });
    }
  }

  handleSetTabValue = (e, v) => {
    this.setState({ tabValue: v });
    this.props.history.replace({
      pathname: v === 0 ? "/mypay/invoice" : "/mypay/history",
    });
  };

  // sendToInvoice = () => {
  //   this.setState({ tabValue: 1 });
  // };
  // sendToHistory = () => {
  //   this.setState({ tabValue: 2 });
  // };
  render() {
    const { credentials } = this.props;
    const { tabValue } = this.state;

    return (
      <Grid container>
        <Typography
          color="textSecondary"
          variant="h4"
          style={{ fontWeight: 300 }}
        >
          My Pay
        </Typography>
        <Grid item xs={12} style={{ marginTop: 60 }}>
          <Paper
            elevation={4}
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: "rgba(212,212,212,0.2)",
              borderTop: "1px solid rgb(212,212,212)",
              borderRight: "1px solid rgb(212,212,212)",
              borderLeft: "1px solid rgb(212,212,212)",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={this.handleSetTabValue}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {/* <Tab label="Timesheet" /> */}
              <Tab label="Current Invoice" />
              <Tab label="Pay History" />
            </Tabs>
          </Paper>
          {tabValue === 0 ? (
            //   <TimeSheet
            //     sendToInvoice={this.sendToInvoice}
            //     sendToHistory={this.sendToHistory}
            //   />
            // ) : tabValue === 1 ? (
            <Invoice credentials={credentials} />
          ) : (
            <History credentials={credentials} />
          )}
        </Grid>
      </Grid>
    );
  }
}
CarePay.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};
export default compose(withRouter, withStyles(styles))(CarePay);
