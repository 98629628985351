import React, { Component } from "react";
import PropTypes from "prop-types";

// Component Imports

import { capitalizeFirstChar } from "../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LanguageIcon from "@mui/icons-material/Language";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import WorkIcon from "@mui/icons-material/Work";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

export class JobTypeLanguageMarkup extends Component {
  render() {
    const {
      classes,

      jobTypeObj,
      jobTypeArray,
      errorJobType,
      languagePref,
      languagePrefArray,
      errorLanguagePref,
    } = this.props;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="primary"
            style={{ margin: "10px 0px", fontWeight: 300 }}
            className={classes.typography}
          >
            Job Types
          </Typography>
          <Box className={classes.sectionBoxLeftOutline}>
            <Typography
              variant="body2"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              What types of jobs are you seeking?
            </Typography>

            {errorJobType && (
              <Typography
                variant="caption"
                style={{ color: "#f44336", fontWeight: 300 }}
              >
                {errorJobType}
              </Typography>
            )}

            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              {Object.entries(jobTypeObj)
                .sort()
                .map((jobType) => (
                  <Grid item key={jobType}>
                    <StyledButton
                      size="large"
                      name={jobType[0]}
                      onClick={(e) => this.props.handleJobType(e)}
                      className={
                        Boolean(errorJobType)
                          ? jobTypeArray.includes(jobType[0])
                            ? classes.errorSelectedToggleButton
                            : classes.errorUnselectedToggleButton
                          : jobTypeArray.includes(jobType[0])
                          ? classes.selectedToggleButton
                          : classes.unselectedToggleButton
                      }
                      startIcon={
                        jobTypeArray.includes(jobType[0]) ? (
                          <WorkIcon />
                        ) : (
                          <WorkOutlineOutlinedIcon />
                        )
                      }
                    >
                      <Typography
                        variant="body2"
                        color={
                          !Boolean(errorJobType) &&
                          !jobTypeArray.includes(jobType[0])
                            ? "textSecondary"
                            : "inherit"
                        }
                        style={{
                          width: "100%",
                        }}
                        noWrap
                      >
                        {jobType[1]}
                      </Typography>
                    </StyledButton>
                  </Grid>
                ))}
            </Grid>
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              style={{ fontWeight: 300, fontStyle: "italic", marginTop: 4 }}
            >
              *If{" "}
              <Typography
                component="span"
                variant="caption"
                style={{ fontWeight: "550" }}
              >
                "Emergency"
              </Typography>{" "}
              is selected, you will be alerted on jobs which require immediate
              attention with mininal notice.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography
            variant="h6"
            color="primary"
            style={{ margin: "10px 0px", fontWeight: 300 }}
            className={classes.typography}
          >
            Language
          </Typography>
          <Box className={classes.sectionBoxLeftOutline}>
            <Typography
              variant="body2"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              What languages do you speak?
            </Typography>

            {errorLanguagePref && (
              <Typography
                variant="caption"
                style={{ color: "#f44336", fontWeight: 300 }}
              >
                {errorLanguagePref}
              </Typography>
            )}

            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              {languagePref.map((lang) => (
                <Grid item key={lang}>
                  <StyledButton
                    name={lang}
                    size="large"
                    onClick={(e) => this.props.handleLanguage(e)}
                    fullWidth
                    className={
                      Boolean(errorLanguagePref)
                        ? languagePrefArray.includes(lang)
                          ? classes.errorSelectedToggleButton
                          : classes.errorUnselectedToggleButton
                        : languagePrefArray.includes(lang)
                        ? classes.selectedToggleButton
                        : classes.unselectedToggleButton
                    }
                    startIcon={<LanguageIcon />}
                  >
                    <Typography
                      variant="body2"
                      color={
                        !Boolean(errorLanguagePref) &&
                        !languagePrefArray.includes(lang)
                          ? "textSecondary"
                          : "inherit"
                      }
                      style={{
                        width: "100%",
                      }}
                      noWrap
                    >
                      {capitalizeFirstChar(lang)}
                    </Typography>
                  </StyledButton>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          style={{ width: "100%", marginTop: 15 }}
        >
          <Grid item>
            <StyledButton
              color="primary"
              onClick={() => this.props.handleBack()}
              style={{ width: 100 }}
            >
              Back
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={() => this.props.handeComplete()}
              style={{ width: 100 }}
            >
              Next
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

JobTypeLanguageMarkup.propTypes = {
  classes: PropTypes.object.isRequired,

  handleBack: PropTypes.func.isRequired,
  handeComplete: PropTypes.func.isRequired,
  handleJobType: PropTypes.func.isRequired,
  jobTypeObj: PropTypes.object.isRequired,
  jobTypeArray: PropTypes.array.isRequired,
  errorJobType: PropTypes.string,
  handleLanguage: PropTypes.func.isRequired,
  languagePref: PropTypes.array.isRequired,
  languagePrefArray: PropTypes.array.isRequired,
  errorLanguagePref: PropTypes.string,
};

export default withStyles(styles)(JobTypeLanguageMarkup);
