import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Component imports
import {
  capitalizeFirstChar,
  splitByCapital,
} from "../../../../util/utilFunctions";
import AnimatedCheck from "../../../../assets/AnimatedCheck";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";

// Icons Imports
import LanguageIcon from "@mui/icons-material/Language";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import BusinessIcon from "@mui/icons-material/Business";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function AlignedBox(props) {
  return <Box display="flex" alignItems="center" {...props} />;
}

function GridTypography(props) {
  return (
    <Typography
      component={Grid}
      item
      xs={6}
      variant="body2"
      style={{ fontWeight: 300 }}
      {...props}
    />
  );
}
export class ReviewJobPosting extends Component {
  editHighlights = () => {
    this.props.onEdit({
      p1: false,
      p2: false,
      p3: false,
      p4: false,
      p5: false,
    });
  };
  editService = () => {
    this.props.onEdit({ p1: true, p2: false, p3: false, p4: false, p5: false });
  };
  editConditions = () => {
    this.props.onEdit({ p1: true, p2: true, p3: false, p4: false, p5: false });
  };
  editLanguage = () => {
    this.props.onEdit({ p1: true, p2: true, p3: true, p4: false, p5: false });
  };
  editFinal = () => {
    this.props.onEdit({ p1: true, p2: true, p3: true, p4: true, p5: false });
  };

  onSubmitJob = () => {
    this.props.onSubmitJob();
  };
  render() {
    const {
      classes,
      address,
      languagePref,
      serviceArray,
      conditionsArray,
      firstName,
      lastName,
      description,
      serviceStart,
      servicePerWeek,
      serviceLength,
      loading,
      generalError,
      postingComplete,
      newJobId,
    } = this.props;

    let serviceStartObj = {
      Immediately: "immediately",
      "1 week": "1week",
      "1 month": "1month",
      "1 month+": "1month+",
    };

    let servicePerWeekObj = {
      "Under 10 hrs": "under10",
      "10 - 20 hrs": "10-20",
      "20hr +": "20+",
    };

    let serviceLengthObj = {
      "Under 1 Month": "underOneMonth",
      "Under 3 Months": "underThreeMonths",
      "Under 6 Months": "underSixMonths",
      "6 Months +": "overSixMonths",
    };

    const languageChip = languagePref.map((key) => (
      <Chip
        label={capitalizeFirstChar(splitByCapital(key))}
        className={classes.chipBlue}
        icon={<LanguageIcon />}
        size="small"
        // color="secondary"
        key={key}
      />
    ));

    const serviceChip = serviceArray.sort().map((key) => (
      <Chip
        label={capitalizeFirstChar(splitByCapital(key))}
        className={classes.chipBlue}
        icon={<AssignmentIcon />}
        size="small"
        // color="secondary"
        key={key}
      />
    ));

    const conditionsChip = conditionsArray.sort().map((key) => (
      <Chip
        label={capitalizeFirstChar(splitByCapital(key))}
        className={classes.chipBlue}
        icon={<LocalHospitalIcon />}
        size="small"
        // color="secondary"
        key={key}
      />
    ));
    return (
      <div style={{ position: "relative" }}>
        {postingComplete && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.8)",
            }}
          >
            <AnimatedCheck large />
            <Typography
              variant="body2"
              style={{ fontWeight: 300, marginTop: 8 }}
            >
              Job successfully posted!
            </Typography>
            <br />
            <Button
              component={Link}
              to={`/profile/jobs/${newJobId}`}
              color="primary"
              variant="contained"
              startIcon={<BusinessIcon />}
            >
              View Posting
            </Button>
          </Box>
        )}
        <Box p={2}>
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: 300 }}
            className={classes.typography}
          >
            Review Job Posting
          </Typography>
          <Typography
            variant="body2"
            style={{ fontWeight: 300, marginBottom: 4 }}
          >
            Please review your job posting details below before posting.
          </Typography>
          <Divider orientation="horizontal" />
          <br />
          <Box className={classes.sectionBoxLeftOutline}>
            <BusinessIcon color="primary" style={{ fontSize: 75 }} />

            <AlignedBox>
              <Typography variant="h6" style={{ fontWeight: 550 }}>
                {capitalizeFirstChar(firstName)} {capitalizeFirstChar(lastName)}{" "}
                •
              </Typography>
              <Chip
                label={"Active Posting"}
                size="small"
                style={{
                  backgroundColor: "rgb(233,245,232)",
                  color: "rgb(26,55,23)",
                  marginLeft: 5,
                }}
              />
            </AlignedBox>
            <AlignedBox>
              <Typography variant="subtitle2">
                {capitalizeFirstChar(address.addressParams.locality)},{" "}
                {address.addressParams.adminAreaLevel1.toUpperCase()}
              </Typography>
              <Button
                disabled={loading || postingComplete}
                onClick={this.editFinal}
                color="secondary"
                size="small"
              >
                Edit
              </Button>
            </AlignedBox>

            <AlignedBox>
              <QueryBuilderIcon
                color="primary"
                style={{ fontSize: 15, marginRight: 5 }}
              />
              <Typography variant="subtitle2" color="textSecondary">
                Be in the first 10 applicants
              </Typography>
            </AlignedBox>

            <br />
            <br />
            <AlignedBox>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", marginRight: 8 }}
              >
                Schedule Highlights
              </Typography>
              <ScheduleIcon />
              <Button
                disabled={loading || postingComplete}
                onClick={this.editHighlights}
                color="secondary"
                size="small"
              >
                Edit
              </Button>
            </AlignedBox>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: 16 }}
            >
              <GridTypography>Start Time:</GridTypography>

              <GridTypography>
                {Object.keys(serviceStartObj).filter(
                  (key) => serviceStartObj[key] === serviceStart
                )}
              </GridTypography>

              <GridTypography>Hours Per Week:</GridTypography>
              <GridTypography>
                {Object.keys(servicePerWeekObj).filter(
                  (key) => servicePerWeekObj[key] === servicePerWeek
                )}
              </GridTypography>

              <GridTypography>Estimated Length:</GridTypography>

              <GridTypography>
                {Object.keys(serviceLengthObj).filter(
                  (key) => serviceLengthObj[key] === serviceLength
                )}
              </GridTypography>
            </Grid>

            <AlignedBox>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Job Description
              </Typography>
              <Button
                disabled={loading || postingComplete}
                onClick={this.editFinal}
                color="secondary"
                size="small"
              >
                Edit
              </Button>
            </AlignedBox>

            <Typography
              variant="body2"
              style={{ fontWeight: 300, whiteSpace: "pre-line" }}
            >
              {description}
            </Typography>
            <br />
            <AlignedBox>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Language Preferences
              </Typography>
              <Button
                disabled={loading || postingComplete}
                onClick={this.editLanguage}
                color="secondary"
                size="small"
              >
                Edit
              </Button>
            </AlignedBox>

            {languageChip}
            <br />
            <AlignedBox>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Services Needed
              </Typography>
              <Button
                disabled={loading || postingComplete}
                onClick={this.editService}
                color="secondary"
                size="small"
              >
                Edit
              </Button>
            </AlignedBox>

            {serviceChip}
            <br />
            <AlignedBox>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Special Conditions
              </Typography>
              <Button
                disabled={loading || postingComplete}
                onClick={this.editConditions}
                color="secondary"
                size="small"
              >
                Edit
              </Button>
            </AlignedBox>

            {conditionsChip}
          </Box>
          <br />

          {Boolean(generalError) && (
            <Typography
              variant="caption"
              style={{ fontWeight: 300, color: "#f44336" }}
            >
              {generalError}
            </Typography>
          )}
          <Button
            disabled={loading || postingComplete}
            fullWidth
            variant="contained"
            color="primary"
            style={{ boxShadow: "none", borderRadius: 25 }}
            onClick={this.onSubmitJob}
          >
            Post Job{" "}
            {loading && (
              <CircularProgress
                thickness={2}
                size={30}
                className={classes.progressRight}
              />
            )}
          </Button>
        </Box>
      </div>
    );
  }
}

ReviewJobPosting.propTypes = {
  classes: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  languagePref: PropTypes.array.isRequired,
  serviceArray: PropTypes.array.isRequired,
  conditionsArray: PropTypes.array.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  serviceStart: PropTypes.string.isRequired,
  servicePerWeek: PropTypes.string.isRequired,
  serviceLength: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSubmitJob: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  generalError: PropTypes.string,
  postingComplete: PropTypes.bool,
  newJobId: PropTypes.string,
};

export default withStyles(styles)(ReviewJobPosting);
