import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import clsx from "clsx";

// Component imports

import LoadingEllipsis from "../layout/LoadingEllipsis";
import TeamShiftContainer from "./components/TeamShiftContainer";
import JobAppDashButton from "../jobs/JobAppDashButton";
import TeamInviteSelect from "./components/TeamInviteSelect";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Icon imports
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupIcon from "@mui/icons-material/Group";

const styles = (theme) => ({
  ...theme.spreadThis,

  primaryText: { fontWeight: "550", color: theme.palette.primary.main },
});

export class DashMyTeamCg extends Component {
  state = { viewIndex: 0, stopUpdate: false };

  // Initial Load Methods
  componentDidUpdate() {
    const { isLoadedTeams, isLoadedTeamInv } = this.props;
    const { stopUpdate } = this.state;
    if (isLoadedTeams && isLoadedTeamInv && !stopUpdate) {
      this.setInitialSwipe();
    }
  }

  setInitialSwipe() {
    const { members, teamInvites } = this.props;
    let viewIndex = 0;
    if (members.length < 1) {
      viewIndex = 1;
      if (
        teamInvites.filter((k) => !k.isAccepted && !k.isDeclined).length < 1
      ) {
        viewIndex = 2;
      }
    }
    this.setState({ viewIndex, stopUpdate: true });
  }

  // UI Methods

  handleIndexPrev = () => {
    const { viewIndex } = this.state;

    let _viewIndex = viewIndex - 1;
    if (_viewIndex < 1) _viewIndex = 0;
    this.setState({ viewIndex: _viewIndex, stopUpdate: true });
  };

  handleIndexNext = () => {
    const { viewIndex } = this.state;

    let _viewIndex = viewIndex + 1;
    if (_viewIndex > 2) _viewIndex = 2;
    this.setState({ viewIndex: _viewIndex, stopUpdate: true });
  };

  handleChangeIndex = (index) => {
    this.setState({ viewIndex: index, stopUpdate: true });
  };

  render() {
    const {
      classes,
      isLoadedTeamInv,
      teamInvites,
      members,
      isLoadedTeams,
      isLoadedShifts,
      shifts,
      jobApplications,
      isLoadedJobApplications,
    } = this.props;

    const { viewIndex } = this.state;

    return (
      <Paper
        elevation={4}
        component={Grid}
        container
        justifyContent="center"
        alignItems="center"
        className={classes.paperContainerC}
        style={{ height: 250 }}
      >
        <Grid
          item
          xs={12}
          style={{ width: "100%" }}
          className={classes.flexBoxSpaced}
        >
          <IconButton
            disabled={viewIndex === 0}
            onClick={this.handleIndexPrev}
            style={{ height: 24, width: 24 }}
            size="large"
          >
            <ArrowBackIosIcon color={viewIndex > 0 ? "primary" : "disabled"} />
          </IconButton>
          <Typography variant="h6" align="center" style={{ fontWeight: 300 }}>
            {viewIndex === 0
              ? "Upcoming Shifts"
              : viewIndex === 1
              ? "My Team Invites"
              : "My Applications"}
          </Typography>
          <IconButton
            disabled={viewIndex > 1}
            onClick={this.handleIndexNext}
            style={{ height: 24, width: 24 }}
            size="large"
          >
            <ArrowForwardIosIcon
              color={viewIndex < 2 ? "primary" : "disabled"}
              style={{ marginRight: -4 }}
            />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12}>
          <SwipeableViews
            index={viewIndex}
            onChangeIndex={this.handleChangeIndex}
            enableMouseEvents
          >
            {/* My team container */}
            <Box
              id="test"
              display="flex"
              value={viewIndex}
              index={0}
              style={{
                height: "calc(218px - 33px)",
                width: "100%",
              }}
            >
              {isLoadedTeams && isLoadedTeamInv ? (
                members.length > 0 ? (
                  <TeamShiftContainer
                    members={members}
                    shifts={shifts}
                    isLoadedShifts={isLoadedShifts}
                  />
                ) : (
                  <Box
                    className={classes.flexBoxCenteredColumn}
                    style={{ width: "100%" }}
                  >
                    <GroupIcon
                      style={{ opacity: 0.7, fontSize: 40 }}
                      color="primary"
                    />
                    <Typography color="textSecondary" align="center">
                      You are not part of any teams at the moment.
                    </Typography>
                    <Button
                      variant="outlined"
                      component={Link}
                      to="/jobs"
                      color="secondary"
                      className={classes.txtTrButton}
                    >
                      Find Your Next Job
                    </Button>
                  </Box>
                )
              ) : (
                <Box
                  className={classes.flexBoxCenteredColumn}
                  style={{ width: "100%" }}
                >
                  <CircularProgress thickness={2} size={75} />
                  <LoadingEllipsis prefix="Loading your upcoming shifts" />
                </Box>
              )}
            </Box>

            {/* Team invites container */}
            <Box
              value={viewIndex}
              index={1}
              style={{
                height: "calc(218px - 33px)",
                width: "100%",
              }}
            >
              {!isLoadedTeamInv ? (
                <Box
                  className={classes.flexBoxCenteredColumn}
                  style={{ height: "100%" }}
                >
                  <CircularProgress thickness={2} size={75} />
                  <LoadingEllipsis prefix="Loading Team Invites" />
                </Box>
              ) : teamInvites.length < 1 ? (
                <Box
                  className={classes.flexBoxCenteredColumn}
                  style={{ height: "100%" }}
                >
                  <Typography color="textSecondary" align="center">
                    You do not have any invites at the moment.
                  </Typography>
                  <Button
                    variant="outlined"
                    component={Link}
                    to="/jobs"
                    color="secondary"
                    className={classes.txtTrButton}
                  >
                    Find Your Next Job
                  </Button>
                </Box>
              ) : (
                <Box
                  p={1}
                  className={clsx("hideScroll", classes.flexBoxColumn)}
                  style={{
                    height: "100%",
                    overflow: "auto",
                  }}
                >
                  <Typography
                    component="div"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    You have{" "}
                    <Box display="inline" className={classes.primaryText}>
                      {
                        teamInvites.filter(
                          (invite) => !invite.isAccepted && !invite.isDeclined
                        ).length
                      }
                    </Box>{" "}
                    unactioned team invite(s).
                  </Typography>

                  {teamInvites.map((teamInvite) => (
                    <TeamInviteSelect
                      teamInvite={teamInvite}
                      key={teamInvite.invId}
                    />
                  ))}
                  <Button
                    variant="outlined"
                    component={Link}
                    to="/invites"
                    color="secondary"
                    style={{ textTransform: "none", marginTop: 8 }}
                  >
                    View All Invites
                  </Button>
                </Box>
              )}
            </Box>
            {/* Applications container */}

            <Box
              value={viewIndex}
              index={2}
              style={{
                height: "calc(218px - 33px)",
                width: "100%",
              }}
            >
              {!isLoadedJobApplications ? (
                <Box
                  className={classes.flexBoxCenteredColumn}
                  style={{ height: "100%" }}
                >
                  <CircularProgress thickness={2} size={75} />
                  <LoadingEllipsis prefix="Loading Job Applications" />
                </Box>
              ) : (
                <Grid
                  container
                  alignItems="center"
                  className={classes.form}
                  style={{
                    height: "100%",
                  }}
                >
                  {/* // Display if no jobApplications found */}
                  {jobApplications.length < 1 && (
                    <Grid component={Box} item xs={12}>
                      <Typography color="textSecondary" align="center">
                        You have not applied to any jobs at the moment.
                      </Typography>
                      <Button
                        variant="outlined"
                        component={Link}
                        to="/jobs"
                        color="secondary"
                        className={classes.txtTrButton}
                      >
                        Find Your Next Job
                      </Button>
                    </Grid>
                  )}

                  {/* // Display if jobApplications */}

                  {jobApplications.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      className="hideScroll"
                      style={{
                        height: "100%",
                        padding: 8,
                        overflow: "auto",
                      }}
                    >
                      <Typography component="div" style={{ width: "100%" }}>
                        You have{" "}
                        <Box display="inline" className={classes.primaryText}>
                          {
                            jobApplications.filter(
                              (jobApp) =>
                                !jobApp.isAccepted && !jobApp.isDeclined
                            ).length
                          }
                        </Box>{" "}
                        unactioned job application(s).
                      </Typography>

                      {jobApplications.map((jobApp, ind) => (
                        <JobAppDashButton jobApp={jobApp} key={ind} />
                      ))}

                      <Button
                        variant="outlined"
                        component={Link}
                        to="/jobs/applications"
                        color="secondary"
                        style={{ textTransform: "none", marginTop: 8 }}
                      >
                        View All Applications
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Box>
          </SwipeableViews>
        </Grid>
      </Paper>
    );
  }
}

DashMyTeamCg.propTypes = {
  classes: PropTypes.object.isRequired,
  teamInvites: PropTypes.array.isRequired,
  isLoadedTeamInv: PropTypes.bool.isRequired,
  userIdNumber: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  isLoadedTeams: PropTypes.bool.isRequired,
  isLoadedShifts: PropTypes.bool.isRequired,
  shifts: PropTypes.array.isRequired,
  jobApplications: PropTypes.array.isRequired,
  isLoadedJobApplications: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DashMyTeamCg);
