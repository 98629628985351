import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter

// Component imports
import { isEmpty } from "../../../../util/utilFunctions";
import DeleteIconButton from "../../../../components/inputs/DeleteIconButton";
import CaregiverWorkDialog from "./CaregiverWorkDialog";

// Redux imports
import { connect } from "react-redux";
import { deleteWorkExp } from "../../../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Icons imports
import BusinessIcon from "@mui/icons-material/Business";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class WorkExperience extends Component {
  handleDelete = () => {
    this.props.deleteWorkExp(this.props.workExp.workExpId);
  };
  render() {
    dayjs.extend(relativeTime);

    const {
      classes,
      workExp: {
        title,
        company,
        startDate,
        endDate,
        description,
        employType,
        workExpId,
      },
      isFamily,
    } = this.props;

    let timeDif, endDateCalc, timeDifYr, timeDifMon;
    if (endDate === "Present") {
      endDateCalc = new Date().toISOString();
    } else {
      endDateCalc = endDate;
    }

    timeDif = dayjs(endDateCalc).diff(startDate, "month");
    let years = Math.floor(timeDif / 12);
    let months = timeDif % 12;

    if (years > 1) {
      timeDifYr = `${years} yrs`;
    } else if ((years = 1)) {
      timeDifYr = `${years} yr`;
    } else if (years < 1) {
      timeDifYr = null;
    }

    if (months > 1) {
      timeDifMon = `${months} mos`;
    } else if ((months = 1)) {
      timeDifMon = `${months} mo`;
    } else if (months < 1) {
      timeDifMon = null;
    }

    return (
      <Box display="flex" justifyContent="space-between">
        <Box p={1}>
          <BusinessIcon size="large" color="primary" />
        </Box>

        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ flex: 1 }}
        >
          <Box>
            <Typography
              style={{ fontWeight: "bold" }}
              className={classes.typography}
            >
              {title}
            </Typography>
            <Typography variant="body2" className={classes.typography}>
              {employType === "Self-employed" ? employType : company}
              {employType !== "Self-employed" &&
                employType !== "" &&
                ` - ${employType}`}
            </Typography>

            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.typography}
              style={{ fontWeight: 300 }}
            >
              {dayjs(startDate).format("MMM YYYY")} -{" "}
              {endDate === "Present"
                ? endDate
                : dayjs(endDate).format("MMM YYYY")}
              {"  •  "}
              {timeDifYr} {timeDifMon}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: 8, whiteSpace: "pre-line" }}
            >
              {!isEmpty(description) && description}
            </Typography>
          </Box>
          {!isFamily && (
            <Box display="flex">
              <CaregiverWorkDialog
                workExp={this.props.workExp}
                workExpId={workExpId}
                isEdit={true}
                color="inherit"
              />
              <DeleteIconButton
                onDeleteClick={this.handleDelete}
                color="inherit"
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

WorkExperience.propTypes = {
  workExp: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  deleteWorkExp: PropTypes.func.isRequired,
  isFamily: PropTypes.bool,
};

export default connect(null, { deleteWorkExp })(
  withStyles(styles)(WorkExperience)
);
