import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import axios from "axios";

// Component imports
import TimeSelector from "../inputs/TimeSelector";
import {
  getLocalTime,
  isEmpty,
  allFalse,
  capitalizeFirstChar,
} from "../../util/utilFunctions";
import DateSelector from "../../components/inputs/DateSelector";
import AddTaskDialog from "../buttons/shift_task/AddTaskDialog";
import TaskDisplay from "./task/TaskDisplay";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/uiActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import AssignmentIcon from "@mui/icons-material/Assignment";

dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const styles = (theme) => ({
  ...theme.spreadThis,

  primary: {
    backgroundColor: theme.palette.primary.main,
    width: 30,
    height: 30,
    margin: "0px 2px 0px 2px",
  },
  secondary: {
    width: 30,
    height: 30,
    margin: "0px 2px 0px 2px",
    backgroundColor: theme.palette.primary.main,
    opacity: 0.35,
  },
  disabled: {
    width: 30,
    height: 30,
    margin: "0px 2px 0px 2px",
  },
  error: {
    color: "red",
    backgroundColor: "rgba(245, 221,219, 1)",
    width: 30,
    height: 30,
    margin: "0px 2px 0px 2px",
  },
  dayIconButton: {
    padding: 4,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
});

export class NewShift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      availDay: {
        isMon: false,
        isTues: false,
        isWed: false,
        isThur: false,
        isFri: false,
        isSat: false,
        isSun: false,
      },
      shiftTime: {
        startTime: "",
        endTime: "",
        startTimeISO: "",
        endTimeISO: "",
      },
      isRepeat: true,
      endDate: "",
      startDate: "",
      note: "",
      tasksArr: [],
      disableWhileLoad: false,
    };
    this.baseState = this.state;
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    this.scrollShifts.scrollIntoView();
  };

  // UI methods

  handleRepeat = () => {
    this.setState({
      isRepeat: !this.state.isRepeat,
      endDate: "",
      availDay: {
        isMon: false,
        isTues: false,
        isWed: false,
        isThur: false,
        isFri: false,
        isSat: false,
        isSun: false,
      },
      errors: {
        ...this.state.errors,
        availDay: false,
        emptyDatesBetweenSelected: false,
        endDate: false,
        endDateComp: false,
        startDateComp: false,
      },
    });
  };

  handleReset = () => {
    this.setState(this.baseState);
  };

  // Action methods
  handleDaySelect = (e) => {
    let value = e.currentTarget.value;
    this.setState({
      availDay: {
        ...this.state.availDay,
        [value]: !this.state.availDay[value],
      },
      errors: {
        ...this.state.errors,
        availDay: false,
        emptyDatesBetweenSelected: false,
      },
    });
  };

  handleStartDate = async (value) => {
    await this.setStateAsync({ startDate: dayjs(value).format("YYYY-MM-DD") });
    this.validateStartDate(value);
  };

  handleEndDate = async (value) => {
    await this.setStateAsync({ endDate: dayjs(value).format("YYYY-MM-DD") });
    this.validateEndDate(value);
  };

  handleStartTime = async (value) => {
    const _value = getLocalTime(value);

    await this.setStateAsync({
      shiftTime: {
        ...this.state.shiftTime,
        startTime: _value,
      },
    });
    this.validateTime();
  };

  handleEndTime = async (value) => {
    const _value = getLocalTime(value);

    await this.setStateAsync({
      shiftTime: {
        ...this.state.shiftTime,
        endTime: _value === "00:00" ? "24:00" : _value,
      },
    });
    this.validateTime();
  };

  setNote = (e) => {
    this.setState({ note: e.target.value });
  };

  setNewTask = (v) => {
    const { tasksArr } = this.state;
    const resTaskArr = tasksArr;
    resTaskArr.push(v);
    resTaskArr.sort((a, b) =>
      a.hasOwnProperty("time") && !b.hasOwnProperty("time")
        ? -1
        : !a.hasOwnProperty("time") && b.hasOwnProperty("time")
        ? 1
        : !a.hasOwnProperty("time") && !b.hasOwnProperty("time")
        ? 0
        : a.time.slice(11, 16) > b.time.slice(11, 16)
        ? 1
        : -1
    );
    this.setState({ tasksArr: resTaskArr });
  };

  handleDeleteTask = (v) => {
    const { tasksArr } = this.state;
    const resTaskArr = tasksArr;
    resTaskArr.splice(v, 1);
    this.setState({ tasksArr: resTaskArr });
  };

  // Error handling

  validateTime = () => {
    const { startTime, endTime } = this.state.shiftTime;

    if (!isEmpty(startTime) && !isEmpty(endTime) && startTime >= endTime) {
      this.setState({
        errors: {
          ...this.state.errors,
          startTime: "May not be later than end time",
          endTime: "May not be early than start time",
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          startTime: false,
          endTime: false,
        },
      });
    }
  };

  validateStartDate = (startDate) => {
    const { endDate, isRepeat } = this.state;

    // Clears errors if not repeat. If repeat, clears errors if endDate is empty or if startDate is earlier than endDate
    if (
      !isRepeat ||
      (isRepeat &&
        (isEmpty(endDate) || dayjs(startDate).format("YYYY-MM-DD") < endDate))
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          startDate: false,
          startDateComp: false,
          endDateComp: false,
        },
      });
    } else {
      this.setState({
        errors: { ...this.state.errors, startDateComp: true },
      });
    }
  };

  validateEndDate = (endDate) => {
    const { startDate } = this.state;

    // Clears error if startDate is empty or endDate is later than startDate
    if (isEmpty(startDate) || dayjs(endDate).format("YYYY-MM-DD") > startDate) {
      this.setState({
        errors: {
          ...this.state.errors,
          endDate: false,
          endDateComp: false,
          startDateComp: false,
        },
      });
    } else {
      this.setState({
        errors: { ...this.state.errors, endDateComp: true },
      });
    }
  };

  checkErrorsBeforeSubmit = () => {
    const { shiftTime, availDay, isRepeat, startDate, endDate, errors } =
      this.state;
    const _errors = { ...errors };
    if (isEmpty(shiftTime.startTime))
      _errors.startTime = "Please enter a start time";
    else if (isEmpty(shiftTime.endTime))
      _errors.endTime = "Please enter an end time";
    else if (shiftTime.startTime > shiftTime.endTime) {
      _errors.startTime = "May not be later than end time";
      _errors.endTime = "May not be early than start time";
    }

    if (isEmpty(startDate)) _errors.startDate = true;
    if (isRepeat) {
      if (allFalse(availDay)) _errors.availDay = true;
      if (isEmpty(endDate)) _errors.endDate = true;
      if (!isEmpty(startDate) && !isEmpty(endDate) && startDate > endDate)
        _errors.startDateComp = true;
    }

    if (_errors.hasOwnProperty("general")) delete _errors.general;
    return { errors: _errors, valid: allFalse(_errors) };
  };

  // Submit function

  setShift = (startDate, shiftTime, note) => {
    const { currMemberData } = this.props;

    const _currCgData = { ...currMemberData };
    delete _currCgData.startDate;
    delete _currCgData.createdAt;
    delete _currCgData.id;
    _currCgData.memberId = currMemberData.id;

    return {
      startTime: dayjs(`${startDate}T${shiftTime.startTime}:00`).toISOString(),
      endTime: dayjs(`${startDate}T${shiftTime.endTime}:00`).toISOString(),
      note,
      ..._currCgData,
      createdAt: new Date().toISOString(),
      status: {
        isCheckedIn: false,
        isCompleted: false,
        isPaid: false,
        isSubmitted: false,
        isApproved: false,
      },
    };
  };

  setShiftsOnSubmit = () => {
    const { shiftTime, isRepeat, startDate, endDate, note, availDay } =
      this.state;

    const shifts = [];
    const startDateComp = dayjs(startDate);
    const endDateComp = dayjs(endDate);

    if (!isRepeat) {
      shifts.push(this.setShift(startDate, shiftTime, note));
    } else {
      const dayMatch = {
        isSun: 0,
        isMon: 1,
        isTues: 2,
        isWed: 3,
        isThur: 4,
        isFri: 5,
        isSat: 6,
      };
      Object.keys(availDay).forEach((k) => {
        if (availDay[k]) {
          let tempDate = startDateComp.clone().day(dayMatch[k]);
          let isContinue = true;

          // Sets shift for dates prior to adding 7 days until endDate reached (see below as loop begins after adding 7 days)

          if (
            tempDate.isSameOrAfter(startDateComp, "d") &&
            tempDate.isSameOrBefore(endDateComp, "d")
          ) {
            shifts.push(
              this.setShift(tempDate.format("YYYY-MM-DD"), shiftTime, note)
            );
          }

          // Sets shift if tempDate + 7 days is same or before endDate
          while (tempDate.isSameOrBefore(endDateComp, "d") && isContinue) {
            tempDate = tempDate.add(7, "days");
            if (tempDate.isSameOrBefore(endDateComp, "d")) {
              shifts.push(
                this.setShift(tempDate.format("YYYY-MM-DD"), shiftTime, note)
              );
            } else {
              isContinue = false;
            }
          }
        }
      });
    }
    shifts.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    );
    return shifts;
  };

  setTasksOnSubmit = () => {
    const { tasksArr } = this.state;
    const { currMemberData } = this.props;

    const tasks = [];
    tasksArr.forEach((task) => {
      const _task = { ...task };
      _task.memberId = currMemberData.id;
      _task.teamId = currMemberData.teamId;
      _task.caregiverInfo = currMemberData.caregiverInfo;
      _task.familyInfo = currMemberData.familyInfo;
      _task.isCompleted = false;
      _task.isDeclined = false;
      _task.createdAt = new Date().toISOString();
      tasks.push(_task);
    });

    return tasks;
  };

  handleValidateSubmit = async () => {
    await this.setStateAsync({
      disableWhileLoad: true,
      errors: { ...this.state.errors, emptyDatesBetweenSelected: false },
    });

    const { errors, valid } = this.checkErrorsBeforeSubmit();
    if (!valid) {
      this.setState({
        errors: { ...this.state.errors, ...errors },
        disableWhileLoad: false,
      });
    } else {
      const shifts = this.setShiftsOnSubmit();
      const tasks = this.setTasksOnSubmit();

      if (shifts.length < 1) {
        await this.setStateAsync({ errors: {}, disableWhileLoad: false });

        this.setState({
          errors: { emptyDatesBetweenSelected: true },
          disableWhileLoad: false,
        });
      } else {
        this.handleSubmitShifts({ shifts, tasks });
      }
    }
  };

  handleSubmitShifts = async (shiftDoc) => {
    try {
      await axios.post("/shift/new-shift", shiftDoc);
      await this.setStateAsync({
        errors: { ...this.state.errors, general: false },
      });
      this.props.setAlert({
        message: `Successfully added ${
          shiftDoc.shifts.length
        } shifts for ${capitalizeFirstChar(
          shiftDoc.shifts[0].caregiverInfo.firstName
        )}!`,
        type: "success",
      });
      this.handleReset();
      if (this.props.onSuccess) this.props.onSuccess();
    } catch (e) {
      await this.setStateAsync({
        errors: { ...this.state.errors, general: e.response.data.error },
      });
    } finally {
      this.setState({
        disableWhileLoad: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      shiftTime,
      errors,
      disableWhileLoad,
      availDay,
      isRepeat,
      startDate,
      endDate,
      note,
      tasksArr,
    } = this.state;

    return (
      <Grid
        container
        justifyContent="center"
        className={classes.form}
        spacing={1}
      >
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.scrollShifts = el;
          }}
        />
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRepeat}
                onChange={this.handleRepeat}
                color="primary"
              />
            }
            label={
              <Typography
                variant="body2"
                color={isRepeat ? "primary" : "inherit"}
              >
                Repeat Shift
              </Typography>
            }
          />
          <Fade in={Boolean(errors.general)} mountOnEnter unmountOnExit>
            <Grid item xs={12}>
              <Typography variant="caption" color="error" align="center">
                {errors.general}
              </Typography>
            </Grid>
          </Fade>
          <Fade
            in={Boolean(errors.emptyDatesBetweenSelected)}
            mountOnEnter
            unmountOnExit
          >
            <Grid item xs={12}>
              <Typography variant="caption" color="error" align="center">
                The days of the week you have selected are not within the range
                of your start and end dates.
              </Typography>
            </Grid>
          </Fade>
        </Grid>
        {errors.availDay && (
          <Grid item xs={12}>
            <Typography variant="caption" color="error" align="center">
              Must select repeating day
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} style={{ marginBottom: 8 }}>
          {[
            "isSun",
            "isMon",
            "isTues",
            "isWed",
            "isThur",
            "isFri",
            "isSat",
          ].map((date) => (
            <IconButton
              size="small"
              value={date}
              key={date}
              disabled={!isRepeat}
              className={classes.dayIconButton}
              onClick={this.handleDaySelect}
            >
              <Avatar
                className={
                  errors.availDay || errors.emptyDatesBetweenSelected
                    ? classes.error
                    : availDay[date]
                    ? classes.primary
                    : isRepeat
                    ? classes.secondary
                    : classes.disabled
                }
              >
                {date === "isSun" ? "Su" : date.slice(2, 3)}
              </Avatar>
            </IconButton>
          ))}
        </Grid>
        <Hidden smUp>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Hidden>
        <Grid
          container
          item
          xs={12}
          sm={10}
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item xs={10} sm={5}>
            <Typography
              variant="body2"
              align="left"
              color={
                errors.startDate || errors.startDateComp ? "error" : "inherit"
              }
            >
              Start Date{isRepeat && "*"}:
            </Typography>
            <DateSelector
              value={startDate}
              onSelectDate={this.handleStartDate}
              propMinDate={dayjs()}
              fullWidth
              variant="outlined"
              errorDate={errors.startDate || errors.startDateComp}
              errorDateText={
                errors.startDate
                  ? "Must select a start date"
                  : errors.startDateComp
                  ? "Must be ealier than end date"
                  : null
              }
              className={
                Boolean(errors.startDate || errors.startDateComp)
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
            />
            {isRepeat && (
              <Fade in>
                <div>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ fontStyle: "italic" }}
                  >
                    *Starting shift includes this date.
                  </Typography>
                </div>
              </Fade>
            )}
          </Grid>
          <Grid item xs={10} sm={5}>
            <Typography
              variant="body2"
              align="left"
              color={
                errors.endDate || errors.endDateComp
                  ? "error"
                  : !isRepeat
                  ? "textSecondary"
                  : "inherit"
              }
            >
              End Date:
            </Typography>
            <DateSelector
              value={endDate}
              onSelectDate={this.handleEndDate}
              propMinDate={isEmpty(startDate) ? dayjs() : dayjs(startDate)}
              fullWidth
              variant="outlined"
              disabled={!isRepeat}
              errorDate={errors.endDate || errors.endDateComp}
              errorDateText={
                errors.endDate
                  ? "Must select an end date"
                  : errors.endDateComp
                  ? "Must be later than start date"
                  : null
              }
              className={
                Boolean(errors.endDate || errors.endDateComp)
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
            />
          </Grid>
          <Grid item xs={10} sm={5}>
            <Typography
              variant="body2"
              align="left"
              color={Boolean(errors.startTime) ? "error" : "inherit"}
            >
              Start Time:
            </Typography>
            <TimeSelector
              onSelectTime={this.handleStartTime}
              error={Boolean(errors.startTime)}
              className={
                Boolean(errors.startTime)
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
              fullWidth
              errorText={Boolean(errors.startTime) && errors.startTime}
              time={shiftTime.startTime}
            />
          </Grid>

          <Grid item xs={10} sm={5}>
            <Typography
              variant="body2"
              align="left"
              color={Boolean(errors.endTime) ? "error" : "inherit"}
            >
              End Time:
            </Typography>
            <TimeSelector
              onSelectTime={this.handleEndTime}
              error={Boolean(errors.endTime)}
              className={
                Boolean(errors.endTime)
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
              errorText={Boolean(errors.endTime) && errors.endTime}
              fullWidth
              time={shiftTime.endTime}
            />
          </Grid>
        </Grid>
        {/* // Total hours count */}
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={10}
          style={{ marginTop: 8 }}
        >
          <Grid item xs={4} sm={3}>
            <Typography
              variant="body2"
              align="left"
              color={
                Boolean(errors.endTime) || Boolean(errors.startTime)
                  ? "error"
                  : "inherit"
              }
              style={{ fontWeight: 550 }}
            >
              Total Daily Hours:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={2}>
            <Typography
              variant="body2"
              align="center"
              color={
                Boolean(errors.endTime) || Boolean(errors.startTime)
                  ? "error"
                  : "inherit"
              }
              style={{ fontWeight: 550 }}
            >
              {isEmpty(shiftTime.endTime) ||
              isEmpty(shiftTime.startTime) ||
              Boolean(errors.endTime) ||
              Boolean(errors.startTime)
                ? 0
                : dayjs(
                    dayjs()
                      .set("h", shiftTime.endTime.slice(0, 2))
                      .set("m", shiftTime.endTime.slice(3, 5))
                  )
                    .diff(
                      dayjs()
                        .set("h", shiftTime.startTime.slice(0, 2))
                        .set("m", shiftTime.startTime.slice(3, 5)),
                      "hour",
                      true
                    )
                    .toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} style={{ margin: "16px 0px" }}>
            <Divider />
          </Grid>
        </Hidden>

        {/* // Shift notes */}
        <Grid item xs={12} sm={10} style={{ marginTop: 16, marginBottom: 8 }}>
          <TextField
            multiline
            minRows={5}
            label="Notes"
            InputLabelProps={{ shrink: true }}
            value={note}
            variant="outlined"
            placeholder="Add a note about this shift..."
            size="small"
            fullWidth
            onChange={this.setNote}
            className={classes.styledTextField}
          />
        </Grid>

        {/* // Tasks display */}
        <Grid item xs={12} sm={10} style={{ marginTop: 16, marginBottom: 8 }}>
          <Box className={classes.flexBoxSpaced}>
            <Typography>Tasks</Typography>
            <AddTaskDialog onSubmitTask={this.setNewTask} />
          </Box>
          <Divider style={{ margin: "4px 0px" }} />
          {tasksArr.length < 1 ? (
            <Box
              className={classes.flexBoxCentered}
              style={{ flexDirection: "column", padding: 10 }}
            >
              <AssignmentIcon color="disabled" fontSize="large" />
              <Typography variant="body2" align="center" color="textSecondary">
                No tasks asigned.
              </Typography>
            </Box>
          ) : (
            tasksArr.map((task, ind) => (
              <TaskDisplay
                key={ind}
                task={task}
                ind={ind}
                isFamily
                handleDeleteTask={this.handleDeleteTask}
              />
            ))
          )}
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          item
          xs={12}
          sm={10}
          style={{ marginTop: 8 }}
        >
          <Button
            onClick={this.handleReset}
            color="primary"
            disabled={disableWhileLoad}
            // className={classes.txtTrButton}
            style={{ marginRight: 8, borderRadius: 25 }}
          >
            Reset
          </Button>
          <Button
            onClick={this.handleValidateSubmit}
            variant="contained"
            color="primary"
            disabled={disableWhileLoad}
            // className={classes.txtTrButton}
            style={{ borderRadius: 25 }}
          >
            Add Shifts
            {disableWhileLoad && (
              <CircularProgress
                thickness={2}
                size={30}
                className={classes.progress}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    );
  }
}
NewShift.propTypes = {
  classes: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default connect(null, { setAlert })(withStyles(styles)(NewShift));
