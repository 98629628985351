import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import firebase from "../../../Firebase";

//Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/uiActions";

// Component Imports
import InvoiceDetails from "./components/InvoiceDetails";
import PaymentPortal from "./components/PaymentPortal";

import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Slide from "@mui/material/Slide";

export class payInvoice extends Component {
  state = {
    invoiceData: {},
    isLoadedInvoice: false,
    errorLoadInvoice: false,
    loadingApprove: false,
    showInvoiceDetails: true,
    showPayDetails: false,
  };

  // Load Methods

  componentDidMount() {
    this.getInvoiceDetails();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async getInvoiceDetails() {
    const invoiceId = this.props.match.params.invoiceId;
    const { userIdNumber } = this.props.credentials;
    let db = firebase.firestore();
    const that = this;

    this.unsubscribe = db
      .collection(`invoices`)
      .doc(invoiceId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const invoiceData = doc.data();
          if (userIdNumber === invoiceData.familyInfo.userIdNumber) {
            invoiceData.id = doc.id;
            that.setState({ invoiceData, isLoadedInvoice: true });
          } else {
            that.props.setAlert({
              message: `You do not have authorization to view invoice #${invoiceId} and have been redirected to your current outsitanding invoices.`,
              type: "warning",
            });
            that.props.history.push("/mypay/invoice");
          }
        } else {
          that.setState({ isLoadedInvoice: true, errorLoadInvoice: true });
        }
      });
  }

  // Action Methods

  approveInvoice = async (invoiceId) => {
    await this.setStateAsync({ loadingApprove: true });
    try {
      await axios.get(`/invoice/approve/${invoiceId}`);
      this.props.setAlert({
        message: "Invoice successfull approved",
        type: "success",
      });
      this.showPayDetails();
    } catch (err) {
      this.props.setAlert({
        message: `Error! ${err.response.data.error}`,
        type: "error",
      });

      return;
    } finally {
      this.setState({ loadingApprove: false });
    }
  };

  showPayDetails = async () => {
    await this.setStateAsync({ showInvoiceDetails: false });
    setTimeout(() => this.setState({ showPayDetails: true }), 150);
  };

  _showInvoiceDetails = async () => {
    await this.setStateAsync({ showPayDetails: false });
    setTimeout(() => this.setState({ showInvoiceDetails: true }), 150);
  };

  render() {
    const invoiceId = this.props.match.params.invoiceId;

    const { credentials } = this.props;
    const {
      invoiceData,
      isLoadedInvoice,
      errorLoadInvoice,
      loadingApprove,
      showInvoiceDetails,
      showPayDetails,
    } = this.state;

    return (
      <div className="res-gr-con">
        <Grid container spacing={2} justifyContent="center">
          <Hidden mdDown>
            <Grid item xs={12} sm={10} md={6}>
              <InvoiceDetails
                invoiceData={invoiceData}
                isLoadedInvoice={isLoadedInvoice}
                errorLoadInvoice={errorLoadInvoice}
                invoiceId={invoiceId}
                approveInvoice={this.approveInvoice}
                loadingApprove={loadingApprove}
              />
            </Grid>

            <Grid item xs={12} sm={10} md={6}>
              <PaymentPortal
                credentials={credentials}
                invoiceData={invoiceData}
                errorLoadInvoice={errorLoadInvoice}
                isLoadedInvoice={isLoadedInvoice}
              />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Slide
              in={showInvoiceDetails}
              direction="right"
              mountOnEnter
              unmountOnExit
            >
              <Grid item xs={12} sm={10} md={6}>
                <InvoiceDetails
                  invoiceData={invoiceData}
                  isLoadedInvoice={isLoadedInvoice}
                  errorLoadInvoice={errorLoadInvoice}
                  invoiceId={invoiceId}
                  approveInvoice={this.approveInvoice}
                  loadingApprove={loadingApprove}
                  showPayDetails={this.showPayDetails}
                />
              </Grid>
            </Slide>
          </Hidden>

          <Hidden mdUp>
            <Slide
              in={showPayDetails}
              direction="left"
              mountOnEnter
              unmountOnExit
            >
              <Grid item xs={12} sm={10} md={6}>
                <PaymentPortal
                  credentials={credentials}
                  invoiceData={invoiceData}
                  isLoadedInvoice={isLoadedInvoice}
                  errorLoadInvoice={errorLoadInvoice}
                  showInvoiceDetails={this._showInvoiceDetails}
                />
              </Grid>
            </Slide>
          </Hidden>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

payInvoice.propTypes = {
  classes: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setAlert })(payInvoice);
