import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

// Component Imports
import { numberWithCommas } from "../../../../util/utilFunctions";
import WelcomeHeader from "./WelcomeHeader";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Popover from "@mui/material/Popover";

// Icon imports
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import SchoolIcon from "@mui/icons-material/School";
import BusinessIcon from "@mui/icons-material/Business";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

const styles = (theme) => ({
  ...theme.spreadThis,
  responsiveGrid: {
    marginTop: 8,
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
  },
  responiveGridItem: {
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  updatesIcon: {
    height: 35,
    width: 35,
    color: theme.palette.primary.main,
  },
  updatesIconSecondary: {
    height: 35,
    width: 35,
    color: theme.palette.secondary.main,
  },
  headerSecondaryText: {
    fontWeight: 550,
    textOverflow: "ellipsis",
    color: theme.palette.secondary.light,
    userNextVariants: true,
  },
  headerPrimaryText: {
    fontWeight: 550,
    textOverflow: "ellipsis",
    color: theme.palette.primary.light,
    userNextVariants: true,
  },
  accountStatusLink: {
    padding: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "rgb(189,189,189)",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
});

function CenteredCardAction(props) {
  return (
    <CardActionArea
      style={{
        borderRadius: 10,
        textAlign: "center",
        width: "100%",
        padding: 8,
        display: "flex",
        alignItems: "center",
      }}
      {...props}
    />
  );
}

function HeaderTypography(props) {
  return <Typography variant="h6" noWrap {...props} />;
}

function LightTypography(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      variant="body2"
      align="left"
      {...props}
    />
  );
}

export class CaregiverAccountUpdates extends Component {
  state = { anchorElStatus: null, anchorElViews: null };

  handleOpenStatus = (e) => {
    this.setState({ anchorElStatus: e.currentTarget });
  };

  handleOpenViews = (e) => {
    this.setState({ anchorElViews: e.currentTarget });
  };

  handleClose = (e) => {
    this.setState({ anchorElStatus: null, anchorElViews: null });
  };
  render() {
    const { anchorElStatus, anchorElViews } = this.state;
    const id = Boolean(anchorElStatus) ? "simple-popover" : undefined;

    const {
      classes,
      isLoaded,
      invoices,
      credentials,
      missingEdu,
      missingWorkExp,
      weeklyShifts,
    } = this.props;

    // Set account status
    let warnings = 0;
    if (!credentials.status.isAvailComplete) {
      warnings += 1;
    }
    if (!credentials.status.isBgCheck) {
      warnings += 1;
    }
    if (!Boolean(credentials.status.isBanking)) {
      warnings += 1;
    }
    if (missingEdu) {
      warnings += 1;
    }
    if (missingWorkExp) {
      warnings += 1;
    }

    // Set profile views counters
    const weeklyViews = credentials.familyViews.filter((family) =>
      dayjs().isSame(family.viewedAt, "week")
    ).length;

    const prevWeeklyViews = credentials.familyViews.filter((family) =>
      dayjs().subtract(7, "d").isSame(family.viewedAt, "week")
    ).length;

    const weeklyIncrease =
      prevWeeklyViews < 1
        ? weeklyViews * 100
        : Math.round((weeklyViews / prevWeeklyViews - 1) * 1000) / 10;

    // Set monthly earnings
    const monthlyEarnings = invoices
      .filter((invoice) =>
        dayjs().isSame(dayjs(invoice.paymentInfo.paidOn), "month")
      )
      .reduce(
        (a, invoice) =>
          a +
          invoice.invoiceBreakdown.grossIncome +
          invoice.invoiceBreakdown.employeeDeductions -
          invoice.invoiceBreakdown.taxes.grossIncomeTax,
        0
      );

    return (
      <Paper elevation={4} style={{ borderRadius: 8 }}>
        <WelcomeHeader credentials={credentials} />

        <div className={classes.paperContainerC} style={{ paddingTop: 0 }}>
          <Typography variant="h6" style={{ fontWeight: 300, marginTop: 8 }}>
            Account Highlights
          </Typography>
          {isLoaded ? (
            <Grid
              container
              justifyContent="space-between"
              className={classes.responsiveGrid}
              style={{ width: "100%" }}
            >
              <Grid item xs={3} md={12}>
                <CenteredCardAction
                  onClick={this.handleOpenViews}
                  className={classes.responiveGridItem}
                >
                  <HeaderTypography className={classes.headerSecondaryText}>
                    {weeklyViews}
                  </HeaderTypography>
                  <Box className={classes.flexBoxCentered}>
                    <VisibilityIcon color="primary" />
                    <LightTypography style={{ marginLeft: 4 }}>
                      Weekly Views
                    </LightTypography>
                  </Box>
                </CenteredCardAction>
                <Popover
                  id={id}
                  open={Boolean(anchorElViews)}
                  onClose={this.handleClose}
                  anchorEl={anchorElViews}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    style: {
                      width: 250,
                    },
                  }}
                >
                  <Box p={1}>
                    <Typography style={{ fontWeight: 300 }}>
                      Views Diagnostics
                    </Typography>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        className={classes.flexBoxCentered}
                        style={{ flexDirection: "column", padding: 8 }}
                      >
                        <Box display="flex">
                          <Typography
                            color={weeklyIncrease < 0 ? "secondary" : "primary"}
                            variant="h6"
                            style={{ fontWeight: 550, marginRight: 4 }}
                          >
                            {weeklyIncrease}%
                          </Typography>
                          {weeklyIncrease < 0 ? (
                            <TrendingDownIcon color="secondary" />
                          ) : weeklyIncrease > 0 ? (
                            <TrendingUpIcon color="primary" />
                          ) : (
                            <TrendingFlatIcon color="primary" />
                          )}
                        </Box>
                        <Typography
                          align="center"
                          variant="body2"
                          color="textSecondary"
                        >
                          from last week
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={classes.flexBoxCentered}
                        style={{ flexDirection: "column", padding: 8 }}
                      >
                        <Box display="flex">
                          <Typography
                            color="primary"
                            variant="h6"
                            style={{ fontWeight: 550 }}
                          >
                            {prevWeeklyViews}
                          </Typography>
                        </Box>
                        <Typography
                          align="center"
                          variant="body2"
                          color="textSecondary"
                        >
                          prev. week views
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Popover>
              </Grid>
              <Grid item xs={3} md={12}>
                <CenteredCardAction
                  component={Link}
                  className={classes.responiveGridItem}
                  to="/myteam"
                >
                  <HeaderTypography className={classes.headerSecondaryText}>
                    {weeklyShifts.length}
                  </HeaderTypography>

                  <Box className={classes.flexBoxCentered}>
                    <ScheduleIcon color="primary" />
                    <LightTypography style={{ marginLeft: 4 }}>
                      Weekly Shifts
                    </LightTypography>
                  </Box>
                </CenteredCardAction>
              </Grid>
              <Grid item xs={3} md={12}>
                <CenteredCardAction
                  component={Link}
                  className={classes.responiveGridItem}
                  to="/mypay/history"
                >
                  <HeaderTypography className={classes.headerSecondaryText}>
                    ${numberWithCommas(monthlyEarnings)}
                  </HeaderTypography>

                  <Box className={classes.flexBoxCentered}>
                    <MonetizationOnIcon color="primary" />
                    <LightTypography style={{ marginLeft: 4 }}>
                      Monthly Earnings
                    </LightTypography>
                  </Box>
                </CenteredCardAction>
              </Grid>
              <Grid item xs={3} md={12}>
                <CenteredCardAction
                  className={classes.responiveGridItem}
                  onClick={
                    warnings > 0 ? this.handleOpenStatus : this.handleClose
                  }
                >
                  <HeaderTypography
                    className={
                      warnings > 0
                        ? classes.headerSecondaryText
                        : classes.headerPrimaryText
                    }
                  >
                    {warnings > 0 ? warnings : "Good"}
                  </HeaderTypography>

                  <Box className={classes.flexBoxCentered}>
                    {warnings > 0 ? (
                      <WarningIcon color="secondary" />
                    ) : (
                      <CheckCircleIcon color="primary" />
                    )}
                    <LightTypography style={{ marginLeft: 4 }}>
                      Account Status
                    </LightTypography>
                  </Box>
                </CenteredCardAction>
              </Grid>
              <Popover
                id={id}
                open={Boolean(anchorElStatus)}
                onClose={this.handleClose}
                anchorEl={anchorElStatus}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  style: {
                    width: 250,
                  },
                }}
              >
                <Box p={1}>
                  <Typography style={{ fontWeight: 300 }}>
                    Account Status
                  </Typography>
                  {!credentials.status.isAvailComplete && (
                    <CardActionArea
                      component={Link}
                      to="/profile"
                      className={classes.accountStatusLink}
                    >
                      <WatchLaterIcon />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: 300, paddingLeft: 8 }}
                      >
                        Missing available hours. Click here to add.
                      </Typography>
                    </CardActionArea>
                  )}
                  {!Boolean(credentials.status.isBanking) && (
                    <CardActionArea
                      component={Link}
                      to="/profile/banking"
                      className={classes.accountStatusLink}
                    >
                      <AccountBalanceIcon />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: 300, paddingLeft: 8 }}
                      >
                        Missing deposit information. Click here to add your
                        direct deposit information.
                      </Typography>
                    </CardActionArea>
                  )}
                  {missingEdu && (
                    <CardActionArea
                      component={Link}
                      to="/profile"
                      className={classes.accountStatusLink}
                    >
                      <SchoolIcon />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: 300, paddingLeft: 8 }}
                      >
                        Missing education and accredidation. Click here to add.
                      </Typography>
                    </CardActionArea>
                  )}
                  {missingWorkExp && (
                    <CardActionArea
                      component={Link}
                      to="/profile"
                      className={classes.accountStatusLink}
                    >
                      <BusinessIcon />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: 300, paddingLeft: 8 }}
                      >
                        Missing work experience. Click here to add.
                      </Typography>
                    </CardActionArea>
                  )}
                  {!credentials.status.isBgCheck && (
                    <CardActionArea
                      component={Link}
                      to="/profile/background-check"
                      className={classes.accountStatusLink}
                    >
                      <AssignmentIndIcon />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: 300, paddingLeft: 8 }}
                      >
                        Pending background check validation. Click here to check
                        progress.
                      </Typography>
                    </CardActionArea>
                  )}
                </Box>
              </Popover>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="space-between"
              className={classes.responsiveGrid}
              spacing={1}
            >
              {[...new Array(4)].map((k, ind) => (
                <Grid item xs={3} md={12} key={ind}>
                  <Skeleton
                    variant="rectangle"
                    height={50}
                    style={{ borderRadius: 8 }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Paper>
    );
  }
}

CaregiverAccountUpdates.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  invoices: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
};
export default withStyles(styles)(CaregiverAccountUpdates);
