import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import ArrayButtonSelect from "../../../../components/inputs/ArrayButtonSelect";
import Stepper from "../../../../components/layout/Components/Stepper";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Icons imports
import LanguageIcon from "@mui/icons-material/Language";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class SFLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languagePref: ["english"],
      errors: { errorLanguagePref: null },
    };
  }

  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    const { languagePref } = this.props;
    if (languagePref.length > 0) this.setState({ languagePref });
  }

  // Error Validation Methods

  validateSubmit = () => {
    const { languagePref } = this.state;
    if (languagePref.length < 1)
      this.setState({
        errors: { errorLanguagePref: "Must select at least 1 language" },
      });
  };

  // Action Methods
  handleLanguagePref = (e) => {
    let value = e.currentTarget.value;
    const { languagePref } = this.state;
    let _languagePref = languagePref;

    if (!_languagePref.includes(value)) {
      _languagePref.push(value);
    } else {
      let pos = _languagePref.findIndex((k) => k === value);
      _languagePref.splice(pos, 1);
    }
    this.setState({
      languagePref: _languagePref,
      errors: { errorLanguagePref: null },
    });
  };

  handleSubmit = async () => {
    await this.validateSubmit();
    const { errors, languagePref } = this.state;

    if (!Boolean(errors.errorLanguagePref)) {
      this.props.onSelectLanguage({ languagePref });
    }
  };

  handleBack = () => {
    this.props.onSelectLanguage({
      pages: { p1: true, p2: true, p3: false, p4: false },
    });
  };

  render() {
    const { classes, languageArray, total, fadeIn } = this.props;
    const { errors, languagePref } = this.state;
    const capitalizeFirstChar = (string) => {
      return string[0].toUpperCase() + string.slice(1);
    };

    return (
      <Box p={2} className={classes.form}>
        <Stepper total={total} fadeIn={fadeIn} />
        <br />

        <Typography
          variant="h6"
          color="primary"
          align="center"
          style={{ margin: "10px 0px", fontWeight: 300 }}
          className={classes.typography}
        >
          Language Needs
        </Typography>
        <Box className={classes.sectionBoxLeftOutline}>
          <Typography variant="body2" style={{ fontWeight: 300 }}>
            Our caregivers come from a variety of different backgrounds and
            speak many languages. Select the languages that are relevant to you.
          </Typography>
          {Boolean(errors.errorLanguagePref) && (
            <Typography
              variant="caption"
              style={{ fontWeight: 300, color: "#f44336" }}
            >
              {errors.errorLanguagePref}
            </Typography>
          )}

          <ArrayButtonSelect
            valueObj={Object.fromEntries(
              languageArray.map((lang) => [lang, capitalizeFirstChar(lang)])
            )}
            value={languagePref}
            handleSelectValue={this.handleLanguagePref}
            error={Boolean(errors.errorLanguagePref)}
            showIcon
            selIcon={<LanguageIcon />}
            unselIcon={<LanguageIcon />}
            noSort
            xs={6}
          />
        </Box>

        <br />
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={this.handleBack}
            style={{
              marginRight: 10,
              width: 100,
              backgroundColor: "white",
              textTransform: "none",
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{ width: 100, textTransform: "none" }}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  }
}

SFLanguage.propTypes = {
  onSelectLanguage: PropTypes.func.isRequired,
  languageArray: PropTypes.array.isRequired,
  languagePref: PropTypes.array,
  total: PropTypes.number.isRequired,
  fadeIn: PropTypes.number.isRequired,
};

export default withStyles(styles)(SFLanguage);
