import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import axios from "axios";
import { isMobile } from "react-device-detect";

// Redux imports
import { connect } from "react-redux";
import { setAlert, setUpdateCheck } from "../../../redux/actions/uiActions";

// Component imports
import { isEmpty } from "../../../util/utilFunctions";
import TooltipButton from "../../inputs/TooltipButton";
import StyledToggleButtonGroup from "../../inputs/StyledToggleButtonGroup";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const styles = (theme) => ({
  ...theme.spreadThis,

  taskDisplayTopDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: 4,
  },
});

export class TaskDisplay extends Component {
  state = {
    openActionPanel: false,
    action: "",
    note: "",
    errors: {},
    disableWhileLoad: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI Method
  handleActionPanel = () => {
    this.setState({ openActionPanel: !this.state.openActionPanel });
  };

  // Action Method

  handleUpdateNote = (e) => {
    this.setState({
      note: e.target.value,
      errors: { ...this.state.errors, note: false },
    });
  };

  handleToggleAction = (e, value) => {
    this.setState({
      errors: { ...this.state.errors, action: null },
    });
    if (this.props.canUpdate) {
      if (this.props.onUpdateTaskStatus) this.updateTaskStatus(value);
    } else {
      this.props.setAlert({
        message: "Must check in before you can action a task",
        type: "warning",
      });
    }
  };

  updateTaskStatus = async (value) => {
    const { task, ind } = this.props;
    const { note } = this.state;
    await this.setStateAsync({ disableWhileLoad: true });
    try {
      const taskDoc = await axios.post(`/task/${task.id}/${value}`, { note });
      this.props.onUpdateTaskStatus(taskDoc.data, ind);
      await this.setStateAsync({ openActionPanel: false });
    } catch (err) {
      await this.setStateAsync({ errors: err.response.data });
    } finally {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const {
      task,
      classes,
      disableDeleteButton,
      small,
      hideDeleteButton,
      showAction,
      ind,
      isFamily,
    } = this.props;

    const { errors, action, note, openActionPanel, disableWhileLoad } =
      this.state;

    return (
      <div
        className={
          isMobile ? classes.mobileContainer : classes.hoverGrayContainer
        }
      >
        {task.hasOwnProperty("isCompleted") && (
          <Box className={classes.flexBoxSpaced}>
            <Typography
              variant="body2"
              align="left"
              style={{ fontWeight: small ? 300 : 550 }}
            >
              Status:
            </Typography>
            <Chip
              label={
                task.isCompleted
                  ? "Completed"
                  : task.isDeclined
                  ? "Declined"
                  : "Pending"
              }
              size="small"
              className={
                task.isCompleted
                  ? classes.chipGreen
                  : task.isDeclined
                  ? classes.chipOrj
                  : classes.chipBlue
              }
            />
          </Box>
        )}

        <Box className={classes.taskDisplayTopDiv}>
          <Typography
            component="div"
            variant="body2"
            align="left"
            color="textSecondary"
            style={{
              width: hideDeleteButton ? "100%" : "calc(100% - 40px)",
              fontWeight: small ? 400 : 550,
              margin: 0,
            }}
          >
            {ind + 1}. {task.title}
            {task.hasOwnProperty("time") && !isEmpty(task.time) && (
              <span style={{ fontWeight: 300 }}>
                {" ("}
                {dayjs(task.time).format("h:mma")}
                {")"}
              </span>
            )}
          </Typography>
          {!hideDeleteButton && isFamily && (
            <IconButton
              onClick={() => this.props.handleDeleteTask(ind)}
              style={{
                width: 40,
                height: 40,
              }}
              disabled={disableDeleteButton}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          )}
          {showAction && !isFamily && (
            <TooltipButton
              tip="Action task"
              onClick={this.handleActionPanel}
              style={{
                width: 40,
                height: 40,
              }}
              disabled={task.isCompleted || task.isDeclined}
            >
              {task.isCompleted || task.isDeclined || openActionPanel ? (
                <AssignmentTurnedInIcon />
              ) : (
                <AssignmentTurnedInOutlinedIcon />
              )}
            </TooltipButton>
          )}
        </Box>
        {!isEmpty(task.body) && (
          <Typography
            component="div"
            variant="body2"
            align="left"
            color="textSecondary"
            noWrap={small}
            style={{
              fontWeight: small ? 300 : 400,
              whiteSpace: small ? "normal" : "pre-line",
              width: "calc(100% - 40px)",
            }}
          >
            {task.body}
          </Typography>
        )}

        {task.hasOwnProperty("actionedAt") && (
          <Typography
            variant="caption"
            align="left"
            component="div"
            color="textSecondary"
            style={{ fontWeight: 300 }}
          >
            Actioned: {dayjs(task.actionedAt).format("h:mma")}
          </Typography>
        )}

        {task.note && (
          <Typography
            component="div"
            variant="subtitle2"
            align="left"
            color="primary"
            noWrap
            style={{ width: "100%", whiteSpace: "pre-line" }}
          >
            Note{" "}
            <Box display="inline" style={{ fontWeight: 300 }}>
              - {task.note}
            </Box>
          </Typography>
        )}
        <Collapse in={openActionPanel}>
          <Box style={{ marginTop: 4 }}>
            <Divider />
            {Boolean(errors.task) && (
              <Typography
                component="div"
                variant="subtitle2"
                align="center"
                color="secondary"
                noWrap
                style={{ width: "100%", margin: "4px 0px" }}
              >
                {errors.task}
              </Typography>
            )}
            <Typography
              variant="body2"
              style={{ fontWeight: 300, marginTop: 4 }}
            >
              Note
            </Typography>
            <TextField
              className={classes.styledTextField}
              fullWidth
              value={note}
              onChange={this.handleUpdateNote}
              variant="outlined"
              size="small"
              multiline
              minRows={3}
              error={Boolean(errors.note)}
              helperText={Boolean(errors.note) && errors.note}
            />
            <Typography
              variant="body2"
              style={{ fontWeight: 300, margin: "4px 0px" }}
            >
              Select an action to save
            </Typography>
            <StyledToggleButtonGroup
              name="action"
              value={action}
              handleToggleValue={this.handleToggleAction}
              options={{ Complete: "complete", Decline: "decline" }}
              error={Boolean(errors.action)}
              errorMsg={errors.action}
              exclusive
              disabled={disableWhileLoad}
            />
          </Box>
        </Collapse>
      </div>
    );
  }
}

TaskDisplay.propTypes = {
  task: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  ind: PropTypes.number.isRequired,
  handleDeleteTask: PropTypes.func,
  disableDeleteButton: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  small: PropTypes.bool,
  showAction: PropTypes.bool,
  isFamily: PropTypes.bool,
  onUpdateTaskStatus: PropTypes.func,
  canUpdate: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  setUpdateCheck: PropTypes.func.isRequired,
};
export default connect(null, { setAlert, setUpdateCheck })(
  withStyles(styles)(TaskDisplay)
);
