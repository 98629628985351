import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

function ReviewLoadingSeleton(props) {
  const { classes, individual, isFamily } = props;
  return individual ? (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton variant="circular" width={125} height={125} />
        </Grid>
        {isFamily && (
          <Grid item xs={12}>
            <Typography variant="h3">
              <Skeleton />
            </Typography>
          </Grid>
        )}

        <Grid item xs={9} sm={7} md={5}>
          {[...new Array(4)].map((k, ind) => (
            <Skeleton
              variant="text"
              height={30}
              key={ind}
              className={classes.basicMargin}
            />
          ))}
        </Grid>

        <Grid item xs={12} sm={11}>
          <Skeleton variant="rectangular" height={118} />
        </Grid>
        <Grid item xs={12} sm={11}>
          <Skeleton variant="text" />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.flexBoxCentered}>
          <Skeleton variant="circular" width={125} height={125} />
        </Grid>
        {!isFamily && (
          <Grid item xs={12}>
            <Typography variant="h3">
              <Skeleton />
            </Typography>
          </Grid>
        )}

        <Grid item xs={3}>
          <Skeleton variant="rectangular" height={25} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rectangular" height={25} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rectangular" height={25} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rectangular" height={25} />
        </Grid>
        {[...new Array(4)].map((k, ind) => (
          <Grid item xs={12} sm={11} md={9} key={ind}>
            <Skeleton variant="rectangular" height={118} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

ReviewLoadingSeleton.propTypes = {
  classes: PropTypes.object.isRequired,
  isFamily: PropTypes.bool.isRequired,
  individual: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ReviewLoadingSeleton);
