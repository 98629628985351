import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Component imports
import { toPhoneNum } from "../../../../util/utilFunctions";
import TooltipButton from "../../../../components/inputs/TooltipButton";
import DateSelector from "../../../../components/inputs/DateSelector";
import LocationSearch from "../../../../components/inputs/LocationSearch";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/uiActions";

import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class EditMyInfo extends Component {
  state = { errors: {}, open: false, disableWhileLoad: false };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  setInitial() {
    const { email, firstName, lastName, phoneNum, dob, address } =
      this.props.credentials;

    this.setState({
      email: email ? email : "",
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      phoneNum: phoneNum
        ? phoneNum
            .replace(/[^0-9]/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        : "",
      dob: dob ? dob : "",
      address: address ? address : {},
      errors: {},
      disableWhileLoad: false,
    });
  }

  // UI Methods

  handleOpen = async () => {
    await this.setInitial();
    this.setState({
      ...this.state,
      open: true,
    });
  };

  handleClose = (event, reason) => {
    if (reason !== "backdropClick") this.setState({ open: false });
  };

  // Action functions

  handleName = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: value.trim() === "" ? "Must not be empty" : null,
      },
    });
  };

  handlePhoneNumber = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      this.setState({
        phoneNum: onlyNums,
        errors: {
          ...this.state.errors,
          phoneNum: onlyNums === "" ? "Must not be empty" : null,
        },
      });
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      this.setState({
        phoneNum: number,
        errors: { ...this.state.errors, phoneNum: null },
      });
    }
  };

  handleAddress = (value) => {
    this.setState({
      address: value,
      errors: { ...this.state.errors, addressFull: null },
    });
  };

  handleSelectDob = (value) => {
    this.setState({ dob: value, errors: { ...this.state.errors, dob: null } });
  };

  handleEmail = (e) => {
    let value = e.target.value;
    this.setState({
      email: value,
      errors: {
        ...this.state.errors,
        email: value === "" ? "Must not be empty" : null,
      },
    });
  };

  // Submit functions

  handleSubmit = async (event) => {
    const { firstName, lastName, email, phoneNum, dob, address } = this.state;
    const { credentials } = this.props;
    event.preventDefault();
    await this.setStateAsync({ disableWhileLoad: true });

    // Sets userDetails if input is different than exisitng data;
    const userDetails = {};
    if (firstName !== credentials.firstName) userDetails.firstName = firstName;
    if (lastName !== credentials.lastName) userDetails.lastName = lastName;
    if (email !== credentials.email) userDetails.email = email;
    if (toPhoneNum(phoneNum) !== credentials.phoneNum)
      userDetails.phoneNum = phoneNum;
    if (dob !== credentials.dob) userDetails.dob = dob;
    if (address !== credentials.address) userDetails.address = address;

    if (Object.keys(userDetails).length > 0) {
      try {
        await axios.post("/user", userDetails);
        this.props.setAlert({
          isAlert: true,
          message: "Profile successfully updated",
          type: "Success",
        });

        this.handleClose();
      } catch (err) {
        this.setState({ errors: err.response.data });
      } finally {
        this.setState({ disableWhileLoad: false });
      }
    } else {
      this.handleClose();
    }
  };

  render() {
    const { classes } = this.props;
    const {
      errors,
      firstName,
      lastName,
      email,
      phoneNum,
      dob,
      address,
      disableWhileLoad,
    } = this.state;

    return (
      <Fragment>
        <TooltipButton
          onClick={this.handleOpen}
          tip="Edit Profile"
          size="small"
          tipClassName={classes.expandButton}
        >
          <EditIcon color="primary" />
        </TooltipButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
          disableEscapeKeyDown
        >
          <Box p={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.typography}>
                Edit My Info
              </Typography>

              <TooltipButton
                tip="Cancel"
                placement="top"
                size="small"
                onClick={this.handleClose}
                tipClassName={classes.closeButton}
              >
                <CloseIcon color="primary" />
              </TooltipButton>
            </Box>

            <Divider orientation="horizontal" style={{ marginBottom: 20 }} />
            <form noValidate onSubmit={this.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    type="firstName"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={firstName}
                    onChange={this.handleName}
                    className={
                      Boolean(errors.firstName)
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    type="lastName"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={lastName}
                    onChange={this.handleName}
                    className={
                      Boolean(errors.lastName)
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    variant="outlined"
                    required
                    fullWidth
                    value={email}
                    onChange={this.handleEmail}
                    className={
                      Boolean(errors.email)
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    inputProps={{ inputMode: "email" }}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ fontStyle: "italic" }}
                  >
                    *Note that this will not change your login details but
                    rather your preferred contact info.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="phoneNum"
                    name="phoneNum"
                    type="tel"
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNum}
                    onChange={this.handlePhoneNumber}
                    className={
                      Boolean(errors.phoneNum)
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    error={Boolean(errors.phoneNum)}
                    helperText={errors.phoneNum}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateSelector
                    onSelectDate={this.handleSelectDob}
                    value={dob}
                    label="Date of Birth"
                    propMaxDate={dayjs()}
                    fullWidth
                    className={
                      Boolean(errors.dob)
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    errorDate={Boolean(errors.dob)}
                    errorDateText={errors.dob}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocationSearch
                    address={address}
                    onSelectAddress={this.handleAddress}
                    className={
                      Boolean(errors.address)
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    medium
                    errorAddress={Boolean(errors.address)}
                    addressSetInitial={true}
                    placeholder="Address..."
                    label="Address"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    className={classes.button}
                    disabled={disableWhileLoad}
                    style={{ width: 100 }}
                  >
                    Update
                    {disableWhileLoad && (
                      <CircularProgress
                        thickness={2}
                        size={30}
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>
      </Fragment>
    );
  }
}

EditMyInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  setAlert: PropTypes.func,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});

export default connect(mapStateToProps, { setAlert })(
  withStyles(styles)(EditMyInfo)
);
