import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

// Component Imports
import { numberWithCommas } from "../../../../util/utilFunctions";
import WelcomeHeader from "../../../caregiverPages/dashboard/components/WelcomeHeader";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import PaymentIcon from "@mui/icons-material/Payment";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

const styles = (theme) => ({
  ...theme.spreadThis,
  responsiveGrid: {
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
  },
  responiveGridItem: {
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  updatesIcon: {
    height: 35,
    width: 35,
    color: theme.palette.primary.main,
  },
  headerSecondaryText: {
    fontWeight: 550,
    textOverflow: "ellipsis",
    color: theme.palette.secondary.light,
    userNextVariants: true,
  },
});

function CenteredCardAction(props) {
  return (
    <CardActionArea
      component={Link}
      style={{
        borderRadius: 10,
        textAlign: "center",
        width: "100%",
        padding: 8,
        display: "flex",
        alignItems: "center",
      }}
      {...props}
    />
  );
}

function LightTypography(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      variant="body2"
      align="left"
      style={{ marginLeft: 4 }}
      {...props}
    />
  );
}

function HeaderTypography(props) {
  return <Typography variant="h6" noWrap {...props} />;
}

export class AccountUpdates extends Component {
  render() {
    const { classes, jobs, isLoaded, invoices, jobApplications, credentials } =
      this.props;

    // Set job views counter
    let viewCounter = 0;
    jobs.forEach((job) => (viewCounter += job.newViews));

    // Set new job applications
    const unactionedJobApplications = jobApplications.filter(
      (jobApp) => !jobApp.isAccepted && !jobApp.isDeclined
    );

    // Set pending invoices
    const pendingInvoices = invoices.filter(
      (invoice) => !invoice.isPaid
    ).length;

    // Set paid invoices
    const ytdAmt = invoices
      .filter(
        (invoice) =>
          !invoice.isPaid && dayjs().isSame(dayjs(invoice.createdAt), "year")
      )
      .reduce(
        (a, b) => a + b.invoiceBreakdown.payableBreakdown.totalPayable,
        0
      );
    return (
      <Paper elevation={4} style={{ borderRadius: 8 }}>
        <WelcomeHeader credentials={credentials} />
        <div className={classes.paperContainerC}>
          <Typography variant="h6" style={{ fontWeight: 300 }}>
            Account Highlights
          </Typography>
          {isLoaded ? (
            <Grid
              container
              justifyContent="space-between"
              className={classes.responsiveGrid}
              style={{ width: "100%" }}
            >
              <Grid item xs={6} sm={3} md={12}>
                <CenteredCardAction
                  className={classes.responiveGridItem}
                  to="/profile/jobs"
                >
                  <HeaderTypography className={classes.headerSecondaryText}>
                    {viewCounter}
                  </HeaderTypography>
                  <Box className={classes.flexBoxCentered}>
                    <VisibilityIcon color="primary" />
                    <LightTypography>Job Views</LightTypography>
                  </Box>
                </CenteredCardAction>
              </Grid>
              <Grid item xs={6} sm={3} md={12}>
                <CenteredCardAction
                  className={classes.responiveGridItem}
                  to={`/jobs/applications`}
                >
                  <HeaderTypography className={classes.headerSecondaryText}>
                    {unactionedJobApplications.length}
                  </HeaderTypography>
                  <Box className={classes.flexBoxCentered}>
                    <WorkOutlineIcon color="primary" />
                    <LightTypography>Apps</LightTypography>
                  </Box>
                </CenteredCardAction>
              </Grid>
              <Grid item xs={6} sm={3} md={12}>
                <CenteredCardAction
                  className={classes.responiveGridItem}
                  to="/mypay/invoice"
                >
                  <HeaderTypography className={classes.headerSecondaryText}>
                    {pendingInvoices}
                  </HeaderTypography>
                  <Box className={classes.flexBoxCentered}>
                    <PaymentIcon color="primary" />
                    <LightTypography>Invoices</LightTypography>
                  </Box>
                </CenteredCardAction>
              </Grid>
              <Grid item xs={6} sm={3} md={12}>
                <CenteredCardAction
                  className={classes.responiveGridItem}
                  to="/mypay/history"
                >
                  <HeaderTypography className={classes.headerSecondaryText}>
                    ${numberWithCommas(ytdAmt)}
                  </HeaderTypography>
                  <Box className={classes.flexBoxCentered}>
                    <MonetizationOnIcon color="primary" />
                    <LightTypography>YTD Cost</LightTypography>
                  </Box>
                </CenteredCardAction>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="space-between"
              className={classes.responsiveGrid}
              spacing={1}
            >
              {[...new Array(4)].map((k, ind) => (
                <Grid item xs={3} md={12} key={ind}>
                  <Skeleton
                    variant="rectangle"
                    height={50}
                    style={{ borderRadius: 8 }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Paper>
    );
  }
}

AccountUpdates.propTypes = {
  classes: PropTypes.object.isRequired,
  jobs: PropTypes.array,
  jobApplications: PropTypes.array,
  credentials: PropTypes.object.isRequired,
};
export default withStyles(styles)(AccountUpdates);
