import React from "react";

import Typography from "@mui/material/Typography";

function Terms() {
  return (
    <>
      <ol id="policies">
        <li id="title">
          <div>
            <Typography variant="h4">Terms of Use</Typography>
            <ol id="policy-details">
              <li>
                <Typography>
                  These terms of use ("<b>Terms</b>") apply to and govern your
                  access to and use of the online platforms (collectively, the "
                  <b>Platforms</b>" and each a "<b>Platform</b>") which are
                  operated by or for or on behalf of Poyo Health Ltd. ("
                  <b>Poyo Health</b>", "<b>we</b>", "<b>us</b>" or "<b>our</b>")
                  for the purposes of Poyo Health providing services to its
                  customers, members, and visitors (collectively, "<b>You</b>"
                  or "<b>Your</b>") with a social network designed to connect
                  home care providers with care seekers and their families
                  looking for home care services. The Platforms include the
                  website{" "}
                  <a className="lnk-txt-ul" href="https://www.poyohealth.com">
                    www.poyohealth.com
                  </a>{" "}
                  (the "<b>Site</b>") and such other online platforms as Poyo
                  Health may introduce from time to time. By accessing a
                  Platform, you acknowledge that you have read and understood
                  the Terms and agree to be bound by them in respect of the
                  access and use of such Platform.
                </Typography>
              </li>
              <li>
                Poyo Health periodically reviews the Terms and reserves the
                right to modify the Terms at any time in its sole discretion,
                without any notice to you. Such modifications shall take effect
                immediately upon the posting of the modified Terms. You agree to
                review the Terms regularly to be aware of such modifications.
                Your continued access to or use of a Platform after such
                modifications shall be deemed to constitute your conclusive
                acceptance of the modified Terms in respect of the use of such
                Platform.
              </li>
              <li>
                Additional terms and conditions may apply to specific portions
                or features of a Platform, including contests, promotions or
                other similar features, all of which terms and conditions shall
                be incorporated into these Terms by reference. In addition,
                certain content or services provided through a Platform may be
                accompanied by their own licensing terms and conditions. If
                there is any conflict between any of the provisions of these
                Terms and any provision of such terms and conditions, the
                provisions of these Terms shall prevail.
              </li>
              <li>
                Poyo Health shall be entitled at any time to add, change or
                withdraw any of the functions available or to be made available
                on any or all of the Platforms at its own discretion.
              </li>
              <li>
                <Typography>
                  Your access to or use of the Platforms will be subject to any
                  other guidelines, procedures, policies and regulations which
                  may from time to time be prescribed, introduced, varied and/or
                  amended by Poyo Health, including Poyo Health’s Privacy Policy
                  (which can be found at{" "}
                  <a
                    href="https://www.poyohealth.com/policies"
                    className="lnk-txt-ul"
                  >
                    www.poyohealth.com/policies
                  </a>
                  ).
                </Typography>
              </li>
              <li>
                <div>
                  By accessing any of the Platforms, you represent and warrant
                  that:
                  <ol id="policy-subdetails">
                    <li>
                      you are of legal age of majority to contract in the
                      jurisdiction in which you reside and, in any event, no
                      less than 18 years of age; and
                    </li>
                    <li>
                      you are legally capable and permitted to accede to the
                      Terms.
                    </li>
                  </ol>
                </div>
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">User Profile</Typography>
            <ol id="policy-details">
              <li>
                <Typography>
                  You must register an account ("<b>User Profile</b>") on a
                  Platform if you wish to use certain of the services available
                  through the Platform (including to make a booking request for
                  in-home care services from a care professional).
                </Typography>
              </li>
              <li>
                You will need to create a username and password to register your
                User Profile. As part of the registration of a User Profile,
                Poyo Health may require you to complete a verification process
                (in such manner as may be determined by Poyo Health from time to
                time) to verify the information which you have provided to Poyo
                Health. You agree to provide truthful, accurate and complete
                information when you register your User Profile (including in
                connection with any verification process) and you agree to
                maintain and update any information so provided. Any false
                identification, email address, telephone contact number or
                otherwise misleading information about your identity is strictly
                forbidden and Poyo Health shall be entitled to delete your User
                Profile in such circumstances.
              </li>
              <li>
                By registering a User Profile, you represent that the
                information provided to Poyo Health is truthful, accurate and
                complete and that you will ensure that the information you have
                provided is constantly up to date. Your information can be
                updated by logging in to your User Profile and making the
                necessary changes.
              </li>
              <li>
                Your User Profile is personal to you and is not transferable to
                third parties. You must only register one User Profile. Multiple
                accounts will be deleted. Poyo Health reserves the right in its
                sole discretion to delete any User Profile it believes to be
                duplicative.
              </li>
              <li>
                A User Profile registered on a Platform will be available for
                use across all Platforms, and you shall be able to access and
                use any Platform and/or the services available through any
                Platform using your registered User Profile.
              </li>
              <li>
                You are responsible for safekeeping and maintaining the
                confidentiality of your username and password. You shall not
                disclose to any person your username or password and shall not
                permit or authorise any person to use your username or password
                for any purpose whatsoever. You agree to take reasonable
                measures and all due care to protect your username and password
                against misuse by third parties.
              </li>
              <li>
                Poyo Health may treat any access to or use of a Platform and/or
                the services available through a Platform through your User
                Profile as access or use by you, notwithstanding that it may
                have been accessed or used by any other person without your
                knowledge or authority. You shall be personally responsible and
                liable for any such access and use (including all information
                posted on or transmitted via any or all of the Platforms by
                anyone using your username and password and any payments due for
                any services acquired through any or all the Platforms by anyone
                using your username and password).
              </li>
              <li>
                <div>
                  <Typography>
                    Should you learn that your User Profile has been used in an
                    unauthorised manner to access or use any of the Platforms
                    and/or the services available through any of the Platforms
                    or effect any transaction in connection with such access or
                    use ("
                    <b>Unauthorised Use</b>"), you shall:
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      immediately notify Poyo Health of the Unauthorised Use;
                    </li>
                    <li>
                      at Poyo Health’s request, make a police report of the
                      Unauthorised Use;
                    </li>
                    <li>
                      provide Poyo Health with any other information relating to
                      the Unauthorised Use as Poyo Health may request; and
                    </li>
                    <li>
                      immediately change the password to your User Profile.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                Poyo Health may notify you of matters related to any or all of
                the Platforms or the services available through any or all of
                the Platforms (including transactional matters relating to such
                services) by sending an email message to the email address
                listed under your User Profile, an electronic message to the
                mailbox assigned to your User Profile or a letter via postal
                mail to the mailing address listed under your User Profile.
                Notices shall become effective immediately.
              </li>
              <li>
                Poyo Health reserves the right in its sole discretion to delete
                your User Profile if Poyo Health determines in its sole
                discretion that you have breached any of these Terms. Suspension
                or termination of your account will in no way modify, change or
                void any payment obligations you may have incurred through your
                use of the site or any services, whether such obligation is to
                Poyo Health ltd. or a third party.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Ownership</Typography>
            <ol id="policy-details">
              <li>
                <Typography>
                  You understand and acknowledge that the software, code,
                  proprietary methods and systems used to provide the Platforms
                  ("<b>Our Technology</b>") are: (a) copyrighted by us and/or
                  our licensors under Canadian and international copyright laws;
                  (b) subject to other intellectual property and proprietary
                  rights and laws; and (c) owned by us or our licensors. Our
                  Technology may not be copied, modified, reproduced,
                  republished, posted, transmitted, sold, offered for sale, or
                  redistributed in any way without our prior written permission
                  and the prior written permission of our applicable licensors.
                </Typography>
              </li>
              <li>
                You must abide by all copyright notices, information, or
                restrictions contained in or attached to any of Our Technology.
                Nothing in these Terms of Use grant You any right to receive
                delivery of a copy of Our Technology or to obtain access to Our
                Technology except as generally and ordinarily permitted through
                the Site according to these Terms of Use.
              </li>
              <li>
                <Typography>
                  Certain of the names, logos, and other materials displayed on
                  the Site or in the Services constitute trademarks, tradenames,
                  service marks or logos ("<b>Marks</b>") of Poyo Health Ltd. or
                  other entities. You are not authorized to use any such Marks.
                  Ownership of all such Marks and the goodwill associated
                  therewith remains with us or those other entities. Any use of
                  third party software provided in connection with the Site or
                  Services will be governed by such third parties' licenses and
                  not by these Terms of Use.
                </Typography>
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Privacy</Typography>
            <ol id="policy-details">
              <li>
                During your access to or use of a Platform or any of the
                services available through a Platform, Poyo Health may collect
                your personal information (including information you enter into
                your User Profile).
              </li>
              <li>
                <Typography>
                  The personal information collected by Poyo Health will be
                  maintained, used, disclosed, stored and/or transferred in
                  accordance with Poyo Health’s Privacy Policy. You can access
                  the Privacy Policy at{" "}
                  <a
                    className="lnk-txt-ul"
                    href="https://www.poyohealth.com/policies/privacy"
                  >
                    www.poyohealth.com/policies/privacy
                  </a>
                  .
                </Typography>
              </li>
              <li>
                Any information that you disclose in the public areas of any of
                the Platforms such as message boards and forums become public
                information. You should exercise caution when disclosing your
                personal information in this way.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">
              Rules Regarding Information {"&"} Other Content
            </Typography>
            <ol id="policy-details">
              <li>
                <div>
                  <Typography>
                    When You access the Platform and/or Platforms, You obtain
                    access to various kinds of information and materials, all of
                    which we call "Content." Content includes information and
                    materials posted to the Site or through the Services by You
                    and other Members. You are entirely responsible for each
                    individual item of Content that You post, email or otherwise
                    make available on the Site or the Services. As between You
                    and us, You retain ownership and any intellectual property
                    rights in any copyrighted materials that are contained in
                    Content that You post to the Site or through the Services.
                    You grant us a non-exclusive, royalty-free, fully paid,
                    fully sublicensable, worldwide license, under any and all of
                    Your copyright and other intellectual property rights
                    related to that Content. You agree that any such Content or
                    any derivative works thereof, may be disseminated,
                    distributed, publicly displayed, reproduced, used,
                    sublicensed, posted, or published by us, and searched,
                    displayed, printed or otherwise used or exploited by our
                    Visitors and Members. You agree not to revise Content posted
                    by others, and You represent and warrant that You will not
                    post or use any Content in any manner that:
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      Infringes the copyright, trademark, trade secret, or other
                      intellectual property or proprietary right of others;
                    </li>
                    <li>
                      Violates the privacy, publicity, or other rights of third
                      parties, including other Members;
                    </li>
                    <li>
                      Violates any law, statute, ordinance or regulation,
                      including laws regarding anti-discrimination and false
                      advertising;
                    </li>
                    <li>
                      Is false or inaccurate or becomes false or inaccurate at
                      any time;
                    </li>
                    <li>
                      Is discriminatory, unlawful, tortious, obscene,
                      fraudulent, defamatory, harmful, threatening,
                      pornographic, indecent, vulgar, harassing, discourteous,
                      hateful, abusive or racially, ethnically, religiously,
                      sexually or otherwise offensive, as determined by us in
                      our sole discretion;
                    </li>
                    <li>
                      Discloses or provides information protected under any law,
                      agreement or fiduciary relationship, including but not
                      limited to proprietary or confidential information of
                      others; Misrepresents Your identity in any way;
                    </li>
                    <li>Misrepresents Your identity in any way;</li>
                    <li>
                      Contains any viruses, Trojan horses, worms, time bombs,
                      cancelbots, or other disabling devices or other harmful
                      component intended to damage, detrimentally interfere
                      with, surreptitiously intercept or expropriate any system,
                      data or personal information
                    </li>
                    <li>
                      Contains any advertising or solicitation for anything
                      other than home care services offer through the Site;
                    </li>
                    <li>Advocates or encourages any illegal activity; or</li>
                    <li>
                      Has the potential to create liability for us or cause us
                      to violate the requirements of or to lose the services, in
                      whole or in part, of our Internet service providers or
                      other suppliers.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                Though we strive to enforce these rules with all of our Members,
                and our other customers, You may be exposed through the Site or
                Services to Content that violates our policies or is otherwise
                offensive. You access the Site and Services at Your own risk. We
                may, but are not obligated to, delete Accounts and/or remove
                Content from the Site if we determine or suspect that those
                Accounts or Content violate the terms of these Terms of Use or
                the applicable agreement with the offending Member(s). We take
                no responsibility for Your exposure to Content on the Site or
                through the Services whether it violates our content policies or
                not.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Acceptable Use</Typography>
            <ol id="policy-details">
              <li>
                <Typography>
                  You shall be responsible for any content that you post or
                  transmit on or through any of the Platforms. If you have a
                  User Profile, you can post ratings of service providers ("
                  <b>Ratings</b>") on the Platforms.
                </Typography>
              </li>
              <li>
                Ratings will identify the User Profile that has posted the
                Rating. Unjustified multiple Ratings, self-Ratings and other
                forms of Ratings which attempt to have a distorting influence on
                the Rating system are prohibited and may be deleted.
              </li>
              <li>
                <div>
                  The following provisions shall apply to all content (including
                  Ratings) posted on or transmitted on or through any of the
                  Platforms:
                  <ol id="policy-subdetails">
                    <li>
                      Content must be based on demonstrable facts, must be
                      written fairly and objectively and must not contain any
                      content which is offensive or abusive, liable to criminal
                      prosecution or otherwise unlawful.
                    </li>
                    <li>
                      Content which violates the requirements set out in Clause
                      6.3.1 will not be published or may be deleted.
                    </li>
                    <li>
                      By posting or transmitting any Content on any of the
                      Platforms, you grant Poyo Health, free of charge, a
                      non-exclusive, royalty-free, worldwide, irrevocable,
                      perpetual licence to use, store, copy, modify or otherwise
                      exploit or deal with the content in any manner it sees
                      fit, including using it for further services, passing it
                      on to third parties or publishing it.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                You agree not to cause, nor knowingly allow others to cause, any
                nuisance, annoyance, or inconvenience, whether to Poyo Health or
                any of the users of any of the Platforms by any means.
              </li>
              <li>
                <div>
                  You agree to use the Platforms in a manner consistent with all
                  applicable laws and regulations. Specifically, you will not
                  use any of the Platforms in any way:
                  <ol id="policy-subdetails">
                    <li>which breaches any of these Terms;</li>
                    <li>
                      to post, transmit, distribute, link to, or solicit content
                      that contains any advertising and promotional message;
                    </li>
                    <li>
                      which violates or infringes any laws, regulatory
                      requirements or codes applicable in Canada;
                    </li>
                    <li>
                      which infringes or violates any copyright, trademark or
                      any other intellectual property, personal or proprietary
                      rights of any person, or violates any obligation of
                      confidence or any other proprietary right;
                    </li>
                    <li>
                      to post, transmit, distribute, link to, or solicit content
                      that contains any obscene, offensive, defamatory or
                      otherwise actionable material;
                    </li>
                    <li>
                      which undermines the security or integrity of any of the
                      Platforms, including without limitation by importing any
                      spyware, viruses, Trojan horses, worms, keystroke loggers,
                      time bombs or other disabling devices or malicious
                      computer software intended to detrimentally interfere,
                      damage, surreptitiously intercept or expropriate such
                      equipment, systems or networks;
                    </li>
                    <li>
                      to post, transmit, distribute, link to, or solicit content
                      that contains any errors, whether technical or otherwise;
                      or
                    </li>
                    <li>
                      to post, transmit, distribute, link to, or solicit content
                      that contains any material in any form that would
                      otherwise render Poyo Health liable or expose Poyo Health
                      to any proceedings whatsoever.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                You shall not use any robot, spider, or other automatic or
                manual device or process to monitor or copy any portion of any
                content contained in any of the Platforms without Poyo Health’s
                express written permission.
              </li>
              <li>
                You shall not use any device, software or routine to interfere
                or attempt to interfere with the proper working of any of the
                Platforms, or to impose an unreasonable or disproportionately
                large load on the servers serving any of the Platforms.
              </li>
              <li>
                You acknowledge that some of the content available on the
                Platforms may be posted on or via the Platforms by third
                parties, including content provided by blogs or reviews or
                comments made by users of the Platforms. Poyo Health shall not
                be responsible for the accuracy of or any errors or omissions in
                any content available on any of the Platforms.
              </li>
              <li>
                Poyo Health shall have the right (but not the obligation) in its
                sole discretion to monitor, refuse or remove any content that
                are available on any of the Platforms. Without limiting the
                foregoing, Poyo Health shall have the right to remove any
                content that violates these Terms or is otherwise objectionable.
              </li>
              <li>
                Poyo Health assumes no responsibility for monitoring any of the
                Platforms for inappropriate content. If at any time Poyo Health
                chooses, at its sole discretion, to monitor any of the
                Platforms, Poyo Health nonetheless assumes no responsibility for
                the content available on any of such Platforms.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Intellectual Property</Typography>
            <ol id="policy-details">
              <li>
                Content available on the Platforms (including information,
                communications, software, images, sounds contained on or
                available through the Platforms) is provided by Poyo Health, its
                content providers and third party licensors. The intellectual
                property rights to or over the contents available on the
                Platforms belong to Poyo Health, its content providers and/or
                third party licensors. All rights are reserved. Subject to
                Clause 7.2, you must not reproduce, modify, transfer,
                distribute, republish, download, post, or transmit in any form
                or by any means, including electronic, mechanical photocopying
                or recording, any of the content available on any of the
                Platforms without the prior written permission of Poyo Health.
                Without prejudice to the foregoing, you agree that the material
                and content contained within or available through the Platforms
                may not be used for commercial purposes or distributed
                commercially.
              </li>
              <li>
                Notwithstanding Clause 7.1, you may view, store, print and
                display the content available on the Platforms solely for your
                personal, non-commercial use.
              </li>
              <li>
                Your access to or use of the Platforms should not be construed
                as granting, by implication, estoppel or otherwise, any licence
                or right to use the trademarks, tradenames, logos or service
                marks appearing on any of the Platforms without Poyo Health’s
                prior written consent. You may not, without Poyo Health’s prior
                written consent, use any of such trademarks, tradenames, logos
                or service marks as a hyperlink to Poyo Health’s website or any
                of the Platforms or any other website or platform.
              </li>
              <li>
                You may not modify, translate, decompile, disassemble, reverse
                engineer or otherwise attempt to derive the source code for the
                computer systems and other technology that operate any of the
                Platforms or create derivative works based on any of the
                Platforms. For the purposes of these Terms, “reverse
                engineering” shall include the examination or analysis of any of
                the Platforms to determine the source code, structure,
                organisation, internal design, algorithms or encryption devices
                of any of such Platform’s underlying technology.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Disclaimer of Warranties</Typography>
            <ol id="policy-details">
              <li>
                The use of any and all of the Platforms is at your own risk.
              </li>
              <li>
                <div>
                  Poyo Health takes certain industry-accepted precautions to
                  secure the Platforms or portions of the Platforms. However,
                  Poyo Health does not warrant that:
                  <ol id="policy-subdetails">
                    <li>
                      the access to or use of the Platforms will meet your
                      requirements;
                    </li>
                    <li>the Platforms will always be available;</li>
                    <li>
                      the access to or use of the Platforms will be
                      uninterrupted, timely, secure, error-free or virus-free,
                      or free from other invasive or damaging code;
                    </li>
                    <li>
                      the quality of any products, services, information, or
                      other material (including the content and services)
                      purchased or obtained by you through your access to or use
                      of the Platforms will meet your expectations; or
                    </li>
                    <li>any errors in the Platforms will be corrected.</li>
                  </ol>
                </div>
              </li>
              <li>
                <div>
                  By accessing and using any of the Platforms, you understand
                  and agree that:
                  <ol id="policy-subdetails">
                    <li>
                      You assume total responsibility and risk for your access
                      to and use of the Platforms. Poyo Health does not make any
                      express or implied warranties, representations or
                      endorsements whatsoever with regard to any of the
                      Platforms or any content or service provided through any
                      of the Platforms, and shall not be liable for any cost or
                      damage arising directly or indirectly from any such
                      transaction. The content and services available on the
                      Platforms are provided to you on an “as is, as available”
                      basis without warranty or condition of any kind.
                    </li>
                    <li>
                      Any content downloaded, uploaded or otherwise obtained
                      through your access to and use of any of the Platforms is
                      done at your own risk and discretion. It is solely your
                      responsibility to evaluate the accuracy, completeness and
                      usefulness of all opinions, advice, services, and other
                      information provided through the Platforms.
                    </li>
                  </ol>
                </div>
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Indemnity</Typography>
            <ol id="policy-details">
              <li>
                To the extent permitted by applicable law, you agree to
                indemnify and hold harmless Poyo Health, its shareholders,
                directors, officers, employees, agents and representatives
                (collectively, the “Indemnified Parties”), from and against any
                and all claims, demands, actions, judgments, losses,
                liabilities, damages, costs and expenses of whatever nature
                (including legal costs on a full indemnity basis) which any or
                all of the Indemnified Parties may incur or suffer as a result
                of, arising out of or in connection with your access to or use
                of any of the Platforms, your breach of any of these Terms, or
                your infringement or violation of the rights of any third party.
              </li>
              <li>
                The obligations under this Clause 9 shall survive any
                termination of your relationship with Poyo Health or your access
                to or use of the Platforms. Poyo Health reserves the right to
                assume the defence and control of any matter subject to
                indemnification by you, in which event you will cooperate with
                Poyo Health in asserting any available defences.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Limitation of Liability</Typography>
            <ol id="policy-details">
              <li>
                To the extent permitted by applicable law, Poyo Health shall not
                be liable to you in any way whatsoever for any loss, damages or
                costs, whether in contract or tort (including negligence or
                breach of statutory duty) or otherwise arising out of or in
                connection with your access to or use of any of the Platforms or
                other content or services provided through any of the Platforms.
              </li>
              <li>
                If you are dissatisfied with any portion of any of the Platforms
                or with any provision in these Terms, your sole and exclusive
                remedy is to terminate the Terms and discontinue your access to
                or use of the Platforms.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">
              Third Party Websites and Third Party Content
            </Typography>
            <ol id="policy-details">
              <li>
                The Platforms may contain hyperlinks or other redirection tools
                to websites or applications that are owned or operated by third
                parties. Such websites or applications are not controlled by
                Poyo Health and do not form part of any of the Platforms. If you
                choose to use such hyperlinks or redirection tools to access
                such websites or applications, you agree to review and accept
                such websites’ or applications’ terms and conditions of use
                before accessing such websites or applications. You access such
                third party websites and applications at your own risk.
              </li>
              <li>
                Poyo Health does not assume any responsibility for material
                created or published by such third party websites or
                applications, and by providing a link to such third party
                websites or applications, Poyo Health does not imply that Poyo
                Health endorses the websites or applications or the products or
                services referenced in such websites or applications. You
                acknowledge that Poyo Health has no control over, excludes all
                liability for and cannot be deemed to have endorsed the content
                of any material on the Internet which can be accessed by using
                the Platforms.
              </li>
              <li>
                You agree that caching, hyperlinking to, and framing of Poyo
                Health’s website or any of its contents are strictly prohibited.
              </li>
              <li>
                Poyo Health reserves all rights to disable any links to, or
                frames of any website containing inappropriate, profane,
                defamatory, infringing, obscene, indecent or unlawful topics,
                names, material or information, or material or information that
                violates any written law, any applicable intellectual property
                or proprietary, privacy or publicity rights.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Dispute Resolution</Typography>
            <ol id="policy-details">
              <li>
                These Terms shall be governed by and construed in accordance
                with the laws of British Columbia.
              </li>
              <li>
                Any dispute arising out of or in connection with these Terms,
                including any question regarding its existence, validity or
                termination, shall be referred to and finally resolved by
                arbitration administered by the Vancouver International
                Arbitration Centre in accordance with the Arbitration Rules of
                the Vancouver International Arbitration Centre for the time
                being in force, which rules are deemed to be incorporated by
                reference in this Clause 12.
              </li>
              <li>
                The seat of the arbitration shall be Vancouver, British
                Columbia. The arbitral tribunal shall consist of one arbitrator.
              </li>
              <li>
                Notwithstanding anything to the contrary in these Terms, Poyo
                Health may at any time without regard to any notice periods
                required by the provisions in these Terms, and as often as is
                necessary or appropriate, seek any injunctive relief or
                measures, or any interlocutory, provisional or interim relief or
                measures, from any court of competent jurisdiction.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">General</Typography>
            <ol id="policy-details">
              <li>
                No failure or delay by Poyo Health in exercising any right or
                remedy under these Terms shall operate as a waiver of such right
                or remedy, nor will any single or partial exercise of any right
                or remedy under these Terms preclude any other or further
                exercise thereof or the exercise of any other right or remedy.
              </li>
              <li>
                If any provision in these Terms shall be, or at any time shall
                become invalid, illegal or unenforceable in any respect, such
                invalidity, illegality or unenforceability shall not in any way
                affect or impair any other provision of these Terms but these
                Terms shall be construed as if such invalid or illegal or
                unenforceable provision had never been contained in these Terms.
              </li>
              <li>
                You shall not assign, transfer or subcontract, or purport to
                assign, transfer or subcontract, any of your rights, interests
                or obligations under these Terms without the prior written
                consent of Poyo Health. Poyo Health shall be entitled to assign,
                transfer or subcontract any or all of its rights, interests and
                obligations under these Terms to any third party without your
                prior written consent.
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Survival</Typography>
            <ol id="policy-details">
              <li>
                All provisions that by their nature survive expiration or
                termination of these Terms of Use shall so survive, including
                without limitation, Section 2, Section 3, all limitations on
                liability explicitly set forth herein and our proprietary rights
                in and to the Platforms, Content provided by us, Our Technology
                and the Services.
              </li>
            </ol>
          </div>
        </li>
      </ol>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ marginTop: "2rem" }}
      >
        Last Revised: January 5, 2022
      </Typography>
    </>
  );
}

export default Terms;
