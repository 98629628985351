import React from "react";
import NumberFormat from "react-number-format";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      // isNumericString
    />
  );
});

function StyledNumberInput(props) {
  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatCustom,
        startAdornment: (
          <InputAdornment position="start">
            {props.start ? props.start : "CAD$"}
          </InputAdornment>
        ),
        input: {
          "&[type=number]": {
            "-moz-appearance": "textfield",
          },
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      }}
      {...props}
    />
  );
}

export default StyledNumberInput;
