import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

// Component imports
import LoadingAnimation from "../../../../../components/layout/LoadingAnimation";
import {
  capitalizeFirstChar,
  isEmpty,
  splitAddressFull,
} from "../../../../../util/utilFunctions";
import OutlinedProfilePic from "../../../../../components/layout/OutlinedProfilePic";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";

// Icon imports
import SearchIcon from "@mui/icons-material/Search";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class SingleReport extends Component {
  state = {
    showAction: false,
    action: "",
    actionError: null,
    disableWhileLoad: false,
  };

  // UI Methods

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  handleAction = () => {
    this.setState({ showAction: !this.state.showAction, actionError: null });
  };

  // Error validation

  validateEmpty = () => {
    const action = this.state.action;
    if (isEmpty(action)) {
      this.setState({ actionError: "Action must not be empty" });
    }
    return !isEmpty(action);
  };

  // Action Methods
  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      actionError: isEmpty(value) && "Action must not be empty",
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const valid = this.validateEmpty();
    if (valid) {
      const action = this.state.action;
      await this.setStateAsync({ disableWhileLoad: true });
      try {
        const actionUpdate = await axios.post(
          `/shift/${this.props.report.shiftId}/report/update/${this.props.report.id}/action`,
          { action }
        );
        if (this.props.onActionSuccess)
          this.props.onActionSuccess(actionUpdate.data);
        this.setState({ action: "", showAction: false });
      } catch (err) {
        console.error(err.response.data);
      } finally {
        this.setStateAsync({ disableWhileLoad: false });
      }
    }
  };

  render() {
    const { classes, report, errors, isLoadedReport, userType } = this.props;
    const { showAction, actionError, action, disableWhileLoad } = this.state;
    const isError = errors.hasOwnProperty("report");
    return (
      <Box style={{ minHeight: 200 }} className={classes.form}>
        <Typography variant="h6" style={{ fontWeight: 300 }}>
          Report
        </Typography>
        <Divider />
        {!isLoadedReport ? (
          <Box style={{ paddingTop: 16 }}>
            <LoadingAnimation message="Loading Report" />
          </Box>
        ) : // Non error display
        !isError ? (
          <Box style={{ marginTop: 16, textAlign: "start" }}>
            <Grid container className={classes.selectedGrayContainer}>
              <Grid
                container
                justifyContent="space-between"
                item
                xs={12}
                style={{ marginBottom: 8 }}
              >
                <Grid item>
                  <Typography variant="body2">Status:</Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={
                      report.status.isActioned
                        ? "Actioned"
                        : report.status.isRead
                        ? "Read"
                        : "Pending Review"
                    }
                    size="small"
                    className={
                      report.status.isActioned
                        ? classes.chipGreenOutlined
                        : report.status.isRead
                        ? classes.chipBlueOutlined
                        : classes.chipOrjOutlined
                    }
                    style={{ margin: 0 }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" item xs={12}>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Submitted By:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {capitalizeFirstChar(report.caregiverInfo.firstName)}{" "}
                    {capitalizeFirstChar(report.caregiverInfo.lastName)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" item xs={12}>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Client Name:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {capitalizeFirstChar(report.familyInfo.clientFirstName)}{" "}
                    {capitalizeFirstChar(report.familyInfo.clientLastName)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" item xs={12}>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Address:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {splitAddressFull(report.address.addressFull)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between" item xs={12}>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Shift:
                  </Typography>
                </Grid>
                <Grid item style={{ textAlign: "right" }}>
                  <Typography
                    component={Link}
                    to={`/myteam/${report.memberId}/shift/${report.shiftId}`}
                    variant="body2"
                    color="primary"
                    style={{ whiteSpace: "pre-line" }}
                    className={classes.typographyHover}
                  >
                    {dayjs(report.shiftInfo.startTime).format("MMM DD, YYYY")}
                    {"\n"}
                    {dayjs(report.shiftInfo.startTime).format("h:mma")} -{" "}
                    {dayjs(report.shiftInfo.endTime).format("h:mma")}
                  </Typography>
                </Grid>
              </Grid>
              {userType === "admin" && (
                <React.Fragment>
                  <Grid container justifyContent="space-between" item xs={12}>
                    <Grid item>
                      <Typography variant="body2" color="secondary">
                        ID:
                      </Typography>
                    </Grid>
                    <Grid item style={{ textAlign: "right" }}>
                      <Typography variant="body2" color="secondary">
                        {report.shiftId}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between" item xs={12}>
                    <Grid item>
                      <Typography variant="body2" color="secondary">
                        Member ID:
                      </Typography>
                    </Grid>
                    <Grid item style={{ textAlign: "right" }}>
                      <Typography variant="body2" color="secondary">
                        {report.memberId}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>

            <Box style={{ padding: 10 }}>
              <Typography variant="h6">{report.title}</Typography>

              <Box className={classes.sectionBoxLeftOutline}>
                <Typography
                  variant="caption"
                  component="div"
                  align="center"
                  style={{ fontStyle: "italic" }}
                >
                  Report was submitted at{" "}
                  {dayjs(report.submitted).format("h:mma")} on{" "}
                  {dayjs(report.submitted).format("MMM DD, YYYY")}.
                  {/* {dayjs(report.actionedAt).format("h:mma MMM DD, YYYY")} */}
                </Typography>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {report.note}
                </Typography>
              </Box>

              {report.status.isActioned && (
                <Box
                  className={classes.sectionBoxLeftOutlineBlue}
                  style={{ marginTop: 8 }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    align="center"
                    style={{ fontStyle: "italic" }}
                  >
                    Report actioned at{" "}
                    {dayjs(report.actionedAt).format("h:mma")} on{" "}
                    {dayjs(report.actionedAt).format("MMM DD, YYYY")}{" "}
                    {report.status.isEdited ? "(edited)" : ""}.
                  </Typography>
                  <Box className={classes.flexBox}>
                    <OutlinedProfilePic
                      imgSrc={report.actionedBy.imgUrlThumb}
                      size={35}
                    />
                    <Typography variant="body2" style={{ marginLeft: 4 }}>
                      {capitalizeFirstChar(report.actionedBy.firstName)}{" "}
                      {capitalizeFirstChar(report.actionedBy.lastName)}{" "}
                      {`(${report.actionedBy.role})`}
                    </Typography>
                  </Box>
                  <Typography style={{ whiteSpace: "pre-line", marginTop: 8 }}>
                    {report.action}
                  </Typography>
                </Box>
              )}
              {userType === "admin" && (
                <Button
                  onClick={this.handleAction}
                  variant={showAction ? "outlined" : "text"}
                  color="primary"
                  style={{
                    boxShadow: "none",
                    borderRadius: 25,
                    margin: "8px 0px",
                  }}
                  fullWidth
                >
                  {report.status.isActioned
                    ? "Edit Report Action"
                    : "Action Report"}
                </Button>
              )}
              <Fade in={showAction} mountOnEnter unmountOnExit>
                <form onSubmit={this.handleSubmit} style={{ marginTop: 16 }}>
                  <InputLabel error={Boolean(actionError)} required>
                    Action Note
                  </InputLabel>
                  <TextField
                    name="action"
                    value={action}
                    variant="outlined"
                    className={
                      Boolean(actionError)
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    onBlur={this.validateEmpty}
                    error={Boolean(actionError)}
                    helperText={Boolean(actionError) && actionError}
                    multiline
                    minRows={3}
                    maxRows={8}
                    onChange={this.handleChange}
                    fullWidth
                    disabled={disableWhileLoad}
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        boxShadow: "none",
                        borderRadius: 25,
                        margin: "8px 0px",
                      }}
                      type="submit"
                      size="small"
                      disabled={disableWhileLoad}
                    >
                      Submit{" "}
                      {disableWhileLoad && (
                        <CircularProgress
                          thickness={2}
                          size={30}
                          className={classes.progress}
                        />
                      )}
                    </Button>
                  </Box>
                </form>
              </Fade>
              <Button
                component={Link}
                to="/reports/"
                className={classes.txtTrButton}
                color="primary"
                fullWidth
              >
                ALL REPORTS
              </Button>
            </Box>
          </Box>
        ) : (
          // Error display
          isError && (
            <Box style={{ marginTop: 16 }}>
              <SearchIcon fontSize="large" color="disabled" />
              <Typography color="textSecondary">{errors.report}</Typography>
              <Button
                component={Link}
                to="/reports/"
                className={classes.txtTrButton}
                color="primary"
              >
                Back
              </Button>
            </Box>
          )
        )}
      </Box>
    );
  }
}

SingleReport.propTypes = {
  report: PropTypes.object.isRequired,
  loadingReport: PropTypes.bool.isRequired,
  isLoadedReport: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  onActionSuccess: PropTypes.func,
};

export default withStyles(styles)(SingleReport);
