import React, { Component } from "react";
import dayjs from "dayjs";

// Component imports
import {
  calcUnsortedShiftsIncome,
  numberWithCommas,
} from "../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Hidden from "@mui/material/Hidden";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ShiftTableLabels extends Component {
  render() {
    const {
      classes,
      shifts,
      filteredShifts,
      currMemberId,
      isInitialSet,
      showAll,
      isFamily,
    } = this.props;

    const { totalHours, grossIncome } = calcUnsortedShiftsIncome(
      showAll
        ? shifts.filter((shift) => {
            if (currMemberId !== "all") {
              return shift.memberId === currMemberId;
            } else {
              return true;
            }
          })
        : filteredShifts
    );

    const currDate = dayjs(new Date().setHours(0, 0, 0, 0)).toISOString();

    return (
      <Grid
        container
        component={Card}
        style={{
          borderRadius: 10,
          padding: 8,
        }}
        className={classes.darkGrayBorderOnly}
        justifyContent="space-evenly"
      >
        <Grid item>
          <Typography
            variant="subtitle2"
            style={{ marginRight: 4, fontWeight: 300 }}
          >
            Sch. Shifts:{" "}
            <Typography
              component="span"
              color="secondary"
              style={{ fontWeight: 550 }}
            >
              {!isInitialSet
                ? "-"
                : currMemberId === "all"
                ? shifts.filter((shift) => shift.startTime >= currDate).length
                : shifts.filter(
                    (shift) =>
                      shift.memberId === currMemberId &&
                      shift.startTime >= currDate
                  ).length}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Hidden mdDown>
          <Grid item>
            <Typography
              variant="subtitle2"
              style={{ marginRight: 4, fontWeight: 300 }}
            >
              Est. {isFamily ? "Salary" : "Income"}:{" "}
              <Typography
                component="span"
                color="secondary"
                style={{ fontWeight: 550 }}
              >
                {!isInitialSet ? "-" : `$${numberWithCommas(grossIncome)}`}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item>
          <Typography
            variant="subtitle2"
            style={{ marginRight: 4, fontWeight: 300 }}
          >
            Period Hours:{" "}
            <Typography
              component="span"
              color="secondary"
              style={{ fontWeight: 550 }}
            >
              {!isInitialSet ? "-" : totalHours}
            </Typography>
          </Typography>
        </Grid>
        {/* <Hidden smDown>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              align="center"
              style={{ marginRight: 4, fontWeight: 300 }}
            >
              Pending Approval:{" "}
              <Typography
                component="span"
                color="secondary"
                style={{ fontWeight: 550 }}
              >
                {!isInitialSet ? "-" : shiftsPendApproval}
              </Typography>
            </Typography>
          </Grid>
        </Hidden> */}
      </Grid>
    );
  }
}

export default withStyles(styles)(ShiftTableLabels);
