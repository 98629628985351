import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Component imports
import InjectedCheckoutForm from "../../../../components/inputs/Stripe/InjectedCheckoutForm";

import { capitalizeFirstChar } from "../../../../util/utilFunctions";

// Redux import
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/uiActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class PaymentSetup extends Component {
  state = { paymentDia: false, paymentCheck: false };

  componentDidMount() {
    this.setPaymentCheck();
  }

  setPaymentCheck() {
    const { credentials } = this.props;
    if (credentials.status.isPaymentMethod) {
      this.setState({ paymentCheck: true }, () =>
        setTimeout(() => this.props.handlePayment(), 3000)
      );
    }
  }

  handleOpenPayDia = () => {
    this.setState({ paymentDia: true });
  };

  handleClosePayDia = () => {
    this.setState({ paymentDia: false });
  };

  handleCompletePaymentMethod = () => {
    this.props.setAlert({ message: "Card details added", type: "success" });
    this.props.handlePayment();
  };

  render() {
    const { credentials, classes, userData } = this.props;

    const { paymentDia, paymentCheck } = this.state;
    return (
      <Fragment>
        <Fade in>
          <Paper
            elevation={4}
            className={clsx(
              classes.smallPaperContainer,
              classes.darkGrayBorderOnly
            )}
            style={{ padding: 16 }}
          >
            <Typography
              align="center"
              color="primary"
              variant="h6"
              style={{ marginBottom: 16 }}
            >
              Step 1: Add Payment Method
            </Typography>
            {paymentCheck ? (
              <Typography
                align="center"
                color="textSecondary"
                style={{ margin: "24px 0px" }}
              >
                We already have a card on file for you!
              </Typography>
            ) : (
              <div>
                <Typography align="left" color="textSecondary" fontWeight={300}>
                  It seems that we don't have a payment method on file for you.
                </Typography>
                <Typography
                  align="left"
                  color="textSecondary"
                  style={{ margin: "24px 0px" }}
                  fontWeight={300}
                >
                  Add a credit to hire and pay{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {capitalizeFirstChar(userData.firstName)}
                  </span>
                  . Your card will only be charged after services are provided
                  and invoices provided. You will have 2 days to review and
                  modify charges before your card is charge automatically.
                </Typography>

                <Grid container justifyContent="center">
                  <Button
                    color="primary"
                    className={classes.txtTrButton}
                    variant="outlined"
                    onClick={() => this.props.handlePaymentBack()}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    className={classes.txtTrButton}
                    variant="contained"
                    onClick={this.handleOpenPayDia}
                  >
                    Add
                  </Button>
                </Grid>
              </div>
            )}
          </Paper>
        </Fade>
        <Dialog
          open={paymentDia}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClosePayDia}
        >
          <Box
            className={classes.headerBarRounded}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Add a new payment method</Typography>
            <IconButton size="small" onClick={this.handleClosePayDia}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText component="div">
              Review and check that the banking information entered is correct
              and{" "}
              <Box display="inline" style={{ fontWeight: 550 }}>
                accurate
              </Box>
              . This card will be charged automatically for your caregiving
              services as provided.
            </DialogContentText>

            <InjectedCheckoutForm
              myInfo={credentials}
              completePaymentMethod={this.handleCompletePaymentMethod}
            />
            <Box className={classes.flexBox} style={{ marginTop: 30 }}>
              <LockIcon style={{ fontSize: 12 }} />
              <Typography color="textSecondary" style={{ fontSize: 10 }}>
                Secure transaction SSL encryption
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

PaymentSetup.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object,
  userData: PropTypes.object.isRequired,
  handlePayment: PropTypes.func.isRequired,
  handlePaymentBack: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
});
export default connect(mapStateToProps, { setAlert })(
  withStyles(styles)(PaymentSetup)
);
