import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import LocationSearch from "../../../../components/inputs/LocationSearch";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// Icons Imports
import SearchIcon from "@mui/icons-material/Search";

const styles = (theme) => ({
  ...theme.spreadThis,
  button: {
    height: "100%",
    boxShadow: "none",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      boxShadow: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
  },
});

export class SearchHeader extends Component {
  state = { showFilter: false, gender: "" };
  // UI Methods

  handleAddress = (v) => {
    this.props.handleAddress(v);
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.onSearch();
  };

  render() {
    const { errors, classes } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              backgroundColor: "primary.dark",
              padding: 2,
            }}
          >
            <div className={classes.flexBoxCentered}>
              <Typography
                variant="h5"
                style={{
                  padding: "8px 16px",
                  margin: 8,
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: 8,
                  color: "#ffffff",
                }}
              >
                Search for your next job
              </Typography>
            </div>

            <form onSubmit={this.handleSearch} style={{ marginTop: 16 }}>
              <div
                className={classes.flexBoxCentered}
                style={{ width: "100%" }}
              >
                <div style={{ flex: 1, maxWidth: 500 }}>
                  <LocationSearch
                    onSelectAddress={this.handleAddress}
                    errorAddress={errors.addressFull}
                    placeholder="Search by city or postal code"
                    label=" "
                    style={{
                      backgroundColor: "white",
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      margin: 0,
                    }}
                    city
                    straightRight
                    showOneResult
                  />
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SearchIcon />}
                  className={classes.button}
                  style={{ height: 40 }}
                >
                  Search
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

SearchHeader.propTypes = {
  classes: PropTypes.object.isRequired,

  onSearch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  handleAddress: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchHeader);
