import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import ArrayButtonSelect from "../../../../components/inputs/ArrayButtonSelect";
import Stepper from "../../../../components/layout/Components/Stepper";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Icons imports
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
  serviceButton: {
    width: "200px",
    margin: 10,
    backgroundColor: "white",
  },
  serviceButtonContained: {
    width: "200px",
    margin: 10,
  },
});

export class SFConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: { errorConditions: null },
      conditionsArray: [],
    };
  }

  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    const { conditionsArray } = this.props;
    if (conditionsArray.length > 0) this.setState({ conditionsArray });
  }
  // Error Validation Methods

  validateSubmit = () => {
    const { conditionsArray } = this.state;
    if (conditionsArray.length < 1) {
      this.setState({
        errors: {
          errorConditions: "Please select at least one condition.",
        },
      });
    }
  };

  // Action Methods

  handleConditionsArray = (e) => {
    let value = e.currentTarget.value;
    const { conditionsArray } = this.state;
    let _conditionsArray = conditionsArray;

    if (!_conditionsArray.includes(value)) {
      _conditionsArray.push(value);
    } else {
      let pos = _conditionsArray.findIndex((k) => k === value);
      _conditionsArray.splice(pos, 1);
    }
    this.setState({
      conditionsArray: _conditionsArray,
      errors: { errorConditions: null },
    });
  };

  handleSubmit = async () => {
    await this.validateSubmit();
    const { errors, conditionsArray } = this.state;
    if (!Boolean(errors.errorConditions)) {
      this.props.onSelectConditions({
        conditionsArray,
      });
    }
  };

  handleBack = () => {
    this.props.onSelectConditions({
      pages: { p1: true, p2: false, p3: false },
    });
  };

  render() {
    const { classes, conditionsObj, total, fadeIn } = this.props;
    const { errors, conditionsArray } = this.state;

    return (
      <Box className={classes.form} p={2}>
        <Stepper total={total} fadeIn={fadeIn} />
        <br />

        <Typography
          variant="h6"
          color="primary"
          align="center"
          style={{ margin: "10px 0px", fontWeight: 300 }}
          className={classes.typography}
        >
          Special Conditions
        </Typography>
        <Box className={classes.sectionBoxLeftOutline}>
          <Typography variant="body2" style={{ fontWeight: 300 }}>
            Let us know if you have any special conditions that need attention
            so we may better match you with a qualified caregiver.
          </Typography>
          {Boolean(errors.errorConditions) && (
            <Typography
              variant="caption"
              style={{ fontWeight: 300, color: "#f44336" }}
            >
              {errors.errorConditions}
            </Typography>
          )}

          <ArrayButtonSelect
            valueObj={conditionsObj}
            value={conditionsArray}
            handleSelectValue={this.handleConditionsArray}
            error={Boolean(errors.errorConditions)}
            showIcon
            selIcon={<LocalHospitalIcon />}
            unselIcon={<LocalHospitalOutlinedIcon />}
          />
        </Box>

        <br />
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={this.handleBack}
            style={{
              marginRight: 10,
              width: 100,
              backgroundColor: "white",
              textTransform: "none",
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{ width: 100, textTransform: "none" }}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  }
}

SFConditions.propTypes = {
  onSelectConditions: PropTypes.func.isRequired,
  conditionsArray: PropTypes.array,
  total: PropTypes.number.isRequired,
  fadeIn: PropTypes.number.isRequired,
};

export default withStyles(styles)(SFConditions);
