import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Component imports
import TooltipButton from "../../../../components/inputs/TooltipButton";
import {
  capitalizeFirstChar,
  allFalse,
  isEmpty,
} from "../../../../util/utilFunctions";
import StyledToggleButtonGroup from "../../../../components/inputs/StyledToggleButtonGroup";
import StyledNumberInput from "../../../../components/inputs/StyledNumberInput";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/uiActions";

import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import EditIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LanguageIcon from "@mui/icons-material/Language";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const PrettySlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    // marginTop: -8,
    // marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

export class EditAboutMe extends Component {
  state = {
    errors: {},
    open: false,
    bio: "",
    languagePref: [],
    wageExp: "",
    workRadius: "",
    yearsExp: 0,
    languageAll: [],
    disableWhileLoad: false,
  };

  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    const {
      languageAll,
      user: {
        credentials: { bio, languagePref, wageExp, workRadius, yearsExp },
      },
    } = this.props;
    this.setState({
      bio: bio,
      languagePref: languagePref.slice(),
      wageExp: wageExp,
      workRadius: workRadius,
      languageAll: languageAll,
      yearsExp: yearsExp,
      errors: {},
      disableWhileLoad: false,
    });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI methods
  handleOpen = async () => {
    await this.setInitial();
    this.setState({
      open: true,
    });
  };

  handleClose = (event, reason) => {
    if (reason !== "backdropClick") this.setState({ open: false });
  };

  // Action methods

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: isEmpty(value) && "Must not be empty",
      },
    });
  };

  handleYearsExp = (e, v) => {
    this.setState({ yearsExp: v });
  };

  handleLanguage = (e) => {
    let value = e.currentTarget.name;
    const { languagePref } = this.state;
    let _languagePref = languagePref;

    if (!languagePref.includes(value)) {
      _languagePref.push(value);
    } else {
      _languagePref = _languagePref.filter((lang) => lang !== value);
    }
    this.setState({
      languagePref: _languagePref,
      errors: {
        ...this.state.errors,
        languagePref:
          _languagePref.length < 1 && "Must select at least 1 language.",
      },
    });
  };

  handleToggleWorkRadius = (e, value) => {
    if (value !== null) {
      this.setState({
        workRadius: value,
        errors: { ...this.state.errors, workRadius: null },
      });
    }
  };

  // Error validation methods
  validateSubmit = () => {
    const { bio, wageExp, languagePref, errors } = this.state;
    let _errors = errors;
    if (languagePref.length < 1)
      _errors.languagePref = "Must select at least 1 language.";
    if (isEmpty(wageExp)) _errors.wageExp = "Must select at least 1 language.";
    if (isEmpty(bio)) _errors.bio = "Must select at least 1 language.";

    this.setState({ errors: _errors });
  };

  reduceInput = () => {
    const { bio, wageExp, languagePref, workRadius, yearsExp } = this.state;
    const {
      user: { credentials },
    } = this.props;
    const userDetails = {};
    if (bio !== credentials.bio) userDetails.bio = bio;
    if (wageExp !== credentials.wageExp) userDetails.wageExp = wageExp;
    if (yearsExp !== credentials.yearsExp) userDetails.yearsExp = yearsExp;
    if (
      credentials.languagePref.length === languagePref.length &&
      credentials.languagePref.every((v) => languagePref.includes(v))
    ) {
    } else {
      userDetails.languagePref = languagePref;
    }
    if (workRadius !== credentials.workRadius)
      userDetails.workRadius = workRadius;
    return { userDetails, valid: Object.keys(userDetails).length > 0 };
  };

  // Submit functions

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.setStateAsync({ disableWhileLoad: true });

    await this.validateSubmit();

    const noError = allFalse(this.state.errors);
    if (noError) {
      const { userDetails, valid } = this.reduceInput();

      if (valid) {
        try {
          await axios.post("/user", userDetails);
          this.props.setAlert({
            message: "Profile successfully updated",
            type: "success",
          });
          this.handleClose();
        } catch (err) {
          console.error(err);
          await this.setStateAsync({
            disableWhileLoad: false,
            errors: err.response.data,
          });

          return;
        }
      } else {
        this.handleClose();
      }
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      errors,
      bio,
      languagePref,
      wageExp,
      yearsExp,
      workRadius,
      languageAll,
      disableWhileLoad,
    } = this.state;

    return (
      <Fragment>
        <TooltipButton
          onClick={this.handleOpen}
          tip="Edit About Me"
          size="small"
          tipClassName={classes.expandButton}
        >
          <EditIcon color="primary" />
        </TooltipButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
          disableEscapeKeyDown
        >
          <DialogContent className={classes.dialogContent}>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" className={classes.typography}>
                  Edit About Me
                </Typography>

                <TooltipButton
                  tip="Cancel"
                  placement="top"
                  onClick={this.handleClose}
                  tipClassName={classes.closeButton}
                >
                  <CloseIcon color="primary" />
                </TooltipButton>
              </Box>

              <Divider />
              <br />
              <form noValidate onSubmit={this.handleSubmit}>
                <Typography
                  variant="body2"
                  className={classes.typography}
                  style={{ fontWeight: 300, marginBottom: 5 }}
                >
                  Families love to get to know their caregivers before working
                  together. Tell your families a little about yourself.
                </Typography>
                <TextField
                  variant="outlined"
                  label="About me"
                  name="bio"
                  placeholder="I am a..."
                  error={Boolean(errors.bio)}
                  helperText={Boolean(errors.bio) ? errors.bio : " "}
                  onChange={this.handleChange}
                  className={
                    !Boolean(errors.bio)
                      ? classes.styledTextField
                      : classes.styledTextFieldError
                  }
                  value={bio}
                  size="small"
                  minRows="5"
                  multiline
                  fullWidth
                />

                <Typography
                  variant="body2"
                  className={classes.typography}
                  style={{ fontWeight: 300 }}
                >
                  Wage Expectation:
                </Typography>

                <StyledNumberInput
                  variant="outlined"
                  color="primary"
                  placeholder="e.g. 20.00"
                  type="number"
                  step="0.01"
                  value={parseFloat(wageExp).toFixed(2)}
                  onChange={this.handleChange}
                  name="wageExp"
                  size="small"
                  id="formatted-numberformat-input"
                  error={Boolean(errors.wageExp)}
                  helperText={Boolean(errors.wageExp) ? errors.wageExp : ""}
                  className={
                    !Boolean(errors.wageExp)
                      ? classes.styledTextField
                      : classes.styledTextFieldError
                  }
                  fullWidth
                />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.typography}
                  style={{ fontStyle: "italic" }}
                >
                  *Please ensure that your desired wage is above your provincial
                  minimum wage requirement
                </Typography>

                <Typography
                  variant="body2"
                  className={classes.typography}
                  style={{ margin: "16px 0px", fontWeight: 300 }}
                >
                  How many years of experience do you have?
                </Typography>
                <PrettySlider
                  key={`slider-yearsExp`}
                  defaultValue={yearsExp}
                  value={yearsExp}
                  getAriaValueText={(v) => v}
                  onChange={this.handleYearsExp}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={30}
                />
                <Typography color="secondary" align="center">
                  {yearsExp} years
                </Typography>

                <Typography
                  variant="body2"
                  className={classes.typography}
                  style={{ fontWeight: 300, marginTop: 16 }}
                >
                  What languages do you speak?
                </Typography>

                {errors.languagePref && (
                  <Typography
                    variant="caption"
                    style={{ color: "#f44336", fontWeight: 300 }}
                  >
                    {errors.languagePref}
                  </Typography>
                )}

                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  style={{ marginTop: 4 }}
                >
                  {languageAll.map((lang) => (
                    <Grid item key={lang}>
                      <StyledButton
                        name={lang}
                        onClick={this.handleLanguage}
                        fullWidth
                        className={
                          Boolean(errors.languagePref)
                            ? languagePref.includes(lang)
                              ? classes.errorSelectedToggleButton
                              : classes.errorUnselectedToggleButton
                            : languagePref.includes(lang)
                            ? classes.selectedToggleButton
                            : classes.unselectedToggleButton
                        }
                        style={{ paddingRight: 10, paddingLeft: 10 }}
                      >
                        <LanguageIcon />

                        <Typography
                          component="p"
                          align="center"
                          color={
                            !Boolean(errors.languagePref) &&
                            !languagePref.includes(lang)
                              ? "textSecondary"
                              : "inherit"
                          }
                          style={{
                            fontSize: "0.9rem",
                            marginLeft: 5,
                          }}
                          noWrap
                        >
                          {capitalizeFirstChar(lang)}
                        </Typography>
                      </StyledButton>
                    </Grid>
                  ))}
                </Grid>

                <Typography
                  variant="body2"
                  color={errors.workRadius ? "error" : "textPrimary"}
                  className={classes.typography}
                  style={{ fontWeight: 300, marginTop: 16 }}
                >
                  I am comfortable working within
                </Typography>
                <div style={{ marginTop: 4 }}>
                  <StyledToggleButtonGroup
                    name="workRadius"
                    value={workRadius}
                    handleToggleValue={this.handleToggleWorkRadius}
                    options={{
                      "10 km": "10",
                      "15 km": "15",
                      "25 km": "25",
                      "50 km": "50",
                    }}
                    error={Boolean(errors.workRadius)}
                    errorMsg={errors.workRadius}
                    exclusive
                  />
                </div>

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    className={classes.button}
                    disabled={disableWhileLoad}
                    onClick={this.handleSubmit}
                    style={{ width: 100 }}
                  >
                    Update
                    {disableWhileLoad && (
                      <CircularProgress
                        thickness={2}
                        size={30}
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

EditAboutMe.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { setAlert })(
  withStyles(styles)(EditAboutMe)
);
