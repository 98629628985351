import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { compose } from "redux";
import { withRouter } from "react-router";

// Component imports
import AnimatedCheck from "../../assets/AnimatedCheck";
import { allFalse, checkAll } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";

const styles = (theme) => ({
  ...theme.spreadThis,
  paperContainer: {
    borderRadius: 10,
    padding: 30,
    border: `1px solid rgba(101,101,101,0.5)`,
  },
  linkText: {
    fontWeight: 550,
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
});

function FlexBox(props) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    />
  );
}

function CSSBox(props) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{
        height: 20,
        paddingTop: 2,
        paddingBottom: 2,
        width: "50%",
        minWidth: 280,
      }}
      {...props}
    />
  );
}

export class confirmResetPassword extends Component {
  state = {
    code: null,
    codeVerified: false,
    errorCode: false,
    errorCodeMessage: "",
    newPassword: "",
    confirmNewPassword: "",
    timer: 10,
    resetComplete: false,
    errors: { errorAllCheck: true },
    passwordChecker: {
      isCapital: false,
      isLower: false,
      isNumber: false,
      isLength: false,
    },
    disableWhileLoad: false,
  };

  // Load methods
  componentDidMount() {
    this.validateResetCode();
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshInterval);
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  validateResetCode = async () => {
    const queryString = this.props.location.search;
    let code = queryString.split("oobCode=")[1].split("&")[0];
    await this.setStateAsync({ code });

    try {
      await axios.post("/forgot_password/verify", { code });
      this.setState({ codeVerified: true });
    } catch (err) {
      this.setState({
        errorCode: true,
        errorCodeMessage: err.response.data.message,
      });
      return;
    }
  };

  // Error Validator Methods

  handleValidateConfirmNewPassword = (password) => {
    const { newPassword } = this.state;

    let passwordSameOrGreaterLength = false;
    passwordSameOrGreaterLength = password.length >= newPassword.length;

    return (
      passwordSameOrGreaterLength && newPassword.trim() !== password.trim()
    );
  };

  checkErrors = async () => {
    await this.setStateAsync({ disableWhileLoad: true });

    const { errors, newPassword, confirmNewPassword } = this.state;

    let _errors = errors;
    if (newPassword !== confirmNewPassword) {
      _errors.confirmNewPassword = "Passwords must match";
    }

    if (newPassword === "") {
      _errors.newPassword = "Must not be empty";
    } else if (errors.errorAllCheck) {
      _errors.newPassword = "Password does not meet safety requirements";
    }

    await this.setStateAsync({ errors: _errors });
  };

  // Helper Methods

  handleResetComplete = async () => {
    await this.setStateAsync({ resetComplete: true });
    const refreshInterval = setInterval(() => {
      if (this.state.timer > 0)
        this.setState((prevState) => {
          return { ...prevState, timer: prevState.timer - 1 };
        });
    }, 1000);
    setTimeout(() => {
      this.props.history.push("/login");
    }, 10000);
    await this.setStateAsync({ refreshInterval, disableWhileLoad: false });
  };
  //Action Methods

  handlePassword = (e) => {
    let value = e.target.value.trim();

    const { isCapital, isLower, isNumber, isLength, allCheck } =
      checkAll(value);
    this.setState({
      newPassword: value,
      passwordChecker: {
        isCapital,
        isLower,
        isNumber,
        isLength,
      },
      errors: {
        ...this.state.errors,
        errorAllCheck: !allCheck,
        newPassword: value === "" ? "Must not be empty" : null,
        confirmNewPassword:
          value === this.state.confirmNewPassword
            ? null
            : this.state.errors.confirmNewPassword,
      },
    });
  };

  handleConfirmPassword = (e) => {
    let value = e.target.value.trim();

    let notMatch = this.handleValidateConfirmNewPassword(value);
    if (this.state.newPassword === "" && notMatch) notMatch = false;

    this.setState({
      confirmNewPassword: value,
      errors: {
        ...this.state.errors,
        confirmNewPassword:
          value === ""
            ? "Must not be empty"
            : notMatch
            ? "Passwords must match"
            : null,
      },
    });
  };

  handleSubmitNewPassword = async (e) => {
    const { errors, newPassword, confirmNewPassword, code } = this.state;
    e.preventDefault();
    await this.checkErrors();
    const noErrors = allFalse(errors);
    if (noErrors) {
      try {
        await axios.post("/forgot_password/confirm", {
          newPassword,
          confirmNewPassword,
          code,
        });

        this.handleResetComplete();
      } catch (err) {
        console.error(err.response.data);
        await this.setStateAsync({
          disableWhileLoad: false,
          errors: { ...this.state.errors, ...err.response.data },
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    const {
      codeVerified,
      errorCode,
      errorCodeMessage,
      newPassword,
      confirmNewPassword,
      errors,
      disableWhileLoad,
      resetComplete,
      timer,
      passwordChecker: { isCapital, isLower, isNumber, isLength },
    } = this.state;

    const passwordCheckers = [
      "Includes at least 1 upper case letter",
      "Includes at least 1 lower case letter",
      "Includes at least 1 digit",
      "Minimum 8 characters in total",
    ];

    const displayError = (
      <Fade in={errorCode} mountOnEnter>
        <div>
          <FlexBox>
            <ErrorIcon
              color="error"
              style={{ opacity: 0.75, fontSize: "5em" }}
            />
          </FlexBox>
          <Typography color="textSecondary" style={{ marginTop: 15 }}>
            Uh oh... there seems to be a problem. {errorCodeMessage}
          </Typography>
          <div style={{ marginTop: 50, textAlign: "center" }}>
            <Typography
              component={Link}
              to="/login"
              className={classes.linkText}
            >
              Back to login
            </Typography>
          </div>
        </div>
      </Fade>
    );

    const displayResetPassword = (
      <Fade in={codeVerified} mountOnEnter>
        <form onSubmit={this.handleSubmitNewPassword}>
          <Typography color="textSecondary">
            Enter your new password.
          </Typography>

          <TextField
            value={newPassword}
            name="newPassword"
            label="Password"
            type="password"
            variant="outlined"
            disabled={resetComplete}
            onChange={this.handlePassword}
            error={Boolean(errors.newPassword)}
            helperText={Boolean(errors.newPassword) ? errors.newPassword : " "}
            fullWidth
            className={
              !errors.newPassword
                ? classes.styledTextField
                : classes.styledTextFieldError
            }
            style={{ marginTop: 15 }}
          />
          <TextField
            value={confirmNewPassword}
            name="confirmNewPassword"
            label="Confirm Password"
            type="password"
            variant="outlined"
            disabled={resetComplete}
            onChange={this.handleConfirmPassword}
            error={Boolean(errors.confirmNewPassword)}
            helperText={
              Boolean(errors.confirmNewPassword)
                ? errors.confirmNewPassword
                : " "
            }
            fullWidth
            className={
              !errors.confirmNewPassword
                ? classes.styledTextField
                : classes.styledTextFieldError
            }
            style={{ marginTop: 10 }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginTop: 10, fontWeight: 550 }}
          >
            Password Requirements
          </Typography>
          <Divider style={{ marginBottom: 5 }} />

          {passwordCheckers.map((k, i) => {
            let notError =
              i === 0
                ? isCapital
                : i === 1
                ? isLower
                : i === 2
                ? isNumber
                : isLength;
            return (
              <CSSBox key={i}>
                <Typography
                  component="div"
                  variant="caption"
                  color={notError ? "textSecondary" : "error"}
                >
                  {k}
                </Typography>
                {notError ? (
                  <AnimatedCheck />
                ) : (
                  <CancelIcon color="error" style={{ fontSize: 16 }} />
                )}
              </CSSBox>
            );
          })}

          <div style={{ marginTop: 120, marginBottom: 12 }}>
            {resetComplete && (
              <div className={classes.flexBoxCenteredColumn}>
                <AnimatedCheck large />
                <Typography
                  sx={{ color: "success.main" }}
                  align="center"
                  style={{ margin: "8px 0px" }}
                >
                  Password reset success! Redirecting to login page in {timer}{" "}
                  or{" "}
                  <Box
                    display="inline"
                    component={Link}
                    to="/login"
                    color="inherit"
                    fontWeight="bold"
                    className={classes.typographyHover}
                  >
                    CLICK HERE
                  </Box>
                </Typography>
              </div>
            )}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={disableWhileLoad || resetComplete}
            style={{
              textTransform: "none",
              borderRadius: 10,
              fontWeight: 550,
              padding: 12,
              color: "white",
            }}
          >
            Reset Password{" "}
            {!resetComplete && disableWhileLoad && (
              <CircularProgress
                thickness={2}
                size={25}
                className={classes.progress}
                style={{ right: 0, marginRight: 5 }}
              />
            )}
          </Button>
        </form>
      </Fade>
    );
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ alignSelf: "center", padding: "36px 12px" }}
      >
        <Grid item xs={11} sm={7} md={6}>
          <Paper elevation={4} className={classes.paperContainer}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ position: "relative", margin: "0px 30px 20px 30px" }}
            >
              <Typography variant="body2" style={{ fontWeight: 550 }}>
                Password Reset
              </Typography>
            </Box>
            <Divider style={{ marginBottom: 20 }} />
            {!codeVerified && !errorCode && (
              <Fragment>
                <FlexBox>
                  <CircularProgress thickness={2} size={50} />
                </FlexBox>
                <Typography
                  align="center"
                  color="textSecondary"
                  style={{ marginTop: 15 }}
                >
                  Validating password reset request
                </Typography>
              </Fragment>
            )}
            {displayError}
            {displayResetPassword}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

confirmResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(confirmResetPassword);
