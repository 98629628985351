import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
// Component imports
import DateSelector from "../../../../../components/inputs/DateSelector";
import { allFalse, isEmpty } from "../../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class EducationMarkup extends Component {
  state = {
    errors: {},
    school: "",
    degree: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
    description: "",
  };

  componentDidMount() {
    this.setEditMode();
  }

  setEditMode() {
    const { eduAccred, isEdit } = this.props;
    isEdit && this.setState({ ...eduAccred });
  }

  // Error validation methods

  validateStartDate = (value) => {
    const { errors, endDate } = this.state;
    this.setState({
      errors: {
        ...errors,
        startDate:
          !isEmpty(endDate) &&
          dayjs(value).toISOString() > endDate &&
          "Must be less than end date",
        endDate:
          !isEmpty(endDate) &&
          dayjs(value).toISOString() > endDate &&
          "Must be greater than start date",
      },
    });
  };

  validateEndDate = (value) => {
    const { errors, startDate } = this.state;
    this.setState({
      errors: {
        ...errors,
        endDate:
          !isEmpty(startDate) &&
          dayjs(value).toISOString() < startDate &&
          "Must be greater than start date",
        startDate:
          !isEmpty(startDate) &&
          dayjs(value).toISOString() < startDate &&
          "Must be less than end date",
      },
    });
  };

  validateSubmit = async () => {
    const { school, errors, endDate, startDate } = this.state;
    this.setState({
      errors: {
        ...errors,
        school: isEmpty(school) && "Must not be empty",
        endDate: isEmpty(endDate)
          ? "Must not be empty"
          : !isEmpty(startDate) &&
            endDate < startDate &&
            "Must be greater than start date",
      },
    });
  };

  //  Action Methods

  handleSchool = (e) => {
    let value = e.target.value;
    this.setState({
      school: value,
      errors: {
        ...this.state.errors,
        school: isEmpty(value) && "School must not be empty",
      },
    });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStartDate = async (value) => {
    await this.validateStartDate(value);

    this.setState({ startDate: dayjs(value).toISOString() });
  };
  handleEndDate = async (value) => {
    await this.validateEndDate(value);

    this.setState({ endDate: dayjs(value).toISOString() });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.validateSubmit();
    const {
      errors,
      school,
      degree,
      fieldOfStudy,
      startDate,
      endDate,
      description,
    } = this.state;
    const noError = allFalse(errors);
    if (noError) {
      let newEduAccred = {
        school,
        degree,
        fieldOfStudy,
        startDate,
        endDate,
        description,
        type: "education",
      };
      this.props.handleSubmit(newEduAccred);
    } else {
      console.error(errors);
    }
  };

  render() {
    const { classes, disableWhileLoad, isEdit } = this.props;
    const {
      errors,
      school,
      degree,
      fieldOfStudy,
      startDate,
      endDate,
      description,
    } = this.state;
    return (
      <Fade in>
        <form onSubmit={this.handleSubmit}>
          <Typography
            variant="body2"
            color={Boolean(errors.school) ? "error" : "inherit"}
            className={classes.typography}
          >
            School*
          </Typography>
          <TextField
            variant="outlined"
            name="school"
            onChange={this.handleSchool}
            placeholder="Ex: Vancouver Community College..."
            value={school}
            error={Boolean(errors.school)}
            helperText={errors.school}
            className={
              Boolean(errors.school)
                ? classes.styledTextFieldError
                : classes.styledTextField
            }
            fullWidth
            size="small"
          />
          <Typography
            variant="body2"
            className={classes.typography}
            style={{ marginTop: 16 }}
          >
            Degree
          </Typography>
          <TextField
            variant="outlined"
            name="degree"
            onChange={this.handleChange}
            value={degree}
            className={classes.styledTextField}
            placeholder="Ex: Certificate..."
            fullWidth
            size="small"
          />
          <Typography
            variant="body2"
            className={classes.typography}
            style={{ marginTop: 16 }}
          >
            Field of study
          </Typography>
          <TextField
            variant="outlined"
            name="fieldOfStudy"
            onChange={this.handleChange}
            value={fieldOfStudy}
            className={classes.styledTextField}
            placeholder="Ex: Nursing..."
            fullWidth
            size="small"
          />

          <Grid
            container
            justifyContent="center"
            spacing={1}
            style={{ marginTop: 16 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                color={Boolean(errors.startDate) ? "error" : "inherit"}
                className={classes.typography}
              >
                Start Year
              </Typography>
              <DateSelector
                variant="outlined"
                size="small"
                placeholder="Start Year..."
                format="YYYY"
                fullWidth
                views={["year"]}
                value={!isEmpty(startDate) && startDate}
                errorDate={Boolean(errors.startDate)}
                errorDateText={errors.startDate}
                onSelectDate={this.handleStartDate}
                propMinDate={dayjs("1960-01-05")}
                propMaxDate={dayjs()}
                className={
                  Boolean(errors.startDate)
                    ? classes.styledTextFieldError
                    : classes.styledTextField
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                color={Boolean(errors.endDate) ? "error" : "inherit"}
                className={classes.typography}
              >
                End Year (or exp.)*
              </Typography>
              <DateSelector
                variant="outlined"
                size="small"
                placeholder="End Year..."
                format="YYYY"
                fullWidth
                views={["year"]}
                value={!isEmpty(endDate) && endDate}
                errorDate={Boolean(errors.endDate)}
                errorDateText={errors.endDate}
                onSelectDate={this.handleEndDate}
                propMinDate={dayjs("1960-01-05")}
                propMaxDate={dayjs("2030-01-05")}
                className={
                  Boolean(errors.endDate)
                    ? classes.styledTextFieldError
                    : classes.styledTextField
                }
              />
            </Grid>
          </Grid>

          <Typography
            variant="body2"
            className={classes.typography}
            style={{ marginTop: 16 }}
          >
            Description
          </Typography>
          <TextField
            variant="outlined"
            name="description"
            onChange={this.handleChange}
            value={description}
            placeholder="What did you learn..."
            className={classes.styledTextField}
            fullWidth
            multiline
            minRows="5"
            size="small"
          />
          <Divider style={{ margin: "8px 0px" }} />
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ textTransform: "none", width: 100 }}
              disabled={disableWhileLoad}
            >
              {isEdit ? "Save" : "Add"}{" "}
              {disableWhileLoad && (
                <CircularProgress
                  thickness={2}
                  size={30}
                  className={classes.progress}
                />
              )}
            </Button>
          </Box>
        </form>
      </Fade>
    );
  }
}

EducationMarkup.propTypes = {
  classes: PropTypes.object.isRequired,
  disableWhileLoad: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  eduAccredId: PropTypes.string,
  eduAccred: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default withStyles(styles)(EducationMarkup);
