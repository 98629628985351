import React, { Component } from "react";
import PropTypes from "prop-types";

import { capitalizeFirstChar } from "../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import InputBase from "@mui/material/InputBase";
import NativeSelect from "@mui/material/NativeSelect";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "8px 26px 8px 12px",
    height: 17,

    color: theme.palette.text.secondary,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(34,137,141,.25)",
    },
  },
}))(InputBase);

export class StyledNativeSelect extends Component {
  handleSelectFilter = (e) => {
    this.props.onSelect(e.target.value);
  };
  render() {
    const {
      values,
      value,
      showAll,
      showAllLast,
      capitalFirst,
      fullWidth,
      name,
      width,
    } = this.props;
    return (
      <NativeSelect
        value={value}
        name={name}
        onChange={this.handleSelectFilter}
        input={<BootstrapInput />}
        style={{
          width: width ? width : 130,
        }}
        fullWidth={fullWidth}
      >
        {showAll && (
          <option aria-label="All" value="">
            All
          </option>
        )}
        {Array.isArray(values)
          ? values.length > 0 &&
            values.map((selectedValue, ind) => (
              <option value={selectedValue} key={ind}>
                {capitalFirst
                  ? capitalizeFirstChar(selectedValue)
                  : selectedValue}
              </option>
            ))
          : Object.keys(values).map((selectedValue, ind) => (
              <option value={values[selectedValue]} key={ind}>
                {capitalFirst
                  ? capitalizeFirstChar(selectedValue)
                  : selectedValue}
              </option>
            ))}
        {showAllLast && (
          <option aria-label="All" value="">
            All
          </option>
        )}
      </NativeSelect>
    );
  }
}

StyledNativeSelect.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export default StyledNativeSelect;
