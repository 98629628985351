import React, { Component, Fragment } from "react";
import axios from "axios";
import PropTypes from "prop-types";

// Redux import
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/uiActions";

import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const styles = (theme) => ({
  ...theme.spreadThis,
  button: {
    textTransform: "none",
    minWidth: 79,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 200,
    },
  },
});
export class BgCheckUploadButton extends Component {
  state = { disableWhileLoad: false };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  handleUploadBgCheck = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    this.uploadBgCheck(formData);
  };

  handleAttachBgCheck = () => {
    const fileInput = document.getElementById("bgCheckInput");
    fileInput.click();
  };

  uploadBgCheck = async (formData) => {
    try {
      await this.setStateAsync({ disableWhileLoad: true });
      if (this.props.onLoad) this.props.onLoad();

      await axios.post("/user/caregiver/background-check", formData);
      if (this.props.onSuccess) this.props.onSuccess();
      this.props.setAlert({
        message: "File successfully uploaded",
        type: "success",
      });
    } catch (err) {
      if (this.props.onError) this.props.onError(err.response.data);
    } finally {
      await this.setStateAsync({ disableWhileLoad: false });
      if (this.props.onFinish) this.props.onFinish();
    }
  };

  render() {
    const { classes, disable, fullWidth } = this.props;
    const { disableWhileLoad } = this.state;

    return (
      <Fragment>
        <input
          type="file"
          id="bgCheckInput"
          hidden="hidden"
          onChange={this.handleUploadBgCheck}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleAttachBgCheck}
          className={classes.button}
          disabled={disableWhileLoad || disable}
          fullWidth={fullWidth}
        >
          Upload
          {disableWhileLoad && (
            <CircularProgress
              thickness={2}
              size={30}
              className={classes.progress}
            />
          )}
        </Button>
      </Fragment>
    );
  }
}

BgCheckUploadButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disable: PropTypes.bool,
  onLoad: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onFinish: PropTypes.func,
  fullWidth: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { setAlert })(
  withStyles(styles)(BgCheckUploadButton)
);
