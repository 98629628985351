import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

// Component imports
import { capitalizeFirstChar } from "../../../util/utilFunctions";

// MUI imports
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';

function ShiftReportsMenuButton(props) {
  const { report } = props;

  return (
    <Alert
      action={
        <Button component={Link} to={`/reports/${report.id}`}>
          View
        </Button>
      }
      severity="info"
      style={{ margin: "8px 0px" }}
    >
      {capitalizeFirstChar(report.caregiverInfo.firstName)} submitted a report
      on {dayjs(report.submitted).format("h:mma MMM DD, YYYY")}
    </Alert>
  );
}

ShiftReportsMenuButton.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ShiftReportsMenuButton;
