import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import DeleteIconButton from "../../../../components/inputs/DeleteIconButton";
import CaregiverEduDialog from "./CaregiverEduDialog";

// Redux imports
import { connect } from "react-redux";
import { deleteEduAccred } from "../../../../redux/actions/userActions";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Icons imports
import SchoolIcon from "@mui/icons-material/SchoolOutlined";
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined";

const styles = (theme) => ({
  ...theme.spreadThis,
  icon: {
    marginTop: 4,
    color: theme.palette.primary.main,
  },
});

export class EduAccred extends Component {
  handleDelete = () => {
    this.props.deleteEduAccred(this.props.eduAccred.eduAccredId);
  };

  render() {
    dayjs.extend(relativeTime);

    const {
      classes,
      eduAccred: {
        type,
        school,
        degree,
        fieldOfStudy,
        startDate,
        endDate,
        description,
        accredName,
        accredDate,
        accredDescription,
        eduAccredId,
      },
      isFamily,
    } = this.props;

    return (
      <Box display="flex" justifyContent="space-between">
        <Box p={1}>
          {type === "education" ? (
            <SchoolIcon className={classes.icon} />
          ) : (
            <AssignmentIcon className={classes.icon} />
          )}
        </Box>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ flex: 1 }}
        >
          <Box>
            <Typography
              style={{ fontWeight: "bold" }}
              className={classes.typography}
            >
              {school ? school : accredName}
            </Typography>
            <Typography variant="body2" className={classes.typography}>
              {degree && `${degree} `} {fieldOfStudy && fieldOfStudy}
            </Typography>

            {type === "education" ? (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.typography}
                style={{ fontWeight: 300 }}
              >
                {startDate && `${dayjs(startDate).format("YYYY")} - `}
                {dayjs(endDate).format("YYYY")}
              </Typography>
            ) : (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.typography}
                style={{ fontWeight: 300 }}
              >
                {dayjs(accredDate).format("YYYY")}
              </Typography>
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: 8 }}
            >
              {description && description}
              {accredDescription && accredDescription}
            </Typography>
          </Box>

          {!isFamily && (
            <Box className={classes.flexBox}>
              <CaregiverEduDialog
                eduAccred={this.props.eduAccred}
                eduAccredId={eduAccredId}
                isEdit
                color="inherit"
              />
              <DeleteIconButton
                color="inherit"
                onDeleteClick={this.handleDelete}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

EduAccred.propTypes = {
  eduAccred: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  deleteEduAccred: PropTypes.func.isRequired,
  isFamily: PropTypes.bool,
};

export default connect(null, { deleteEduAccred })(
  withStyles(styles)(EduAccred)
);
