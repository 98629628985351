import React, { Component } from "react";
import PropTypes from "prop-types";
import { capitalizeFirstChar, splitByCapital } from "../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class SingleObjSelect extends Component {
  state = { allObjValue: {}, currValue: "" };
  componentDidMount() {
    this.setState({
      allObjValue: this.props.allObjValue,
      currValue: this.props.currValue,
    });
  }

  handleObjectChange = (e) => {
    this.setState({ currValue: e.currentTarget.value }, () => {
      this.props.selectValue(this.state.currValue);
    });
  };

  render() {
    const { classes, justifyContent, error } = this.props;
    const { allObjValue, currValue } = this.state;

    let content = Object.keys(allObjValue).map((key) => {
      return (
        <Button
          variant={allObjValue[key] === currValue ? "contained" : "outlined"}
          size="small"
          color="primary"
          className={classes.editItemButton}
          value={allObjValue[key]}
          onClick={this.handleObjectChange}
          key={key}
          style={
            error
              ? {
                  borderColor: "red",
                  backgroundColor:
                    allObjValue[key] === currValue
                      ? "rgba(245, 221,219, 1)"
                      : "transparent",
                  color: "red",
                }
              : {}
          }
        >
          {(capitalizeFirstChar, splitByCapital(splitByCapital(key)))}
        </Button>
      );
    });
    return (
      <Grid
        container
        item
        justifyContent={justifyContent ? justifyContent : "center"}
      >
        {content}
      </Grid>
    );
  }
}

SingleObjSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  allObjValue: PropTypes.object.isRequired,
  currValue: PropTypes.string.isRequired,
  selectValue: PropTypes.func.isRequired,
  justifyContent: PropTypes.string,
  error: PropTypes.bool,
};

export default withStyles(styles)(SingleObjSelect);
