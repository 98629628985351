import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import clsx from "clsx";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

const styles = (theme) => ({
  ...theme.spreadThis,
  counterZero: {
    width: 25,
    height: 25,
  },
  unreadMarker: {
    width: 8,
    height: 8,
    backgroundColor: `${theme.palette.primary.main}`,
    position: "absolute",
    top: 0,
    right: 0,
    marginRight: "2%",
    borderRadius: "50%",
  },
});

export class SmallJobSelect extends Component {
  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };
  render() {
    const { jobData, classes } = this.props;
    return (
      <CardActionArea
        component={Link}
        to={`/profile/jobs/${jobData.id}`}
        style={{
          padding: "12px 4px",
          borderRadius: 8,
          backgroundColor: jobData.isAvailable
            ? "rgba(233,245,232, 0.5)"
            : "rgba(251,231,229,0.5)",
        }}
        className={clsx(classes.flexBox, classes.basicMargin)}
        onClick={this.onClick}
      >
        <Box
          className={classes.flexBoxCentered}
          style={{ flexDirection: "column", marginRight: 8 }}
        >
          <Avatar
            className={
              parseInt(jobData.numApply) === 0
                ? classes.counterZero
                : classes.dashCounterBlue
            }
            style={{ height: 25, width: 25, margin: 0 }}
          >
            {jobData.numApply}
          </Avatar>
          <Typography
            component="div"
            variant="caption"
            style={{ fontWeight: 300 }}
          >
            Apps
          </Typography>
        </Box>
        <Box style={{ width: "calc(100% - 53px)" }}>
          {jobData.jobApps.some((jobApp) => !jobApp.isRead) && (
            <div className={classes.unreadMarker} />
          )}

          <Typography component="div" variant="caption" noWrap>
            {jobData.description}
          </Typography>

          <Typography component="div" variant="caption" color="textSecondary">
            Posted: {dayjs(jobData.createdAt).format("MMM DD, YYYY")}
          </Typography>
        </Box>
      </CardActionArea>
    );
  }
}

SmallJobSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  jobData: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(SmallJobSelect);
