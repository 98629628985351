import React, { Component } from "react";

// Component imports
import NotificationMarkup from "../../../components/layout/Components/Notification/NotificationMarkup";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

export class notifications extends Component {
  render() {
    return (
      <Grid container justifyContent="center" className="res-gr-con">
        <Grid item xs={12} sm={11} md={8}>
          <Paper elevation={4}>
            <NotificationMarkup hideLink onClickLink={() => false} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default notifications;
