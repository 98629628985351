import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter
import { Link } from "react-router-dom";

// Component imports
import { capitalizeFirstChar } from "../../util/utilFunctions";
import ChipArrayDisplay from "../profile/ChipArrayDisplay";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardActionArea from "@mui/material/CardActionArea";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import AssignmentIcon from "@mui/icons-material/Assignment";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  chip: {
    margin: 3,
  },
  jobButton: {
    color: `${theme.palette.primary.main}`,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      color: "white",
    },
  },
  jobCard: {
    padding: 16,
    height: "100%",
    // border: `1px solid ${theme.palette.primary.main}`,
  },
});

export class JobsList extends Component {
  render() {
    const { classes, jobData, userData } = this.props;

    const isApplied = jobData.apps.some(
      (app) => app.userIdNumber === userData.userIdNumber
    );

    const isHired = jobData.hires.some(
      (hire) => hire.userIdNumber === userData.userIdNumber
    );

    const isViewed = jobData.cgViews.some(
      (cg) => cg.userIdNumber === userData.userIdNumber
    );

    return (
      <CardActionArea component={Link} to={`/job/${jobData.jobId}`}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className={classes.jobCard}
        >
          <Grid item>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ fontWeight: "bold" }} align="left">
                {jobData.familyInfo.firstName}
              </Typography>
              {(isHired || isApplied || isViewed) && (
                <Chip
                  label={isHired ? "Hired" : isApplied ? "Applied" : "Viewed"}
                  size="small"
                  className={
                    isHired
                      ? classes.chipGreenOutlined
                      : classes.chipBlueOutlined
                  }
                />
              )}
            </Box>
            <Typography variant="body2" align="left">
              is looking for help with:
            </Typography>
            <ChipArrayDisplay
              icon={<AssignmentIcon />}
              dataArray={Object.keys(jobData.service).sort().slice(0, 4)}
              // className={classes.chipBlue}
              variant="outlined"
              color="secondary"
            />
            {Object.keys(jobData.service).length > 4 && (
              <Typography variant="body2">...and more</Typography>
            )}
          </Grid>
          <Divider style={{ marginTop: 8 }} />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="caption" style={{ marginRight: 10 }}>
                {dayjs(jobData.createdAt).fromNow()}
                {<br />}
                {capitalizeFirstChar(
                  jobData.address.addressParams.locality
                )},{" "}
                {jobData.address.addressParams.adminAreaLevel1.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="text" size="small" className={classes.jobButton}>
                View More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    );
  }
}

JobsList.propTypes = {
  jobData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobsList);
