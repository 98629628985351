import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";

import curve1 from "../../../assets/images/curve.svg";
import curve2 from "../../../assets/images/curve-2.svg";

// Redux imports
import { connect } from "react-redux";
import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

// CSS imports
import "../landing.css";

import withStyles from "@mui/styles/withStyles";

// Component imports
const HeroBanner = lazy(() => import("./components/HeroBanner"));
const CovidDisclaimer = lazy(() => import("./components/CovidDisclaimer"));
const Services = lazy(() => import("./components/Services"));
const HowItWorks = lazy(() => import("./components/HowItWorks"));
const CompAdvantage = lazy(() => import("./components/CompAdvantage"));
const Pricing = lazy(() => import("./components/Pricing"));
const GetStarted = lazy(() => import("./components/GetStarted"));

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class home extends Component {
  componentDidMount() {
    store.dispatch({ type: SET_PAGE, payload: "Home" });
  }

  componentWillUnmount() {
    store.dispatch({ type: UNSET_PAGE });
  }

  render() {
    return (
      <div className="home-wrapper">
        <Suspense fallback={<div></div>}>
          <section className="full-vw-container hero-img">
            <HeroBanner />
            <div className="curves">
              <img
                src={curve1}
                alt="curve1"
                draggable="false"
                className="curve"
              />
              <img
                src={curve2}
                alt="curve2"
                draggable="false"
                className="curve"
              />
            </div>
          </section>

          <section className="stn-margin">
            <CovidDisclaimer />
          </section>

          <section className="services stn-margin">
            <Services />
          </section>

          <section className="comp-advan stn-margin">
            <HowItWorks />
          </section>

          <section className="comp-advan stn-margin">
            <CompAdvantage />
          </section>

          <section className="stn-margin">
            <Pricing />
          </section>

          <section className=" get-started full-vw-container stn-margin">
            <GetStarted />
          </section>
        </Suspense>
      </div>
    );
  }
}

home.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps)(withStyles(styles)(home));
