import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Component imports
import JobsCard from "../../../../components/jobs/JobsCard";

import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Icons imports
import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";

const styles = (theme) => ({
  ...theme.spreadThis,
  linkText: {
    textDecoration: "underline",
    fontWeight: 350,
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: 550,
    },
  },
  iconTextSecondary: {
    fontSize: 56,
    color: theme.palette.text.secondary,
  },
  reactiveCenterFlexStartContainer: {
    justifyContent: "flex-start",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
});

const CSSTab = withStyles((theme) => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    border: "1px solid rgba(217,217,217)",
  },
  selected: {
    backgroundColor: "rgba(212,212,212,0.3)",
  },
}))(Tab);

export class DashJobSearch extends Component {
  state = { tabValue: 0 };

  setTabValue = (e, v) => {
    this.setState({ tabValue: v });
  };

  render() {
    const { classes, isLoadedJobs, jobs, credentials, favouritesDoc } =
      this.props;
    const { tabValue } = this.state;

    const viewAllCg = !isLoadedJobs ? (
      <Grid item xs={12}>
        <CircularProgress thickness={2} />
      </Grid>
    ) : jobs.length < 1 ? (
      <Grid item xs={12}>
        <SearchIcon className={classes.iconTextSecondary} />
        <Typography color="textSecondary" style={{ fontWeight: 300 }}>
          Oops... It seems we can not find any jobs based on your profile. To
          view all available jobs, click{" "}
          <Box component={Link} to="/jobs" className={classes.linkText}>
            here
          </Box>
          .
        </Typography>
      </Grid>
    ) : (
      jobs.map((job, ind) => (
        <Grid
          item
          xs={12}
          sm={"auto"}
          md={4}
          key={ind}
          className={classes.flexBoxCentered}
        >
          <JobsCard jobData={job} userData={credentials} />
        </Grid>
      ))
    );

    const viewFavourites =
      favouritesDoc.length < 1 ? (
        <Grid item xs={12}>
          <FavoriteIcon className={classes.iconTextSecondary} />
          <Typography color="textSecondary" style={{ fontWeight: 300 }}>
            You do not have any favourited jobs at the moment.
          </Typography>
        </Grid>
      ) : (
        favouritesDoc.map((job, ind) => (
          <Grid
            item
            xs={12}
            sm={"auto"}
            md={3}
            key={ind}
            className={classes.flexBoxCentered}
          >
            <JobsCard jobData={job} userData={credentials} />
          </Grid>
        ))
      );

    return (
      <Paper
        elevation={4}
        className={classes.paperContainerC}
        style={{ minHeight: 400 }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h6" style={{ fontWeight: 300, flex: 1 }}>
            New jobs based on your profile
          </Typography>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.setTabValue}
            style={{ marginLeft: 8 }}
          >
            <CSSTab label="View All" />
            <CSSTab icon={<FavoriteIcon />} />
          </Tabs>
        </Box>
        <Divider />
        <br />
        <Grid
          container
          className={classes.reactiveCenterFlexStartContainer}
          spacing={2}
        >
          {tabValue === 0 && viewAllCg}
          {tabValue === 1 && viewFavourites}
          {isLoadedJobs && (
            <Grid item xs={12}>
              <Button
                component={Link}
                to="/jobs/"
                variant="contained"
                color="primary"
                style={{ borderRadius: 25 }}
              >
                Find more job postings
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
}

DashJobSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoadedJobs: PropTypes.bool.isRequired,
  jobs: PropTypes.array.isRequired,
  credentials: PropTypes.object.isRequired,
  favouritesDoc: PropTypes.array,
};

export default withStyles(styles)(DashJobSearch);
