import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";

// Component imports
import InvoiceItem from "./InvoiceItem";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class InvoiceContent extends Component {
  state = {
    index: 0,
    showInvoice: true,
    filterBy: "Newest",
    filteredInvoices: [],
    isSortedInvoices: false,
  };

  componentDidMount() {
    (async () => {
      await this.filterInvoicesApiData();
      await this._setInitialInvoiceId();
    })();
  }

  filterInvoicesApiData = async () => {
    const { apiInvoices, isFamily } = this.props;
    const { filterBy } = this.state;

    let filteredInvoices = apiInvoices.slice();

    switch (filterBy) {
      case "Newest":
        break;
      case "Oldest":
        filteredInvoices.sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0
        );
        break;

      case "Unpaid":
        filteredInvoices = filteredInvoices.sort((a, b) =>
          a.status.isPaid && !b.status.isPaid
            ? 1
            : !a.status.isPaid && b.status.isPaid
            ? -1
            : 0
        );

        break;
      case "Paid":
        filteredInvoices = filteredInvoices.sort((a, b) =>
          !a.status.isPaid && b.status.isPaid
            ? 1
            : a.status.isPaid && !b.status.isPaid
            ? -1
            : 0
        );

        break;

      case "First Name":
        isFamily
          ? filteredInvoices.sort((a, b) =>
              a.caregiverInfo.firstName < b.caregiverInfo.firstName
                ? -1
                : a.caregiverInfo.firstName > b.caregiverInfo.firstName
                ? 1
                : 0
            )
          : filteredInvoices.sort((a, b) =>
              a.familyInfo.clientFirstName < b.familyInfo.clientFirstName
                ? -1
                : a.familyInfo.clientFirstName > b.familyInfo.clientFirstName
                ? 1
                : 0
            );

        break;
      case "Last Name":
        isFamily
          ? filteredInvoices.sort((a, b) =>
              a.caregiverInfo.lastName < b.caregiverInfo.lastName
                ? -1
                : a.caregiverInfo.lastName > b.caregiverInfo.lastName
                ? 1
                : 0
            )
          : filteredInvoices.sort((a, b) =>
              a.familyInfo.clientLastName < b.familyInfo.clientLastName
                ? -1
                : a.familyInfo.clientLastName > b.familyInfo.clientLastName
                ? 1
                : 0
            );

        break;
      default:
        break;
    }
    await this.setStateAsync({
      filteredInvoices,
      isSortedInvoices: true,
      index: 0,
    });
  };

  _setInitialInvoiceId() {
    const invoiceId = this.props.match.params.invoiceId;
    const { filteredInvoices } = this.state;

    const pos = filteredInvoices.findIndex(
      (invoice) => invoice.id === invoiceId
    );

    if (pos < 0) {
      this.props.history.replace({
        pathname: `/mypay/invoice/${filteredInvoices[0].id}`,
      });
    } else {
      this.props.history.replace({
        pathname: `/mypay/invoice/${invoiceId}`,
      });
      this.setState({ index: pos });
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  setFilter = async (e) => {
    const value = e.target.value;
    await this.setStateAsync({ filterBy: value });
    await this.filterInvoicesApiData();
    this.props.history.replace({
      pathname: `/mypay/invoice/${
        this.state.filteredInvoices[this.state.index].id
      }`,
    });
  };

  handleNextInvoice = async () => {
    await this.setStateAsync({ showInvoice: false });
    const { index, filteredInvoices } = this.state;

    await setTimeout(async () => {
      await this.setStateAsync((prevState) => ({
        index: prevState.index + 1,
      }));
      this.props.history.replace({
        pathname: `/mypay/invoice/${filteredInvoices[index].id}`,
      });
    }, 150);
    setTimeout(() => this.setState({ showInvoice: true }), 150);
  };

  handlePrevInvoice = async () => {
    await this.setStateAsync({ showInvoice: false });
    const { index, filteredInvoices } = this.state;
    await setTimeout(async () => {
      await this.setStateAsync((prevState) => ({
        index: prevState.index - 1,
      }));
      this.props.history.replace({
        pathname: `/mypay/invoice/${filteredInvoices[index].id}`,
      });
    }, 150);
    setTimeout(() => this.setState({ showInvoice: true }), 150);
  };

  render() {
    const { classes, isFamily } = this.props;
    const { index, showInvoice, filterBy, filteredInvoices, isSortedInvoices } =
      this.state;

    return (
      isSortedInvoices && (
        <div style={{ padding: 8 }}>
          <Box
            className={classes.flexBox}
            style={{ margin: "8px 0px", justifyContent: "flex-end" }}
          >
            <Typography color="textSecondary" style={{ marginRight: 4 }}>
              Filter:
            </Typography>
            <FormControl size="small">
              <Select
                native
                value={filterBy}
                onChange={this.setFilter}
                className={classes.styledTextField}
              >
                {[
                  "Newest",
                  "Oldest",
                  "Unpaid",
                  "Paid",
                  "First Name",
                  "Last Name",
                ].map((opt, ind) => (
                  <option key={ind} value={opt}>
                    {opt}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <div
            className={clsx(classes.flexBoxSpaced, classes.grayContainerLight)}
            style={{ padding: 4, borderRadius: 10 }}
          >
            <IconButton
              disabled={index === 0}
              onClick={this.handlePrevInvoice}
              size="large"
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            <div className={classes.form}>
              <Typography variant="body2" color="textSecondary">
                Invoice NO.
              </Typography>
              <Typography variant="body2">
                {filteredInvoices[index].id}
              </Typography>
            </div>
            <IconButton
              disabled={index === filteredInvoices.length - 1}
              onClick={this.handleNextInvoice}
              size="large"
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </div>
          <Fade in={showInvoice}>
            <div style={{ marginTop: 20 }}>
              {isFamily && (
                <div
                  className={classes.flexBoxCentered}
                  style={{ margin: "8px 0px 24px 0px" }}
                >
                  <Button
                    component={Link}
                    to={`/mypay/invoice/${filteredInvoices[index].id}/pay`}
                    variant="outlined"
                    color="secondary"
                    className={classes.txtTrButton}
                    fullWidth
                    style={{ maxWidth: 200 }}
                    disabled={filteredInvoices[index].status.isPaid}
                  >
                    {filteredInvoices[index].status.isPaid
                      ? "Paid"
                      : filteredInvoices[index].status.isApproved
                      ? "Pay Invoice"
                      : "Approve Invoice"}
                  </Button>
                  <Button
                    component={Link} // TODO: complete help button
                    to={`/resources`}
                    color="secondary"
                    className={classes.txtTrButton}
                    fullWidth
                    style={{ maxWidth: 200 }}
                  >
                    Help
                  </Button>
                </div>
              )}
              <InvoiceItem
                invoiceData={filteredInvoices[index]}
                isFamily={isFamily}
              />
            </div>
          </Fade>
        </div>
      )
    );
  }
}

InvoiceContent.propTypes = {
  classes: PropTypes.object.isRequired,
  apiInvoices: PropTypes.array.isRequired,
  isFamily: PropTypes.bool.isRequired,
};

export default compose(withRouter, withStyles(styles))(InvoiceContent);
