import React, { Component } from "react";
import PropTypes from "prop-types";

// import axios from "axios";
import LoadingEllipsis from "../../layout/LoadingEllipsis";
import InvoiceContent from "./InvoiceContent";
import firebase from "../../../Firebase";

import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Icons Imports
import EventIcon from "@mui/icons-material/Event";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class Invoice extends Component {
  state = {
    isLoadedInvoices: false,
    apiInvoices: [],
  };

  componentDidMount() {
    this.getActiveInvoices();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  // Initialize Methods

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  getActiveInvoices = async () => {
    const {
      credentials: { userIdNumber, userType },
    } = this.props;

    const isFamily = userType === "family";

    const db = firebase.firestore();

    const ref = db
      .collection("invoices")
      .where(
        `${isFamily ? "familyInfo" : "caregiverInfo"}.userIdNumber`,
        "==",
        userIdNumber
      );

    const that = this;

    this.unsubscribe = ref
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const apiInvoices = [];
        snapshot.forEach((invoice) => {
          const _invoice = invoice.data();
          _invoice.id = invoice.id;
          apiInvoices.push(_invoice);
        });

        that.setState({
          apiInvoices,
          isLoadedInvoices: true,
        });
      });
  };

  render() {
    const {
      credentials: { userType },
    } = this.props;
    const { isLoadedInvoices, apiInvoices } = this.state;

    const isFamily = userType === "family";

    return (
      <Paper
        elevation={4}
        style={{
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          minHeight: 450,
          border: "1px solid rgb(212,212,212)",
        }}
      >
        {!isLoadedInvoices ? (
          <Box
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            <CircularProgress
              thickness={2}
              size={75}
              style={{ opacity: 0.5 }}
            />
            <LoadingEllipsis
              component="div"
              variant="caption"
              color="textSecondary"
              style={{ marginTop: 10 }}
              prefix="Loading Your Active Timesheet"
            />
          </Box>
        ) : apiInvoices.length < 1 ? (
          <Box
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            <EventIcon color="primary" style={{ fontSize: 70, opacity: 0.5 }} />
            <Typography component="div" variant="caption" color="textSecondary">
              No active nvoices found at this time
            </Typography>
          </Box>
        ) : (
          <InvoiceContent isFamily={isFamily} apiInvoices={apiInvoices} />
        )}
      </Paper>
    );
  }
}

Invoice.propTypes = {
  classes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default withStyles(styles)(Invoice);
