import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
// import createTheme from "@mui/material/styles/createTheme";
import themeFile from "./util/themeFile";
import { AuthProvider } from "./AuthContext";
import firebase from "./Firebase";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import history from "./util/history";

// REDUX
import { Provider, connect } from "react-redux";
import store from "./redux/store";
import { setFBAuthHeader } from "./redux/actions/userActions";
import { SET_INITIAL_LOAD, SET_CREDENTIALS } from "./redux/types";

// Components
import Navbar from "./components/layout/navbar/Navbar";
import FooterMain from "./components/layout/footer/FooterMain";
import AuthRoute from "./util/AuthRoute";
import UnauthRoute from "./util/UnauthRoute";
import AuthCareRoute from "./util/AuthCareRoute";
import AuthFamRoute from "./util/AuthFamRoute";

// Page
import home from "./pages/main/home/home";
import joinPoyo from "./pages/main/join_poyo/joinPoyo";
import covid19 from "./pages/main/covid19/covid19";
import about from "./pages/main/about/about";
import policies from "./pages/main/policies/policies";
import resources from "./pages/main/resources/resources";
import contact from "./pages/main/contact/contact";
import careers from "./pages/main/careers/careers";
import careerSingle from "./pages/main/careers/careerSingle";
import GenericNotFound from "./pages/main/GenericNotFound";
import dashboard from "./pages/main/auth_pages/dashboard";
import login from "./pages/loginSignup/login";
import signup from "./pages/loginSignup/signup";
import forgotPassword from "./pages/loginSignup/forgotPassword";
import confirmResetPassword from "./pages/loginSignup/confirmResetPassword";
import messages from "./pages/main/auth_pages/messages";
import myTeam from "./pages/main/auth_pages/myTeam";
import reports from "./pages/main/auth_pages/reports/reports";
import reviews from "./pages/main/auth_pages/reviews";
import profile from "./pages/main/auth_pages/profile";
import myPay from "./pages/main/auth_pages/myPay";
import applications from "./pages/main/auth_pages/applications";
import invites from "./pages/main/auth_pages/invites";
import invoiceDisplay from "./pages/main/auth_pages/invoiceDisplay";
import notifications from "./pages/main/auth_pages/notifications";

// CG Pages
import caregiverBasicInfo from "./pages/caregiverPages/signupProfileBuild/caregiverBasicInfo";
import caregiverServices from "./pages/caregiverPages/signupProfileBuild/caregiverServices";
import caregiverFinish from "./pages/caregiverPages/signupProfileBuild/caregiverFinish";
import caregiverEducationExperience from "./pages/caregiverPages/signupProfileBuild/caregiverEducationExperience";
import caregiverBanking from "./pages/caregiverPages/profile/caregiverBanking";
import caregiverBg from "./pages/caregiverPages/profile/caregiverBg";
import jobPage from "./pages/caregiverPages/job/jobPage";
import jobSearchPage from "./pages/caregiverPages/job/jobSearchPage";

//Family Pages
import cgPage from "./pages/familyPages/cgPages/cgPage";
import cgHirePage from "./pages/familyPages/cgPages/cgHirePage";
import familyAllJobs from "./pages/familyPages/profile/familyAllJobs";
import familyNewJob from "./pages/familyPages/profile/familyNewJob";
import cgSearchPage from "./pages/familyPages/cgPages/cgSearchPage";
import payInvoice from "./pages/familyPages/payInvoice/payInvoice";

// UI imports
import CenteredCircularLoading from "./components/layout/CenteredCircularLoading";
import FullVH from "./components/layout/FullVH";
import AlertMarkup from "./components/layout/AlertMarkup";
import ScrollToTop from "./util/ScrollToTop";

const theme = responsiveFontSizes(createTheme(themeFile));
const stripePromise = loadStripe(
  "pk_test_51HZPvSAi69rUKdGQ7KkRQV1Z5EKOPatbv5MuZnmpJfnGAE57ODqdDybqwMslVHian2tWKjIqBVQeyLDbPFXqF46n0049aQMtv4"
);
// const { REACT_APP_LOGOUT_KEY } = process.env;

export class App extends Component {
  constructor(props) {
    super(props);
    this.updateToken = this.updateToken.bind(this);
  }
  componentDidMount() {
    // this.setupBeforeUnloadListener();

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
          .onSnapshot(
            (user) => {
              const userData = user.data();
              store.dispatch({ type: SET_CREDENTIALS, payload: userData });
            },
            (error) => {
              console.error("Logging out");
            }
          );
      }
    });

    firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        this.updateToken(idToken);
      } else {
        store.dispatch({ type: SET_INITIAL_LOAD });
      }
    });
  }

  updateToken(idToken) {
    const { authenticated } = this.props.user;
    store.dispatch(setFBAuthHeader(idToken, authenticated));
  }

  // // Things to do before unloading/closing the tab
  // doSomethingBeforeUnload = () => {
  //   const {
  //     authenticated,
  //     loadedUser,
  //     credentials: { userIdNumber },
  //   } = this.props.user;
  //   if (authenticated && loadedUser) {
  //     var client = new XMLHttpRequest();
  //     client.open("POST", "/login/offline/on-close", false); // third parameter indicates sync xhr. :(
  //     client.setRequestHeader("Content-Type", "application/json");

  //     client.send({
  //       userIdNumber,
  //       passcode: REACT_APP_LOGOUT_KEY,
  //     });
  //   } else {
  //     console.log("Not");
  //   }
  // // };

  // // Setup the `beforeunload` event listener
  // setupBeforeUnloadListener = () => {
  //   window.addEventListener("beforeunload", (ev) => {
  //     ev.preventDefault();
  //     return this.doSomethingBeforeUnload();
  //   });
  // };

  render() {
    const { isFooter, initialLoad, page } = this.props.UI;

    return (
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <Router>
              <Elements stripe={stripePromise}>
                <AuthProvider>
                  <Navbar />

                  <AlertMarkup />
                  {initialLoad ? (
                    <div className={isFooter ? "container" : "containerB"}>
                      <main
                        style={
                          page === "Home"
                            ? {
                                marginTop: 0,
                                minHeight: "100vh",
                                maxWidth: "auto",
                              }
                            : {}
                        }
                      >
                        <ScrollToTop>
                          <Switch>
                            <UnauthRoute exact path="/" component={home} />
                            <Route
                              exact
                              path="/response-to-covid-19"
                              component={covid19}
                            />
                            <Route
                              exact
                              path="/join-poyo"
                              component={joinPoyo}
                            />
                            <UnauthRoute
                              exact
                              path="/login"
                              component={login}
                            />
                            <UnauthRoute
                              exact
                              path="/signup"
                              component={signup}
                            />
                            <UnauthRoute
                              exact
                              path="/forgot_password"
                              component={forgotPassword}
                            />
                            <UnauthRoute
                              path="/forgot_password/action"
                              component={confirmResetPassword}
                            />
                            <AuthRoute
                              exact
                              path="/dashboard"
                              component={dashboard}
                            />
                            <AuthRoute
                              exact
                              path="/notifications"
                              component={notifications}
                            />

                            <AuthCareRoute
                              exact
                              path="/caregiver/signup/basic-info"
                              component={caregiverBasicInfo}
                            />
                            <AuthCareRoute
                              exact
                              path="/caregiver/signup/services"
                              component={caregiverServices}
                            />
                            <AuthCareRoute
                              exact
                              path="/caregiver/signup/education-experience"
                              component={caregiverEducationExperience}
                            />
                            <AuthCareRoute
                              exact
                              path="/caregiver/signup/finish"
                              component={caregiverFinish}
                            />
                            <AuthRoute
                              exact
                              path="/jobs/applications"
                              component={applications}
                            />
                            <AuthRoute
                              exact
                              path="/jobs/applications/:jobAppId"
                              component={applications}
                            />
                            <AuthRoute
                              exact
                              path="/messages"
                              component={messages}
                            />
                            <AuthRoute
                              exact
                              path="/messages/:chnId"
                              component={messages}
                            />
                            <AuthRoute
                              exact
                              path="/profile"
                              component={profile}
                            />
                            <AuthRoute
                              exact
                              path="/profile/banking"
                              component={caregiverBanking}
                            />
                            <AuthRoute
                              exact
                              path="/profile/background-check"
                              component={caregiverBg}
                            />
                            <AuthRoute
                              exact
                              path="/profile/jobs"
                              component={familyAllJobs}
                            />
                            <AuthRoute
                              exact
                              path="/profile/jobs/new"
                              component={familyNewJob}
                            />
                            <AuthRoute
                              exact
                              path="/profile/jobs/:jobId"
                              component={familyAllJobs}
                            />
                            <AuthRoute
                              exact
                              path="/job/:jobId"
                              component={jobPage}
                            />
                            <AuthRoute
                              exact
                              path="/jobs"
                              component={jobSearchPage}
                            />
                            <AuthRoute
                              exact
                              path={"/jobs/search/:jobQuery"}
                              component={jobSearchPage}
                            />
                            <AuthRoute
                              exact
                              path={"/jobs/search"}
                              component={jobSearchPage}
                            />
                            <AuthFamRoute
                              exact
                              path="/caregiver/:userIdNumber"
                              component={cgPage}
                            />
                            <AuthFamRoute
                              exact
                              path="/caregiver/:userIdNumber/hire"
                              component={cgHirePage}
                            />
                            <AuthFamRoute
                              exact
                              path="/caregivers/"
                              component={cgSearchPage}
                            />
                            <AuthFamRoute
                              exact
                              path="/caregivers/search/"
                              component={cgSearchPage}
                            />
                            <AuthFamRoute
                              exact
                              path="/caregivers/search/:cgQuery"
                              component={cgSearchPage}
                            />
                            <AuthRoute
                              exact
                              path="/invites/"
                              component={invites}
                            />
                            <AuthRoute
                              exact
                              path="/invites/:inviteId"
                              component={invites}
                            />

                            <AuthRoute
                              exact
                              path="/myteam/"
                              component={myTeam}
                            />
                            <AuthRoute
                              exact
                              path="/myteam/:memberId"
                              component={myTeam}
                            />
                            <AuthRoute
                              exact
                              path="/myteam/:memberId/schedule-shift"
                              component={myTeam}
                            />
                            <AuthRoute
                              exact
                              path="/myteam/:memberId/shift/:shiftId"
                              component={myTeam}
                            />
                            <AuthRoute
                              exact
                              path="/reports/"
                              component={reports}
                            />
                            <AuthRoute
                              exact
                              path="/reports/:reportId"
                              component={reports}
                            />
                            <AuthRoute
                              exact
                              path="/reviews"
                              component={reviews}
                            />

                            <AuthRoute
                              exact
                              path="/reviews/:memberId"
                              component={reviews}
                            />

                            <AuthRoute exact path="/mypay/" component={myPay} />
                            <AuthRoute
                              exact
                              path="/mypay/(timesheet|history|invoice)"
                              component={myPay}
                            />

                            <AuthRoute
                              exact
                              path="/mypay/invoice/:invoiceId"
                              component={myPay}
                            />
                            <AuthFamRoute
                              exact
                              path="/mypay/invoice/:invoiceId/pay"
                              component={payInvoice}
                            />

                            <AuthRoute
                              exact
                              path="/mypay/history/:invoiceId"
                              component={invoiceDisplay}
                            />
                            <AuthRoute
                              exact
                              path="/mypay/detail"
                              component={myPay}
                            />
                            <Route path="/about-us" exact component={about} />
                            <Route
                              path="/policies"
                              exact
                              component={policies}
                            />
                            <Route
                              path="/resources"
                              exact
                              component={resources}
                            />
                            <Route path="/contact" exact component={contact} />
                            <Route path="/careers" exact component={careers} />
                            <Route
                              path="/careers/:careerId"
                              exact
                              component={careerSingle}
                            />
                            <Route
                              path="/policies/(terms-of-use|privacy)"
                              exact
                              component={policies}
                            />

                            <Route path="*" exact component={GenericNotFound} />
                          </Switch>
                        </ScrollToTop>
                      </main>
                      {isFooter && <FooterMain />}
                    </div>
                  ) : (
                    <FullVH>
                      <CenteredCircularLoading size={70} />
                    </FullVH>
                  )}
                </AuthProvider>
              </Elements>
            </Router>
          </Provider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user,
});

export default connect(mapStateToProps)(App);
