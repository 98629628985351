import React, { Component } from "react";
import PropTypes from "prop-types";
import Geocode from "react-geocode";
import geohash from "ngeohash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import axios from "axios";

// Component imports
import JobsCard from "../../../components/jobs/JobsCard";

import { languages } from "../../../util/utilConst";
import { arrayHasSameElements, isEmpty } from "../../../util/utilFunctions";
import SearchHeader from "./components/SearchHeader";
import SearchFilters from "./components/SearchFilters";

// Redux imports
import { connect } from "react-redux";
import {
  queryJobs,
  setFilteredResults,
} from "../../../redux/actions/dataActions";
import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

import withStyles from "@mui/styles/withStyles";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";

// Icons Imports
import SearchIcon from "@mui/icons-material/Search";

dayjs.extend(relativeTime);

Geocode.setApiKey("AIzaSyBS_m4APPDCbm_1xqD3lPllrFbO-_8m2es");
Geocode.setLanguage("en");
Geocode.setRegion("ca");

const styles = (theme) => ({
  ...theme.spreadThis,
  containerDiv: {
    [theme.breakpoints.up("sm")]: {
      // marginLeft: 85,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },

  reactiveContainerGrid: {
    padding: "0px 8px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  reactiveJustifyGrid: {
    justifyContent: "flex-start",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    minHeight: 366,
  },
  iconTextSecondary: {
    fontSize: 56,
    color: theme.palette.text.secondary,
  },
});

export class jobSearchPage extends Component {
  state = {
    errors: {},
    locality: this.props.credentials.address.addressParams.locality,
    adminAreaLevel1:
      this.props.credentials.address.addressParams.adminAreaLevel1,
    geohash: "",
    datePosted: "",
    serviceStart: "",
    servicePerWeek: "",
    serviceLength: "",
    languagePref: "",
    conditions: [],
    service: [],
    pageNum: 1,
    numFilters: 0,
    currentQueryParams: {
      geohash: "",
      locality: this.props.credentials.address.addressParams.locality,
      adminAreaLevel1:
        this.props.credentials.address.addressParams.adminAreaLevel1,
    },
    filterMaterials: {},
    isSetFilteredResults: false,
    currFilteredRes: [],
    isLoadedFilters: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    store.dispatch({ type: SET_PAGE, payload: "Jobs" });

    this.splitQuery();
    this.getFilterMaterials();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.queryLoaded && !prevProps.data.queryLoaded) {
      this.filterSearchResults();
    }
    if (this.props.match.params.jobQuery !== prevProps.match.params.jobQuery) {
      // Prevents reload on clearing query

      this.splitQuery();
    }
  }
  componentWillUnmount() {
    store.dispatch({ type: UNSET_PAGE });
  }
  async splitQuery() {
    const jobQuery = this.props.match.params.jobQuery;
    let queryData = {
      geohash: "",
      locality: this.props.credentials.address.addressParams.locality,
      adminAreaLevel1:
        this.props.credentials.address.addressParams.adminAreaLevel1,
    };
    if (jobQuery) {
      if (jobQuery.includes("serviceStart="))
        queryData.serviceStart = jobQuery
          .split("serviceStart=")[1]
          .split("&")[0];
      if (jobQuery.includes("date="))
        queryData.datePosted = jobQuery.split("date=")[1].split("&")[0];
      if (jobQuery.includes("servicePerWeek="))
        queryData.servicePerWeek = jobQuery
          .split("servicePerWeek=")[1]
          .split("&")[0];
      if (jobQuery.includes("serviceLength="))
        queryData.serviceLength = jobQuery
          .split("serviceLength=")[1]
          .split("&")[0];
      if (jobQuery.includes("page="))
        queryData.pageNum = jobQuery.split("page=")[1].split("&")[0];

      if (jobQuery.includes("language=")) {
        queryData.languagePref = jobQuery.split("language=")[1].split("&")[0];
      }
      if (jobQuery.includes("locality=") && jobQuery.includes("region=")) {
        let locality = jobQuery.split("locality=")[1].split("&")[0];
        let adminAreaLevel1 = jobQuery.split("region=")[1].split("&")[0];
        queryData.locality = locality;
        queryData.adminAreaLevel1 = adminAreaLevel1;
        try {
          const response = await Geocode.fromAddress(
            `${locality}, ${adminAreaLevel1}`
          );

          const { lat, lng } = response.results[0].geometry.location;
          queryData.geohash = geohash.encode(lat, lng);
        } catch (err) {
          console.error(err);
        }
      }
      queryData.isInitial = false;
    } else {
      queryData = {
        ...queryData,
        locality: this.props.credentials.address.addressParams.locality,
        adminAreaLevel1:
          this.props.credentials.address.addressParams.adminAreaLevel1,
        geohash: "",
        datePosted: "",
        serviceStart: "",
        servicePerWeek: "",
        serviceLength: "",
        languagePref: "",
        conditions: [],
        service: [],
        pageNum: 1,
      };
    }
    // Checks if query has already been loaded and queryParams matches currentQueryParams

    if (
      !this.props.data.queryLoaded ||
      queryData.geohash !== this.state.currentQueryParams.geohash
    ) {
      this.props.queryJobs({ geohash: queryData.geohash });
    } else if (this.props.data.queryLoaded) {
      // Reloads filteredResults if query already loaded and no new query completed
      await this.filterSearchResults();
    }
    await this.setStateAsync({
      ...queryData,
      currentQueryParams: {
        geohash: queryData.geohash,
        locality: queryData.locality,
        adminAreaLevel1: queryData.adminAreaLevel1,
      },
    });
  }

  async getFilterMaterials() {
    const filterMaterials = await axios.get("/signup-material");
    let _filterMaterials = filterMaterials.data;
    _filterMaterials.language = languages;

    this.setState({
      filterMaterials: _filterMaterials,
      isLoadedFilters: true,
    });
  }

  async filterSearchResults() {
    const { queryResults } = this.props.data;
    const {
      datePosted,
      serviceStart,
      servicePerWeek,
      serviceLength,
      service,
      pageNum,
      conditions,
      languagePref,
    } = this.state;
    let _queryResults = [...queryResults];
    let numFilters = 0;
    if (!isEmpty(datePosted)) {
      numFilters += 1;
      const today = dayjs(new Date().setHours(0, 0, 0, 0)).format();

      const compareDate = dayjs(today)
        .subtract(parseInt(datePosted), "d")
        .toISOString();

      _queryResults = _queryResults.filter(
        (job) => job.createdAt >= compareDate
      );
    }

    if (!isEmpty(serviceStart)) {
      numFilters += 1;
      _queryResults = _queryResults.filter(
        (job) => job.serviceStart === serviceStart
      );
    }
    if (!isEmpty(servicePerWeek)) {
      numFilters += 1;
      _queryResults = _queryResults.filter(
        (job) => job.servicePerWeek === servicePerWeek
      );
    }
    if (!isEmpty(serviceLength)) {
      numFilters += 1;
      _queryResults = _queryResults.filter(
        (job) => job.serviceLength === serviceLength
      );
    }

    if (!isEmpty(languagePref)) {
      numFilters += 1;
      let value = Object.keys(languages).find(
        (lang) => languages[lang] === languagePref
      );
      _queryResults = _queryResults.filter(
        (job) => job.languagePref.indexOf(value) > -1
      );
    }

    if (conditions.length > 0) {
      numFilters += 1;
      _queryResults = _queryResults.filter((job) =>
        arrayHasSameElements(conditions, Object.keys(job.conditions))
      );
    }
    if (service.length > 0) {
      numFilters += 1;
      _queryResults = _queryResults.filter((job) =>
        arrayHasSameElements(service, Object.keys(job.service))
      );
    }

    this.props.setFilteredResults(_queryResults);

    const currFilteredRes = _queryResults.slice(
      parseInt(pageNum) * 20 - 20,
      parseInt(pageNum) * 20
    );

    this.setState({
      numFilters,
      currFilteredRes,
      isSetFilteredResults: true,
    });
  }

  // UI Methods

  handleReplaceQuery = async () => {
    const {
      locality,
      adminAreaLevel1,
      datePosted,
      serviceStart,
      servicePerWeek,
      serviceLength,
      languagePref,
      pageNum,
    } = this.state;

    const searchRedirectLink = "/jobs/search/";
    let queryParam = "";
    if (!isEmpty(locality) && locality !== "any")
      queryParam = queryParam + `&locality=${locality}`;
    if (!isEmpty(adminAreaLevel1) && adminAreaLevel1 !== "any")
      queryParam = queryParam + `&region=${adminAreaLevel1}`;
    if (!isEmpty(datePosted) && datePosted !== "any")
      queryParam = queryParam + `&date=${datePosted}`;
    if (!isEmpty(serviceStart) && serviceStart !== "any")
      queryParam = queryParam + `&serviceStart=${serviceStart}`;
    if (!isEmpty(servicePerWeek) && servicePerWeek !== "any")
      queryParam = queryParam + `&servicePerWeek=${servicePerWeek}`;
    if (!isEmpty(serviceLength) && serviceLength !== "any")
      queryParam = queryParam + `&serviceLength=${serviceLength}`;
    if (languagePref === "any") queryParam = queryParam + `&languagePref=any`;
    if (pageNum !== "1") queryParam = queryParam + `&page=${pageNum}`;

    if (this.props.match.params.jobQuery !== queryParam) {
      this.props.history.replace({
        pathname: searchRedirectLink + queryParam,
      });
      this.setState({ isSetFilteredResults: false });
    }
  };
  // Action Functions

  clearFilters = async () => {
    await this.setStateAsync({
      numFilters: 0,
      service: [],
      conditions: [],
      jobType: "",
      gender: "",
      wageExp: "",
      languagePref: "",
      datePosted: "",
      serviceStart: "",
      servicePerWeek: "",
      serviceLength: "",
    });
    await this.handleReplaceQuery();
  };

  handleSelect = async (name, value) => {
    if (this.state[name] !== value) {
      await this.setStateAsync({ [name]: value });
      await this.handleReplaceQuery();
    }
  };

  handleSelectService = async (value) => {
    const { service } = this.state;
    let _service = service;
    if (value === "any") {
      _service = [];
    } else {
      if (_service.includes(value)) {
        let pos = _service.indexOf(value);
        _service.splice(pos, 1);
      } else {
        _service.push(value);
        _service = _service.sort();
      }
    }
    await this.setStateAsync({ service: _service });
    await this.filterSearchResults();
  };
  handleSelectConditions = async (value) => {
    const { conditions } = this.state;
    let _conditions = conditions;

    if (value === "any") {
      _conditions = [];
    } else {
      if (_conditions.includes(value)) {
        let pos = _conditions.indexOf(value);
        _conditions.splice(pos, 1);
      } else {
        _conditions.push(value);
        _conditions = _conditions.sort();
      }
    }
    await this.setStateAsync({ conditions: _conditions });
    await this.filterSearchResults();
  };

  handleAddress = (value) => {
    this.setState({
      locality: value.addressParams.locality,
      adminAreaLevel1: value.addressParams.adminAreaLevel1,
      geohash: value.geohash,
      errors: { ...this.state.errors, addressFull: false },
    });
  };

  handlePageChange = (e, v) => {
    const { pageNum } = this.state;
    if (parseInt(pageNum) !== v)
      this.setState({ pageNum: v }, () => {
        this.handleReplaceQuery();
      });
  };

  handleSearch = () => {
    this.handleReplaceQuery();
  };

  render() {
    const {
      classes,
      credentials,
      data: { filteredResults },
    } = this.props;

    const {
      errors,
      numFilters,
      datePosted,
      serviceStart,
      servicePerWeek,
      serviceLength,
      languagePref,
      service,
      conditions,
      pageNum,
      filterMaterials,
      currFilteredRes,
      isSetFilteredResults,
      isLoadedFilters,
    } = this.state;

    return (
      <div className={classes.containerDiv}>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          className="res-gr-con"
        >
          <Grid item xs={12} className={classes.form}>
            <SearchHeader
              onSearch={this.handleSearch}
              errors={errors}
              handleAddress={this.handleAddress}
              credentials={credentials}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={4} className={classes.paperContainerC}>
              <SearchFilters
                service={service}
                conditions={conditions}
                languagePref={languagePref}
                handleSelect={this.handleSelect}
                handleSelectService={this.handleSelectService}
                handleSelectConditions={this.handleSelectConditions}
                filterMaterials={filterMaterials}
                clearFilters={this.clearFilters}
                isLoadedFilters={isLoadedFilters}
                datePosted={datePosted}
                serviceStart={serviceStart}
                servicePerWeek={servicePerWeek}
                serviceLength={serviceLength}
                numFilters={numFilters}
              />
              <Divider style={{ margin: "8px 0px" }} />
              <Typography variant="h6" style={{ fontWeight: 300 }}>
                Based on your search results:
              </Typography>
              {isSetFilteredResults && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginBottom: 5 }}
                >
                  Showing {currFilteredRes.length} of {filteredResults.length}{" "}
                  {filteredResults.length > 1 ? "jobs" : "job"} found.
                </Typography>
              )}
              <Grid
                container
                style={{ marginTop: 16 }}
                className={classes.reactiveJustifyGrid}
                spacing={2}
              >
                {!isSetFilteredResults ? (
                  [...new Array(12)].map((v, i) => (
                    <Grid
                      item
                      xs={12}
                      sm={"auto"}
                      key={i}
                      className={classes.flexBoxCentered}
                    >
                      <Skeleton variant="rectangle" height={368} width={325} />
                    </Grid>
                  ))
                ) : filteredResults.length < 1 || errors.jobs ? (
                  <Grid item xs={12}>
                    <SearchIcon className={classes.iconTextSecondary} />
                    <Typography
                      color="textSecondary"
                      style={{ fontWeight: 300 }}
                    >
                      Oops... It seems we can not find any jobs based on your
                      search criteria at the moment.
                    </Typography>
                  </Grid>
                ) : (
                  currFilteredRes.map((job, ind) => (
                    <Fade in key={ind}>
                      <Grid
                        item
                        xs={12}
                        sm={"auto"}
                        // md={4}
                        className={classes.flexBoxCentered}
                      >
                        <JobsCard jobData={job} userData={credentials} />
                      </Grid>
                    </Fade>
                  ))
                )}
              </Grid>

              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Pagination
                  count={
                    Math.ceil(filteredResults.length / 20) < 1
                      ? 1
                      : Math.ceil(filteredResults.length / 20)
                  }
                  page={parseInt(pageNum)}
                  onChange={this.handlePageChange}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

jobSearchPage.propTypes = {
  credentials: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  queryJobs: PropTypes.func.isRequired,
  setFilteredResults: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  credentials: state.user.credentials,
  UI: state.UI,
  data: state.data,
});

export default connect(mapStateToProps, { queryJobs, setFilteredResults })(
  withStyles(styles)(jobSearchPage)
);
