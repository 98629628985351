import React, { Component, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Component imports
import LoadingEllipsis from "../../../components/layout/LoadingEllipsis";
import JobMarkup from "../../../components/jobs/JobMarkup";
import SmallJobSelect from "./components/SmallJobSelect";
import "../../../App.css";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WarningIcon from "@mui/icons-material/Warning";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class familyAllJobs extends Component {
  state = {
    jobs: [],
    jobData: {},
    errors: {},
    isLoadedJobs: false,
    isSetJob: false,
    isErrorSetJob: false,
    signupMaterials: {},
    showJobMarkup: false,
  };

  componentDidMount() {
    this.setInitial();
  }

  componentDidUpdate(prevProps, prevState) {
    const jobId = this.props.match.params.jobId;

    if (jobId !== prevProps.match.params.jobId) {
      this.setJobData();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  setInitial() {
    const jobId = this.props.match.params.jobId;
    axios
      .all([
        axios.post("/my-jobs", { isUpdate: false }),
        axios.get("/signup-material"),
      ])
      .then(
        axios.spread(async (res1, res2) => {
          const jobs = res1.data;
          await this.setStateAsync({
            jobs: jobs,
            isLoadedJobs: true,
            signupMaterials: res2.data,
          });
          if (!jobId) {
            this.props.history.replace({
              pathname: `/profile/jobs/${jobs[0].id}`,
            });
          } else {
            this.setJobData();
          }
        })
      )
      .catch((err) => {
        console.error(err);
        this.setState({ errors: err });
      });
  }

  setJobData() {
    const { jobs } = this.state;
    const jobId = this.props.match.params.jobId;

    const pos = jobs.findIndex((job) => job.id === jobId);
    if (pos > -1) {
      this.setState({
        jobData: jobs[pos],
        isSetJob: true,
        showJobMarkup: true,
        isErrorSetJob: false,
      });
    } else {
      this.setState({
        isSetJob: true,
        isErrorSetJob: true,
        showJobMarkup: true,
      });
    }
  }

  handleUpdateJobData = (id, value) => {
    const { jobs } = this.state;

    const pos = jobs.findIndex((job) => job.id === id);
    if (pos > -1) {
      let _jobs = jobs;
      _jobs[pos] = { ..._jobs[pos], ...value };
      this.setState({ jobs: _jobs, jobData: _jobs[pos] });
    }
  };

  handleHideJobMarkup = () => {
    this.setState({ showJobMarkup: false });
  };

  handleShowJobMarkup = () => {
    this.setState({ showJobMarkup: true });
  };

  render() {
    const { classes } = this.props;
    const {
      jobs,
      isLoadedJobs,
      jobData,
      signupMaterials,
      showJobMarkup,
      isSetJob,
      isErrorSetJob,
    } = this.state;

    const jobsMarkup = !isLoadedJobs ? (
      <div style={{ textAlign: "center" }}>
        <CircularProgress thickness={2} style={{ marginTop: 10 }} />
      </div>
    ) : jobs.length > 0 ? (
      jobs.map((jobData, index) => (
        <Fragment key={jobData.id}>
          <SmallJobSelect
            jobData={jobData}
            onClick={this.handleShowJobMarkup}
          />
          {index === jobs.length - 1 && <Divider />}
        </Fragment>
      ))
    ) : null;

    const jobMarkup = !isSetJob ? (
      <Paper
        elevation={4}
        className={classes.smallPaperContainer}
        style={{
          height: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress size={70} thickness={2} />

        <LoadingEllipsis prefix="Loading Job Data" />
      </Paper>
    ) : isErrorSetJob ? (
      <Paper
        elevation={4}
        className={classes.smallPaperContainer}
        style={{
          height: 500,
        }}
      >
        <Box
          p={1}
          display="flex"
          flexDirection="column"
          style={{ width: "100%" }}
        >
          <div style={{ textAlign: "center" }}>
            <WarningIcon color="secondary" className={classes.iconStyle} />
            <Typography color="textSecondary">
              Uh oh! It seems that this job posting is missing.
            </Typography>
          </div>
          <br />
          <Hidden smUp>
            <Button onClick={this.handleHideJobMarkup}>Back</Button>
          </Hidden>
        </Box>
      </Paper>
    ) : (
      <JobMarkup
        jobData={jobData}
        signupMaterials={signupMaterials}
        hideData={this.handleHideJobMarkup}
        onUpdate={this.handleUpdateJobData}
      />
    );

    return (
      <div className={classes.containerDiv}>
        <Grid container spacing={2} className="res-gr-con">
          <Hidden smDown>
            <Grid item xs={12} sm={4}>
              <Paper
                elevation={4}
                className="hideScroll"
                style={{
                  maxHeight: "90vh",
                  overflow: "auto",
                  padding: 8,
                  borderRadius: 10,
                }}
              >
                <Typography
                  className={classes.Typography}
                  variant="h6"
                  style={{ fontWeight: 300 }}
                >
                  My Job Postings
                </Typography>

                <Divider />
                <Button
                  component={Link}
                  to="/profile/jobs/new"
                  fullWidth
                  size="small"
                  color="secondary"
                  variant="outlined"
                  style={{ margin: "4px 0px" }}
                >
                  Post New Job
                </Button>
                {jobsMarkup}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
              {jobMarkup}
            </Grid>
          </Hidden>

          <Hidden smUp>
            {!showJobMarkup && (
              <Slide direction="right" in={!showJobMarkup}>
                <Grid item xs={12}>
                  <Paper
                    elevation={4}
                    style={{
                      padding: 8,
                      borderRadius: 10,
                    }}
                  >
                    <Box className={classes.flexBoxSpaced}>
                      <Typography
                        className={classes.Typography}
                        variant="h6"
                        style={{ fontWeight: 300 }}
                      >
                        My Job Postings
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={this.handleShowJobMarkup}
                      >
                        <ArrowForwardIcon color="primary" />
                      </IconButton>
                    </Box>
                    <Divider />
                    <Button
                      component={Link}
                      to="/profile/jobs/new"
                      fullWidth
                      size="small"
                      color="secondary"
                      variant="outlined"
                      style={{ margin: "4px 0px" }}
                    >
                      Post New Job
                    </Button>
                    {jobsMarkup}
                  </Paper>
                </Grid>
              </Slide>
            )}

            {showJobMarkup && (
              <Slide direction="left" in={showJobMarkup}>
                <Grid item xs={12}>
                  {jobMarkup}
                </Grid>
              </Slide>
            )}
          </Hidden>
        </Grid>
      </div>
    );
  }
}

familyAllJobs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(familyAllJobs);
