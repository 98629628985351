import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Link } from "react-router-dom";
import axios from "axios";

// Component imports
import OutlinedProfilePic from "../../OutlinedProfilePic";
import { setNotifURL } from "../../../../util/utilFunctions";

// MUI imports
import withStyles from "@mui/styles/withStyles";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

dayjs.extend(relativeTime);

const styles = (theme) => ({
  ...theme.spreadThis,
  isUnreadDot: {
    position: "absolute",
    right: 0,
    height: 8,
    width: 8,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
  },
});

export class NotificationItemClickable extends Component {
  _markNotificationRead = async () => {
    const { notification } = this.props;
    this.props.onClickLink && this.props.onClickLink();

    if (!notification.read)
      await axios.post(`/notifications/mark_read`, [notification.id]);
  };

  render() {
    const { notification, classes } = this.props;

    return (
      <CardActionArea
        onClick={this._markNotificationRead}
        component={Link}
        to={`${setNotifURL(notification.class, notification.refId)}`}
        style={{
          margin: "4px 0px",
          padding: 10,
          borderRadius: 8,
        }}
      >
        <Box className={classes.flexBox} style={{ position: "relative" }}>
          <OutlinedProfilePic
            imgSrc={notification.senderImgUrlThumb}
            size={35}
          />
          <div style={{ marginLeft: 8, flex: 1, paddingRight: 10 }}>
            <Typography color="textSecondary" variant="body2">
              <span
                dangerouslySetInnerHTML={{ __html: notification.message }}
              />
            </Typography>
            <Typography component="p" variant="caption" color="primary">
              <b>{dayjs(notification.createdAt).fromNow()}</b>
            </Typography>
          </div>
          {!notification.read && <div className={classes.isUnreadDot} />}
        </Box>
      </CardActionArea>
    );
  }
}
NotificationItemClickable.propTypes = {
  classes: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  onClickLink: PropTypes.func,
};
export default withStyles(styles)(NotificationItemClickable);
