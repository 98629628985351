import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

// Component imports
import {
  capitalizeFirstChar,
  numberWithCommas,
} from "../../../util/utilFunctions";
import OutlinedProfilePic from "../../layout/OutlinedProfilePic";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const styles = (theme) => ({
  ...theme.spreadThis,
  gridItem: {
    padding: "0px 8px",
    display: "flex",
    alignItems: "center",
  },
});

function CSSTypography(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      align="center"
      variant="body2"
      {...props}
    />
  );
}

export class InvoiceRowDisplay extends Component {
  state = { anchorEl: null };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      isOdd,
      userType,
      invoice: {
        caregiverInfo,
        familyInfo,
        invoiceBreakdown: {
          payableBreakdown: { totalPayable },
          netIncome,
        },
        sortedShifts,
        paymentInfo: { paidOn },
        id,
      },
    } = this.props;
    const { anchorEl } = this.state;

    const isFamily = userType === "family";

    const sortedOrderedShifts = Object.keys(sortedShifts).sort();

    return (
      <Box
        className={
          !isMobile
            ? classes.hoverGrayContainer
            : isOdd
            ? classes.selectedGrayContainer
            : classes.mobileContainer
        }
        style={{ margin: "4px 0px" }}
      >
        <Typography variant="body2">#{id}</Typography>
        <div className={classes.titleLine} />
        <Grid container item xs={12} alignItems="center">
          <Grid container item xs={9}>
            <Grid
              item
              xs={6}
              sm={4}
              className={classes.gridItem}
              style={{ paddingLeft: 0 }}
            >
              <OutlinedProfilePic
                imgSrc={
                  isFamily ? caregiverInfo.imgUrlThumb : familyInfo.imgUrlThumb
                }
                alt="profile pic"
                size={30}
              />

              <CSSTypography align="left" style={{ paddingLeft: 5 }}>
                {capitalizeFirstChar(
                  isFamily
                    ? caregiverInfo.firstName
                    : familyInfo.clientFirstName
                )}{" "}
                {isFamily
                  ? caregiverInfo.lastName[0].toUpperCase()
                  : familyInfo.clientLastName[0].toUpperCase()}
                .
              </CSSTypography>
            </Grid>
            <Hidden smDown>
              <Grid item sm={5} className={classes.gridItem}>
                <CSSTypography>
                  {dayjs(sortedOrderedShifts[0])
                    .startOf("week")
                    .format("M/D/YYYY")}{" "}
                  -{" "}
                  {dayjs(sortedOrderedShifts[0])
                    .endOf("week")
                    .format("M/D/YYYY")}
                </CSSTypography>
              </Grid>
            </Hidden>
            <Grid item sm={3} className={classes.gridItem}>
              <CSSTypography>{dayjs(paidOn).format("M/D/YYYY")}</CSSTypography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            className={classes.gridItem}
            style={{ paddingRight: 0, justifyContent: "flex-end" }}
          >
            <CSSTypography>
              ${numberWithCommas(isFamily ? totalPayable : netIncome)}
            </CSSTypography>
            <IconButton size="small" onClick={this.handleMenu} color="primary">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                component={Link}
                to={`/mypay/history/${id}`}
                onClick={this.handleClose}
              >
                View Invoice
              </MenuItem>
              <MenuItem
                component={Link}
                to="/resources"
                onClick={this.handleClose}
              >
                Help
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

InvoiceRowDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
};
export default withStyles(styles)(InvoiceRowDisplay);
