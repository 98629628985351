import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import axios from "axios";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/uiActions";

// Component import
import { isEmpty } from "../../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Icon imports
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class ShiftReportButton extends Component {
  state = {
    openDialog: false,
    disableWhileLoad: false,
    title: "",
    note: "",
    errors: {},
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI Methods

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false, title: "", note: "", errors: {} });
  };
  // Error Validation

  validateError = () => {
    const { startTime, status } = this.props.shift;

    let errorMsg;

    if (!status.isCheckedIn && dayjs().isBefore(startTime, "d"))
      errorMsg = "You may only submit a report on the scheduled date.";

    if (Boolean(errorMsg))
      this.props.setAlert({ message: errorMsg, type: "warning" });

    return Boolean(errorMsg);
  };

  validateReport = () => {
    const { title, note } = this.state;
    const errors = {};
    if (isEmpty(title)) errors.title = "Report must include a title.";
    if (isEmpty(note))
      errors.note = "Please include a brief description of the events.";
    this.setState({ errors });
    return Object.keys(errors).length < 1;
  };

  errorSwitch = (name) => {
    let errorMsg = "";
    switch (name) {
      case "title":
        errorMsg = "Report must include a title.";
        break;
      case "note":
        errorMsg = "Please include a brief description of the events.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  // Action methods
  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: isEmpty(value) && this.errorSwitch(name),
      },
    });
  };

  handleSubmitReport = async () => {
    const { id } = this.props.shift;
    const isValidReport = this.validateReport();
    if (isValidReport) {
      const isError = this.validateError();

      if (!isError) {
        await this.setStateAsync({ disableWhileLoad: true });

        try {
          const { title, note } = this.state;
          const reportDoc = await axios.post(`/shift/${id}/report/submit`, {
            title,
            note,
          });
          if (this.props.onSuccess) this.props.onSuccess(reportDoc.data);
          await this.handleCloseDialog();
        } catch (err) {
          Boolean(err.response.data.report) &&
            this.props.setAlert({
              message: err.response.data.report,
              type: "error",
            });
        } finally {
          this.setStateAsync({ disableWhileLoad: false });
        }
      }
    }
  };

  render() {
    const {
      classes,
      fullWidth,
      variant,
      shift: { status },
    } = this.props;

    const { openDialog, disableWhileLoad, title, note, errors } = this.state;

    return (
      <React.Fragment>
        <Button
          className={classes.txtTrButton}
          onClick={this.handleOpenDialog}
          variant={variant ? variant : "outlined"}
          startIcon={<ReportProblemIcon />}
          color="secondary"
          size="small"
          fullWidth={fullWidth}
          style={{ margin: 4, minWidth: 0 }}
          disabled={disableWhileLoad || status.isCompleted}
        >
          Report
          {disableWhileLoad && (
            <CircularProgress
              thickness={2}
              size={25}
              className={classes.progress}
            />
          )}
        </Button>
        <Dialog
          open={openDialog}
          onClose={this.handleCloseDialog}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Add a report</DialogTitle>
          <DialogContent>
            <TextField
              name="title"
              value={title}
              variant="outlined"
              label="Title"
              autoFocus
              fullWidth
              onChange={this.handleChange}
              className={
                Boolean(errors.title)
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
              error={Boolean(errors.title)}
              helperText={Boolean(errors.title) && errors.title}
            />
            <TextField
              name="note"
              value={note}
              variant="outlined"
              label="Description"
              fullWidth
              onChange={this.handleChange}
              className={
                Boolean(errors.note)
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
              multiline
              minRows={3}
              maxRows={5}
              style={{ marginTop: 8 }}
              error={Boolean(errors.note)}
              helperText={Boolean(errors.note) && errors.note}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              color="primary"
              disabled={disableWhileLoad}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleSubmitReport}
              variant="contained"
              color="primary"
              style={{ borderRadius: 25, boxShadow: "none" }}
              disabled={disableWhileLoad}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ShiftReportButton.propTypes = {
  classes: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
};

export default connect(null, { setAlert })(
  withStyles(styles)(ShiftReportButton)
);
