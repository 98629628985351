import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import dayjs from "dayjs";

// Component imports
import StyledNumberInput from "../../../../components/inputs/StyledNumberInput";
import DateSelector from "../../../../components/inputs/DateSelector";
import LocationSearch from "../../../../components/inputs/LocationSearch";
import RadioJobSelect from "../../../../components/jobs/RadioJobSelect";
import {
  isEmpty,
  capitalizeFirstChar,
  allFalse,
} from "../../../../util/utilFunctions";
import OutlinedProfilePic from "../../../../components/layout/OutlinedProfilePic";
import ReviewInviteDialog from "./ReviewInviteDialog";

// Redux imports
import { connect } from "react-redux";
import { setAlert } from "../../../../redux/actions/uiActions";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Hidden from "@mui/material/Hidden";
import Tooltip from "@mui/material/Tooltip";

// Icon imports
import HelpIcon from "@mui/icons-material/Help";

const styles = (theme) => ({
  ...theme.spreadThis,
  media: {
    display: "block",
    marginRight: "auto",
    marginLeft: "auto",
    borderRadius: "50%",
    objectFit: "cover",
    height: 200,
    width: 200,
    marginTop: 10,
  },
});

export class TeamInviteMenu extends Component {
  state = {
    jobs: [],
    isLoadedJobs: false,
    currJob: "",
    clientFirstName: "",
    clientLastName: "",
    startDate: "",
    message: "",
    propWage: 0,
    estimatedHours: 0,
    address: {},
    errors: {},
    disableWhileLoad: false,
    isInitial: true,
    showJobs: false,
    showInvite: false,
    showNext: false,
    teamInvites: [],
    isLoadedInv: false,
    deactivateJobOnHire: false,
    isVacationIncluded: true,
  };

  // Initial Load Methods

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isInitial: false, showJobs: true });
    }, 2000);
    this.getJobs();
    this.getTeamInv();
    this.setInitial();
  }

  async getTeamInv() {
    const invData = await axios.get("/team/invite");
    await this.setStateAsync({
      teamInvites: invData.data,
      isLoadedInv: true,
    });
  }

  async getJobs() {
    const data = (await axios.post("/my-jobs")).data;
    await this.setStateAsync({ jobs: data, isLoadedJobs: true });
  }

  setInitial = async () => {
    const { wageExp, firstName } = this.props.userData;
    await this.setStateAsync({
      propWage: parseFloat(wageExp),
      message: `Hi ${capitalizeFirstChar(
        firstName
      )},\n\nI would love to extend an invite and have you join my team!\nIf you have any questions, please message me and we'll chat.`,
    });
    await this.validatePropWage(parseFloat(wageExp));
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI Methods

  handleShowInvite = () => {
    setTimeout(() => {
      this.setState({ showInvite: true });
    }, 250);
  };

  handleShowJobs = () => {
    setTimeout(() => {
      this.setState({ showJobs: true });
    }, 250);
  };

  handleJobSelect = async (e) => {
    this.setStateAsync({
      currJob: e.target.value,
      errors: { ...this.state.errors, currJob: false },
      showJobs: false,
    });
    this.handleShowInvite();
  };

  handleBack = async () => {
    this.setStateAsync({ showInvite: false, showNext: true });
    this.handleShowJobs();
  };

  handleNext = async () => {
    this.setStateAsync({ showJobs: false });
    this.handleShowInvite();
  };

  // Action methods
  handleChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    await this.setStateAsync({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: isEmpty(value) && "Must not be empty",
      },
    });
  };

  handleChangeWage = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    await this.setStateAsync({
      [name]: value < 0 ? 0 : value,
    });
    await this.validatePropWage(value);
  };

  handleStartDate = (value) => {
    this.setState({
      startDate: dayjs(value).format("YYYY-MM-DD"),
      errors: { ...this.state.errors, startDate: false },
    });
  };

  handleAddress = (value) => {
    this.setState({
      address: value,
      errors: { ...this.state.errors, address: false },
    });
  };

  handleToggleJobState = (e) => {
    this.setState({ deactivateJobOnHire: e.target.checked });
  };

  handleToggleIncludeVacation = async (e) => {
    await this.setStateAsync({ isVacationIncluded: e.target.checked });
    await this.validatePropWage(this.state.propWage);
  };

  // Submit Error Validation Methods

  validatePropWage = (value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        propWage: !this.state.isVacationIncluded
          ? value < 15.2 &&
            "Proposed wage must be greater than minimum wage of $15.20."
          : value < 15.2 * 1.04 &&
            `Proposed wage must be greater than minimum wage and 4% vacation pay of $${
              Math.ceil(15.2 * 1.04 * 100) / 100
            }.`,
      },
    });
  };

  validateErrors = () => {
    const {
      currJob,
      clientFirstName,
      clientLastName,
      propWage,
      estimatedHours,
      address,
      startDate,
      message,
      errors,
    } = this.state;

    const _errors = errors;

    if (isEmpty(currJob)) _errors.currJob = true;
    if (isEmpty(clientFirstName)) _errors.clientFirstName = "Must not be empty";
    if (isEmpty(clientLastName)) _errors.clientLastName = "Must not be empty";
    if (isEmpty(startDate)) _errors.startDate = "Must not be empty";
    if (isEmpty(message)) _errors.message = "Must not be empty";
    if (estimatedHours < 1) _errors.estimatedHours = "Must be greater than 1";
    if (propWage < 15.2)
      _errors.propWage =
        "Proposed wage must be greater than minimum wage of $15.20";
    if (!address.addressFull) _errors.address = true;

    this.setState({ errors: _errors });
    return allFalse(_errors);
  };

  handleHire = async () => {
    await this.setStateAsync({ disableWhileLoad: true });
    const valid = await this.validateErrors();

    const {
      currJob,
      clientFirstName,
      clientLastName,
      propWage,
      address,
      startDate,
      message,
      deactivateJobOnHire,
      isVacationIncluded,
      estimatedHours,
    } = this.state;
    const { userData } = this.props;

    if (valid) {
      const teamInviteDoc = {
        jobId: currJob,
        clientFirstName,
        clientLastName,
        propWage,
        startDate,
        address,
        caregiverInfo: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          imgUrlThumb: userData.imgUrlThumb,
        },
        deactivateJobOnHire,
        message,
        isVacationIncluded,
        estimatedHours,
      };

      try {
        await axios.post(
          `/team/invite/user/${userData.userIdNumber}`,
          teamInviteDoc
        );
        this.props.setAlert({
          message: `Invitation sent to ${capitalizeFirstChar(
            userData.firstName
          )}`,
          type: "success",
        });

        this.props.handleTeamInv();
      } catch (err) {
        this.props.setAlert({
          message: `Error: ${err.response.data.error}`,
          type: "error",
        });
        await this.setStateAsync({ disableWhileLoad: false });
      }
    } else {
      await this.setStateAsync({ disableWhileLoad: false });
    }
  };

  render() {
    const { classes, userData } = this.props;
    const {
      jobs,
      isLoadedJobs,
      isLoadedInv,
      currJob,
      clientFirstName,
      clientLastName,
      propWage,
      estimatedHours,
      errors,
      disableWhileLoad,
      isInitial,
      showJobs,
      showInvite,
      showNext,
      teamInvites,
      deactivateJobOnHire,
      address,
      message,
      isVacationIncluded,
    } = this.state;

    const jobsDisplay = jobs.map((job) => {
      const isApplied = job.apps.some(
        (app) => app.userIdNumber === userData.userIdNumber
      );

      const isAccepted = teamInvites.some(
        (invite) =>
          invite.jobId === job.id &&
          invite.isAccepted &&
          invite.caregiverInfo.userIdNumber === userData.userIdNumber
      );
      const isInvited = teamInvites.some(
        (invite) =>
          invite.jobId === job.id &&
          invite.caregiverInfo.userIdNumber === userData.userIdNumber
      );

      return (
        <RadioJobSelect
          key={job.id}
          jobData={job}
          errors={errors}
          currJob={currJob}
          handleJobSelect={this.handleJobSelect}
          isApplied={isApplied}
          isInvited={isInvited}
          isAccepted={isAccepted}
        />
      );
    });

    const jobsMarkup = (
      <Grid item xs={12} md={5}>
        <Paper
          elevation={4}
          className={clsx(
            classes.smallPaperContainer,
            classes.darkGrayBorderOnly
          )}
        >
          <Typography
            align="center"
            color="primary"
            variant="h6"
            style={{ marginBottom: 16 }}
          >
            Step 2: Select a Job Posting
          </Typography>
          <Typography
            component="div"
            variant="body2"
            align="left"
            color={errors.currJob ? "error" : "initial"}
            style={{ marginBottom: 4 }}
          >
            Please select the job you want to hire{" "}
            <Box display="inline" style={{ fontWeight: 550 }}>
              {capitalizeFirstChar(userData.firstName)}
            </Box>{" "}
            for:
          </Typography>
          {isLoadedJobs && isLoadedInv ? (
            <div style={{ textAlign: "center" }}>
              <Box p={1}>{jobsDisplay}</Box>

              <Hidden smUp>
                <Fade in={showNext} mountOnEnter>
                  <Grid container justifyContent="flex-end">
                    <Button
                      color="primary"
                      className={classes.txtTrButton}
                      variant="outlined"
                      size="small"
                      onClick={this.handleNext}
                      disabled={disableWhileLoad}
                      style={{ width: 100 }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Fade>
              </Hidden>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <CircularProgress thickness={2} size={75} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: 16 }}
              >
                Loading jobs...
              </Typography>
            </div>
          )}
        </Paper>
      </Grid>
    );

    const inviteMarkup = (
      <Grid item xs={12} md={7}>
        <Paper
          elevation={4}
          className={clsx(
            classes.smallPaperContainer,
            classes.darkGrayBorderOnly
          )}
        >
          <Typography
            align="center"
            color="primary"
            variant="h6"
            style={{ marginBottom: 16 }}
          >
            Step 3: Make an Offer
          </Typography>
          <Typography
            component="div"
            variant="body2"
            style={{ marginBottom: 16 }}
          >
            Finalize your hiring process for{" "}
            <Box display="inline" style={{ fontWeight: 550 }}>
              {capitalizeFirstChar(userData.firstName)}
            </Box>{" "}
            by completing the below:
          </Typography>
          <Grid container spacing={1}>
            <Grid
              item
              xs="auto"
              sm={3}
              className={classes.flexBoxCentered}
              style={{ flexDirection: "column", height: "100%" }}
            >
              <OutlinedProfilePic imgSrc={userData.imgUrlThumb} size={70} />

              <Typography variant="body2" style={{ fontWeight: 550 }}>
                {capitalizeFirstChar(userData.firstName)}{" "}
                {capitalizeFirstChar(userData.lastName)}
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 5 }}>
                ${parseFloat(userData.wageExp).toFixed(2)}/hr
              </Typography>
            </Grid>

            <Grid
              container
              item
              xs="auto"
              sm={9}
              spacing={1}
              style={{ flex: 1 }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  name="clientFirstName"
                  value={clientFirstName}
                  size="small"
                  label="Client First Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.handleChange}
                  fullWidth
                  required
                  className={
                    Boolean(errors.clientFirstName)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.clientFirstName)}
                  helperText={
                    Boolean(errors.clientFirstName) && errors.clientFirstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  name="clientLastName"
                  value={clientLastName}
                  size="small"
                  label="Client Last Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  onChange={this.handleChange}
                  required
                  className={
                    Boolean(errors.clientLastName)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.clientLastName)}
                  helperText={
                    Boolean(errors.clientLastName) && errors.clientLastName
                  }
                />
              </Grid>

              <Grid item xs={12}>
                {/* <DateSelector
                  variant="outlined"
                  size="small"
                  placeholder=""
                  onSelectDate={this.handleStartDate}
                  propMinDate={new Date()}
                  label="Start Date"
                  inputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%" }}
                  className={
                    Boolean(errors.startDate)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  required
                  errorDate={Boolean(errors.startDate)}
                  errorDateText={Boolean(errors.startDate) && errors.startDate}
                /> */}
                <DateSelector
                  size="small"
                  onSelectDate={this.handleStartDate}
                  propMinDate={dayjs()}
                  fullWidth
                  label="Start Date"
                  variant="outlined"
                  errorDate={Boolean(errors.startDate)}
                  errorDateText={Boolean(errors.startDate) && errors.startDate}
                  className={
                    Boolean(errors.startDate || errors.startDateComp)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <LocationSearch
                  onSelectAddress={this.handleAddress}
                  required={true}
                  label="Address"
                  placeholder="Address..."
                  address={address}
                  errorAddress={errors.address ? true : false}
                />
              </Grid>

              <Grid item xs={12}>
                <StyledNumberInput
                  variant="outlined"
                  size="small"
                  name="propWage"
                  value={parseFloat(propWage).toFixed(2)}
                  label="Proposed Wage"
                  type="number"
                  step="0.01"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  onChange={this.handleChangeWage}
                  className={
                    Boolean(errors.propWage)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  helperText={Boolean(errors.propWage) && errors.propWage}
                  error={Boolean(errors.propWage)}
                />
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={8}>
                    <Typography variant="body2">
                      Includes mandatory 4% vacation pay.
                      <Tooltip
                        disableFocusListener
                        enterTouchDelay={0}
                        placement="top"
                        title={
                          <Typography variant="caption">
                            Proposed wage includes a mandatory 4% vacation pay
                            as required by provincial law in lieu of annual
                            vacation time.
                          </Typography>
                        }
                      >
                        <HelpIcon
                          color="inherit"
                          style={{ fontSize: 14, opacity: 0.7 }}
                        />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Switch
                      checked={isVacationIncluded}
                      onChange={this.handleToggleIncludeVacation}
                      name="isVacationIncluded"
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="estimatedHours"
                  value={estimatedHours}
                  size="small"
                  type="number"
                  label="Est. Weekly Hours"
                  InputProps={{ inputProps: { min: 0 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  onChange={this.handleChange}
                  required
                  className={
                    Boolean(errors.estimatedHours)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.estimatedHours)}
                  helperText={
                    Boolean(errors.estimatedHours) && errors.estimatedHours
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  minRows={5}
                  variant="outlined"
                  name="message"
                  value={message}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  label="Message"
                  required
                  className={
                    Boolean(errors.message)
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.message)}
                  helperText={Boolean(errors.message) && errors.message}
                />
              </Grid>
              <Grid
                component="label"
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item xs={8}>
                  <Typography variant="body2">
                    Deactivate this job posting upon accept?
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Switch
                    checked={deactivateJobOnHire}
                    onChange={this.handleToggleJobState}
                    name="deactivateJobOnHire"
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: 8 }}
                className={classes.flexBoxCentered}
              >
                <Hidden smUp>
                  <Button
                    color="primary"
                    className={classes.txtTrButton}
                    size="small"
                    onClick={this.handleBack}
                    disabled={disableWhileLoad}
                    style={{ marginRight: 10 }}
                  >
                    BACK
                  </Button>
                </Hidden>

                <ReviewInviteDialog
                  validateErrors={this.validateErrors}
                  onHire={this.handleHire}
                  {...this.state}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );

    return (
      <Fragment>
        {/* // Show not mobile */}
        <Hidden mdDown>
          {isInitial && (
            <Fade in={isInitial} mountOnEnter unmountOnExit>
              <Typography
                variant="h4"
                align="center"
                style={{
                  fontWeight: 300,
                  marginTop: 24,
                }}
              >
                You're almost done!
              </Typography>
            </Fade>
          )}
          <Grid container spacing={2}>
            <Fade in={!isInitial} timeout={{ appear: 500, enter: 1000 }}>
              {jobsMarkup}
            </Fade>
            <Fade in={!isInitial} timeout={{ appear: 500, enter: 1000 }}>
              {inviteMarkup}
            </Fade>
          </Grid>
        </Hidden>

        {/* // Show mobile */}
        <Hidden mdUp>
          {isInitial && (
            <Fade in={isInitial} mountOnEnter unmountOnExit>
              <Typography
                variant="h3"
                align="center"
                style={{ fontWeight: 300 }}
              >
                You're almost done!
              </Typography>
            </Fade>
          )}
          <Slide
            direction="right"
            in={showJobs}
            timeout={{ enter: 250, exit: 250 }}
            mountOnEnter
            unmountOnExit
          >
            {jobsMarkup}
          </Slide>
          <Slide
            direction="left"
            in={showInvite}
            timeout={{ enter: 250, exit: 250 }}
            mountOnEnter
            unmountOnExit
          >
            {inviteMarkup}
          </Slide>
        </Hidden>
      </Fragment>
    );
  }
}

TeamInviteMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  handleTeamInv: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { setAlert })(withStyles(styles)(TeamInviteMenu));
