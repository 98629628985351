import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import relativeTime from "dayjs/plugin/relativeTime"; // Relative time formatter
import updateLocale from "dayjs/plugin/updateLocale"; // Relative time formatter

// Component imports
import {
  capitalizeFirstChar,
  isEmpty,
  splitByCapital,
} from "../../util/utilFunctions";
import LocationSearch from "../inputs/LocationSearch";
import ObjSelectButton from "../inputs/ObjSelectButton";
import ArraySelectButton from "../inputs/ArraySelectButton";
import JobAppMediaCard from "./JobAppMediaCard";

import SingleObjSelect from "../inputs/SingleObjSelect";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MuiLink from "@mui/material/Link";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";

// Icons Imports
import PeopleIcon from "@mui/icons-material/People";
import LanguageIcon from "@mui/icons-material/Language";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BusinessIcon from "@mui/icons-material/Business";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a min",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a mo",
    MM: "%d mos",
    y: "a yr",
    yy: "%d yrs",
  },
});

const styles = (theme) => ({
  ...theme.spreadThis,
  counterZero: {
    width: 25,
    height: 25,
  },
  counter: {
    width: 25,
    height: 25,
    backgroundColor: `${theme.palette.secondary.main}`,
    marginBottom: 2,
  },
});

export class JobMarkup extends Component {
  state = {
    anchorEl: null,
    isEdit: false,
    errors: {},
    address: {},
    serviceStart: "",
    servicePerWeek: "",
    serviceLength: "",
    description: null,
    languagePref: [],
    service: {},
    conditions: {},
    isLoading: false,
    setActiveDialog: false,
    loading: false,
    showMore: false,
  };

  setStateAsync(state) {
    new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // UI Methods
  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleOpenSetActiveDialog = () => {
    this.handleClose();

    this.setState({ setActiveDialog: true });
  };

  handleCloseSetActiveDialog = () => {
    this.setState({ setActiveDialog: false });
  };

  handleMoreApplications = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  // Action methods
  handleSetActive = async () => {
    const { jobData } = this.props;
    const updateMaterial = { isAvailable: !jobData.isAvailable };
    await this.setStateAsync({ loading: true });
    try {
      await axios.post(`/job/${jobData.id}/edit/avail`, updateMaterial);

      this.props.onUpdate(jobData.id, updateMaterial);
      await this.handleCloseSetActiveDialog();
      await this.setState({ loading: false });
    } catch (err) {
      console.error(err);
    }
  };

  handleAddress = (value) => {
    this.setState({
      address: value,
    });
  };

  selectServiceStart = (value) => {
    this.setState({ serviceStart: value });
  };

  selectServPerWeek = (value) => {
    this.setState({ servicePerWeek: value });
  };

  selectServiceLength = (value) => {
    this.setState({ serviceLength: value });
  };

  handleLanguagePref = (value) => {
    this.setState({ languagePref: value });
  };
  handleService = (value) => {
    this.setState({
      service: value,
      errors: { ...this.state.errors, service: false },
    });
  };
  handleConditions = (value) => {
    this.setState({
      conditions: value,
      errors: { ...this.state.errors, conditions: false },
    });
  };

  handleSaveJob = async () => {
    await this.setStateAsync({ isLoading: true });
    const {
      address,
      serviceStart,
      servicePerWeek,
      serviceLength,
      description,
      languagePref,
      service,
      conditions,
    } = this.state;

    const { jobData } = this.props;

    const updateMaterial = {
      address: address,
      serviceStart: serviceStart,
      servicePerWeek: servicePerWeek,
      serviceLength: serviceLength,
      description: description,
      languagePref: languagePref,
      service: service,
      conditions: conditions,
    };
    let errors = {};

    if (isEmpty(description)) errors.description = true;
    if (Object.keys(service).length < 1) errors.service = true;
    if (Object.keys(conditions).length < 1) errors.conditions = true;

    if (Object.keys(errors).length > 0) {
      this.setState({ ...this.state, errors: errors, isLoading: false });
    } else {
      try {
        await axios.post(`/job/${jobData.id}/edit`, updateMaterial);
        this.props.onUpdate(jobData.id, updateMaterial);

        await this.handleEditJob();
      } catch (err) {
        console.error(err);
      } finally {
        await this.setStateAsync({ isLoading: false });
      }
    }
  };

  handleEditJob = () => {
    const {
      jobData: {
        serviceStart,
        address,
        servicePerWeek,
        serviceLength,
        description,
        languagePref,
        service,
        conditions,
      },
    } = this.props;

    const { isEdit } = this.state;

    let _jobData = {
      address,
      serviceStart,
      servicePerWeek,
      serviceLength,
      description,
      languagePref,
      service,
      conditions,
    };
    this.setState({ isEdit: !isEdit, ..._jobData, errors: {} }, () =>
      this.handleClose()
    );
  };

  render() {
    const {
      classes,
      signupMaterials,
      jobData: {
        serviceStart,
        address,
        servicePerWeek,

        createdAt,
        serviceLength,
        description,
        numApply,
        languagePref,
        service,
        conditions,
        isAvailable,
        jobApps,
        cgViews,
        familyInfo: { firstName, lastName },
      },
    } = this.props;
    const {
      anchorEl,
      isEdit,
      errors,
      isLoading,
      setActiveDialog,
      loading,
      showMore,
    } = this.state;

    let serviceStartObj = {
      Immediately: "immediately",
      "1 week": "1week",
      "1 month": "1month",
      "1 month+": "1month+",
    };

    let servicePerWeekObj = {
      "Under 10 hrs": "under10",
      "10 - 20 hrs": "10-20",
      "20hr +": "20+",
    };

    let serviceLengthObj = {
      "Under 1 Month": "underOneMonth",
      "Under 3 Months": "underThreeMonths",
      "Under 6 Months": "underSixMonths",
      "6 Months +": "overSixMonths",
    };

    const languageChip = languagePref.map((key) => (
      <Chip
        label={capitalizeFirstChar(splitByCapital(key))}
        className={classes.chipBlue}
        icon={<LanguageIcon />}
        size="small"
        // color="secondary"
        key={key}
      />
    ));

    const serviceChip = Object.keys(service)
      .sort()
      .map((key) => (
        <Chip
          label={capitalizeFirstChar(splitByCapital(key))}
          className={classes.chipBlue}
          icon={<AssignmentIcon />}
          size="small"
          // color="secondary"
          key={key}
        />
      ));

    const conditionsChip = Object.keys(conditions)
      .sort()
      .map((key) => (
        <Chip
          label={capitalizeFirstChar(splitByCapital(key))}
          className={classes.chipBlue}
          icon={<LocalHospitalIcon />}
          size="small"
          // color="secondary"
          key={key}
        />
      ));

    return (
      <Grid container spacing={1} style={{ marginTop: 0 }}>
        <Paper
          elevation={4}
          className={classes.smallPaperContainer}
          style={{ width: "100%" }}
        >
          <Grid container item xs={12} justifyContent="space-between">
            <Hidden smUp>
              <div style={{ marginRight: 10 }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    this.props.hideData();
                  }}
                >
                  <ArrowBackIcon color="primary" />
                </IconButton>
              </div>
            </Hidden>
            <div />
            <div style={{ marginRight: 10 }}>
              <IconButton size="small" onClick={this.handleMenu}>
                <MoreHorizIcon color="primary" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem component={Link} to="/profile/jobs/new">
                  Post New Job
                </MenuItem>
                <MenuItem onClick={this.handleEditJob}>
                  {!isEdit ? "Edit Job" : "Cancel"}
                </MenuItem>
                <MenuItem onClick={this.handleOpenSetActiveDialog}>
                  {isAvailable ? "Deactivate" : "Activate"}
                </MenuItem>
              </Menu>
              <Dialog
                open={setActiveDialog}
                onClose={this.handleCloseSetActiveDialog}
              >
                <DialogTitle>
                  {isAvailable ? "Deactivate Posting" : "Activate Posting"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {isAvailable
                      ? "Deactivate your job posting and remove it from search for all caregivers. You may reactivate your posting at any time."
                      : "Activate your job posting so caregivers may find and apply for this job. You may deactivate your posting at any time."}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleCloseSetActiveDialog}
                    disabled={loading}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.handleSetActive}
                    color="primary"
                    autoFocus
                    disabled={loading}
                  >
                    Confirm
                    {loading && (
                      <CircularProgress
                        thickness={2}
                        size={30}
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              padding: "0 10px 0 10px",
            }}
          >
            <Divider orientation="horizontal" />
          </Grid>
          <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }}>
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item style={{ height: 75 }}>
                <BusinessIcon color="primary" style={{ fontSize: 75 }} />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {dayjs(createdAt).fromNow()}
                </Typography>
              </Grid>
            </Grid>
            <Box style={{ alignItems: "center", display: "flex" }}>
              <Typography variant="h6" style={{ fontWeight: 550 }}>
                {capitalizeFirstChar(firstName)} {capitalizeFirstChar(lastName)}{" "}
                •
              </Typography>
              <Chip
                label={isAvailable ? "Active Posting" : "Inactive Posting"}
                size="small"
                style={
                  isAvailable
                    ? {
                        backgroundColor: "rgb(233,245,232)",
                        color: "rgb(26,55,23)",
                        marginLeft: 5,
                      }
                    : {
                        backgroundColor: "rgb(251,231,229)",
                        color: "rgb(76,17,17)",
                        marginLeft: 5,
                      }
                }
              />
            </Box>

            {!isEdit ? (
              <Typography variant="subtitle2">
                {capitalizeFirstChar(address.addressParams.locality)},{" "}
                {address.addressParams.adminAreaLevel1.toUpperCase()}
              </Typography>
            ) : (
              <Grid item xs={12} md={6} style={{ marginTop: 10 }}>
                <LocationSearch
                  address={address}
                  onSelectAddress={this.handleAddress}
                  addressSetInitial={isEdit}
                  label="City"
                  city
                  showOneResult
                />
              </Grid>
            )}
            <Box style={{ display: "flex", alignItems: "center" }}>
              <QueryBuilderIcon
                color="primary"
                style={{ fontSize: 15, marginRight: 5 }}
              />
              <Typography variant="subtitle2" color="textSecondary">
                {cgViews.length} view{cgViews.length > 1 && "s"}
              </Typography>
            </Box>
          </div>
        </Paper>

        <Paper
          elevation={4}
          className={classes.smallPaperContainer}
          style={{
            width: "100%",
            marginTop: 16,
            padding: 16,
          }}
        >
          <Grid item xs={12} style={{ minHeight: 100 }}>
            <Box
              display="flex"
              alignItems="center"
              style={{ marginBottom: 16 }}
            >
              <Typography
                variant="body2"
                style={{ fontWeight: 550, marginRight: 10 }}
              >
                Applications
              </Typography>
              {parseInt(numApply) > 0 && (
                <Avatar
                  className={classes.dashCounterOrj}
                  style={{ height: 25, width: 25 }}
                >
                  {numApply}
                </Avatar>
              )}
            </Box>
            {jobApps.length < 1 ? (
              <Box>
                <Box display="flex" justifyContent="center">
                  <PeopleIcon
                    style={{ color: "rgba(98, 98, 98, 0.9)", fontSize: 32 }}
                  />
                </Box>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  style={{ fontWeight: 300 }}
                >
                  It seems that you don't have any applications so far. Click{" "}
                  <Box component={MuiLink} href="/caregivers/search/">
                    here
                  </Box>{" "}
                  to start your search.
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={2} style={{ marginBottom: 10 }}>
                {jobApps.slice(0, 10).map((jobApp) => (
                  <JobAppMediaCard jobApp={jobApp} key={jobApp.id} />
                ))}
                {showMore &&
                  jobApps
                    .slice(10, jobApps.length)
                    .map((jobApp) => (
                      <JobAppMediaCard jobApp={jobApp} key={jobApp.id} />
                    ))}
              </Grid>
            )}
            {jobApps.length > 10 && (
              <Grid container item xs={12} justifyContent="center">
                <Button
                  size="small"
                  color="primary"
                  style={{ textTransform: "none" }}
                  onClick={this.handleMoreApplications}
                >
                  View More
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>

        <Paper
          elevation={4}
          className={classes.smallPaperContainer}
          style={{
            width: "100%",
            marginTop: 16,
            padding: 16,
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", marginBottom: 8 }}
            >
              Schedule Highlights
            </Typography>

            {!isEdit ? (
              <Grid
                container
                justifyContent="center"
                className={classes.form}
                style={{ marginBottom: 20 }}
              >
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ fontWeight: 300 }}>
                    Start Time:
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: 300 }}>
                    Hours Per Week:
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: 300 }}>
                    Estimated Length:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ fontWeight: 300 }}>
                    {Object.keys(serviceStartObj).filter(
                      (key) => serviceStartObj[key] === serviceStart
                    )}
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: 300 }}>
                    {Object.keys(servicePerWeekObj).filter(
                      (key) => servicePerWeekObj[key] === servicePerWeek
                    )}
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: 300 }}>
                    {Object.keys(serviceLengthObj).filter(
                      (key) => serviceLengthObj[key] === serviceLength
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Fade in={isEdit} mountOnEnter unmountOnExit>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ marginBottom: 20 }}
                >
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Start Time:{isEdit && "*"}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginBottom: 20 }}>
                    <SingleObjSelect
                      justifyContent="flex-start"
                      allObjValue={serviceStartObj}
                      currValue={serviceStart}
                      selectValue={this.selectServiceStart}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Hours Per Week:{isEdit && "*"}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginBottom: 20 }}>
                    <SingleObjSelect
                      justifyContent="flex-start"
                      allObjValue={servicePerWeekObj}
                      currValue={servicePerWeek}
                      selectValue={this.selectServPerWeek}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Estimated Length:{isEdit && "*"}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginBottom: 20 }}>
                    <SingleObjSelect
                      justifyContent="flex-start"
                      allObjValue={serviceLengthObj}
                      currValue={serviceLength}
                      selectValue={this.selectServiceLength}
                    />
                  </Grid>
                </Grid>
              </Fade>
            )}

            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", marginBottom: 8 }}
                color={errors.description ? "error" : "inherit"}
              >
                Job Description{isEdit && "*"}
              </Typography>
              {!isEdit ? (
                <Typography
                  variant="body2"
                  style={{ fontWeight: 300, whiteSpace: "pre-line" }}
                >
                  {description}
                </Typography>
              ) : (
                <TextField
                  value={
                    !Boolean(this.state.description)
                      ? description
                      : this.state.description
                  }
                  fullWidth
                  multiline
                  minRows={10}
                  size="small"
                  variant="outlined"
                  name="description"
                  onChange={(e) =>
                    this.setState({
                      description: e.target.value,
                      errors: { ...this.state.errors, description: false },
                    })
                  }
                  className={
                    errors.description
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={errors.description}
                  helperText={errors.description ? "Must not be empty" : null}
                />
              )}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", marginBottom: 8 }}
              >
                Language Preferences{isEdit && "*"}
              </Typography>
              {!isEdit ? (
                languageChip
              ) : (
                <Fade in={isEdit} mountOnEnter unmountOnExit>
                  <div>
                    <ArraySelectButton
                      optionsAll={signupMaterials.language}
                      optionsCntr={languagePref}
                      onHandleSelect={this.handleLanguagePref}
                      startIcon={<LanguageIcon />}
                    />
                  </div>
                </Fade>
              )}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", marginBottom: 8 }}
                color={errors.service ? "error" : "inherit"}
              >
                Services Needed{isEdit && "*"}
              </Typography>
              {errors.service ? (
                <Typography variant="subtitle2" color={"error"}>
                  Must select at least 1
                </Typography>
              ) : null}
              {!isEdit ? (
                serviceChip
              ) : (
                <Fade in={isEdit} mountOnEnter unmountOnExit>
                  <div>
                    <ObjSelectButton
                      optionsAll={signupMaterials.service}
                      optionsCntr={service}
                      startIcon={<AssignmentIcon />}
                      unselStartIcon={<AssignmentOutlinedIcon />}
                      onHandleSelect={this.handleService}
                      error={errors.service ? "Is Error" : null}
                    />
                  </div>
                </Fade>
              )}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", marginBottom: 8 }}
                color={errors.conditions ? "error" : "inherit"}
              >
                Special Conditions{isEdit && "*"}
              </Typography>
              {errors.conditions ? (
                <Typography variant="subtitle2" color={"error"}>
                  Must select at least 1
                </Typography>
              ) : null}
              {!isEdit ? (
                conditionsChip
              ) : (
                <Fade in={isEdit} mountOnEnter unmountOnExit>
                  <div>
                    <ObjSelectButton
                      optionsAll={signupMaterials.conditions}
                      optionsCntr={conditions}
                      startIcon={<LocalHospitalIcon />}
                      unselStartIcon={<LocalHospitalOutlinedIcon />}
                      onHandleSelect={this.handleConditions}
                      error={errors.conditions ? "Is Error" : null}
                    />
                  </div>
                </Fade>
              )}
            </Grid>
          </Grid>
          <Fade in={isEdit} mountOnEnter unmountOnExit>
            <div>
              <Divider />
              <div className={classes.flexBoxEnd}>
                <Button
                  color="primary"
                  className={classes.txtTrButton}
                  style={{ width: 100 }}
                  onClick={this.handleEditJob}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={this.handleSaveJob}
                  color="primary"
                  disabled={isLoading}
                  className={classes.txtTrButton}
                  style={{ width: 100 }}
                >
                  Save
                  {isLoading && (
                    <CircularProgress
                      thickness={2}
                      size={30}
                      className={classes.progress}
                    />
                  )}
                </Button>
              </div>
            </div>
          </Fade>
        </Paper>
      </Grid>
    );
  }
}

JobMarkup.propTypes = {
  jobData: PropTypes.object.isRequired,
  signupMaterials: PropTypes.object.isRequired,
  hideData: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default withStyles(styles)(JobMarkup);
