import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";

// Component imports
import AccountStatusBlock from "../../../components/profile/AccountStatusBlock";
import AboutMeBlock from "../../../components/profile/AboutMeBlock";
import MyAvailBlock from "./eduWork/MyAvailBlock";
import ProfilePicBlock from "../../../components/profile/ProfilePicBlock";
import MyInfoBlock from "../../../components/profile/MyInfoBlock";
import JobServiceConditionContainer from "./about/JobServiceConditionContainer";
import EduAccredBlock from "./eduWork/EduAccredBlock";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ReviewBlock from "./about/ReviewBlock";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class CaregiverProfile extends Component {
  state = {
    signupMaterials: {
      language: [],
      service: {},
      jobType: {},
      conditions: {},
    },
    reviews: [],
    isLoadedReviews: false,
  };

  componentDidMount() {
    this.getSignup();
    this.getReviews();
  }

  async getSignup() {
    try {
      const _signupMaterials = await axios.get("/signup-material");
      let signupMaterials = _signupMaterials.data;
      this.setState({ signupMaterials });
    } catch (err) {
      console.error(err.response.data);
      return;
    }
  }

  async getReviews() {
    const credentials = this.props.user.credentials;
    if (credentials.reviews.length > 0) {
      const reviewsDoc = await axios.get("/review/get_all");
      this.setState({ reviews: reviewsDoc.data, isLoadedReviews: true });
    } else {
      this.setState({ isLoadedReviews: true });
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const {
      classes,
      user: { credentials, workExp, eduAccred },
    } = this.props;

    const { signupMaterials, reviews, isLoadedReviews } = this.state;

    const headerInfo = (
      <Grid item xs={12} sm={10} md={4}>
        <ProfilePicBlock credentials={credentials} />

        <Box style={{ marginTop: 16 }}>
          <AccountStatusBlock credentials={credentials} />
        </Box>

        <Box style={{ marginTop: 16 }}>
          <MyInfoBlock credentials={credentials} />
        </Box>

        <Box style={{ marginTop: 16 }}>
          <AboutMeBlock
            credentials={credentials}
            languageAll={signupMaterials.language}
          />
        </Box>
      </Grid>
    );

    const content = (
      <Grid item xs={12} sm={10} md={8}>
        <Paper elevation={4} className={classes.darkGrayBorder}>
          <MyAvailBlock />
        </Paper>
        <Paper
          elevation={4}
          className={classes.darkGrayBorder}
          style={{
            marginTop: 16,
          }}
        >
          <JobServiceConditionContainer
            credentials={credentials}
            signupMaterials={signupMaterials}
          />
        </Paper>

        <Paper
          elevation={4}
          className={classes.darkGrayBorder}
          style={{
            marginTop: 16,
          }}
        >
          <EduAccredBlock workExps={workExp} eduAccreds={eduAccred} />
        </Paper>
        <Paper
          elevation={4}
          className={classes.darkGrayBorder}
          style={{
            marginTop: 16,
          }}
        >
          <ReviewBlock isLoadedReviews={isLoadedReviews} reviews={reviews} />
        </Paper>
      </Grid>
    );
    return (
      <div>
        {/* // Show only xs */}
        <Hidden smUp>
          <Grid container justifyContent="center" className={classes.form}>
            <Grid item xs={12}>
              {headerInfo}

              <div style={{ marginTop: 16 }}>{content}</div>
            </Grid>
          </Grid>
        </Hidden>

        {/* // Hidden only xs */}

        <Hidden only="xs">
          <Grid
            container
            justifyContent="center"
            className={classes.form}
            spacing={2}
          >
            {headerInfo}
            {content}
          </Grid>
        </Hidden>
      </div>
    );
  }
}

CaregiverProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(CaregiverProfile);
