import React, { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

// Redux imports
import { connect } from "react-redux";

// Component imports
import { isEmpty } from "../../../util/utilFunctions";
import WeekSelector from "../../layout/WeekSelector";
import ShiftAccordion from "../shift/ShiftAccordion";
import ShiftTableLabels from "./ShiftTableLabels";
import AddShiftButton from "../../buttons/shift_task/AddShiftButton";
import LoadingAnimation from "../../layout/LoadingAnimation";
import MemberDisplay from "../member/MemberDisplay";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Hidden from "@mui/material/Hidden";
import Pagination from "@mui/material/Pagination";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Icon imports
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class MemberShiftDataContainer extends Component {
  state = {
    weekStart: dayjs().startOf("week").toISOString(),
    weekEnd: dayjs().endOf("week").toISOString(),
    filteredShifts: [],
    numFilteredShifts: 0,
    isInitialSetShifts: false,
    showAll: false,
    currPage: 1,
    isSearchSelectedShift: false,
  };

  componentDidMount() {
    this.searchSelectedShift();
  }

  componentDidUpdate(prevProps) {
    const {
      currMemberId,
      data: { isLoadedShifts },
      UI: { updateCheck },
    } = this.props;
    const { isInitialSetShifts, isSearchSelectedShift } = this.state;
    if (isLoadedShifts && !isEmpty(currMemberId)) {
      if (!isInitialSetShifts) {
        if (isSearchSelectedShift) this.handleSortShiftsInitialSet();
        else this.handleSortShifts();
      }

      if (isInitialSetShifts && currMemberId !== prevProps.currMemberId) {
        this.handleSortShifts();
      }
    }
    if (updateCheck && !prevProps.UI.updateCheck) {
      if (isSearchSelectedShift) this.handleSortShiftsInitialSet();
      else this.handleSortShifts();
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  searchSelectedShift() {
    const isSearchSelectedShift = Boolean(this.props.shiftId);
    this.setState({ isSearchSelectedShift });
  }

  setInitialWeek = () => {
    const {
      data: { shifts },
    } = this.props;

    const shiftId = this.props.shiftId;
    const pos = shifts.findIndex((shift) => shift.id === shiftId);

    let weekStart = dayjs().startOf("week").toISOString();
    let weekEnd = dayjs().endOf("week").toISOString();

    if (pos > -1) {
      weekStart = dayjs(shifts[pos].startTime).startOf("week").toISOString();
      weekEnd = dayjs(shifts[pos].endTime).endOf("week").toISOString();
    }
    return {
      weekStart,
      weekEnd,
      isSkip: pos < 0,
    };
  };

  handleSortShiftsInitialSet = () => {
    const { weekStart, weekEnd, isSkip } = this.setInitialWeek();
    if (isSkip) this.handleSortShifts();
    else {
      const {
        data: { shifts },
        currMemberData,
        currMemberId,
      } = this.props;
      const { showAll } = this.state;

      let filteredShifts = [...shifts];

      if (currMemberId !== "all")
        filteredShifts = filteredShifts.filter(
          (k) => k.memberId === currMemberData.id
        );

      if (!showAll) {
        filteredShifts = filteredShifts.filter(
          (k) =>
            dayjs(k.endTime).isSameOrBefore(weekEnd, "d") &&
            dayjs(k.startTime).isSameOrAfter(weekStart, "d")
        );
      }

      const numFilteredShifts = filteredShifts.length;
      const pos = filteredShifts.findIndex(
        (shift) => shift.id === this.props.shiftId
      );
      const currPage = Math.ceil((pos + 1) / 12);
      filteredShifts = filteredShifts.slice(currPage * 12 - 12, currPage * 12);

      this.setState({
        filteredShifts,
        isInitialSetShifts: true,
        numFilteredShifts,
        weekStart,
        weekEnd,
        currPage,
        isSearchSelectedShift: false,
      });
    }
  };

  onlyUnique(value, index, self) {
    return self.findIndex((v) => v.id === value.id) === index;
  }

  handleSortShifts = () => {
    const {
      data: { shifts },
      currMemberData,
      currMemberId,
    } = this.props;
    const { weekStart, weekEnd, showAll, currPage } = this.state;

    let filteredShifts = [...shifts].filter(this.onlyUnique);

    if (currMemberId !== "all")
      filteredShifts = filteredShifts.filter(
        (k) => k.memberId === currMemberData.id
      );

    if (!showAll) {
      filteredShifts = filteredShifts.filter(
        (k) =>
          dayjs(k.endTime).isSameOrBefore(weekEnd, "d") &&
          dayjs(k.startTime).isSameOrAfter(weekStart, "d")
      );
    }

    const numFilteredShifts = filteredShifts.length;
    filteredShifts = filteredShifts.slice(currPage * 12 - 12, currPage * 12);

    this.setState({
      filteredShifts,
      isInitialSetShifts: true,
      numFilteredShifts,
    });
  };

  handleWeekData = async (weekStart, weekEnd) => {
    const { firstDateLoaded } = this.props.data;
    if (weekEnd < firstDateLoaded) {
      this.props.loadShifts(weekStart);
    }
    await this.setStateAsync({
      weekStart,
      weekEnd,
    });
    if (!isEmpty(this.props.currMemberId)) {
      await this.handleSortShifts();
    }
  };

  handleShowAll = async (e) => {
    const { showAll } = this.state;
    const { firstDateLoaded } = this.props.data;

    const lastYearDate = dayjs()
      .subtract(3, "months")
      .startOf("month")
      .toISOString();

    if (lastYearDate < firstDateLoaded) {
      this.props.loadShifts(lastYearDate);
    }
    await this.setStateAsync({ showAll: !showAll, currPage: 1 });

    this.handleSortShifts();
  };

  handleShowOlder = () => {
    const { firstDateLoaded } = this.props.data;

    const lastYearDate = dayjs(firstDateLoaded)
      .subtract(2, "months")
      .startOf("month")
      .toISOString();

    this.props.loadShifts(lastYearDate, true);
    this.setState({ currPage: 1 });

    this.handleSortShifts();
  };

  handleUpdatePage = async (e, value) => {
    await this.setStateAsync({ currPage: value });

    this.handleSortShifts();
  };

  onUpdateMember = () => {
    this.props.onUpdateMember();
  };

  finishSelect = () => {
    this.props.onSelectBack();
  };

  render() {
    const {
      classes,
      currMemberData,
      isInitialSet,
      currMemberId,
      scheduleNewShift,
      shiftId,
      credentials: { userType },
      data: { shifts, isLoadedShifts, firstDateLoaded },
    } = this.props;
    const {
      filteredShifts,
      isInitialSetShifts,
      weekStart,
      weekEnd,
      numFilteredShifts,
      currPage,
      showAll,
    } = this.state;

    const isFamily = userType === "family";

    return (
      <div
        style={{ flexDirection: "column" }}
        className={classes.flexBoxSpaced}
      >
        <Box
          className={classes.flexBox}
          style={{ marginBottom: 8, width: "100%" }}
        >
          <Hidden mdUp>
            <IconButton
              size="small"
              onClick={this.finishSelect}
              style={{ marginRight: 8 }}
            >
              <ArrowBackIosIcon color="primary" />
            </IconButton>
          </Hidden>
          <Typography variant="h6" style={{ fontWeight: 300 }}>
            Schedule
          </Typography>
        </Box>
        <Divider style={{ width: "100%" }} />
        {isInitialSet && currMemberId !== "all" && (
          <MemberDisplay
            member={currMemberData}
            onUpdateMember={this.onUpdateMember}
          />
        )}
        {isInitialSet && currMemberId === "all" && (
          <div style={{ width: "100%", textAlign: "left", margin: "8px 0px" }}>
            <Typography variant="h5" style={{ fontWeight: 300 }}>
              Showing All Shifts
            </Typography>
          </div>
        )}

        {isFamily && currMemberId !== "all" && (
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ margin: "8px 0px", width: "100%" }}
          >
            <AddShiftButton
              currMemberData={currMemberData}
              initialOpen={scheduleNewShift}
              isInitialSetShifts={isInitialSetShifts}
              style={{ borderRadius: 25, textTransform: "none" }}
            />
          </Box>
        )}
        <Box
          className={classes.flexBoxSpaced}
          style={{
            padding: "8px 0px",
            width: "100%",
            borderRadius: 10,
            backgroundColor: "rgba(212,212,212,0.15)",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showAll}
                size="small"
                onChange={this.handleShowAll}
                name="showAll"
                color="primary"
              />
            }
            label={
              <Typography
                variant="subtitle2"
                color={showAll ? "primary" : "textSecondary"}
              >
                All Shifts
              </Typography>
            }
            style={{ marginLeft: 0 }}
          />
          <WeekSelector
            disabled={showAll || !isLoadedShifts}
            setWeekData={this.handleWeekData}
            derived
            weekStart={weekStart}
            weekEnd={weekEnd}
          />
        </Box>

        <Box style={{ minHeight: 40, width: "100%" }}>
          <ShiftTableLabels
            isInitialSet={isInitialSet}
            shifts={shifts}
            filteredShifts={filteredShifts}
            currMemberId={currMemberId}
            showAll={showAll}
            isFamily={isFamily}
          />
        </Box>

        <Box
          style={{
            padding: 5,
            minHeight: 300,
            flex: 1,
            width: "100%",
          }}
        >
          {!isLoadedShifts || !isInitialSetShifts || !isInitialSet ? (
            <Box style={{ padding: "16px 4px" }}>
              <LoadingAnimation message="Loading" />
            </Box>
          ) : (
            <Box>
              {filteredShifts.length > 0 ? (
                <Fade in>
                  <div>
                    <ShiftAccordion
                      filteredShifts={filteredShifts}
                      currMemberId={currMemberId}
                      shiftId={shiftId}
                      showName={currMemberId === "all"}
                      isFamily={isFamily}
                    />
                  </div>
                </Fade>
              ) : (
                !showAll && (
                  <Box style={{ textAlign: "center", padding: "16px 4px" }}>
                    <SearchIcon color="disabled" fontSize="large" />
                    <Typography variant="body2" color="textSecondary">
                      No shifts found between
                    </Typography>
                    <Typography variant="body2" style={{ marginTop: 4 }}>
                      {dayjs(weekStart).format("MMMM DD, YYYY")} -{" "}
                      {dayjs(weekEnd).format("MMMM DD, YYYY")}{" "}
                    </Typography>
                  </Box>
                )
              )}
              {showAll && currPage >= Math.ceil(numFilteredShifts / 12) && (
                <Fade in mountOnEnter unmountOnExit>
                  <Box
                    className={classes.flexBoxCentered}
                    style={{ flexDirection: "column", padding: 8 }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: 8 }}
                    >
                      Showing all shifts since{" "}
                      {dayjs(firstDateLoaded).format("MMMM DD, YYYY")}.
                    </Typography>
                    <Button
                      onClick={this.handleShowOlder}
                      className={classes.txtTrButton}
                      variant="outlined"
                      // style={{ margin: 0 }}
                      size="small"
                      color="primary"
                    >
                      Load More
                    </Button>
                  </Box>
                </Fade>
              )}
            </Box>
          )}
        </Box>
        <Pagination
          count={
            Math.ceil(numFilteredShifts / 12) > 0
              ? Math.ceil(numFilteredShifts / 12)
              : 1
          }
          page={currPage}
          onChange={this.handleUpdatePage}
          shape="rounded"
        />
      </div>
    );
  }
}

MemberShiftDataContainer.propTypes = {
  currMemberData: PropTypes.object.isRequired,
  onSelectBack: PropTypes.func,
  currMemberId: PropTypes.string.isRequired,
  scheduleNewShift: PropTypes.bool,
  loadShifts: PropTypes.func.isRequired,
  isInitialSet: PropTypes.bool.isRequired,
  onUpdateMember: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  UI: state.UI,
  credentials: state.user.credentials,
});
export default connect(mapStateToProps)(
  withStyles(styles)(MemberShiftDataContainer)
);
