import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import Stepper from "../../../../components/layout/Components/Stepper";
import LocationSearch from "../../../../components/inputs/LocationSearch";
import { allFalse } from "../../../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class NewJobFinal extends Component {
  state = {
    address: { addressFull: "" },
    description: "",
    addressSetInitial: false,
    errors: {},
  };

  // Initial load methods
  componentDidMount() {
    this.setInitial();
  }

  setInitial() {
    const { address, description, credentials } = this.props;
    let obj = {};
    if (description !== "") obj.description = description;

    if (address.addressFull !== "") {
      obj.address = address;
      obj.addressSetInitial = true;
    } else {
      obj.address = credentials.address;
      obj.addressSetInitial = true;
    }

    this.setState({ ...obj });
  }

  // Error validation method

  validateSubmit = () => {
    const {
      errors,
      description,
      address: { addressFull },
    } = this.state;
    let _errors = errors;
    if (description.trim() === "") _errors.description = "Must not be empty";

    if (addressFull === "") _errors.addressFull = true;

    this.setState({ errors: _errors });
  };

  // Action methods
  handleAddress = (value) => {
    this.setState({
      address: value,
      errors: { ...this.state.errors, addressFull: false },
    });
  };

  handleChange = (e) => {
    const { errors } = this.state;
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      errors: {
        ...errors,
        [name]: value === "" && "Job description must not be empty",
      },
      [name]: value,
    });
  };

  handleSubmit = async () => {
    await this.validateSubmit();
    const { errors, description, address } = this.state;
    if (allFalse(errors)) {
      this.props.onFinish({
        address,
        description,
      });
    }
  };

  handleBack = () => {
    this.props.onFinish({
      pages: { p3: true, p4: false },
    });
  };

  render() {
    const { classes, total, fadeIn } = this.props;
    const { address, addressSetInitial, description, errors } = this.state;
    return (
      <Box p={2}>
        <Stepper total={total} fadeIn={fadeIn} />
        <br />
        <Typography
          variant="h6"
          color="primary"
          style={{ margin: "10px 0px", fontWeight: 300 }}
          className={classes.typography}
        >
          Finishing Touches
        </Typography>
        <Box className={classes.sectionBoxLeftOutline}>
          <Typography
            variant="body2"
            style={{ fontWeight: 300, marginBottom: 4, whiteSpace: "pre-line" }}
          >
            Confirm the city the job will take place. {"\n"} For your privacy, a
            detailed address can only be provided once a hiring decision is
            made.
          </Typography>
          <LocationSearch
            onSelectAddress={this.handleAddress}
            errorAddress={Boolean(errors.addressFull)}
            medium
            city
            showOneResult
            label="City"
            address={address.addressFull !== "" ? address : null}
            addressSetInitial={addressSetInitial}
          />
          <Typography
            variant="body2"
            style={{ fontWeight: 300, marginBottom: 4 }}
          >
            Provide a brief job description as well as other important job
            details.
          </Typography>
          <TextField
            id="description"
            name="description"
            type="description"
            label="Job Description"
            variant="outlined"
            value={description}
            error={Boolean(errors.description)}
            helperText={Boolean(errors.description) ? errors.description : ""}
            onChange={this.handleChange}
            className={
              !Boolean(errors.description)
                ? classes.styledTextField
                : classes.styledTextFieldError
            }
            fullWidth
            multiline
            minRows={5}
            size="medium"
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={this.handleBack}
            style={{
              marginRight: 10,
              width: 100,
              backgroundColor: "white",
              textTransform: "none",
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleSubmit}
            style={{ width: 100, textTransform: "none" }}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  }
}

NewJobFinal.propTypes = {
  classes: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  fadeIn: PropTypes.number.isRequired,
  address: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  credentials: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default withStyles(styles)(NewJobFinal);
