import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// Redux imports
import { connect } from "react-redux";
import { updateDataArray } from "../../../redux/actions/dataActions";
import { setAlert } from "../../../redux/actions/uiActions";

// Component imports
import { capitalizeFirstChar, isEmpty } from "../../../util/utilFunctions";

import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// Icons import
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import NoteIcon from "@mui/icons-material/Note";
import EventNoteIcon from "@mui/icons-material/EventNote";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class PinnedNote extends Component {
  state = {
    isEdit: false,
    pinnedNote: "",
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  // UI methods
  handleShowCareplan = () => {
    this.setState({ showCareplan: !this.state.showCareplan });
  };

  handleEdit = () => {
    if (this.state.isEdit)
      this.setState({
        isEdit: false,
        pinnedNote: "",
      });
    else
      this.setState({
        isEdit: true,
        pinnedNote: Boolean(this.props.member.pinnedNote)
          ? this.props.member.pinnedNote
          : "",
      });
  };

  // Action methods
  handlePinnedNote = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddCareItem = async (e) => {
    e.preventDefault();
    const updateObj = { pinnedNote: this.state.pinnedNote };
    await this.setStateAsync({ disableWhileLoad: true });
    try {
      const memberDoc = await axios.post(
        `/team/member/${this.props.member.id}/update`,
        updateObj
      );
      this.props.updateDataArray(memberDoc.data, "members", this.props.index);
      this.props.setAlert({
        message: "Successfully added pinned note",
        type: "success",
      });
      if (this.props.onUpdate) this.props.onUpdate();
      await this.handleEdit();
    } catch (err) {
      this.props.setAlert({
        message: "An error has occured while updating your pinned note",
        type: "error",
      });
    } finally {
      this.setState({ disableWhileLoad: false });
    }
  };

  render() {
    const { classes, member, isFamily } = this.props;
    const { isEdit, disableWhileLoad, pinnedNote } = this.state;
    return (
      <Box>
        <Box display="flex">
          <Typography align="center" style={{ fontWeight: 300, flex: 1 }}>
            Pinned Note
          </Typography>
          {isFamily && (
            <IconButton size="small" color="primary" onClick={this.handleEdit}>
              {isEdit ? <NoteIcon /> : <NoteOutlinedIcon />}
            </IconButton>
          )}
        </Box>
        <Box
          className={classes.sectionBoxLeftOutline}
          style={{ paddingTop: 8, paddingBottom: 8 }}
        >
          {isEdit ? (
            <Box style={{ margin: "8px 0px" }}>
              <Typography variant="subtitle2" color="textSecondary">
                Add a pinned note:
              </Typography>
              <form
                onSubmit={this.handleAddCareItem}
                className={classes.flexBox}
              >
                <TextField
                  value={pinnedNote}
                  name="pinnedNote"
                  variant="outlined"
                  className={classes.styledTextField}
                  onChange={this.handlePinnedNote}
                  style={{ margin: 0 }}
                  fullWidth
                  size="small"
                  multiline
                  maxRows={5}
                  disabled={disableWhileLoad}
                />
                <Button
                  style={{ height: 40, marginLeft: 4, boxShadow: "none" }}
                  color="primary"
                  disabled={disableWhileLoad}
                  type="submit"
                >
                  Pin
                </Button>
              </form>
            </Box>
          ) : (
            <Box>
              {member.hasOwnProperty("pinnedNote") &&
              !isEmpty(member.pinnedNote) ? (
                <Typography
                  color="textSecondary"
                  style={{ fontWeight: 300, whiteSpace: "pre-line" }}
                >
                  {member.pinnedNote}
                </Typography>
              ) : isFamily ? (
                <Box style={{ textAlign: "center" }}>
                  <EventNoteIcon fontSize="large" style={{ opacity: 0.7 }} />
                  <Typography variant="body2" color="textSecondary">
                    Pin a note here which can be viewed by{" "}
                    {capitalizeFirstChar(member.caregiverInfo.firstName)} from
                    all shifts. Add important client information that applies to
                    all visits.
                  </Typography>
                </Box>
              ) : (
                <Box style={{ textAlign: "center" }}>
                  <Typography variant="body2" color="textSecondary">
                    {capitalizeFirstChar(member.familyInfo.firstName)} has not
                    pinned a note.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

PinnedNote.propTypes = {
  classes: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  index: PropTypes.number,
  isFamily: PropTypes.bool.isRequired,
  updateDataArray: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { updateDataArray, setAlert })(
  withStyles(styles)(PinnedNote)
);
