import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux imports
import { connect } from "react-redux";
import { setFav, setUnfav } from "../../../redux/actions/userActions";
import TooltipButton from "../../inputs/TooltipButton";

// MUI imports
import FavouriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export class FavouriteIconButton extends Component {
  handleFav = () => {
    const {
      id,
      user: { favourite },
    } = this.props;
    const isFav = favourite.indexOf(id) >= 0;

    if (isFav) {
      this.props.setUnfav(id);
    } else {
      this.props.setFav(id);
    }
  };

  render() {
    const {
      user: { favourite },
      id,
      className,
    } = this.props;

    const isFav = favourite.indexOf(id) >= 0;

    return (
      <TooltipButton
        tip={isFav ? "Unfavourite" : "Favourite"}
        size="small"
        onClick={this.handleFav}
        btnClassName={className}
      >
        {isFav ? (
          <FavouriteIcon style={{ color: "rgba(204,28,53,0.7)" }} />
        ) : (
          <FavoriteBorderIcon style={{ color: "rgb(204,28,53)" }} />
        )}
      </TooltipButton>
    );
  }
}

FavouriteIconButton.propTypes = {
  user: PropTypes.object.isRequired,
  setFav: PropTypes.func.isRequired,
  setUnfav: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, { setFav, setUnfav })(
  FavouriteIconButton
);
