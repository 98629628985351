import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import AnimatedCheck from "../../../../assets/AnimatedCheck";
import { capitalizeFirstChar } from "../../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
  media: {
    display: "block",
    marginRight: "auto",
    marginLeft: "auto",
    borderRadius: "50%",
    objectFit: "cover",
    height: 200,
    width: 200,
    marginTop: 10,
  },
  textFieldHelperText: {
    width: "80%",
    marginBottom: 10,
    "& p": {
      color: theme.palette.primary.main,
    },
  },
});

export class InvitationSent extends Component {
  state = { timer: 20, isRedirect: false };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    const refreshIntervalId = setInterval(() => {
      this.setState((prevState) => {
        return { timer: prevState.timer - 1 };
      });
    }, 1000);
    this.setState({ refreshIntervalId });
  }

  componentDidUpdate() {
    if (this.state.timer === 0) {
      this.handleDashboard();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  handleDashboard = () => {
    clearInterval(this.state.refreshIntervalId);
    this.props.history.push("/dashboard/");
  };

  render() {
    const { classes, userData } = this.props;
    const { timer } = this.state;

    return (
      <Fade in mountOnEnter>
        <Paper
          elevation={4}
          className={classes.smallPaperContainer}
          style={{ padding: 16 }}
        >
          <Box
            className={classes.flexBoxCentered}
            style={{ flexDirection: "column" }}
          >
            <AnimatedCheck large />
            <Typography
              variant="h4"
              align="center"
              color="primary"
              style={{ marginBottom: 24 }}
            >
              Congratulations!
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              style={{ marginBottom: 24 }}
            >
              Your Job Invitation Was Sent.
            </Typography>
            <Typography align="center" style={{ marginBottom: 24 }}>
              You will be notified when{" "}
              {capitalizeFirstChar(userData.firstName)} responds to your offer.
              Once the invitation has been accepted, you will be able to
              schedule your first shift!
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              Redirecting to the homepage in {timer}...
            </Typography>
            <Button
              onClick={this.handleDashboard}
              className={classes.txtTrButton}
              color="primary"
            >
              Go Back Now
            </Button>
          </Box>
        </Paper>
      </Fade>
    );
  }
}

InvitationSent.propTypes = {
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
};

export default withStyles(styles)(InvitationSent);
