import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Redux imports
import { connect } from "react-redux";

// Component imports
import { capitalizeFirstChar } from "../../../util/utilFunctions";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupIcon from "@mui/icons-material/Group";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const styles = (theme) => ({
  ...theme.spreadThis,
  selectedCgDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(212,212,212,0.7)",

    textTransform: "none",

    borderRight: `2px solid ${theme.palette.primary.light}`,
  },
});

export class MemberSelectContainer extends Component {
  selectMember = (event) => {
    this.props.onSelectMember(event.currentTarget.value);
    if (this.props.onSelectNext) this.props.onSelectNext();
  };

  finishSelect = () => {
    this.props.onSelectNext();
  };
  render() {
    const {
      classes,
      isReview,
      currMemberId,
      hideShowAll,
      credentials: { userType },
      data: { isLoadedTeams, members },
    } = this.props;

    const isFamily = userType === "family";

    return (
      <div style={{ minHeight: "inherit" }}>
        <Box
          className={classes.flexBoxSpaced}
          style={{ height: 30, marginBottom: 8 }}
        >
          <Typography variant="h6" style={{ fontWeight: 300 }}>
            {isFamily ? "My Team Members" : "My Families"}
          </Typography>
          <Hidden mdUp>
            <IconButton
              size="small"
              disabled={!isLoadedTeams}
              onClick={this.finishSelect}
              color="primary"
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Hidden>
        </Box>
        <Box className="hideScroll" style={{ overflow: "auto" }}>
          {!isLoadedTeams ? (
            <div id="loading-container">
              <Skeleton
                variant="rect"
                height={40}
                className={classes.basicMargin}
              />
              {[...new Array(4)].map((v, ind) => (
                <div
                  className={classes.flexBox}
                  key={ind}
                  style={{ margin: "4px 0px" }}
                >
                  <Skeleton variant="circular" height={70} width={70} />
                  <div style={{ marginLeft: 8 }}>
                    <Skeleton width={150} />
                    <Skeleton width={150} />
                  </div>
                </div>
              ))}
            </div>
          ) : members.length < 1 ? (
            <Box
              className={classes.selectedGrayContainer}
              style={{ height: "100%", textAlign: "center", marginTop: 10 }}
            >
              <GroupIcon color="primary" fontSize="large" />
              <Typography variant="body2" color="textSecondary">
                {isFamily
                  ? "Your Poyo team is currently empty"
                  : "You are currently not part of any teams."}
              </Typography>
            </Box>
          ) : (
            <div>
              {!hideShowAll && (
                <Button
                  color="secondary"
                  className={clsx(
                    classes.flexBox,
                    (!Boolean(currMemberId) || currMemberId === "all") &&
                      classes.selectedCgDiv
                  )}
                  style={{ justifyContent: "center", textTransform: "none" }}
                  fullWidth
                  value="all"
                  onClick={this.selectMember}
                >
                  Show All {isFamily ? "Caregivers" : "Clients"}
                </Button>
              )}
              {members.map((member) => (
                <Button
                  key={member.id}
                  className={clsx(
                    classes.flexBox,
                    currMemberId === member.id && classes.selectedCgDiv,
                    classes.basicMargin
                  )}
                  style={{
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  fullWidth
                  value={member.id}
                  onClick={this.selectMember}
                >
                  <Avatar
                    src={
                      isFamily
                        ? member.caregiverInfo.imgUrlThumb
                        : member.familyInfo.imgUrlThumb
                    }
                    className={classes.avatarSelectButton}
                  />
                  <div style={{ paddingLeft: 10, textAlign: "left" }}>
                    <Typography
                      noWrap
                      variant="body2"
                      color={
                        currMemberId === member.id ? "inherit" : "textSecondary"
                      }
                    >
                      {isFamily
                        ? `${capitalizeFirstChar(
                            member.caregiverInfo.firstName
                          )} ${capitalizeFirstChar(
                            member.caregiverInfo.lastName
                          )}`
                        : `${capitalizeFirstChar(
                            member.familyInfo.clientFirstName
                          )} ${capitalizeFirstChar(
                            member.familyInfo.clientLastName
                          )}`}
                    </Typography>
                    {!isFamily && (
                      <Typography
                        noWrap
                        component="p"
                        variant="caption"
                        color={
                          currMemberId === member.id
                            ? "inherit"
                            : "textSecondary"
                        }
                        style={{ fontWeight: 300 }}
                      >
                        {"("}
                        {capitalizeFirstChar(member.familyInfo.firstName)}{" "}
                        {capitalizeFirstChar(member.familyInfo.lastName)}
                        {")"}
                      </Typography>
                    )}
                  </div>
                  {isReview && (
                    <div
                      className={classes.flexBox}
                      style={{
                        flex: 1,
                        justifyContent: "flex-end",
                        paddingRight: currMemberId === member.id ? 0 : 2,
                      }}
                    >
                      {member.review.isReviewed ? (
                        <CheckCircleIcon />
                      ) : member.review.shouldInquire ? (
                        <PendingActionsIcon />
                      ) : null}
                    </div>
                  )}
                </Button>
              ))}
            </div>
          )}
        </Box>
      </div>
    );
  }
}

MemberSelectContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  currMemberId: PropTypes.string,
  onSelectMember: PropTypes.func.isRequired,
  onSelectNext: PropTypes.func,
  hideShowAll: PropTypes.bool,
  isReview: PropTypes.bool,
  data: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  credentials: state.user.credentials,
});

export default connect(mapStateToProps)(
  withStyles(styles)(MemberSelectContainer)
);
