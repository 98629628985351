import React, { Component } from "react";
import PropTypes from "prop-types";
import CgMyTeam from "../../caregiverPages/myTeam/CgMyTeam";
import FamilyMyTeam from "../../familyPages/myTeam/FamilyMyTeam";
// Redux imports
import { connect } from "react-redux";
import store from "../../../redux/store";
import { SET_PAGE, UNSET_PAGE } from "../../../redux/types";

export class myTeam extends Component {
  componentDidMount() {
    store.dispatch({ type: SET_PAGE, payload: "MyTeam" });
  }

  componentWillUnmount() {
    store.dispatch({ type: UNSET_PAGE });
  }

  render() {
    const {
      user: {
        credentials,
        credentials: { userType },
      },
      UI,
    } = this.props;

    return (
      <div className="res-gr-con">
        {userType === "family" ? (
          <FamilyMyTeam credentials={credentials} UI={UI} />
        ) : userType === "caregiver" ? (
          <CgMyTeam credentials={credentials} UI={UI} />
        ) : null}
      </div>
    );
  }
}

myTeam.propTypes = {
  user: PropTypes.object.isRequired,
  UI: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps)(myTeam);
