import React from "react";
import Typography from "@mui/material/Typography";

function Privacy() {
  return (
    <>
      <Typography style={{ marginBottom: 24 }}>
        Poyo Health Ltd. ("<b>Poyo Health"</b>) is committed to protecting your
        privacy. We have prepared this Privacy Policy to describe to you our
        practices regarding the Personal Data (as defined below) we collect from
        (i) users of our website ("<b>Visitors</b>"), located at
        www.poyohealth.com ("<b>Site</b>") and (ii) Care Seekers, Care Providers
        and other third parties using our Services (collectively, "
        <b>Members</b>" and together with Visitors, "<b>users</b>"). Capitalized
        terms not defined in this Privacy Policy have the meanings given in our
        Terms of Use located at
        <a
          className="lnk-txt-ul"
          href="https://www.poyohealth.com/policies/terms-of-use"
        >
          www.poyohealth.com/policies/terms-of-use
        </a>
        .
      </Typography>
      <ol id="policies">
        <li id="title">
          <div>
            <Typography variant="h4">Note about children</Typography>
            <Typography style={{ marginBottom: "1.5rem" }}>
              We do not intentionally gather Personal Data from users who are
              under the age of 18. If a user under the age of 18 submits
              Personal Data to Company and we learn that the Personal Data is
              the information of a user under the age of 18, we will attempt to
              delete the information as soon as possible. If a parent or
              guardian becomes aware that his or her child has provided us with
              information without their consent, he or she should contact us. We
              will delete such information from our files as soon as reasonably
              possible.
            </Typography>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Data Collection. Personal Data</Typography>
            <Typography style={{ marginBottom: "1.5rem" }}>
              Means any information which, either alone or in combination with
              other information we hold about you, identifies you as an
              individual, including, for example, your name, address, telephone
              number, email address, as well as any other non-public information
              about you that is associated with or linked to any of the
              foregoing data. "Anonymous Data" means data that is not associated
              with or linked to your Personal Data; Anonymous Data does not, by
              itself, permit the identification of individual persons. We
              collect Personal Data and Anonymous Data, as described below.
            </Typography>
            <ol id="policy-details">
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Information You Provide to Us.
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      If you are a Care Provider, when you create an account to
                      log in to our Site, we may collect Personal Data from you,
                      such as your first and last name, birthdate, social
                      insurance number, driver's license number, criminal
                      history, ethnicity, gender, religion, email and mailing
                      address, phone number, work experience, salary/income,
                      professional license numbers, education, interests,
                      hobbies, professional and personal references, profile
                      picture, professional skills and abilities, lifestyle
                      information, schedule availability, music preferences and
                      bank information to facilitate direct deposit of earnings.
                    </li>
                    <li>
                      If you are a Care Seeker, when you create an account to
                      log in to our Site, we may collect Personal Data from you,
                      such as your first and last name, birth date, gender,
                      religion, email and mailing address, phone number, care
                      services needed, health conditions, activities, profile
                      picture, interests, music preferences and credit card
                      information to facilitate payment processing.
                    </li>
                    <li>
                      If you are a Member other than a Care Provider or Care
                      Seeker, such as a family member or healthcare provider,
                      when you create an account to log in to our Site, we may
                      collect Personal Data from you, such as your first and
                      last name, birthdate, social insurance number, driver's
                      license number, criminal history, ethnicity, gender,
                      religion, email and mailing address, phone number, work
                      experience, salary/income, professional license numbers,
                      education, interests, hobbies, professional and personal
                      references, profile picture, professional skills and
                      abilities, lifestyle information, schedule availability,
                      care services needed and health conditions. We retain
                      information on your behalf, such as files and messages
                      that you store using your account.
                    </li>
                    <li>
                      If you provide us feedback or contact us via email, we
                      will collect your name and email address, as well as any
                      other content included in the email, in order to send you
                      a reply.
                    </li>
                    <li>
                      When you post messages or upload content within our Site
                      (including in any Poyo Health forum), the information
                      contained in your posting will be stored on our servers
                      and other users to whom you provide access, will be able
                      to see it.
                    </li>
                    <li>
                      We also collect other types of Personal Data that you
                      provide to us voluntarily, such as your operating system
                      and version, and other requested information if you
                      contact us via email regarding support for the Services.
                    </li>
                    <li>
                      We may also collect Personal Data, at such other points in
                      our Site that state that Personal Data is being collected.
                    </li>
                    <li>
                      Please note that you can choose not to provide us with
                      certain information, but this may limit the features of
                      the Site or Services you are able to use.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Information Collected via Technology
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      To make our Site and Services more useful to you, our
                      servers (which may be hosted by a third-party service
                      provider) collect information from you, including your
                      browser type, operating system, Internet Protocol (IP)
                      address (a number that is automatically assigned to your
                      computer when you use the Internet, which may vary from
                      session to session), domain name, and/or a date/time stamp
                      for your visit.
                    </li>
                    <li>
                      We also use cookies and URL information to gather
                      information regarding the date and time of your visit and
                      the information for which you searched and which you
                      viewed. "Cookies" are small pieces of information that a
                      website sends to your computer's hard drive while you are
                      viewing a web site. We may use both session Cookies (which
                      expire once you close your web browser) and persistent
                      Cookies (which stay on your computer until you delete
                      them) to provide you with a more personal and interactive
                      experience on our Site. Persistent Cookies can be removed
                      by following Internet browser help file directions.
                    </li>
                    <li>
                      We may also use (i) third-party tracking services, such as
                      Google Analytics, to track and analyze Anonymous Data from
                      users of our Site and Services and (ii) location-enabled
                      services for Care Providers as a means to facilitate time
                      card data. If you use the location-enabled services, Poyo
                      Health may receive information about your actual location
                      (such as GPS signals sent by a mobile device or via your
                      web browser when on the Site).
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Information Collected from You About Others
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      Users to our Site can invite other people to become Poyo
                      Health members by sending invitation emails via our Site.
                      We store the email addresses that are provided to us and,
                      if they are provided by a Poyo Health member, we will
                      associate those email addresses with such member's
                      account. We do not currently sell these email addresses or
                      use them to send any other communication besides
                      invitations (unless the email address is used in
                      registration by a new Poyo Health member) but we may do so
                      in the future. Recipients of invitations may unsubscribe
                      by contacting us at support@poyohealth.com to request that
                      we refrain from sending them emails or to have us delete
                      their information in accordance with this Privacy Policy.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Information Collected from Others About You
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      Other Poyo Health Members may provide us Personal and/or
                      Anonymous Data about you through our Site or the Services.
                      Examples include when a Care Seeker posts feedback
                      regarding a Care Provider or when another Poyo Health
                      Member (including a Care Seeker's healthcare provider)
                      submits health information to any Poyo Health forum. If
                      you are a Care Seeker, we will collect certain Personal
                      Information about you via the applicable Care Provider,
                      such as information they collect as part of their
                      performance of certain health tasks and or the completion
                      of certain questions/requirements under a care plan
                      provided by you.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Information Collected from Third-Party Companies
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      We may receive Personal and/or Anonymous Data about you
                      from companies that assist Poyo Health in providing the
                      Services, such as Certn, or companies that offer their
                      products and/or services on our Site. These third-party
                      companies may supply us with your Personal Data. We may
                      add this information to the information we have already
                      collected from you via our Site in order to provide and
                      improve the Services.
                    </li>
                  </ol>
                </div>
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Use of Your Personal Data</Typography>
            <ol id="policy-details">
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    General User
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    In general, Personal Data collected by us is used either to
                    respond to requests that you make, or to aid us in serving
                    you better. We may use your Personal Data in the following
                    ways:
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      to facilitate the creation of and secure your account on
                      our network;
                    </li>
                    <li>identify you as a user in our system;</li>
                    <li>
                      run a background check and verify your credentials (if you
                      are a Care Provider);
                    </li>
                    <li>
                      provide improved administration of our Site and Services;
                    </li>
                    <li>
                      provide the Services and customer support you request;
                    </li>
                    <li>
                      improve the quality of experience when you interact with
                      our Site and Services;
                    </li>
                    <li>
                      tailor the features, performance and support of the Site
                      or Services to you;
                    </li>
                    <li>
                      send you a welcome email to verify ownership of the email
                      address provided when your account was created;
                    </li>
                    <li>
                      send you administrative email notifications, such as
                      security or support and maintenance advisories;
                    </li>
                    <li>
                      respond to your inquiries related to employment
                      opportunities or other requests;
                    </li>
                    <li>
                      respond to your requests, resolve disputes and/or
                      troubleshoot problems;
                    </li>
                    <li>
                      to make telephone calls to you, from time to time, as a
                      part of secondary fraud protection or to solicit your
                      feedback;
                    </li>
                    <li>
                      to send newsletters, surveys, offers, and other
                      promotional materials related to our Services and for
                      other marketing purposes of Poyo Health;
                    </li>
                    <li>
                      customize the advertising you may see on the Site or
                      through the Services;
                    </li>
                    <li>collect and/or distribute money; and</li>
                    <li>
                      verify your compliance with your obligations in our Terms
                      of Use or other Poyo Health policies.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Creation of Anonymous Data
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    We may create Anonymous Data records from Personal Data by
                    excluding information (such as your name) that make the data
                    personally identifiable to you. We use this Anonymous Data
                    to analyze request and usage patterns so that we may enhance
                    the content of our Services and improve Site navigation.
                    Poyo Health reserves the right to use Anonymous Data for any
                    purpose and disclose Anonymous Data to third parties in its
                    sole discretion.
                  </Typography>
                </div>
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">
              Disclosure of Your Personal Data
            </Typography>
            <Typography style={{ marginBottom: "1.5rem" }}>
              We disclose your Personal Data as described below and as described
              elsewhere in this Privacy Policy.
            </Typography>
            <ol id="policy-details">
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Other Poyo Health Members
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    We may share your Personal Data with third parties to whom
                    you ask us to send Personal Data, including other Members.
                    Unless you direct us to share to your Personal Data via your
                    "Privacy Settings" or otherwise, we do not disclose the
                    Personal Data of any Care Seeker or Care Provider to any
                    another Member. We do, however, provide other Members with
                    the opportunity to communicate with you through a web-based
                    message box. If you respond to any in-browser message, you
                    will be providing the recipient your name and allowing them
                    to continue to message you.
                  </Typography>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Third-Party Service Providers
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    We may share your Personal Data with third-party service
                    providers to: provide you with the Services that we offer
                    you through our Site; to conduct quality assurance testing;
                    to facilitate creation of accounts; to provide technical
                    support; and/or to provide other services to Poyo Health.
                    Examples of such third-party service providers include
                    companies that administer background checks of Care
                    Providers and companies that process payments from both Care
                    Providers and Care Seekers. These third-party service
                    providers are required not to use your Personal Data other
                    than to provide the services requested by Poyo Health.
                  </Typography>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Affiliates and Acquisitions
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    We may share some or all of your Personal Data with our
                    parent company, subsidiaries, joint ventures, or other
                    companies under common control with us ("Affiliates"), in
                    which case we will require our Affiliates to honor this
                    Privacy Policy. If another company acquires our company,
                    business, or our assets, by way of any transaction including
                    a merger or bankruptcy proceedings, that company will
                    possess the Personal Data collected by us and will assume
                    the rights and obligations regarding your Personal Data as
                    described in this Privacy Policy.
                  </Typography>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Other Disclosures
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    Regardless of any choices you make regarding your Personal
                    Data (as described below), Poyo Health may disclose Personal
                    Data if it believes in good faith that such disclosure is
                    necessary (a) in connection with any legal investigation;
                    (b) to comply with relevant laws or to respond to subpoenas
                    or warrants served on Poyo Health; (c) to protect or defend
                    the rights or property of Poyo Health or users of the
                    Services; and/or (d) to investigate or assist in preventing
                    any violation or potential violation of the law, this
                    Privacy Policy or Terms of Use.
                  </Typography>
                </div>
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Third-party Data Collection</Typography>

            <ol id="policy-details">
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Information Collected by Ad Networks
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    We use third-party advertising companies to serve ads when
                    you visit our Site. These companies may collect and use
                    information (not including your name, address, email
                    address, or telephone number) about your visits to this and
                    other websites in order to provide advertisements about
                    goods and services to you.
                  </Typography>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Information Collected by Other Third Parties
                  </Typography>
                  <Typography
                    style={{ marginBottom: "1.5rem", whiteSpace: "pre-line" }}
                  >
                    This Privacy Policy addresses only our use and disclosure of
                    information we collect from and/or about you. If you
                    disclose information to others, including to other Poyo
                    Health members, the use and disclosure restrictions
                    contained in this Privacy Policy will not apply to any
                    third-party. We do not control the privacy policies of third
                    parties, and you are subject to the privacy policies of
                    those third parties where applicable. In addition,
                    information made available through the Service by Members
                    may be protected by applicable privacy laws, including the
                    Health Insurance Portability and Accountability Act.
                    {"\n\n"}
                    Also, the Site may contain links to other websites that are
                    not owned or controlled by us. We have no control over, do
                    not review and are not responsible for the privacy policies
                    of or content displayed on such other websites. When you
                    click on such a link, you will leave our Site and go to
                    another site. During this process, another entity may
                    collect Personal Data or Anonymous Data from you. Please be
                    aware that the terms of this Privacy Policy do not apply to
                    these outside websites or content, or to any collection of
                    data after you click on links to such outside websites.
                  </Typography>
                </div>
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">
              Your Choices Regarding Your Personal Data
            </Typography>
            <ol id="policy-details">
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Choices
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    We offer you choices regarding the collection, use, and
                    sharing of your Personal Data.
                  </Typography>
                  <ol id="policy-subdetails">
                    <li>
                      We will periodically send you free newsletters and emails
                      that directly promote the use of our Site or Services.
                      When you receive newsletters or promotional communications
                      from us, you may indicate a preference to stop receiving
                      further communications from us and you will have the
                      opportunity to "unsubscribe" by clicking a link included
                      in the email or contacting us via our contact below.
                    </li>
                    <li>
                      Despite your indicated email preferences, we may send you
                      service related communication, including notices of any
                      updates to our Terms of Use or Privacy Policy.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <div>
                  <Typography style={{ marginBottom: "0.8rem" }}>
                    Changes to Personal Data
                  </Typography>
                  <Typography style={{ marginBottom: "1.5rem" }}>
                    You may change any of your Personal Data in your Account by
                    editing your profile within your account or by contacting us
                    directly. You may request deletion of your Personal Data by
                    us, but please note that we may be required to keep this
                    information and not delete it (or to keep this information
                    for a certain time, in which case we will comply with your
                    deletion request only after we have fulfilled such
                    requirements). When we delete any information, it will be
                    deleted from the active database, but may remain in our
                    archives. We may retain your information for fraud or
                    similar purposes. Please remember, however, if we have
                    already disclosed some of your Personal Data to third
                    parties, we cannot access that Personal Data any longer and
                    cannot force the deletion or modification of any such
                    information by the parties to whom we have made those
                    disclosures.
                  </Typography>
                </div>
              </li>
            </ol>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Security of Your Personal Data</Typography>
            <Typography style={{ marginBottom: "1.5rem" }}>
              Poyo Health is committed to protecting the security of your
              Personal Data. We use a variety of industry-standard security
              technologies and procedures to help protect your Personal Data
              from unauthorized access, use, or disclosure. We also require you
              to enter a password to access your account information. Please do
              not disclose your account password to unauthorized people. No
              method of transmission over the Internet, or method of electronic
              data storage, is 100% secure, however. Therefore, while Poyo
              Health uses reasonable efforts to protect your Personal Data, Poyo
              Health cannot guarantee its absolute security.
            </Typography>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Contact Information</Typography>
            <Typography style={{ marginBottom: "1.5rem" }}>
              Poyo Health welcomes your comments or questions regarding this
              Privacy Policy. Please e-mail us at privacy@poyohealth.com or
              contact us at the following address:
            </Typography>
            <Typography
              style={{ marginBottom: "1.5rem", whiteSpace: "pre-line" }}
            >
              Poyo Health Ltd.{"\n"}Attn: Privacy Matters{"\n"}3137 18TH Ave E,
              Vancouver, BC V5M 2R6
            </Typography>
          </div>
        </li>
        <li id="title">
          <div>
            <Typography variant="h4">Changes to This Privacy Policy</Typography>
            <Typography style={{ marginBottom: "1.5rem" }}>
              This Privacy Policy is subject to occasional revision, and if we
              make any material changes in the way we use your Personal Data, we
              will notify you by sending you an email to the last email address
              you provided to us and/or by prominently posting notice of the
              changes on our Site. Any changes to this Privacy Policy will be
              effective upon the earlier of thirty (30) calendar days following
              our dispatch of an email notice to you or thirty (30) calendar
              days following our posting of notice of the changes on our Site.
              These changes will be effective immediately for new users of our
              Service. Please note that at all times you are responsible for
              updating your Personal Data to provide us with your most current
              email address. In the event that the last email address that you
              have provided us is not valid, or for any reason is not capable of
              delivering to you the notice described above, our dispatch of the
              email containing such notice will nonetheless constitute effective
              notice of the changes described in the notice. In any event,
              changes to this Privacy Policy may affect our use of Personal Data
              that you provided us prior to our notification to you of the
              changes. If you do not wish to permit changes in our use of your
              Personal Data, you must notify us prior to the effective date of
              the changes that you wish to deactivate your account with us.
              Continued use of our Site or Service, following notice of such
              changes shall indicate your acknowledgement of such changes and
              agreement to be bound by the terms and conditions of such changes.
            </Typography>
          </div>
        </li>
      </ol>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ marginTop: "2rem" }}
      >
        Last Revised: January 5, 2022
      </Typography>
    </>
  );
}

export default Privacy;
