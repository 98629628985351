import React from "react";
import { Link } from "react-scroll";
import SignupFamilyDialog from "../../../loginSignup/family/SignupFamilyDialog";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function CovidHero() {
  return (
    <Grid container direction="row-reverse" className="cv-hero-wrapper">
      <Grid item xs={12} md={6} style={{ minHeight: 200 }}>
        <div className="flexbox-centered-column cv-hero-header contrast-txt ">
          <div style={{ width: "100%" }}>
            <Typography
              variant="h2"
              align="right"
              className="txt-bold responsive-text-center "
            >
              Your safety is our top priority.
            </Typography>
            <Typography
              variant="h5"
              className="description-txt responsive-text-center "
              align="right"
            >
              Home is the safest place to be right now.
            </Typography>
            <Typography
              className="description-txt responsive-text-left "
              align="right"
            >
              At Poyo, we are commited to protecting the most vulnerable members
              of our community throughout the COVID-19 Pandemic.
            </Typography>
            <div className="cv-flex-hero">
              <Button className="txt-link-btn">
                <Link
                  className="contrast-txt"
                  to="covid-actions"
                  spy
                  smooth
                  offset={-56}
                >
                  Learn more below
                </Link>
              </Button>
            </div>
          </div>

          <SignupFamilyDialog
            btnText="Get Started Today"
            btnProps={{
              className: "heroBanner-signup-button-family",
              fullWidth: false,
              size: "large",
              fontWeight: 550,
              style: {
                borderRadius: 25,
                textTransform: "none",
                marginTop: 24,
              },
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="img-cover cv-hero-img" />
      </Grid>
    </Grid>
  );
}

export default CovidHero;
